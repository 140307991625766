import { useMemo, useState } from 'react';
import { Text } from '../../../../../components/ui/base/typography';
import { GmrNotification } from '../../../../../store/declarations/uk/gvms-declaration';
import {
    BackToList,
    CodeDiv,
    DetailsLabel,
    DetailsLabelDiv,
    DetailsValue,
    MessageDiv,
    ShowDetailsDiv,
    StyledErrorRow,
    Title,
} from '../../../common/declaration-errors-drawer/DeclarationsNotificationsDrawer.styles';
import { ErrorRow } from '../../../common/declaration-errors-drawer/declaration-errors/ais/RevenueImportErrorDetails';
import { goToDeclarationField } from '../../../utils/form-utils';
import { Typography } from 'antd';
import { getFieldName } from '../../../../../utils/validationParserUtils';
import ukGvmsRecordParser from '../validation/ukGvmsRecordParser';

const GvmsDeclarationErrors = ({ errors, onClose }: SpecificDeclarationErrorsProps) => {
    const [selectedError, setSelectedError] = useState<GmrNotification['message']['ruleFailures'][0] | undefined>(
        undefined
    );

    const renderListOfErrors = (errors: GmrNotification['message']['ruleFailures'] | undefined) =>
        errors?.map((error, i: number) => (
            <StyledErrorRow justify="space-between" key={`error-row-${i}`}>
                <CodeDiv>
                    <Text>{error?.code || '-'}</Text>
                </CodeDiv>
                <MessageDiv>
                    <Text>{error?.technicalMessage || '-'}</Text>
                </MessageDiv>
                <ShowDetailsDiv>
                    <span onClick={() => setSelectedError(error)}>Show details</span>
                </ShowDetailsDiv>
            </StyledErrorRow>
        ));

    const errorDetails = useMemo(() => {
        const errorField = selectedError?.field ? selectedError.field.slice(2) : null; // removes "$." from path
        return (
            <>
                <BackToList onClick={() => setSelectedError(undefined)}>Back to list</BackToList>
                <>
                    <Title>Error details</Title>
                    <ErrorRow label="Code:" value={selectedError?.code ?? '-'} />
                    <ErrorRow label="Message:" value={selectedError?.technicalMessage ?? '-'} />
                    <StyledErrorRow>
                        <DetailsLabelDiv>
                            <DetailsLabel>Field:</DetailsLabel>
                        </DetailsLabelDiv>
                        <DetailsLabelDiv style={{ flex: 1 }}>
                            <Typography.Link
                                onClick={() => {
                                    if (!errorField) return;
                                    onClose?.();
                                    goToDeclarationField(errorField, 100);
                                }}
                            >
                                <DetailsValue>
                                    {errorField ? getFieldName(ukGvmsRecordParser as any, errorField) || '-' : '-'}
                                </DetailsValue>
                            </Typography.Link>
                        </DetailsLabelDiv>
                    </StyledErrorRow>
                    <ErrorRow label="Value:" value={selectedError?.value ?? '-'} />
                </>
            </>
        );
    }, [selectedError, onClose]);

    if (!selectedError) return <>{renderListOfErrors(errors)}</>;
    else return <>{errorDetails}</>;
};

export default GvmsDeclarationErrors;
