import { Notifiable } from '../declaration';
import { GVMSMessageTypes } from '../enums/common/declaration-types';

export const gvmsNameLabels = [
    {
        key: GVMSMessageTypes.STANDARD_MOVEMENT_GMR,
        value: 'Standard Movement GMR',
    },
    {
        key: GVMSMessageTypes.TIR_MOVEMENT_GMR,
        value: 'TIR Movement GMR',
    },
    {
        key: GVMSMessageTypes.UNACCOMPANIED_ATA_CARNET_CONTAINER_MOVEMENT,
        value: 'Unaccompanied ATA Carnet Container Movement',
    },
    {
        key: GVMSMessageTypes.INDIRECT_EXPORT_DECLARATIONS_MOVEMENT_GMR,
        value: 'Indirect Export Declarations Movement GMR',
    },
    {
        key: GVMSMessageTypes.EMPTY_MOVEMENT_GMR,
        value: 'Empty Movement GMR',
    },
    {
        key: GVMSMessageTypes.ORAL_OR_BY_CONDUCT_DECLARATION,
        value: 'Oral or by Conduct Declaration',
    },
    {
        key: GVMSMessageTypes.EXEMPTION_MOVEMENT_RECORD,
        value: 'Exemption Movement Record',
    },
    {
        key: GVMSMessageTypes.UKC,
        value: 'UKC',
    },
] as const;

export interface GmrActionRequiredNotification {
    messageId: string;
    gmrId: string;
    gmrState: string;
    gmrStatusVersion: number;
    inspectionRequired: boolean;
    reportToLocations: [
        {
            inspectionTypeId: string;
            locationIds: string[];
        }
    ];
    createdDateTime: string;
    updatedDateTime: string;
    ruleFailures: {
        code: string;
        technicalMessage: string;
        field: string;
        value: string;
    }[];
}

export interface GmrNotification {
    id: string;
    notificationId: string;
    boxId: string;
    message: {
        messageId: string;
        gmrId: string;
        gmrState: string;
        gmrStatusVersion: number;
        inspectionRequired: string;
        reportToLocations: string;
        createdDateTime: string;
        updatedDateTime: string;
        ruleFailures: {
            code: string;
            technicalMessage: string;
            field: string;
            value: string;
        }[];
    };
    status: string;
    createdAt: string;
    createdDateTime: string;
}

export interface UkGvmsRecord extends Notifiable<GmrNotification | GmrActionRequiredNotification> {
    direction: string;
    haulierType: string;
    isUnaccompanied: boolean;
    vehicleRegNum: string;
    trailerRegistrationNums: string[];
    containerReferenceNums: string[];
    plannedCrossing: {
        routeId: string;
        localDateTimeOfDeparture: string;
    };
    sAndSMasterRefNum: string;
    customsDeclarations: {
        customsDeclarationId: string;
        customsDeclarationPartId: string;
        sAndSMasterRefNum: string;
    }[];
    sadDeclaration: {
        sadGoods: {
            sAndSMasterRefNum: string;
        }[];
    };
    transitDeclarations: {
        transitDeclarationId: string;
        isTSAD: boolean;
        sAndSMasterRefNum: string;
    }[];
    tirDeclarations: {
        tirCarnetId: string;
        sAndSMasterRefNum: string;
    }[];
    ataDeclarations: {
        ataCarnetId: string;
        sAndSMasterRefNum: string;
    }[];
    eidrDeclarations: {
        traderEORI: string;
        sAndSMasterRefNum: string;
    }[];
    indirectExportDeclarations: {
        eadMasterRefNum: string;
    }[];
    emptyVehicle: {
        isOwnVehicle: boolean;
        sAndSMasterRefNum: string;
    };
    dbcDeclaration: {
        isOwnVehicle: boolean;
        dbcGoods: {
            sAndSMasterRefNum: string;
        }[];
    };
    exemptionDeclaration: {
        exemptedGoods: {
            sAndSMasterRefNum: string;
        }[];
    };
    ukcDeclaration: {
        fpoEORI: string;
        sAndSMasterRefNum: string;
    };
    messageType?: GVMSMessageTypes;
    gmrId?: string;
    gmrExpiredAt?: string;
    gmrIdBarcode?: string;

    gvmsRouteData: {
        arrivalPortId?: string;
        departurePortId?: string;
        carrierId?: string;
    };
}

export interface GvmsReferenceDataStore {
    gvmsReferenceData: GvmsReferenceData | undefined;
}

export interface GvmsReferenceData {
    transformedRoutes: {
        [routeDirection: string]: {
            [carrierId: string]: {
                [arrivalPortId: string]: {
                    [departurePortId: string]: string;
                };
            };
        };
    };
    routes: {
        routeId: string;
        routeDirection: string;
        routeEffectiveFrom: string;
        departurePortId: number;
        arrivalPortId: number;
        carrierId: number;
        routeEffectiveTo: string;
    }[];
    ports: {
        portId: number;
        portRegion: string;
        portEffectiveFrom: string;
        portDescription: string;
        timezoneId: string;
        chiefPortCode: string;
        cdsPortCode: string;
        officeOfTransitCustomsOfficeCode: string;
        portEffectiveTo: string;
        portCountryCode: string;
        isOperatingArrivedExportsProcess: boolean;
    }[];
    carriers: {
        carrierId: number;
        carrierName: string;
        countryCode: string;
    }[];
    locations: {
        locationId: string;
        locationDescription: string;
        address: {
            id: string;
            city: string;
            country: string;
            streetAndNumber: string;
            postCode: string;
        };
        locationType: string;
        supportedDirections: string[];
        locationEffectiveFrom: string;
        locationEffectiveTo: string;
        supportedInspectionTypeIds: string[];
        requiredInspectionLocations: [number];
    }[];
    inspectionTypes: {
        inspectionTypeId: string;
        description: string;
    }[];
    ruleFailures: {
        ruleId: string;
        ruleDescription: string;
    }[];
}
