import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

const useDebounce = <TFunction extends (...args: any) => any>(callback: TFunction, ms: number = 1000) => {
    const ref = useRef<TFunction>(callback);

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        if (!ref.current) return undefined;
        return debounce(ref.current, ms);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return debouncedCallback;
};

export default useDebounce;
