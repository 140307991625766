import { Col, Modal, Row, notification, TablePaginationConfig } from 'antd';
import Container from 'components/ui/base/container';
import CustomModal from 'components/ui/base/modal/Modal';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import Filter from '../../components/ui/composed/filter/Filter';
import { FilterResult } from 'components/ui/composed/filter/filter-result';
import { getFiltersForDeclaration } from 'components/ui/composed/filter/filter-utils';
import ListFilter from 'components/ui/composed/filter/ListFilters';
import { enumToText } from 'core/utils/enum-to-text';
import { capitalize } from 'core/utils/strings';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { useRequestPromise } from 'hooks/useRequest';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    getIrelandSadDraft,
    getIrelandClearanceDraft,
    generateIrelandSadDraft,
    generateIrelandClearanceDraft,
    listDeclarations as listDeclarationsRequest,
    archiveDeclaration,
    unarchiveDeclaration,
    listArchivedDeclarations,
    deleteDeclaration,
} from 'store/declarations/client';
import { Declaration, DeclarationAndAgent } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { CreateButton, StyledDivider, TableDiv } from './DeclarationDashboard.styles';
import DeclarationTable from './declaration-table/DeclarationsTable';
import useDeclarationFilter from '../../hooks/useDeclarationFilter';
import { DocumentsDiv, SadDocTitle } from '../declarations/common/summary-view/SummaryView.styles';
import DocumentTable from '../declarations/common/summary-view/DocumentTable';
import StatusSegmented, { Segment } from 'views/declarations/common/dashboard/StatusSegmented';
import { ListPayload } from 'core/http/response';
import MirrorDeclarationModal from './declaration-table/MirrorDeclarationModal';
import { getTableChangeParams } from '../../utils/tableHelpers';
import { DeclarationExternalEntity } from '../../store/declarations/enums/common/declaration-external-entity';
import DeclarationDashboardActionRequiredMessage from './declaration-table/components/DeclarationDashboardActionRequiredMessage';
import { GvmsReferenceData } from '../../store/declarations/uk/gvms-declaration';
import { getGvmsReferenceData } from '../declarations/uk/gvms/utils';
import BarcodeModal from './declaration-table/BarcodeModal';
import useDeclarations from '../../hooks/useDeclarations';
import { format } from 'date-fns';
import { parseToDate } from '../../components/ui/base/datepicker/utils';

interface PrintModalValues {
    visible: boolean;
    declarationId: string | undefined;
}

interface BarcodeModalValues {
    visible: boolean;
    declaration: Declaration | undefined;
}

const DeclarationDashboard: FC = () => {
    const { country, type, dashboardView } = useParams<{
        country: DeclarationCountry;
        type: DeclarationInternalType;
        dashboardView: 'archived' | 'core-declarations';
    }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation('customs_declarations');
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { toggleCoreTemplateStatus } = useDeclarations();

    const [paginator, setPaginator] = useState<TablePaginationConfig>({ pageSize: 10, current: 1, total: 0 });
    const [searchQuery, setSearchQuery] = useState<string>('');

    const { declarationFilter, setDeclarationFilter } = useDeclarationFilter();
    const [filterResult, setFilterResult] = useState<FilterResult[]>(
        declarationFilter.declarationType === type ? declarationFilter.filter : []
    );

    const [printModalState, setPrintModalState] = useReducer(
        (initialState: PrintModalValues, newState: Partial<PrintModalValues>) => ({ ...initialState, ...newState }),
        { visible: false, declarationId: undefined }
    );

    const [archiveIds, setArchiveIds] = useState<string[]>([]);
    const [archiveModalVisible, setArchiveModalVisible] = useState<boolean>(false);
    const showArchiveModal = useCallback(() => setArchiveModalVisible(true), []);
    const hideArchiveModal = useCallback(() => {
        setArchiveModalVisible(false);
        setArchiveIds([]);
    }, []);
    const [unarchiveModalVisible, setUnarchiveModalVisible] = useState<boolean>(false);
    const showUnarchiveModal = useCallback(() => setUnarchiveModalVisible(true), []);
    const hideUnarchiveModal = useCallback(() => {
        setUnarchiveModalVisible(false);
        setArchiveIds([]);
    }, []);

    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [deleteDeclarationId, setDeleteDeclarationId] = useState<string | undefined>(undefined);

    const atArchivedView = dashboardView === 'archived';
    const atCoreDeclView = dashboardView === 'core-declarations';

    const [barcodeModalState, setBarcodeModalState] = useReducer(
        (initialState: BarcodeModalValues, newState: Partial<BarcodeModalValues>) => ({ ...initialState, ...newState }),
        { visible: false, declaration: undefined }
    );
    const handleOpenBarcodeModal = (declaration: Declaration | undefined) =>
        setBarcodeModalState({ visible: true, declaration });
    const [gvmsReferenceData, setGvmsReferenceData] = useState<GvmsReferenceData | undefined>(undefined);
    useEffect(() => {
        if (
            country === DeclarationCountry.UK &&
            type === DeclarationInternalType.GVMS &&
            gvmsReferenceData === undefined
        )
            getGvmsReferenceData().then(setGvmsReferenceData);
    }, [country, type, gvmsReferenceData]);

    useEffect(() => {
        if (!(type && filterResult)) return;
        setDeclarationFilter({ declarationType: type, filter: filterResult });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, filterResult]);

    const declarationExternalEntityParams = useMemo(() => {
        const declarationParams: {
            declarationExternalEntity: DeclarationExternalEntity[];
            declarationInternalType: DeclarationInternalType[];
        } = {
            declarationInternalType: type ? [type] : [],
            declarationExternalEntity: [],
        };

        if (country === DeclarationCountry.IRELAND) {
            declarationParams.declarationExternalEntity.push(DeclarationExternalEntity.REVENUE);
        } else if (country === DeclarationCountry.UK) {
            if (type === DeclarationInternalType.IMPORT) {
                declarationParams.declarationInternalType.push(DeclarationInternalType.IMPORT_NEW);
            }

            if (type === DeclarationInternalType.GVMS) {
                declarationParams.declarationExternalEntity.push(DeclarationExternalEntity.GVMS);
            } else if (type === DeclarationInternalType.NCTS) {
                declarationParams.declarationExternalEntity.push(DeclarationExternalEntity.HMRC);
            } else {
                declarationParams.declarationExternalEntity.push(DeclarationExternalEntity.CDS);
            }
        }

        return declarationParams;
    }, [type, country]);

    const getDeclarationParameters = useCallback(
        ({
            searchQueryParam,
            filters,
            pagination,
        }: {
            searchQueryParam?: string;
            filters?: FilterResult[];
            pagination?: TablePaginationConfig;
        }) => {
            let formattedQuery;
            const date = parseToDate(searchQueryParam || searchQuery, [
                'dd/MM/yyyy',
                "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                'dd.MM.yyyy',
            ]);

            if (date instanceof Date && !isNaN(date.getTime())) {
                formattedQuery = format(date, 'dd-MM-yyyy');
            } else {
                formattedQuery = searchQueryParam || searchQuery;
            }

            return {
                ...declarationExternalEntityParams,
                ...getFiltersForDeclaration(filters ?? filterResult),
                ...getTableChangeParams({ pagination: pagination ?? paginator }),
                query: formattedQuery,
                archived: atArchivedView,
                isCoreTemplate: atCoreDeclView,
            };
        },
        [atArchivedView, declarationExternalEntityParams, filterResult, paginator, searchQuery, atCoreDeclView]
    );

    const {
        data: declarations,
        isLoading,
        refetch: listDeclarations,
    } = useRequestPromise(
        (params?: any) => {
            const { archived, ...rest } = params ?? {};
            return archived ? listArchivedDeclarations(rest) : listDeclarationsRequest(rest);
        },
        {
            args: [{ ...declarationExternalEntityParams, archived: atArchivedView, isCoreTemplate: atCoreDeclView }],
            withSignal: true,
        }
    );

    useEffect(() => {
        setPaginator((prev) => ({ ...prev, total: declarations?.total, current: (declarations?.pageNumber ?? 0) + 1 }));
    }, [declarations]);

    const debouncedSearch = debounce((query: string) => {
        const p = { ...paginator, current: 1 };
        listDeclarations(getDeclarationParameters({ searchQueryParam: query, pagination: p }));
    }, 700);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Customs Declarations',
                path: '',
            },
            {
                breadcrumbName: t(`custom${country}`),
                path: '',
            },
            {
                breadcrumbName: t(`${type}${country}`),
                path: '',
            },
        ]);

        return () => {
            setSearchQuery('');
            setFilterResult([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, type]);

    const onFilterChange = useCallback(
        (filters: FilterResult[]) => {
            setFilterResult(filters);
            listDeclarations(getDeclarationParameters({ filters }));
        },
        [listDeclarations, getDeclarationParameters]
    );

    const handlePrintButtonClick = (declarationId: string | undefined) => {
        if (!declarationId) return;

        Promise.all([generateIrelandSadDraft(declarationId), generateIrelandClearanceDraft(declarationId)]).then(() =>
            setPrintModalState({ visible: true, declarationId })
        );
    };

    const handleDuplicate = async (declaration: Declaration) => {
        navigate(`/invoice-upload/${country}/${type}/job/${declaration.jobId}`, {
            state: { declaration: declaration },
        });
    };

    const handleDeleteButtonClick = (deleteId: string | undefined) => {
        setDeleteDeclarationId(deleteId);
        setDeleteModalVisible(true);
    };

    const handleToggleCoreTemplateStatus = useCallback(
        async (declaration: Declaration) => {
            if (!declaration?.id) return;

            const notificationType = declaration?.isCoreTemplate ? 'removeAsCoreTemplate' : 'setAsCoreTemplate';

            await toggleCoreTemplateStatus(declaration.id, {
                notificationType,
            });
            listDeclarations(getDeclarationParameters({}));
        },
        [getDeclarationParameters, listDeclarations, toggleCoreTemplateStatus]
    );

    const handleDeleteDeclaration = () => {
        if (!deleteDeclarationId)
            return notification.error({ message: 'Something went wrong, please try again later!' });

        deleteDeclaration(deleteDeclarationId)
            .then(() => {
                notification.success({ message: 'Declaration deleted successfully!' });
                listDeclarations(getDeclarationParameters({}));
            })
            .catch(() => {
                notification.error({ message: 'Something went wrong, please try again later!' });
            })
            .finally(() => {
                setDeleteModalVisible(false);
                setDeleteDeclarationId(undefined);
            });
    };

    const handleArchive = (ids: string[]) => {
        setArchiveIds(ids);
        showArchiveModal();
    };

    const handleUnarchive = (ids: string[]) => {
        setArchiveIds(ids);
        showUnarchiveModal();
    };

    const onDeclarationTableChange = (pagination: TablePaginationConfig) => {
        setPaginator(pagination);
        listDeclarations(getDeclarationParameters({ pagination }));
    };

    useEffect(() => {
        listDeclarations({
            ...declarationExternalEntityParams,
            ...(declarationFilter.declarationType === type && getFiltersForDeclaration(declarationFilter.filter)),
            archived: atArchivedView,
            isCoreTemplate: atCoreDeclView,
        })?.catch((err) => {
            if (err.message === 'canceled') Promise.resolve();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const entity = type === DeclarationInternalType.GVMS ? 'record' : 'declaration';
    const archiveDeclarations = async () => {
        hideArchiveModal();
        Promise.all(archiveIds.map((id) => archiveDeclaration(id)))
            .then(() => {
                listDeclarations(getDeclarationParameters({}));
                notification.success({ message: `${capitalize(entity)} archived successfully!` });
            })
            .catch(() => {
                notification.error({ message: `Could not archive the ${entity}!` });
            })
            .finally(() => {
                setArchiveIds([]);
            });
    };
    const unarchiveDeclarations = async () => {
        hideUnarchiveModal();
        Promise.all(archiveIds.map((id) => unarchiveDeclaration(id)))
            .then((res) => {
                listDeclarations(getDeclarationParameters({}));
                notification.success({ message: `${capitalize(entity)} unarchived successfully!` });
            })
            .catch(() => {
                notification.error({ message: `Could not unarchive the ${entity}!` });
            })
            .finally(() => {
                setArchiveIds([]);
            });
    };

    const [declarationToBeMirrored, setDeclarationToBeMirrored] = useState<Declaration | null>(null);
    const [mirrorDeclarationModalVisible, setMirrorDeclarationModalVisible] = useState(false);
    const openMirrorDeclarationModal = useCallback((declaration: Declaration) => {
        setDeclarationToBeMirrored(declaration);
        setMirrorDeclarationModalVisible(true);
    }, []);
    const closeMirrorDeclarationModal = useCallback(() => {
        setDeclarationToBeMirrored(null);
        setMirrorDeclarationModalVisible(false);
    }, []);

    const initiateMirroringFlow = (data: { country: string; declarationType: string }) => {
        if (!declarationToBeMirrored) throw new Error('No declaration was selected to be mirrored');
        navigate(`/invoice-upload/${data.country}/${data.declarationType}/job/${declarationToBeMirrored.jobId}`, {
            state: { declaration: declarationToBeMirrored, mirroring: true },
        });
        setDeclarationToBeMirrored(null);
    };

    return (
        <>
            <CustomModal
                title={<H5>Do you want to delete this declaration?</H5>}
                centered
                visible={deleteModalVisible}
                onOk={handleDeleteDeclaration}
                onCancel={() => setDeleteModalVisible(false)}
                width={762}
                confirmText="Delete"
                cancelText="Cancel"
                contentText={'The declaration will be deleted and all information associated with it will be lost.'}
            />
            <CustomModal
                title={<H5>Do you want to archive this {entity}?</H5>}
                centered
                visible={archiveModalVisible}
                onOk={archiveDeclarations}
                onCancel={hideArchiveModal}
                width={762}
                confirmText="Archive"
                cancelText="Cancel"
                contentText={`The ${entity} will be added to the archive.`}
            />
            <CustomModal
                title={<H5>Do you want to unarchive this {entity}?</H5>}
                centered
                visible={unarchiveModalVisible}
                onOk={unarchiveDeclarations}
                onCancel={hideUnarchiveModal}
                width={762}
                confirmText="Unarchive"
                cancelText="Cancel"
                contentText={`The ${entity} will be unarchived and become active.`}
            />
            <Modal
                centered
                width={762}
                visible={printModalState.visible}
                onCancel={() => setPrintModalState({ visible: false })}
                footer={null}
                bodyStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '3.2rem',
                    minHeight: '236px',
                }}
            >
                <>
                    <DocumentsDiv>
                        <SadDocTitle>SAD Document</SadDocTitle>
                        <DocumentTable
                            id={printModalState.declarationId}
                            request={getIrelandSadDraft}
                            fileName="sad-document"
                        />
                    </DocumentsDiv>
                    <DocumentsDiv>
                        <SadDocTitle>Clearance Slip</SadDocTitle>
                        <DocumentTable
                            id={printModalState.declarationId}
                            request={getIrelandClearanceDraft}
                            fileName="clearanceslip-document"
                        />
                    </DocumentsDiv>
                </>
            </Modal>
            <Container>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <H5>
                            {type === DeclarationInternalType.GVMS
                                ? 'Goods Movement Records'
                                : type === DeclarationInternalType.PBN
                                ? 'Pre-Boarding Notifications'
                                : `${t(type!)} ${t('declarations')}`}
                        </H5>

                        <CreateButton
                            type="primary"
                            size="large"
                            onClick={() => navigate(`/invoice-upload/${country}/${type}`)}
                            disabled={Boolean(dashboardView)}
                        >
                            {type === DeclarationInternalType.GVMS
                                ? 'Create Goods Movement Record'
                                : type === DeclarationInternalType.PBN
                                ? 'Create Pre-Boarding Notification'
                                : `Create ${enumToText(type)} ${capitalize(country!)} Declaration`}
                        </CreateButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                        {!dashboardView &&
                            ((type === DeclarationInternalType.EXPORT && country === DeclarationCountry.IRELAND) ||
                                type === DeclarationInternalType.GVMS ||
                                type === DeclarationInternalType.NCTS) && (
                                <DeclarationDashboardActionRequiredMessage
                                    declarationParams={declarationExternalEntityParams}
                                />
                            )}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <StatusSegmented
                                value={dashboardView || 'active'}
                                absoluteNavPath={`/customs-declarations/${country}/${type}/`}
                                additionalOptions={[
                                    {
                                        label: (
                                            <Segment>
                                                core{' '}
                                                {type === DeclarationInternalType.GVMS ||
                                                type === DeclarationInternalType.PBN
                                                    ? 'records'
                                                    : 'decl.'}
                                            </Segment>
                                        ),
                                        value: 'core-declarations',
                                    },
                                ]}
                                onChangeExtension={() => setSearchQuery('')}
                            />
                        </div>
                    </div>
                </div>
                <StyledDivider />
                <Row gutter={16} wrap={false}>
                    <Col flex="auto">
                        <SearchBar
                            value={searchQuery}
                            inputPlaceholder={t(
                                type === DeclarationInternalType.GVMS ? 'searchGvmsTable' : 'SearchByDeclarationTable'
                            )}
                            onSearch={(value) => {
                                setSearchQuery(value);
                                debouncedSearch(value);
                            }}
                            onClear={() => {
                                setSearchQuery('');
                                listDeclarations({ ...declarationExternalEntityParams });
                            }}
                        />
                    </Col>
                    <Col>
                        <Filter filterState={filterResult} onFilterChange={onFilterChange} />
                    </Col>
                </Row>

                <TableDiv>
                    <ListFilter filters={filterResult} onFilter={onFilterChange} />
                    {declarations && (
                        <DeclarationTable
                            data={declarations as ListPayload<DeclarationAndAgent>}
                            pagination={{ ...paginator, position: ['bottomCenter'] }}
                            handleDuplicate={handleDuplicate}
                            onMirror={openMirrorDeclarationModal}
                            onUnarchive={handleUnarchive}
                            onArchive={handleArchive}
                            onDelete={handleDeleteButtonClick}
                            loading={isLoading}
                            onPrint={(declarationId: string) => handlePrintButtonClick(declarationId)}
                            onChange={onDeclarationTableChange}
                            gvmsReferenceData={gvmsReferenceData}
                            onBarcode={handleOpenBarcodeModal}
                            onCoreTemplateToggle={handleToggleCoreTemplateStatus}
                        />
                    )}
                </TableDiv>
            </Container>
            <MirrorDeclarationModal
                visible={mirrorDeclarationModalVisible}
                onCancel={closeMirrorDeclarationModal}
                onContinue={initiateMirroringFlow}
            />
            <BarcodeModal
                visible={barcodeModalState.visible}
                declaration={barcodeModalState.declaration}
                onCancel={() => setBarcodeModalState({ visible: false })}
            />
        </>
    );
};

export default DeclarationDashboard;
