import Button from 'components/ui/base/button';
import { H5 } from 'components/ui/base/typography';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { useFormik } from 'formik';
import { createRef, FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeclarationCountry, declarationCountryLabels } from 'store/declarations/enums/common/declaration-country';
import {
    DeclarationInternalType,
    irelandInternalDeclarationTypes,
    ukInternalDeclarationTypes,
} from 'store/declarations/enums/common/declaration-internal-type';
import { getRequiredMessage } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';
import { getFormikProps } from '../../declarations/utils/form-utils';
import { FlexDiv, NewDeclarationCard } from './CreateNewDeclarationCard.styles';
import { JobResponse } from 'store/jobs/job';

interface Props {
    handleCancel: Function;
    isActive?: boolean;
    job?: JobResponse;
}

const valididationSchema = Yup.object().shape({
    declarationType: Yup.string().required(getRequiredMessage('declarationType')).nullable(),
    country: Yup.string().required(getRequiredMessage('country')).nullable(),
});

const CreateNewDeclarationCard: FC<Props> = ({ handleCancel, job, isActive }) => {
    const cardRef = createRef<HTMLDivElement>();
    const navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>(undefined);

    const { isValid, handleSubmit, getFieldProps, getFieldHelpers, getFieldMeta, resetForm } = useFormik<{
        declarationType: DeclarationInternalType | null;
        country: DeclarationCountry | null;
    }>({
        initialValues: {
            declarationType: null,
            country: null,
        },
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: valididationSchema,
        onSubmit: (values) => createDeclaration(values),
    });

    const createDeclaration = (values: {
        declarationType: DeclarationInternalType | null;
        country: DeclarationCountry | null;
    }) => {
        return job
            ? navigate(`/invoice-upload/${values.country}/${values.declarationType}`, { state: { job } })
            : navigate(`/invoice-upload/${values.country}/${values.declarationType}`);
    };

    const capitalizeCountryLabels = () =>
        declarationCountryLabels.map((item) => ({
            id: item.id,
            value: `${item.value.charAt(0).toUpperCase()}${item.value.slice(1)}`,
        }));

    const handleCountryChange = (value: string) => {
        setSelectedCountry(value);
    };

    const declarationTypeProps = useMemo(
        () => getFormikProps('declarationType', { getFieldProps, getFieldHelpers }),
        [getFieldHelpers, getFieldProps]
    );

    useEffect(() => {
        if (!cardRef) {
            resetForm();
            setSelectedCountry(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardRef]);

    useEffect(() => {
        // making sure whenever we change country, we have correct value from correct codelist
        if (!selectedCountry) return;
        const defaultValue =
            selectedCountry === DeclarationCountry.IRELAND
                ? irelandInternalDeclarationTypes[0].id
                : ukInternalDeclarationTypes[0].id;
        declarationTypeProps.fieldHelper.setValue(defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountry]);

    return (
        <>
            {isActive && (
                <NewDeclarationCard ref={cardRef}>
                    <H5 style={{ marginBottom: '2.7rem' }}>Create Declaration </H5>
                    <FormSelect
                        hideKeys
                        label="Country"
                        fieldMeta={getFieldMeta('country')}
                        fieldHelper={getFieldHelpers('country')}
                        fieldProps={getFieldProps('country')}
                        selectOptions={capitalizeCountryLabels()}
                        placeholder="Select country"
                        handleCountryChange={handleCountryChange}
                    />
                    <FormSelect
                        hideKeys
                        label="Declaration type"
                        fieldProps={getFieldProps('declarationType')}
                        fieldMeta={getFieldMeta('declarationType')}
                        fieldHelper={getFieldHelpers('declarationType')}
                        selectOptions={
                            selectedCountry === DeclarationCountry.IRELAND
                                ? irelandInternalDeclarationTypes
                                : ukInternalDeclarationTypes
                        }
                        placeholder="Select declaration type"
                    />

                    <FlexDiv>
                        <Button
                            onClick={() => {
                                resetForm();
                                handleCancel();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            data-testid="continue-button"
                            testId="create-new-declaration-modal"
                            disabled={!isValid}
                            onClick={() => handleSubmit()}
                        >
                            Continue
                        </Button>
                    </FlexDiv>
                </NewDeclarationCard>
            )}
        </>
    );
};
export default CreateNewDeclarationCard;
