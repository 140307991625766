import Button from 'components/ui/base/button/Button';
import Divider from 'components/ui/base/divider/Divider';
import styled from 'styled-components';

export const StyledDivider = styled(Divider)`
    margin-bottom: 2.5rem;
`;

export const CreateButton = styled(Button)`
    margin-top: 1.6rem;
    margin-bottom: 0.5rem;
`;

export const TableDiv = styled.div`
    margin-top: 5.1rem;
`;

export const DivTableActionInline = styled.div`
    display: flex;
    justify-content: flex-end;
`;
