import { FormikProps, FormikProvider } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import AdditionalInformationBlock, {
    additionalInformationBlockValidation,
} from './export/blocks/AdditionalInformationBlock';
import AdditionalDocumentBlock, { additionalDocumentBlockValidation } from './export/blocks/AdditionalDocumentBlock';
import PreviousDocumentsBlock, {
    previousDocumentsBlockProductValidation,
} from './export/blocks/PreviousDocumentsBlock';
import AdditionalFiscalReferencesBlock, {
    additionalFiscalReferencesBlockProductValidation,
} from './export/blocks/AdditionalFiscalReferencesBlock';
import AdditionalSupplyChainActorBlock, {
    additionalSupplyChainActorBlockProductValidation,
} from './export/blocks/AdditionalSupplyChainActorBlock';
import NatureOfTransactionCard, {
    natureOfTransactionCardProductValidation,
} from './export/cards/NatureOfTransactionCard';
import ItemFieldsCard, { itemFieldsCardValidation } from './export/cards/Products/ItemFieldsCard';
import PackagingCard, { packagingCardValidation } from './export/cards/Products/PackagingCard';
import ProcedureCard, { procedureCardValidation } from './export/cards/Products/ProcedureCard';
import CountryOfOriginBlock, { countryOfOriginBlockValidation } from './export/blocks/CountryOfOriginBlock';
import { ValidationSchema } from './export/validations/validations';
import PartiesAddressForm from 'views/declarations/common/parties/cds/PartiesAddressForm';
import PartiesEoriForm from 'views/declarations/common/parties/cds/PartiesEoriForm';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import TransportEquipmentCard, { transportEquipmentCardValidationProduct } from './export/cards/TransportEquipmentCard';
import CommodityCodesCard, { commodityCodesCardValidation } from './export/cards/Products/CommodityCodesCard';
import useHideEntities from '../../../hooks/useHideEntities';
import { exportItemPartiesCardBlockValidation as exportPartiesCardBlockValidation } from '../common/parties/cds/PartiesBlockValidations';
import AdditionsDeductionsCard from './import/cards/AdditionsDeductionsCard';
import ValuationIndicatorsCard from './import/cards/Products/ValuationIndicatorsCard';
import ItemPriceCard from './import/cards/Products/ItemPriceCard';
import ValuationMethodCard from './import/cards/Products/ValuationMethodCard';
import DutyTaxFeeCard from './import/cards/Products/DutyTaxFeeCard';

// Multiple items card

export const previousDocumentsCardValidation = {
    selfValidators: [previousDocumentsBlockProductValidation],
};

const additionalSupplyChainActorCardValidation = {
    selfValidators: [additionalSupplyChainActorBlockProductValidation],
};

export const additionalFiscalReferencesCardValidation = {
    selfValidators: [additionalFiscalReferencesBlockProductValidation],
};

export const countryOfOriginCardValidation = {
    selfValidators: [countryOfOriginBlockValidation],
};

const partiesCardValidation = {
    selfValidators: [exportPartiesCardBlockValidation],
};

export const cdsExportProductSectionValidation: ValidationSchema = {
    selfValidators: [
        commodityCodesCardValidation,
        itemFieldsCardValidation,
        partiesCardValidation,
        additionalInformationBlockValidation,
        additionalDocumentBlockValidation,
        natureOfTransactionCardProductValidation,
        packagingCardValidation,
        previousDocumentsCardValidation,
        additionalSupplyChainActorCardValidation,
        additionalFiscalReferencesCardValidation,
        countryOfOriginCardValidation,
        transportEquipmentCardValidationProduct,
        procedureCardValidation,
    ],
};

interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    cancelFetchProduct?: boolean;
}

const UkProductsSection: FC<Props> = ({ formik, toggleHelp, viewOnly, cancelFetchProduct = false }) => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();
    const totalNumberOfCards = 15;

    return (
        <FormikProvider value={formik}>
            <CommodityCodesCard />
            <ItemFieldsCard
                keyCard={`item-fields-card`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={3}
                hidden={isFormType([])}
            />
            <ProcedureCard
                keyCard="procedure-card"
                formik={formik}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={4}
                hidden={isFormType([])}
            />
            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'consignment.consignor',
                        header: 'Consignor',
                        refNumber: '3/7',
                        hidden: isFormType(['IMPORT_H1', 'IMPORT_H2']),
                    },
                    {
                        path: 'consignee',
                        header: 'Consignee',
                        refNumber: '3/9',
                        hidden: isFormType(['IMPORT_H1', 'IMPORT_H2']),
                    },
                    {
                        path: 'consignor',
                        header: 'Exporter',
                        refNumber: '3/1',
                        hidden: isFormType(['EXPORT_B1', 'IMPORT_H2']),
                    },
                    {
                        path: 'seller',
                        header: 'Seller',
                        refNumber: '3/24',
                        hasPhoneNumber: true,
                        hidden: isFormType(['EXPORT_B1', 'IMPORT_H2']),
                    },
                    {
                        path: 'buyer',
                        header: 'Buyer',
                        refNumber: '3/26',
                        hasPhoneNumber: true,
                        hidden: isFormType(['EXPORT_B1', 'IMPORT_H2']),
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.cityName',
                        country: 'address.countryCode',
                        name: 'name',
                        postCode: 'address.postcodeID',
                        streetAndNumber: 'address.line',
                    },
                    additional: {
                        representativeStatus: 'functionCode',
                    },
                    eori: 'id',
                }}
                PartiesAddressForm={PartiesAddressForm}
                PartiesEoriForm={PartiesEoriForm}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={5}
                keyCard={`parties-card`}
                condensed
            />
            <MultipleItemsCard
                title={t('additionalInformation.title')}
                keyCard="uk-export-additionalInformation"
                viewOnly={viewOnly}
                path="additionalInformation"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
                initialValues={{ statementCode: '', statementDescription: '' }}
                list={(list) => [
                    { field: t('additionalInformationCode'), value: list?.statementCode },
                    { field: t('additionalInformationDescription'), value: list?.statementDescription },
                ]}
                condensed
                hidden={isFormType([])}
                refNumber="2/2"
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalInformationBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('additionalDocument.title')}
                keyCard="uk-export-additionalDocument"
                viewOnly={viewOnly}
                path="additionalDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={8}
                initialValues={{
                    code: '',
                    id: '',
                    lpcoExemptionCode: '',
                    name: '',
                    effectiveDateTime: '',
                    submitter: { name: '' },
                    writeOff: { quantityQuantity: '', amountAmount: '' },
                }}
                condensed
                list={(list) => [
                    { field: t('documentsProduced.code'), value: list?.code },
                    { field: t('documentsProduced.identifier'), value: list?.id },
                    { field: t('documentsProduced.status'), value: list?.lpcoExemptionCode },
                    { field: t('documentsProduced.reason'), value: list?.name },
                    { field: t('writingOff.dateOfValidity'), value: list?.effectiveDateTime },
                    { field: t('writingOff.issuingAuthority'), value: list?.submitter?.name },
                    { field: t('writingOff.measurementUnitAndQualifier'), value: list?.writeOff?.amountAmount },
                    { field: t('writingOff.quantity'), value: list?.writeOff?.quantityQuantity },
                ]}
                required={isFormType(['IMPORT_H2'])}
                formik={formik}
                hidden={isFormType([])}
                refNumber="8/7"
            >
                {(path) => (
                    <FormCardContainer oneColumn>
                        <AdditionalDocumentBlock path={path} hide={isFormType(['IMPORT_H2'])} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NatureOfTransactionCard
                propsPathPrefix=""
                viewOnly={viewOnly}
                keyCard={`nature-of-transaction`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={9}
                hidden={isFormType([])}
            />

            <PackagingCard
                viewOnly={viewOnly}
                keyCard={`uk-export-packaging-card`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={10}
                formik={formik}
                hidden={isFormType([])}
            />

            <MultipleItemsCard
                title={t('previousDocuments')}
                keyCard="uk-export-previousDocuments"
                viewOnly={viewOnly}
                path="previousDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={11}
                initialValues={{ categoryCode: '', typeCode: '', id: '', lineNumeric: '' }}
                list={(list) => [
                    { field: t('previousDocumentCategory'), value: list?.categoryCode },
                    { field: t('previousDocumentType'), value: list?.typeCode },
                    { field: t('previousDocumentReference'), value: list?.id },
                    { field: t('previousDocumentCode'), value: list?.lineNumeric },
                ]}
                condensed
                hidden={isFormType([])}
                refNumber="2/1"
            >
                {(path) => (
                    <FormCardContainer>
                        <PreviousDocumentsBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('AdditionalSupplyChain')}
                keyCard="uk-export-additional-supply-chain-actor"
                viewOnly={viewOnly}
                path="aeoMutualRecognitionParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={12}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('identificationNumber'), value: list?.id },
                    { field: t('roleCode'), value: list?.roleCode },
                ]}
                formik={formik}
                condensed
                hidden={isFormType([])}
                refNumber="3/37"
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalSupplyChainActorBlock
                            path={path}
                            declarationOptions={{
                                UK_EXPORT_B1: {
                                    fieldOptions: { id: { required: true }, roleCode: { required: true } },
                                },
                            }}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('AddFiscalRefIdentification')}
                keyCard="uk-export-additional-references"
                viewOnly={viewOnly}
                path="domesticDutyTaxParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={13}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('vatIdentificationNumber'), value: list?.id },
                    { field: t('roleCode'), value: list?.roleCode },
                ]}
                formik={formik}
                condensed
                hidden={isFormType([])}
                refNumber="3/40"
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalFiscalReferencesBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <DutyTaxFeeCard
                viewOnly={viewOnly}
                keyCard={`calculation-of-taxes-card`}
                propsPathPrefix="commodity.dutyTaxFee"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={17}
            />

            <AdditionsDeductionsCard
                propsPathPrefix="customsValuation.chargeDeduction"
                viewOnly={viewOnly}
                keyCard={`uk-import-addition-deductions`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
                hidden={isFormType(['EXPORT_B1', 'IMPORT_H2'])}
            />

            <ValuationIndicatorsCard
                keyCard="valuation-indicators"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
                hidden={isFormType(['EXPORT_B1', 'IMPORT_H2'])}
            />

            <ItemPriceCard
                keyCard="item-price"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
                hidden={isFormType(['EXPORT_B1', 'IMPORT_H2'])}
            />

            <ValuationMethodCard
                keyCard="valuation-method"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
                hidden={isFormType(['EXPORT_B1', 'IMPORT_H2'])}
            />

            <MultipleItemsCard
                title={t('countryOfOrigin')}
                keyCard="uk-export-country-of-origin"
                viewOnly={viewOnly}
                path="origin"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={14}
                initialValues={{ countryCode: '', typeCode: '' }}
                list={(list) => [
                    { field: t('countryOfOriginCountryCode'), value: list?.countryCode },
                    { field: t('countryOfOriginRole'), value: list?.typeCode },
                ]}
                formik={formik}
                condensed
                hidden={isFormType([])}
                refNumber={['5/15', '5/16']}
            >
                {(path) => (
                    <FormCardContainer>
                        <CountryOfOriginBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <TransportEquipmentCard
                isProduct
                propsPathPrefix="commodity.transportEquipment"
                viewOnly={viewOnly}
                keyCard={`uk-export-container-identification-number`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={15}
            />
        </FormikProvider>
    );
};
export default UkProductsSection;
