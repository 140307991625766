import PhoneNumber from 'awesome-phonenumber';
import { FormItem } from 'components/ui/base/form';
import Notification from 'components/ui/base/notification/Notification';
import { TextSmall } from 'components/ui/base/typography/Typography.styles';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import ProfilePicture from 'components/ui/composed/imageUpload/ProfilePicture';
import { enumToText } from 'core/utils/enum-to-text';
import { useFormik } from 'formik';
import useCountries from 'hooks/useCountries';
import useCustomers from 'hooks/useCustomers';
import { useProfilePicture } from 'hooks/useProfilePicture';
import useSession from 'hooks/useSession';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    deleteCustomerProfilePicture,
    getCustomerProfilePictureDownloadPreSign,
    getCustomerProfilePictureUploadPreSign,
    uploadCustomerProfilePicture,
} from 'store/customers/client';
import { Customer, customerTypeLabels } from 'store/customers/customer';
import { isAdmin } from 'store/individuals/utils';
import CustomerAddressForm from 'views/customers/components/CustomerAddressForm';
import { StyledCard, StyledInfoDiv } from '../../Settings.styles';
import FormFooter from '../FormFooter';
import { Label, StyledForm, StyledFormItem, Title } from './CompanyDetails.styles';
import companyValidateSchema from './companyValidateSchema';

const CompanyDetails: FC = () => {
    const { userInfo } = useSession();
    const customerId = userInfo?.customerId;
    const { customer, editCustomer, error, isLoading } = useCustomers({ customerId });
    const { t } = useTranslation('settings');
    const [edit, setEdit] = useState<boolean>(false);
    const [editClicked, setEditClicked] = useState<boolean>(false);
    const { getNameFromCountryCode } = useCountries();

    const formik = useFormik({
        initialValues: customer,
        enableReinitialize: true,
        validationSchema: companyValidateSchema,
        onSubmit: (values) => handleSubmit(values),
    });

    useEffect(() => {
        if (editClicked) {
            setEditClicked(false);
            if (!error && !isLoading) {
                setEdit(false);
                Notification({
                    type: 'success',
                    messageTitle: t('companyDetails.editSuccessTitle'),
                    description: t('companyDetails.editSuccessMessage'),
                });
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('companyDetails.editErrorTitle'),
                    description: t('companyDetails.editErrorMessage'),
                });
            }
        }
    }, [editClicked, isLoading, error, t]);

    const handleSubmit = async (values: Customer) => {
        let body = { ...values };
        if (values.contact?.phoneCountryCode && values.contact?.phoneNumber) {
            const contact = { ...values.contact };
            body = {
                ...body,
                contact: {
                    ...contact,
                    phoneNumber: `${values.contact.phoneCountryCode}${values.contact.phoneNumber}`,
                },
            };
        }

        await editCustomer(customer.id, body);
        setEditClicked(true);
    };

    const companyDetailsView = useMemo(() => {
        const handleCancel = () => {
            setEdit(false);
        };

        const handleSave = async () => {
            await formik.validateForm();
            await formik.submitForm();
        };

        const handleEdit = () => {
            if (customer.contact?.phoneNumber) {
                const phone = new PhoneNumber(customer.contact.phoneNumber);
                const data = {
                    ...customer,
                    contact: {
                        ...customer.contact,
                        phoneCountryCode: `+${phone.getCountryCode().toString()}`,
                        phoneNumber: phone.getNumber('significant'),
                    },
                };
                formik.setValues(data);
            }
            setEdit(true);
        };

        if (!edit) {
            return (
                <StyledForm layout="vertical">
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.name')}:`}</Label>
                        <TextSmall>{customer?.name ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.type')}:`}</Label>
                        <TextSmall>{enumToText(customer?.type || '')}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.eori')}:`}</Label>
                        <TextSmall>{customer?.eori ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.tan')}:`}</Label>
                        <TextSmall>{customer?.traderAccountNumber ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.dan')}:`}</Label>
                        <TextSmall>{customer?.deferredAccountNumber ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.addressLine1')}:`}</Label>
                        <TextSmall>{customer?.address?.addressLine1 ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.addressLine2')}:`}</Label>
                        <TextSmall>{customer?.address?.addressLine2 ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.postCode')}:`}</Label>
                        <TextSmall>{customer?.address?.postCode ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.city')}:`}</Label>
                        <TextSmall>{customer?.address?.city ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.country')}:`}</Label>
                        <TextSmall>{getNameFromCountryCode(customer?.address?.country) ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.email')}:`}</Label>
                        <TextSmall>{customer?.contact?.email ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <Label>{`${t('companyDetails.phone')}:`}</Label>
                        <TextSmall>{customer?.contact?.phoneNumber ?? '-'}</TextSmall>
                    </StyledInfoDiv>
                    <FormItem style={{ textAlign: 'right' }}>
                        <FormFooter edit={edit} onCancel={handleCancel} onSave={handleSave} onEdit={handleEdit} />
                    </FormItem>
                </StyledForm>
            );
        } else {
            return (
                <StyledForm layout="vertical">
                    <FormInput
                        required
                        label={t('companyDetails.name')}
                        fieldProps={formik.getFieldProps('name')}
                        fieldMeta={formik.getFieldMeta('name')}
                    />
                    <FormSelect
                        hideKeys
                        label={t('companyDetails.type')}
                        selectOptions={customerTypeLabels}
                        fieldProps={formik.getFieldProps('type')}
                    />
                    <FormInput
                        label={t('companyDetails.eori')}
                        fieldMeta={formik.getFieldMeta('eori')}
                        fieldProps={formik.getFieldProps('eori')}
                        fieldHelper={formik.getFieldHelpers('eori')}
                    />
                    <FormInput
                        label={t('companyDetails.tan')}
                        fieldMeta={formik.getFieldMeta('traderAccountNumber')}
                        fieldProps={formik.getFieldProps('traderAccountNumber')}
                        tooltip={t('common.trader_account_number_tooltip')}
                    />
                    <FormInput
                        required
                        label={t('companyDetails.dan')}
                        fieldMeta={formik.getFieldMeta('deferredAccountNumber')}
                        fieldProps={formik.getFieldProps('deferredAccountNumber')}
                        tooltip={t('common.deferred_account_number_tooltip')}
                    />

                    <CustomerAddressForm
                        required={{
                            addressLine1: true,
                            postCode: true,
                            city: true,
                            country: true,
                        }}
                        fieldProps={{
                            addressLine1: formik.getFieldProps('address.addressLine1'),
                            addressLine2: formik.getFieldProps('address.addressLine2'),
                            postCode: formik.getFieldProps('address.postCode'),
                            city: formik.getFieldProps('address.city'),
                            country: formik.getFieldProps('address.country'),
                        }}
                        fieldMeta={{
                            addressLine1: formik.getFieldMeta('address.addressLine1'),
                            addressLine2: formik.getFieldMeta('address.addressLine2'),
                            postCode: formik.getFieldMeta('address.postCode'),
                            city: formik.getFieldMeta('address.city'),
                            country: formik.getFieldMeta('address.country'),
                        }}
                        fieldHelper={{
                            addressLine1: formik.getFieldHelpers('address.addressLine1'),
                            addressLine2: formik.getFieldHelpers('address.addressLine2'),
                            postCode: formik.getFieldHelpers('address.postCode'),
                            city: formik.getFieldHelpers('address.city'),
                            country: formik.getFieldHelpers('address.country'),
                        }}
                        label={{
                            addressLine1: t('companyDetails.addressLine1'),
                            addressLine2: t('companyDetails.addressLine2'),
                            postCode: t('companyDetails.postCode'),
                            city: t('companyDetails.city'),
                            country: t('companyDetails.country'),
                        }}
                    />
                    <FormInput
                        label={t('companyDetails.email')}
                        fieldProps={formik.getFieldProps('contact.email')}
                        fieldMeta={formik.getFieldMeta('contact.email')}
                    />
                    <FormPhoneNumber
                        fieldCodeProps={formik.getFieldProps('contact.phoneCountryCode')}
                        fieldCodeMeta={formik.getFieldMeta('contact.phoneCountryCode')}
                        fieldNumberProps={formik.getFieldProps('contact.phoneNumber')}
                        fieldNumberMeta={formik.getFieldMeta('contact.phoneNumber')}
                    />

                    <StyledFormItem>
                        <FormFooter edit={edit} onCancel={handleCancel} onSave={handleSave} onEdit={handleEdit} />
                    </StyledFormItem>
                </StyledForm>
            );
        }
    }, [edit, formik, t, customer, getNameFromCountryCode]);

    const { profilePicture, handleProfilePictureDelete, handleProfilePictureUpload } = useProfilePicture({
        getPicture: () => getCustomerProfilePictureDownloadPreSign(customerId!),
        getPreSign: (file) =>
            getCustomerProfilePictureUploadPreSign(customerId!, {
                customerId: customerId!,
                pictureName: file.name,
                pictureSize: file.size,
            }),
        deletePicture: () => deleteCustomerProfilePicture(customerId!),
        uploadPicture: (preSignUrl) => uploadCustomerProfilePicture(customerId!, preSignUrl),
    });

    return (
        <>
            <StyledCard>
                <Title>{t('settings.companyDetails')}</Title>
                <ProfilePicture
                    profilePicture={profilePicture}
                    onUpload={handleProfilePictureUpload}
                    onDelete={handleProfilePictureDelete}
                    viewOnly={userInfo?.role && !isAdmin(userInfo.role)}
                    style={{ marginBottom: 20 }}
                />
                {companyDetailsView}
            </StyledCard>
        </>
    );
};
export default CompanyDetails;
