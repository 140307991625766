import { Switch } from 'antd';
import { H5, H6 } from 'components/ui/base/typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    BusinessRulesStatus,
    CreateBusinessRuleStatusPayload,
    getBusinessRulesValidationStatus,
    setAllBusinessRulesValidationStatus,
    setBusinessRulesValidationStatus,
    UpdateBusinessRuleStatusPayload,
} from 'store/business-rules/client';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { MessageTypes, MessageTypesMerge } from 'store/declarations/enums/common/declaration-types';
import { toTitleCase } from 'views/declarations/utils/validation-utils';
import { StyledInfoDiv } from '../Settings.styles';
import { Label } from './company-details/CompanyDetails.styles';

const data: Partial<Record<DeclarationExternalEntity, Partial<Record<DeclarationInternalType, MessageTypes[]>>>> = {
    [DeclarationExternalEntity.REVENUE]: {
        [DeclarationInternalType.IMPORT]: [MessageTypesMerge.H1, MessageTypesMerge.H7],
        [DeclarationInternalType.EXPORT]: [
            MessageTypesMerge.B1,
            MessageTypesMerge.B2,
            MessageTypesMerge.B3,
            MessageTypesMerge.B4,
            MessageTypesMerge.C1,
            MessageTypesMerge.C2,
        ],
        [DeclarationInternalType.ARRIVAL]: [MessageTypesMerge.ARRIVAL],
        [DeclarationInternalType.ENS]: [MessageTypesMerge.ENS],
        [DeclarationInternalType.TEMPORARY]: [MessageTypesMerge.G4, MessageTypesMerge.G4G3],
        [DeclarationInternalType.NCTS]: [MessageTypesMerge.D1, MessageTypesMerge.D2, MessageTypesMerge.D4],
    },
    [DeclarationExternalEntity.CDS]: {
        [DeclarationInternalType.IMPORT]: [MessageTypesMerge.H1, MessageTypesMerge.H2],
        [DeclarationInternalType.EXPORT]: [
            MessageTypesMerge.B1,
            MessageTypesMerge.B2,
            MessageTypesMerge.B4,
            MessageTypesMerge.C1,
            MessageTypesMerge.C2,
        ],
        [DeclarationInternalType.GVMS]: [
            MessageTypesMerge.EMPTY_MOVEMENT_GMR,
            MessageTypesMerge.EXEMPTION_MOVEMENT_RECORD,
            MessageTypesMerge.INDIRECT_EXPORT_DECLARATIONS_MOVEMENT_GMR,
            MessageTypesMerge.ORAL_OR_BY_CONDUCT_DECLARATION,
            MessageTypesMerge.STANDARD_MOVEMENT_GMR,
            MessageTypesMerge.TIR_MOVEMENT_GMR,
            MessageTypesMerge.UNACCOMPANIED_ATA_CARNET_CONTAINER_MOVEMENT,
            MessageTypesMerge.UKC,
        ],
    },
};

const BusinessRulesValidationStatusSection = () => {
    const [brValidationStatus, setBrValidationStatus] = useState<BusinessRulesStatus[]>([]);

    const fetchBusinessRulesValidationStatus = async () => {
        const data = await getBusinessRulesValidationStatus();
        setBrValidationStatus(data);
    };

    useEffect(() => {
        fetchBusinessRulesValidationStatus();
    }, []);

    const handleAllSwitch = useCallback(async (active: boolean) => {
        const data = await setAllBusinessRulesValidationStatus(active);
        setBrValidationStatus(data);
    }, []);

    const allActive = useMemo(() => {
        return (
            brValidationStatus.find(
                (status) =>
                    (status.declarationExternalEntity as any) === 'ALL' &&
                    (status.declarationInternalType as any) === 'ALL' &&
                    (status.declarationMessageType as any) === 'ALL'
            )?.active ?? true
        );
    }, [brValidationStatus]);

    const handleSwitch = useCallback(
        async (payload: CreateBusinessRuleStatusPayload | UpdateBusinessRuleStatusPayload) => {
            const newStatus: (
                | CreateBusinessRuleStatusPayload
                | UpdateBusinessRuleStatusPayload
                | BusinessRulesStatus
            )[] = [...brValidationStatus];

            if ('id' in payload && payload.id) {
                // update
                const index = newStatus.findIndex((status) => 'id' in status && status.id === payload.id);
                if (index !== 1) newStatus[index] = payload;
            } else {
                // add
                newStatus.push(payload);
            }

            const status = await setBusinessRulesValidationStatus(newStatus);
            setBrValidationStatus(status);
        },
        [brValidationStatus]
    );

    const switches = useMemo(() => {
        return Object.entries(data).map(([declarationExternalEntity, declarationInternalTypeMap]) => {
            return (
                <div key={declarationExternalEntity}>
                    <H6 style={{ fontSize: '18px' }}>
                        {declarationExternalEntity === DeclarationExternalEntity.REVENUE ? 'Ireland' : 'UK'}
                    </H6>
                    <ul style={{ listStyleType: 'none', paddingLeft: '2rem' }}>
                        {Object.entries(declarationInternalTypeMap).map(([declarationInternalType, messageTypes]) => {
                            return (
                                <li>
                                    <H6>{toTitleCase(declarationInternalType)}</H6>
                                    <ul
                                        key={declarationInternalType}
                                        style={{ listStyleType: 'none', paddingLeft: '2rem' }}
                                    >
                                        {messageTypes.map((messageType) => {
                                            const status = brValidationStatus.find(
                                                (status) =>
                                                    status.declarationExternalEntity === declarationExternalEntity &&
                                                    status.declarationInternalType === declarationInternalType &&
                                                    status.declarationMessageType === messageType
                                            );

                                            return (
                                                <li>
                                                    <StyledInfoDiv key={`${declarationInternalType}-${messageType}`}>
                                                        <Label>{messageType}</Label>
                                                        <Switch
                                                            checked={status?.active ?? allActive ?? true}
                                                            onChange={(active: boolean) =>
                                                                handleSwitch({
                                                                    id: status?.id,
                                                                    declarationExternalEntity:
                                                                        declarationExternalEntity as DeclarationExternalEntity,
                                                                    declarationInternalType:
                                                                        declarationInternalType as DeclarationInternalType,
                                                                    declarationMessageType: messageType,
                                                                    active,
                                                                })
                                                            }
                                                        />
                                                    </StyledInfoDiv>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        });
    }, [brValidationStatus, handleSwitch, allActive]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <H5>Business Rules Validations</H5>
            <StyledInfoDiv>
                <Label>All</Label>
                <Switch checked={allActive} onChange={handleAllSwitch} />
            </StyledInfoDiv>

            {switches}
        </div>
    );
};

export default BusinessRulesValidationStatusSection;
