import PhoneNumber from 'awesome-phonenumber';

export const extractPhoneNumber = (phoneNumber: string | null | undefined) => {
    if (!phoneNumber) return { countryCode: '', phoneNumber: '' };
    const phone = new PhoneNumber(phoneNumber);

    return {
        countryCode: `+${phone.getCountryCode().toString()}`,
        phoneNumber: phone.getNumber('significant'),
    };
};
