import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProducedDocumentsWritingOffHeader } from 'store/declarations/common/produced-document-writing-off-header';
import { SimplifiedDeclarationDocumentWritingOffHeader } from 'store/declarations/common/simplified-declaration-document-writing-off-header';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import addPathPrefix from 'utils/addPathPrefix';
import { getBox44Path, updateBox44 } from 'views/declarations/common/box44/box-44-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import { getFormikProps, getFullCodelistValue, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import { validators } from 'views/declarations/uk/export/validations/validations';

enum PreviousDocumentType {
    COMMERCIAL_INVOICE = '380',
}

const PreviousDocumentInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    const { aisCodelists } = useCodelists();

    const box44Items = useMemo(() => {
        const path = getBox44Path(props.declarationType!);
        return (props.getFieldProps(path).value ?? {}) as Record<string, string[]>;
    }, [props]);

    const box44ItemsHelper = useMemo(() => {
        const path = getBox44Path(props.declarationType!);
        return props.getFieldHelpers!(path);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.declarationType]);

    const updateBox44Field = useCallback(
        (field: Required<Omit<ProducedDocumentsWritingOffHeader, 'id'>>) => {
            const box44 = updateBox44(field, box44Items);
            box44ItemsHelper.setValue(box44);
        },
        [box44Items, box44ItemsHelper]
    );

    const addItem = (values: SimplifiedDeclarationDocumentWritingOffHeader) => {
        if (
            values.previousDocumentType === PreviousDocumentType.COMMERCIAL_INVOICE &&
            props.declarationType === DeclarationName.H1
        ) {
            const box44Item = {
                documentType: 'N935',
                documentIdentifier: values.previousDocumentIdentifier!,
            };
            updateBox44Field(box44Item);
        }
    };

    const handleDelete = (index: number) => {
        const formikProps = getFormikProps(`simplifiedDeclarationDocumentWritingOff`, props);
        const list = formikProps.fieldProps.value as SimplifiedDeclarationDocumentWritingOffHeader[];
        const item = list.find((obj, i) => i === index);
        if (
            item &&
            item.previousDocumentType === PreviousDocumentType.COMMERCIAL_INVOICE &&
            props.declarationType === DeclarationName.H1
        ) {
            const box44ItemsCopy = { ...box44Items };
            const index = box44ItemsCopy['N935'].indexOf(item.previousDocumentIdentifier ?? '');
            box44ItemsCopy['N935'].splice(index, 1);
            if (!box44ItemsCopy['N935'].length) delete box44ItemsCopy[item.previousDocumentType];
            box44ItemsHelper.setValue(box44ItemsCopy);
        }
    };

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path="goodsShipment.simplifiedDeclarationDocumentWritingOff"
            initialValues={{ previousDocumentType: '', previousDocumentIdentifier: '', previousDocumentLineId: '' }}
            list={(list) => [
                {
                    field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentType'),
                    value: getFullCodelistValue(list?.previousDocumentType, aisCodelists?.previousDocumentType),
                },
                {
                    field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier'),
                    value: list?.previousDocumentIdentifier,
                },
                {
                    field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId'),
                    value: list?.previousDocumentLineId,
                },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={t('previousDocument')}
            onDelete={handleDelete}
            onAdd={addItem}
            condensed
            refNumber="2.1"
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'previousDocumentType'), formProps)}
                        refNumber="2.1"
                        label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')}
                        selectOptions={aisCodelists?.previousDocumentType}
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '2.1',
                            t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')
                        )}
                        condensed
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        codeValidation={[validators.maxLength(5)]}
                    />
                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'previousDocumentIdentifier'), formProps)}
                        refNumber="2.1"
                        label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')}
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '2.1',
                            t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')
                        )}
                        condensed
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                    />
                    <FormInput
                        numbersAndLetters
                        maxLength={5}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'previousDocumentLineId'), formProps)}
                        refNumber="2.1"
                        label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')}
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '2.1',
                            t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')
                        )}
                        condensed
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default PreviousDocumentInformationCard;
