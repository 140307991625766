import { useState } from 'react';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Image, Upload, notification, Spin } from 'antd';

import styles from './ProfilePicture.module.css';

const ProfilePicture = (props: {
    onUpload?: (file: File) => void | Promise<void>;
    onDelete?: () => void | Promise<void>;
    profilePicture: string | null | undefined;
    viewOnly?: boolean;
    style?: React.CSSProperties;
}) => {
    const [pictureStatus, setPictureStatus] = useState<{
        loading: boolean;
        action: 'none' | 'uploading' | 'deleting';
    }>({ loading: false, action: 'none' });

    const [picturePreview, setPicturePreview] = useState<{
        visible: boolean;
        preview: string | null;
    }>({ visible: false, preview: null });

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {pictureStatus.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const handleUpload = async (file: any) => {
        setPictureStatus({ loading: true, action: 'uploading' });
        try {
            await props.onUpload?.(file);

            notification.success({
                message: 'Success',
                description: 'Profile picture uploaded successfully',
            });
        } catch (e: any) {
            notification.error({ message: e.message });
        } finally {
            setPictureStatus({ loading: false, action: 'none' });
        }
    };

    const handleDelete = async () => {
        setPictureStatus({ loading: true, action: 'deleting' });
        try {
            await props.onDelete?.();

            notification.success({
                message: 'Success',
                description: 'Profile picture deleted successfully',
            });
        } catch (e: any) {
            notification.error({ message: e.message });
        } finally {
            setPictureStatus({ loading: false, action: 'none' });
        }
    };

    const handlePreview = async (file: any) => {
        const getBase64 = (file: any) =>
            new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            });

        if (!file.url && !file.preview) {
            // eslint-disable-next-line no-param-reassign
            file.preview = await getBase64(file.originFileObj);
        }

        setPicturePreview({ visible: true, preview: file.url || file.preview });
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%', ...props.style }}>
            <div className={`${styles.pictureContainer} ${props.profilePicture ? styles.hideUpload : ''}`}>
                {pictureStatus.action !== 'deleting' ? (
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        fileList={
                            props.profilePicture
                                ? [
                                      {
                                          uid: '1',
                                          name: '',
                                          status: 'done',
                                          url: props.profilePicture,
                                      },
                                  ]
                                : []
                        }
                        disabled={props.viewOnly}
                        beforeUpload={handleUpload}
                        onRemove={handleDelete}
                        onPreview={handlePreview}
                    >
                        {uploadButton}
                    </Upload>
                ) : (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                )}

                {picturePreview.preview && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: picturePreview.visible,
                            onVisibleChange: (visible) => setPicturePreview((prev) => ({ ...prev, visible })),
                            // afterOpenChange: (visible) =>
                            //     !visible && setPicturePreview((prev) => ({ ...prev, preview: null })),
                        }}
                        alt="Profile Picture"
                        src={picturePreview.preview}
                    />
                )}
            </div>
        </div>
    );
};

export default ProfilePicture;
