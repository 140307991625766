import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button/Button';
import Checkbox, { Group } from 'components/ui/base/checkbox/Checkbox';
import { TextExtraSmall, TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts, layers } from 'theme';

export const FilterCard = styled.div`
    position: absolute;
    margin-top: 2.2rem;
    z-index: ${layers.popup};
    right: 0.8rem;
    width: 41.3rem;
    min-height: 4rem;
    background: ${colors.white};
    border: 1px solid ${colors.darkGrey2};
    /* drop shadow v2 */
    box-shadow: 0px 2px 7px rgba(9, 0, 255, 0.15);
    border-radius: 4px;
    padding: 1.6rem 0;
`;

export const Input = styled.input`
    width: 100%;
    height: 2.4rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    border: none;
    padding: 0;
    margin-bottom: 1.6rem;
    -webkit-appearance: none;
    &:focus {
        outline: none;
    }
`;

export const TitleDiv = styled.div`
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
`;

export const Title = styled(TextExtraSmall)`
    font-weight: ${fonts.weight.regular};
`;

export const Option = styled(TextSmall)`
    padding: 0 1.6rem;
    height: 4rem;
    cursor: pointer;
    &:hover {
        background: ${colors.lightGrey2};
    }
`;

export const SearchIcon = styled(SearchOutlined)`
    margin-right: 1.6rem;
`;

export const FilterHeader = styled.div`
    padding: 0 1.6rem 0 1.6rem;
    border-bottom: 1px solid ${colors.darkGrey2};
`;

export const DateFilter = styled.div`
    padding: 1.6rem 1.6rem 0 1.6rem;
`;

export const FilterButton = styled(Button)`
    margin-right: 0.8rem;
`;

export const CloseIcon = styled(CloseOutlined)`
    margin-left: 1.2rem;
`;

export const FilterGroup = styled(Group)`
    width: 100%;
`;

export const FilterCheckbox = styled(Checkbox)`
    width: 100%;
`;

export const FilterOption = styled.div`
    padding: 0 1.6rem;
    align-items: center;
    vertical-align: middle;
    display: flex;
    height: 4rem;
    cursor: pointer;
    &:hover {
        background: ${colors.lightGrey2};
    }
`;
