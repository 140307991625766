import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';
import { FC } from 'react';
import { BackButton as StyledButton } from '../TaricCode.styles';
interface Props {
    onClick: Function;
}
export const BackButton: FC<Props> = ({ onClick }) => {
    return (
        <StyledButton
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
        >
            <LeftOutlined /> Back
        </StyledButton>
    );
};
export default BackButton;
