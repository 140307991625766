import { forgotPassword, resetPassword, signIn, signUp, updateToken, oauth } from './client';
import { SessionPayload } from './session';
import { LoginCredentials } from './user';

const hasRememberUser = () => (localStorage.getItem('auth') ? true : false);

export const doOauthSignIn: Function =
    () =>
    async (dispatch: Function): Promise<SessionPayload | null> => {
        dispatch({ type: 'USER_SIGN_IN_REQUEST' });

        try {
            const payload = await oauth();
            dispatch({ type: 'USER_SIGN_IN_SUCCESS', payload });

            localStorage.setItem('auth', JSON.stringify(payload));
            return payload;
        } catch (e: any) {
            dispatch({ type: 'USER_SIGN_IN_ERROR', error: e?.response?.data });
            return null;
        }
    };

export const doSignIn: Function =
    (credentials: LoginCredentials) =>
    async (dispatch: Function): Promise<SessionPayload | null> => {
        dispatch({ type: 'USER_SIGN_IN_REQUEST' });

        try {
            const payload = await signIn(credentials);
            dispatch({ type: 'USER_SIGN_IN_SUCCESS', payload });

            if (credentials.remember) {
                localStorage.setItem('auth', JSON.stringify(payload));
            } else {
                sessionStorage.setItem('auth', JSON.stringify(payload));
            }
            return payload;
        } catch (e: any) {
            dispatch({ type: 'USER_SIGN_IN_ERROR', error: e?.response?.data });
            return null;
        }
    };

export const doSignUp: Function = (params: any) => async (dispatch: Function) => {
    dispatch({ type: 'SIGN_UP_REQUEST' });

    try {
        const response = await signUp(params);
        dispatch({ type: 'SIGN_UP_SUCCESS', payload: response });
        return response;
    } catch (e: any) {
        dispatch({ type: 'SIGN_UP_ERROR', error: e?.response?.data });
        return e?.response?.data || e;
    }
};

export const doUpdateToken: Function = (token: string) => async (dispatch: Function) => {
    dispatch({ type: 'REFRESH_TOKEN_REQUEST' });
    try {
        const response = await updateToken(token);
        dispatch({ type: 'REFRESH_TOKEN_SUCCESS', payload: response });
        if (hasRememberUser()) {
            console.log('save token in local storage', response);
            localStorage.setItem('auth', JSON.stringify(response));
        } else {
            console.log('save token in session storage', response);
            sessionStorage.setItem('auth', JSON.stringify(response));
        }

        return response;
    } catch (e: any) {
        console.log(e);
        dispatch({ type: 'REFRESH_TOKEN_ERROR', error: e?.response?.data });
    }
};

// TODO remove any
export const doUpdatePassword: Function = (params: any) => async (dispatch: Function) => {
    dispatch({ type: 'SIGN_UP_REQUEST' });

    try {
        const response = await signUp(params);
        dispatch({ type: 'SIGN_UP_SUCCESS', payload: response });
    } catch (e: any) {
        dispatch({ type: 'SIGN_UP_ERROR', error: e?.response?.data });
    }
};

export const doForgotPassword: Function = (email: string) => async (dispatch: Function) => {
    dispatch({ type: 'FORGOT_PASSWORD_REQUEST' });
    try {
        await forgotPassword(email);
        dispatch({ type: 'FORGOT_PASSWORD_SUCCESS' });
    } catch (e: any) {
        dispatch({ type: 'FORGOT_PASSWORD_ERROR', error: e?.response?.data });
    }
};

export const doResetPassword: Function = (newPassword: string, token: string) => async (dispatch: Function) => {
    dispatch({ type: 'RESET_PASSWORD_REQUEST' });
    try {
        await resetPassword(newPassword, token);
        dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
    } catch (e: any) {
        dispatch({ type: 'RESET_PASSWORD_ERROR', error: e?.response?.data });
    }
};
