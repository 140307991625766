export enum NCTSInformationExchangeMessages {
    CC004C = 'CC004C',
    CC007C = 'CC007C',
    CC009C = 'CC009C',
    CC013C = 'CC013C',
    CC014C = 'CC014C',
    CC015C = 'CC015C',
    CC019C = 'CC019C',
    CC022C = 'CC022C',
    CC023C = 'CC023C',
    CC025C = 'CC025C',
    CC026C = 'CC026C',
    CC028C = 'CC028C',
    CC029C = 'CC029C',
    CC034C = 'CC034C',
    CC035C = 'CC035C',
    CC037C = 'CC037C',
    CC043C = 'CC043C',
    CC044C = 'CC044C',
    CC045C = 'CC045C',
    CC051C = 'CC051C',
    CC054C = 'CC054C',
    CC055C = 'CC055C',
    CC056C = 'CC056C',
    CC057C = 'CC057C',
    CC060C = 'CC060C',
    CC140C = 'CC140C',
    CC141C = 'CC141C',
    CC170C = 'CC170C',
    CC182C = 'CC182C',
    CC224C = 'CC224C',
    CC225C = 'CC225C',
    CC228C = 'CC228C',
    CC229C = 'CC229C',
    CC231C = 'CC231C',
    CC906C = 'CC906C',
    CC917C = 'CC917C',
    CC928C = 'CC928C',
    TR015V = 'TR015V',
    TR054 = 'TR054',
    TR062 = 'TR062',
    TR862 = 'TR862',
    TR064 = 'TR064',
    TR864 = 'TR864',
    TR082 = 'TR082',
    TR083 = 'TR083',
    TR084 = 'TR084',
    TR882 = 'TR882',
    TR884 = 'TR884',
}
