import { DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import { handleNullValues } from 'components/ui/base/table/TableUtils';
import { FC, useCallback, useMemo } from 'react';
import { colors } from 'theme';
import useFormUtils from '../../../../hooks/useFormUtils';
import { TableChangeParams } from '../../../../utils/tableHelpers';
import { ProductsErrors } from '../../sections/products/DeclarationProductsTable';
import { ActionButton, InvalidIcon } from '../../ireland/common/IrelandProductsTable.styles';
import getNCTSProductsTableColumns from '../../ireland/common/columns/NCTSProductsTableColumns';

interface Props {
    data: any | undefined | null;
    onDelete?: (id: string) => void;
    onEdit?: (id: string) => void;
    onAddToTemplate?: (item: string) => void;
    loading?: boolean;
    viewOnly?: boolean;
    errors?: ProductsErrors;
    onChange: (params: TableChangeParams) => void;
}

const UkNctsProductsTable: FC<Props> = ({
    data,
    onDelete,
    onEdit,
    onAddToTemplate,
    loading,
    viewOnly,
    errors,
    onChange,
}) => {
    console.log(data);
    const { formType } = useFormUtils();

    const checkInvalid = useCallback((index: number) => (errors ? !!errors[index] : false), [errors]); //TODO find by IDs

    const getInvalidStyle = useCallback(
        (index: number) => {
            return checkInvalid(index) ? { style: { background: `${colors.invalid}`, border: 'none' } } : {};
        },
        [checkInvalid]
    );

    const handleTableValues = useCallback(
        (index: number, value?: string | number) => {
            const style = getInvalidStyle(index);
            return { props: style, children: handleNullValues(value) };
        },
        [getInvalidStyle]
    );

    const handleInvalidIcon = useCallback(
        (index: number) => {
            const style = getInvalidStyle(index);
            return checkInvalid(index)
                ? {
                      props: style,
                      children: <InvalidIcon />,
                  }
                : { props: style, children: <></> };
        },
        [getInvalidStyle, checkInvalid]
    );

    const columns: ColumnsType<any> = useMemo(() => {
        return getNCTSProductsTableColumns(handleTableValues);
    }, []);

    const commandsColumn: ColumnsType<any> = [
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (text, record: any, index: number) => ({
                props: getInvalidStyle(index),
                children: (
                    <>
                        {viewOnly ? (
                            formType !== 'ARRIVAL' && (
                                <ActionButton
                                    invalid={checkInvalid(index)}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onAddToTemplate?.(record.id!);
                                    }}
                                >
                                    Add to templates
                                </ActionButton>
                            )
                        ) : (
                            <>
                                {formType !== 'ARRIVAL' && (
                                    <ActionButton
                                        size="small"
                                        invalid={checkInvalid(index)}
                                        marginRight="1.2"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onAddToTemplate?.(record.id!);
                                        }}
                                    >
                                        Add to templates
                                    </ActionButton>
                                )}
                                <ActionButton
                                    size="small"
                                    invalid={checkInvalid(index)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete && onDelete(record.id!);
                                    }}
                                >
                                    Delete <DeleteOutlined />
                                </ActionButton>
                            </>
                        )}
                    </>
                ),
            }),
        },
    ];

    const columnIcon: ColumnsType<any> = [
        {
            dataIndex: 'icon',
            key: 'icon',
            render: (text, record, index: number) => handleInvalidIcon(index),
        },
    ];

    const rowSelection: TableRowSelection<any> = {
        renderCell: (value, record, index: number, originNode: React.ReactNode) => {
            return { props: getInvalidStyle(index), children: originNode };
        },
    };

    const templateColumns = useMemo(() => {
        if (onAddToTemplate) {
            return [...columns, ...commandsColumn, ...columnIcon];
        }

        return [...columns, ...columnIcon];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onAddToTemplate]);

    return (
        <Table
            rowKey="id"
            dataSource={data?.list}
            columns={templateColumns}
            loading={loading}
            rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        event.stopPropagation();
                        return onEdit && onEdit(record.id!);
                    },
                };
            }}
            onChange={(pagination, _, sorter) => onChange({ pagination, sorter })}
            pagination={{
                current: (data?.pageNumber || 0) + 1,
                total: data?.total,
                pageSize: data?.pageSize,
                position: ['bottomCenter'],
            }}
        />
    );
};
export default UkNctsProductsTable;
