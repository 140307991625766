import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import useDeclarations from '../../../hooks/useDeclarations';

const Refunds: FC<{}> = () => {
    const { declaration } = useDeclarations();
    return (
        <Outlet
            context={{
                declaration: declaration,
            }}
        />
    );
};
export default Refunds;
