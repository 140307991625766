import { FormikProps, FormikProvider } from 'formik';
import { FC, useMemo, useState } from 'react';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import { getFormikProps, getFullCodelistValue } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import useTooltips from 'hooks/useTooltips';
import FormSelect from 'components/ui/composed/declarations/formSelect';
import { validators } from 'views/declarations/uk/export/validations/validations';
import useCodelists from 'hooks/useCodelists';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import { packaging, simplifiedDeclarationDocumentWritingOffItem } from '../h1/validation/ValidationSchema';
import FormInput from 'components/ui/composed/declarations/formInput';
import addPathPrefix from 'utils/addPathPrefix';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import TaricCodeDrawer from 'views/declarations/common/taric-codes/TaricCodeDrawer';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { TaricCodesType } from 'store/taric-codes/taric-code';
import { TaricDetailsType } from 'views/declarations/common/taric-codes/TaricCodesCard';
import AutoFillModal from '../h1/components/AutoFillModal';
import { set } from 'lodash';

interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    selectCard?: (id: string) => void;
    updateTaricCode: (taricCode: string) => void;
    country: DeclarationCountry;
    detailsType: TaricDetailsType;
    productTemplate?: boolean;
}

const IrelandI1ProductSection: FC<Props> = ({
    formik,
    viewOnly,
    country,
    updateTaricCode,
    detailsType,
    productTemplate,
}) => {
    const { aisCodelists } = useCodelists();
    const { t } = useTranslation('form');
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);

    const openModal = (e: any) => {
        setOpen(true);
        setValue(e?.target?.value);
    };
    const closeModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };

    const { getH1TooltipsByRefNumberAndField, getH1TooltipsByField } = useTooltips();

    const [isTaricCodeDrawerVisible, setIsTaricCodeDrawerVisible] = useState(false);

    // Taric Code Drawer
    const taricCodeDrawer = useMemo(() => {
        const taricCodeType = country === DeclarationCountry.UK ? TaricCodesType.UK : TaricCodesType.EU;
        return (
            <TaricCodeDrawer
                visible={isTaricCodeDrawerVisible}
                onClose={() => setIsTaricCodeDrawerVisible(false)}
                selectedCommodityCode={(taricCode) => {
                    updateTaricCode(taricCode);
                    setIsTaricCodeDrawerVisible(false);
                }}
                taricCodeType={taricCodeType}
                detailsType={productTemplate ? TaricDetailsType.BOTH : TaricDetailsType.IMPORT}
            />
        );
    }, [isTaricCodeDrawerVisible, detailsType, country, updateTaricCode, productTemplate]);
    // Taric Code Drawer - END

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => set(acc, field, value), values);
        formik?.setValues(newValues);
    };

    return (
        <FormikProvider value={formik}>
            <NewFormCard title={t('goodsItems.title')}>
                <FormCardContainer>
                    <FormSelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`preference`, formik)}
                        refNumber="4.17"
                        label={t('preference')}
                        selectOptions={aisCodelists?.preference}
                        tooltip={getH1TooltipsByRefNumberAndField('4.17', t('preference'))}
                        condensed
                        codeValidation={[validators.exact(3)]}
                    />

                    <FormInput
                        required
                        maxLength={8}
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsInformation.combinedNomenclatureCode`, formik)}
                        refNumber="6.14"
                        label={t('commodityCode')}
                        tooltip={getH1TooltipsByRefNumberAndField('6.14', t('commodityCode'))}
                        onSearchTaricCode={() => setIsTaricCodeDrawerVisible(true)}
                        condensed
                    />

                    <FormInput
                        required
                        maxLength={2}
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsInformation.taricCode`, formik)}
                        refNumber="6.15"
                        label={t('goodsItems.taricCode')}
                        tooltip={getH1TooltipsByRefNumberAndField('6.15', t('goodsItems.taricCode'))}
                        condensed
                    />

                    <MultipleDeclarationField name="goodsInformation.taricAdditionalCode" max={2}>
                        {(fieldProps, controls) => {
                            return (
                                <FormInput
                                    maxLength={4}
                                    viewOnly={viewOnly}
                                    refNumber="6.16"
                                    label={t('comodityCodeTaricCode')}
                                    tooltip={getH1TooltipsByRefNumberAndField('6.14', t('comodityCodeTaricCode'))}
                                    {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                    condensed
                                    multipleF={controls}
                                />
                            );
                        }}
                    </MultipleDeclarationField>

                    <FormInput
                        required
                        maxLength={512}
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsInformation.goodsDescription`, formik)}
                        refNumber="6.8"
                        label={t('goodsItems.goodsDescription')}
                        tooltip={getH1TooltipsByRefNumberAndField('6.8', t('goodsItems.goodsDescription'))}
                        condensed
                    />

                    <DeclarationNumberInput
                        integerNumbers
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsInformation.supplementaryUnits`, formik)}
                        refNumber="6.2"
                        label={t('goodsItems.supplementaryUnits')}
                        refTooltip={getH1TooltipsByRefNumberAndField('6.2', t('goodsItems.supplementaryUnits'))}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path={`goodsInformation.packaging`!}
                initialValues={{ packageNumber: '', typePackage: '', shippingMarks: '' }}
                list={(list) => [
                    {
                        field: t('packages.packageType'),
                        value: getFullCodelistValue(list.typePackage, aisCodelists?.kindOfPackages),
                    },
                    { field: t('packages.numberOfPackages'), value: list.packageNumber },
                    { field: t('packages.shippingMarks'), value: list.shippingMarks },
                ]}
                viewOnly={viewOnly}
                keyCard={`package-card`}
                title={t('packages.title')}
                condensed
                validationSchema={packaging()}
                refNumber={['6.09', '6.10', '6.11']}
            >
                {(path) => (
                    <FormCardContainer oneColumn>
                        <FormSelect
                            required
                            viewOnly={viewOnly}
                            refNumber="6.9"
                            label={t('packages.packageType')}
                            {...getFormikProps(addPathPrefix(path, 'typePackage'), formik)}
                            selectOptions={aisCodelists?.kindOfPackages}
                            tooltip={getH1TooltipsByRefNumberAndField('6.9', t('packages.packageType'))}
                            condensed
                            codeValidation={[validators.maxLength(2)]}
                        />

                        <DeclarationNumberInput
                            required
                            viewOnly={viewOnly}
                            refNumber="6.10"
                            {...getFormikProps(addPathPrefix(path, 'packageNumber'), formik)}
                            label={t('packages.numberOfPackages')}
                            refTooltip={getH1TooltipsByRefNumberAndField('6.10', t('packages.numberOfPackages'))}
                            condensed
                        />

                        <FormInput
                            required
                            maxLength={512}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'shippingMarks'), formik)}
                            refNumber="6.11"
                            label={t('packages.shippingMarks')}
                            tooltip={getH1TooltipsByRefNumberAndField('6.11', t('packages.shippingMarks'))}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title={t('Item Details')}>
                <FormCardContainer>
                    <AutoFillModal
                        visible={open}
                        value={value}
                        prevValue={prevValue}
                        fields={[
                            {
                                name: 'countryPreferentialOrigin',
                                label: t('countryPreferentialOrigin'),
                            },
                        ]}
                        onCancel={closeModal}
                        onOk={autoFill}
                    />
                    <FormSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`procedureCode`, formik)}
                        refNumber="1.10"
                        label={t('requestedProcedure')}
                        selectOptions={aisCodelists?.procedureCode}
                        tooltip={getH1TooltipsByRefNumberAndField('1.10', t('requestedProcedure'))}
                        condensed
                        codeValidation={[validators.exact(2)]}
                    />

                    <FormSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`previousProcedureCode`, formik)}
                        refNumber="1.10"
                        label={t('previousProcedure')}
                        tooltip={t('defaultTooltip')}
                        selectOptions={aisCodelists?.procedureCode}
                        condensed
                        codeValidation={[validators.exact(2)]}
                    />

                    <DeclarationNumberInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsInformation.grossMass`, formik)}
                        refNumber="6.5"
                        label={t('goodsItems.grossMass')}
                        refTooltip={getH1TooltipsByRefNumberAndField('6.5', t('goodsItems.grossMass'))}
                        condensed
                    />

                    <MultipleDeclarationField parent="additionalProcedure" name="additionalProcedure">
                        {(fieldProps, controls) => (
                            <FormSelect
                                viewOnly={viewOnly}
                                refNumber="1.11"
                                label={t('additionalProcedure')}
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                selectOptions={aisCodelists?.additionalProcedure}
                                tooltip={getH1TooltipsByField(t('additionalProcedure'))}
                                condensed
                                multipleF={controls}
                                codeValidation={[validators.exact(3)]}
                            />
                        )}
                    </MultipleDeclarationField>

                    <DeclarationNumberInput
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsInformation.netMass`, formik)}
                        refNumber="6.1"
                        label={t('goodsItems.netMass')}
                        refTooltip={getH1TooltipsByRefNumberAndField('6.1', t('goodsItems.netMass'))}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path="simplifiedDeclarationDocumentWritingOff"
                initialValues={{
                    previousDocumentType: '',
                    previousDocumentIdentifier: '',
                    previousDocumentLineId: '',
                }}
                list={(list) => [
                    {
                        field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentType'),
                        value: getFullCodelistValue(list.previousDocumentType, aisCodelists?.previousDocumentType),
                    },
                    {
                        field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier'),
                        value: list.previousDocumentIdentifier,
                    },
                    {
                        field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId'),
                        value: list.previousDocumentLineId,
                    },
                ]}
                viewOnly={viewOnly}
                keyCard={`simplified-declaration-document-writing-off-item-card`}
                title={t('simplifiedDeclarationDocumentWritingOff.title')}
                condensed
                refNumber="2.1"
                validationSchema={simplifiedDeclarationDocumentWritingOffItem}
            >
                {(path) => (
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentType'), formik)}
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')}
                            selectOptions={aisCodelists?.previousDocumentType}
                            refNumber="2.1"
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')
                            )}
                            condensed
                            codeValidation={[validators.maxLength(5)]}
                        />

                        <FormInput
                            numbersAndLetters
                            maxLength={35}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentIdentifier'), formik)}
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')}
                            refNumber="2.1"
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')
                            )}
                            condensed
                        />

                        <FormInput
                            numbersAndLetters
                            maxLength={5}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentLineId'), formik)}
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')}
                            refNumber="2.1"
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')
                            )}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path="additionalInformation"
                initialValues={{ additionalInformationCode: '', additionalInformationText: '' }}
                list={(list) => [
                    {
                        field: t('additionalInformation.code'),
                        value: getFullCodelistValue(
                            list.additionalInformationCode,
                            aisCodelists?.additionalInformationCode
                        ),
                    },
                    {
                        field: t('additionalInformation.description'),
                        value: list.additionalInformationText,
                    },
                ]}
                viewOnly={viewOnly}
                keyCard={`additional-information-card`}
                title={t('additionalInformation.title')}
                condensed
                refNumber="2.2"
            >
                {(path) => (
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationCode'), formik)}
                            refNumber="2.2"
                            label={t('additionalInformation.code')}
                            condensed
                            selectOptions={aisCodelists?.additionalInformationCode}
                            tooltip={getH1TooltipsByRefNumberAndField('2.2', t('additionalInformation.code'))}
                            codeValidation={[validators.exact(5)]}
                        />
                        <FormInput
                            maxLength={512}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationText'), formik)}
                            refNumber="2.2"
                            condensed
                            label={t('additionalInformation.description')}
                            tooltip={getH1TooltipsByRefNumberAndField('2.2', t('additionalInformation.description'))}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                declarationType={DeclarationName.H1}
                parties={[
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: '3.1',
                    },
                    {
                        path: 'buyer',
                        header: 'Buyer',
                        refNumber: '3.26',
                    },
                ]}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                }}
                viewOnly={viewOnly}
                keyCard={`parties-card`}
                condensed
            />

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path={'supplyChainActor'!}
                keyCard={`supply-chain-actor`}
                initialValues={{ roleCode: '', traderIdentification: '' }}
                list={(list) => [
                    {
                        field: t('supplyChainActor.roleCode'),
                        value: getFullCodelistValue(list.roleCode, aisCodelists?.supplyChainActorRoleCode),
                    },
                    {
                        field: t('supplyChainActor.traderIdentification'),
                        value: list.traderIdentification,
                    },
                ]}
                viewOnly={viewOnly}
                title={t('supplyChainActor.title')}
                condensed
                refNumber="3.37"
            >
                {(path) => (
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'roleCode'), formik)}
                            refNumber="3.37"
                            label={t('supplyChainActor.roleCode')}
                            tooltip={getH1TooltipsByRefNumberAndField('3.37', t('supplyChainActor.roleCode'))}
                            selectOptions={aisCodelists?.supplyChainActorRoleCode}
                            condensed
                            codeValidation={[validators.maxLength(3)]}
                        />
                        <FormInput
                            maxLength={18}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'traderIdentification'), formik)}
                            refNumber="3.37"
                            label={t('supplyChainActor.traderIdentification')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '3.37',
                                t('supplyChainActor.traderIdentification')
                            )}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path={'taxBox43Bis'!}
                initialValues={{
                    boxTaxType: '',
                    boxTaxBaseUnit: '',
                    boxAmount: '',
                    boxTaxRate: '',
                    boxTaxPayableAmount: '',
                    boxTaxPaymentMethod: '',
                }}
                list={(list) => [
                    {
                        field: t('boxCalcTaxes.boxTaxType'),
                        value: getFullCodelistValue(list.boxTaxType, aisCodelists?.taxType),
                    },
                    {
                        field: t('dutiesAndTaxes.boxTaxBaseUnit'),
                        value: list.boxTaxBaseUnit,
                    },
                    {
                        field: t('boxCalcTaxes.boxAmount'),
                        value: list.boxAmount,
                    },
                    {
                        field: t('boxCalcTaxes.boxTaxRate'),
                        value: list.boxTaxRate,
                    },
                    {
                        field: t('boxCalcTaxes.boxTaxPayableAmount'),
                        value: list.boxTaxPayableAmount,
                    },
                    {
                        field: t('boxCalcTaxes.boxTaxPaymentMethod'),
                        value: list.boxTaxPaymentMethod,
                    },
                ]}
                viewOnly={viewOnly}
                keyCard={`tax-box`}
                title={t('boxCalcTaxes.title')}
                condensed
                refNumber={['4.3', '4.4', '4.5', '4.6']}
            >
                {(path) => (
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'boxTaxType'), formik)}
                            refNumber="4.3"
                            label={t('boxCalcTaxes.boxTaxType')}
                            selectOptions={aisCodelists?.taxType}
                            tooltip={getH1TooltipsByRefNumberAndField('4.3', t('boxCalcTaxes.boxTaxType'))}
                            condensed
                            codeValidation={[validators.exact(3)]}
                        />
                        <FormInput
                            maxLength={6}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'boxTaxBaseUnit'), formik)}
                            label={t('dutiesAndTaxes.boxTaxBaseUnit')}
                            tooltip={getH1TooltipsByField(t('dutiesAndTaxes.boxTaxBaseUnit'))}
                            condensed
                        />
                        <DeclarationNumberInput
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'boxAmount'), formik)}
                            refNumber="4.4"
                            label={t('boxCalcTaxes.boxAmount')}
                            refTooltip={getH1TooltipsByRefNumberAndField('4.4', t('boxCalcTaxes.boxAmount'))}
                            condensed
                        />
                        <DeclarationNumberInput
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'boxTaxRate'), formik)}
                            refNumber="4.5"
                            label={t('boxCalcTaxes.boxTaxRate')}
                            refTooltip={getH1TooltipsByRefNumberAndField('4.5', t('boxCalcTaxes.boxTaxRate'))}
                            condensed
                        />
                        <DeclarationNumberInput
                            disabled
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'boxTaxPayableAmount'), formik)}
                            refNumber="4.6"
                            label={t('boxCalcTaxes.boxTaxPayableAmount')}
                            refTooltip={getH1TooltipsByRefNumberAndField('4.6', t('boxCalcTaxes.boxTaxPayableAmount'))}
                            condensed
                        />
                        <DeclarationNumberInput
                            disabled
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'boxTaxPaymentMethod'), formik)}
                            refNumber="4.6"
                            label={t('boxCalcTaxes.boxTaxPaymentMethod')}
                            refTooltip={getH1TooltipsByRefNumberAndField('4.6', t('boxCalcTaxes.boxTaxPaymentMethod'))}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title={'Value Details'}>
                <FormCardContainer>
                    <DeclarationNumberInput
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`itemAmount`, formik)}
                        refNumber="4.14"
                        label={t('itemAmount')}
                        refTooltip={getH1TooltipsByRefNumberAndField('4.14', t('itemAmount'))}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title={'Extra item details'}>
                <FormCardContainer>
                    <FormInput
                        maxLength={8}
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsInformation.cusCode`, formik)}
                        refNumber="6.13"
                        label={t('goodsItems.cusCode')}
                        tooltip={getH1TooltipsByRefNumberAndField('6.13', t('goodsItems.cusCode'))}
                        condensed
                    />

                    <DeclarationNumberInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`taxTotalAmount`, formik)}
                        refNumber="4.7"
                        label={t('taxTotalAmount')}
                        refTooltip={getH1TooltipsByRefNumberAndField('4.7', t('taxTotalAmount'))}
                        condensed
                    />

                    <CountrySelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`countryDispatch`, formik)}
                        refNumber="5.14"
                        label={t('countryDispatch')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.14', t('Country of Dispatch'))}
                        condensed
                    />

                    <CountrySelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`countryPreferentialOrigin`, formik)}
                        refNumber="5.16"
                        label={t('countryPreferentialOrigin')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.16', t('countryPreferentialOrigin'))}
                        condensed
                    />

                    <FormInput
                        maxLength={6}
                        viewOnly={viewOnly}
                        {...getFormikProps(`quotaOrderNumber`, formik)}
                        refNumber="8.1"
                        label={t('quotaOrderNumber')}
                        tooltip={getH1TooltipsByRefNumberAndField('8.1', t('quotaOrderNumber'))}
                        condensed
                    />

                    <DeclarationSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`transactionNature`, formik)}
                        refNumber="8.5"
                        label={t('transactionNature')}
                        tooltip={getH1TooltipsByRefNumberAndField('8.5', t('transactionNature'))}
                        selectOptions={aisCodelists?.natureOfTransaction}
                        condensed
                        codeValidation={[validators.maxLength(2)]}
                    />

                    <CountrySelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`countryOrigin`, formik)}
                        refNumber="5.15"
                        label={t('countryOrigin')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.15', t('countryOrigin'))}
                        condensed
                        onChange={openModal}
                    />

                    <FormInput
                        maxLength={35}
                        viewOnly={viewOnly}
                        {...getFormikProps(`ucr`, formik)}
                        refNumber="2.4"
                        label={t('ucr')}
                        tooltip={getH1TooltipsByRefNumberAndField('2.4', t('ucr'))}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title={t('containerIdentificationNumbers')}>
                <FormCardContainer>
                    <MultipleDeclarationField name="containerIdentificationNumber">
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                viewOnly={viewOnly}
                                maxLength={17}
                                refNumber="7.10"
                                label={t('containerIdentificationNumbers')}
                                tooltip={getH1TooltipsByRefNumberAndField('7.10', t('containerIdentificationNumbers'))}
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                condensed
                                multipleF={controls}
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
                {taricCodeDrawer}
            </NewFormCard>
        </FormikProvider>
    );
};
export default IrelandI1ProductSection;
