import { useEffect } from 'react';

import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';
import useDeclarations from '../../../../../hooks/useDeclarations';
import { getDeclarationPayload } from '../../../utils/declaration-utils';

interface Props {
    activeTabKey: DeclarationTabKeys;
}

const useRefetchDeclarationOnTabChangeIfAmendable = ({ activeTabKey }: Props) => {
    const { getDeclaration, declaration } = useDeclarations();

    const declarationPayload = declaration ? getDeclarationPayload(declaration) : {};

    useEffect(() => {
        if (
            !(
                declarationPayload &&
                'amendmentAccepted' in declarationPayload &&
                'userAmendmentSubmitted' in declarationPayload
            )
        )
            return;

        const amendmentHasNoAnswer = !declarationPayload?.amendmentAccepted && !declarationPayload?.amendmentRejected;
        const amendmentRequestSubmitted = declarationPayload?.userAmendmentSubmitted;

        if (declaration?.id && amendmentRequestSubmitted && amendmentHasNoAnswer) {
            getDeclaration(declaration.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabKey]);
};

export default useRefetchDeclarationOnTabChangeIfAmendable;
