import { Modal, ModalProps } from 'antd';
import { H5 } from 'components/ui/base/typography';
import useDeclarations from 'hooks/useDeclarations';
import { useRequestPromise } from 'hooks/useRequest';
import { useMemo } from 'react';
import { lookupIEMRN } from 'store/declarations/client';

type Props = ModalProps;

const MRNLookupModal = (props: Props) => {
    const { declaration } = useDeclarations();

    const {
        data: mrnLookup,
        isLoading,
        error,
    } = useRequestPromise(() => {
        if (!declaration?.id || !declaration?.mrn) return null;
        return lookupIEMRN(declaration?.id);
    });

    const content = useMemo(() => {
        if (error) return <p>Couldn't load MRN lookup data</p>;

        if (isLoading) return <p>Loading...</p>;

        if (!mrnLookup) return null;

        return (
            <div>
                <p>Our MRN: {declaration?.mrn}</p>
                <p>Actual MRN: {mrnLookup.mrn}</p>
                <p>Declaration Status: {mrnLookup.declarationStatus}</p>
                <p>Control Status: {mrnLookup.controlStatus}</p>
                <p>Date of Enquiry: {new Date(mrnLookup.dateOfEnquiry).toDateString()}</p>
                <p>Time of Enquiry: {new Date(mrnLookup.timeOfEnquiry).toTimeString()}</p>
                <p>Date of Last Update: {new Date(mrnLookup.dateOfLastUpdate).toDateString()}</p>
                <p>Time of Last Update: {new Date(mrnLookup.timeOfLastUpdate).toTimeString()}</p>
            </div>
        );
    }, [declaration?.mrn, error, isLoading, mrnLookup]);

    return (
        <Modal {...props} footer={null}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <H5>MRN Lookup</H5>
                {content}
            </div>
        </Modal>
    );
};

export default MRNLookupModal;
