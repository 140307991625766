import Drawer from 'components/ui/base/drawer/Drawer';
import { FC } from 'react';
import FormHelp from './FormHelp';

interface Props {
    section?: number | string;
    isVisible: boolean;
    onClose: Function;
}
export const FormHelperDrawer: FC<Props> = ({ isVisible, onClose, ...FormHelpProps }) => {
    return (
        <Drawer title="Form Help" width="757" visible={isVisible} onClose={() => onClose()}>
            <FormHelp {...FormHelpProps} />
        </Drawer>
    );
};

export default FormHelperDrawer;
