import { Row } from 'antd';
import jobsIcon from 'assets/jobs.svg';
import { H6Style } from 'components/ui/base/typography/Typography.styles';
import { ListPayload } from 'core/http/response';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Customer } from 'store/customers/customer';
import { JobResponse } from 'store/jobs/job';
import {
    DetailsDiv,
    Icon,
    JobsDiv,
    JobsDivList,
    ReferenceIdDiv,
    StyledBoldText,
    StyledLink,
} from './CustomerDetails.styles';
import CustomerInfo from './CustomerInfo';

interface Props {
    customer?: Customer | null;
    jobs?: ListPayload<JobResponse>;
}

const CustomerDetails: FC<Props> = ({ customer, jobs }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('customers');

    const detailsView = useMemo(() => {
        return (
            <>
                <DetailsDiv>
                    <CustomerInfo customer={customer} />
                </DetailsDiv>

                {!!jobs?.total && (
                    <JobsDiv>
                        <H6Style>{t('form.jobs')}</H6Style>
                        <JobsDivList>
                            {jobs?.list.map((job) => {
                                return (
                                    <Row key={job.id}>
                                        <Icon src={jobsIcon} />
                                        <ReferenceIdDiv>
                                            <StyledBoldText>{job.referenceId}</StyledBoldText>
                                        </ReferenceIdDiv>
                                        <div>
                                            <StyledLink onClick={() => navigate(`/jobs/${job.id}`)}>
                                                {t('form.viewJobsDetails')}
                                            </StyledLink>
                                        </div>
                                    </Row>
                                );
                            })}
                        </JobsDivList>
                    </JobsDiv>
                )}
            </>
        );
    }, [customer, jobs, navigate, t]);

    return <div>{detailsView}</div>;
};
export default CustomerDetails;
