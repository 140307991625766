import { FormikErrors, isEmptyArray } from 'formik';
import { get, set, startCase } from 'lodash';
import { ParsedField, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { ParsedCard } from 'store/declaration-form-errors/ParsedForm';
import { CdsExportCustomer, CdsDeclaration } from '../store/declarations/uk/cds-declaration';
import { IrelandExportDeclaration, IrelandExportParty } from 'store/declarations/ireland/export-declaration';
import { UkGvmsRecord } from '../store/declarations/uk/gvms-declaration';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';

const addIndexToPathBeforeLast = (path: string) => {
    const split = path.split('.');
    split.splice(split.length - 1, 0, '1');
    return split.join('.');
};

export const subtractOneFromEveryFoundNumber = <TString extends string | undefined>(string: TString): TString => {
    let newString = '';
    if (!string) return undefined as TString;
    for (let i = 0; i < string.length; i++) {
        const char = string[i];
        if (!isNaN(Number(char))) newString += (Number(char) - 1).toString();
        else newString += char;
    }
    return newString as TString;
};

export const fromExternalFieldNameToInternal = (fieldName: string) => {
    fieldName = subtractOneFromEveryFoundNumber(fieldName);
    if (fieldName.includes('cdsExportGovernmentAgencyGoodsItem')) {
        const split = fieldName.split('.');
        fieldName = [split.at(0), split.at(3), split.at(2), ...split.slice(4)].join('.');
    }
    return fieldName;
};

export const subtractOneFromFirstFoundNumber = (string: string | undefined) => {
    let newString = string;
    const splitString = string?.split(' ');
    const indexOfNumber = splitString?.findIndex((part) => !isNaN(Number(part)));
    if (splitString && indexOfNumber && indexOfNumber !== -1) {
        splitString[indexOfNumber] = (Number(splitString[indexOfNumber]) - 1).toString();
        newString = splitString?.join(' ');
    }
    return newString;
};
const getCardAndFieldName = (cardErrors: ParsedCard[] | undefined) => {
    const card = cardErrors?.find(({ fields }) => fields !== undefined);
    const cardName = card?.name ?? 'Unknown card';
    const fieldName = card?.fields?.[0].name ?? 'Unknown field';
    return { cardName, fieldName };
};
export const getFieldName = (
    validationParser: (validation: FormikErrors<any>) => {
        masterDetails: ParsedCard[];
        items: ParsedItem[];
    },
    path: string,
    productPath?: string | null
) => {
    const isItem = productPath ? path.includes(productPath) : null;

    let errors = validationParser(set({}, path, 'error'));
    if (!errors.items.length && !errors.masterDetails.length) {
        errors = validationParser(set({}, addIndexToPathBeforeLast(path), 'error'));
    }

    if (isItem && errors.items.length) {
        const product = errors.items[0];
        const productNumber = product.index - 1;

        let { cardName, fieldName } = getCardAndFieldName(product.errors);
        cardName = subtractOneFromFirstFoundNumber(cardName) ?? 'Unknown card';

        return `Item Details - Product ${productNumber} - ${cardName} - ${fieldName}`;
    } else if (isItem) {
        const regex = new RegExp(/\bgoodsShipment\.governmentAgencyGoodsItem\.(\d+)\b/g);
        const match = regex.exec(path);
        if (match?.length) return `Product ${match[1]}`;
    } else if (errors.masterDetails.length) {
        let { cardName, fieldName } = getCardAndFieldName(errors.masterDetails);
        cardName = subtractOneFromFirstFoundNumber(cardName) ?? 'Unknown card';

        return `Master Information - ${cardName} - ${fieldName}`;
    }

    return undefined;
};

export const parseFieldErrorsForCard = (
    cardName: string,
    paths: (string | { [path: string]: string })[],
    errors: ParsedCard[],
    validation: FormikErrors<CdsDeclaration> | UkGvmsRecord
) => {
    const fields = parseAnyValidationError(paths, validation);
    if (fields.length > 0) {
        errors.push({ name: cardName, fields });
    }
};

export const parseFieldErrorsForMultipleCard = (
    cardName: string,
    cardPath: string,
    paths: (string | { [path: string]: string })[],
    errors: ParsedCard[],
    validation: FormikErrors<CdsDeclaration> | UkGvmsRecord
) => {
    const data = get(validation, cardPath);

    if (typeof data === 'string') {
        errors.push({ name: cardName, fields: [{ name: cardName, message: data }] });
    } else if (Array.isArray(data)) {
        data?.forEach((val: any, index: number) => {
            const fields = parseAnyValidationError(paths, val);
            if (fields.length > 0) {
                errors.push({ name: `${cardName} ${index + 1}`, fields });
            }
        });
    }
};

export const parseFieldErrorsForCdsCustomerCard = (
    cardName: string,
    customerPath: string,
    errors: ParsedCard[],
    validation: FormikErrors<CdsDeclaration>
) => {
    const fields = parseCdsDeclarationCustomerErrors(get(validation, customerPath), cardName);
    if (fields.length > 0) {
        errors.push({ name: 'Parties', fields });
    }
};

const parseCdsDeclarationCustomerErrors = (customer: CdsExportCustomer, customerName: string) => {
    const fields: ParsedField[] = [];

    const addCustomerName = (fieldName: string) => {
        if (!customerName) {
            return fieldName;
        }
        return customerName + ' - ' + fieldName;
    };

    if (typeof customer === 'string') {
        fields.push({ name: customerName, message: customer });
    }

    if (customer?.id) {
        fields.push({ name: addCustomerName('Identification Number'), message: customer.id });
    }

    if (customer?.name) {
        fields.push({ name: addCustomerName('Name'), message: customer.name });
    }

    if (customer?.address?.line) {
        fields.push({ name: addCustomerName('Street And Number'), message: customer.address?.line });
    }

    if (customer?.address?.cityName) {
        fields.push({ name: addCustomerName('City'), message: customer.address.cityName });
    }

    if (customer?.address?.postcodeID) {
        fields.push({ name: addCustomerName('Post Code'), message: customer.address.postcodeID });
    }

    if (customer?.address?.countryCode) {
        fields.push({ name: addCustomerName('Country'), message: customer.address.countryCode });
    }

    return fields;
};

export const parseFieldErrorsForAesCustomerCard = (
    cardName: string,
    customerPath: string,
    errors: ParsedCard[],
    validation: FormikErrors<IrelandExportDeclaration>
) => {
    const fields = parseAesDeclarationCustomerErrors(get(validation, customerPath), cardName);
    if (fields.length > 0) {
        errors.push({ name: 'Parties', fields });
    }
};

const parseAesDeclarationCustomerErrors = (customer: IrelandExportParty, customerName: string) => {
    const fields: ParsedField[] = [];

    const addCustomerName = (fieldName: string) => {
        if (!customerName) return fieldName;
        return customerName + ' - ' + fieldName;
    };

    if (typeof customer === 'string') {
        fields.push({ name: customerName, message: customer });
    }

    if (customer?.identificationNumber) {
        fields.push({ name: addCustomerName('Identification Number'), message: customer.identificationNumber });
    }
    if (customer?.status) {
        fields.push({ name: addCustomerName('Status'), message: customer.status });
    }
    if (customer?.name) {
        fields.push({ name: addCustomerName('Name'), message: customer.name });
    }
    if (customer?.address?.streetAndNumber) {
        fields.push({ name: addCustomerName('Street And Number'), message: customer.address.streetAndNumber });
    }
    if (customer?.address?.city) {
        fields.push({ name: addCustomerName('City'), message: customer.address.city });
    }
    if (customer?.address?.postcode) {
        fields.push({ name: addCustomerName('Postcode'), message: customer.address.postcode });
    }
    if (customer?.address?.country) {
        fields.push({ name: addCustomerName('Country'), message: customer.address.country });
    }
    if (customer?.contactPerson?.name) {
        fields.push({ name: addCustomerName('Contact Person - Name'), message: customer.contactPerson.name });
    }
    if (customer?.contactPerson?.phoneNumber) {
        fields.push({
            name: addCustomerName('Contact Person - Phone Number'),
            message: customer.contactPerson.phoneNumber,
        });
    }
    if (customer?.contactPerson?.emailAddress) {
        fields.push({
            name: addCustomerName('Contact Person - E-mail Address'),
            message: customer.contactPerson.emailAddress,
        });
    }

    return fields;
};

export const parseFieldErrorsForAisCustomerCard = (
    cardName: string,
    customerPath: string,
    errors: ParsedCard[],
    validation: FormikErrors<IrelandImportDeclaration>
) => {
    const fields = parseAesDeclarationCustomerErrors(get(validation, customerPath), cardName);
    if (fields.length > 0) {
        errors.push({ name: 'Parties', fields });
    }
};

export const parseAnyValidationError = (
    paths: (string | { [path: string]: string })[],
    validation: FormikErrors<unknown>,
    prefix?: string
) => {
    const fields: ParsedField[] = [];

    paths.forEach((path) => {
        const pathIsString = typeof path === 'string';
        const pathIsObject = typeof path === 'object' && path !== null;
        if (pathIsString) {
            const val = get(validation, path);
            if (!val) return;

            const name = startCase(path.split('.').at(-1));
            const finalName = prefix ? `${prefix} - ${name}` : name;
            fields.push({ name: finalName, message: val, path: path });
        } else if (pathIsObject) {
            const pathKey = Object.keys(path)[0];
            const val = get(validation, pathKey);
            if (!val) return;

            if (isEmptyArray(val)) return;

            const pathValue = Object.values(path)[0];
            const name = pathValue.replace(/\b\w/g, (char) => char.toUpperCase());
            const finalName = prefix ? `${prefix} - ${name}` : name;
            fields.push({ name: finalName, message: val, path: pathKey });
        } else {
            throw new Error('Validation parser paths type violated');
        }
    });

    return fields;
};

export const handleField = (fields: ParsedField[], item: any, path: string, name: string) => {
    const message = get(item, path);
    if (message) {
        fields.push({
            name,
            message,
            path,
        });
    }
};
