import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { set } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { useTemplateContext } from '../../../../../../../components/ui/composed/template/TemplateContext';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleDeclarationField from '../../../../../common/MultipleDeclarationField';
import AutoFillModal from '../../components/AutoFillModal';
import { validators } from 'views/declarations/uk/export/validations/validations';

const ItemDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField, getH1TooltipsByField } = useTooltips();
    const { template, templateFormik } = useTemplateContext();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);

    const openModal = (e: any) => {
        setOpen(true);
        setValue(e?.target?.value);
    };
    const closeModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };

    const formik = useMemo(() => {
        if (template && templateFormik) return templateFormik;
        return props.formik;
    }, [props.formik, template, templateFormik]);

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => set(acc, field, value), values);
        formik?.setValues(newValues);
    };

    return (
        <NewFormCard title={t('Item Details')}>
            <FormCardContainer>
                <AutoFillModal
                    visible={open}
                    value={value}
                    prevValue={prevValue}
                    fields={[
                        {
                            name: 'countryPreferentialOrigin',
                            label: t('countryPreferentialOrigin'),
                        },
                    ]}
                    onCancel={closeModal}
                    onOk={autoFill}
                />
                <CountrySelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`countryOrigin`, props)}
                    refNumber="5.15"
                    label={t('countryOrigin')}
                    tooltip={getH1TooltipsByRefNumberAndField('5.15', t('countryOrigin'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    onChange={openModal}
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsInformation.grossMass`, props)}
                    refNumber="6.5"
                    label={t('goodsItems.grossMass')}
                    refTooltip={getH1TooltipsByRefNumberAndField('6.5', t('goodsItems.grossMass'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsInformation.netMass`, props)}
                    refNumber="6.1"
                    label={t('goodsItems.netMass')}
                    refTooltip={getH1TooltipsByRefNumberAndField('6.1', t('goodsItems.netMass'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`procedureCode`, props)}
                    refNumber="1.10"
                    label={t('requestedProcedure')}
                    selectOptions={aisCodelists?.procedureCode}
                    tooltip={getH1TooltipsByRefNumberAndField('1.10', t('requestedProcedure'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.exact(2)]}
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`previousProcedureCode`, props)}
                    refNumber="1.10"
                    label={t('previousProcedure')}
                    tooltip={t('defaultTooltip')}
                    selectOptions={aisCodelists?.procedureCode}
                    condensed
                    codeValidation={[validators.exact(2)]}
                />

                <MultipleDeclarationField parent="additionalProcedure" name="additionalProcedure">
                    {(fieldProps, controls) => (
                        <FormSelect
                            viewOnly={props.viewOnly}
                            refNumber="1.11"
                            label={t('additionalProcedure')}
                            {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                            selectOptions={aisCodelists?.additionalProcedure}
                            tooltip={getH1TooltipsByField(t('additionalProcedure'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            condensed
                            multipleF={controls}
                            codeValidation={[validators.exact(3)]}
                        />
                    )}
                </MultipleDeclarationField>
            </FormCardContainer>
        </NewFormCard>
    );
};

export default ItemDetailsCard;
