import { Col, Row } from 'antd';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import addPathPrefix from 'utils/addPathPrefix';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import AddressForm from '../AddressForm';
import PartiesAddressFormProps from './PartiesAddressFormProps';
import { Party, Paths } from './PartiesCard';
import { FormCardContainer } from '../cards/NewFormCard';
import { DeclarationFormCardProps } from '../declaration.form.card';

const addPathPrefixToValues = <TType extends Object>(prefix: string, object: TType) => {
    const newObject = {} as any;
    Object.entries(object).forEach(([key, value]) => {
        newObject[key] = addPathPrefix(prefix, value);
    });
    return newObject as TType;
};
const createAddressPath = (party: Party, paths: Omit<Paths, 'eori'>) => {
    if (!paths?.address) return undefined;
    return {
        ...addPathPrefixToValues(party.path, paths.address),
    };
};

interface Props extends PartiesAddressFormProps, DeclarationFormCardProps {}

const PartiesAddressForm = ({
    paths: pathsProp,
    party,
    refNumber,
    condensed,
    onBlur,
    disabled,
    ...other
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const paths = useMemo(() => createAddressPath(party, pathsProp), [party, pathsProp]);

    if (!paths) return <></>;

    if (condensed) {
        return (
            <FormCardContainer>
                <FormInput
                    {...getFormikProps(paths.name, other)}
                    refNumber={refNumber}
                    label={t('name')}
                    viewOnly={other.viewOnly}
                    condensed={condensed}
                    onBlur={onBlur}
                    disabled={disabled}
                />
                <AddressForm
                    declarationType={other.declarationType}
                    viewOnly={other.viewOnly}
                    disableFields={{ streetAndNumber: disabled, country: disabled, postCode: disabled, city: disabled }}
                    fieldProps={{
                        streetAndNumber: other.getFieldProps(paths.streetAndNumber),
                        country: other.getFieldProps(paths.country),
                        postCode: other.getFieldProps(paths.postCode),
                        city: other.getFieldProps(paths.city),
                    }}
                    fieldMeta={{
                        streetAndNumber: other.getFieldMeta!(paths.streetAndNumber),
                        country: other.getFieldMeta!(paths.country),
                        postCode: other.getFieldMeta!(paths.postCode),
                        city: other.getFieldMeta!(paths.city),
                    }}
                    fieldHelper={{
                        streetAndNumber: other.getFieldHelpers(paths.streetAndNumber),
                        country: other.getFieldHelpers(paths.country),
                        postCode: other.getFieldHelpers(paths.postCode),
                        city: other.getFieldHelpers(paths.city),
                    }}
                    refNumbers={{
                        streetAndNumber: refNumber ?? '',
                        city: refNumber ?? '',
                        country: refNumber ?? '',
                        postcode: refNumber ?? '',
                    }}
                    condensed={condensed}
                    onBlur={onBlur}
                />
            </FormCardContainer>
        );
    }

    return (
        <Row gutter={12}>
            <Col xs={24}>
                <FormInput
                    {...getFormikProps(paths.name, other)}
                    refNumber={refNumber}
                    label={t('name')}
                    viewOnly={other.viewOnly}
                    condensed={condensed}
                    onBlur={onBlur}
                    disabled={disabled}
                />
            </Col>
            <AddressForm
                declarationType={DeclarationName.H1}
                viewOnly={other.viewOnly}
                disableFields={{ streetAndNumber: disabled, country: disabled, postCode: disabled, city: disabled }}
                fieldProps={{
                    streetAndNumber: other.getFieldProps(paths.streetAndNumber),
                    country: other.getFieldProps(paths.country),
                    postCode: other.getFieldProps(paths.postCode),
                    city: other.getFieldProps(paths.city),
                }}
                fieldMeta={{
                    streetAndNumber: other.getFieldMeta!(paths.streetAndNumber),
                    country: other.getFieldMeta!(paths.country),
                    postCode: other.getFieldMeta!(paths.postCode),
                    city: other.getFieldMeta!(paths.city),
                }}
                fieldHelper={{
                    streetAndNumber: other.getFieldHelpers(paths.streetAndNumber),
                    country: other.getFieldHelpers(paths.country),
                    postCode: other.getFieldHelpers(paths.postCode),
                    city: other.getFieldHelpers(paths.city),
                }}
                refNumbers={{
                    streetAndNumber: refNumber ?? '',
                    city: refNumber ?? '',
                    country: refNumber ?? '',
                    postcode: refNumber ?? '',
                }}
                condensed={condensed}
                onBlur={onBlur}
            />
        </Row>
    );
};

export default PartiesAddressForm;
