import { FormikProps, FormikProvider } from 'formik';
import { FC } from 'react';
import NewFormCard, { FormCardContainer } from '../../common/cards/NewFormCard';
import { getFormikProps, getFullCodelistValue } from '../../utils/form-utils';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { validators } from '../../uk/export/validations/validations';
import useCodelists from '../../../../hooks/useCodelists';
import PartiesCard from '../../common/parties/PartiesCard';
import MultipleItemsCard from '../../common/MultipleItemsCard';
import addPathPrefix from '../../../../utils/addPathPrefix';
import CardSection from '../../common/cards/CardSection';
import CountrySelect from '../../../../components/ui/composed/declarations/CountrySelect';
import MultipleDeclarationField from '../../common/MultipleDeclarationField';
import { useOutletContext } from 'react-router-dom';
import { TSDMessageTypes } from '../../../../store/declarations/enums/common/declaration-types';
import DeclarationNumberInput from '../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useNumberOfItemsModal from '../../common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from '../../../../hooks/useProducts';
import useFormUtils from '../../../../hooks/useFormUtils';

interface Props {
    formik: FormikProps<any>;
}

const IrelandTsdForm: FC<Props> = ({ formik }) => {
    const { aisCodelists } = useCodelists();
    const { declarationFormType } = useFormUtils();
    const { createIrelandTsdProduct, listIrelandTsdProducts } = useProducts();
    const { saveAsDraft } =
        useOutletContext<{
            saveAsDraft: (withNotification: boolean, data?: unknown) => Promise<unknown>;
        }>() ?? {};

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createIrelandTsdProduct,
        listProducts: listIrelandTsdProducts,
        saveAsDraft,
    });

    return (
        <FormikProvider value={formik}>
            <NewFormCard title="Customs Offices">
                <FormCardContainer>
                    <DeclarationInput
                        label="Supervising Customs Office"
                        refNumber="5.27"
                        {...getFormikProps('declaration.customsOffices.supervisingCustomsOffice527', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        required
                        label="Customs Office of Lodgement"
                        {...getFormikProps(`declaration.customsOffices.customsOfficeLodgement`, formik)}
                        condensed
                        selectOptions={aisCodelists?.nationalCustomsOffice}
                        codeValidation={[validators.exact(8)]}
                    />
                    <DeclarationNumberInput
                        label="Number of Items"
                        {...getFormikProps(`numberOfItems`, formik)}
                        fieldEvents={{
                            onBlur(value) {
                                handleNumberOfItems(Number(value));
                            },
                        }}
                        required
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'declaration.parties.declarant',
                        header: 'Declarant',
                        refNumber: '3.17',
                        eoriRequired: true,
                        hasAddress: false,
                    },
                    {
                        path: 'declaration.parties.representative',
                        header: 'Representative',
                        refNumber: '3.19',
                        hasRepresentativeStatus: true,
                        hasAddress: false,
                    },
                ]}
                paths={{
                    eoriByPath: (path) => {
                        if (!path) return '';
                        if (path.includes('declarant')) {
                            return 'declarant318';
                        } else if (path.includes('representative')) {
                            return 'representative320';
                        }
                        return '';
                    },
                    additional: {
                        representativeStatus: 'representativeStatus321',
                    },
                }}
                condensed
            />

            <MultipleItemsCard
                title="Simplified Declaration / Previous Documents / Writing-off"
                path="goodsShipment.documentsAuthorisations.simplifiedDeclarationDocumentWritingOff201"
                refNumber="2.01"
                initialValues={{
                    previousDocumentType: '',
                    previousDocumentIdentifier: '',
                    previousDocumentLineId: '',
                }}
                list={(list) => [
                    {
                        field: 'Previous Document Type',
                        value: getFullCodelistValue(list.previousDocumentType, aisCodelists?.previousDocumentType),
                    },
                    { field: 'Previous Document Reference', value: list.previousDocumentIdentifier },
                    { field: 'Declaration Line Number Previously Recorded', value: list.previousDocumentLineId },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Previous Document Type"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentType'), formik)}
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aisCodelists?.previousDocumentType}
                        />
                        <DeclarationInput
                            label="Previous Document Reference"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentIdentifier'), formik)}
                            condensed
                        />
                        <DeclarationInput
                            label="Declaration Line Number Previously Recorded"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentLineId'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Information"
                path="goodsShipment.documentsAuthorisations.additionalInformation22"
                refNumber="2.2"
                initialValues={{
                    additionalInformationCode: '',
                    additionalInformationText: '',
                }}
                list={(list) => [
                    {
                        field: 'Additional Statement Code',
                        value: getFullCodelistValue(
                            list.additionalInformationCode,
                            aisCodelists?.additionalInformationCode
                        ),
                    },
                    { field: 'Additional Statement Text', value: list.additionalInformationText },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Additional Statement Code"
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationCode'), formik)}
                            codeValidation={[validators.exact(5)]}
                            condensed
                            selectOptions={aisCodelists?.additionalInformationCode}
                        />
                        <DeclarationInput
                            label="Additional Statement Text"
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationText'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Documents Produced / Certificates and Authorisations / Additional References"
                path="goodsShipment.documentsAuthorisations.producedDocumentsWritingOff203"
                refNumber="2.03"
                initialValues={{
                    documentType: '',
                    documentIdentifier: '',
                }}
                list={(list) => [
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.documentType, aisCodelists?.commonDocumentsType),
                    },
                    { field: 'Id', value: list.documentIdentifier },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'documentType'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aisCodelists?.commonDocumentsType}
                        />
                        <DeclarationInput
                            label="Id"
                            {...getFormikProps(addPathPrefix(path, 'documentIdentifier'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Reference Number">
                <FormCardContainer>
                    <DeclarationInput
                        label="UCR"
                        refNumber="2.4"
                        {...getFormikProps('goodsShipment.documentsAuthorisations.ucr24', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Identification of Warehouse">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Warehouse Type"
                        required
                        refNumber="2.7"
                        {...getFormikProps(
                            'goodsShipment.documentsAuthorisations.warehouseIdentification27.warehouseType',
                            formik
                        )}
                        condensed
                        selectOptions={aisCodelists?.warehouseType}
                        codeValidation={[validators.exact(1)]}
                    />
                    <DeclarationInput
                        label="Warehouse Identifier"
                        required
                        refNumber="2.7"
                        {...getFormikProps(
                            'goodsShipment.documentsAuthorisations.warehouseIdentification27.warehouseIdentifier',
                            formik
                        )}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Location of Goods">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Identification of Location"
                        {...getFormikProps(
                            'goodsShipment.datesPlaces.locationGoods523.identificationOfLocation',
                            formik
                        )}
                        condensed
                        selectOptions={aisCodelists?.locationOfGoodsCode}
                        codeValidation={[validators.maxLength(35)]}
                    />
                    <DeclarationSelect
                        label="Qualifier of the Identification"
                        {...getFormikProps(
                            'goodsShipment.datesPlaces.locationGoods523.qualifierIdentification',
                            formik
                        )}
                        condensed
                        selectOptions={aisCodelists?.locationIdentificationQualifier}
                        codeValidation={[validators.exact(1)]}
                    />
                    <DeclarationInput
                        label="Additional Identifier"
                        {...getFormikProps('goodsShipment.datesPlaces.locationGoods523.additionalIdentifier', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        label="Type of Location Code"
                        {...getFormikProps('goodsShipment.datesPlaces.locationGoods523.locationTypeCode', formik)}
                        condensed
                        selectOptions={aisCodelists?.locationType}
                        codeValidation={[validators.exact(1)]}
                    />
                    <CardSection title="Address">
                        <DeclarationInput
                            label="City"
                            {...getFormikProps('goodsShipment.datesPlaces.locationGoods523.address.city', formik)}
                            condensed
                        />
                        <CountrySelect
                            label="Country Code"
                            {...getFormikProps(
                                'goodsShipment.datesPlaces.locationGoods523.address.countryCode',
                                formik
                            )}
                            condensed
                        />
                        <DeclarationInput
                            label="Street and Number"
                            {...getFormikProps(
                                'goodsShipment.datesPlaces.locationGoods523.address.streetAndNumber',
                                formik
                            )}
                            condensed
                        />
                        <DeclarationInput
                            label="Postcode"
                            {...getFormikProps('goodsShipment.datesPlaces.locationGoods523.address.postcode', formik)}
                            condensed
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Transportation">
                <FormCardContainer>
                    <MultipleDeclarationField
                        parent="goodsShipment.transportInformation.containerIdentificationNumber710"
                        name="containerIdentificationNumber"
                    >
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                refNumber="7.10"
                                label="Container Identification Number"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>

                    <DeclarationInput
                        refNumber="7.18"
                        label="Number of Seals"
                        {...getFormikProps('goodsShipment.transportInformation.seal.sealNumber718', formik)}
                        condensed
                    />

                    <MultipleDeclarationField
                        parent="goodsShipment.transportInformation.seal.sealIdentifier718"
                        name="sealIdentifier"
                    >
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                refNumber="7.18"
                                label="Seal Identifier"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>
                    <CardSection title="Active Border Transport Means">
                        <DeclarationSelect
                            label="Type of Identification"
                            required
                            refNumber="7.14"
                            {...getFormikProps(
                                'goodsShipment.transportInformation.arrivalTransportMeansId79.identificationType',
                                formik
                            )}
                            condensed
                            selectOptions={aisCodelists?.meansIdentityType}
                            codeValidation={[validators.exact(2)]}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            required
                            refNumber="7.14"
                            {...getFormikProps(
                                'goodsShipment.transportInformation.arrivalTransportMeansId79.identificationNumber',
                                formik
                            )}
                            condensed
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Total Gross Weight">
                <FormCardContainer>
                    <DeclarationInput
                        label="Total Gross Weight"
                        {...getFormikProps('goodsShipment.grossMass65', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Supply Chain Actor"
                path="goodsShipment.supplyChainActor337"
                refNumber="3.37"
                initialValues={{
                    roleCode: '',
                    traderIdentification: '',
                }}
                list={(list) => [
                    {
                        field: 'Role Code',
                        value: getFullCodelistValue(list.roleCode, aisCodelists?.supplyChainActorRoleCode),
                    },
                    { field: 'Identifier', value: list.traderIdentification },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Role Code"
                            {...getFormikProps(addPathPrefix(path, 'roleCode'), formik)}
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aisCodelists?.supplyChainActorRoleCode}
                        />
                        <DeclarationInput
                            label="Identifier"
                            {...getFormikProps(addPathPrefix(path, 'traderIdentification'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Presentation" hidden={declarationFormType === TSDMessageTypes.G4}>
                <FormCardContainer>
                    <DeclarationInput
                        hidden={declarationFormType === TSDMessageTypes.G4}
                        refNumber="3.30"
                        required
                        label="Person Presenting the Goods to Customs Identification No."
                        {...getFormikProps('goodsShipment.presentation.presentationTrader330', formik)}
                        condensed
                    />
                    <DeclarationInput
                        hidden={declarationFormType === TSDMessageTypes.G4}
                        refNumber="5.24"
                        required
                        label="Customs Office of First Entry"
                        {...getFormikProps('goodsShipment.presentation.firstEntryCustomsOffice524', formik)}
                        condensed
                    />
                    <CardSection title="Identity of Means of Transport at Border">
                        <DeclarationSelect
                            hidden={declarationFormType === TSDMessageTypes.G4}
                            required
                            label="Type of Identification"
                            {...getFormikProps(
                                'goodsShipment.presentation.activeBorderTransportMeansId714.identificationType',
                                formik
                            )}
                            codeValidation={[validators.exact(2)]}
                            condensed
                            selectOptions={aisCodelists?.meansIdentityType}
                        />
                        <DeclarationInput
                            hidden={declarationFormType === TSDMessageTypes.G4}
                            required
                            label="Identification Number"
                            {...getFormikProps(
                                'goodsShipment.presentation.activeBorderTransportMeansId714.identificationNumber',
                                formik
                            )}
                            condensed
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>
            {modalContextHolder}
        </FormikProvider>
    );
};
export default IrelandTsdForm;
