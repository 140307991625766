import { Col } from 'antd';
import { FC, useCallback, useState } from 'react';
import { TaricCodeChapter, TaricCodeSection } from 'store/taric-codes/taric-code';
import { CollapseIcon, DescriptionCol, ExpandIcon, ListText, StyledRow } from '../TaricCode.styles';
interface Props {
    section: TaricCodeSection;
    searchCode: (code: string) => void;
}
const ListSection: FC<Props> = ({ section, searchCode }) => {
    const [collapsed, setCollapsed] = useState(true);
    const getChapters = useCallback(
        (chapter: TaricCodeChapter[]) =>
            chapter.length > 1
                ? `${chapter[0].number} to ${chapter[chapter.length - 1].number}`
                : `${chapter[0].number}`,
        []
    );
    const listChildren = useCallback(
        (chapters: TaricCodeChapter[], section: string) =>
            chapters?.map((c, i) => (
                <StyledRow
                    align="middle"
                    key={`list-section-${c.title}-${i}-${c.number}`}
                    margin="2"
                    onClick={() => searchCode(c.code)}
                >
                    <Col span={20}>
                        <ListText>{c?.title}</ListText>
                    </Col>
                    <Col span={2}>
                        <ListText>{section}</ListText>
                    </Col>
                    <Col span={2}>
                        <ListText>{c.number}</ListText>
                    </Col>
                </StyledRow>
            )),
        [searchCode]
    );
    const handleIcon = useCallback(
        (section: TaricCodeSection) => {
            if (section?.chapters) {
                if (collapsed) {
                    return <ExpandIcon />;
                } else {
                    return <CollapseIcon />;
                }
            }
        },
        [collapsed]
    );
    return (
        <>
            <StyledRow align="middle" onClick={() => setCollapsed(!collapsed)}>
                <DescriptionCol span={20}>
                    <ListText>
                        {handleIcon(section)}
                        {section?.title}
                    </ListText>
                </DescriptionCol>
                <Col span={2}>
                    <ListText>{section?.number}</ListText>
                </Col>
                <Col span={2}>
                    <ListText>{getChapters(section?.chapters)}</ListText>
                </Col>
            </StyledRow>
            {!collapsed && listChildren(section?.chapters, section?.number)}
        </>
    );
};
export default ListSection;
