import { SuccessResponse } from '../../core/http/response';
import axiosClient from '../../config/axios';
import config from '../../config';

export const deleteDocument = (
    declarationId: string | undefined,
    declarationUploadedDocumentId: string | undefined
): Promise<any> =>
    axiosClient
        .delete<SuccessResponse<any>>(
            `${config.declarationsUrl}/declarations/${declarationId}/documents/${declarationUploadedDocumentId}`
        )
        .then((response) => response.data);

/*
 *
 *
 *    AIS request's
 *
 *
 */

export const createDocumentAis = (document: any, declarationId: string): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/documents`,
            document
        )
        .then((response) => response.data.payload);

export const updateDocumentAis = (document: any, declarationId: string): Promise<any> =>
    axiosClient
        .put<SuccessResponse<any>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/documents/${document.id}`,
            document
        )
        .then((response) => response.data.payload);

export const submitDocumentsAis = (documentIds: any, declarationId: string | undefined): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/documents/submit`,
            documentIds
        )
        .then((response) => response.data.payload);

/*
 *
 *
 *    UK request's
 *
 *
 */

export const createDocumentUk = (document: any, declarationId: string): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(`${config.declarationsUrl}/uk/declarations/${declarationId}/documents`, document)
        .then((response) => response.data.payload);

export const submitDocumentsUk = (documentIds: any, declarationId: string | undefined): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(
            `${config.declarationsUrl}/uk/declarations/${declarationId}/documents/submit`,
            documentIds
        )
        .then((response) => response.data.payload);

/***
 *
 *
 * AES requests
 *
 */

export const createDocumentAes = (document: any, declarationId: string): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/documents`,
            document
        )
        .then((response) => response.data.payload);

export const updateDocumentAes = (document: any, declarationId: string): Promise<any> =>
    axiosClient
        .put<SuccessResponse<any>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/documents/${document.id}`,
            document
        )
        .then((response) => response.data.payload);

export const submitDocumentsAes = (documentIds: any, declarationId: string | undefined): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/documents/submit`,
            documentIds
        )
        .then((response) => response.data.payload);

/***
 *
 *
 * NCTS requests
 *
 */

export const createDocumentNcts = (document: any, declarationId: string): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(
            `${config.declarationsUrl}/ie/ncts/declarations/${declarationId}/documents`,
            document
        )
        .then((response) => response.data.payload);

export const updateDocumentNcts = (document: any, declarationId: string): Promise<any> =>
    axiosClient
        .put<SuccessResponse<any>>(
            `${config.declarationsUrl}/ie/ncts/declarations/${declarationId}/documents/${document.id}`,
            document
        )
        .then((response) => response.data.payload);

export const submitDocumentsNcts = (documentIds: any, declarationId: string | undefined): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(
            `${config.declarationsUrl}/ie/ncts/declarations/${declarationId}/documents/submit`,
            documentIds
        )
        .then((response) => response.data.payload);
