import { cloneDeep, merge } from 'lodash';
import { validators } from '../../../uk/export/validations/validations';
import { irelandTsdG4ValidationHeader } from './IrelandTsdG4Validation';

const header = {
    childValidators: {
        goodsShipment: {
            childValidators: {
                presentation: {
                    childValidators: {
                        presentationTrader330: [validators.required(), validators.maxLength(17)], // TODO: Check if eori
                        firstEntryCustomsOffice524: [validators.required(), validators.exact(8)],
                        activeBorderTransportMeansId714: {
                            childValidators: {
                                identificationType: [validators.required(), validators.exact(2)],
                                identificationNumber: [validators.required(), validators.maxLength(35)],
                            },
                        },
                    },
                },
            },
        },
    },
};

export const irelandTsdG4G3ValidationHeader = merge(cloneDeep(irelandTsdG4ValidationHeader), header);
