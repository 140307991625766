import { IndividualType } from '../../store/individuals/individual';
export const individualTypeText: Record<IndividualType, string> = {
    [IndividualType.BROKER_ADMIN]: 'Broker Admin',
    [IndividualType.BROKER_CLERK]: 'Broker Clerk',
    [IndividualType.DRIVER]: 'Driver',
    [IndividualType.HAULIER_ADMIN]: 'Haulier Admin',
    [IndividualType.SYSTEM_ADMIN]: 'System Admin',
    [IndividualType.TRADER_ADMIN]: 'Trader Admin',
    [IndividualType.TRADER_USER]: 'Trader User',
    [IndividualType.SUPER_ADMIN]: 'Super Admin',
};
