import { FormModel, validators } from '../../../uk/export/validations/validations';

export const irelandEtdD3ValidationItem = {
    childValidators: {
        transitDeclaration13: [validators.required(), validators.maxLength(5)],
        goodsInformation: {
            childValidators: {
                grossMass65: [validators.float(16, 6)],
                goodsDescription68: [validators.maxLength(512)],
                cusCode613: [validators.exact(8)],
                packaging: {
                    childValidators: {
                        typePackage69: [validators.required(), validators.maxLength(2)],
                        packageNumber610: [validators.maxLength(8)],
                        shippingMarks611: [validators.maxLength(512)],
                    },
                    selfValidators: [validators.minLength(1)],
                    stopAtNull: true,
                },
                commodityCode: {
                    childValidators: { combinedNomenclatureCode614: [validators.maxLength(6)] },
                },
            },
        },
        documentsAuthorisations: {
            childValidators: {
                simplifiedDeclarationDocumentWritingOff201: {
                    childValidators: {
                        previousDocumentType: [validators.maxLength(3)],
                        previousDocumentIdentifier: [validators.maxLength(35)],
                        previousDocumentLineId: [validators.maxLength(5)],
                    },
                    stopAtNull: true,
                },
                additionalInformation22: {
                    childValidators: {
                        additionalInformationCode: [validators.exact(5)],
                        additionalInformationText: [validators.maxLength(512)],
                    },
                    stopAtNull: true,
                },
                producedDocumentsWritingOff203: {
                    childValidators: {
                        documentType: [validators.exact(4)],
                        documentIdentifier: [validators.maxLength(35)],
                    },
                    stopAtNull: true,
                },
                ucr24: [validators.maxLength(35)],
            },
        },
        parties: {
            childValidators: {
                consignee: {
                    childValidators: {
                        consignee310: [validators.eori()],
                    },
                },
                supplyChainActor337: {
                    childValidators: {
                        roleCode: [validators.maxLength(3)],
                        traderIdentification: [validators.maxLength(17)],
                    },
                    stopAtNull: true,
                },
            },
        },
        datesPlaces: {
            childValidators: {
                countryDestination58: [validators.exact(2)],
            },
        },
        transportInformation: {
            childValidators: {
                nationality78: [validators.exact(2)],
                departureTransportMeans77: {
                    childValidators: {
                        identificationType: [validators.exact(2)],
                        identificationNumber: [validators.maxLength(35)],
                    },
                },
                containerIdentificationNumber710: {
                    childValidators: { containerIdentificationNumber: [validators.maxLength(17)] },
                    stopAtNull: true,
                },
            },
        },
    },
};

export const irelandEtdD3ValidationHeader = {
    childValidators: {
        numberOfItems: [
            validators.required(),
            validators.number(),
            validators.min(1),
            validators.maxLength(3),
            async (_: string, b: FormModel<any>) => {
                const itemsLength = b.getValues()?.goodsShipment?.goodsShipmentItem?.length;
                const numberOfItemsField = b.getValues()?.numberOfItems;
                if (itemsLength === undefined) return undefined;
                if (Number(itemsLength) !== Number(numberOfItemsField)) {
                    return 'Number of items from the input does not match the number of items in the declaration.';
                }
                return undefined;
            },
        ],
        declaration: {
            childValidators: {
                transitDeclaration13: [validators.required(), validators.maxLength(5)],
                goodsInformation: {
                    childValidators: {
                        totalPackageNumber618: [validators.maxLength(8)],
                        grossMass65: [validators.float(16, 6)],
                    },
                },
                customsOffices: {
                    childValidators: {
                        customsOfficeLodgement: [validators.required(), validators.maxLength(8)],
                        customsOfficeOfDestination56: [
                            validators.regex(
                                /^[a-zA-Z]{2}[a-zA-Z0-9]{6}$/,
                                'The input must start with 2 alphabetic characters followed by 6 alphanumeric characters.'
                            ),
                        ],
                    },
                },
                parties: {
                    childValidators: {
                        holderOfTheTransitProcedure: {
                            childValidators: {
                                holderOfTheTransitProcedure323: [validators.required(), validators.eori()],
                            },
                        },
                        representative: {
                            childValidators: {
                                representative320: [validators.eori()],
                            },
                        },
                    },
                },
            },
        },
        goodsShipment: {
            childValidators: {
                parties: {
                    childValidators: {
                        consignee: {
                            childValidators: {
                                consignee310: [validators.eori()],
                            },
                        },
                        supplyChainActor337: {
                            childValidators: {
                                roleCode: [validators.maxLength(3)],
                                traderIdentification: [validators.maxLength(17)],
                            },
                            stopAtNull: true,
                        },
                    },
                },
                documentsAuthorisations: {
                    childValidators: {
                        simplifiedDeclarationDocumentWritingOff201: {
                            childValidators: {
                                previousDocumentType: [validators.maxLength(3)],
                                previousDocumentIdentifier: [validators.maxLength(35)],
                                previousDocumentLineId: [validators.maxLength(5)],
                            },
                            stopAtNull: true,
                        },
                        additionalInformation22: {
                            childValidators: {
                                additionalInformationCode: [validators.exact(5)],
                                additionalInformationText: [validators.maxLength(512)],
                            },
                            stopAtNull: true,
                        },
                        producedDocumentsWritingOff203: {
                            childValidators: {
                                documentType: [validators.exact(4)],
                                documentIdentifier: [validators.maxLength(35)],
                            },
                            stopAtNull: true,
                        },
                        ucr24: [validators.maxLength(35)],
                    },
                },
                datesPlaces: {
                    childValidators: {
                        countryDestination58: [validators.exact(2)],
                        transportationPlace: {
                            childValidators: {
                                unlocode: [validators.maxLength(17)],
                                countryCode: [validators.exact(2)],
                                place: [validators.maxLength(35)],
                            },
                        },
                    },
                },
                transportInformation: {
                    childValidators: {
                        departureTransportMeans77: {
                            childValidators: {
                                identificationType: [validators.exact(2)],
                                identificationNumber: [validators.maxLength(35)],
                            },
                        },
                        nationality78: [validators.exact(2)],
                        containerIdentificationNumber710: {
                            childValidators: { containerIdentificationNumber: [validators.maxLength(17)] },
                            stopAtNull: true,
                        },
                        seal: {
                            childValidators: {
                                sealNumber718: [validators.maxLength(4)],
                                sealIdentifier718: {
                                    childValidators: { sealIdentifier: [validators.maxLength(20)] },
                                    stopAtNull: true,
                                },
                            },
                        },
                    },
                },
                goodsShipmentItem: irelandEtdD3ValidationItem,
            },
        },
    },
};
