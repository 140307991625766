import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { ParsedCard, ParsedField, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import {
    AdditionalFiscalReference,
    AdditionalInformation,
    AdditionalProcedure,
    DocumentsAuth,
    GoodsShipment,
    GoodsShipmentItem,
    IrelandH7ImportDeclaration,
    Payment,
    PreviousDocument,
    SupportingDocuments,
    TransportDocument,
    ValuationInformation,
} from 'store/declarations/ireland/h7-import-declaration';
import { handleField, parseAnyValidationError } from 'utils/validationParserUtils';
import { addToParties, parseDeclarationCustomer } from '../../h1/validation/utils';

export const parseValidationErrors = (
    validation: FormikErrors<IrelandH7ImportDeclaration>,
    options?: { withoutCustomers?: boolean }
) => {
    const masterDetailsErros: ParsedCard[] = [];
    parseMasterdetails(masterDetailsErros, validation);
    if (!options?.withoutCustomers) {
        parseExporter(masterDetailsErros, validation);
        parseImporter(masterDetailsErros, validation);
        parseDeclarant(masterDetailsErros, validation);
    }
    parseDocumentsAdditionalInformation(masterDetailsErros, validation);
    parsePreviousDocument(masterDetailsErros, validation);
    parseTransportDocument(masterDetailsErros, validation);
    parseTransportCosts(masterDetailsErros, validation);
    parseLocationOfGoods(masterDetailsErros, validation);
    const itemsErrors: ParsedItem[] = [];
    parseDeclarationItems(itemsErrors, validation);
    const errors = {
        masterDetails: masterDetailsErros,
        items: itemsErrors,
    };
    return errors;
};

const parseMasterdetails = (errors: ParsedCard[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const fields: ParsedField[] = [];

    handleField(fields, validation, 'additionalDeclarationType', 'Additional Declaration Type');
    handleField(fields, validation, 'customsOfficeLodgement', 'Customs Office of Lodgement');
    handleField(fields, validation, 'deferredPayment', 'Deferred Payment');
    handleField(fields, validation, 'preferredPaymentMethod', 'Preferred Payment Method');
    handleField(fields, validation, 'representativeIdentificationNumber', 'Representative Identification Number');
    handleField(fields, validation, 'representativeStatus', 'Representative Status');
    handleField(fields, validation, 'numberOfItems', 'Number of items');
    handleField(fields, validation, 'goodsShipment.grossMass', 'Gross Mass');

    if (fields.length > 0) {
        errors.push({ name: 'Master Details', fields });
    }
};

const getGoodShipment = (validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    return validation.goodsShipment as FormikErrors<GoodsShipment>;
};

const parseExporter = (errors: ParsedCard[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodShipment = getGoodShipment(validation);
    const exporterValidation = goodShipment?.exporter as FormikErrors<DeclarationCustomer>;
    const fields: ParsedField[] = parseDeclarationCustomer(exporterValidation, 'Exporter');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseImporter = (errors: ParsedCard[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodShipment = getGoodShipment(validation);
    const importerValidation = goodShipment?.importer as FormikErrors<DeclarationCustomer>;
    const fields: ParsedField[] = parseDeclarationCustomer(importerValidation, 'Importer');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseDeclarant = (errors: ParsedCard[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const declarantValidation = validation?.declarant as FormikErrors<DeclarationCustomer>;
    const fields: ParsedField[] = parseDeclarationCustomer(declarantValidation, 'Declarant');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseDocumentsAdditionalInformation = (
    errors: ParsedCard[],
    validation: FormikErrors<IrelandH7ImportDeclaration>
) => {
    const goodsShipment = getGoodShipment(validation);
    const documentsAuthorisations = goodsShipment?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const docuemnts = (documentsAuthorisations?.additionalInformation as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<AdditionalInformation>, index) => {
        const fields: ParsedField[] = [];

        handleField(
            fields,
            validation,
            `goodsShipment.documentsAuthorisations.additionalInformation.${index}.additionalInformationCode`,
            'Code'
        );
        handleField(
            fields,
            validation,
            `goodsShipment.documentsAuthorisations.additionalInformation.${index}.additionalInformationText`,
            'Text'
        );

        if (fields.length > 0) {
            errors.push({ name: `Documents Additional Information ${index + 1}`, fields });
        }
    });
};

const parsePreviousDocument = (errors: ParsedCard[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const documentsAuthorisations = goodsShipment?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const docuemnts = (documentsAuthorisations?.previousDocument as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<PreviousDocument>, index) => {
        const fields: ParsedField[] = [];

        handleField(
            fields,
            validation,
            `goodsShipment.documentsAuthorisations.previousDocument.${index}.previousDocumentReferenceNumber`,
            'Reference Number'
        );
        handleField(
            fields,
            validation,
            `goodsShipment.documentsAuthorisations.previousDocument.${index}.previousDocumentType`,
            'Type'
        );

        if (fields.length > 0) {
            errors.push({ name: `Previous Document ${index + 1}`, fields });
        }
    });
};

const parseTransportDocument = (errors: ParsedCard[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const valuationInformation = goodsShipment?.valuationInformation as FormikErrors<ValuationInformation>;
    const docuemnts = (valuationInformation?.transportDocument as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<TransportDocument>, index) => {
        const fields: ParsedField[] = [];

        handleField(
            fields,
            validation,
            `goodsShipment.valuationInformation.transportDocument.${index}.transportDocumentReferenceNumber`,
            'Reference Number'
        );
        handleField(
            fields,
            validation,
            `goodsShipment.valuationInformation.transportDocument.${index}.transportDocumentType`,
            'Type'
        );

        if (fields.length > 0) {
            errors.push({ name: `Transport Document ${index + 1}`, fields });
        }
    });
};

const parseTransportCosts = (errors: ParsedCard[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const fields: ParsedField[] = [];

    handleField(
        fields,
        validation,
        `goodsShipment.valuationInformation.transportAndInsuranceCostsToTheDestinationAmount`,
        'Transport And Insurance Costs to the Destination Amount'
    );
    handleField(
        fields,
        validation,
        `goodsShipment.valuationInformation.transportAndInsuranceCostsToTheDestinationCurrency`,
        'Transport And Insurance Costs to the Destination Currency'
    );

    if (fields.length > 0) {
        errors.push({ name: 'Transport Costs', fields });
    }
};

const parseLocationOfGoods = (errors: ParsedCard[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const fields: ParsedField[] = [];

    handleField(fields, validation, `goodsShipment.locationOfGoodsUnlocode`, 'Unlocode');
    handleField(fields, validation, `goodsShipment.locationOfGoodsAdditionalIdentifier`, 'Additional Identifier');
    handleField(fields, validation, `goodsShipment.locationOfGoodsTypeOfLocation`, 'Type of Location code');
    handleField(
        fields,
        validation,
        `goodsShipment.locationOfGoodsQualifierOfIdentification`,
        'Qualifier of the identification'
    );
    handleField(fields, validation, `goodsShipment.locationOfGoodsAddress.streetAndNumber`, 'Street Number');
    handleField(fields, validation, `goodsShipment.locationOfGoodsAddress.city`, 'City');
    handleField(fields, validation, `goodsShipment.locationOfGoodsAddress.postCode`, 'Post Code');
    handleField(fields, validation, `goodsShipment.locationOfGoodsAddress.country`, 'Country');

    if (fields.length > 0) {
        errors.push({ name: 'Location Of Goods', fields });
    }
};

const parseDeclarationItems = (errors: ParsedItem[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    if (!goodsShipment) {
        return;
    }

    const items = (goodsShipment?.governmentAgencyGoodsItem as unknown as any[]) || [];

    items.forEach((itemErrors: FormikErrors<GoodsShipmentItem> | undefined, index) => {
        if (itemErrors === undefined) return;
        const item = {
            index: index + 1,
            errors: [],
        };

        parseItemsGoodsInformation(item.errors, itemErrors);
        parseAdditionalProcedureCode(item.errors, itemErrors);
        parseItemBox44(item.errors, itemErrors);
        parseItemsAdditionalInformation(item.errors, itemErrors);
        parseItemPreviousDocument(item.errors, itemErrors);
        parseItemValuationInformation(item.errors, itemErrors);
        parseItemTransportDocuments(item.errors, itemErrors);
        parseItemExporter(item.errors, itemErrors);
        parseItemFiscalReferences(item.errors, itemErrors);
        parseItemDutiesAndTaxes(item.errors, itemErrors);
        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseH7ProductTemplate = (validation: FormikErrors<GoodsShipment>) => {
    const productTemplateErrors: ParsedCard[] = [];

    parseItemsGoodsInformation(productTemplateErrors, validation);
    parseAdditionalProcedureCode(productTemplateErrors, validation);
    parseItemBox44(productTemplateErrors, validation);
    parseItemsAdditionalInformation(productTemplateErrors, validation);
    parseItemPreviousDocument(productTemplateErrors, validation);
    parseItemValuationInformation(productTemplateErrors, validation);
    parseItemTransportDocuments(productTemplateErrors, validation);
    parseItemExporter(productTemplateErrors, validation);
    parseItemFiscalReferences(productTemplateErrors, validation);
    parseItemDutiesAndTaxes(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseItemsGoodsInformation = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fields: ParsedField[] = [];

    handleField(fields, validation, 'descriptionOfGoods', 'Description of Goods');
    handleField(fields, validation, 'commodityCodeHarmonizedSystemSubHeadingCode', 'Commodity Code');
    handleField(fields, validation, 'documentsAuthorisation.referenceNumberUcr', 'Reference Number UCR');
    handleField(fields, validation, 'grossMass', 'Gross Mass');
    handleField(fields, validation, 'supplementaryUnits', 'Supplementary Units');
    handleField(fields, validation, 'packagingNumberOfPackages', 'Number of Packages');

    if (fields.length > 0) {
        errors.push({ name: 'Goods Information', fields });
    }
};

const parseAdditionalProcedureCode = (
    errors: ParsedCard[],
    validation: FormikErrors<GoodsShipmentItem> | undefined
) => {
    const additionalProcedure = validation?.additionalProcedure as unknown as AdditionalProcedure[] | string;
    const additionalProcedureIsArray = Array.isArray(additionalProcedure);
    if (!additionalProcedureIsArray || (additionalProcedureIsArray && !additionalProcedure.length)) {
        errors.push({
            name: 'Additional Procedure Code',
            fields: [{ name: 'Additional Procedure Code', message: 'Needs at least one item' }],
        });
    } else if (validation) {
        parseFieldErrorsForMultipleCard(
            'Additional Procedure Code',
            'additionalProcedure',
            ['additionalProcedure'],
            errors,
            validation
        );
    }
};

const parseItemBox44 = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const documentsAuthorisations = validation?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const box44 = (documentsAuthorisations?.supportingDocument as unknown as any[]) || [];

    box44.forEach((box44Item: FormikErrors<SupportingDocuments>, index) => {
        const fields: ParsedField[] = [];

        handleField(
            fields,
            validation,
            `documentsAuthorisations.supportingDocument.${index}.supportingDocumentType`,
            'Document Type'
        );
        handleField(
            fields,
            validation,
            `documentsAuthorisations.supportingDocument.${index}.supportingDocumentReferenceNumber`,
            'Document Identifier'
        );

        if (fields.length > 0) {
            errors.push({ name: `Additional documents and references (Box 44) ${index + 1}`, fields });
        }
    });
};

const parseItemsAdditionalInformation = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const documentsAuthorisations = validation?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const docuemnts = (documentsAuthorisations?.additionalInformation as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<AdditionalInformation>, index) => {
        const fields: ParsedField[] = [];

        handleField(
            fields,
            validation,
            `documentsAuthorisations.additionalInformation.${index}.additionalInformationCode`,
            'Code'
        );
        handleField(
            fields,
            validation,
            `documentsAuthorisations.additionalInformation.${index}.additionalInformationText`,
            'Text'
        );

        if (fields.length > 0) {
            errors.push({ name: `Documents Additional Information ${index + 1}`, fields });
        }
    });
};

const parseItemPreviousDocument = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const documentsAuthorisations = validation?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const docuemnts = (documentsAuthorisations?.previousDocument as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<PreviousDocument>, index) => {
        const fields: ParsedField[] = [];

        handleField(
            fields,
            validation,
            `documentsAuthorisations.previousDocument.${index}.previousDocumentReferenceNumber`,
            'Reference Number'
        );
        handleField(
            fields,
            validation,
            `documentsAuthorisations.previousDocument.${index}.previousDocumentType`,
            'Document Type'
        );

        if (fields.length > 0) {
            errors.push({ name: `Previous Document ${index + 1}`, fields });
        }
    });
};

const parseItemValuationInformation = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fields: ParsedField[] = [];
    const itemAmountInvoicedIntrinsicValue =
        validation.itemAmountInvoicedIntrinsicValue as FormikErrors<ValuationInformation>;

    handleField(
        fields,
        validation,
        'itemAmountInvoicedIntrinsicValue.transportAndInsuranceCostsToTheDestinationAmount',
        'Transport And Insurance Costs To The Destination Amount'
    );
    handleField(
        fields,
        validation,
        'itemAmountInvoicedIntrinsicValue.transportAndInsuranceCostsToTheDestinationCurrency',
        'Transport And Insurance Costs To The Destination Currency'
    );
    handleField(
        fields,
        validation,
        'itemAmountInvoicedIntrinsicValue.itemAmountInvoicedIntrinsicValueAmount',
        'Item Amount Invoiced Intrinsic Value Amount'
    );
    handleField(
        fields,
        validation,
        'itemAmountInvoicedIntrinsicValue.itemAmountInvoicedIntrinsicValueCurrency',
        'Item Amount Invoiced Intrinsic Value Currency'
    );

    if (fields.length > 0) {
        errors.push({ name: 'Valuation Information', fields });
    }
};

const parseItemTransportDocuments = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const itemAmountInvoicedIntrinsicValue =
        validation.itemAmountInvoicedIntrinsicValue as FormikErrors<ValuationInformation>;
    const transportDocuments = (itemAmountInvoicedIntrinsicValue?.transportDocument as unknown as any[]) || [];

    transportDocuments.forEach((document: FormikErrors<TransportDocument>, index) => {
        const fields: ParsedField[] = [];

        handleField(
            fields,
            validation,
            `itemAmountInvoicedIntrinsicValue.transportDocument.${index}.transportDocumentReferenceNumber`,
            'Reference Number'
        );
        handleField(
            fields,
            validation,
            `itemAmountInvoicedIntrinsicValue.transportDocument.${index}.transportDocumentType`,
            'Type'
        );

        if (fields.length > 0) {
            errors.push({ name: `Transport Document ${index + 1}`, fields });
        }
    });
};

const parseItemExporter = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const exporterValidation = validation?.exporter as FormikErrors<DeclarationCustomer>;
    const fields: ParsedField[] = parseDeclarationCustomer(exporterValidation, 'Exporter');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseItemFiscalReferences = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fiscalReferences = (validation.additionalFiscalReference as unknown as any[]) || [];

    fiscalReferences.forEach((document: FormikErrors<AdditionalFiscalReference>, index) => {
        const fields: ParsedField[] = [];

        handleField(
            fields,
            document,
            `additionalFiscalReference.${index}.additionalFiscalReferenceRole`,
            'Reference VAT'
        );
        handleField(
            fields,
            document,
            `additionalFiscalReference.${index}.additionalFiscalReferenceVatIdentificationNumber`,
            'Reference Id'
        );

        if (fields.length > 0) {
            errors.push({ name: `Fiscal Reference ${index + 1}`, fields });
        }
    });
};

const parseItemDutiesAndTaxes = (errors: ParsedCard[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fiscalReferences = (validation.dutiesAndTaxes as unknown as any[]) || [];

    fiscalReferences.forEach((payment: FormikErrors<Payment>, index) => {
        const fields: ParsedField[] = [];

        handleField(fields, payment, `dutiesAndTaxes.${index}.dutiesAndTaxesMethodOfPayment`, 'Method of Payment');
        handleField(fields, payment, `dutiesAndTaxes.${index}.boxTaxType`, 'Box Tax Type');
        handleField(fields, payment, `dutiesAndTaxes.${index}.boxTaxBaseUnit`, 'Box Tax Base Unit');
        handleField(fields, payment, `dutiesAndTaxes.${index}.boxAmount`, 'Box Amount');

        if (fields.length > 0) {
            errors.push({ name: `Duties And Taxes ${index + 1}`, fields });
        }
    });
};

const parseFieldErrorsForMultipleCard = (
    cardName: string,
    cardPath: string,
    paths: (string | { [path: string]: string })[],
    errors: ParsedCard[],
    validation: FormikErrors<any>
) => {
    let hasErrors = false;

    (get(validation, cardPath) as any)?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError(paths, val);

        if (!hasErrors && fields.length > 0) {
            errors.push({ name: cardName, fields: undefined });
            hasErrors = true;
        }

        if (fields.length > 0) {
            errors.push({ name: `${cardName} ${index + 1}`, fields });
        }
    });
};
