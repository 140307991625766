import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { H6, Text, TextExtraSmall, TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';
export const CardContent = styled.div`
    margin-bottom: 3.2rem;
    margin-right: 4rem;
`;
export const OptionText = styled(TextSmall)`
    width: 50rem;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
`;
export const CountryCol = styled(Col)``;
export const SearchCol = styled(Col)``;
export const StyledH6 = styled(H6)`
    font-weight: normal;
`;
export const InfoDiv = styled.div`
    margin-top: 1.4rem;
    width: 65%;
`;
export const DateDiv = styled.div`
    width: 35%;
`;
export const BackButton = styled(Text)`
    cursor: pointer;
`;
export const CodeButton = styled(TextExtraSmall)`
    margin-left: 0.5rem;
    cursor: pointer;
    color: ${colors.purpleBlue};
`;
export const CollapseIcon = styled(DownOutlined)`
    cursor: pointer;
    color: ${colors.purpleBlue};
    margin-right: 0.8rem;
`;
export const ExpandIcon = styled(RightOutlined)`
    cursor: pointer;
    color: ${colors.purpleBlue};
    margin-right: 0.8rem;
`;
export const ListText = styled(TextExtraSmall)`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
`;
interface RowProps {
    margin?: string;
}
export const StyledRow = styled(Row)<RowProps>`
    border-bottom: 1px solid ${colors.darkGrey2};
    min-height: 3.8rem;
    cursor: pointer;
    margin-left: ${({ margin }) => (margin ? `${margin}rem` : '0rem')};
    &:hover {
        background: ${colors.lightGrey};
    }
`;
export const DescriptionCol = styled(Col)`
    padding-right: 1.6rem;
`;
export const Content = styled.div`
    padding-right: 0.4rem;
`;
export const CodeHeading = styled(Row)`
    margin-top: 4.3rem;
    margin-bottom: 3.5rem;
`;
export const SectionsRow = styled(Row)`
    margin-top: 2.5rem;
    margin-bottom: 1.7rem;
`;
export const DetailsHeading = styled.div`
    margin-top: 4.3rem;
`;
export const DetailsRow = styled(Row)`
    margin-top: 1.6rem;
`;
export const DetailsExportCol = styled(Col)`
    padding-left: 4rem;
    border-left: 1px solid ${colors.darkGrey2};
`;

export const Measurements = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Measurement = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    background-color: ${colors.lightGrey};
    padding: 0.5rem;

    & + & {
        margin-top: 1rem;
    }
`;

export const MeasurementInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MeasurementDiv = styled.div`
    & + & {
        margin-top: 2rem;
    }
`;
export const FlexDiv = styled.div`
    display: flex;
    margin-bottom: 1.6rem;
`;
export const SelectCodeButton = styled(TextExtraSmall)`
    margin-left: 3.2rem;
    cursor: pointer;
    color: ${colors.purpleBlue};
    &.disabled {
        cursor: not-allowed;
        color: ${colors.grey};
    }
`;
export const Title = styled(H6)`
    margin-bottom: 1.6rem;
`;
export const HighlightedText = styled.span<{ highlighted?: boolean }>`
    background: ${({ highlighted }) => (highlighted ? colors.yellow : 'none')};
    margin-right: 0.5rem;
`;
export const DutyText = styled(ListText)`
    text-decoration: underline;
    margin-left: 0.5rem;
`;
