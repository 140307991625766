import { ColumnsType } from 'antd/lib/table';

const getNCTSProductsTableColumns: (handleTableValues: (index: number, value: any) => any) => ColumnsType<any> = (
    handleTableValues
) => [
    {
        title: 'Harmonized System Sub-Heading Code',
        key: 'harmonized_code',
        render: (_, record: any, index: number) => {
            return handleTableValues(index, record?.commodity?.commodityCode?.harmonizedSystemSubHeadingCode ?? '-');
        },
        sorter: (a: any, b: any) => {
            return (
                a.record?.commodity?.commodityCode?.harmonizedSystemSubHeadingCode -
                b.record?.commodity?.commodityCode?.harmonizedSystemSubHeadingCode
            );
        },
    },
    {
        title: 'Combined Nomenclature Code',
        key: 'combined_nomenclature_code',
        render: (_, record: any, index: number) => {
            return handleTableValues(index, record?.commodity?.commodityCode?.combinedNomenclatureCode ?? '-');
        },
        sorter: (a: any, b: any) => {
            return (
                a.record?.commodity?.commodityCode?.harmonizedSystemSubHeadingCode -
                b.record?.commodity?.commodityCode?.harmonizedSystemSubHeadingCode
            );
        },
    },
    {
        title: 'Description of Goods',
        key: 'description_of_goods',
        render: (_, record: any, index: number) => {
            return handleTableValues(index, record?.commodity?.descriptionOfGoods ?? '-');
        },
        sorter: (a: any, b: any) => {
            return a.commodity?.descriptionOfGoods ?? '-'.localeCompare(b.commodity?.descriptionOfGoods ?? '-');
        },
    },
    {
        title: 'Net Mass',
        key: 'net_mass',
        render: (_, record: any, index: number) => {
            return handleTableValues(index, record?.commodity?.goodsMeasure?.netMass);
        },
        sorter: (a: any, b: any) => {
            return a.commodity?.goodsMeasure?.netMass - b.commodity?.goodsMeasure?.netMass;
        },
    },
];

export default getNCTSProductsTableColumns;
