export interface TaricCodeResponse {
    code: string;
    description: string;
    children?: TaricCodeResponse[];
}
export interface TaricCode {
    chapter: string;
    section: string;
    taricCodeResponses: TaricCodeResponse[];
}
export interface Amount {
    code: string;
    dutyExpression: string;
    measureType: string;
    measureExclusions: string[];
    vat: string;
}
export interface TaricCodeAmount {
    importAmounts: Amount;
    exportAmounts: Amount;
}
export interface TaricCodeChapter {
    number: string;
    title: string;
    code: string;
}
export interface TaricCodeSection {
    number: string;
    title: string;
    chapters: TaricCodeChapter[];
}
export enum TaricCodesType {
    EU = 'EU',
    UK = 'UK',
}
export enum MeasureType {
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT',
    GENERAL = 'GENERAL',
}
export interface Condition {
    number: string;
    condition: string;
    certificate: string;
    action: string;
    certificateDescription: string;
}
export interface CountryResponses {
    measureType: string;
    duty: string;
    exclusions: string[];
    conditions: Condition[];
    legalBase: string;
}
export interface TaricMeasurement {
    journey: string;
    countryResponses: CountryResponses[];
}
