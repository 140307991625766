const REMOVE = null;

export const irelandNctsD3ValidationItem = {
    childValidators: {
        countryOfDispatch: REMOVE,

        commodity: {
            childValidators: {
                goodsMeasure: {
                    childValidators: {
                        netMass: REMOVE,
                    },
                },
            },
        },

        previousDocument: {
            childValidators: {
                complementOfInformation: REMOVE,
            },
        },
        supportingDocument: {
            childValidators: {
                complementOfInformation: REMOVE,
            },
        },
    },
};

export const irelandNctsD3ValidationHeader = {
    childValidators: {
        transitOperation: {
            childValidators: {
                tirCarnetNumber: REMOVE,

                security: REMOVE,
                reducedDatasetIndicator: REMOVE,
                specificCircumstanceIndicator: REMOVE,

                bindingItinerary: REMOVE,
            },
        },
        customsOfficeOfTransitDeclared: REMOVE,
        customsOfficeOfExitForTransitDeclared: REMOVE,

        guarantee: REMOVE,
        consignment: {
            childValidators: {
                countryOfDispatch: REMOVE,
                inlandModeOfTransport: REMOVE,
                modeOfTransportAtTheBorder: REMOVE,

                consignor: REMOVE,

                countryOfRoutingOfConsignment: REMOVE,

                activeBorderTransportMeans: REMOVE,

                previousDocument: {
                    childValidators: {
                        complementOfInformation: REMOVE,
                    },
                },
                supportingDocument: {
                    childValidators: {
                        complementOfInformation: REMOVE,
                    },
                },

                houseConsignment: {
                    childValidators: {
                        countryOfDispatch: REMOVE,

                        consignor: REMOVE,

                        previousDocument: {
                            childValidators: {
                                complementOfInformation: REMOVE,
                            },
                        },
                        supportingDocument: {
                            childValidators: {
                                complementOfInformation: REMOVE,
                            },
                        },
                    },
                },
            },
        },
    },
};
