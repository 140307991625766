import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { FC, isValidElement } from 'react';
import { Icon, ListRow, ListRowControls, StyledTextSmall } from '../CardItemRow.styles';
import { isBoolean, isEmpty, kebabCase } from 'lodash';

interface PropsRowCol {
    field: string;
    value: string | string[];
}

interface PropsRow {
    cols: PropsRowCol[];
    onDelete?: Function;
    onEdit: Function;
    viewOnly: boolean | undefined;
    condensed?: boolean;
    last?: boolean;
    hasError?: boolean;
    cardTitle?: string;
}

type Data = { field: string; value: string | undefined };

interface Props {
    data: Data[][];
    onDelete: (index: number, obj: Data[]) => void;
    onEdit: (index: number, obj: Data[]) => void;
    viewOnly: boolean | undefined;
    required?: boolean;
    condensed?: boolean;
    errors?: any[];
    cardTitle?: string;
}

const CardItemRow: FC<PropsRow> = ({ cols, onEdit, onDelete, viewOnly, condensed, last, hasError, cardTitle }) => {
    const parseValue = (value: string | string[] | React.ReactNode[]) => {
        if (!value && isEmpty(value)) return '-';

        if (Array.isArray(value)) {
            if (value.some(isValidElement)) return value;

            return value.join(', ');
        }

        if (isBoolean(value)) return value ? 'Yes' : 'No';

        return value;
    };

    return (
        <>
            <ListRow hasError={hasError} data-testid={`${cardTitle}-card-row`}>
                <div style={{ display: 'flex', gap: '0.5rem 5rem', flexWrap: 'wrap' }}>
                    {cols.map((obj, index) => {
                        return (
                            <div key={index}>
                                <StyledTextSmall
                                    data-testid={kebabCase(obj.field)}
                                    style={{ display: 'flex', width: 'auto', alignItems: 'start' }}
                                >
                                    <span>{obj.field}: </span>
                                    <span style={{ marginLeft: '7px' }}>{parseValue(obj.value)} </span>
                                </StyledTextSmall>
                            </div>
                        );
                    })}
                </div>
                {!viewOnly && (
                    <ListRowControls>
                        <Icon onClick={() => onEdit()}>
                            <EditOutlined />
                        </Icon>
                        {onDelete && (
                            <Icon onClick={() => onDelete?.()}>
                                <DeleteOutlined />
                            </Icon>
                        )}
                    </ListRowControls>
                )}
            </ListRow>
            {!last && <Divider style={{ margin: condensed ? '0.5rem 0' : '1rem 0 2rem 0' }} />}
        </>
    );
};

const CardList: FC<Props> = ({ data, onDelete, onEdit, viewOnly, required, condensed, errors, cardTitle }) => {
    return (
        <div style={{ marginTop: required ? '2rem' : '0' }}>
            {data?.map((obj, index) => {
                const hasError = errors && !isEmpty(errors?.at(index));
                return (
                    <CardItemRow
                        key={index}
                        hasError={hasError}
                        cols={obj as PropsRowCol[]}
                        viewOnly={viewOnly}
                        onDelete={
                            (required && index === 0) || (cardTitle === 'house-consignment' && data.length === 1)
                                ? undefined
                                : () => onDelete(index, obj)
                        }
                        onEdit={() => onEdit(index, obj)}
                        last={index === data.length - 1}
                        condensed={condensed}
                        cardTitle={cardTitle}
                    />
                );
            })}
        </div>
    );
};

export default CardList;
