import { useMemo } from '@storybook/addons';
import ChevronLeftIcon from 'components/icons/ChevronLeft';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import AisBusinessRules from './ais-documentation/AisBusinessRules';
import { BackButton, StyledHelpView } from './BusinessRules.styles';

export enum DocumentationType {
    AIS = 'AIS',
}

interface Props {
    type: DocumentationType;
}

const HelpView: FC<Props> = ({ type }) => {
    const navigate = useNavigate();

    const rules = useMemo(() => {
        switch (type) {
            case DocumentationType.AIS:
                return <AisBusinessRules />;
            default:
                return <></>;
        }
    }, [type]);

    return (
        <StyledHelpView>
            <BackButton onClick={() => navigate('/home')}>
                <ChevronLeftIcon />
                <span> Back to dashboard</span>
            </BackButton>
            {rules}
        </StyledHelpView>
    );
};
export default HelpView;
