import { Notifiable } from '../declaration';
import { NCTSMessageTypes } from '../enums/common/declaration-types';
import { NCTSInformationExchangeMessages } from '../enums/ireland/ncts/NCTSInformationExchangeMessages';

export const nctsNameLabels = [
    {
        key: NCTSMessageTypes.D1,
        value: '(D1) Special procedure – Transit declaration.',
    },
    {
        key: NCTSMessageTypes.D2,
        value: '(D2) Special procedure –Transit declaration with reduced dataset – (Transport by rail, air and maritime transport.',
    },
    {
        key: NCTSMessageTypes.D3,
        value: '(D3) Special procedure – Transit – Use of an electronic transport document as customs declaration – (Transport by air and maritime transport).',
    },
    {
        key: NCTSMessageTypes.D4,
        value: '(D4) Presentation Notification in relation to the pre-lodged transit declaration.',
    },
] as const;

export interface NctsDeclarationNotification {
    notification: {
        messageType: NCTSInformationExchangeMessages;
    };
    messageType: NCTSInformationExchangeMessages;
    rawMessage: string;
}

export interface CC022CNotification extends NctsDeclarationNotification {
    notification: {
        messageType: NCTSInformationExchangeMessages.CC022C;
        FunctionalError: {
            errorCode: string;
            errorPointer: string;
            errorReason: string;
            originalAttributeValue: string;
        }[];
    };
}

export interface CC055CNotification extends NctsDeclarationNotification {
    notification: {
        messageType: NCTSInformationExchangeMessages.CC055C;
        transitOperation: {
            mrn: string;
            declarationAcceptanceDate: string;
        };
        customsOfficeOfDeparture: {
            referenceNumber: string;
        };
        holderOfTheTransitProcedure: {
            identificationNumber: string;
            name: string;
            address: {
                streetAndNumber: string;
                postcode: string;
                city: string;
                country: string;
            };
        };
        guaranteeReference: {
            sequenceNumber: string;
            grn: string;
            invalidGuaranteeReason: {
                sequenceNumber: string;
                code: string;
                text: string;
            }[];
        }[];
    };
}
export interface TR015VNotification extends NctsDeclarationNotification {
    notification: {
        messageType: NCTSInformationExchangeMessages.TR015V;
        messageSender: string;
        messageRecipient: string;
        preparationDateAndTime: string;
        messageIdentification: string;
        correlationIdentifier: string;
        Declaration: {
            declarationType: string;
            additionalDeclarationType: string;
            lrn: string;
            mrn: string;
            declarationAcknowledgmentDate: string;
            customsOfficeOfDeparture: {
                referenceNumber: string;
            };
            customsOfficeOfDestinationDeclared: {
                referenceNumber: string;
            };
            customsOfficeOfTransitDeclared: {
                sequenceNumber: string;
                referenceNumber: string;
            }[];
            customsOfficeOfExitForTransitDeclared: {
                sequenceNumber: string;
                referenceNumber: string;
            }[];
        };
        HolderOfTheTransitProcedure: {
            identificationNumber: string;
            name: string;
            address: {
                streetAndNumber: string;
                postcode: string;
                city: string;
                country: string;
            };
            tirholderIdentificationNumber: string;
        };
        Representative: {
            identificationNumber: string;
            status: string;
        };
    };
}

export interface NctsDeclaration extends Notifiable<NctsDeclarationNotification> {
    messageSender: string;
    messageRecipient: string;
    preparationDateAndTime: string;
    messageIdentification: string;
    messageType: string;
    correlationIdentifier: string;
    transitOperation: {
        lrn: string;
        declarationType: string;
        additionalDeclarationType: string;
        tirCarnetNumber: string;
        presentationOfTheGoodsDateAndTime: string;
        security: string;
        reducedDatasetIndicator: string;
        specificCircumstanceIndicator: string;
        communicationLanguageAtDeparture: string;
        bindingItinerary: string;
        limitDate: string;
    };
    authorisation: {
        sequenceNumber: string;
        type: string;
        referenceNumber: string;
    }[];
    customsOfficeOfDeparture: {
        referenceNumber: string;
    };
    customsOfficeOfDestinationDeclared: {
        referenceNumber: string;
    };
    customsOfficeOfTransitDeclared: {
        sequenceNumber: string;
        referenceNumber: string;
        arrivalDateAndTimeEstimated: string;
    }[];
    customsOfficeOfExitForTransitDeclared: {
        sequenceNumber: string;
        referenceNumber: string;
    }[];
    holderOfTheTransitProcedure: {
        identificationNumber: string;
        tirHolderIdentificationNumber: string;
        name: string;
        address: {
            city: string;
            country: string;
            streetAndNumber: string;
            postCode: string;
        };
        contactPerson: {
            name: string;
            phoneNumber: string;
            emailAddress: string;
        };
    };
    representative: {
        identificationNumber: string;
        status: string;
        contactPerson: {
            name: string;
            phoneNumber: string;
            emailAddress: string;
        };
    };
    guarantee: {
        sequenceNumber: string;
        guaranteeType: string;
        otherGuaranteeReference: string;
        guaranteeReference: {
            sequenceNumber: string;
            grn: string;
            accessCode: string;
            amountToBeCovered: number;
            currency: string;
        }[];
    }[];
    consignment: {
        countryOfDispatch: string;
        countryOfDestination: string;
        containerIndicator: string;
        inlandModeOfTransport: string;
        modeOfTransportAtTheBorder: string;
        grossMass: number;
        referenceNumberUCR: string;
        carrier: {
            identificationNumber: string;
            contactPerson: {
                name: string;
                phoneNumber: string;
                emailAddress: string;
            };
        };
        consignor: {
            identificationNumber: string;
            name: string;
            address: {
                city: string;
                country: string;
                streetAndNumber: string;
                postCode: string;
            };
            contactPerson: {
                name: string;
                phoneNumber: string;
                emailAddress: string;
            };
        };
        consignee: {
            identificationNumber: string;
            name: string;
            address: {
                city: string;
                country: string;
                streetAndNumber: string;
                postCode: string;
            };
        };
        additionalSupplyChainActor: {
            sequenceNumber: string;
            role: string;
            identificationNumber: string;
        }[];
        transportEquipment: {
            sequenceNumber: string;
            containerIdentificationNumber: string;
            numberOfSeals: number;
            seal: {
                sequenceNumber: string;
                identifier: string;
            }[];
            goodsReference: {
                sequenceNumber: string;
                declarationGoodsItemNumber: number;
            }[];
        }[];
        locationOfGoods: {
            sequenceNumber: string;
            typeOfLocation: string;
            qualifierOfIdentification: string;
            authorisationNumber: string;
            additionalIdentifier: string;
            unLocode: string;
            customsOffice: {
                referenceNumber: string;
            };
            gnss: {
                latitude: string;
                longitude: string;
            };
            economicOperator: {
                identificationNumber: string;
            };
            address: {
                city: string;
                country: string;
                streetAndNumber: string;
                postCode: string;
            };
            postcodeAddress: {
                houseNumber: string;
                postcode: string;
                country: string;
            };
            contactPerson: {
                name: string;
                phoneNumber: string;
                emailAddress: string;
            };
        };
        departureTransportMeans: {
            sequenceNumber: string;
            typeOfIdentification: string;
            identificationNumber: string;
            nationality: string;
        }[];
        countryOfRoutingOfConsignment: {
            sequenceNumber: string;
            country: string;
        }[];
        activeBorderTransportMeans: {
            sequenceNumber: string;
            customsOfficeAtBorderReferenceNumber: string;
            typeOfIdentification: string;
            identificationNumber: string;
            nationality: string;
            conveyanceReferenceNumber: string;
        }[];
        placeOfLoading: {
            unLocode: string;
            country: string;
            location: string;
        };
        placeOfUnloading: {
            unLocode: string;
            country: string;
            location: string;
        };
        previousDocument: {
            sequenceNumber: string;
            type: string;
            referenceNumber: string;
            goodsItemNumber: number;
            typeOfPackages: string;
            numberOfPackages: number;
            measurementUnitAndQualifier: string;
            quantity: number;
            complementOfInformation: string;
        }[];
        supportingDocument: {
            sequenceNumber: string;
            type: string;
            referenceNumber: string;
            documentLineItemNumber: number;
            complementOfInformation: string;
        }[];
        transportDocument: {
            sequenceNumber: string;
            type: string;
            referenceNumber: string;
        }[];
        additionalReference: {
            sequenceNumber: string;
            type: string;
            referenceNumber: string;
        }[];
        additionalInformation: {
            sequenceNumber: string;
            code: string;
            text: string;
        }[];
        transportCharges: {
            methodOfPayment: string;
        };
        houseConsignment: {
            id: string;
            sequenceNumber: string;
            countryOfDispatch: string;
            grossMass: number;
            referenceNumberUCR: string;
            consignor: {
                identificationNumber: string;
                name: string;
                address: {
                    city: string;
                    country: string;
                    streetAndNumber: string;
                    postCode: string;
                };
                contactPerson: {
                    name: string;
                    phoneNumber: string;
                    emailAddress: string;
                };
            };
            consignee: {
                identificationNumber: string;
                name: string;
                address: {
                    city: string;
                    country: string;
                    streetAndNumber: string;
                    postCode: string;
                };
            };
            additionalSupplyChainActor: {
                sequenceNumber: string;
                role: string;
                identificationNumber: string;
            }[];
            departureTransportMeans: {
                sequenceNumber: string;
                typeOfIdentification: string;
                identificationNumber: string;
                nationality: string;
            }[];
            previousDocument: {
                sequenceNumber: string;
                type: string;
                referenceNumber: string;
                goodsItemNumber: number;
                typeOfPackages: string;
                numberOfPackages: number;
                measurementUnitAndQualifier: string;
                quantity: number;
                complementOfInformation: string;
            }[];
            supportingDocument: {
                sequenceNumber: string;
                type: string;
                referenceNumber: string;
                documentLineItemNumber: number;
                complementOfInformation: string;
            }[];
            transportDocument: {
                sequenceNumber: string;
                type: string;
                referenceNumber: string;
            }[];
            additionalReference: {
                sequenceNumber: string;
                type: string;
                referenceNumber: string;
            }[];
            additionalInformation: {
                sequenceNumber: string;
                code: string;
                text: string;
            }[];
            transportCharges: {
                methodOfPayment: string;
            };
            consignmentItem: {
                goodsItemNumber: string;
                declarationGoodsItemNumber: number;
                declarationType: string;
                countryOfDispatch: string;
                countryOfDestination: string;
                referenceNumberUCR: string;
                consignee: {
                    identificationNumber: string;
                    name: string;
                    address: {
                        city: string;
                        country: string;
                        streetAndNumber: string;
                        postCode: string;
                    };
                };
                additionalSupplyChainActor: {
                    sequenceNumber: string;
                    role: string;
                    identificationNumber: string;
                }[];
                commodity: {
                    descriptionOfGoods: string;
                    cusCode: string;
                    commodityCode: {
                        harmonizedSystemSubHeadingCode: string;
                        combinedNomenclatureCode: string;
                        taricAdditionalCode: {
                            sequenceNumber: string;
                            taricAdditionalCode: string;
                        }[];
                    };
                    dangerousGoods: {
                        sequenceNumber: string;
                        unNumber: string;
                    }[];
                    goodsMeasure: {
                        grossMass: number;
                        netMass: number;
                        supplementaryUnits: number;
                    };
                };
                packaging: {
                    sequenceNumber: string;
                    typeOfPackages: string;
                    numberOfPackages: number;
                    shippingMarks: string;
                }[];
                previousDocument: {
                    sequenceNumber: string;
                    type: string;
                    referenceNumber: string;
                    goodsItemNumber: number;
                    typeOfPackages: string;
                    numberOfPackages: number;
                    measurementUnitAndQualifier: string;
                    quantity: number;
                    complementOfInformation: string;
                }[];
                supportingDocument: {
                    sequenceNumber: string;
                    type: string;
                    referenceNumber: string;
                    documentLineItemNumber: number;
                    complementOfInformation: string;
                }[];
                transportDocument: {
                    sequenceNumber: string;
                    type: string;
                    referenceNumber: string;
                }[];
                additionalReference: {
                    sequenceNumber: string;
                    type: string;
                    referenceNumber: string;
                }[];
                additionalInformation: {
                    sequenceNumber: string;
                    code: string;
                    text: string;
                }[];
                transportCharges: {
                    methodOfPayment: string;
                };
            }[];
        }[];
    };
    phaseID: string;
}
