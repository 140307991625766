import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { CustomRoute } from '../../../components/ui/base/breadcrumb';
import Button from '../../../components/ui/base/button/Button';
import Title from '../../../components/ui/base/title/Title';
import useBreadcrumb from '../../../hooks/useBreadcrumb';
import useDrivers from '../../../hooks/useDrivers';
import useSession from '../../../hooks/useSession';
import AddDriver from './AddDriver';
import DriverProgress from './DriverProgress';
import Map from './Map';
import Table from './Table';

const routes: CustomRoute[] = [
    {
        breadcrumbName: 'Drivers',
        path: '',
    },
    {
        breadcrumbName: 'Map',
        path: '/drivers/view-map',
    },
];

const ViewMap: FC<{}> = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { listDrivers } = useDrivers();
    const { customerId } = useSession();

    useEffect(() => {
        setBreadcrumbRoutes(routes);
        listDrivers({ customerId: customerId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const refreshCustomers = () => {
        listDrivers({ customerId: customerId });
    };

    return (
        <>
            <Row>
                <Col span={18}>
                    <Title>Real Time Driver Status</Title>
                </Col>
                <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button>Create new Job</Button>
                    <Button style={{ marginLeft: 20 }} onClick={showModal}>
                        <UserOutlined />
                        Add Driver
                    </Button>
                    <Button style={{ marginLeft: 20 }}>
                        <SettingOutlined />
                        Driver settings
                    </Button>
                </Col>
            </Row>
            <DriverProgress />
            <Map />
            <div style={{ marginTop: 50 }}>
                <Table />
            </div>
            <AddDriver
                refreshCustomers={refreshCustomers}
                open={isModalVisible}
                closeModal={handleCancel}
                handleOk={handleOk}
            />
        </>
    );
};
export default ViewMap;
