import { getTemplates } from 'store/template/client';
import { MessageTypes } from '../../store/declarations/enums/common/declaration-types';

interface Args {
    templateData: {
        country?: string;
        declarationType?: string;
        formType?: MessageTypes;
    };
}
const getAllDeclarationTemplateNames = async ({ templateData }: Args) => {
    const { country, declarationType, formType } = templateData;
    if (!country || !declarationType || !formType) return;
    const templates = await getTemplates(
        country?.toUpperCase() === 'IRELAND' ? 'ireland' : 'uk',
        declarationType?.toLowerCase() as any,
        formType,
        { size: 9999 }
    );
    const templateNames = templates?.list.map(({ templateName }) => templateName!);
    return new Set(templateNames);
};

export default getAllDeclarationTemplateNames;
