import { Card, Col, Form, Row } from 'antd';
import Button from 'components/ui/base/button';
import styled from 'styled-components';
import { colors } from 'theme';
import { InboxOutlined, CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { H5 } from 'components/ui/base/typography';

export const MainDiv = styled.div`
    margin-right: 15.9rem;
`;

export const RowActions = styled(Row)`
    margin-top: 4.1rem;
`;

export const ColActions = styled(Col)``;

export const ColActionsRight = styled(Col)`
    text-align: right;
`;

export const UploadedFileCard = styled(Card)`
    margin-top: 2rem;

    max-width: 40rem;

    border-color: ${colors.darkPurple};
    span {
        color: ${colors.darkPurple};
    }
`;

export const UploadedFileCardInnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
`;

export const FormUpload = styled(Form)`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2rem;
`;

export const CustomRow = styled(Row)`
    &.disabled {
        cursor: not-allowed;
    }
`;

export const DivDownloadTemplate = styled.div<{ disabled?: boolean }>`
    color: ${({ disabled }) => (disabled ? colors.grey : colors.blueLink)};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 2.9rem;
    &:hover {
        text-decoration: ${({ disabled }) => (!disabled ? 'underline' : 'none')};
    }
`;

export const StyledDiv = styled.div`
    margin-top: 3.3rem;
    margin-right: 2.3rem;
`;

export const BackButton = styled(Button)`
    margin-bottom: 2.4rem;
`;

export const DivInformationTemplateFirstRow = styled.div`
    color: ${colors.blueLink};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.9rem;
    color: ${colors.red};
`;

export const InboxOutlinedIcon = styled(InboxOutlined)`
    font-size: 1.6rem;
    color: ${colors.purpleBlue};
`;

export const BrowseSpan = styled.span`
    color: ${colors.purpleBlue};
`;

export const SectionTitle = styled(H5)`
    font-size: 22px;
    &.disabled {
        cursor: not-allowed;
        color: ${colors.darkGrey2};
    }
`;

export const BulkUploadButton = styled(Button)`
    width: 24rem;
`;

export const PreviewIcon = styled(CheckCircleOutlined)`
    margin-right: 2.5rem;
    color: ${colors.lightGreen};
`;

export const CloseIcon = styled(CloseOutlined)`
    font-size: 18px;
    margin-right: 2rem;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
        color: ${colors.darkGrey2};
    }
`;
