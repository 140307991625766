import { PaginatedParams } from 'core/http/pagination';
import { createIndividual, deleteIndividual, editIndividual, getIndividual, listIndividuals } from './client';
import { Individual } from './individual';

export const doCreateIndividual: Function = (individual: Individual) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_INDIVIDUAL_REQUEST' });
    try {
        const payload = await createIndividual(individual);

        dispatch({
            type: 'CREATE_INDIVIDUAL_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_INDIVIDUAL_ERROR', error: e?.response?.data });
        throw e;
    }
};

export const doListIndividuals: Function =
    (params?: Parameters<typeof listIndividuals>[number]) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_INDIVIDUALS_REQUEST' });
        try {
            const payload = await listIndividuals(params);
            dispatch({
                type: 'LIST_INDIVIDUALS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_INDIVIDUALS_ERROR', error: e?.response?.data });
        }
    };

export const doGetIndividual: Function = (individualId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_INDIVIDUAL_REQUEST' });
    try {
        const payload = await getIndividual(individualId);
        dispatch({
            type: 'GET_INDIVIDUAL_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_INDIVIDUAL_ERROR', error: e?.response?.data });
    }
};

export const doEditIndividual: Function =
    (individualId: string, individual: Individual) => async (dispatch: Function) => {
        dispatch({ type: 'EDIT_INDIVIDUAL_REQUEST' });
        try {
            const payload = await editIndividual(individualId, individual);
            dispatch({
                type: 'EDIT_INDIVIDUAL_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'EDIT_INDIVIDUAL_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIndividual: Function = (individualId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_INDIVIDUAL_REQUEST' });
    try {
        const payload = await deleteIndividual(individualId);
        dispatch({
            type: 'DELETE_INDIVIDUAL_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_INDIVIDUAL_ERROR', error: e?.response?.data });
    }
};
