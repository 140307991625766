import { ParsedCard } from '../../../../../store/declaration-form-errors/ParsedForm';
import { UkGvmsRecord } from '../../../../../store/declarations/uk/gvms-declaration';
import { parseFieldErrorsForCard, parseFieldErrorsForMultipleCard } from '../../../../../utils/validationParserUtils';
import { GvmsFormMicCards } from '../enums';

const ukGvmsRecordParser = (record: UkGvmsRecord) => {
    const masterDetailsParsedCards: ParsedCard[] = [];

    parseFieldErrorsForCard(
        'Master Details',
        [
            { haulierType: 'Haulier Type' },
            { isUnaccompanied: 'Unaccompanied?' },
            { vehicleRegNum: 'Vehicle Registration Number' },
            { trailerRegistrationNums: 'Trailer Registration Number' },
            { containerReferenceNums: 'Container Registration Number' },
            { sAndSMasterRefNum: 'S&S MRN' },
        ],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForCard(
        'Planned Crossing',
        [
            { 'gvmsRouteData.departurePortId': 'Port of Departure' },
            { 'gvmsRouteData.arrivalPortId': 'Port of Arrival' },
            { 'gvmsRouteData.carrierId': 'Carrier' },
            { 'plannedCrossing.routeId': 'Route ID' },
            { direction: 'Direction' },
            { 'plannedCrossing.localDateTimeOfDeparture': 'Local Date & Time of Departure' },
        ],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForMultipleCard(
        GvmsFormMicCards.CUSTOMS_DECLARATIONS,
        'customsDeclarations',
        [
            { customsDeclarationId: 'Customs Declaration ID' },
            { customsDeclarationPartId: 'Customs Declaration Part ID' },
            { sAndSMasterRefNum: 'S&S MRN' },
        ],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForMultipleCard(
        'SAD Declarations',
        'sadDeclaration.sadGoods',
        [{ sAndSMasterRefNum: 'SAD Goods References' }],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForMultipleCard(
        GvmsFormMicCards.TRANSIT_DECLARATIONS,
        'transitDeclarations',
        [
            { transitDeclarationId: 'Transit Declaration ID' },
            { isTSAD: 'Is it TSAD?' },
            { sAndSMasterRefNum: 'S&S MRN' },
        ],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForMultipleCard(
        GvmsFormMicCards.TIR_CARNET_DECLARATIONS,
        'tirDeclarations',
        [{ tirCarnetId: 'TIR Carnet ID' }, { sAndSMasterRefNum: 'S&S MRN' }],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForMultipleCard(
        GvmsFormMicCards.ATA_CARNET_DECLARATIONS,
        'ataDeclarations',
        [{ ataCarnetId: 'ATA Carnet ID' }, { sAndSMasterRefNum: 'S&S MRN' }],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForMultipleCard(
        GvmsFormMicCards.EIDR_DECLARATIONS,
        'eidrDeclarations',
        [{ traderEORI: 'EORI' }, { sAndSMasterRefNum: 'S&S MRN' }],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForMultipleCard(
        'Indirect Export Declarations',
        'indirectExportDeclarations',
        [{ eadMasterRefNum: 'MRN' }],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForCard(
        'Empty Vehicle',
        [{ 'emptyVehicle.isOwnVehicle': 'Own Vehicle?' }, { 'emptyVehicle.sAndSMasterRefNum': 'S&S MRN' }],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForCard(
        'Declarations by Conduct',
        [
            { 'dbcDeclaration.isOwnVehicle': 'Own Vehicle?' },
            { 'dbcDeclaration.dbcGoods.0.sAndSMasterRefNum': 'SAD Goods References' },
            { 'dbcDeclaration.dbcGoods.1.sAndSMasterRefNum': 'SAD Goods References' },
            { 'dbcDeclaration.dbcGoods.2.sAndSMasterRefNum': 'SAD Goods References' },
            { 'dbcDeclaration.dbcGoods.3.sAndSMasterRefNum': 'SAD Goods References' },
            { 'dbcDeclaration.dbcGoods.4.sAndSMasterRefNum': 'SAD Goods References' },
            // Hotfix - Handle all indexes of MultipleDeclarationField
        ],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForMultipleCard(
        'Exemption Declarations',
        'exemptionDeclaration.exemptedGoods',
        [{ sAndSMasterRefNum: 'Exempted Goods' }],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForCard(
        'Empty Vehicle',
        [{ 'ukcDeclaration.fpoEORI': 'FPO EORI' }, { 'ukcDeclaration.sAndSMasterRefNum': 'S&S MRN' }],
        masterDetailsParsedCards,
        record
    );

    parseFieldErrorsForCard(
        'UKC Declaration',
        [{ 'ukcDeclaration.fpoEORI': 'EORI' }, { 'ukcDeclaration.sAndSMasterRefNum': 'S&S MRN' }],
        masterDetailsParsedCards,
        record
    );

    return {
        masterDetails: masterDetailsParsedCards,
        items: [],
    };
};

export default ukGvmsRecordParser;
