import { FormikProps, FormikProvider } from 'formik';
import { FC, useReducer, useState } from 'react';
import useFormUtils from 'hooks/useFormUtils';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { getFormikProps, getFullCodelistValue } from 'views/declarations/utils/form-utils';
import { validators } from 'views/declarations/uk/export/validations/validations';
import useCodelists from 'hooks/useCodelists';
import { useOutletContext } from 'react-router-dom';
import useNumberOfItemsModal from 'views/declarations/common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from 'hooks/useProducts';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect';
import { useTranslation } from 'react-i18next';
import useTooltips from 'hooks/useTooltips';
import FormInput from 'components/ui/composed/declarations/formInput';
import { Exchange } from 'store/exchanges/exchange';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import useExchanges from 'hooks/useExchanges';
import { set } from 'lodash';
import AutoFillModal from '../h1/components/AutoFillModal';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';
import useSearchCustomer from 'views/declarations/common/search-customer/useSearchCustomer';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import FormEori from 'components/ui/composed/formEori/FormEori';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';

interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    selectCard?: (id: string) => void;
}

interface ExchangeRatingModalState {
    open: boolean;
    currency: Exchange | undefined;
}
const IrelandI1Form: FC<Props> = ({ formik, viewOnly }) => {
    const { formType } = useFormUtils();
    const { getH1TooltipsByRefNumberAndField, getH1TooltipsByRefNumber, getH1TooltipsByField } = useTooltips();
    const { t } = useTranslation('form');
    const { exchanges } = useExchanges();
    const { aisCodelists } = useCodelists();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);

    const openModal = (e: any) => {
        setOpen(true);
        setValue(e?.target?.value);
    };
    const closeModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };
    const { createIrelandImportProduct, createArrivalAtExitProduct, listIrelandH1Products, listArrivalAtExitProducts } =
        useProducts();
    const { saveAsDraft, amendment } =
        useOutletContext<{
            saveAsDraft: (withNotification: boolean, data?: unknown) => Promise<unknown>;
            amendment: boolean;
        }>() ?? {};

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: formType === 'ARRIVAL' ? createArrivalAtExitProduct : createIrelandImportProduct,
        listProducts: formType === 'ARRIVAL' ? listArrivalAtExitProducts : listIrelandH1Products,
        saveAsDraft,
    });

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => set(acc, field, value), values);
        formik?.setValues(newValues);
    };

    const [exchangeRatingModal, setExchangeRatingModal] = useReducer(
        (state: ExchangeRatingModalState, newState: Partial<ExchangeRatingModalState>) => ({
            ...state,
            ...newState,
        }),
        { open: false, currency: undefined }
    );

    return (
        <FormikProvider value={formik}>
            <NewFormCard title="Master Details">
                <FormCardContainer>
                    <DeclarationSelect
                        label={t('declarationType')}
                        {...getFormikProps(`declarationType`, formik)}
                        required
                        condensed
                        viewOnly={viewOnly}
                        refNumber="1.1"
                        tooltip={getH1TooltipsByRefNumber('1.1')}
                        selectOptions={aisCodelists?.typeOfDeclaration}
                        disabled={amendment}
                        codeValidation={[validators.exact(2)]}
                    />

                    <DeclarationSelect
                        label={t('additionalDeclarationType')}
                        {...getFormikProps('additionalDeclarationType', formik)}
                        refNumber="1.2"
                        tooltip={getH1TooltipsByRefNumber('1.2')}
                        required
                        codeValidation={[validators.exact(1)]}
                        condensed
                        viewOnly={viewOnly}
                        disabled={amendment}
                        selectOptions={aisCodelists?.additionalDeclarationType}
                    />

                    <DeclarationNumberInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`grossMass`, formik)}
                        refNumber="6.5"
                        label={t('goodsItems.grossMass')}
                        condensed
                    />

                    <FormInput
                        maxLength={35}
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.ucr`, formik)}
                        refNumber="2.4"
                        label={t('ucr')}
                        tooltip={getH1TooltipsByRefNumber('2.4')}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title={t('valueDetails')}>
                <FormCardContainer>
                    <CurrencySelect
                        required
                        label="invoiceCurrency"
                        viewOnly={viewOnly}
                        {...getFormikProps(`invoiceCurrency`, formik)}
                        onChange={(value) => {
                            const currency = exchanges.find(
                                (exchange) => exchange.currency.code === value?.target?.value
                            );
                            if (currency) {
                                setExchangeRatingModal({ open: true, currency });
                            }
                        }}
                        refNumber="4.10"
                        tooltip={getH1TooltipsByRefNumber('4.10')}
                        condensed
                    />
                    <DeclarationNumberInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`invoiceAmount`, formik)}
                        refNumber="4.11"
                        label={t('invoiceAmount')}
                        refTooltip={getH1TooltipsByRefNumberAndField('4.11', t('invoiceAmount'))}
                        condensed
                    />
                    <FormEori
                        required
                        maxLength={17}
                        viewOnly={viewOnly}
                        {...getFormikProps(`personPayingCustomsDuty`, formik)}
                        refNumber="3.46"
                        label={t('personPayingCustomsDuty')}
                        tooltip={getH1TooltipsByRefNumber('3.46')}
                        condensed
                        onSearchCustomer={({ fieldHelpers }) => {
                            triggerShowSearchCustomerDrawer((customer) => {
                                fieldHelpers?.setValue(customer.eori);
                            });
                        }}
                    />
                    <FormSelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`preferredPaymentMethod`, formik)}
                        refNumber="4.8"
                        label={t('preferredPaymentMethod')}
                        selectOptions={aisCodelists?.taxPaymentMethod}
                        tooltip={getH1TooltipsByRefNumber('4.8')}
                        condensed
                        codeValidation={[validators.maxLength(1)]}
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title={'Transport Information'}>
                <FormCardContainer>
                    <FormSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`customsOfficeLodgement`, formik)}
                        label={t('customsOfficeLodgement')}
                        disabled={amendment}
                        tooltip={getH1TooltipsByField(t('customsOfficeLodgement'))}
                        required
                        selectOptions={aisCodelists?.nationalCustomsOffice}
                        condensed
                        onChange={openModal}
                        codeValidation={[validators.exact(8)]}
                    />
                    <AutoFillModal
                        visible={open}
                        value={value}
                        prevValue={prevValue}
                        fields={[
                            {
                                name: 'presentationCustomsOffice',
                                label: t('presentationCustomsOffice'),
                                codeList: aisCodelists?.nationalCustomsOffice,
                            },
                            {
                                name: 'supervisingCustomsOffice',
                                label: t('authorisation.supervisingCustomsOffice'),
                                codeList: aisCodelists?.nationalCustomsOffice,
                            },
                        ]}
                        onCancel={closeModal}
                        onOk={autoFill}
                    />
                    <FormSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`presentationCustomsOffice`, formik)}
                        refNumber="5.26"
                        label={t('presentationCustomsOffice')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.26', t('presentationCustomsOffice'))}
                        selectOptions={aisCodelists?.nationalCustomsOffice}
                        condensed
                        codeValidation={[validators.exact(8)]}
                    />
                    <FormSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`supervisingCustomsOffice`, formik)}
                        refNumber="5.27"
                        label={t('authorisation.supervisingCustomsOffice')}
                        selectOptions={aisCodelists?.nationalCustomsOffice}
                        tooltip={getH1TooltipsByRefNumberAndField('5.27', t('authorisation.supervisingCustomsOffice'))}
                        condensed
                        codeValidation={[validators.exact(8)]}
                    />
                </FormCardContainer>
            </NewFormCard>

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: '3.1',
                        disabled: amendment,
                    },
                    {
                        header: 'Importer',
                        path: 'goodsShipment.importer',
                        refNumber: '3.15',
                        eoriRequired: true,
                    },
                    {
                        path: 'declarant',
                        header: 'Declarant',
                        refNumber: '3.17',
                        eoriRequired: true,
                        eoriAutofill: [
                            {
                                name: 'personPayingCustomsDuty',
                                label: 'Customs Duty Payer',
                            },
                        ],
                        disabled: amendment,
                    },
                    {
                        path: 'representative',
                        header: 'Representative',
                        refNumber: '3.19',
                        hasRepresentativeStatus: true,
                        disabled: amendment,
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.city',
                        country: 'address.country',
                        name: 'name',
                        postCode: 'address.postcode',
                        streetAndNumber: 'address.streetAndNumber',
                    },
                    eori: 'identificationNumber',
                    contactPerson: {
                        name: 'contactPerson.name',
                        phoneNumber: 'contactPerson.phoneNumber',
                        email: 'contactPerson.emailAddress',
                    },
                    additional: {
                        representativeStatus: 'status',
                    },
                }}
                condensed
            />

            <MultipleItemsCard
                title="Authorisation Holder"
                path="authorisationHolder"
                initialValues={{ authorisationTypeCode: '', authorisationHolderIdentification: '' }}
                list={(list) => [
                    {
                        field: t('authorisationHolder.typeCode'),
                        value: getFullCodelistValue(list.authorisationTypeCode, aisCodelists?.authorisationTypeCode),
                    },
                    {
                        field: t('authorisationHolder.identificationNumber'),
                        value: list.authorisationHolderIdentification,
                    },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'authorisationTypeCode'), formik)}
                            refNumber="3.39"
                            label={t('authorisationHolder.typeCode')}
                            condensed
                            selectOptions={aisCodelists?.authorisationTypeCode}
                            tooltip={getH1TooltipsByRefNumberAndField('3.39', t('authorisationHolder.typeCode'))}
                            codeValidation={[validators.maxLength(4)]}
                        />
                        <DeclarationInput
                            maxLength={17}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'authorisationHolderIdentification'), formik)}
                            refNumber="3.39"
                            label={t('authorisationHolder.identificationNumber')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '3.39',
                                t('authorisationHolder.identificationNumber')
                            )}
                            condensed
                            onSearchCustomer={({ fieldHelpers }) => {
                                triggerShowSearchCustomerDrawer((customer) => {
                                    fieldHelpers?.setValue(customer.eori);
                                });
                            }}
                        />
                        <SearchCustomer.Drawer
                            onClose={triggerHideSearchCustomerDrawer}
                            show={showSearchCustomerDrawer}
                            onSelectCustomer={handleSelectCustomer}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path="goodsShipment.simplifiedDeclarationDocumentWritingOff"
                initialValues={{ previousDocumentType: '', previousDocumentIdentifier: '', previousDocumentLineId: '' }}
                list={(list) => [
                    {
                        field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentType'),
                        value: getFullCodelistValue(list?.previousDocumentType, aisCodelists?.previousDocumentType),
                    },
                    {
                        field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier'),
                        value: list?.previousDocumentIdentifier,
                    },
                    {
                        field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId'),
                        value: list?.previousDocumentLineId,
                    },
                ]}
                defaultOpen={false}
                viewOnly={viewOnly}
                title={t('previousDocument')}
                key={'ireland-h1-previous-doc-info'}
                condensed
                refNumber="2.1"
            >
                {(path) => (
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentType'), formik)}
                            refNumber="2.1"
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')}
                            selectOptions={aisCodelists?.previousDocumentType}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')
                            )}
                            condensed
                            codeValidation={[validators.maxLength(5)]}
                        />
                        <FormInput
                            maxLength={35}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentIdentifier'), formik)}
                            refNumber="2.1"
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')
                            )}
                            condensed
                        />
                        <FormInput
                            numbersAndLetters
                            maxLength={5}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentLineId'), formik)}
                            refNumber="2.1"
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')
                            )}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path={'goodsShipment.additionalInformation'}
                initialValues={{ additionalInformationCode: '', additionalInformationText: '' }}
                list={(list) => [
                    {
                        field: t('additionalInformation.code'),
                        value: getFullCodelistValue(
                            list.additionalInformationCode,
                            aisCodelists?.additionalInformationCode
                        ),
                    },
                    {
                        field: t('additionalInformation.description'),
                        value: list.additionalInformationText,
                    },
                ]}
                defaultOpen={false}
                viewOnly={viewOnly}
                title={t('additionalInformation.title')}
                condensed
                refNumber="2.2"
            >
                {(path) => (
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationCode'), formik)}
                            refNumber="2.2"
                            label={t('additionalInformation.code')}
                            condensed
                            selectOptions={aisCodelists?.additionalInformationCode}
                            tooltip={getH1TooltipsByRefNumberAndField('2.2', t('additionalInformation.code'))}
                            codeValidation={[validators.exact(5)]}
                        />
                        <FormInput
                            maxLength={512}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationText'), formik)}
                            refNumber="2.2"
                            condensed
                            label={t('additionalInformation.description')}
                            tooltip={getH1TooltipsByRefNumberAndField('2.2', t('additionalInformation.description'))}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title={t('otherDetails')}>
                <FormCardContainer>
                    <FormSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.warehouseType`, formik)}
                        refNumber="2.7"
                        label={t('goodsShipment.warehouseType')}
                        selectOptions={aisCodelists?.warehouseType}
                        tooltip={getH1TooltipsByRefNumberAndField('2.7', t('goodsShipment.warehouseType'))}
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />

                    <FormInput
                        maxLength={35}
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.warehouseIdentifier`, formik)}
                        refNumber="2.7"
                        label={t('goodsShipment.warehouseIdentifier')}
                        tooltip={getH1TooltipsByRefNumberAndField('2.7', t('goodsShipment.warehouseIdentifier'))}
                        condensed
                    />
                    <CountrySelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.countryDispatch`, formik)}
                        refNumber="5.14"
                        label={t('goodsShipment.countryDispatch')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.14', t('Country of Dispatch'))}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path={'supplyChainActor'!}
                initialValues={{ roleCode: '', traderIdentification: '' }}
                list={(list) => [
                    {
                        field: t('supplyChainActor.roleCode'),
                        value: getFullCodelistValue(list.roleCode, aisCodelists?.supplyChainActorRoleCode),
                    },
                    {
                        field: t('supplyChainActor.traderIdentification'),
                        value: list.traderIdentification,
                    },
                ]}
                defaultOpen={false}
                viewOnly={viewOnly}
                title={t('supplyChainActor.title')}
                condensed
                refNumber="3.37"
            >
                {(path) => (
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'roleCode'), formik)}
                            refNumber="3.37"
                            label={t('supplyChainActor.roleCode')}
                            tooltip={getH1TooltipsByRefNumberAndField('3.37', t('supplyChainActor.roleCode'))}
                            selectOptions={aisCodelists?.supplyChainActorRoleCode}
                            condensed
                            codeValidation={[validators.maxLength(3)]}
                        />
                        <FormInput
                            maxLength={18}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'traderIdentification'), formik)}
                            refNumber="3.37"
                            label={t('supplyChainActor.traderIdentification')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '3.37',
                                t('supplyChainActor.traderIdentification')
                            )}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Goods location">
                <FormCardContainer>
                    <FormSelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.locationGoods.locationTypeCode`, formik)}
                        refNumber="5.23"
                        label={t('firstPlaceOfUse.locationTypeCode')}
                        selectOptions={aisCodelists?.locationType}
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('firstPlaceOfUse.locationTypeCode'))}
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />

                    <FormSelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.locationGoods.qualifierIdentification`, formik)}
                        refNumber="5.23"
                        label={t('locationGoods.qualifierOfIdentification')}
                        selectOptions={aisCodelists?.locationIdentificationQualifier}
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.qualifierOfIdentification'))}
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />

                    <FormSelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.locationGoods.identificationOfLocation`, formik)}
                        refNumber="5.23"
                        label={t('locationGoods.identificationOfLocation')}
                        selectOptions={aisCodelists?.locationOfGoodsCode}
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.identificationOfLocation'))}
                        condensed
                        codeValidation={[validators.maxLength(35)]}
                    />

                    <FormInput
                        maxLength={3}
                        viewOnly={viewOnly}
                        {...getFormikProps(`identificationOfLocation.additionalIdentifier`, formik)}
                        refNumber="5.23"
                        label={t('locationGoods.additionalIdentifier')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.additionalIdentifier'))}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                getFieldHelpers={formik.getFieldHelpers}
                getFieldProps={formik.getFieldProps}
                path={'goodsShipment.containerIdentificationNumber'}
                initialValues={{ containerIdentificationNumber: '' }}
                list={(list) => [
                    {
                        field: t('containerIdentificationNumbers'),
                        value: list.containerIdentificationNumber,
                    },
                ]}
                defaultOpen={false}
                viewOnly={viewOnly}
                title={t('containerIdentificationNumbers')}
                condensed
                refNumber="7.10"
            >
                {(path) => (
                    <FormCardContainer>
                        <FormInput
                            maxLength={17}
                            viewOnly={viewOnly}
                            {...getFormikProps(addPathPrefix(path, 'containerIdentificationNumber'), formik)}
                            refNumber="7.10"
                            label={t('containerIdentificationNumbers')}
                            tooltip={getH1TooltipsByRefNumberAndField('7.10', t('containerIdentificationNumbers'))}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
        </FormikProvider>
    );
};
export default IrelandI1Form;
