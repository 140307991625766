import axios from 'config/axios';

interface Eori {
    eori: string;
    valid: boolean;
    city?: string;
    street?: string;
    postCode?: string;
    name?: string;
}

type Eoris = { [eoriCode: string]: Eori };

type Resolve<TToResolve> = (toResolve: TToResolve | PromiseLike<TToResolve>) => void;
type Reject = (toReject?: { reason: any; code: any }) => void;

class Task<TResult> {
    id: string;
    resolve: Resolve<TResult>;
    reject: Reject;

    constructor(id: string, resolve: Resolve<TResult>, reject: Reject) {
        this.id = id;
        this.resolve = resolve;
        this.reject = reject;
    }
}

class Subscriber {
    id: number;
    key: string;
    callback: Function;

    constructor(id: number, key: string, callback: Function) {
        this.id = id;
        this.key = key;
        this.callback = callback;
    }
}

export class EventBus {
    static subscriberId = 0;

    subscribers: { [id: string]: Subscriber[] };
    subscriberIdToKeyMap: Map<number, string>;

    constructor() {
        this.subscribers = {};
        this.subscriberIdToKeyMap = new Map();
    }

    subscribe(key: string, callback: Function, callCallback?: boolean) {
        if (!this.subscribers[key]) this.subscribers[key] = [];

        const subscriberId = EventBus.subscriberId++;
        this.subscribers[key].push(new Subscriber(subscriberId, key, callback));
        this.subscriberIdToKeyMap.set(subscriberId, key);

        // This is here to guarantee that the subscriber will get the latest status at least once
        if (callCallback) {
            callback();
        }

        return subscriberId;
    }

    unsubscribe(subscriberId: number) {
        const key = this.subscriberIdToKeyMap.get(subscriberId);

        if (!key || !this.subscribers[key]) return;

        this.subscribers[key] = this.subscribers[key].filter((subscriber) => subscriber.id !== subscriberId);
        this.subscriberIdToKeyMap.delete(subscriberId);
    }

    publish(id: string, value?: any) {
        if (!this.subscribers[id]) return;

        this.subscribers[id].forEach((subscriber) => subscriber.callback(value));
    }
}

export default class EoriService {
    private eori: Eoris;
    private timeoutId: NodeJS.Timeout | null;
    private eoriTasksMap: { [eori: string]: Task<boolean>[] };
    eventHandler: EventBus;

    constructor() {
        this.eori = {};
        this.timeoutId = null;
        this.eoriTasksMap = {};
        this.eventHandler = new EventBus();
    }

    async checkEori(field: string, eori: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.eori[field]?.eori === eori) resolve(this.eori[field].valid);
            else {
                this.eventHandler.publish(`checking-eori-${field}`, { loading: true, error: false });

                if (this.eoriTasksMap[eori] === undefined) this.eoriTasksMap[eori] = [];
                this.eoriTasksMap[eori].push(new Task(field, resolve, reject));

                if (this.timeoutId !== null) clearTimeout(this.timeoutId);

                this.timeoutId = setTimeout(() => this.run(), 100);
            }
        });
    }

    run() {
        this.timeoutId = null;

        const eoris = Object.keys(this.eoriTasksMap).join();

        if (!eoris) {
            return;
        }

        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/declarations-service/eori/check?${new URLSearchParams({
                    eoris,
                })}`
            )
            .then((res) => res.data)
            .then((data) => (data as any).payload as Eori[])
            .then((eori) => {
                eori.forEach((e) => {
                    this.eoriTasksMap[e.eori].forEach((task) => {
                        this.eori[task.id] = e;

                        task.resolve(e.valid);

                        this.eventHandler.publish(`checking-eori-${task.id}`, { loading: false, error: false });
                    });

                    delete this.eoriTasksMap[e.eori];
                });
            })
            .catch((error) => {
                Object.values(this.eoriTasksMap).forEach((tasks) => {
                    tasks.forEach((task) => {
                        task.reject({
                            reason: 'Service could not check the EORI code',
                            code: error.response.status,
                        });
                        this.eventHandler.publish(`checking-eori-${task.id}`, { loading: false, error: true });
                    });
                });

                this.eoriTasksMap = {};
            });
    }
}
