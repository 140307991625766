import {
    BackToList,
    CodeDiv,
    MessageDiv,
    ShowDetailsDiv,
    ShowRawDataDiv,
    StyledErrorRow,
    Title,
} from '../../DeclarationsNotificationsDrawer.styles';
import { Text } from 'components/ui/base/typography';
import { useState } from 'react';
import { colors } from '../../../../../../theme';
import { isEmpty } from 'lodash';
import { ErrorRow } from '../../declaration-errors/ais/RevenueImportErrorDetails';
import useCodelists from '../../../../../../hooks/useCodelists';
import config from 'config/config';
import RawData from '../ncts/RawData';

enum HandledAESNotifications {
    CC_560_C = 'CC_560_C',
    CC_509_C = 'CC_509_C',
    EX_582 = 'EX_582',
    CC_521_C = 'CC_521_C',
    CC_522_C = 'CC_522_C',
    CC_561_C = 'CC_561_C',
}

const AesActionRequiringNotification = ({ latestNotification }: SpecificDeclarationErrorsProps) => {
    const { aesCodelists } = useCodelists();
    const [showRawMessage, setShowRawMessage] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const { additionalProperties, rawMessage, notification } = latestNotification;

    const summaryView = (
        <StyledErrorRow justify="space-between">
            <CodeDiv color={colors.action}>
                <Text>{additionalProperties.ie} </Text>
            </CodeDiv>
            <MessageDiv>
                <Text>{additionalProperties.messageName}</Text>
            </MessageDiv>
            <ShowDetailsDiv>
                <span onClick={() => setShowDetails(true)}>Show details</span>
            </ShowDetailsDiv>
        </StyledErrorRow>
    );

    const notificationRender = () => {
        if (showRawMessage) return <pre>{rawMessage}</pre>;

        switch (notification.messageType) {
            case HandledAESNotifications.CC_560_C:
            case HandledAESNotifications.CC_561_C:
                return (
                    <>
                        <Title>Notification details</Title>
                        {!isEmpty(notification.typeOfControls) && (
                            <div>
                                <h2>Type of Controls</h2>
                                <div>
                                    {notification.typeOfControls.map((item: any, index: number) => (
                                        <div key={index}>
                                            <ErrorRow label="Type:" value={item.type ?? '-'} />
                                            <ErrorRow
                                                label="Text:"
                                                value={
                                                    item.text ??
                                                    aesCodelists?.typeOfControls.find(
                                                        (value: any) => value.id === item.type
                                                    )?.value ??
                                                    '-'
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {!isEmpty(notification.requestedDocument) && (
                            <div>
                                <h2>Requested Documents</h2>
                                <div>
                                    {notification.requestedDocument.map((item: any, index: number) => (
                                        <div key={index}>
                                            <ErrorRow label="Document Type:" value={item.documentType ?? '-'} />
                                            <ErrorRow
                                                label="Description:"
                                                value={
                                                    item.description ??
                                                    aesCodelists?.documentType.find(
                                                        (value: any) => value.id === item.documentType
                                                    )?.value ??
                                                    '-'
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                );
            case HandledAESNotifications.CC_509_C:
                return (
                    <>
                        <Title>Notification details</Title>
                        <ErrorRow
                            label="Invalidation Decision Date and Time:"
                            value={notification.exportOperation.invalidationDecisionDateAndTime ?? '-'}
                        />
                        <ErrorRow
                            label="Invalidation Request Date and Time:"
                            value={notification.exportOperation.invalidationRequestDateAndTime ?? '-'}
                        />
                        <ErrorRow
                            label="Invalidation Initiated By Customs:"
                            value={notification.exportOperation.invalidationInitiatedByCustoms ?? '-'}
                        />
                        <ErrorRow
                            label="Invalidation Justification:"
                            value={
                                !!notification.exportOperation.invalidationInitiatedByCustoms
                                    ? notification.exportOperation.invalidationInitiatedByCustoms === '0'
                                        ? 'No'
                                        : 'Yes'
                                    : '-'
                            }
                        />
                    </>
                );
            case HandledAESNotifications.EX_582:
                return (
                    <>
                        <Title>Notification details</Title>
                        {!isEmpty(notification.additionalInformation) && (
                            <div>
                                <h2>Additional Information</h2>
                                <div>
                                    {notification.additionalInformation.map((item: any, index: number) => (
                                        <div key={index}>
                                            <ErrorRow label="Document Type:" value={item.documentType ?? '-'} />
                                            <ErrorRow
                                                label="Document Complementary Information:"
                                                value={item.documentComplementaryInformation}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                );
            case HandledAESNotifications.CC_521_C:
                return (
                    <>
                        <Title>Notification details</Title>
                        <ErrorRow
                            label="Diversion Rejection Reason Code:"
                            value={notification.exportOperation.diversionRejectionReasonCode ?? '-'}
                        />
                        <ErrorRow
                            label="Diversion Rejection Text:"
                            value={
                                notification.exportOperation.diversionRejectionText ??
                                aesCodelists?.diversionRejectionCode.find(
                                    (value: any) =>
                                        value.id === notification.exportOperation.diversionRejectionReasonCode
                                )?.value ??
                                '-'
                            }
                        />
                        <ErrorRow
                            label="Customs Office of Exit Actual - Reference Number:"
                            value={notification.customsOfficeOfExitActual.referenceNumber ?? '-'}
                        />
                    </>
                );
            case HandledAESNotifications.CC_522_C:
                return (
                    <>
                        <Title>Notification details</Title>
                        <ErrorRow
                            label="Exit Rejection Motivation Code:"
                            value={notification.exportOperation.exitRejectionMotivationCode ?? '-'}
                        />
                        <ErrorRow
                            label="Exit Rejection Motivation:"
                            value={notification.exportOperation.exitRejectionMotivation ?? '-'}
                        />
                        <ErrorRow
                            label="Customs Office of Exit Actual - Reference Number:"
                            value={notification.customsOfficeOfExitActual.referenceNumber ?? '-'}
                        />
                        <ErrorRow label="Control Result - Date:" value={notification.controlResult.date ?? '-'} />
                    </>
                );

            default:
                return <RawData notification={latestNotification} rawMessage={latestNotification.rawMessage} />;
        }
    };

    const detailsView = (
        <>
            <BackToList onClick={() => (showRawMessage ? setShowRawMessage(false) : setShowDetails(false))}>
                Back to list
            </BackToList>
            {notificationRender()}
        </>
    );

    if (!showRawMessage && !showDetails)
        return (
            <>
                {config.isPIT && (
                    <ShowRawDataDiv>
                        <span onClick={() => setShowRawMessage(true)}>Show raw data</span>
                    </ShowRawDataDiv>
                )}

                {summaryView}
            </>
        );
    else return <>{detailsView}</>;
};

export default AesActionRequiringNotification;
