import { CC022CNotification } from 'store/declarations/ireland/ncts-declaration';
import { ErrorRow } from '../../declaration-errors/ais/RevenueImportErrorDetails';
import { isEmpty } from 'lodash';

const CC022C = (props: { notification: CC022CNotification['notification'] }) => {
    if (isEmpty(props.notification.FunctionalError)) return null;
    return (
        <div>
            <h2>Functional Errors</h2>
            <div>
                {props.notification.FunctionalError.map(
                    (item: CC022CNotification['notification']['FunctionalError'][number], index: number) => (
                        <div key={index}>
                            <ErrorRow label="Error Code:" value={item.errorCode ?? '-'} />
                            <ErrorRow label="Error Pointer:" value={item.errorPointer ?? '-'} />
                            <ErrorRow label="Error Reason:" value={item.errorReason ?? '-'} />
                            <ErrorRow label="Original Attribute Value:" value={item.originalAttributeValue ?? '-'} />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default CC022C;
