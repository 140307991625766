import { useCallback } from 'react';
import {
    doAddProductTemplateToIrelandENS,
    doAddProductTemplateToIrelandExport,
    doAddProductTemplateToIrelandH1,
    doAddProductTemplateToIrelandH7,
    doAddProductTemplateToUk,
} from 'store/declaration-product/actions';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import { DeclarationCountry } from '../store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from '../store/declarations/enums/common/declaration-internal-type';
import { AISMessageTypes, ENSMessageTypes } from '../store/declarations/enums/common/declaration-types';
import { addProductTemplateToDeclarationNotifs } from '../utils/notificationMessages';
import { DeclarationTypes } from './useProducts';
import useResponseNotification from './useResponseNotification';

const useDeclarationProducts = () => {
    const declaration = useAppSelector((state) => state.declarationProduct.declaration);
    const isLoading = useAppSelector((state) => state.declarationProduct.isLoading);
    const error = useAppSelector((state) => state.declarationProduct.error);
    const { withResponseNotifications } = useResponseNotification();

    const dispatch = useAppDispatch();

    const addProductsTemplatesToIrelandH1 = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToIrelandH1(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductsTemplatesToIrelandH7 = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToIrelandH7(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductsTemplatesToIrelandENS = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToIrelandENS(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductsTemplatesToIrelandExport = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToIrelandExport(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductsTemplatesToUk = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToUk(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductTemplatesToDeclaration = async ({
        productTemplateIds,
        declarationId,
        formType,
        internalType,
        country,
    }: {
        declarationId: string;
        productTemplateIds: string[];
    } & DeclarationTypes) => {
        if (!(country && internalType && formType))
            throw new Error('No data provided for add product templates to declaration request.');

        let response;
        if (country === DeclarationCountry.UK) {
            response = await addProductsTemplatesToUk(declarationId, productTemplateIds);
        } else {
            if (internalType === DeclarationInternalType.EXPORT) {
                response = await addProductsTemplatesToIrelandExport(declarationId, productTemplateIds);
            } else {
                if (formType === AISMessageTypes.H7) {
                    response = await addProductsTemplatesToIrelandH7(declarationId, productTemplateIds);
                } else if (formType === ENSMessageTypes.ENS) {
                    response = await addProductsTemplatesToIrelandENS(declarationId, productTemplateIds);
                } else {
                    response = await addProductsTemplatesToIrelandH1(declarationId, productTemplateIds);
                }
            }
        }
        return withResponseNotifications(response, addProductTemplateToDeclarationNotifs());
    };

    return {
        isLoading,
        error,
        declaration,
        addProductTemplatesToDeclaration,
    };
};

export default useDeclarationProducts;
