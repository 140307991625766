import { useEffect, useState } from 'react';
import { uploadFile } from 'views/declarations/sections/customer-documents/components/UploadUtils';

type Args = {
    getPicture: () => Promise<string | null>;
    getPreSign: (file: File) => Promise<string>;
    uploadPicture: (preSignUrl: string) => Promise<any>;
    deletePicture: () => Promise<any>;
};

export const useProfilePicture = (args: Args | (() => Args)) => {
    const [profilePicture, setProfilePicture] = useState<string | null>(null);

    const { deletePicture, getPicture, getPreSign, uploadPicture } = args instanceof Function ? args() : args;

    const fetchProfilePicture = async () => {
        const url = await getPicture();
        setProfilePicture(url);
    };

    useEffect(() => {
        fetchProfilePicture();
    }, []);

    const handleProfilePictureUpload = async (file: File) => {
        const preSignUrl = await getPreSign(file);
        await uploadFile(file, preSignUrl);
        const match = preSignUrl.match(/\/([^/?]+)\?/);
        const uuid = match?.[1];
        await uploadPicture(uuid!);
        fetchProfilePicture();
    };

    const handleProfilePictureDelete = async () => {
        setProfilePicture(null);
        await deletePicture();
        fetchProfilePicture();
    };

    return { profilePicture, handleProfilePictureUpload, handleProfilePictureDelete };
};
