import { Button } from 'antd';
import styled from 'styled-components';

export const Customer = {
    Contact: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            gap: 1rem;
        `,
        Header: {
            Container: styled.div`
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 2rem;
            `,
            Text: styled.h2`
                margin: 0;
            `,
            Button: styled(Button)`
                margin: 0;
                font-weight: 600;
            `,
        },
    },
};
