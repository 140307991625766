import { useMemo } from 'react';
import { DeclarationExternalEntity } from '../store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from '../store/declarations/enums/common/declaration-internal-type';
import { getDeclarationKey } from '../views/declarations/utils/declaration-utils';
import declarationMap, { DeclarationMapValues } from '../views/declarations/utils/declarationMap';
import useFormUtils from './useFormUtils';

interface Props {
    declarationInternalType?: DeclarationInternalType;
    declarationExternalEntity?: DeclarationExternalEntity;
    h7?: boolean;
}

const useGetDeclarationMapValues = ({ options }: { options?: Props } = {}) => {
    const { internalType, formType, externalEntity } = useFormUtils();

    const declarationMapValues = useMemo(() => {
        const declarationKeyValues = options ?? {
            declarationInternalType: internalType as DeclarationInternalType,
            declarationExternalEntity: externalEntity as DeclarationExternalEntity,
            h7: formType === 'H7',
        };
        if (!declarationKeyValues.declarationExternalEntity) return {} as DeclarationMapValues<any>;

        const declarationKey = getDeclarationKey(declarationKeyValues);
        if (declarationKey === null) return {} as DeclarationMapValues<any>;

        const declarationValues = declarationMap[declarationKey];

        if ('default' in declarationValues) {
            const newDeclarationValues = {};

            Object.assign(newDeclarationValues, declarationValues['default']);
            if (formType && formType in declarationValues) {
                Object.assign(newDeclarationValues, declarationValues[formType]);
            }
            return newDeclarationValues as DeclarationMapValues<any>;
        }

        return declarationValues as DeclarationMapValues<any>;
    }, [internalType, formType, externalEntity, options]);

    return declarationMapValues;
};

export default useGetDeclarationMapValues;
