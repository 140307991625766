import config from 'config';
import { SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { SessionPayload, SessionUser } from './session';

export const signIn = (credentials: { email: string; password: string; remember: boolean }): Promise<SessionPayload> =>
    axiosClient
        .post<SuccessResponse<SessionPayload>>(`${config.authUrl}/session`, credentials)
        .then((response) => response.data.payload);

// FIXME typings
export const signUp = (params: any): Promise<string> =>
    axiosClient.post<any>(`${config.cmsUrl}/register`, params).then((response) => response.data.payload);

export const updateToken = (token: string): Promise<SessionPayload> =>
    axiosClient
        .put<SuccessResponse<SessionPayload>>(`${config.authUrl}/session`, { refreshToken: token })
        .then((response) => response.data.payload);

export const updatePassword = (credentials: { newPassword: string; oldPassword: string }): Promise<SessionPayload> =>
    axiosClient
        .put<SuccessResponse<SessionPayload>>(`${config.authUrl}/session/password`, credentials)
        .then((response) => response.data.payload);

export const confirmationRegistration = (confirmation: {
    token: string;
    password: string;
    confirmPassword: string;
}): Promise<SessionUser> =>
    axiosClient
        .post<SuccessResponse<SessionUser>>(`${config.authUrl}/confirmation`, confirmation)
        .then((response) => response.data.payload);

export const resendInvite = (userId: string): Promise<SessionUser> =>
    axiosClient
        .post<SuccessResponse<SessionUser>>(`${config.authUrl}/users/${userId}/invite`)
        .then((response) => response.data.payload);

export const forgotPassword = (email: string): Promise<void> =>
    axiosClient
        .post<SuccessResponse<void>>(`${config.authUrl}/session/reset`, { email })
        .then((response) => response.data.payload);

export const resetPassword = (newPassword: string, token: string): Promise<void> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.authUrl}/session/reset`, { newPassword, token })
        .then((response) => response.data.payload);

export const oauth = (): Promise<SessionPayload> =>
    axiosClient
        .get<SuccessResponse<SessionPayload>>(`${config.authUrl}/session/oauth2`)
        .then((response) => response.data.payload);
