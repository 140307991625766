import { useCallback } from 'react';
import { doGetIrelandBusinessRules } from 'store/business-rules/action';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import { IrelandBusinessRulesType } from '../store/business-rules/client';

const useBusinessRules = () => {
    const aisRules = useAppSelector((state) => state.businessRules?.aisRules);
    const aesRules = useAppSelector((state) => state.businessRules?.aesRules);
    const error = useAppSelector((state) => state.businessRules.error);
    const isLoading = useAppSelector((state) => state.businessRules.isLoading);

    const dispatch = useAppDispatch();

    const getIrelandBusinessRules = useCallback(
        (type: IrelandBusinessRulesType) => dispatch(doGetIrelandBusinessRules(type)),
        [dispatch]
    );

    return {
        getIrelandBusinessRules,
        isLoading,
        error,
        aisRules,
        aesRules,
    };
};

export default useBusinessRules;
