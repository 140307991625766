import { FormModel, Validator, validators } from 'views/declarations/uk/export/validations/validations';
import { TARIC_CODE_TYPE, invalidEoriMessage, CUSTOMS_OFFICE } from '../../../../utils/validation-utils';

type PartyValidator = {
    childValidators: {
        identificationNumber: Validator[];
        status?: Validator[];
        name: Validator[];
        address: {
            childValidators: {
                streetAndNumber: Validator[];
                postcode: Validator[];
                city: Validator[];
                country: Validator[];
            };
        };
        contactPerson?: {
            childValidators: {
                name: Validator[];
                phoneNumber: Validator[];
                emailAddress: Validator[];
            };
        };
    };
};

export const getPartyValidator = (options?: {
    eoriRequired?: boolean;
    contactPerson?: { present?: boolean; required?: boolean };
    status?: { present?: boolean; required?: boolean };
}) => {
    const validator: PartyValidator = {
        childValidators: {
            identificationNumber: [validators.eori()], // Identification Number
            name: [validators.maxLength(70)], // Name
            address: {
                // Address
                childValidators: {
                    streetAndNumber: [validators.maxLength(70)], // Street and Number
                    postcode: [validators.maxLength(17)], // Postcode
                    city: [validators.maxLength(35)], // City
                    country: [validators.exact(2)], // Country
                },
            },
        },
    };

    if (options?.eoriRequired) {
        validator.childValidators.identificationNumber.unshift(validators.required());
    }

    if (options?.contactPerson?.present) {
        const contactPerson = {
            // Contact Person
            childValidators: {
                name: [validators.maxLength(70)], // Name
                phoneNumber: [validators.maxLength(35)], // Phone Number
                emailAddress: [validators.email()], // E-mail Address
            },
        };

        if (options.contactPerson.required) {
            contactPerson.childValidators.name.unshift(validators.required());
            contactPerson.childValidators.phoneNumber.unshift(validators.required());
        }

        validator.childValidators.contactPerson = contactPerson;
    }

    if (options?.status) {
        validator.childValidators.status = [validators.number(), validators.exact(1)];
        if (options?.status.required) {
            validator.childValidators.status.unshift(validators.required());
        }
    }

    return validator;
};

export const irelandI1ValidationItem = {
    childValidators: {
        preference: [validators.exact(3), validators.required()],
        goodsInformation: {
            childValidators: {
                combinedNomenclatureCode: [validators.maxLength(8), validators.required()],
                taricCode: [
                    validators.exact(2),
                    validators.required(),
                    validators.regex(TARIC_CODE_TYPE, 'Taric code should have two integer.'),
                ],
                taricAdditionalCode: [],
                goodsDescription: [validators.maxLength(512), validators.required()],
                supplementaryUnits: [validators.float(16, 6)],
                packaging: {
                    childValidators: {
                        packageNumber: [validators.maxLength(8), validators.required()],
                        typePackage: [validators.maxLength(2), validators.required()],
                        shippingMarks: [validators.maxLength(512), validators.required()],
                    },
                },
                grossMass: [validators.float(16, 6)],
                netMass: [
                    validators.maxLength(16),
                    validators.required(),
                    async (nMass: string, b: FormModel<any>) => {
                        const grossMass = b.getValues()?.grossMass;
                        const netMass = parseFloat(nMass);

                        if (!netMass || !grossMass) {
                            return undefined;
                        }
                        if (grossMass < netMass) {
                            return 'Net mass cannot be larger than gross mass.';
                        }
                        return undefined;
                    },
                ],

                cusCode: [validators.exact(8)],
            },
        },
        procedureCode: [validators.exact(2)],
        previousProcedureCode: [validators.exact(2)],
        additionalProcedure: {
            childValidators: {
                additionalProcedure: [validators.exact(3)],
            },
            stopAtNull: false,
        },
        simplifiedDeclarationDocumentWritingOff: {
            childValidators: {
                previousDocumentType: [validators.maxLength(5)],
                previousDocumentIdentifier: [validators.maxLength(35)],
                previousDocumentLineId: [validators.maxLength(5)],
            },
        },
        additionalInformation: {
            childValidators: {
                additionalInformationCode: [validators.exact(5)],
                additionalInformationText: [validators.maxLength(512)],
            },
        },
        exporter: getPartyValidator(),
        buyer: getPartyValidator(),
        supplyChainActor: {
            childValidators: {
                roleCode: [validators.maxLength(3)],
                traderIdentification: [validators.maxLength(17)],
            },
        },
        taxBox43Bis: {
            childValidators: {
                boxTaxType: [validators.exact(3)],
                boxTaxBaseUnit: [], // to be done
                boxAmount: [validators.maxLength(16)],
                boxTaxRate: [validators.maxLength(17)],
                boxTaxPayableAmount: [validators.maxLength(16)],
                boxTaxPaymentMethod: [validators.exact(1)],
            },
        },
        itemAmount: [validators.maxLength(16), validators.required()],
        taxTotalAmount: [validators.maxLength(35)],
        countryDispatch: [validators.exact(2)],
        countryPreferentialOrigin: [validators.maxLength(4)],
        quotaOrderNumber: [validators.exact(6)],
        transactionNature: [validators.maxLength(2)],
        countryOrigin: [validators.exact(2), validators.required()],
        ucr: [validators.maxLength(35)],
        containerIdentificationNumber: {
            childValidators: {
                containerIdentificationNumber: [validators.maxLength(17)],
            },
            stopAtNull: false,
        },
    },
};

export const irelandI1ValidationHeader = {
    childValidators: {
        declarationType: [validators.required()],
        additionalDeclarationType: [validators.required()],
        grossMass: [validators.float(16, 6)],
        goodsShipment: {
            childValidators: {
                ucr: [validators.maxLength(35)],
                simplifiedDeclarationDocumentWritingOff: {
                    childValidators: {
                        previousDocumentType: [validators.maxLength(5)],
                        previousDocumentIdentifier: [validators.maxLength(35)],
                        previousDocumentLineId: [validators.maxLength(5)],
                    },
                },
                additionalInformation: {
                    childValidators: {
                        code: [validators.exact(5)],
                        description: [validators.maxLength(512)],
                    },
                },
                warehouseType: [validators.exact(1)],
                warehouseIdentifier: [validators.maxLength(35)],
                countryDispatch: [validators.exact(2), validators.required()],
                importer: getPartyValidator({ eoriRequired: true }),
                locationTypeCode: [validators.exact(1), validators.required()],
                qualifierIdentification: [validators.exact(1), validators.required()],
                identificationOfLocation: [validators.maxLength(35), validators.required()],
            },
        },
        invoiceCurrency: [validators.maxLength(3), validators.required()],
        invoiceAmount: [validators.maxLength(16)],
        personPayingCustomsDuty: [validators.required(), validators.eori()],
        preferredPaymentMethod: [validators.maxLength(1), validators.required()],
        customsOfficeLodgement: [validators.required()],
        presentationCustomsOffice: [validators.exact(8)],
        supervisingCustomsOffice: [validators.regex(CUSTOMS_OFFICE, invalidEoriMessage)],
        exporter: getPartyValidator(),
        declarant: getPartyValidator({ eoriRequired: true }),
        representative: getPartyValidator(),
        authorisationHolder: {
            childValidators: {
                authorisationTypeCode: [validators.maxLength(4)],
                authorisationHolderIdentification: [validators.maxLength(17)],
            },
        },
        supplyChainActor: {
            childValidators: {
                roleCode: [validators.maxLength(3)],
                traderIdentification: [validators.maxLength(17)],
            },
        },
        additionalIdentifier: [validators.exact(3)],
        containerIdentificationNumber: {
            childValidators: {
                containerIdentificationNumber: [validators.maxLength(17)],
            },
            stopAtNull: false,
        },
    },
};
