import { Action, Reducer, AnyAction } from 'redux';
import { GvmsReferenceDataStore } from '../declarations/uk/gvms-declaration';

export const SET_GVMS_REFERENCE_DATA = 'SET_GVMS_REFERENCE_DATA';

interface SetGvmsReferenceDataAction extends Action<typeof SET_GVMS_REFERENCE_DATA> {
    gvmsReferenceData: GvmsReferenceDataStore;
}

export const gvmsReferenceDataInitialState: GvmsReferenceDataStore = { gvmsReferenceData: undefined };

export const setGvmsReferenceData = (gvmsReferenceData: GvmsReferenceDataStore): SetGvmsReferenceDataAction => ({
    type: SET_GVMS_REFERENCE_DATA,
    gvmsReferenceData,
});

const gvmsReferenceDataReducer: Reducer<GvmsReferenceDataStore, SetGvmsReferenceDataAction> = (
    state = gvmsReferenceDataInitialState,
    action: SetGvmsReferenceDataAction | AnyAction
) => {
    switch (action.type) {
        case SET_GVMS_REFERENCE_DATA:
            return { ...state, gvmsReferenceData: action.gvmsReferenceData };
        default:
            return state;
    }
};

export default gvmsReferenceDataReducer;
