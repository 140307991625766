import { FormikProps, FormikProvider } from 'formik';
import { FC } from 'react';
import FiscalReferenceCard from 'views/declarations/ireland/import/h7/cards/products/FiscalReferenceCard';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import DocumentsAdditionalInfoCard from './cards/DocumentsAdditionalInfoCard';
import PreviousDocumentCard from './cards/PreviousDocumentCard';
import AdditionalProcedures from './cards/products/AdditionalProcedures';
import DutiesAndTaxes from './cards/products/DutiesAndTaxes';
import GoodsInformationCard from './cards/products/GoodsInformationCard';
import ValuationInformationCard from './cards/products/ValuationInformationCard';
import TransportDocumentsCard from './cards/TransportDocumentsCard';

interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
}

const IrelandH7ProductsSection: FC<Props> = ({ toggleHelp, formik, viewOnly }) => {
    return (
        <FormikProvider value={formik}>
            <GoodsInformationCard
                keyCard={`goods-information-card`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={11}
                cardNumber={1}
            />

            <AdditionalProcedures
                viewOnly={viewOnly}
                keyCard={`additional-procedures-card`}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                formik={formik}
                cardTotal={11}
                cardNumber={2}
            />

            <DocumentsAdditionalInfoCard
                keyCard={`documents-add-information-card`}
                propsPathPrefix="documentsAuthorisations.additionalInformation"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={11}
                cardNumber={5}
            />

            <PreviousDocumentCard
                keyCard={`previous-documents-card`}
                propsPathPrefix="documentsAuthorisations.previousDocument"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={11}
                cardNumber={6}
            />

            <ValuationInformationCard
                viewOnly={viewOnly}
                keyCard={`valuation-information-card`}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={11}
                cardNumber={7}
            />
            <TransportDocumentsCard
                viewOnly={viewOnly}
                keyCard={`transport-documents-card`}
                propsPathPrefix="itemAmountInvoicedIntrinsicValue.transportDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={8}
            />
            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: '3.1',
                    },
                ]}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                }}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={9}
                keyCard={`parties-card`}
                condensed
            />

            <FiscalReferenceCard
                keyCard={`fiscal-reference-card`}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                getFieldMeta={formik.getFieldMeta}
                cardTotal={11}
                cardNumber={10}
                propsPathPrefix="additionalFiscalReference"
            />
            <DutiesAndTaxes
                keyCard={`duties-and-taxes-card`}
                viewOnly={viewOnly}
                key={`duties-and-taxes-card`}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={11}
                cardNumber={11}
                propsPathPrefix="dutiesAndTaxes"
            />
        </FormikProvider>
    );
};
export default IrelandH7ProductsSection;
