const DeclarationTabKeys = {
    FORM: 'form',
    SUMMARY: 'summary',
    DOCUMENTATION: 'documentation',
    CUSTOMER_DETAILS: 'customer-details',
    VIEW_ONLY: 'view-only',
    HISTORY: 'history',
    REFUNDS: 'refunds',
    BARCODE: 'barcode',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
type DeclarationTabKeys = typeof DeclarationTabKeys[keyof typeof DeclarationTabKeys];

export default DeclarationTabKeys;
