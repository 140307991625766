import { FormikProps, FormikProvider } from 'formik';
import { FC } from 'react';
import useCodelists from '../../../../hooks/useCodelists';
import addPathPrefix from '../../../../utils/addPathPrefix';
import NewFormCard, { FormCardContainer } from '../../common/cards/NewFormCard';
import MultipleItemsCard from '../../common/MultipleItemsCard';
import { validators } from '../../uk/export/validations/validations';
import { getFormikProps, getFullCodelistValue } from '../../utils/form-utils';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import MultipleDeclarationField from '../../common/MultipleDeclarationField';

interface Props {
    formik: FormikProps<any>;
}

const IrelandTsdProductsSection: FC<Props> = ({ formik }) => {
    const { aisCodelists } = useCodelists();
    return (
        <FormikProvider value={formik}>
            <NewFormCard title="Goods Information">
                <FormCardContainer>
                    <DeclarationInput
                        label="CUS Code"
                        refNumber="6.13"
                        {...getFormikProps('goodsInformation.cusCode613', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Commodity Code - Combined Nomenclature Code"
                        refNumber="6.14"
                        {...getFormikProps('goodsInformation.commodityCode.combinedNomenclatureCode614', formik)}
                        condensed
                        /** Must be DeclarationSelect, but aisCodelists.nomenclatureCodeHS is undefined. */
                    />
                    <DeclarationInput
                        label="Description of Goods"
                        refNumber="6.8"
                        {...getFormikProps('goodsInformation.goodsDescription68', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Gross Mass"
                        refNumber="6.5"
                        {...getFormikProps('goodsInformation.grossMass65', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Simplified Declaration / Previous Documents / Writing-off"
                path="documentsAuthorisations.simplifiedDeclarationDocumentWritingOff201"
                refNumber="2.01"
                initialValues={{
                    previousDocumentType: '',
                    previousDocumentIdentifier: '',
                    previousDocumentLineId: '',
                }}
                list={(list) => [
                    {
                        field: 'Previous Document Type',
                        value: getFullCodelistValue(list.previousDocumentType, aisCodelists?.previousDocumentType),
                    },
                    { field: 'Previous Document Reference', value: list.previousDocumentIdentifier },
                    { field: 'Declaration Line Number Previously Recorded', value: list.previousDocumentLineId },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Previous Document Type"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentType'), formik)}
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aisCodelists?.previousDocumentType}
                        />
                        <DeclarationInput
                            label="Previous Document Reference"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentIdentifier'), formik)}
                            condensed
                        />
                        <DeclarationInput
                            label="Declaration Line Number Previously Recorded"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentLineId'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Information"
                path="documentsAuthorisations.additionalInformation22"
                refNumber="2.2"
                initialValues={{
                    additionalInformationCode: '',
                    additionalInformationText: '',
                }}
                list={(list) => [
                    {
                        field: 'Additional Statement Code',
                        value: getFullCodelistValue(
                            list.additionalInformationCode,
                            aisCodelists?.additionalInformationCode
                        ),
                    },
                    { field: 'Additional Statement Text', value: list.additionalInformationText },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Additional Statement Code"
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationCode'), formik)}
                            codeValidation={[validators.exact(5)]}
                            condensed
                            selectOptions={aisCodelists?.additionalInformationCode}
                        />
                        <DeclarationInput
                            label="Additional Statement Text"
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationText'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Documents Produced / Certificates and Authorisations / Additional References"
                path="documentsAuthorisations.producedDocumentsWritingOff203"
                refNumber="2.03"
                initialValues={{
                    documentType: '',
                    documentIdentifier: '',
                }}
                list={(list) => [
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.documentType, aisCodelists?.commonDocumentsType),
                    },
                    { field: 'Id', value: list.documentIdentifier },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'documentType'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aisCodelists?.commonDocumentsType}
                        />
                        <DeclarationInput
                            label="Id"
                            {...getFormikProps(addPathPrefix(path, 'documentIdentifier'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Reference Number">
                <FormCardContainer>
                    <DeclarationInput
                        label="UCR"
                        refNumber="2.4"
                        {...getFormikProps('documentsAuthorisations.ucr24', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Supply Chain Actor"
                path="supplyChainActor337"
                refNumber="3.37"
                initialValues={{
                    roleCode: '',
                    traderIdentification: '',
                }}
                list={(list) => [
                    {
                        field: 'Role Code',
                        value: getFullCodelistValue(list.roleCode, aisCodelists?.supplyChainActorRoleCode),
                    },
                    { field: 'Identifier', value: list.traderIdentification },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Role Code"
                            {...getFormikProps(addPathPrefix(path, 'roleCode'), formik)}
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aisCodelists?.supplyChainActorRoleCode}
                        />
                        <DeclarationInput
                            label="Identifier"
                            {...getFormikProps(addPathPrefix(path, 'traderIdentification'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Packaging"
                path="goodsInformation.packaging"
                refNumber="3.37"
                required
                initialValues={{
                    typePackage69: '',
                    packageNumber610: '',
                    shippingMarks611: '',
                }}
                list={(list) => [
                    {
                        field: 'Type of Packages',
                        value: getFullCodelistValue(list.typePackage69, aisCodelists?.kindOfPackages),
                    },
                    { field: 'Number of Packages', value: list.packageNumber610 },
                    { field: 'Shipping Marks', value: list.shippingMarks611 },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            required
                            label="Type of Packages"
                            refNumber="6.9"
                            {...getFormikProps(addPathPrefix(path, 'typePackage69'), formik)}
                            codeValidation={[validators.maxLength(2)]}
                            condensed
                            selectOptions={aisCodelists?.kindOfPackages}
                        />
                        <DeclarationInput
                            label="Number of Packages"
                            refNumber="6.10"
                            {...getFormikProps(addPathPrefix(path, 'packageNumber610'), formik)}
                            condensed
                        />
                        <DeclarationInput
                            label="Shipping Marks"
                            refNumber="6.11"
                            {...getFormikProps(addPathPrefix(path, 'shippingMarks611'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Transportation">
                <FormCardContainer>
                    <MultipleDeclarationField
                        parent="transportInformation.containerIdentificationNumber710"
                        name="containerIdentificationNumber"
                    >
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                refNumber="7.10"
                                label="Container Identification Number"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>
        </FormikProvider>
    );
};

export default IrelandTsdProductsSection;
