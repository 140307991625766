import { BackToList, ShowRawDataDiv } from '../DeclarationsNotificationsDrawer.styles';
import { RevenueImportErrorDetails } from './ais/RevenueImportErrorDetails';
import useFormUtils from '../../../../../hooks/useFormUtils';
import RevenueExportErrorDetails from './aes/RevenueExportErrorDetails';
import { useMemo, useState } from 'react';
import { AesInvalidError, AesRejectedError } from '../../../../../store/declarations/ireland/export-declaration';
import { SubmissionErrors } from '../../../../../store/declarations/ireland/ireland-notification';
import AisErrorsList from './ais/AisErrorsList';
import AesErrorsList from './aes/AesErrorsList';
import config from 'config/config';

const AisAesDeclarationErrors = ({
    errors,
    onClose,
    latestNotification,
    showRawData = false,
}: SpecificDeclarationErrorsProps) => {
    const { isAes, isArrival, isNcts, isUkNcts } = useFormUtils();
    const [showRawMessage, setShowRawMessage] = useState<boolean>(showRawData);
    const [selectedError, setSelectedError] = useState<
        (SubmissionErrors & (AesRejectedError & AesInvalidError)) | undefined
    >(undefined);

    const errorsList = useMemo(() => {
        let errorsListContent;

        if (isAes || isArrival || isNcts || isUkNcts) {
            errorsListContent = <AesErrorsList errors={errors} setSelectedError={setSelectedError} />;
        } else {
            errorsListContent = <AisErrorsList errors={errors} setSelectedError={setSelectedError} />;
        }

        return <>{errorsListContent}</>;
    }, [errors, isAes, isArrival, isNcts, isUkNcts]);

    const errorDetails = useMemo(() => {
        let detailsContent;

        if (showRawMessage) {
            detailsContent = <pre>{latestNotification.rawMessage}</pre>;
        } else if (isAes || isArrival || isNcts || isUkNcts) {
            detailsContent = (
                <RevenueExportErrorDetails
                    details={selectedError}
                    onClose={onClose}
                    latestNotification={latestNotification}
                    rulesAndConditions={
                        isNcts || isUkNcts
                            ? {
                                  text: 'NCTS Rules and Conditions',
                                  url: 'https://www.revenue.ie/en/online-services/support/software-developers/documents/ncts-p5/ncts-business-rules-and-conditions-v2.30.pdf',
                              }
                            : undefined
                    }
                />
            );
        } else {
            detailsContent = <RevenueImportErrorDetails details={selectedError} onClose={onClose} />;
        }

        return (
            <>
                {!showRawData && (
                    <BackToList
                        onClick={() => (showRawMessage ? setShowRawMessage(false) : setSelectedError(undefined))}
                    >
                        Back to list
                    </BackToList>
                )}
                {detailsContent}
            </>
        );
    }, [isAes, latestNotification, onClose, showRawMessage, selectedError, showRawData, isArrival, isNcts, isUkNcts]);

    if (!selectedError && !showRawMessage)
        return (
            <>
                {config.isPIT && (
                    <ShowRawDataDiv>
                        <span onClick={() => setShowRawMessage(true)}>Show raw data</span>
                    </ShowRawDataDiv>
                )}

                {errorsList}
            </>
        );
    else return <>{errorDetails}</>;
};

export default AisAesDeclarationErrors;
