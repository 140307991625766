import { useCallback, useEffect, useRef, useState } from 'react';
import useGlobalOverlay from './useGlobalOverlay';

const getErrorMessage = (error: any) => error.response?.data || error.message || 'Something went wrong';

const useRequest = <
    TFunction extends (params: Parameters<TFunction>[0], extraParams: Parameters<TFunction>[1]) => ReturnType<TFunction>
>(
    requestFunc: TFunction
) => {
    const [data, setData] = useState<Awaited<ReturnType<TFunction>> | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const doRequest = useCallback(
        async (...params: Parameters<TFunction>): Promise<Awaited<ReturnType<TFunction>> | null> => {
            setLoading(true);
            setError(null);

            let _data = null;

            try {
                _data = (await requestFunc(params[0], params[1])) as Awaited<ReturnType<TFunction>>;
            } catch (error) {
                setError(getErrorMessage(error));
            } finally {
                setData(_data);
                setLoading(false);

                return _data;
            }
        },
        [requestFunc]
    );

    return {
        doRequest,
        isLoading,
        error,
        data,
    };
};

const useRequestPromise = <TRequest extends (...args: any) => Promise<any> | null>(
    request: TRequest,
    options?: { loadingMessage?: string; args?: Parameters<TRequest>; withSignal?: boolean },
    dependencyArray: any[] = []
) => {
    const [data, setData] = useState<Awaited<ReturnType<TRequest>> | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

    const controller = useRef(new AbortController());

    const doRequest = useCallback(
        (...args: Parameters<TRequest>) => {
            setLoading(true);
            setError(null);

            if (options?.loadingMessage)
                showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: options.loadingMessage } });

            if (options?.withSignal) {
                controller.current.abort();
                controller.current = new AbortController();
                args[0].signal = controller.current.signal;
            }

            const response = request(...(args as Parameters<TRequest>[]));

            if (!response) {
                return null;
            }

            return response
                .then((lData) => {
                    setData(lData);
                    return lData;
                })
                .catch((error) => {
                    setError(getErrorMessage(error));
                    throw error;
                })
                .finally(() => {
                    setLoading(false);
                    if (options?.loadingMessage) hideGlobalOverlay();
                }) as ReturnType<TRequest>;
        },
        [hideGlobalOverlay, options?.loadingMessage, options?.withSignal, request, showGlobalOverlay]
    );

    useEffect(() => {
        let req;
        if (options?.args) {
            req = doRequest(...(options.args as Parameters<TRequest>));
        } else {
            req = doRequest(...([] as Parameters<TRequest>));
        }
        req?.catch((err) => {
            if (err.message === 'canceled') Promise.resolve();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencyArray]);

    return {
        refetch: doRequest,
        isLoading,
        error,
        data,
    };
};

export { useRequestPromise };

export default useRequest;
