import {
    BackToList,
    CodeDiv,
    MessageDiv,
    ShowDetailsDiv,
    ShowRawDataDiv,
    StyledErrorRow,
} from '../DeclarationsNotificationsDrawer.styles';
import { Text } from 'components/ui/base/typography';
import { useState } from 'react';
import { colors } from '../../../../../theme';
import config from 'config/config';

type Props = Pick<SpecificDeclarationErrorsProps, 'latestNotification'> & {
    render?: (notification: any, rawMessage: string) => React.ReactNode;
    rawMessageOnly?: boolean;
};

const ActionRequiredNotification = ({ latestNotification, render, rawMessageOnly = false }: Props) => {
    const [showRawMessage, setShowRawMessage] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const { additionalProperties, rawMessage, notification } = latestNotification;

    const summaryView = (
        <StyledErrorRow justify="space-between">
            <CodeDiv color={colors.action}>
                <Text>{additionalProperties?.ie} </Text>
            </CodeDiv>
            <MessageDiv>
                <Text>{additionalProperties?.messageName}</Text>
            </MessageDiv>
            <ShowDetailsDiv>
                <span onClick={() => setShowDetails(true)}>Show details</span>
            </ShowDetailsDiv>
        </StyledErrorRow>
    );

    const detailsView = (
        <>
            <BackToList onClick={() => (showRawMessage ? setShowRawMessage(false) : setShowDetails(false))}>
                Back to list
            </BackToList>

            {showRawMessage ? <pre>{rawMessage}</pre> : render?.(notification, rawMessage)}
        </>
    );

    if (rawMessageOnly)
        return <pre>{render?.(notification, rawMessage) || rawMessage || JSON.stringify(notification, null, 2)}</pre>;

    if (!showRawMessage && !showDetails)
        return (
            <>
                {config.isPIT && (
                    <ShowRawDataDiv>
                        <span onClick={() => setShowRawMessage(true)}>Show raw data</span>
                    </ShowRawDataDiv>
                )}

                {summaryView}
            </>
        );
    else return <>{detailsView}</>;
};

export default ActionRequiredNotification;
