import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, getFullCodelistValue, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { dutiesAndTaxes } from '../../validation/H7ProductValidationSchema';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';
import { validators } from 'views/declarations/uk/export/validations/validations';

const AdditionalProcedures: FC<DeclarationFormCardProps> = ({
    defaultOpen,
    cardNumber,
    cardTotal,
    keyCard,
    propsPathPrefix,
    expandAll,
    ...props
}) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH7TooltipsByRefNumberAndField, getH7TooltipsByField } = useTooltips();

    return (
        <MultipleItemsCard
            defaultOpen={defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={cardNumber}
            cardTotal={cardTotal}
            title={t('dutiesAndTaxes.title')}
            keyCard={keyCard}
            initialValues={{ boxTaxType: '', boxAmount: '', boxTaxBaseUnit: '', dutiesAndTaxesMethodOfPayment: '' }}
            list={(values) => [
                {
                    field: t('dutiesAndTaxes.boxTaxType'),
                    value: getFullCodelistValue(values.boxTaxType, aisCodelists?.taxType),
                },
                {
                    field: t('dutiesAndTaxes.boxAmount'),
                    value: values.boxAmount,
                },
                {
                    field: t('dutiesAndTaxes.boxTaxBaseUnit'),
                    value: values.boxTaxBaseUnit,
                },
                {
                    field: t('dutiesAndTaxes.paymentMethod'),
                    value: getFullCodelistValue(values.dutiesAndTaxesMethodOfPayment, aisCodelists?.taxPaymentMethod),
                },
            ]}
            path={propsPathPrefix ?? ''}
            getFieldProps={props.getFieldProps}
            getFieldHelpers={props.getFieldHelpers}
            expandAll={expandAll}
            validationSchema={dutiesAndTaxes}
            condensed
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'boxTaxType'), formProps)}
                        label={t('dutiesAndTaxes.boxTaxType')}
                        tooltip={getH7TooltipsByField(t('dutiesAndTaxes.boxTaxType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={aisCodelists?.taxType}
                        condensed
                        codeValidation={[validators.exact(3)]}
                    />
                    <DeclarationNumberInput
                        maxLength={16}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'boxAmount'), formProps)}
                        label={t('dutiesAndTaxes.boxAmount')}
                        refTooltip={getH7TooltipsByField(t('dutiesAndTaxes.boxAmount'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        maxLength={6}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'boxTaxBaseUnit'), formProps)}
                        label={t('dutiesAndTaxes.boxTaxBaseUnit')}
                        tooltip={getH7TooltipsByField(t('dutiesAndTaxes.boxTaxBaseUnit'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'dutiesAndTaxesMethodOfPayment'), formProps)}
                        refNumber="4.8"
                        label={t('dutiesAndTaxes.paymentMethod')}
                        tooltip={getH7TooltipsByRefNumberAndField('4.8', t('dutiesAndTaxes.paymentMethod'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={aisCodelists?.taxPaymentMethod}
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />
                </FormCardContainer>
            )}
        />
    );
};

export default AdditionalProcedures;
