import styled from 'styled-components';
import { H4Style, H5Style } from '../../../../../../components/ui/base/typography/Typography.styles';
import { ClockCircleOutlined } from '@ant-design/icons';
import { FC } from 'react';
import Base64Image from '../../../../common/Base64Image';
import moment from 'moment';
import UkGvmsRecordUtils from '../../utils';
import { DivGap } from '../../../../../custom-declaration/declaration-table/components/DeclarationsTable.styled';

export const Ticket = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1e7042;
    padding: 40px 0;
`;

const UkGvmsTicket: FC<{
    gmrExpiredAt: string | undefined;
    gmrIdBarcode: string | undefined;
    gmrId: string | undefined;
}> = ({ gmrExpiredAt, gmrIdBarcode, gmrId }) => {
    const getExpiryText = (expiryDate: string | undefined) => {
        if (!expiryDate) return;

        // Calculate the difference in days, including both today and the last day.
        const momentExpiryDate = moment(expiryDate).startOf('day');
        const now = moment().startOf('day');
        const remainingDaysIncludingToday = momentExpiryDate.diff(now, 'days') + 1;

        const formattedExpiryDate = momentExpiryDate.format('DD/MM/YYYY');

        if (remainingDaysIncludingToday === 0) {
            return (
                <DivGap style={{ color: '#FFA500' }}>
                    <ClockCircleOutlined /> This GMR will expire today.
                </DivGap>
            );
        } else if (remainingDaysIncludingToday > 0) {
            return (
                <DivGap style={{ color: 'white' }}>
                    <ClockCircleOutlined /> This GMR will expire in{' '}
                    {String(remainingDaysIncludingToday).padStart(2, '0')} days on {formattedExpiryDate}.
                </DivGap>
            );
        } else {
            return (
                <DivGap style={{ color: 'red' }}>
                    <ClockCircleOutlined /> This GMR has expired on {formattedExpiryDate}.
                </DivGap>
            );
        }
    };

    return (
        <Ticket>
            <H4Style style={{ color: 'white' }}>Present this Goods Movement Reference at the port.</H4Style>
            <H4Style style={{ color: 'white' }}>{UkGvmsRecordUtils.addSpacesToGmrId(gmrId)}</H4Style>
            <Base64Image base64String={gmrIdBarcode} alt="barcode" />
            <H5Style style={{ fontWeight: 'normal', marginTop: 20 }}>{getExpiryText(gmrExpiredAt)}</H5Style>
        </Ticket>
    );
};

export default UkGvmsTicket;
