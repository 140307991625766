import { H5 } from '../../../components/ui/base/typography';
import { StyledInfoDiv } from '../Settings.styles';
import { Label } from './company-details/CompanyDetails.styles';
import axiosClient from 'config/axios';
import config from 'config/config';
import { useEffect, useState } from 'react';
import { SuccessResponse } from '../../../core/http/response';
import { notification, Switch } from 'antd';
import BusinessRulesValidationStatusSection from './BusinessRulesValidationStatusSection';

const CustomsExciseSettings = () => {
    const [eoriServiceEnabled, setEoriServiceEnabled] = useState<boolean>(false);

    const handleEoriServiceStatusChange = async (status: boolean) => {
        try {
            await axiosClient.put(
                `${config.declarationsUrl}/eori/mode?${new URLSearchParams({
                    enable: status.toString(),
                })}`
            );
        } catch (err) {
            notification.error({
                message: 'Please try again later.',
            });
        }

        setEoriServiceEnabled(status);
    };

    useEffect(() => {
        axiosClient
            .get<SuccessResponse<boolean>>(`${config.declarationsUrl}/eori/isEnable`)
            .then((res) => setEoriServiceEnabled(res.data.payload));
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 50 }}>
            <H5>C&E Settings</H5>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <StyledInfoDiv>
                    <Label style={{ marginRight: '2rem' }}>Eori Service</Label>
                    <Switch checked={eoriServiceEnabled} onChange={handleEoriServiceStatusChange} />
                </StyledInfoDiv>

                <BusinessRulesValidationStatusSection />
            </div>
        </div>
    );
};

export default CustomsExciseSettings;
