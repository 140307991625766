import Select, { DefaultOptionType, SelectProps } from 'antd/lib/select';
import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useDeclarationInputFocused from 'hooks/useDeclarationInputFocused';
import { FC, FocusEvent, ReactNode, UIEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import { Option } from '../../../base/select/Select';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    ViewOnlyLabel,
} from '../formInput/DeclarationInput.styles';
import { handleBlur } from '../utils';
import { StyledDeclarationSelect } from './DeclarationSelect.styles';
import { getFormikProps, toPlaceholder } from 'views/declarations/utils/form-utils';
import MultipleButtons from '../MultipleButtons';
import { useOutletContext, useLocation } from 'react-router-dom';
import { TemplateCheckboxes } from 'components/ui/composed/template/TemplateCheckbox';
import { useTemplateContext } from '../../template/TemplateContext';
import { useRegisterField } from '../../template/CardContext';
import SearchCustomerButton from 'views/declarations/common/search-customer/SearchCustomerButton';
import useFieldTemplateMetaData from '../../template/useTemplateViewEditControls';
import { Divider, Space, Tooltip } from 'antd';
import CodelistMenuDrawer from './CodelistMenuDrawer';
import useDeclarations from 'hooks/useDeclarations';
import { Validator } from 'views/declarations/uk/export/validations/validations';
import { kebabCase } from 'lodash';
import { TestProps } from 'core/utils/testTypes';
import { useMirrorMetaContext } from '../../mirroring/MirrorMetaContext';
import { LinkOutlined } from '@ant-design/icons';
import GvmsCustomFieldsDrawerButton from '../../../../../views/declarations/uk/gvms/components/GvmsCustomFieldsDrawerButton';
import { GvmsFormCustomFields } from '../../../../../views/declarations/uk/gvms/enums';
import { TextSmall } from '../../../base/typography';
import EllipsisTextTooltip from '../../../../EllipsisTextTooltip';

const { OptGroup } = Select;

export interface SelectOption {
    id: string;
    value: string;
    disabled?: boolean;
}

export interface MultipleProps {
    node: ReactNode;
}

export type GroupSelectOption = Record<string, Readonly<SelectOption[]>>;

export type FormSelectProps = SelectProps &
    TestProps & {
        label?: string;
        tooltip?: string;
        icon?: ReactNode;
        refNumber?: string | string[];
        selectOptions?: Readonly<SelectOption[]> | Readonly<GroupSelectOption>;
        children?: ReactNode;
        refClicked?: (refNumber: string | number) => void;
        fieldProps: FieldInputProps<any>;
        fieldMeta?: FieldMetaProps<any>;
        fieldHelper?: FieldHelperProps<any>;
        required?: boolean;
        multiple?: MultipleProps;
        hideKeys?: boolean;
        viewOnly?: boolean;
        disabled?: boolean;
        amendment?: boolean;
        canLoadMore?: boolean;
        onLoadMore?: () => void;
        fieldEvents?: {
            onChange?: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
            onBlur?: (e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLElement>) => void;
        };
        onPopupScroll?: (event: UIEvent<HTMLDivElement>) => void;
        onClear?: () => void;
        condensed?: boolean;
        multipleF?: {
            onAdd: () => void;
            onRemove: () => void;
            canAdd?: boolean;
            canRemove?: boolean;
        };
        onlyKeys?: boolean;
        specialName?: string; // Used for template meta handle of taxBox34Bis fields as of 13/04/23
        hidden?: boolean;
    } & (
        | { hideCodelistMenu: boolean; codeValidation?: Validator[] }
        | { hideCodelistMenu?: boolean; codeValidation: Validator[] }
    );

export interface SelectSearchOption {
    children: any;
    key: string;
    props: any;
    value: string;
}

export const hideAllDropdowns = () => {
    const selectDropdowns = document.querySelectorAll('.ant-select-dropdown');
    if (selectDropdowns) selectDropdowns.forEach((dropdown: any) => (dropdown.style.visibility = 'hidden'));
};
export const showAllDropdowns = () => {
    const selectDropdowns = document.querySelectorAll('.ant-select-dropdown');
    if (selectDropdowns) selectDropdowns.forEach((dropdown: any) => (dropdown.style.visibility = 'visible'));
};

const DeclarationSelect: FC<FormSelectProps> = ({
    label,
    refNumber: refNumberProp,
    tooltip,
    selectOptions,
    fieldProps: fieldPropsProp,
    fieldMeta: fieldMetaProp,
    fieldHelper: fieldHelpersProp,
    multiple,
    refClicked,
    required,
    hideKeys,
    viewOnly: viewOnlyProp,
    disabled,
    fieldEvents,
    onClear: handleClear,
    condensed,
    multipleF,
    onlyKeys,
    specialName,
    hideCodelistMenu,
    codeValidation,
    hidden,
    testId,
    canLoadMore,
    onLoadMore,
    onPopupScroll,
    ...selectProps
}) => {
    const [codelistMenuDrawer, setCodelistMenuDrawer] = useState<boolean | undefined>(undefined);
    const { t } = useTranslation('common');
    const { setFocused } = useDeclarationInputFocused();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const { template, templateFormik, form, isViewOnly: isTemplateViewOnly } = useTemplateContext();
    const { declaration } = useDeclarations();

    const { meta, handleOpenModal } = useMirrorMetaContext();

    const isFieldMirrored = useMemo(() => {
        if (!(fieldPropsProp?.name && meta)) return undefined;

        return meta?.[fieldPropsProp?.name]?.isMirrored;
    }, [fieldPropsProp?.name, meta]);

    useRegisterField({ path: fieldPropsProp?.name, required, hidden });
    const { isViewable, isEditable, isInvisible } = useFieldTemplateMetaData(fieldPropsProp?.name);

    const { fieldProps, fieldMeta, fieldHelpers } = useMemo(() => {
        if (template && templateFormik && form) {
            const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
            return { ...f, fieldHelpers: f.fieldHelper };
        }

        return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
    }, [template, templateFormik, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

    const handleRefClick = useCallback(() => {
        if (Array.isArray(refNumberProp)) return;

        if (refClicked) {
            refNumberProp ? refClicked(refNumberProp) : refClicked(label ?? '');
        }
    }, [refClicked, refNumberProp, label]);

    const handleEmptyValues = useCallback((item: SelectOption) => {
        return !!item.value ? item.value : item.id;
    }, []);

    const handleShowCodelistMenuDrawer = useCallback(() => {
        setCodelistMenuDrawer(true);

        hideAllDropdowns();
    }, []);

    const hideCodelistMenuDrawer = useCallback(() => {
        showAllDropdowns();

        setCodelistMenuDrawer(false);
    }, []);

    const onMouseClicked = (e: React.MouseEvent<Element>) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const showTooltip = useMemo(() => tooltip && tooltip.length > 0, [tooltip]);

    const viewOnly = useMemo(() => {
        if (template) return isTemplateViewOnly;
        if (outletContext?.amendment) return isViewable;
        return viewOnlyProp || isViewable || inViewOnly || declaration?.archived;
    }, [
        template,
        isTemplateViewOnly,
        outletContext?.amendment,
        isViewable,
        viewOnlyProp,
        inViewOnly,
        declaration?.archived,
    ]);

    const optionsRender = useMemo(() => {
        if (!selectOptions) {
            return null;
        }

        if (Array.isArray(selectOptions)) {
            return selectOptions?.map((item: SelectOption, i: number) =>
                onlyKeys ? (
                    <Option onClick={onMouseClicked} key={i} value={item.id} disabled={item.disabled}>
                        {item.id}
                    </Option>
                ) : !hideKeys ? (
                    <Option onClick={onMouseClicked} key={i} value={item.id} disabled={item.disabled}>
                        {`${item.id}${item.value ? ' - ' + item.value : ''}`}
                    </Option>
                ) : (
                    <Option onClick={onMouseClicked} key={i} value={item.id} disabled={item.disabled}>
                        {handleEmptyValues(item)}
                    </Option>
                )
            );
        }

        return Object.entries(selectOptions as Readonly<GroupSelectOption>).map(([group, options]) => {
            return (
                <OptGroup label={group}>
                    {options?.map((item: SelectOption, i: number) =>
                        !hideKeys ? (
                            <Option onClick={onMouseClicked} key={i} value={item.id}>
                                {`${item.id} - ${item.value}`}
                            </Option>
                        ) : (
                            <Option onClick={onMouseClicked} key={i} value={item.id}>
                                {handleEmptyValues(item)}
                            </Option>
                        )
                    )}
                </OptGroup>
            );
        });
    }, [handleEmptyValues, hideKeys, onlyKeys, selectOptions]);

    const refNumber = useMemo(() => {
        if (Array.isArray(refNumberProp)) {
            return (
                <>
                    {refNumberProp.map((ref) => (
                        <RefDiv key={`${label}-${ref}`}>{ref}</RefDiv>
                    ))}
                </>
            );
        } else if (typeof refNumberProp === 'string') {
            return <RefDiv>{refNumberProp}</RefDiv>;
        }
    }, [refNumberProp, label]);

    const fillButtonsDisabled = useMemo(
        () => template && !isEditable && !isViewable,
        [isEditable, isViewable, template]
    );
    const handleTemplateCheckboxChange = useMemo(() => {
        return fieldEvents?.onChange
            ? (value: any) => fieldEvents.onChange!(value, [])
            : selectProps.onChange
            ? (value: any) => {
                  fieldProps.onChange({ target: { value, name: fieldProps.name } });
                  selectProps.onChange!(value, []);
              }
            : undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldEvents?.onChange, fieldProps.onChange, selectProps.onChange]);

    const handleOpenMirrorSeveringModal = useCallback(() => {
        if (!isFieldMirrored) return;

        const revertValueCallback = () => {
            fieldHelpers?.setValue(selectProps?.value ?? fieldProps.value);
        };

        handleOpenModal?.(revertValueCallback);
    }, [isFieldMirrored, selectProps?.value, fieldProps?.value, handleOpenModal, fieldHelpers]);

    const viewOnlyValues = useMemo(() => {
        const selectedCodelist = (selectOptions as any)?.find((o: { id: string }) => o.id === fieldPropsProp?.value);
        const value = selectedCodelist ? `${selectedCodelist.id} - ${selectedCodelist.value}` : '-';

        return {
            value,
            tooltipTitle: selectedCodelist?.value,
        };
    }, [fieldPropsProp?.value, selectOptions]);

    const handlePopupScroll = (event: UIEvent<HTMLDivElement>) => {
        const target = event.currentTarget as HTMLDivElement;
        const { scrollTop, scrollHeight, clientHeight } = target;

        if (scrollTop + clientHeight + 100 >= scrollHeight && canLoadMore) {
            onLoadMore?.();
        }
    };

    const render = useMemo(
        () => (
            <>
                {viewOnly ? (
                    <>
                        <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                        {template && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                        <EllipsisTextTooltip tooltipTitle={viewOnlyValues.tooltipTitle}>
                            <TextSmall
                                data-testid={testId ?? kebabCase(label ?? fieldPropsProp?.name)}
                                style={{
                                    display: 'block',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {viewOnlyValues?.value}
                            </TextSmall>
                        </EllipsisTextTooltip>
                    </>
                ) : (
                    <>
                        <LabelDiv condensed={condensed}>
                            {label && (
                                <InputLabel>
                                    {label}
                                    {required && <InputLabelRequired>*</InputLabelRequired>}
                                </InputLabel>
                            )}

                            {refNumber}
                            {showTooltip && (
                                <IconTooltip
                                    title={label}
                                    tooltip={tooltip ?? t('defaultTooltip')}
                                    icon={<StyledInfoCircleFilled />}
                                    tooltipClicked={handleRefClick}
                                />
                            )}
                            {isFieldMirrored && (
                                <Tooltip title={'Mirrored field'}>
                                    <LinkOutlined
                                        style={{ marginLeft: '0.8rem', color: '#00CCFF', cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            )}
                        </LabelDiv>
                        {template && (
                            <TemplateCheckboxes
                                required={required}
                                fieldPath={fieldPropsProp?.name}
                                specialName={specialName}
                                onChange={handleTemplateCheckboxChange}
                            />
                        )}
                        <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                            <InputDiv>
                                <div style={{ flex: '1', maxWidth: '100%', width: '100%', minWidth: '0' }}>
                                    <StyledDeclarationSelect
                                        mirrored={isFieldMirrored}
                                        disabled={disabled || isInvisible}
                                        placeholder={selectProps.placeholder ?? toPlaceholder(label, 'Select') ?? ''}
                                        showSearch
                                        allowClear
                                        onClear={handleClear}
                                        testId={testId ?? kebabCase(label ?? fieldPropsProp?.name)}
                                        {...fieldProps}
                                        status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                                        filterOption={(input: string, option: SelectSearchOption) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value, option) => {
                                            fieldProps?.onChange({ target: { value, name: fieldProps.name } });
                                            selectProps.onChange?.(
                                                { target: { value, name: fieldProps.name } },
                                                option
                                            );
                                            handleOpenMirrorSeveringModal();
                                        }}
                                        {...fieldEvents}
                                        onBlur={(e) => {
                                            fieldHelpers?.setTouched(true);
                                            handleBlur(setFocused, fieldProps, e);
                                            selectProps?.onBlur?.(e);
                                            fieldEvents?.onBlur?.(e);
                                        }}
                                        value={selectProps.value ?? fieldProps.value}
                                        onClick={onMouseClicked}
                                        onFocus={() => setFocused(true)}
                                        size={condensed ? 'middle' : 'large'}
                                        condensed={condensed}
                                        mode={selectProps.mode}
                                        getPopupContainer={
                                            !!selectProps?.getPopupContainer
                                                ? selectProps?.getPopupContainer
                                                : (trigger) => trigger.parentElement
                                        }
                                        onPopupScroll={handlePopupScroll}
                                        dropdownRender={(codelist) => (
                                            <>
                                                {codelist}
                                                {!hideCodelistMenu && (
                                                    <>
                                                        <Divider style={{ margin: '8px 0' }} />
                                                        <Space style={{ padding: '0 8px 4px' }}>
                                                            <SearchCustomerButton
                                                                disabled={fillButtonsDisabled}
                                                                name="shows_err_w/o_name"
                                                                condensed={condensed}
                                                                title={'Codelist Menu'}
                                                                onClick={handleShowCodelistMenuDrawer}
                                                            />
                                                        </Space>
                                                    </>
                                                )}
                                                {declaration?.gvmsDeclaration &&
                                                    Object.values(GvmsFormCustomFields).some(
                                                        (customF) => customF === label
                                                    ) && (
                                                        <>
                                                            <Divider style={{ margin: '8px 0' }} />
                                                            <Space style={{ padding: '0 8px 4px' }}>
                                                                <GvmsCustomFieldsDrawerButton
                                                                    label={label as GvmsFormCustomFields}
                                                                    fieldPath={fieldPropsProp?.name}
                                                                />
                                                            </Space>
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    >
                                        {optionsRender}
                                    </StyledDeclarationSelect>
                                </div>

                                {multipleF && <MultipleButtons {...multipleF} />}
                                {multiple ? multiple.node : <></>}
                            </InputDiv>
                            <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                                {!!fieldMeta?.error && !!fieldMeta?.touched && (
                                    <ErrorLabel>{fieldMeta?.error}</ErrorLabel>
                                )}
                            </ErrorDiv>
                        </FormItem>
                        {codelistMenuDrawer && codeValidation && (
                            <CodelistMenuDrawer
                                codelistData={selectOptions}
                                fieldLabel={label}
                                fieldName={fieldPropsProp?.name}
                                visible={codelistMenuDrawer}
                                onClose={hideCodelistMenuDrawer}
                                codeValidation={codeValidation}
                            />
                        )}
                    </>
                )}
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            fieldMeta?.error,
            fieldMeta?.touched,
            fieldMeta?.value,
            fieldEvents?.onBlur,
            fieldEvents?.onChange,
            disabled,
            selectOptions,
            codelistMenuDrawer,
            viewOnly,
            fieldProps,
            multipleF?.canRemove,
            multipleF?.canAdd,
        ]
    );

    if (hidden || (isInvisible && !template)) return null;

    return render;
};

export default DeclarationSelect;
