import { InputProps, InputRef, Spin, Tooltip } from 'antd';
import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { TextSmall } from 'components/ui/base/typography';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useDeclarationInputFocused from 'hooks/useDeclarationInputFocused';
import { ChangeEventHandler, forwardRef, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import { SuccessLabel } from '../../formInput/FormInput.styles';
import {
    ErrorDiv,
    ErrorLabel,
    FlexDiv,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    SelectAccountNumbers,
    StyledInput,
    SuccessDiv,
    SuccessIcon,
    ViewOnlyLabel,
} from './DeclarationInput.styles';
import { getFormikProps, toPlaceholder } from 'views/declarations/utils/form-utils';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import MultipleButtons from '../MultipleButtons';
import { TemplateCheckboxes } from 'components/ui/composed/template/TemplateCheckbox';
import { useTemplateContext } from '../../template/TemplateContext';
import { useRegisterField } from '../../template/CardContext';
import SearchCustomerButton from '../../../../../views/declarations/common/search-customer/SearchCustomerButton';
import { useLocation, useOutletContext } from 'react-router-dom';
import useFieldTemplateMetaData from '../../template/useTemplateViewEditControls';
import useDeclarations from 'hooks/useDeclarations';
import { flushSync } from 'react-dom';
import { kebabCase } from 'lodash';
import { TestProps } from 'core/utils/testTypes';
import { useMirrorMetaContext } from '../../mirroring/MirrorMetaContext';
import { LinkOutlined } from '@ant-design/icons';

export interface MultipleProps {
    node: ReactNode;
}

export interface FormInputProps extends Omit<InputProps, 'multiple'>, TestProps {
    label?: string;
    tooltip?: string;
    icon?: ReactNode;
    refNumber?: string;
    children?: ReactNode;
    fieldProps?: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    multiple?: MultipleProps;
    disabled?: boolean;
    required?: boolean;
    maxLength?: number;
    viewOnly?: boolean;
    numbersAndLetters?: boolean;
    searching?: boolean;
    selectAccountNumbers?: () => void;
    eoriSuccess?: boolean;
    refClicked?: (refNumber: string | number) => void;
    isSearching?: boolean;
    fieldChange?: {
        onChange: ChangeEventHandler<HTMLInputElement>;
    };
    onSearchCustomer?: (args: {
        fieldProps?: FieldInputProps<any>;
        fieldMeta?: FieldMetaProps<any>;
        fieldHelpers?: FieldHelperProps<any>;
    }) => void;
    condensed?: boolean;
    multipleF?: {
        onAdd: () => void;
        onRemove: () => void;
        canAdd?: boolean;
        canRemove?: boolean;
    };
    onSearchTaricCode?: () => void;
    normal?: boolean;
    onMakeInvisible?: (name: string | undefined) => void;
    after?: ReactNode;
    specialName?: string; // Used for template meta handle of taxBox34Bis fields as of 13/04/23
}

export interface IconTooltipProps {
    tooltip?: string;
    icon?: ReactNode;
}

const DeclarationInput = forwardRef<InputRef, FormInputProps>(
    (
        {
            label,
            refNumber,
            tooltip,
            fieldMeta: fieldMetaProp,
            fieldProps: fieldPropsProp,
            fieldHelper: fieldHelpersProp,
            multiple,
            disabled,
            required,
            maxLength,
            viewOnly: viewOnlyProp,
            numbersAndLetters,
            selectAccountNumbers,
            eoriSuccess,
            refClicked,
            isSearching,
            fieldChange,
            onSearchCustomer,
            condensed,
            multipleF,
            onSearchTaricCode,
            normal,
            value: valueProp,
            onChange,
            onBlur,
            onMakeInvisible,
            after,
            specialName,
            hidden,
            testId,
            ...inputProps
        },
        ref
    ) => {
        const { t } = useTranslation('common');
        const { setFocused } = useDeclarationInputFocused();
        const outletContext = useOutletContext<{
            amendment?: boolean;
        }>();
        const location = useLocation();
        const inViewOnly = location.pathname.includes('view-only');
        const { template, templateFormik, form, isViewOnly: isTemplateViewOnly } = useTemplateContext();
        const { declaration } = useDeclarations();

        const { meta, handleOpenModal } = useMirrorMetaContext();

        const isFieldMirrored = useMemo(() => {
            const fieldPath = fieldPropsProp?.name || inputProps.name;
            if (!(fieldPath && meta)) return undefined;

            return meta?.[fieldPath]?.isMirrored;
        }, [fieldPropsProp?.name, inputProps?.name, meta]);

        const [localValue, setLocalValue] = useState<string | undefined | null>(undefined);

        const [selectingCustomer, setSelectingCustomer] = useState(false);

        useRegisterField({ path: fieldPropsProp?.name ?? inputProps.name, required, hidden });
        const { isViewable, isEditable, isInvisible } = useFieldTemplateMetaData(
            fieldPropsProp?.name ?? inputProps.name
        );

        const { fieldProps, fieldMeta, fieldHelpers } = useMemo(() => {
            if (template && templateFormik && !normal && form) {
                const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
                return { ...f, fieldHelpers: f.fieldHelper };
            }

            return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
        }, [template, templateFormik, normal, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

        useEffect(() => {
            if (hidden) return;
            if (fieldProps?.value === '' && fieldHelpers) {
                fieldHelpers.setValue(null);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [fieldProps?.value]);

        const handleAllowedChars = useCallback(
            (e: any) => {
                if (numbersAndLetters) {
                    const result = /^([^0-9,A-Z,a-z ])$/.test(e.key);
                    return result && e.preventDefault();
                }
                return e.key;
            },
            [numbersAndLetters]
        );

        const handleRefClick = useCallback(() => {
            if (refClicked) {
                refNumber ? refClicked(refNumber) : refClicked(label ?? '');
            }
        }, [refClicked, refNumber, label]);

        const iconInput = useMemo(() => {
            if (!!isSearching) {
                return <Spin size="small" />;
            }
            return eoriSuccess ? <SuccessIcon /> : <span />;
        }, [isSearching, eoriSuccess]);

        const showTooltip = useMemo(() => tooltip && tooltip.length > 0, [tooltip]);

        const viewOnly = useMemo(() => {
            if (template) return isTemplateViewOnly;
            if (outletContext?.amendment) return isViewable;
            return viewOnlyProp || isViewable || inViewOnly || declaration?.archived;
        }, [
            template,
            isTemplateViewOnly,
            outletContext?.amendment,
            isViewable,
            viewOnlyProp,
            inViewOnly,
            declaration?.archived,
        ]);

        const fillButtonsDisabled = useMemo(
            () => template && !isEditable && !isViewable,
            [isEditable, isViewable, template]
        );

        // Handle validation after choosing a customer
        useEffect(() => {
            if (selectingCustomer) {
                fieldProps?.onBlur({ target: { name: fieldProps.name } });
                fieldHelpers?.setTouched(true);
                setSelectingCustomer(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [fieldProps?.value]);

        const value = useMemo(() => {
            if (localValue !== undefined) return localValue;
            return valueProp ?? fieldProps?.value;
        }, [fieldProps?.value, localValue, valueProp]);

        const handleTemplateCheckboxChange = useMemo(() => {
            return onChange
                ? (value: any) => onChange({ target: { value, name: inputProps.name ?? '' } } as any)
                : fieldChange?.onChange
                ? (value: any) =>
                      fieldChange.onChange({
                          target: { value, name: inputProps.name ?? '' },
                      } as any)
                : undefined;
        }, [fieldChange, inputProps.name, onChange]);

        const handleOpenMirrorSeveringModal = useCallback(() => {
            if (!isFieldMirrored) return;

            const revertValueCallback = () => setLocalValue(valueProp ?? fieldProps?.value);

            handleOpenModal?.(revertValueCallback);
        }, [isFieldMirrored, valueProp, fieldProps?.value, handleOpenModal]);

        const render = useMemo(
            () => (
                <>
                    {viewOnly ? (
                        <>
                            <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                            {template && !normal && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                            <TextSmall data-testid={testId ?? kebabCase(label ?? fieldPropsProp?.name)}>
                                {value ?? '-'}
                            </TextSmall>
                        </>
                    ) : (
                        <>
                            <FlexDiv>
                                <LabelDiv condensed={condensed}>
                                    {label && (
                                        <InputLabel>
                                            {label}
                                            {required && <InputLabelRequired>*</InputLabelRequired>}
                                        </InputLabel>
                                    )}

                                    {refNumber && <RefDiv>{refNumber}</RefDiv>}
                                    {showTooltip && (
                                        <IconTooltip
                                            title={label}
                                            tooltip={tooltip ?? t('defaultTooltip')}
                                            icon={<StyledInfoCircleFilled />}
                                            tooltipClicked={handleRefClick}
                                        />
                                    )}
                                    {isFieldMirrored && (
                                        <Tooltip title={'Mirrored field'}>
                                            <LinkOutlined
                                                style={{ marginLeft: '0.8rem', color: '#00CCFF', cursor: 'pointer' }}
                                            />
                                        </Tooltip>
                                    )}
                                </LabelDiv>
                                {selectAccountNumbers && (
                                    <SelectAccountNumbers onClick={() => selectAccountNumbers()}>
                                        {t('selectFromCustomer')}
                                    </SelectAccountNumbers>
                                )}
                            </FlexDiv>
                            {template && !normal && (
                                <TemplateCheckboxes
                                    onMakeInvisible={onMakeInvisible}
                                    required={required}
                                    fieldPath={fieldPropsProp?.name ?? inputProps.name}
                                    specialName={specialName}
                                    onChange={handleTemplateCheckboxChange}
                                />
                            )}
                            <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                                <InputDiv>
                                    <StyledInput
                                        ref={ref}
                                        mirrored={isFieldMirrored}
                                        showSuccess={eoriSuccess ?? false}
                                        placeholder={inputProps.placeholder ?? toPlaceholder(label) ?? ''}
                                        suffix={iconInput}
                                        disabled={disabled || isInvisible}
                                        testId={testId ?? kebabCase(label ?? fieldPropsProp?.name)}
                                        maxLength={maxLength}
                                        onKeyDown={handleAllowedChars}
                                        status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                                        name={inputProps.name}
                                        size={condensed ? 'middle' : 'large'}
                                        condensed={condensed}
                                        {...fieldProps}
                                        value={value}
                                        onFocus={(e) => {
                                            setFocused(true);
                                            setLocalValue(e.target.value ?? null);
                                        }}
                                        onBlur={(e) => {
                                            flushSync(() => {
                                                if (onChange) onChange(e);
                                                else if (fieldChange?.onChange) fieldChange.onChange(e);
                                                else fieldProps?.onChange(e);
                                            });
                                            setFocused(false);
                                            setLocalValue(undefined);
                                            if (onBlur) onBlur(e);
                                            else fieldProps?.onBlur(e);
                                            fieldHelpers?.setTouched(true);
                                        }}
                                        onChange={(e) => {
                                            setLocalValue(e.target.value);
                                            handleOpenMirrorSeveringModal();
                                        }}
                                    />
                                    {multiple ? multiple.node : <></>}
                                    {multipleF && <MultipleButtons {...multipleF} />}
                                    {(!isViewable && !template) ||
                                        (!viewOnly && onSearchCustomer && (
                                            <SearchCustomer.Button
                                                compact
                                                disabled={fillButtonsDisabled}
                                                condensed={condensed}
                                                onClick={() => {
                                                    onSearchCustomer({ fieldProps, fieldMeta, fieldHelpers });
                                                    setSelectingCustomer(true);
                                                }}
                                            />
                                        ))}

                                    {!viewOnly && onSearchTaricCode && (
                                        <SearchCustomerButton
                                            compact
                                            disabled={fillButtonsDisabled}
                                            condensed={condensed}
                                            title=" Look up taric codes"
                                            onClick={() => onSearchTaricCode()}
                                        />
                                    )}
                                    {after}
                                </InputDiv>
                                {!eoriSuccess ? (
                                    <ErrorDiv
                                        error={!!fieldMeta?.error}
                                        touched={!!fieldMeta?.touched}
                                        condensed={condensed}
                                    >
                                        {!!fieldMeta?.error && !!fieldMeta?.touched && (
                                            <ErrorLabel>{fieldMeta?.error}</ErrorLabel>
                                        )}
                                    </ErrorDiv>
                                ) : (
                                    <SuccessDiv>
                                        <SuccessLabel>The EORI code is valid!</SuccessLabel>
                                    </SuccessDiv>
                                )}
                            </FormItem>
                        </>
                    )}
                </>
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [
                fieldMeta?.error,
                fieldMeta?.touched,
                fieldMeta?.value,
                value,
                disabled,
                onBlur,
                onChange,
                iconInput,
                viewOnly,
                multipleF?.canRemove,
                multipleF?.canAdd,
                eoriSuccess,
            ]
        );

        if (hidden || (isInvisible && !template)) return null;

        return render;
    }
);

export default DeclarationInput;
