import { Button, Divider } from 'antd';
import styled from 'styled-components';
import { H5 } from '../../../../../../components/ui/base/typography';

export const SubmitGmrButton = styled(Button)`
    background-color: #35a766;
    border: none;
    color: white;

    &:hover {
        background-color: #49ad78;
        color: white;
    }
`;

export const SectionModal = styled.div`
    max-height: 600px;
    overflow: auto;
`;

export const SectionCard = styled.div`
    margin-top: 20px;
`;

export const SectionTitle = styled(H5)`
    font-size: 2rem;
    margin-bottom: 0.7rem;
`;

export const FieldContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
`;

export const BoldText = styled.div`
    font-weight: bold;
`;

export const MessageText = styled.div`
    color: #9d9d9d;
    font-weight: 500;
`;

export const StyledDivider = styled(Divider)`
    margin: 7px 0;
    color: #9d9d9d;
`;

export const SectionMicCard = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 10px;
`;

export const SectionMicField = styled.div`
    display: flex;
    gap: 7px;
`;
