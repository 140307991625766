import { Form, Input, Modal, ModalProps, notification } from 'antd';
import { H5 } from 'components/ui/base/typography';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { InfoText } from './DeclarationInformation';
import { HollowButton } from './box44/Box44';
import { FooterButton } from '../sections/view-only/ViewOnlyTab';
import { DeclarationStatus } from '../../../store/declarations/enums/common/declaration-status';
import { Declaration } from '../../../store/declarations/declaration';

const CancelDeclarationModalButton = ({
    declaration,
    cancelCdsDeclarationRequest,
}: {
    declaration: Declaration | undefined;
    cancelCdsDeclarationRequest: (reason: string) => Promise<Declaration>;
}) => {
    const [cancelDeclarationModalVisible, setCancelDeclarationModalVisible] = useState<boolean>(false);
    const showCancelDeclarationModal = useCallback(() => setCancelDeclarationModalVisible(true), []);
    const hideCancelDeclarationModal = useCallback(() => setCancelDeclarationModalVisible(false), []);

    const showCancelButton = useMemo(
        () => declaration?.status !== DeclarationStatus.DRAFT && declaration?.declarationExternalEntity === 'CDS',
        [declaration?.declarationExternalEntity, declaration?.status]
    );

    const cancelCdsDeclaration = useCallback(
        (data?: { reason?: string }) => {
            let message: string;
            if (!data?.reason) {
                message = 'Missing reason!';
                notification.error({ message });
                return Promise.reject({ message });
            }

            return cancelCdsDeclarationRequest(data.reason)
                .then((data: Declaration) => {
                    message = 'Cancellation request sent successfully!';
                    notification.success({ message });
                    return { ...data, message };
                })
                .catch((error: any) => {
                    message = 'An error occurred while sending cancellation request!';
                    notification.error({ message });
                    return { ...error, message };
                })
                .finally(() => {
                    hideCancelDeclarationModal();
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cancelCdsDeclarationRequest]
    );

    return (
        <>
            {showCancelButton && <FooterButton onClick={showCancelDeclarationModal}>Cancel</FooterButton>}

            <CancelDeclarationModal
                visible={cancelDeclarationModalVisible}
                onClose={hideCancelDeclarationModal}
                onDeclarationCancel={cancelCdsDeclaration}
            />
        </>
    );
};

export default CancelDeclarationModalButton;

interface Props extends ModalProps {
    onDeclarationCancel: (data?: { reason?: string }) => Promise<void>;
    onClose: () => void;
    cancelText?: string;
}

const CancelDeclarationModal = ({ onDeclarationCancel, onClose, cancelText, ...modalProps }: Props): ReactElement => {
    return (
        <Modal
            title={<H5>Cancel declaration</H5>}
            destroyOnClose
            onCancel={onClose}
            footer={[
                <HollowButton onClick={onClose} size="large">
                    Close
                </HollowButton>,
                <HollowButton form="cancellationForm" htmlType="submit" inverted size="large">
                    {cancelText ?? 'Send Cancellation Request'}
                </HollowButton>,
            ]}
            {...modalProps}
        >
            <InfoText>Please enter the reason for the declaration cancellation.</InfoText>
            <Form
                style={{ marginTop: '1rem', height: 180 }}
                id="cancellationForm"
                layout="vertical"
                onFinish={onDeclarationCancel}
            >
                <Form.Item name="reason" label="Reason:" rules={[{ required: true, message: 'Field is required' }]}>
                    <Input.TextArea style={{ resize: 'none' }} rows={5} placeholder="Enter reason" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
