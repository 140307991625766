import { FC, useState } from 'react';
import { BusinessRule } from 'store/business-rules/business-rule';
import {
    BusinessRuleCode,
    BusinessRuleDescription,
    BusinessRuleMessage,
    StyledBusinessRuleRow,
    StyledDownOutlined,
} from '../BusinessRules.styles';

export const AisBusinessRuleRow: FC<{ rule: BusinessRule }> = ({ rule }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    return (
        <StyledBusinessRuleRow>
            <BusinessRuleMessage onClick={() => setShowDescription((s) => !s)}>
                <StyledDownOutlined expanded={showDescription} />
                <BusinessRuleCode>{rule.code}:</BusinessRuleCode>
                <span>{'message' in rule && rule.message}</span>
            </BusinessRuleMessage>
            {showDescription ? <BusinessRuleDescription>{rule.description}</BusinessRuleDescription> : <></>}
        </StyledBusinessRuleRow>
    );
};

export default AisBusinessRuleRow;
