import useNotification from 'hooks/useNotification';
import useSession from 'hooks/useSession';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from './axios';
import config from './config';

const useAxiosInterceptors = () => {
    const { t } = useTranslation('login');
    const { logout, updateToken, getToken } = useSession();
    const [ready, setReady] = useState(false);
    const { setErrorNotification } = useNotification();

    const updateTokenStore = () => {
        const authStorage = getToken();
        if (authStorage) {
            const store = JSON.parse(authStorage);
            return store.token;
        }
    };

    useEffect(() => {
        // add request interceptors
        api.interceptors.request.use(async (config) => {
            try {
                const newHeaders: { Authorization?: string } = {};
                const token: string = updateTokenStore();
                if (token) {
                    newHeaders['Authorization'] = `Bearer ${token}`;
                }

                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        ...newHeaders,
                    },
                };
            } catch (error) {
                return config;
            }
        });

        // add response interceptors
        api.interceptors.response.use(
            (response) => response,
            async (error) => {
                console.log(error);
                if (error) {
                    const { response } = error;
                    if (response) {
                        const { status } = response;
                        const originalRequest = error.config;
                        if (status === 401) {
                            if (
                                originalRequest.method !== 'post' &&
                                originalRequest.url !== `${config.authUrl}/session` &&
                                error.response &&
                                !originalRequest._retry
                            ) {
                                originalRequest._retry = true;
                                await updateToken();
                                return api(originalRequest);
                            } else {
                                if (error?.response?.data?.code === 'AUTH-30108') {
                                    setErrorNotification(
                                        t('error.defaultErrorTitle'),
                                        error?.response?.data?.message,
                                        status
                                    );
                                } else {
                                    setErrorNotification(
                                        t('error.defaultErrorTitle'),
                                        t('error.sessionExpired'),
                                        status
                                    );
                                }
                                logout();
                            }
                        }
                    }
                }
                return Promise.reject(error);
            }
        );
        setReady(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ready;
};

export default useAxiosInterceptors;
