import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';

import ieH1ValidationSchema, {
    goodsShipmentItemSchemaRequired as ieH1ProductValidationSchema,
} from '../ireland/import/h1/validation/ValidationSchema';
import { parseDeclaration as h1ParseForm, ParserOptions } from '../ireland/import/h1/validation/utils';
import irelandI1ValidationErrorsParser from '../ireland/import/i1/validation/irelandI1ValidationErrorsParser';

import ieH7ValidationSchema from '../ireland/import/h7/validation/ValidationSchema';
import ieH7ProductValidationSchema from '../ireland/import/h7/validation/H7ProductValidationSchema';
import { parseValidationErrors as h7ParseValidationErrors } from '../ireland/import/h7/validation/utils';

import ieEnsValidationSchema, {
    goodsItems as ieEnsProductValidationSchema,
} from '../ireland/ens/validation/irelandEnsValidation';
import ieEnsParseValidationErrors from '../ireland/ens/validation/parseValidationErrors';

import CdsExportDeclarationUtils from '../uk/export/utils';
import cdsParseValidationErrors from '../uk/parseValidationErrors';
import { cdsExportFormValidation } from '../uk/UkForm';
import { cdsExportProductSectionValidation } from '../uk/UkProductsSection';
import IrelandImportH1DeclarationUtils from '../ireland/import/h1/utils';
import IrelandImportH7DeclarationUtils from '../ireland/import/h7/utils';
import { DeclarationFieldName } from '../common/declarationType';
import { cdsImportH2ProductValidation, cdsImportH2Validation } from '../uk/import/h2/validation/cdsImportH2Validation';
import { IEH1_DataStructure, IEH1_ItemDataStructure } from '../ireland/import/h1/ie-h1-data-structure';
import { IEH7_DataStructure, IEH7_ItemDataStructure } from '../ireland/import/h7/ie-h7-data-structure';
import { cdsImportH1ProductValidation, cdsImportH1Validation } from '../uk/import/h1/validation/cdsImportH1Validation';
import CdsImportH1DeclarationUtils from '../uk/import/h1/utils';
import { ENS_DataStructure, ENS_ItemDataStructure } from '../ireland/ens/ie-ens-data-structure';
import { DeclarationExternalEntity } from '../../../store/declarations/enums/common/declaration-external-entity';
import {
    irelandExportB1ValidationHeader,
    irelandExportB1ValidationItem,
} from '../ireland/export/validation/IrelandExportB1Validation';
import {
    irelandExportB2ValidationHeader,
    irelandExportB2ValidationItem,
} from '../ireland/export/validation/IrelandExportB2Validation';
import {
    irelandExportB3ValidationHeader,
    irelandExportB3ValidationItem,
} from '../ireland/export/validation/IrelandExportB3Validation';
import {
    irelandExportB4ValidationHeader,
    irelandExportB4ValidationItem,
} from '../ireland/export/validation/IrelandExportB4Validation';
import {
    irelandExportC1ValidationHeader,
    irelandExportC1ValidationItem,
} from '../ireland/export/validation/IrelandExportC1Validation';
import {
    irelandExportC2ValidationHeader,
    irelandExportC2ValidationItem,
} from '../ireland/export/validation/IrelandExportC2Validation';
import irelandExportValidationErrorsParser from '../ireland/export/validation/IrelandExportValidationErrorsParser';
import IrelandEnsDeclarationUtils from '../ireland/ens/utils';
import { ValidationSchema } from '../uk/export/validations/validations';
import { Declarations, TransformData } from '../common/declaration-view/DeclarationView';
import { AnyObjectSchema } from 'yup';
import useProducts from '../../../hooks/useProducts';
import useDeclarations from '../../../hooks/useDeclarations';
import { ParsedForm } from '../../../store/declaration-form-errors/ParsedForm';
import ukGvmsRecordParser from '../uk/gvms/validation/ukGvmsRecordParser';
import ukGvmsValidation from '../uk/gvms/validation/UkGvmsValidation';
import ukNctsValidationErrorsParser from '../uk/ncts/validation/UkNctsValidationErrorsParser';
import {
    AISMessageTypes,
    AESMessageTypes,
    NCTSMessageTypes,
    TSDMessageTypes,
} from '../../../store/declarations/enums/common/declaration-types';
import {
    arrivalAtExitValidationHeader,
    arrivalAtExitValidationItem,
} from '../ireland/arrival/validation/ArrivalAtExitValidation';
import {
    irelandTsdG4ValidationHeader,
    irelandTsdValidationItem,
} from '../ireland/tsd/validation.ts/IrelandTsdG4Validation';
import { irelandTsdG4G3ValidationHeader } from '../ireland/tsd/validation.ts/IrelandTsdG4G3Validation';
import irelandTsdValidationErrorsParser from '../ireland/tsd/validation.ts/IrelandTsdValidationErrorsParser';
import pbnRecordParser from '../ireland/pbn/validation/pbnRecordParser';
import pbnValidation from '../ireland/pbn/validation/pbnValidation';
import irelandNctsValidationErrorsParser from '../ireland/ncts/validation/IrelandNctsValidationErrorsParser';
import {
    irelandNctsD1ValidationHeader,
    irelandNctsD1ValidationItem,
} from '../ireland/ncts/validation/IrelandNctsD1Validation';
import {
    irelandNctsD2ValidationHeader,
    irelandNctsD2ValidationItem,
} from '../ireland/ncts/validation/IrelandNctsD2Validaton';
import {
    irelandNctsD3ValidationHeader,
    irelandNctsD3ValidationItem,
} from '../ireland/ncts/validation/IrelandNctsD3Validation';
import {
    irelandNctsD4ValidationHeader,
    irelandNctsD4ValidationItem,
} from '../ireland/ncts/validation/IrelandNctsD4Validation';
import irelandEtdValidationErrorsParser from '../ireland/etd/validation/IrelandEtdValidationErrorsParser';
import {
    irelandEtdD3ValidationHeader,
    irelandEtdD3ValidationItem,
} from '../ireland/etd/validation/IrelandEtdD3Validation';
import { ukNctsD1ValidationHeader, ukNctsD1ValidationItem } from '../uk/ncts/validation/UkNctsD1Validation';
import { ukNctsD2ValidationHeader, ukNctsD2ValidationItem } from '../uk/ncts/validation/UkNctsD2Validation';
import { ukNctsD3ValidationHeader, ukNctsD3ValidationItem } from '../uk/ncts/validation/UkNctsD3Validation';
import { ukNctsD4ValidationHeader, ukNctsD4ValidationItem } from '../uk/ncts/validation/UkNctsD4Validation';
import {
    irelandI1ValidationHeader,
    irelandI1ValidationItem,
} from '../ireland/import/i1/validation/irelandI1Validation';

export interface DeclarationMapValues<TDeclaration extends Declarations> {
    declarationValidationSchema?: AnyObjectSchema | ((numberOfItems?: number) => AnyObjectSchema);
    declarationFormValidations?: ValidationSchema;
    productFormValidations?: ValidationSchema;
    productValidationSchema?: AnyObjectSchema | undefined;
    parseForm?: (form: TDeclaration, options?: ParserOptions) => ParsedForm;
    declarationType: DeclarationFieldName;
    declarationInternalType: DeclarationInternalType;
    declarationName: string;
    updateDeclarationFuncName: keyof OnlyFunctions<ReturnType<typeof useDeclarations>>;
    updateProductFuncName?: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    createProductFuncName?: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    listProductsFuncName?: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    transformData?: TransformData;
    productFieldsToAutosaveOn?: string[];
    payloadPath?: string;
    transformProductCreateResponse?: (res: any) => any;
    numberOfItemsFieldName?: string;
    itemPath?: string;
    // structure and itemStructure used for template
    // default values of nested objects and arrays
    structure?: Record<string, any>;
    itemStructure?: Record<string, any>;

    grossMassPath?: string;
    itemItemAmountPath?: string;
    itemGrossMassPath?: string;
    itemItemCurrencyPath?: string;
    lrnPath?: string;
}

type DeclarationMap = {
    [key in string]: DeclarationMapValues<any> | { [key: string]: Partial<DeclarationMapValues<any>> };
};

const declarationMap: DeclarationMap = {
    [DeclarationFieldName.IE_IMPORT_H1]: {
        default: {
            declarationName: 'Import',
            declarationType: DeclarationFieldName.IE_IMPORT_H1,
            declarationInternalType: DeclarationInternalType.IMPORT,
            declarationValidationSchema: ieH1ValidationSchema,
            productValidationSchema: ieH1ProductValidationSchema,
            parseForm: h1ParseForm,
            createProductFuncName: 'createIrelandImportProduct',
            listProductsFuncName: 'listIrelandH1Products',
            updateDeclarationFuncName: 'updateIrelandImportDeclaration',
            updateProductFuncName: 'updateIrelandImportProduct',
            productFieldsToAutosaveOn: ['itemAmount', 'taxBox43bis'],
            numberOfItemsFieldName: 'numberOfItems',
            transformData: {
                declaration: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return IrelandImportH1DeclarationUtils.transformForClient(data);

                        const currentDeclaration = data?.irelandImportDeclaration;
                        if (!currentDeclaration) return;
                        return {
                            ...data,
                            irelandImportDeclaration:
                                IrelandImportH1DeclarationUtils.transformForClient(currentDeclaration),
                        };
                    },
                    forServer: (data: any) => {
                        return IrelandImportH1DeclarationUtils.transformForServer(data);
                    },
                },
                product: {
                    forClient: (data: any) => {
                        return IrelandImportH1DeclarationUtils.transformProductForClient(data);
                    },
                    forServer: (data: any) => {
                        return IrelandImportH1DeclarationUtils.transformProductForServer(data);
                    },
                },
            },
            itemPath: 'goodsShipment.goodsShipmentItem',
            structure: IEH1_DataStructure,
            itemStructure: IEH1_ItemDataStructure,
            grossMassPath: 'grossMass',
            itemGrossMassPath: 'goodsInformation.grossMass',
            itemItemAmountPath: 'itemAmount',
            lrnPath: 'lrn',
        },
        [AISMessageTypes.I1]: {
            declarationValidationSchema: undefined,
            productValidationSchema: undefined,
            declarationFormValidations: irelandI1ValidationHeader,
            productFormValidations: irelandI1ValidationItem,
            parseForm: irelandI1ValidationErrorsParser,
        },
    },
    [DeclarationFieldName.IE_IMPORT_H7]: {
        declarationName: 'H7',
        declarationType: DeclarationFieldName.IE_IMPORT_H7,
        declarationInternalType: DeclarationInternalType.IMPORT,
        declarationValidationSchema: ieH7ValidationSchema,
        productValidationSchema: ieH7ProductValidationSchema,
        parseForm: h7ParseValidationErrors,
        createProductFuncName: 'createIrelandH7ImportProduct',
        listProductsFuncName: 'listIrelandH7Products',
        updateDeclarationFuncName: 'updateIrelandH7ImportDeclaration',
        updateProductFuncName: 'updateIrelandH7ImportProduct',
        numberOfItemsFieldName: 'numberOfItems',
        transformData: {
            declaration: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return IrelandImportH7DeclarationUtils.transformForClient(data);

                    const currentDeclaration = data?.irelandH7ImportDeclaration;
                    if (!currentDeclaration) return;
                    return {
                        ...data,
                        irelandH7ImportDeclaration:
                            IrelandImportH7DeclarationUtils.transformForClient(currentDeclaration),
                    };
                },
                forServer: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformForServer(data);
                },
            },
            product: {
                forClient: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformProductForClient(data);
                },
                forServer: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformProductForServer(data);
                },
            },
        },
        itemPath: 'goodsShipment.governmentAgencyGoodsItem',
        structure: IEH7_DataStructure,
        itemStructure: IEH7_ItemDataStructure,
        grossMassPath: 'goodsShipment.grossMass',
        itemGrossMassPath: 'grossMass',
        itemItemAmountPath: 'itemAmountInvoicedIntrinsicValue.valueAmount',
        itemItemCurrencyPath: 'itemAmountInvoicedIntrinsicValue.valueCurrency',
        lrnPath: 'lrn',
    },
    [DeclarationFieldName.IE_EXPORT]: {
        default: {
            declarationName: 'Ireland Export',
            declarationType: DeclarationFieldName.IE_EXPORT,
            declarationInternalType: DeclarationInternalType.EXPORT,
            parseForm: irelandExportValidationErrorsParser,
            createProductFuncName: 'createIrelandExportProduct',
            listProductsFuncName: 'listIrelandExportProducts',
            updateDeclarationFuncName: 'updateIrelandExportDeclaration',
            updateProductFuncName: 'updateIrelandExportProduct',
            itemPath: 'goodsShipment.goodsItem',
            lrnPath: 'exportOperation.lrn',
        },
        [AESMessageTypes.B1]: {
            declarationFormValidations: irelandExportB1ValidationHeader,
            productFormValidations: irelandExportB1ValidationItem,
        },
        [AESMessageTypes.B2]: {
            declarationFormValidations: irelandExportB2ValidationHeader,
            productFormValidations: irelandExportB2ValidationItem,
        },
        [AESMessageTypes.B3]: {
            declarationFormValidations: irelandExportB3ValidationHeader,
            productFormValidations: irelandExportB3ValidationItem,
        },
        [AESMessageTypes.B4]: {
            declarationFormValidations: irelandExportB4ValidationHeader,
            productFormValidations: irelandExportB4ValidationItem,
        },
        [AESMessageTypes.C1]: {
            declarationFormValidations: irelandExportC1ValidationHeader,
            productFormValidations: irelandExportC1ValidationItem,
        },
        [AESMessageTypes.C2]: {
            declarationFormValidations: irelandExportC2ValidationHeader,
            productFormValidations: irelandExportC2ValidationItem,
        },
    },
    [DeclarationFieldName.IE_ENS]: {
        declarationName: 'ENS',
        declarationType: DeclarationFieldName.IE_ENS,
        declarationInternalType: DeclarationInternalType.ENS,
        declarationValidationSchema: ieEnsValidationSchema,
        productValidationSchema: ieEnsProductValidationSchema,
        parseForm: ieEnsParseValidationErrors,
        createProductFuncName: 'createIrelandEnsProduct',
        numberOfItemsFieldName: 'numberOfItems',
        listProductsFuncName: 'listIrelandEnsProducts',
        updateDeclarationFuncName: 'updateEntrySummaryDeclaration',
        updateProductFuncName: 'updateIrelandEnsProduct',
        itemPath: 'goodsShipment.goodsItems',
        structure: ENS_DataStructure,
        itemStructure: ENS_ItemDataStructure,
        transformData: {
            product: {
                forClient: (data: any) => {
                    return IrelandEnsDeclarationUtils.transformProductForClient(data);
                },
                forServer: (data: any) => {
                    return IrelandEnsDeclarationUtils.transformProductForServer(data);
                },
            },
        },
        lrnPath: 'exportOperation.lrn',
    },
    [DeclarationFieldName.IE_ETD]: {
        declarationName: 'ETD',
        declarationType: DeclarationFieldName.IE_ETD,
        declarationInternalType: DeclarationInternalType.ETD,
        parseForm: irelandEtdValidationErrorsParser,
        createProductFuncName: 'createIrelandEtdProduct',
        listProductsFuncName: 'listIrelandEtdProducts',
        updateDeclarationFuncName: 'updateElectronicTransportDocument',
        updateProductFuncName: 'updateIrelandEtdProduct',
        itemPath: 'goodsShipment.goodsShipmentItem',
        declarationFormValidations: irelandEtdD3ValidationHeader,
        productFormValidations: irelandEtdD3ValidationItem,
    },
    [DeclarationFieldName.IE_TSD]: {
        default: {
            declarationName: 'Temporary Storage',
            declarationType: DeclarationFieldName.IE_TSD,
            declarationInternalType: DeclarationInternalType.TEMPORARY,
            parseForm: irelandTsdValidationErrorsParser,
            createProductFuncName: 'createIrelandTsdProduct',
            listProductsFuncName: 'listIrelandTsdProducts',
            updateDeclarationFuncName: 'updateTemporaryStorageDeclaration',
            updateProductFuncName: 'updateIrelandTsdProduct',
            itemPath: 'goodsShipment.goodsShipmentItem',
            productFormValidations: irelandTsdValidationItem,
        },
        [TSDMessageTypes.G4]: {
            declarationFormValidations: irelandTsdG4ValidationHeader,
        },
        [TSDMessageTypes.G4G3]: {
            declarationFormValidations: irelandTsdG4G3ValidationHeader,
        },
    },
    [DeclarationFieldName.IE_NCTS]: {
        default: {
            declarationName: 'NCTS',
            declarationType: DeclarationFieldName.IE_NCTS,
            declarationInternalType: DeclarationInternalType.NCTS,
            parseForm: irelandNctsValidationErrorsParser,
            createProductFuncName: 'createIrelandNctsProduct',
            listProductsFuncName: 'listIrelandNctsProducts',
            updateDeclarationFuncName: 'updateNctsDeclaration',
            updateProductFuncName: 'updateIrelandNctsProduct',
            itemPath: 'consignment.houseConsignment.x.consignmentItem',
            lrnPath: 'transitOperation.lrn',
        },
        [NCTSMessageTypes.D1]: {
            declarationFormValidations: irelandNctsD1ValidationHeader,
            productFormValidations: irelandNctsD1ValidationItem,
        },
        [NCTSMessageTypes.D2]: {
            declarationFormValidations: irelandNctsD2ValidationHeader,
            productFormValidations: irelandNctsD2ValidationItem,
        },
        [NCTSMessageTypes.D3]: {
            declarationFormValidations: irelandNctsD3ValidationHeader,
            productFormValidations: irelandNctsD3ValidationItem,
        },
        [NCTSMessageTypes.D4]: {
            declarationFormValidations: irelandNctsD4ValidationHeader,
            productFormValidations: irelandNctsD4ValidationItem,
        },
    },
    [DeclarationFieldName.IE_ARRIVAL]: {
        declarationName: 'Arrival At Exit',
        declarationType: DeclarationFieldName.IE_ARRIVAL,
        declarationInternalType: DeclarationInternalType.ARRIVAL,
        declarationFormValidations: arrivalAtExitValidationHeader,
        productFormValidations: arrivalAtExitValidationItem,
        parseForm: irelandExportValidationErrorsParser,
        createProductFuncName: 'createArrivalAtExitProduct',
        listProductsFuncName: 'listArrivalAtExitProducts',
        updateDeclarationFuncName: 'updateArrivalAtExitDeclaration',
        updateProductFuncName: 'updateArrivalAtExitProduct',
        itemPath: 'goodsShipment.goodsItem',
    },
    [DeclarationExternalEntity.CDS]: {
        default: {
            declarationName: 'Export',
            declarationType: DeclarationFieldName.UK,
            declarationInternalType: DeclarationInternalType.EXPORT,
            parseForm: cdsParseValidationErrors,
            createProductFuncName: 'createCdsProduct',
            listProductsFuncName: 'listCdsProducts',
            updateDeclarationFuncName: 'updateCdsDeclaration',
            updateProductFuncName: 'updateCdsProduct',
            payloadPath: 'cdsDeclarationPayload',
            transformProductCreateResponse: (res: any) => res?.governmentAgencyGoodsItem,
            transformData: {
                declaration: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return CdsExportDeclarationUtils.transformForClient(data);

                        const currentDeclaration = data?.cdsDeclaration;
                        if (!currentDeclaration) return;
                        return {
                            ...data,
                            cdsDeclaration: CdsExportDeclarationUtils.transformForClient(currentDeclaration),
                        };
                    },
                    forServer: (data: any) => {
                        return CdsExportDeclarationUtils.transformForServer(data);
                    },
                },
                product: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return CdsExportDeclarationUtils.transformProductForClient(data);
                        return CdsExportDeclarationUtils.transformProductForClient(data?.governmentAgencyGoodsItem);
                    },
                    forServer: (data: any) => {
                        return { governmentAgencyGoodsItem: CdsExportDeclarationUtils.transformProductForServer(data) };
                    },
                },
            },
            numberOfItemsFieldName: 'goodsItemQuantity',
            itemPath: 'goodsShipment.governmentAgencyGoodsItem',
            lrnPath: 'lrn',
        },
        B1: {
            declarationFormValidations: cdsExportFormValidation,
            productFormValidations: cdsExportProductSectionValidation,
        },
        H1: {
            declarationName: 'Import',
            declarationInternalType: DeclarationInternalType.IMPORT_NEW,
            declarationFormValidations: cdsImportH1Validation,
            productFormValidations: cdsImportH1ProductValidation,
            transformData: {
                declaration: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return CdsImportH1DeclarationUtils.transformForClient(data);

                        const currentDeclaration = data?.cdsDeclaration;
                        if (!currentDeclaration) return;
                        return {
                            ...data,
                            cdsDeclaration: CdsImportH1DeclarationUtils.transformForClient(currentDeclaration),
                        };
                    },
                    forServer: (data: any) => {
                        return CdsImportH1DeclarationUtils.transformForServer(data);
                    },
                },
                product: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return CdsImportH1DeclarationUtils.transformProductForClient(data);
                        return CdsImportH1DeclarationUtils.transformProductForClient(data?.governmentAgencyGoodsItem);
                    },
                    forServer: (data: any) => {
                        return {
                            governmentAgencyGoodsItem: CdsImportH1DeclarationUtils.transformProductForServer(data),
                        };
                    },
                },
            },
        },
        H2: {
            declarationName: 'Import',
            declarationInternalType: DeclarationInternalType.IMPORT_NEW,
            declarationFormValidations: cdsImportH2Validation,
            productFormValidations: cdsImportH2ProductValidation,
        },
    },
    [DeclarationFieldName.GVMS]: {
        declarationName: 'Goods Movement Reference',
        declarationType: DeclarationFieldName.GVMS,
        declarationInternalType: DeclarationInternalType.GVMS,
        parseForm: ukGvmsRecordParser,
        updateDeclarationFuncName: 'updateGvmsRecord',
        declarationFormValidations: ukGvmsValidation,
    },
    [DeclarationFieldName.PBN]: {
        declarationName: 'Pre-Boarding Notification',
        declarationType: DeclarationFieldName.PBN,
        declarationInternalType: DeclarationInternalType.PBN,
        parseForm: pbnRecordParser,
        updateDeclarationFuncName: 'updatePbnRecord',
        declarationFormValidations: pbnValidation,
    },
    [DeclarationFieldName.UK_NCTS]: {
        default: {
            declarationName: 'NCTS',
            declarationType: DeclarationFieldName.UK_NCTS,
            declarationInternalType: DeclarationInternalType.NCTS,
            parseForm: ukNctsValidationErrorsParser,
            createProductFuncName: 'createUkNctsProduct',
            listProductsFuncName: 'listUkNctsProducts',
            updateDeclarationFuncName: 'updateUkNctsDeclaration',
            updateProductFuncName: 'updateUkNctsProduct',
            itemPath: 'consignment.houseConsignment.x.consignmentItem',
            lrnPath: 'transitOperation.lrn',
            numberOfItemsFieldName: 'goodsItemQuantity',
        },
        [NCTSMessageTypes.D1]: {
            declarationFormValidations: ukNctsD1ValidationHeader,
            productFormValidations: ukNctsD1ValidationItem,
        },
        [NCTSMessageTypes.D2]: {
            declarationFormValidations: ukNctsD2ValidationHeader,
            productFormValidations: ukNctsD2ValidationItem,
        },
        [NCTSMessageTypes.D3]: {
            declarationFormValidations: ukNctsD3ValidationHeader,
            productFormValidations: ukNctsD3ValidationItem,
        },
        [NCTSMessageTypes.D4]: {
            declarationFormValidations: ukNctsD4ValidationHeader,
            productFormValidations: ukNctsD4ValidationItem,
        },
    },
};

export default declarationMap;
