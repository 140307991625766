import { kebabCase } from 'lodash';
import { FC } from 'react';
import { Option, SearchIcon } from './Filter.styles';
import { useParams } from 'react-router-dom';
import { DeclarationInternalType } from '../../../../../store/declarations/enums/common/declaration-internal-type';

interface FilterOptionsProps {
    options: string[];
    onSelect?: (option: string) => void;
}
export const FilterOptions: FC<FilterOptionsProps> = ({ options, onSelect }) => {
    const { type } = useParams();
    const isGvms = type === DeclarationInternalType.GVMS;

    return (
        <>
            {options.map((o, index) => (
                <Option
                    key={`${o}-${index}`}
                    onClick={() => onSelect && onSelect(o)}
                    data-testid={`${kebabCase(o)}-option`}
                >
                    <SearchIcon /> {isGvms ? o.replace('Declaration', 'Record') : o}
                </Option>
            ))}
        </>
    );
};

export default FilterOptions;
