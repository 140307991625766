import { NewButton } from '../../../../../../components/ui/base/button/Button.styles';
import {
    HeaderTitle,
    TemplateTitle as InvoiceTitle,
    XButton,
} from '../../../../../../components/ui/composed/template/TemplateHeader';
import { CloseOutlined } from '@ant-design/icons';
import { BtMasterInformationItemDetails } from '../../../../Form.styles';
import { HTMLAttributes } from 'react';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import { SModalHeader } from '../../../../../../components/ui/composed/template/TemplateModal';

interface Props extends HTMLAttributes<HTMLDivElement> {
    handleClose: () => void;
    handleChangeForm: (form: 'master' | 'product') => void;
    activeForm: 'master' | 'product';
    onSave: () => void;
    itemDetailsDisabled: boolean;
}

const InvoiceHeader = ({ handleClose, handleChangeForm, activeForm, onSave, itemDetailsDisabled }: Props) => {
    const toMasterInformation = () => handleChangeForm?.('master');
    const toItemDetails = () => handleChangeForm?.('product');

    return (
        <SModalHeader>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                <InvoiceTitle>Invoice Extraction Summary</InvoiceTitle>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <NewButton onClick={onSave}>Save</NewButton>
                    <XButton onClick={handleClose}>
                        <CloseOutlined />
                    </XButton>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div>
                    <BtMasterInformationItemDetails active={activeForm === 'master'} onClick={toMasterInformation}>
                        Master Information
                    </BtMasterInformationItemDetails>
                    <BtMasterInformationItemDetails
                        active={activeForm === 'product'}
                        onClick={toItemDetails}
                        disabled={itemDetailsDisabled}
                    >
                        Item Details
                    </BtMasterInformationItemDetails>
                </div>

                <FormCardContainer invoice>
                    <HeaderTitle>Field Name</HeaderTitle>
                    <HeaderTitle>Current Value</HeaderTitle>
                    <HeaderTitle>Suggested Value</HeaderTitle>
                    <HeaderTitle>Select</HeaderTitle>
                </FormCardContainer>
            </div>

            <hr />
        </SModalHeader>
    );
};

export default InvoiceHeader;
