import Button from 'components/ui/base/button';
import { H4 } from 'components/ui/base/typography';
import { enumToText } from 'core/utils/enum-to-text';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIrelandSadDraft, getIrelandClearanceDraft } from 'store/declarations/client';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';
import {
    isDeclarationRefundDepositRequested,
    isDeclarationRefundRemissionRequested,
} from 'views/declarations/utils/declaration-utils';
import DocumentTable from './DocumentTable';
import { CheckIcon, DocumentsDiv, ExtraSpaceRow, MainDiv, SadDocTitle, StyledButton } from './SummaryView.styles';
import useFormUtils from '../../../../hooks/useFormUtils';

const SummaryView: FC = () => {
    const { toggleCoreTemplateStatus } = useDeclarations();
    const { declaration } = useDeclarations();
    const navigate = useNavigate();

    const { countryLowerCase: country } = useFormUtils();

    const isCoreTemplate = declaration?.isCoreTemplate;

    const handleToggleCoreTemplateStatus = useCallback(async () => {
        if (!declaration?.id) return;

        const notificationType = isCoreTemplate ? 'removeAsCoreTemplate' : 'setAsCoreTemplate';

        await toggleCoreTemplateStatus(declaration.id, {
            notificationType,
        });
    }, [toggleCoreTemplateStatus, declaration?.id, isCoreTemplate]);

    const handleDuplicate = useCallback(async () => {
        if (!declaration) return;
        navigate(`/invoice-upload/${country}/${declaration.declarationInternalType}/job/${declaration.jobId}`, {
            state: { declaration: declaration },
        });
    }, [declaration, country, navigate]);

    const handleCreateRefundRemission = () => {
        navigate('refunds/remissions/new', {
            state: { activeTabKey: DeclarationTabKeys.REFUNDS },
        });
    };

    const handleCreateRefundDeposit = () => {
        navigate('refunds/deposits/new', {
            state: { activeTabKey: DeclarationTabKeys.REFUNDS },
        });
    };

    return (
        <MainDiv>
            <ExtraSpaceRow>
                <H4>
                    <CheckIcon />
                    {`${country?.charAt(0).toUpperCase()}${country?.slice(1)}  ${
                        declaration?.declarationInternalType && enumToText(declaration?.declarationInternalType)
                    } submitted successfully`}
                </H4>
            </ExtraSpaceRow>
            <ExtraSpaceRow>
                <StyledButton onClick={() => handleDuplicate()}>Duplicate</StyledButton>
                <StyledButton onClick={() => handleToggleCoreTemplateStatus()}>
                    {isCoreTemplate ? 'Remove as Core Template' : 'Use as Core Template'}
                </StyledButton>

                {isDeclarationRefundRemissionRequested(declaration) && (
                    <StyledButton onClick={() => handleCreateRefundRemission()}> Create Refund Remission</StyledButton>
                )}

                {isDeclarationRefundDepositRequested(declaration) && (
                    <Button onClick={() => handleCreateRefundDeposit()}> Create Refund Deposit</Button>
                )}
            </ExtraSpaceRow>
            <DocumentsDiv>
                <SadDocTitle>Sad Document</SadDocTitle>
                <DocumentTable id={declaration?.id} request={getIrelandSadDraft} fileName="sad-document" />
            </DocumentsDiv>
            <DocumentsDiv>
                <SadDocTitle>Clearance slip</SadDocTitle>
                <DocumentTable
                    id={declaration?.id}
                    request={getIrelandClearanceDraft}
                    fileName="clearanceslip-document"
                />
            </DocumentsDiv>
        </MainDiv>
    );
};
export default SummaryView;
