import { ReactElement } from 'react';
import { notification } from 'antd';
import { PaginatedParams } from 'core/http/pagination';
import { FormikErrors, setNestedObjectValues, useFormik } from 'formik';
import useDeclarationAutoRefresh from 'hooks/useDeclarationAutoRefresh';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarationNotifications from 'hooks/useDeclarationNotifications';
import useDeclarations, { UpdateDeclarationFunction } from 'hooks/useDeclarations';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useExchanges from 'hooks/useExchanges';
import useProducts, { CreateProductFunction, ListProductFunction, UpdateProductFunction } from 'hooks/useProducts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import FormFooter from 'views/declarations/footer/Footer';
import { StyledHeader, StyledLayout } from 'views/declarations/Form.styles';
import {
    FormAction,
    removeEmptyObjects,
    removeEmptyObjectsFromDeclarationArrays,
    trimWhiteSpaces,
} from 'views/declarations/utils/form-utils';
import { get, isEmpty, merge, omit, set } from 'lodash';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { AnyObjectSchema } from 'yup';
import useHandleFormAction from './utils/useHandleFormAction';
import useAutoSaveProduct from './utils/useAutoSaveProduct';
import useHandleErrors from './utils/useHandleErrors';
import useHandleUnsavedChanges from './utils/useHandleUnsavedChanges';
import validate, { FormModel, transformErrorsForFormik } from 'views/declarations/uk/export/validations/validations';
import { TemplateContextProvider } from 'components/ui/composed/template/TemplateContext';
import { DeclarationFieldName } from '../declarationType';
import useUpdateViewOfDeclaration from './utils/useUpdateViewOfDeclaration';
import MirrorMetaContextProvider from '../../../../components/ui/composed/mirroring/MirrorMetaContext';
import ProductContextProvider from './ProductContext';
import { IrelandExportDeclaration } from '../../../../store/declarations/ireland/export-declaration';
import useFormUtils from '../../../../hooks/useFormUtils';
import {
    irelandExportRepresentativeContactPersonLevelRequired,
    irelandExportRepresentativeEoriLevelRequired,
} from '../../ireland/export/validation/IrelandExportB1Validation';
import { deepObjHasTruthyNonObjValue } from '../../../../core/utils/objects';
import useGetDeclarationMapValues from '../../../../hooks/useGetDeclarationMapValues';
import { DeclarationContextProvider } from '../../../../utils/DeclarationContext';
import useJobs from '../../../../hooks/useJobs';
import GvmsHeaderDraftMessage from '../../uk/gvms/components/GvmsHeaderDraftMessage';
import { generateDucr } from '../../../../store/declarations/client';
import useCDSPreviousDocumentUtils from '../../../../hooks/useCdsPreviousDocumentUtils';
import { CdsDeclarationPayloadPayload } from '../../../../store/declarations/uk/cds-declaration';
import { DeclarationInternalType } from '../../../../store/declarations/enums/common/declaration-internal-type';
import { DeclarationExternalEntity } from '../../../../store/declarations/enums/common/declaration-external-entity';
import DeclarationPopupManager from './components/DeclarationPopupManager';
import { NctsDeclaration } from '../../../../store/declarations/ireland/ncts-declaration';

export enum FormSection {
    MASTER_DETAILS = 0,
    PRODUCTS = 1,
}

export type Declarations = NonNullable<Declaration[DeclarationFieldName]>;

interface Transform<TReturn = any> {
    forClient?: (data: any, straight?: boolean) => TReturn;
    forServer?: (data: any, straight?: boolean) => TReturn;
}

export interface TransformData {
    product?: Transform;
    declaration?: Transform;
}
const DeclarationView = <TDeclaration extends Declarations>(): ReactElement => {
    // * UseState
    const location = useLocation();
    const { declarationId, productId: urlParamsProductId } = useParams<{ declarationId: string; productId: string }>();
    const [declaration, setDeclaration] = useState<Declaration | undefined>(undefined);
    const [triedToSubmit, setTriedToSubmit] = useState<boolean>(false);
    const [cancelClicked, setCancelClicked] = useState<boolean>(false);
    const [productId, setProductId] = useState<string | undefined>(urlParamsProductId);
    const [isSaving, setIsSaving] = useState(false);
    const [declarationSaved, setDeclarationSaved] = useState(true);

    // * Hooks
    const declarations = useDeclarations();
    const { job } = useJobs({ jobId: declarations?.declaration?.jobId });
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const products = useProducts({ productId });
    const { setFormDeclarationErrors, clearFormDeclarationErrors } = useDeclarationFormErrors();
    const { listExchanges } = useExchanges();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { showErrorNotification } = useDeclarationNotifications();
    const { declarationValidation, setFormAction, setHasUnsavedChanges, isSavingAsDraft } = useDeclarationValidation({
        formAction: null,
    });

    const { isAes } = useFormUtils();
    const { ...mapper } = useGetDeclarationMapValues();

    const currentDeclaration = useMemo(
        () => declaration?.[mapper.declarationType],
        [declaration, mapper.declarationType]
    );

    const getValidationSchema = (): AnyObjectSchema | undefined =>
        mapper.declarationValidationSchema instanceof Function
            ? mapper.declarationValidationSchema(products.products?.total)
            : mapper.declarationValidationSchema;

    const getDeclarationFormValidations = useCallback(
        (formikValues: TDeclaration) => {
            if (!isAes) return mapper.declarationFormValidations;

            if (deepObjHasTruthyNonObjValue((formikValues as IrelandExportDeclaration)?.representative?.contactPerson))
                return merge(
                    {},
                    mapper.declarationFormValidations,
                    irelandExportRepresentativeContactPersonLevelRequired
                );
            else if (deepObjHasTruthyNonObjValue((formikValues as IrelandExportDeclaration)?.representative))
                return merge({}, mapper.declarationFormValidations, irelandExportRepresentativeEoriLevelRequired);
            else return mapper.declarationFormValidations;
        },
        [mapper.declarationFormValidations, isAes]
    );

    const formik = useFormik<TDeclaration>({
        initialValues:
            (mapper.payloadPath ? get(currentDeclaration, mapper.payloadPath) : (currentDeclaration as any)) ?? {},
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: getValidationSchema(),
        validate: mapper.declarationFormValidations
            ? async (values) => {
                  // TODO: Memoize already validated fields to speed up the process.
                  // If we don't do this, we may end up with a lot of unnecessary requests to eori check service for example.
                  return transformErrorsForFormik(
                      await validate(new FormModel(values), getDeclarationFormValidations(values))
                  );
              }
            : () => {},
        onSubmit: () => {},
    });

    const productInitialValues = useMemo(() => {
        if (mapper.transformData?.product?.forClient) {
            return mapper.transformData?.product?.forClient(
                isEmpty(products.product)
                    ? declarations.declarationTemplate?.template?.product.defaults
                    : products.product
            );
        }
        if (isEmpty(products.product)) {
            return declarations.declarationTemplate?.template?.product.defaults;
        }
        return products.product;
    }, [declarations.declarationTemplate?.template?.product.defaults, products.product, mapper.transformData?.product]);

    const productsFormik = useFormik({
        initialValues: productInitialValues ?? {},
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: mapper.productValidationSchema,
        validate: mapper.productFormValidations
            ? async (values) =>
                  // TODO: Memoize already validated fields to speed up the process.
                  // If we don't do this, we may end up with a lot of unnecessary requests to eori check service for example.
                  transformErrorsForFormik(await validate(new FormModel(values), mapper.productFormValidations!))
            : () => {},
        onSubmit: () => {},
    });

    // * UseMemo

    const touchFieldsToShowErrorsMasterDetails = useCallback(
        (validations: FormikErrors<TDeclaration>) => {
            formik.setTouched(setNestedObjectValues(validations, true));
        },
        [formik]
    );

    const validateForm = useCallback(async () => {
        const masterDetailsValidation = await formik.validateForm();

        touchFieldsToShowErrorsMasterDetails(masterDetailsValidation);

        if (isEmpty(masterDetailsValidation)) {
            // Reset errors
            clearFormDeclarationErrors();

            return true;
        }

        if (isSavingAsDraft) return true;

        if (mapper.parseForm) {
            setFormDeclarationErrors(mapper.parseForm(masterDetailsValidation));
        }

        return false;
    }, [
        mapper,
        formik,
        touchFieldsToShowErrorsMasterDetails,
        isSavingAsDraft,
        clearFormDeclarationErrors,
        setFormDeclarationErrors,
    ]);

    const updateProduct = async (form: any, declarationId: string) => {
        if (!products.product?.id || !mapper.updateProductFuncName) return;

        return (products[mapper.updateProductFuncName] as UpdateProductFunction)(
            removeEmptyObjects(form),
            declarationId,
            products.product?.id
        ).then((res) => {
            if (!res) return;
            setHasUnsavedChanges(false);
            return res;
        });
    };
    const createProduct = async (form: any, declarationId: string) => {
        if (products.product?.id || !mapper.createProductFuncName) return;

        if (declaration?.ieNctsDeclaration || declaration?.ukNctsDeclaration)
            form.houseConsignmentId = (formik.values as NctsDeclaration)?.consignment?.houseConsignment?.[0]?.id;

        await (products[mapper.createProductFuncName] as CreateProductFunction)(form, declarationId).then((res) => {
            if (!res?.id) return;

            let data;

            if (mapper.transformProductCreateResponse) {
                const transformed = mapper.transformProductCreateResponse(res);
                data = mapper.transformData?.product?.forClient?.(transformed) ?? transformed ?? {};
            } else {
                data = mapper.transformData?.product?.forClient?.(res) ?? res ?? {};
            }

            productsFormik.setValues(data);
            setProductId(res.id);
            return data;
        });
    };
    const handleProductsFormSubmit = async (item: GoodsShipmentItem) => {
        if (!declaration) return;

        let form = removeEmptyObjectsFromDeclarationArrays(trimWhiteSpaces({ ...item }, { emptyStringToNull: true }));

        const isProductCreated = products.product?.id;

        if (mapper.transformData?.product?.forServer) {
            form = mapper.transformData.product.forServer(form);
        }

        let data;
        if (isProductCreated) {
            data = await updateProduct(form, declaration.id!);
        } else {
            data = await createProduct(form, declaration.id!);
        }

        window.EventBus.publish('afterDeclarationSave', data);

        await declarations.getDeclaration(declaration.id!);
    };

    const saveDeclaration = async (data: any, declarationId: string, withNotification: boolean) => {
        return (declarations[mapper.updateDeclarationFuncName] as UpdateDeclarationFunction)(
            job?.customerId,
            declarationId,
            data,
            withNotification
        ).then((res) => {
            if (!res) return;

            window.EventBus.publish('afterDeclarationSave');
            setHasUnsavedChanges(false);
            setDeclarationSaved(true);
        });
    };

    const saveAsDraft = async (
        withNotification = true,
        paramData?: Partial<TDeclaration>,
        declarationWithNewItem?: TDeclaration
    ) => {
        if (!declaration) return;

        let form = removeEmptyObjects(
            removeEmptyObjectsFromDeclarationArrays(
                trimWhiteSpaces(declarationWithNewItem ?? merge(formik.values, paramData), {
                    emptyStringToNull: true,
                })
            )
        );

        if (mapper.transformData?.declaration?.forServer !== undefined) {
            if (mapper.payloadPath) {
                form = {
                    ...declaration?.[mapper.declarationType],
                    [mapper.payloadPath]: form,
                };
            }

            form = mapper.transformData.declaration.forServer(form);
        }

        const formWithoutNotifications = omit(form, ['notifications']);
        const data = { ...declaration, [mapper.declarationType]: formWithoutNotifications };

        window.EventBus.publish('beforeDeclarationSave', true);
        return saveDeclaration(data, declaration.id!, withNotification);
    };

    const { setDUCRMUCR, previousDocumentPath } = useCDSPreviousDocumentUtils({ formik });

    const refetchDeclaration = useCallback(() => {
        return declarations.getDeclaration(declaration?.id!);
    }, [declaration?.id, declarations]);

    useDeclarationAutoRefresh();
    useUpdateViewOfDeclaration({
        declarationStatus: declarations.declaration?.status,
        declarationIsArchived: declarations.declaration?.archived,
    });

    useEffect(() => {
        clearFormDeclarationErrors();
        listExchanges();
        return () => {
            clearFormDeclarationErrors();
            products.clearError();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        formik.setValues(
            (mapper.payloadPath ? get(currentDeclaration, mapper.payloadPath) : (currentDeclaration as any)) ?? {}
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDeclaration]);
    useEffect(() => {
        productsFormik.setValues(productInitialValues ?? {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productInitialValues]);

    // Trigger transform
    useEffect(() => {
        const transformForClient = mapper.transformData?.declaration?.forClient;

        if (transformForClient) {
            setDeclaration(transformForClient(declarations.declaration, false));
        } else {
            setDeclaration(declarations.declaration);
        }
    }, [mapper.transformData?.declaration?.forClient, declarations.declaration, mapper.transformData]);

    useHandleFormAction({
        productsFormik,
        saveDeclaration: saveAsDraft,
        saveProduct: handleProductsFormSubmit,
        setIsSaving,
        validateForm,
        refetchDeclaration,
    });
    useAutoSaveProduct({
        setFormAction,
        declarationStatus: declaration?.status,
        productsFormik,
        fieldsToAutosaveOn: mapper.productFieldsToAutosaveOn,
    });
    useHandleErrors({
        formik,
        productsFormik,
        validationErrorsParser: mapper.parseForm,
        triedToSubmit,
    });
    useHandleUnsavedChanges({
        declarationSaved,
        formik,
        productsFormik,
        setDeclarationSaved,
    });

    return (
        <DeclarationContextProvider>
            <TemplateContextProvider>
                <MirrorMetaContextProvider>
                    <ProductContextProvider productId={productId} setProductId={(value) => setProductId(value)}>
                        <StyledHeader>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <DeclarationPopupManager
                                    submitting={triedToSubmit}
                                    submissionModalsActions={{
                                        validateForm,
                                        onCancel: () => setTriedToSubmit(false),
                                    }}
                                    formik={formik}
                                    saveAsDraft={saveAsDraft}
                                />
                            </div>
                        </StyledHeader>
                        {declarations?.declaration?.gvmsDeclaration && declarations?.declaration.status === 'DRAFT' && (
                            <GvmsHeaderDraftMessage />
                        )}
                        <StyledLayout>
                            <Outlet
                                context={{
                                    formik,
                                    productsFormik,
                                    cancelProducts: cancelClicked,
                                    clearCancel: async () => {
                                        await declarations.getDeclaration(declarationId!);
                                        setCancelClicked(false);
                                    },
                                    saveAsDraft,
                                    declaration,
                                }}
                            />
                        </StyledLayout>

                        {!location.pathname.includes('view-only') && (
                            /**TODO: Remove duplicated footer in ViewOnlyTab and use this one */
                            <FormFooter
                                declarationStatus={declaration?.status}
                                isSaving={isSaving}
                                iconDeclarationSaved={declarationSaved}
                                disabled={
                                    currentDeclaration &&
                                    typeof currentDeclaration === 'object' &&
                                    'userInvalidationSubmitted' in currentDeclaration &&
                                    !!currentDeclaration.userInvalidationSubmitted
                                }
                                cancelSaveProduct={() => {
                                    setCancelClicked(true);
                                    setFormAction(null);
                                    if (declarationValidation.hasUnsavedChanges) {
                                        setFormAction(FormAction.PRODUCT);
                                    }
                                    navigate(`/declarations/${declaration?.id}/products`, {
                                        replace: true,
                                        state: { comingBackFromProductView: true },
                                    });
                                }}
                                saveProduct={{
                                    trigger: () => {
                                        showGlobalOverlay({
                                            type: 'LoadingOverlay',
                                        });
                                        setTriedToSubmit(false);
                                        setFormAction(FormAction.PRODUCT);
                                    },
                                    loading: isSaving,
                                }}
                                saveDraft={{
                                    trigger: async () => {
                                        showGlobalOverlay({
                                            type: 'LoadingOverlay',
                                        });
                                        setTriedToSubmit(false);
                                        setFormAction(FormAction.DRAFT);
                                    },
                                    loading: isSaving,
                                }}
                                submitDeclaration={{
                                    trigger: async ({ saveProduct }) => {
                                        showGlobalOverlay({
                                            type: 'LoadingOverlay',
                                        });

                                        if (!declaration?.id) return;

                                        /* FIXME: this setVALUE it's just for the declaration's product list to have the same ordering as the product table */
                                        const params: Partial<PaginatedParams> = { size: 1000 };

                                        let declarationWitNewItem: TDeclaration | undefined = undefined;
                                        if (saveProduct && mapper.listProductsFuncName) {
                                            await handleProductsFormSubmit(productsFormik.values);
                                            await refetchDeclaration();
                                            await validateForm();

                                            const productsList = (
                                                await (products[mapper.listProductsFuncName] as ListProductFunction)(
                                                    declaration.id,
                                                    params
                                                )
                                            ).list;

                                            if (mapper?.itemPath)
                                                declarationWitNewItem = set(
                                                    formik.values,
                                                    mapper.itemPath,
                                                    productsList
                                                );
                                        }

                                        await saveAsDraft(true, {}, declarationWitNewItem);

                                        /**
                                         * Handle additional Cds Export submission things
                                         * - DUCR generation
                                         */
                                        if (
                                            declarations.declaration?.declarationInternalType ===
                                                DeclarationInternalType.EXPORT &&
                                            declarations.declaration?.declarationExternalEntity ===
                                                DeclarationExternalEntity.CDS
                                        ) {
                                            try {
                                                const generatedDucr = await generateDucr(declaration.id);
                                                if (
                                                    generatedDucr !==
                                                    (formik?.values as CdsDeclarationPayloadPayload)?.ducr
                                                ) {
                                                    const newPreviousDocuments = setDUCRMUCR('DCR', generatedDucr);
                                                    await saveAsDraft(
                                                        true,
                                                        set({}, previousDocumentPath, newPreviousDocuments)
                                                    );
                                                }
                                            } catch {
                                                notification.error({
                                                    message:
                                                        '"Exporter - Identification Number" is required for DUCR generation',
                                                });
                                            }
                                        }

                                        const isValid = await validateForm();

                                        if (!isValid) {
                                            showErrorNotification(
                                                t('error.declaration_invalid_title'),
                                                t('error.formRequired')
                                            );
                                        }

                                        hideGlobalOverlay();

                                        setTriedToSubmit(true);
                                    },
                                }}
                            />
                        )}
                    </ProductContextProvider>
                </MirrorMetaContextProvider>
            </TemplateContextProvider>
        </DeclarationContextProvider>
    );
};

export default DeclarationView;
