import {
    CheckSquareOutlined,
    CloseSquareOutlined,
    DeleteOutlined,
    EditOutlined,
    PhoneOutlined,
    ContainerOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import Avatar from 'components/ui/base/avatar/Avatar';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { ListPayload } from 'core/http/response';
import { FC, useState } from 'react';
import { Customer, CustomerSortParameter } from 'store/customers/customer';
import { Row } from 'antd';
import { CommandButton } from '../../../components/ui/composed/dashboard/DashboardTable';
import { TableChangeParams } from '../../../utils/tableHelpers';

interface Props {
    data?: ListPayload<Customer & { jobs: number }>;
    onDelete?: (ids: string[]) => void;
    onEdit: (id: string) => void;
    onDetails: (id: string) => void;
    loading: boolean;
    onUnarchive?: (id: string) => void;
    onArchive?: (id: string) => void;
    atArchivedPath?: boolean;
    onChange: (params: TableChangeParams) => void;
}

const CustomersTable: FC<Props> = ({
    data,
    onDelete,
    onEdit,
    onDetails,
    onChange,
    loading,
    onArchive,
    onUnarchive,
    atArchivedPath,
}) => {
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: (Customer & { jobs: number })[]) => {
            const ids = selectedRows.map((c) => c.id);
            setDeleteIds(ids);
        },
    };

    const columns: ColumnsType<any> = [
        {
            title: 'EORI',
            dataIndex: CustomerSortParameter.EORI,
            key: CustomerSortParameter.EORI,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            sorter: true,
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.eori ? <span>{record.eori}</span> : <span>-</span>,
        },
        {
            title: 'Reference Code',
            dataIndex: CustomerSortParameter.REFERENCE_CODE,
            key: CustomerSortParameter.REFERENCE_CODE,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            sorter: true,
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.referenceCode ? <span>{record.referenceCode}</span> : <span>-</span>,
        },
        {
            title: 'Customer',
            dataIndex: CustomerSortParameter.NAME,
            key: CustomerSortParameter.NAME,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.name ? (
                    <span>
                        <Row align="middle" wrap={false}>
                            <Avatar name={record.name}></Avatar>
                            {record.name}
                        </Row>
                    </span>
                ) : (
                    <span>-</span>
                ),
            sorter: true,
        },
        {
            title: 'Active Jobs',
            dataIndex: 'JOBS',
            key: 'JOBS',
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Customer & { jobs: number }, index: number) => (
                <span>
                    {record.jobs > 0 ? (
                        <>
                            <Row style={{ width: '80%' }} justify="space-between" align="middle" wrap={false}>
                                <span>{record.jobs} active jobs</span>
                                <i style={{ color: '#219653' }}>
                                    <CheckSquareOutlined />
                                </i>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row style={{ width: '80%' }} justify="space-between" align="middle" wrap={false}>
                                <span>No active jobs</span>
                                <i style={{ color: '#EB5757' }}>
                                    <CloseSquareOutlined />
                                </i>
                            </Row>
                        </>
                    )}
                </span>
            ),
            sorter: (a: any, b: any) => a.jobs - b.jobs,
        },
        {
            title: 'Email',
            dataIndex: CustomerSortParameter.EMAIL,
            key: CustomerSortParameter.EMAIL,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.contact?.email ? <span>{record.contact.email}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Phone Number',
            dataIndex: CustomerSortParameter.PHONE,
            key: CustomerSortParameter.PHONE,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.contact?.phoneNumber ? (
                    <Row style={{ width: '80%' }} align="middle" wrap={false}>
                        <i style={{ marginRight: '0.9rem' }}>
                            <PhoneOutlined />
                        </i>

                        <span>{record.contact.phoneNumber}</span>
                    </Row>
                ) : (
                    <span>-</span>
                ),
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            align: 'left',
            render: (text: string, record: Customer & { jobs: number }) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '1rem',
                            justifyContent: 'left',
                        }}
                    >
                        {onEdit && !atArchivedPath && (
                            <CommandButton tooltip="Edit" icon={<EditOutlined />} onClick={() => onEdit(record.id)} />
                        )}
                        {onDelete && !atArchivedPath && (
                            <CommandButton
                                tooltip="Edit"
                                icon={<DeleteOutlined />}
                                onClick={() => onDelete?.([record.id])}
                            />
                        )}
                        {onArchive && onUnarchive && (
                            <CommandButton
                                tooltip={!atArchivedPath ? 'Archive' : 'Unarchive'}
                                icon={<ContainerOutlined />}
                                onClick={() => (!atArchivedPath ? onArchive(record.id) : onUnarchive(record.id))}
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Table
                rowKey="id"
                style={{ marginTop: '2.5rem' }}
                columns={columns}
                dataSource={data?.list}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                }}
                onChange={(pagination, _, sorter) => onChange({ pagination, sorter })}
                loading={loading}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete?.(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};
export default CustomersTable;
