import { Col, Row } from 'antd';
import SearchBar from 'components/ui/base/searchbar';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { CdsGovernmentAgencyGoodsItem } from 'store/declarations/uk/cds-declaration';
import IrelandProductsTable from 'views/declarations/ireland/common/IrelandProductsTable';
import EnsProductsTable from 'views/declarations/ireland/ens/cards/products/EnsProductsTable';
import UkProductsTable from 'views/declarations/uk/common/UkProductsTable';
import UkNctsProductsTable from 'views/declarations/uk/common/UkNctsProductsTable';
import useProductsTemplates from '../../../../hooks/useProductsTemplates';
import { handleAddDeclarationProductToProductTemplates } from '../../../custom-declaration/products/productTemplateUtils';
import { ListPayload } from 'core/http/response';
import { ProductContext } from '../../common/declaration-view/ProductContext';
import { getTableChangeParams, TableChangeParams } from '../../../../utils/tableHelpers';
import useDeclarationFormErrors from '../../../../hooks/useDeclarationFormErrors';
import { debounce } from 'lodash';
import useFormUtils from '../../../../hooks/useFormUtils';

interface NavigationProductsTable {
    comingBackFromProductView?: boolean;
}

export type ProductsErrors = { [index: number]: boolean };

const DeclarationProductsTable: FC<{}> = () => {
    const { onEdit, viewOnly } = useDeclarationProductsTable();
    const { declarationId } = useParams<{ declarationId: string }>();
    const { state } = useLocation();
    const comingBackFromProductView = (state as NavigationProductsTable)?.comingBackFromProductView;
    const { declaration } = useDeclarations();
    const { products, listDeclarationProducts, deleteDeclarationProduct } = useProducts();
    const { saveProductTemplate } = useProductsTemplates({});
    const navigate = useNavigate();
    const { setProductId } = useContext(ProductContext);
    const { countryLowerCase: country, formType, internalType } = useFormUtils();

    const { declarationErrors } = useDeclarationFormErrors();

    const errors = useMemo(() => {
        const de: ProductsErrors = {};

        declarationErrors.items.forEach(({ index }) => (de[index - 1] = true));

        return de;
    }, [declarationErrors.items]);

    useEffect(() => {
        setProductId?.(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDelete = useCallback(
        async (productId: string) => {
            if (!declarationId) return;

            await deleteDeclarationProduct({
                productId,
                declarationId,
                formType,
                country,
                internalType,
            });

            const response = await listDeclarationProducts({ declarationId, formType, internalType, country });

            if (response?.total === 0) {
                navigate(`/declarations/${declarationId}/products/new`, { replace: true });
            }
        },
        [country, declarationId, deleteDeclarationProduct, formType, internalType, listDeclarationProducts, navigate]
    );

    const getAddDeclarationProductToProductTemplate = useCallback(
        (productId: string) =>
            handleAddDeclarationProductToProductTemplates(
                productId,
                products,
                saveProductTemplate,
                declaration?.templateId,
                Boolean(declaration?.cdsDeclaration)
            ),
        [declaration, products, saveProductTemplate]
    );

    useEffect(() => {
        const checkItems = async (declarationId: string) => {
            const response = await listDeclarationProducts?.({ declarationId, formType, internalType, country });

            const size = response?.total ?? 0;
            const productId = response?.list?.[0]?.id;

            if (size === 0) {
                navigate(`/declarations/${declarationId}/products/new`, { replace: true });
            }
            if (size === 1 && productId) {
                navigate(
                    `/declarations/${declarationId}${
                        viewOnly ? `/view-only/products/${productId}` : `/products/${productId}`
                    }`,
                    {
                        replace: true,
                    }
                );
            }
        };

        if (declarationId && declaration?.id === declarationId && !comingBackFromProductView) {
            checkItems(declarationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, declaration?.id, declarationId, comingBackFromProductView, viewOnly]);

    const onChange = useCallback(
        ({ pagination, sorter }: TableChangeParams) => {
            if (!declarationId) return;
            listDeclarationProducts?.({
                declarationId,
                formType,
                internalType,
                country,
                params: getTableChangeParams({ sorter, pagination }),
            });
        },

        [declarationId, listDeclarationProducts, formType, internalType, country]
    );

    const debouncedSearch = debounce((query: string) => {
        declarationId &&
            listDeclarationProducts?.({ declarationId, formType, internalType, country, params: { query } });
    }, 700);

    const container = useMemo(() => {
        if (declaration) {
            switch (declaration.declarationExternalEntity) {
                case DeclarationExternalEntity.REVENUE: {
                    return (
                        <>
                            {declaration.declarationInternalType !== DeclarationInternalType.ENS && (
                                <IrelandProductsTable
                                    errors={errors}
                                    data={products}
                                    onDelete={handleDelete}
                                    onEdit={onEdit}
                                    onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                    viewOnly={viewOnly}
                                    onChange={onChange}
                                />
                            )}
                            {declaration.declarationInternalType === DeclarationInternalType.ENS && (
                                <EnsProductsTable
                                    errors={errors}
                                    data={products as ListPayload<EnsGoodsShipmentItem>}
                                    onDelete={handleDelete}
                                    viewOnly={viewOnly}
                                    onEdit={onEdit}
                                    onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                    onChange={onChange}
                                />
                            )}
                        </>
                    );
                }
                case DeclarationExternalEntity.CDS:
                    return (
                        <UkProductsTable
                            errors={errors}
                            data={products as ListPayload<CdsGovernmentAgencyGoodsItem>}
                            onDelete={handleDelete}
                            onEdit={onEdit}
                            onAddToTemplate={getAddDeclarationProductToProductTemplate}
                            viewOnly={viewOnly}
                            onChange={onChange}
                        />
                    );
                case DeclarationExternalEntity.HMRC:
                    return (
                        <UkNctsProductsTable
                            errors={errors}
                            data={products as ListPayload<any>}
                            onDelete={handleDelete}
                            onEdit={onEdit}
                            onAddToTemplate={getAddDeclarationProductToProductTemplate}
                            viewOnly={viewOnly}
                            onChange={onChange}
                        />
                    );
                default:
                    <></>;
            }
        }
        return <></>;
    }, [
        onChange,
        declaration,
        errors,
        handleDelete,
        onEdit,
        getAddDeclarationProductToProductTemplate,
        viewOnly,
        products,
    ]);

    return (
        <>
            <Row gutter={16} wrap={false} style={{ marginBottom: '2.5rem' }}>
                <Col flex="auto">
                    <SearchBar
                        onSearch={(value) => debouncedSearch(value)}
                        inputPlaceholder="Search by tag, commodity code and description of goods"
                    />
                </Col>
            </Row>

            {container}
        </>
    );
};
export default DeclarationProductsTable;

function useDeclarationProductsTable() {
    return useOutletContext<{
        onEdit: (id: string) => Promise<void>;
        loading?: boolean;
        viewOnly?: boolean;
    }>();
}
