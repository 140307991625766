import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import useTooltips from 'hooks/useTooltips';
import { FC, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getBox44FieldPath,
    h1Box44KeyNames,
    h1PathBox44,
    handleBox44Field,
} from 'views/declarations/common/box44/box-44-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import useSearchCustomer from 'views/declarations/common/search-customer/useSearchCustomer';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import FormEori from 'components/ui/composed/formEori/FormEori';
import ModalExchangeRating from 'views/declarations/common/ModalExchangeRating';
import { Exchange } from 'store/exchanges/exchange';
import useExchanges from 'hooks/useExchanges';
import useCodelists from '../../../../../../hooks/useCodelists';
import { validators } from 'views/declarations/uk/export/validations/validations';

interface ExchangeRatingModalState {
    open: boolean;
    currency: Exchange | undefined;
}

const ValueDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { viewOnly } = props;
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField, getH1TooltipsByRefNumber } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();
    const { aisCodelists } = useCodelists();

    const { exchanges } = useExchanges();
    const [exchangeRatingModal, setExchangeRatingModal] = useReducer(
        (state: ExchangeRatingModalState, newState: Partial<ExchangeRatingModalState>) => ({
            ...state,
            ...newState,
        }),
        { open: false, currency: undefined }
    );

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();

    return (
        <NewFormCard title={t('Value details')}>
            <FormCardContainer>
                <CurrencySelect
                    required
                    label="invoiceCurrency"
                    viewOnly={viewOnly}
                    {...getFormikProps(`invoiceCurrency`, props)}
                    onChange={(value) => {
                        const currency = exchanges.find((exchange) => exchange.currency.code === value?.target?.value);
                        if (currency) {
                            setExchangeRatingModal({ open: true, currency });
                        }
                    }}
                    refNumber="4.10"
                    tooltip={getH1TooltipsByRefNumber('4.10')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <ModalExchangeRating
                    isOpen={exchangeRatingModal.open}
                    close={() => {
                        setExchangeRatingModal({ open: false });
                    }}
                    onConfirm={(exchange: Exchange) => {
                        props.getFieldHelpers('exchangeRate').setValue(exchange.rate);
                    }}
                    currency={exchangeRatingModal.currency}
                />

                <DeclarationNumberInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`invoiceAmount`, props)}
                    refNumber="4.11"
                    label={t('invoiceAmount')}
                    refTooltip={getH1TooltipsByRefNumberAndField('4.11', t('invoiceAmount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationNumberInput
                    refNumber="4.15"
                    viewOnly={viewOnly}
                    {...getFormikProps(`exchangeRate`, props)}
                    label={t('exchangeRate')}
                    refTooltip={getH1TooltipsByRefNumber('4.15')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormSelect
                    required
                    viewOnly={viewOnly}
                    {...getFormikProps(`preferredPaymentMethod`, props)}
                    refNumber="4.8"
                    label={t('preferredPaymentMethod')}
                    selectOptions={aisCodelists?.taxPaymentMethod}
                    tooltip={getH1TooltipsByRefNumber('4.8')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.maxLength(1)]}
                />

                <FormEori
                    required
                    maxLength={17}
                    viewOnly={viewOnly}
                    {...getFormikProps(`personPayingCustomsDuty`, props)}
                    refNumber="3.46"
                    label={t('personPayingCustomsDuty')}
                    tooltip={getH1TooltipsByRefNumber('3.46')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    onSearchCustomer={({ fieldHelpers }) => {
                        triggerShowSearchCustomerDrawer((customer) => {
                            fieldHelpers?.setValue(customer.eori);
                        });
                    }}
                />

                <FormInput
                    numbersAndLetters
                    maxLength={255}
                    viewOnly={viewOnly}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N934' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: 'N934',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N934"
                    label={t('dv1Declaration')}
                    tooltip={getH1TooltipsByRefNumber('N934')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    numbersAndLetters
                    maxLength={255}
                    viewOnly={viewOnly}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: '1A01' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: '1A01',
                            },
                            { template, form }
                        )
                    }
                    refNumber="1A01"
                    label={t('vatFreeAuthorisation')}
                    tooltip={getH1TooltipsByRefNumber('1A01')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <SearchCustomer.Drawer
                    onClose={triggerHideSearchCustomerDrawer}
                    show={showSearchCustomerDrawer}
                    onSelectCustomer={handleSelectCustomer}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default ValueDetailsCard;
