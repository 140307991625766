import useModal from 'antd/lib/modal/useModal';
import useDeclarations from 'hooks/useDeclarations';
import useGetDeclarationMapValues from 'hooks/useGetDeclarationMapValues';
import useProducts from 'hooks/useProducts';
import { get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDeclarationPayload } from 'views/declarations/utils/declaration-utils';
import GvmsPreSubmissionFormCheckModal from '../../../uk/gvms/components/pre-submission-modal/GvmsPreSubmissionFormCheckModal';
import ModalPreviewSadAndClearanceSlip from '../../ModalPreviewSadAndClearanceSlip';
import SubmitModal from '../../submit-modal/SubmitModal';
import WarningSubmitModal from '../../WarningSubmitModal';
import {
    missingGrossMassFromAllItems,
    missingGrossMassFromMaster,
    missingGrossMassFromSomeItems,
} from '../utils/grossMassUtils';

interface Props {
    customerId: string | undefined;
    isGvms: boolean;
    hasRightCertificate: boolean | undefined;
    submitting: boolean | undefined;
    submissionModalsActions: { validateForm: () => Promise<boolean>; onCancel: () => void };
}

const SubmissionModalsManager = ({
    customerId,
    isGvms,
    hasRightCertificate,
    submitting,
    submissionModalsActions,
}: Props) => {
    const { declaration } = useDeclarations();
    const { products } = useProducts();
    const { ...mapper } = useGetDeclarationMapValues();

    const [submissionModalVisible, setSubmissionModalVisible] = useState<boolean>(false);
    const [preSubmissionModalVisible, setPreSubmissionModalVisible] = useState<boolean>(false);

    const [applySuggestedGrossMass, setApplySuggestedGrossMass] = useState<boolean>(false);
    useEffect(() => {
        const masterGrossMass = mapper.grossMassPath && get(getDeclarationPayload(declaration), mapper.grossMassPath);
        const hasGrossMass = masterGrossMass ? Number(masterGrossMass) > 0 : false;
        setApplySuggestedGrossMass(hasGrossMass);
    }, [mapper.grossMassPath, declaration]);

    const [modal, modalContext] = useModal();

    const handleGrossMass = useCallback(() => {
        return new Promise<boolean>((resolve) => {
            if (!mapper.grossMassPath && !mapper.itemGrossMassPath) return resolve(true);

            const grossMassMasterMissing = missingGrossMassFromMaster(
                getDeclarationPayload(declaration),
                mapper.grossMassPath
            );
            const grossMassAllItemsMissing = missingGrossMassFromAllItems(products?.list, mapper.itemGrossMassPath);
            const grossMassSomeItemsMissing = missingGrossMassFromSomeItems(products?.list, mapper.itemGrossMassPath);

            const case1 = !grossMassMasterMissing && grossMassAllItemsMissing;
            const case2 = grossMassMasterMissing && !grossMassAllItemsMissing;

            const allFilled = !grossMassMasterMissing && !grossMassSomeItemsMissing;

            if (!case1 && !case2 && !allFilled) {
                const missingGrossMassItems = mapper.itemGrossMassPath
                    ? (products?.list as any[] | undefined)
                          ?.filter((product: any) => !get(product, mapper.itemGrossMassPath!))
                          .map((_, i) => `Item: ${i + 1}`)
                    : null;

                const missing = [
                    ...(missingGrossMassItems ? missingGrossMassItems : []),
                    ...(grossMassMasterMissing ? ['Master'] : []),
                ];

                modal.confirm({
                    title: 'Gross mass is partially filled',
                    style: { minWidth: 660 },
                    closable: false,
                    content: (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <span>
                                Missing in: <b data-testId="gross-mass-missing-in">{missing.join(', ')}</b>
                            </span>
                            <span>You can go back and revisit or continue with partially filled gross mass.</span>
                        </div>
                    ),
                    cancelText: 'Go back',
                    cancelButtonProps: { 'data-testid': 'go-back-button' } as any,
                    onCancel: () => {
                        return resolve(false);
                    },
                    okText: 'Continue',
                    okButtonProps: { 'data-testid': 'continue-button' } as any,
                    onOk: () => {
                        return resolve(true);
                    },
                });
            } else {
                return resolve(true);
            }
        });
    }, [declaration, mapper.grossMassPath, mapper.itemGrossMassPath, modal, products?.list]);

    const preSubmissionModal = useMemo(() => {
        const defaultProps = {
            visible: preSubmissionModalVisible,
            onClose: () => {
                submissionModalsActions?.onCancel();
                setPreSubmissionModalVisible(false);
            },
        };

        const modal = !hasRightCertificate ? (
            <WarningSubmitModal {...defaultProps} />
        ) : isGvms ? (
            <GvmsPreSubmissionFormCheckModal {...defaultProps} onConfirm={submissionModalsActions.validateForm} />
        ) : (
            <ModalPreviewSadAndClearanceSlip
                {...defaultProps}
                onConfirm={async () => {
                    const noErrors = await submissionModalsActions.validateForm();
                    if (!noErrors) return;

                    const continueGrossMass = await handleGrossMass();

                    if (continueGrossMass) {
                        setPreSubmissionModalVisible(false);
                        setSubmissionModalVisible(true);
                    } else {
                        setPreSubmissionModalVisible(false);
                    }
                }}
                onToggle={(toggled) => {
                    setApplySuggestedGrossMass(toggled);
                }}
                toggled={applySuggestedGrossMass}
            />
        );

        return modal;
    }, [
        preSubmissionModalVisible,
        hasRightCertificate,
        isGvms,
        submissionModalsActions,
        applySuggestedGrossMass,
        handleGrossMass,
    ]);

    useEffect(() => {
        if (submitting) {
            setPreSubmissionModalVisible(true);
        }
    }, [submitting, declaration]);

    return (
        <>
            {preSubmissionModal}
            {modalContext}
            <SubmitModal
                visible={submissionModalVisible}
                handleCancel={() => {
                    submissionModalsActions.onCancel();
                    setSubmissionModalVisible(false);
                }}
                applySuggestedGrossMass={applySuggestedGrossMass}
                customerId={customerId}
            />
        </>
    );
};

export default SubmissionModalsManager;
