import { DeleteOutlined } from '@ant-design/icons';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import { ConfigProvider } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import Button from 'components/ui/base/button/Button';
import { CommandButton } from 'components/ui/composed/dashboard/DashboardTable';
import Table from 'components/ui/base/table/Table';
import { Text } from 'components/ui/base/typography';
import ListTags from 'components/ui/composed/tag/ListTags';
import { ListPayload } from 'core/http/response';
import { FC, useCallback, useMemo, Dispatch } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProductTemplate } from 'store/products-templates/products';
import { EmptyRenderCol, EmptyRenderIcon, EmptyRenderRow } from '../Products.styles';
import { FlexDiv, SpanEllipsis } from '../../declaration-table/components/DeclarationsTable.styled';
import { TemplateResponse } from 'store/template/template';
import { capitalize, isEmpty } from 'lodash';
import useFormUtils from '../../../../hooks/useFormUtils';
import { AddProductTemplatesToDeclaration } from '../../../declarations/common/DeclarationsProductsTemplates';
import useProductsTemplates from 'hooks/useProductsTemplates';
import { DeleteProductTemplates } from '../ProductTemplatesDashboard';
import { getTableChangeParams } from '../../../../utils/tableHelpers';

const customizeRenderEmpty = () => (
    <EmptyRenderRow>
        <EmptyRenderCol>
            <EmptyRenderIcon />
            <Text>You don't have any products yet.</Text>
        </EmptyRenderCol>
    </EmptyRenderRow>
);

export type Product<TExtends = unknown> = ProductTemplate<TExtends>;

interface Props {
    source: ListPayload<Product>;
    showCommands?: boolean;
    deleteProductTemplatesIds?: string[];
    setDeleteProductTemplates?: Dispatch<Partial<DeleteProductTemplates>>;
    declarationTemplatesList?: TemplateResponse[] | null;
    onAddProductTemplatesToDeclaration?: Dispatch<Partial<AddProductTemplatesToDeclaration>>;
}

const ProductTemplatesTable: FC<Props> = ({
    source,
    showCommands,
    deleteProductTemplatesIds,
    setDeleteProductTemplates,
    declarationTemplatesList,
    onAddProductTemplatesToDeclaration,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { listProductTemplates, saveProductTemplate, isLoading } = useProductsTemplates({});
    const { formType, internalType, countryLowerCase } = useFormUtils();

    const rowSelection: TableRowSelection<any> = {
        type: 'checkbox',
        onChange: (selectedRowKeys: any, selectedRows: Product[]) => {
            const selectedProductTemplateIds = selectedRows.reduce((acc: string[], row) => {
                if (typeof row?.id === 'string') acc.push(row.id);
                return acc;
            }, []);

            onAddProductTemplatesToDeclaration?.({ productTemplatesIds: selectedProductTemplateIds });
            setDeleteProductTemplates?.({ productTemplateIds: selectedProductTemplateIds });
        },
    };

    const handleDuplicate = useCallback(
        async ({ id, ...rest }: Product) => saveProductTemplate(rest)?.then(() => listProductTemplates()),
        [saveProductTemplate, listProductTemplates]
    );

    const handleTags = (tags: string[]) => {
        if (tags?.length) {
            return <ListTags tags={tags} vertical />;
        } else {
            return <span>-</span>;
        }
    };

    const getProductType = () => {
        return (
            <FlexDiv>
                <SpanEllipsis>{capitalize(countryLowerCase)}</SpanEllipsis>
                <SpanEllipsis>{`${capitalize(internalType)} (${formType ?? '-'})`}</SpanEllipsis>
            </FlexDiv>
        );
    };

    const getCommodityCode = (product: Product<any>) => {
        const irelandH1CommodityCode = product?.goodsInformation?.combinedNomenclatureCode;
        const irelandH7CommodityCode = product?.commodityCodeHarmonizedSystemSubHeadingCode;
        const ukH1CommodityCode = product?.commodityCombinedNomenclatureTypeCode;
        const ukB1CommodityCode = product?.commodity?.classification?.[0]?.id;
        const ensCommodityCode = product?.commodity?.combinedNomenclature;
        const aesCommodityCode = product?.commodity?.commodityCode?.harmonizedSystemSubHeadingCode;

        const commodityCode =
            irelandH1CommodityCode ??
            irelandH7CommodityCode ??
            ukH1CommodityCode ??
            ukB1CommodityCode ??
            ensCommodityCode ??
            aesCommodityCode ??
            '-';

        return <SpanEllipsis>{commodityCode}</SpanEllipsis>;
    };

    const getDescriptionOfGoods = (product: Product<any>) => {
        const irelandH1DescriptionOfGoods = product?.goodsInformation?.goodsDescription;
        const irelandH7DescriptionOfGoods = product?.descriptionOfGoods;
        const ukH1DescriptionOfGoods = product?.goodsDescription;
        const ukB1EnsDescriptionOfGoods = product?.commodity?.description;
        const aesDescriptionOfGoods = product?.commodity?.descriptionOfGoods;

        const descriptionOfGoods =
            irelandH1DescriptionOfGoods ??
            irelandH7DescriptionOfGoods ??
            ukH1DescriptionOfGoods ??
            ukB1EnsDescriptionOfGoods ??
            aesDescriptionOfGoods ??
            '-';

        return <SpanEllipsis>{descriptionOfGoods}</SpanEllipsis>;
    };

    const getItemPrice = (product: Product<any>) => {
        const irelandH1ItemPrice = product?.itemAmount;
        const irelandH7ItemPrice = product?.itemAmountInvoicedIntrinsicValue?.valueAmount;
        const ukH1ItemPrice = product?.itemPriceAmount;
        const ukB1ItemPrice = product?.statisticalValueAmount;
        const aesItemPrice = product?.statisticalValue;

        const itemPrice =
            irelandH1ItemPrice ?? irelandH7ItemPrice ?? ukH1ItemPrice ?? ukB1ItemPrice ?? aesItemPrice ?? '-';

        return <SpanEllipsis>{itemPrice}</SpanEllipsis>;
    };

    const declarationTemplateNames = useMemo(
        () =>
            declarationTemplatesList?.reduce((acc, template) => {
                acc.set(template.id, template.templateName);
                return acc;
            }, new Map()),
        [declarationTemplatesList]
    );

    const getDeclarationTemplate = (product: Product) => {
        const declarationTemplateName = declarationTemplateNames?.get(product?.declarationTemplateId);
        return <SpanEllipsis>{declarationTemplateName ?? 'DEFAULT'}</SpanEllipsis>;
    };

    const columns = [
        {
            title: 'Product Type',
            dataIndex: 'product_type',
            key: 'product_type',
            render: () => getProductType(),
            sorter: true,
        },
        {
            title: 'Declaration Template',
            dataIndex: 'declaration_template',
            key: 'declaration_template',
            render: (text: string, record: Product) => record && getDeclarationTemplate(record),
            sorter: true,
        },
        {
            title: 'Commodity Code',
            dataIndex: 'commodity_code',
            key: 'commodity_code',
            render: (text: string, record: Product) => record && getCommodityCode(record),
            sorter: true,
        },
        {
            title: 'Description of Goods',
            dataIndex: 'description_of_goods',
            key: 'description_of_goods',
            render: (text: string, record: Product) => record && getDescriptionOfGoods(record),
            sorter: true,
        },
        {
            title: 'Item Price',
            dataIndex: 'item_price',
            key: 'item_price',
            render: (text: string, record: Product) => record && getItemPrice(record),
            sorter: true,
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            render: (text: string, record: Product) => handleTags(record.tags ?? []),
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (text: string, record: Product) => (
                <>
                    <CommandButton
                        style={{ marginRight: '1.6rem' }}
                        tooltip="Copy"
                        icon={<CopyOutlined />}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDuplicate?.(record);
                        }}
                    />
                    <CommandButton
                        tooltip="Delete"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!record?.id) return;
                            setDeleteProductTemplates?.({
                                modalVisible: true,
                                productTemplateIds: [record.id],
                            });
                        }}
                    />
                </>
            ),
        },
    ];

    const getColumns = () => (showCommands ? columns : columns.filter((c) => c.title !== 'Commands'));

    return (
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <div className="config-provider">
                <Table
                    rowKey={(record: Product) => source?.list.findIndex((r) => record === r)}
                    columns={getColumns()}
                    dataSource={source.list}
                    rowSelection={rowSelection}
                    onChange={(pagination, _, sorter) =>
                        listProductTemplates(getTableChangeParams({ pagination, sorter }))
                    }
                    loading={isLoading}
                    onRow={(record: any) => {
                        return {
                            onClick: () => {
                                if (location.pathname.includes(`/declarations`)) {
                                    onAddProductTemplatesToDeclaration?.({
                                        productTemplatesIds: [record.id],
                                        modalVisible: true,
                                    });
                                } else {
                                    navigate(`${record.id}`);
                                }
                            },
                        };
                    }}
                    pagination={{
                        current: source?.pageNumber + 1,
                        total: source?.total,
                        showSizeChanger: false,
                        pageSize: source?.pageSize,
                        position: ['bottomCenter'],
                    }}
                />
                {!isEmpty(deleteProductTemplatesIds) && (
                    <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={() => setDeleteProductTemplates?.({ modalVisible: true })}
                    >
                        Delete
                    </Button>
                )}
            </div>
        </ConfigProvider>
    );
};
export default ProductTemplatesTable;
