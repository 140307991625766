import useTaricCodes from 'hooks/useTaricCodes';
import { FC } from 'react';
import { TaricCodeResponse } from 'store/taric-codes/taric-code';
import { TaricDetailsType } from '../../TaricCodesCard';
import BackButton from './BackButton';
import TaricMeasurement from './TaricMeasurement';
interface Props {
    detailsType: TaricDetailsType;
    setSelectedCode: Function;
    selectedCommodityCode: (code: string, duty?: string, vat?: string) => void;
    selectedCode: Pick<TaricCodeResponse, 'code' | 'description'>;
}
export const ListTaricMeasurement: FC<Props> = ({
    detailsType,
    setSelectedCode,
    selectedCommodityCode,
    selectedCode,
}) => {
    const { importTaricMeasurement, exportTaricMeasurement, generalTaricMeasurement } = useTaricCodes();
    return (
        <>
            <BackButton
                onClick={() => {
                    setSelectedCode(undefined);
                }}
            />
            <TaricMeasurement
                importTaricMeasurement={importTaricMeasurement}
                exportTaricMeasurement={exportTaricMeasurement}
                generalTaricMeasurement={generalTaricMeasurement}
                selectedCode={selectedCode}
                detailsType={detailsType}
                selectedCommodityCode={selectedCommodityCode}
            />
        </>
    );
};
export default ListTaricMeasurement;
