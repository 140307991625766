import { Select } from 'antd';
import { useMemo, useState } from 'react';
import { Individual } from 'store/individuals/individual';

type Props = {
    authors: Individual[] | undefined;
    onSelect?: (authors: Individual[]) => void;
    value?: Individual[];
    cardRef?: React.MutableRefObject<HTMLDivElement | undefined>;
};

const AuthorFilter = (props: Props) => {
    const [search, setSearch] = useState<string>('');

    const options = useMemo(
        () =>
            props.authors
                ?.map((author) => ({ label: `${author.fullName} (${author.email})`, value: author.id }))
                .filter((author) => {
                    if (!search) return true;
                    return author.label?.toLowerCase().includes(search?.toLowerCase());
                }),
        [props.authors, search]
    );

    const handleChange = (value: string[]) => {
        props.onSelect?.(props.authors?.filter((author) => value.includes(author.id)) ?? []);
    };

    return (
        <div style={{ padding: '1rem' }}>
            <Select
                options={options}
                getPopupContainer={(triggerNode) => props.cardRef?.current || triggerNode.parentNode}
                mode="multiple"
                showSearch
                autoFocus
                filterOption={false}
                onSearch={(value) => setSearch(value)}
                allowClear
                onChange={handleChange}
                style={{ width: '100%' }}
                value={props.value?.map((author) => author.id)}
            />
        </div>
    );
};

export default AuthorFilter;
