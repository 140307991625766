import { Drawer } from 'antd';
import { useState } from 'react';
import SearchCustomer from './SearchCustomer';
import { Customer } from '../../../../store/customers/customer';
import SearchCustomerButton from '../../../../views/declarations/common/search-customer/SearchCustomerButton';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface SearchCustomerDrawerProps {
    onSelectCustomer: (customer: Customer, onClose: () => void) => void;
    size?: SizeType;
}

const SearchCustomerDrawerButton = ({ onSelectCustomer, size = 'middle' }: SearchCustomerDrawerProps) => {
    const [showCustomersDrawer, setShowCustomersDrawer] = useState<boolean>(false);

    return (
        <>
            <SearchCustomerButton size={size} condensed onClick={() => setShowCustomersDrawer(true)} />
            <SearchCustomerDrawer
                visible={showCustomersDrawer}
                onClose={() => setShowCustomersDrawer(false)}
                onSelectCustomer={onSelectCustomer}
            />
        </>
    );
};

const SearchCustomerDrawer = ({
    visible,
    onClose,
    onSelectCustomer,
}: {
    visible: boolean;
    onClose: () => void;
} & SearchCustomerDrawerProps) => {
    return (
        <Drawer title="Search for customer" width="1098" visible={visible} onClose={onClose}>
            <SearchCustomer handleSelect={(customer: Customer) => onSelectCustomer(customer, onClose)} />
        </Drawer>
    );
};

export default SearchCustomerDrawerButton;
