import { FormikProps, FormikProvider } from 'formik';
import { FC } from 'react';
import useCodelists from '../../../../hooks/useCodelists';
import NewFormCard, { FormCardContainer } from '../../common/cards/NewFormCard';
import { validators } from '../../uk/export/validations/validations';
import { getFormikProps, getFullCodelistValue } from '../../utils/form-utils';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import CountrySelect from '../../../../components/ui/composed/declarations/CountrySelect';
import MultipleDeclarationField from '../../common/MultipleDeclarationField';
import PartiesCard from '../../common/parties/PartiesCard';
import MultipleItemsCard from '../../common/MultipleItemsCard';
import addPathPrefix from '../../../../utils/addPathPrefix';
import useProducts from '../../../../hooks/useProducts';
import { useOutletContext } from 'react-router-dom';
import useNumberOfItemsModal from '../../common/declaration-view/utils/useNumberOfItemsModal';
import DeclarationNumberInput from '../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';

interface Props {
    formik: FormikProps<any>;
}

const IrelandEtdForm: FC<Props> = ({ formik }) => {
    const { aisCodelists } = useCodelists();
    const { createIrelandEtdProduct, listIrelandEtdProducts } = useProducts();

    const { saveAsDraft } =
        useOutletContext<{
            saveAsDraft: (withNotification: boolean, data?: unknown) => Promise<unknown>;
        }>() ?? {};

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createIrelandEtdProduct,
        listProducts: listIrelandEtdProducts,
        saveAsDraft,
    });

    return (
        <FormikProvider value={formik}>
            <NewFormCard title="Customs Offices">
                <FormCardContainer>
                    <DeclarationInput
                        required
                        label="Transit declaration/Proof of customs status type"
                        refNumber="1.3"
                        {...getFormikProps('declaration.transitDeclaration13', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        required
                        label="Customs office of lodgement"
                        {...getFormikProps(`declaration.customsOffices.customsOfficeLodgement`, formik)}
                        condensed
                        selectOptions={aisCodelists?.nationalCustomsOffice}
                        codeValidation={[validators.exact(8)]}
                    />
                    <DeclarationInput
                        label="Customs office of destination"
                        refNumber="5.6"
                        {...getFormikProps('declaration.customsOffices.customsOfficeOfDestination56', formik)}
                        condensed
                    />
                    <DeclarationNumberInput
                        label="Number of Items"
                        {...getFormikProps(`numberOfItems`, formik)}
                        fieldEvents={{
                            onBlur(value) {
                                handleNumberOfItems(Number(value));
                            },
                        }}
                        required
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Goods Information">
                <FormCardContainer>
                    <DeclarationInput
                        label="Gross mass"
                        refNumber="6.5"
                        {...getFormikProps('declaration.goodsInformation.grossMass65', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Total packages"
                        refNumber="6.18"
                        {...getFormikProps('declaration.goodsInformation.totalPackageNumber618', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'declaration.parties.holderOfTheTransitProcedure',
                        header: 'Holder of the transit procedure',
                        refNumber: '3.22',
                        eoriRequired: true,
                    },
                    {
                        path: 'declaration.parties.representative',
                        header: 'Representative',
                        refNumber: '3.19',
                        hasRepresentativeStatus: true,
                    },
                    {
                        path: 'goodsShipment.parties.consignee',
                        header: 'Consignee',
                        refNumber: '3.9',
                    },
                ]}
                paths={{
                    eoriByPath: (path) => {
                        if (!path) return '';
                        if (path.includes('holder')) {
                            return 'holderOfTheTransitProcedure323';
                        } else if (path.includes('representative')) {
                            return 'representative320';
                        } else if (path.includes('consignee')) {
                            return 'consignee310';
                        }
                        return '';
                    },
                    address: {
                        city: 'address.city',
                        country: 'address.country',
                        name: 'name',
                        postCode: 'address.postcode',
                        streetAndNumber: 'address.streetAndNumber',
                    },
                    additional: {
                        representativeStatus: 'representativeStatus321',
                    },
                }}
                condensed
            />

            <MultipleItemsCard
                title="Simplified Declaration / Previous Documents / Writing-off"
                path="goodsShipment.documentsAuthorisations.simplifiedDeclarationDocumentWritingOff201"
                refNumber="2.01"
                initialValues={{
                    previousDocumentType: '',
                    previousDocumentIdentifier: '',
                    previousDocumentLineId: '',
                }}
                list={(list) => [
                    {
                        field: 'Previous Document Type',
                        value: getFullCodelistValue(list.previousDocumentType, aisCodelists?.previousDocumentType),
                    },
                    { field: 'Previous Document Reference', value: list.previousDocumentIdentifier },
                    { field: 'Declaration Line Number Previously Recorded', value: list.previousDocumentLineId },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Previous Document Type"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentType'), formik)}
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aisCodelists?.previousDocumentType}
                        />
                        <DeclarationInput
                            label="Previous Document Reference"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentIdentifier'), formik)}
                            condensed
                        />
                        <DeclarationInput
                            label="Declaration Line Number Previously Recorded"
                            {...getFormikProps(addPathPrefix(path, 'previousDocumentLineId'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Information"
                path="goodsShipment.documentsAuthorisations.additionalInformation22"
                refNumber="2.2"
                initialValues={{
                    additionalInformationCode: '',
                    additionalInformationText: '',
                }}
                list={(list) => [
                    {
                        field: 'Additional Statement Code',
                        value: getFullCodelistValue(
                            list.additionalInformationCode,
                            aisCodelists?.additionalInformationCode
                        ),
                    },
                    { field: 'Additional Statement Text', value: list.additionalInformationText },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Additional Statement Code"
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationCode'), formik)}
                            codeValidation={[validators.exact(5)]}
                            condensed
                            selectOptions={aisCodelists?.additionalInformationCode}
                        />
                        <DeclarationInput
                            label="Additional Statement Text"
                            {...getFormikProps(addPathPrefix(path, 'additionalInformationText'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Documents Produced / Certificates and Authorisations / Additional References"
                path="goodsShipment.documentsAuthorisations.producedDocumentsWritingOff203"
                refNumber="2.03"
                initialValues={{
                    documentType: '',
                    documentIdentifier: '',
                }}
                list={(list) => [
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.documentType, aisCodelists?.commonDocumentsType),
                    },
                    { field: 'Id', value: list.documentIdentifier },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'documentType'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aisCodelists?.commonDocumentsType}
                        />
                        <DeclarationInput
                            label="Id"
                            {...getFormikProps(addPathPrefix(path, 'documentIdentifier'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Reference Number">
                <FormCardContainer>
                    <DeclarationInput
                        label="UCR"
                        refNumber="2.4"
                        {...getFormikProps('goodsShipment.documentsAuthorisations.ucr24', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Supply Chain Actor"
                path="goodsShipment.parties.supplyChainActor337"
                refNumber="3.37"
                initialValues={{
                    roleCode: '',
                    traderIdentification: '',
                }}
                list={(list) => [
                    {
                        field: 'Role Code',
                        value: getFullCodelistValue(list.roleCode, aisCodelists?.supplyChainActorRoleCode),
                    },
                    { field: 'Identifier', value: list.traderIdentification },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Role Code"
                            {...getFormikProps(addPathPrefix(path, 'roleCode'), formik)}
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aisCodelists?.supplyChainActorRoleCode}
                        />
                        <DeclarationInput
                            label="Identifier"
                            {...getFormikProps(addPathPrefix(path, 'traderIdentification'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Dates and Places">
                <FormCardContainer>
                    <CountrySelect
                        label="Country of destination code"
                        {...getFormikProps(`goodsShipment.datesPlaces.countryDestination58`, formik)}
                        condensed
                        refNumber="5.8"
                    />
                    <DeclarationInput
                        label="UN/LOCODE"
                        {...getFormikProps('goodsShipment.datesPlaces.transportationPlace.unlocode', formik)}
                        condensed
                    />
                    <CountrySelect
                        label="Country code"
                        {...getFormikProps(`goodsShipment.datesPlaces.transportationPlace.countryCode`, formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Location"
                        {...getFormikProps('goodsShipment.datesPlaces.transportationPlace.place', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Transport Information">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Type of identification"
                        {...getFormikProps(
                            `goodsShipment.transportInformation.departureTransportMeans77.identificationType`,
                            formik
                        )}
                        condensed
                        selectOptions={aisCodelists?.meansIdentityType}
                        codeValidation={[validators.exact(2)]}
                    />
                    <DeclarationInput
                        label="Identification number"
                        {...getFormikProps(
                            'goodsShipment.transportInformation.departureTransportMeans77.identificationNumber',
                            formik
                        )}
                        condensed
                    />
                    <DeclarationInput
                        label="Nationality"
                        refNumber="7.8"
                        {...getFormikProps('goodsShipment.transportInformation.nationality78', formik)}
                        condensed
                    />
                    <MultipleDeclarationField
                        parent="goodsShipment.transportInformation.containerIdentificationNumber710"
                        name="containerIdentificationNumber"
                    >
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                refNumber="7.10"
                                label="Container Identification Number"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>

                    <DeclarationInput
                        refNumber="7.18"
                        label="Number of Seals"
                        {...getFormikProps('goodsShipment.transportInformation.seal.sealNumber718', formik)}
                        condensed
                    />

                    <MultipleDeclarationField
                        parent="goodsShipment.transportInformation.seal.sealIdentifier718"
                        name="sealIdentifier"
                    >
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                refNumber="7.18"
                                label="Seal Identifier"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>
            {modalContextHolder}
        </FormikProvider>
    );
};

export default IrelandEtdForm;
