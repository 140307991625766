import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { businessRulesActions } from './actionType';

import { BusinessRule } from './business-rule';

export interface BusinessRulesState {
    isLoading: boolean;
    error?: ErrorResponse;
    aisRules: BusinessRule[] | undefined;
    aesRules: BusinessRule[] | undefined;
}

export const businessRulesInitialState: Readonly<BusinessRulesState> = {
    isLoading: false,
    aisRules: undefined,
    aesRules: undefined,
};

const businessRules: Reducer<BusinessRulesState, businessRulesActions> = (
    state = businessRulesInitialState,
    action
): BusinessRulesState => {
    switch (action.type) {
        case 'LIST_AIS_BUSINESS_RULES_REQUEST':
        case 'LIST_AES_BUSINESS_RULES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_AIS_BUSINESS_RULES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                aisRules: action.payload.list,
            };
        case 'LIST_AES_BUSINESS_RULES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                aesRules: action.payload.list,
            };
        case 'LIST_AES_BUSINESS_RULES_ERROR':
        case 'LIST_AIS_BUSINESS_RULES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default businessRules;
