import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { TextSmall } from 'components/ui/base/typography';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import {
    ErrorDiv,
    ErrorLabel,
    FlexDiv,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    ViewOnlyLabel,
} from '../formInput/DeclarationInput.styles';
import { StyledCheckbox } from './DeclarationCheckbox.styles';
import { CheckboxProps } from 'antd';
import { TemplateCheckboxes } from '../../template/TemplateCheckbox';
import { useTemplateContext } from '../../template/TemplateContext';
import useFieldTemplateMetaData from '../../template/useTemplateViewEditControls';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useRegisterField } from '../../template/CardContext';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import useDeclarations from 'hooks/useDeclarations';
import { kebabCase } from 'lodash';

export interface Props extends CheckboxProps {
    label?: string;
    tooltip?: string;
    icon?: ReactNode;
    refNumber?: string;
    children?: ReactNode;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    disabled?: boolean;
    required?: boolean;
    viewOnly?: boolean;
    condensed?: boolean;
    hidden?: boolean;
}

export interface IconTooltipProps {
    tooltip?: string;
    icon?: ReactNode;
}

const DeclarationCheckbox: FC<Props> = ({
    label,
    refNumber,
    tooltip,
    fieldMeta: fieldMetaProp,
    fieldProps: fieldPropsProp,
    fieldHelper: fieldHelpersProp,
    viewOnly: viewOnlyProp,
    required,
    condensed,
    hidden,
    ...checkboxProps
}) => {
    const { t } = useTranslation('common');
    const { templateFormik, form, template, isViewOnly: isTemplateViewOnly } = useTemplateContext();
    const { isViewable, isInvisible } = useFieldTemplateMetaData(fieldPropsProp?.name ?? checkboxProps.name);
    const { declaration } = useDeclarations();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');

    const { fieldProps, fieldMeta } = useMemo(() => {
        if (template && templateFormik && form) {
            const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
            return { ...f, fieldHelpers: f.fieldHelper };
        }

        return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
    }, [template, templateFormik, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

    const viewOnly = useMemo(() => {
        if (template) return isTemplateViewOnly;
        if (outletContext?.amendment) return false;
        return viewOnlyProp || isViewable || inViewOnly || declaration?.archived;
    }, [
        template,
        isTemplateViewOnly,
        outletContext?.amendment,
        viewOnlyProp,
        isViewable,
        inViewOnly,
        declaration?.archived,
    ]);

    useRegisterField({ path: fieldPropsProp?.name ?? checkboxProps.name, required, hidden });

    const handleTemplateCheckboxChange = useMemo(() => {
        return checkboxProps.onChange
            ? (value: any) =>
                  checkboxProps.onChange!({
                      target: { value, name: checkboxProps.name ?? '' },
                  } as any)
            : undefined;
    }, [checkboxProps.name, checkboxProps.onChange]);

    const render = useMemo(
        () => (
            <>
                {viewOnly ? (
                    <>
                        <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                        {template && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                        <TextSmall data-testid={kebabCase(label || fieldPropsProp?.name)}>
                            {fieldProps.value ? 'Yes' : 'No'}
                        </TextSmall>
                    </>
                ) : (
                    <>
                        <FlexDiv>
                            <LabelDiv condensed={condensed}>
                                {label && <InputLabel>{label}</InputLabel>}
                                {required && <InputLabelRequired>*</InputLabelRequired>}
                                {refNumber && <RefDiv>{refNumber}</RefDiv>}
                                {tooltip && tooltip.length > 0 && (
                                    <IconTooltip
                                        title={label}
                                        tooltip={tooltip ?? t('defaultTooltip')}
                                        icon={<StyledInfoCircleFilled />}
                                    />
                                )}
                            </LabelDiv>
                        </FlexDiv>
                        {template && (
                            <TemplateCheckboxes
                                disabled={required}
                                fieldPath={fieldPropsProp?.name ?? checkboxProps.name}
                                onChange={handleTemplateCheckboxChange}
                            />
                        )}
                        <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                            <InputDiv>
                                <StyledCheckbox
                                    disabled={isInvisible || viewOnly}
                                    checked={checkboxProps.checked ?? checkboxProps.value ?? fieldProps.value}
                                    data-testid={kebabCase(label ?? fieldPropsProp?.name)}
                                    {...fieldProps}
                                    {...checkboxProps}
                                />
                            </InputDiv>
                            <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                                {!!fieldMeta?.error && !!fieldMeta?.touched && (
                                    <ErrorLabel>{fieldMeta?.error}</ErrorLabel>
                                )}
                            </ErrorDiv>
                        </FormItem>
                    </>
                )}
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldMeta?.error, fieldMeta?.touched, fieldMeta?.value, isInvisible, viewOnly]
    );

    if (hidden || (isInvisible && !template)) return null;

    return render;
};

export default DeclarationCheckbox;
