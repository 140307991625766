import { FormModel, validators } from '../../../uk/export/validations/validations';

export const irelandTsdValidationItem = {
    childValidators: {
        documentsAuthorisations: {
            childValidators: {
                simplifiedDeclarationDocumentWritingOff201: {
                    childValidators: {
                        previousDocumentType: [validators.maxLength(3)],
                        previousDocumentIdentifier: [validators.maxLength(35)],
                        previousDocumentLineId: [validators.maxLength(5)],
                    },
                    stopAtNull: true,
                },
                additionalInformation22: {
                    childValidators: {
                        additionalInformationCode: [validators.exact(5)],
                        additionalInformationText: [validators.maxLength(512)],
                    },
                    stopAtNull: true,
                },
                producedDocumentsWritingOff203: {
                    childValidators: {
                        documentType: [validators.exact(4)],
                        documentIdentifier: [validators.maxLength(35)],
                    },
                    stopAtNull: true,
                },
                ucr24: [validators.maxLength(35)],
            },
        },
        supplyChainActor337: {
            childValidators: {
                roleCode: [validators.maxLength(3)],
                traderIdentification: [validators.maxLength(17)],
            },
            stopAtNull: true,
        },
        goodsInformation: {
            childValidators: {
                goodsDescription68: [validators.maxLength(512)],
                grossMass65: [validators.float(16, 6)],
                cusCode613: [validators.exact(8)],
                packaging: {
                    childValidators: {
                        typePackage69: [validators.required(), validators.maxLength(2)],
                        packageNumber610: [validators.maxLength(8)],
                        shippingMarks611: [validators.maxLength(512)],
                    },
                    selfValidators: [validators.minLength(1)],
                    stopAtNull: true,
                },
                commodityCode: {
                    childValidators: { combinedNomenclatureCode614: [validators.maxLength(6)] },
                },
            },
        },
        transportInformation: {
            childValidators: {
                containerIdentificationNumber710: {
                    childValidators: { containerIdentificationNumber: [validators.maxLength(17)] },
                    stopAtNull: true,
                },
            },
        },
    },
};

export const irelandTsdG4ValidationHeader = {
    childValidators: {
        numberOfItems: [
            validators.required(),
            validators.number(),
            validators.min(1),
            validators.maxLength(3),
            async (_: string, b: FormModel<any>) => {
                const itemsLength = b.getValues()?.goodsShipment.customsGoodsItem?.length;
                const numberOfItemsField = b.getValues()?.numberOfItems;
                if (itemsLength === undefined) return undefined;
                if (Number(itemsLength) !== Number(numberOfItemsField)) {
                    return 'Number of items from the input does not match the number of items in the declaration.';
                }
                return undefined;
            },
        ],
        declaration: {
            childValidators: {
                customsOffices: {
                    childValidators: {
                        supervisingCustomsOffice527: [validators.exact(8)],
                        customsOfficeLodgement: [validators.required(), validators.exact(8)],
                    },
                },
                parties: {
                    childValidators: {
                        declarant: {
                            childValidators: {
                                declarant318: [validators.required(), validators.eori()],
                            },
                        },
                        representative: {
                            childValidators: {
                                representative320: [validators.eori()],
                                representativeStatus321: [validators.exact(1)],
                            },
                        },
                    },
                },
            },
        },
        goodsShipment: {
            childValidators: {
                documentsAuthorisations: {
                    childValidators: {
                        simplifiedDeclarationDocumentWritingOff201: {
                            childValidators: {
                                previousDocumentType: [validators.maxLength(3)],
                                previousDocumentIdentifier: [validators.maxLength(35)],
                                previousDocumentLineId: [validators.maxLength(5)],
                            },
                            stopAtNull: true,
                        },
                        additionalInformation22: {
                            childValidators: {
                                additionalInformationCode: [validators.exact(5)],
                                additionalInformationText: [validators.maxLength(512)],
                            },
                            stopAtNull: true,
                        },
                        producedDocumentsWritingOff203: {
                            childValidators: {
                                documentType: [validators.exact(4)],
                                documentIdentifier: [validators.maxLength(35)],
                            },
                            stopAtNull: true,
                        },
                        ucr24: [validators.maxLength(35)],
                        warehouseIdentification27: {
                            childValidators: {
                                warehouseType: [validators.required(), validators.exact(1)],
                                warehouseIdentifier: [validators.required(), validators.maxLength(35)],
                            },
                            selfValidators: [validators.minLength(1)],
                            stopAtNull: true,
                        },
                    },
                },

                datesPlaces: {
                    childValidators: {
                        locationGoods523: {
                            childValidators: {
                                identificationOfLocation: [validators.maxLength(35)],
                                qualifierIdentification: [validators.exact(1)],
                                additionalIdentifier: [validators.exact(3)],
                                locationTypeCode: [validators.exact(1)],
                                address: {
                                    childValidators: {
                                        city: [validators.maxLength(35)],
                                        countryCode: [validators.exact(2)],
                                        streetAndNumber: [validators.maxLength(70)],
                                        postcode: [validators.maxLength(9)],
                                    },
                                },
                            },
                        },
                    },
                },

                transportInformation: {
                    childValidators: {
                        arrivalTransportMeansId79: {
                            childValidators: {
                                identificationType: [validators.required(), validators.exact(2)],
                                identificationNumber: [validators.required(), validators.maxLength(35)],
                            },
                        },
                        containerIdentificationNumber710: {
                            childValidators: {
                                containerIdentificationNumber: [validators.maxLength(17)],
                            },
                            stopAtNull: true,
                        },
                        seal: {
                            childValidators: { sealNumber718: [validators.maxLength(4)] },
                            sealIdentifier718: {
                                childValidators: {
                                    sealIdentifier: [validators.maxLength(20)],
                                },
                                stopAtNull: true,
                            },
                        },
                    },
                },
                grossMass65: [validators.float(16, 6)],
                supplyChainActor337: {
                    childValidators: {
                        roleCode: [validators.maxLength(3)],
                        traderIdentification: [validators.maxLength(17)],
                    },
                    stopAtNull: true,
                },
                goodsShipmentItem: irelandTsdValidationItem,
            },
        },
    },
};
