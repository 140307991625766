import { Divider, Form } from 'antd';
import PhoneNumber from 'awesome-phonenumber';
import Button from 'components/ui/base/button';
import { FormItem } from 'components/ui/base/form';
import FormEori from 'components/ui/composed/formEori/FormEori';
import FormTextArea from 'components/ui/composed/formTextArea/FormTextArea';
import { ErrorResponse } from 'core/http/response';
import { objectIsEmpty, removeEmptyStringFromObject } from 'core/utils/objects';
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import useLoadingButton from 'hooks/useLoadingButton';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createCustomer, editCustomer } from 'store/customers/client';
import { Customer, CustomerContact, CustomerType, customerTypeLabels } from 'store/customers/customer';
import { Eori } from 'store/eori/eori';
import { Address } from 'store/individuals/individual';
import CustomerAddressForm from 'views/customers/components/CustomerAddressForm';
import {
    getEoriValidation,
    getExactLengthValidation,
    getNumberValidation,
    invalidEoriMessage,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';
import useRequest from '../../../hooks/useRequest';
import useSession from '../../../hooks/useSession';
import { Customer as CustomerC } from './CreateNewCustomer.styles';
import CustomerContactForm from './CustomerContactForm';
import DeclarationInput from 'components/ui/composed/declarations/formInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect';
import { extractPhoneNumber } from 'utils/phoneNumber';
interface Props {
    refreshCustomers: Function;
    closeModal: () => void;
    handleOk: (newCustomer?: any) => void;
    handleEdit?: (customer?: any) => void;
    handleCreateError?: Function;
    handleEditError?: Function;
    customer?: Customer;
}

const contactSchema = Yup.object()
    .shape(
        {
            name: Yup.string().required('Contact name is required.').nullable(),
            email: Yup.string()
                .email('Email is invalid.')
                .when('phoneNumber', (phone) => {
                    if (!phone) {
                        return Yup.string().required('Phone number or email is required').nullable();
                    }
                    return Yup.string().nullable();
                })
                .nullable(),
            phoneNumber: getNumberValidation()
                .when('email', (email) => {
                    if (!email) {
                        return Yup.string().required('Phone number or email is required').nullable();
                    }
                    return Yup.string().nullable();
                })
                .nullable()
                .when('phoneCountryCode', (phoneCountryCode) => {
                    return phoneCountryCode && getNumberValidation().required('Phone number is required.');
                })
                .nullable(),
            role: Yup.string().required('Contact role is required.').nullable(),
        },
        [['phoneNumber', 'email']]
    )
    .required('Contact is required');

const customerSchema = (phoneCountryCode?: string) => {
    return Yup.object().shape({
        referenceCode: getExactLengthValidation('referenceCode', 3).required('Reference code is required.').nullable(),
        eori: getEoriValidation().nullable(),
        name: Yup.string().required('Customer name is required.').nullable(),
        type: Yup.string().required('Customer type is required.').nullable(),
        contact: contactSchema,
        contacts: Yup.array().of(contactSchema).nullable(),
        address: Yup.object()
            .shape({
                addressLine1: Yup.string().when('address', (address) => {
                    if (!address?.addressLine1) {
                        return Yup.string().required('Address Line 1 is required');
                    }
                    return Yup.string().nullable();
                }),
                city: Yup.string().when('address', (address) => {
                    if (!address?.city) {
                        return Yup.string().required('City is required');
                    }
                    return Yup.string().nullable();
                }),
                postCode: Yup.string().when('address', (address) => {
                    if (!address?.postCode) {
                        return Yup.string().required('Post Code is required');
                    }
                    return Yup.string().nullable();
                }),
                country: Yup.string().when('address', (address) => {
                    if (!address?.country) {
                        return Yup.string().required('Country is required');
                    }
                    return Yup.string().nullable();
                }),
            })
            .default(undefined),
    });
};

const CreateNewCustomer: FC<Props> = ({
    refreshCustomers,
    closeModal,
    handleOk,
    customer,
    handleEdit,
    handleCreateError,
    handleEditError,
}) => {
    const [phoneCountryCode, setPhoneCountryCode] = useState<string | undefined>();
    const { t } = useTranslation('customers');

    const formik = useFormik<Partial<Customer>>({
        initialValues: {},
        validationSchema: customerSchema(phoneCountryCode),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => createNewCustomerSubmit(values),
    });

    const {
        getFieldProps,
        resetForm,
        getFieldMeta,
        setValues,
        errors,
        values,
        validateForm,
        setTouched,
        setFieldError,
        setFieldValue,
        setFieldTouched,
        getFieldHelpers,
        submitForm,
    } = formik;

    const { customerId } = useSession();

    const { loading, handleOnPressButton } = useLoadingButton({ onSubmit: submitForm });

    const {
        data: newCustomer,
        doRequest: doCreateCustomer,
        isLoading: loadingCreate,
        error: createError,
    } = useRequest(createCustomer);

    const {
        data: editedCustomer,
        doRequest: doEditCustomer,
        isLoading: loadingEdit,
        error: editError,
    } = useRequest(editCustomer);

    useEffect(() => {
        if (!customer) {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubErrors = (err: ErrorResponse) => {
        err?.subErrors?.forEach((e) => {
            if (
                e.field === 'city' ||
                e.field === 'postCode' ||
                e.field === 'country' ||
                e.field === 'addressLine1' ||
                e.field === 'addressLine2'
            ) {
                setFieldError(`address.${e.field}`, e.message);
                setFieldTouched(`address.${e.field}`, true, false);
            } else {
                setFieldError(e.field, e.message);
            }
        });
    };
    const createNewCustomerSubmit = async (values: Partial<Customer>) => {
        const combinePhone = (contact: CustomerContact | undefined) => {
            if (!contact) return undefined;
            if (!contact.phoneCountryCode && !contact.phoneNumber) return contact;
            const contactClone = { ...contact };
            delete contactClone.phoneCountryCode;
            return {
                ...contactClone,
                phoneNumber: `${contact.phoneCountryCode}${contact.phoneNumber}`,
            };
        };
        let body = {
            ...values,
            brokerIds: [customerId],
            contact: combinePhone(values.contact),
            contacts: (values.contacts ?? []).map(combinePhone),
        };

        if (!body.type) {
            body.type = CustomerType.TRADER;
        }

        if (body.address) {
            const { address } = body;
            body.address = removeEmptyStringFromObject(address);
            if (objectIsEmpty(body.address)) {
                delete body['address'];
            }
        }

        if (customer && customer.id) {
            await doEditCustomer(customer.id, body as Partial<Customer>);
        } else {
            await doCreateCustomer(body as Customer);
        }
    };

    useEffect(() => {
        if (loadingCreate === false && newCustomer !== null) {
            if (!createError) {
                refreshCustomers();
                resetForm();
                handleOk(newCustomer);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreate, newCustomer, createError]);

    useEffect(() => {
        setPhoneCountryCode(values.contact?.phoneCountryCode);
    }, [values]);

    useEffect(() => {
        if (loadingEdit === false && editedCustomer !== null && handleEdit) {
            if (!editError) {
                refreshCustomers();
                resetForm();
                handleEdit(editedCustomer);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingEdit, editedCustomer, editError]);

    useEffect(() => {
        if (createError && handleCreateError) {
            handleCreateError(createError);
            const err: ErrorResponse = { ...createError };
            handleSubErrors(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createError]);

    useEffect(() => {
        if (editError && handleEditError) {
            handleEditError(editError);
            const err: ErrorResponse = { ...editError };
            handleSubErrors(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editError]);

    useEffect(() => {
        const formatContact = (contact: CustomerContact | undefined) => {
            if (!contact) return undefined;
            const phone = extractPhoneNumber(contact.phoneNumber);
            return { ...contact, phoneCountryCode: phone.countryCode, phoneNumber: phone.phoneNumber };
        };
        const formatCustomer = (customer: Customer | undefined) => {
            if (!customer) return {};
            const contact = formatContact(customer.contact);
            const contacts = (customer.contacts ?? []).map(formatContact);
            return { ...customer, contact, contacts };
        };

        setValues(formatCustomer(customer));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    const onCloseButton = () => {
        closeModal();
        resetForm();
    };

    const checkRequired = () => {
        return !!!getFieldProps('contact.phoneNumber').value && !!!getFieldProps('contact.email').value;
    };

    const handleConfirm = async () => {
        const validation = await validateForm();
        if (Object.keys(validation).length > 0) {
            setTouched(setNestedObjectValues(validation, true));
        }

        if (objectIsEmpty(validation)) {
            await handleOnPressButton(); //handleSubmit();
        } else {
            console.log(errors);
        }
    };

    const fillEoriInfo = useCallback(
        (eori: Eori) => {
            setFieldValue('name', eori?.name);
            setFieldValue('eori', eori?.eori);
            setFieldValue('address.addressLine1', eori?.street);
            setFieldValue('address.city', eori?.city);
            setFieldValue('address.postCode', eori?.postCode);
        },
        [setFieldValue]
    );

    const onError = useCallback(() => {
        setFieldError('eori', invalidEoriMessage);
    }, [setFieldError]);

    const onSuccess = useCallback(() => {
        setFieldError('eori', undefined);
    }, [setFieldError]);

    const hasValues = useMemo(
        () =>
            !!getFieldProps('name').value ||
            !!getFieldProps('address.addressLine1').value ||
            !!getFieldProps('address.city').value ||
            !!getFieldProps('address.postCode').value,
        [getFieldProps]
    );

    useEffect(() => {
        const { address } = values;

        const checkAddressWithAllFieldsEmpty = (values: Address) => {
            const isNotEmpty = Object.entries(values).filter(([key, value]) => value?.length > 0);
            return isNotEmpty.length === 0;
        };

        if (address && (objectIsEmpty(address) || checkAddressWithAllFieldsEmpty(address))) {
            const newValues = { ...values, address: undefined };
            setValues(newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <Form layout="vertical" style={{ paddingRight: '4rem' }}>
            <FormikProvider value={formik}>
                <DeclarationInput
                    required
                    label="Customer name"
                    fieldMeta={getFieldMeta('name')}
                    fieldProps={getFieldProps('name')}
                />

                <DeclarationSelect
                    required
                    hideKeys
                    label="Customer type"
                    fieldMeta={getFieldMeta('type')}
                    fieldProps={getFieldProps('type')}
                    selectOptions={customerTypeLabels}
                    hideCodelistMenu
                />

                <FormEori
                    declaration
                    hasValues={hasValues}
                    fieldMeta={getFieldMeta('eori')}
                    fieldProps={getFieldProps('eori')}
                    fieldHelper={getFieldHelpers('eori')}
                    onApply={fillEoriInfo}
                    onError={onError}
                    onSuccess={onSuccess}
                    tooltip={t('common.eori_tooltip')}
                />

                <DeclarationInput
                    required
                    label="Reference Code"
                    maxLength={3}
                    tooltip={t('common.reference_code_info')}
                    fieldMeta={getFieldMeta('referenceCode')}
                    fieldProps={getFieldProps('referenceCode')}
                />

                <DeclarationInput
                    label="Trader Account Number"
                    fieldMeta={getFieldMeta('traderAccountNumber')}
                    fieldProps={getFieldProps('traderAccountNumber')}
                    tooltip={t('common.trader_account_number_tooltip')}
                />

                <DeclarationInput
                    label="Deferred account number"
                    fieldMeta={getFieldMeta('deferredAccountNumber')}
                    fieldProps={getFieldProps('deferredAccountNumber')}
                    tooltip={t('common.deferred_account_number_tooltip')}
                />

                <DeclarationInput
                    label="VAT Number"
                    fieldMeta={getFieldMeta('vat')}
                    fieldProps={getFieldProps('vat')}
                />

                <CustomerContactForm
                    formik={{
                        getFieldProps: (name) => getFieldProps(`contact.${name}`),
                        getFieldMeta: (name) => getFieldMeta(`contact.${name}`),
                    }}
                />

                <FieldArray
                    name="contacts"
                    render={(arrayHelpers) => {
                        return (
                            <CustomerC.Contact.Container
                                style={{ marginBottom: !values.contacts?.length ? '2rem' : 0 }}
                            >
                                <CustomerC.Contact.Header.Container>
                                    <CustomerC.Contact.Header.Text>Additional Contacts</CustomerC.Contact.Header.Text>
                                    <CustomerC.Contact.Header.Button
                                        shape="circle"
                                        onClick={() =>
                                            arrayHelpers.insert(values.contacts?.length ?? 0, {
                                                name: '',
                                                email: '',
                                                phoneNumber: '',
                                                phoneCountryCode: '',
                                            })
                                        }
                                    >
                                        <PlusOutlined />
                                    </CustomerC.Contact.Header.Button>
                                </CustomerC.Contact.Header.Container>
                                {values.contacts?.map((_, index) => (
                                    <>
                                        <CustomerC.Contact.Container>
                                            <CustomerC.Contact.Header.Container>
                                                <CustomerC.Contact.Header.Text>
                                                    Additional Contact {index + 1}
                                                </CustomerC.Contact.Header.Text>
                                                <Divider style={{ width: 'auto', minWidth: 0, flex: '1' }} />
                                                <CustomerC.Contact.Header.Button
                                                    shape="circle"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    <MinusOutlined />
                                                </CustomerC.Contact.Header.Button>
                                            </CustomerC.Contact.Header.Container>
                                            <div>
                                                <CustomerContactForm
                                                    formik={{
                                                        getFieldProps: (name) =>
                                                            getFieldProps(`contacts.${index}.${name}`),
                                                        getFieldMeta: (name) =>
                                                            getFieldMeta(`contacts.${index}.${name}`),
                                                    }}
                                                />
                                            </div>
                                        </CustomerC.Contact.Container>
                                        <Divider
                                            style={{
                                                width: 'auto',
                                                minWidth: 0,
                                                flex: '1',
                                                margin: 0,
                                                marginBottom: index === (values.contacts?.length ?? 0) - 1 ? '2rem' : 0,
                                            }}
                                        />
                                    </>
                                ))}
                            </CustomerC.Contact.Container>
                        );
                    }}
                />

                <CustomerAddressForm
                    fieldProps={{
                        addressLine1: getFieldProps('address.addressLine1'),
                        addressLine2: getFieldProps('address.addressLine2'),
                        postCode: getFieldProps('address.postCode'),
                        city: getFieldProps('address.city'),
                        country: getFieldProps('address.country'),
                    }}
                    fieldMeta={{
                        addressLine1: getFieldMeta('address.addressLine1'),
                        addressLine2: getFieldMeta('address.addressLine2'),
                        postCode: getFieldMeta('address.postCode'),
                        city: getFieldMeta('address.city'),
                        country: getFieldMeta('address.country'),
                    }}
                    label={{
                        addressLine1: 'Address Line 1',
                        addressLine2: 'Address Line 2',
                        postCode: 'Post Code',
                        city: 'City',
                        country: 'Country',
                    }}
                />

                <FormTextArea
                    label="Special instructions"
                    fieldMeta={getFieldMeta('specialInstructions')}
                    fieldProps={getFieldProps('specialInstructions')}
                />
                <FormItem style={{ textAlign: 'right', marginTop: '3.6rem' }}>
                    <Button size="large" style={{ marginRight: '1.6rem' }} onClick={() => onCloseButton()}>
                        Cancel
                    </Button>
                    <Button size="large" type="primary" onClick={() => handleConfirm()} loading={loading}>
                        Confirm
                    </Button>
                </FormItem>
            </FormikProvider>
        </Form>
    );
};
export default CreateNewCustomer;
