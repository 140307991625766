const GvmsHeaderDraftMessage = () => (
    <div style={{ marginBottom: 10, fontSize: 16 }}>
        <span>You can only use a draft GMR to make transport bookings.</span>{' '}
        <div style={{ display: 'inline', fontWeight: 'bolder' }}>
            You must complete your GMR to use it for moving goods.
        </div>{' '}
        <br />
        <span>
            Add more information to this draft to complete your GMR at any time before you travel. Leave enough time to
            gather all the information required.
        </span>
    </div>
);

export default GvmsHeaderDraftMessage;
