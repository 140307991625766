import { ParsedCard, ParsedField, ParsedForm } from 'store/declaration-form-errors/ParsedForm';
import { parseDeclarationItems, parseParty } from './utils';
import { get } from 'lodash';

const taricsParser = (parsedItemDetails: ParsedCard[] | undefined, item: any) => {
    const fields: ParsedField[] = [];

    const suggestedTaricsArrayPath = 'suggestedTarics';
    const suggestedTaricArrayValue = get(item, suggestedTaricsArrayPath) as unknown as any[];

    const harmonizedSystemSubHeadingCodeValues: string[] = [];
    const combinedNomenclatureCodeValues: string[] = [];

    suggestedTaricArrayValue?.forEach((taricPair) => {
        const commodityCodeValue = get(taricPair, 'commodityCode');

        harmonizedSystemSubHeadingCodeValues.push(commodityCodeValue.substring(0, 6));
        combinedNomenclatureCodeValues.push(commodityCodeValue.substring(6, 8));
    });

    const harmonizedSystemSubHeadingCodePath = 'commodity.commodityCode.harmonizedSystemSubHeadingCode';
    const harmonizedSystemSubHeadingCode = get(item, harmonizedSystemSubHeadingCodePath);

    const combinedNomenclatureCodePath = 'commodity.commodityCode.combinedNomenclatureCode';
    const combinedNomenclatureCode = get(item, combinedNomenclatureCodePath);

    if (!harmonizedSystemSubHeadingCode) {
        fields.push({
            name: `Harmonized System sub-heading code`,
            message: harmonizedSystemSubHeadingCodeValues,
            path: harmonizedSystemSubHeadingCodePath,
        });
    } else {
        const field = parsedItemDetails
            ?.find(({ name }) => name === 'Goods Information')
            ?.fields?.find((field) => field.path === harmonizedSystemSubHeadingCodePath);
        if (field) {
            field.message = combinedNomenclatureCode.substring(0, 6);
        }
    }

    if (!combinedNomenclatureCode) {
        fields.push({
            name: `Combined nomenclature code`,
            message: combinedNomenclatureCodeValues,
            path: combinedNomenclatureCodePath,
        });
    } else {
        const field = parsedItemDetails
            ?.find(({ name }) => name === 'Goods Information')
            ?.fields?.find((field) => field.path === combinedNomenclatureCodePath);
        if (field) {
            field.message = field.message?.toString().substring(6, 8);
        }
    }

    if (fields.length > 0 && parsedItemDetails) {
        parsedItemDetails.unshift({ name: 'Suggested Tarics', fields });
    }
};

export const parseInvoiceResponse = (defaultParse: ParsedForm, declaration: any) => {
    parseParty(defaultParse.masterDetails, declaration, 'representative');
    parseParty(defaultParse.masterDetails, declaration, 'holderOfTheTransitProcedure');
    parseParty(defaultParse.masterDetails, declaration, 'consignment.carrier');
    parseParty(defaultParse.masterDetails, declaration, 'consignment.consignor');
    parseParty(defaultParse.masterDetails, declaration, 'consignment.consignee');

    const items = declaration?.consignment?.houseConsignment?.map((hc: any) => hc.consignmentItem).flat();

    parseDeclarationItems(defaultParse.items, declaration, '', taricsParser, items);
    const parsedDeclaration = {
        masterDetails: defaultParse.masterDetails,
        items: defaultParse.items,
    };
    return parsedDeclaration;
};
