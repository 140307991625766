import {
    getEuTaricCodeMeasurement,
    getTaricCodes,
    getTaricCodesAmount,
    getTaricCodesDetails,
    getTaricCodesSections,
    getUkTaricCodeMeasurement,
} from './client';
import { MeasureType, TaricCodesType } from './taric-code';
export const doGetTaricCodes: Function = (type: TaricCodesType, query: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_TARIC_CODES_REQUEST' });
    try {
        const payload = await getTaricCodes(type, query);
        dispatch({
            type: 'GET_TARIC_CODES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_TARIC_CODES_ERROR', error: e?.response?.data });
    }
};
export const doGetTaricCodesDetails: Function =
    (type: TaricCodesType, query?: string, date?: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_TARIC_CODES_SEARCH_REQUEST' });
        try {
            const payload = await getTaricCodesDetails(type, query, date);
            dispatch({
                type: 'GET_TARIC_CODES_SEARCH_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_TARIC_CODES_SEARCH_ERROR', error: e?.response?.data });
        }
    };
export const doGetTaricCodesAmount: Function =
    (type: TaricCodesType, code: string, country?: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_TARIC_CODES_AMOUNT_REQUEST' });
        try {
            const payload = await getTaricCodesAmount(type, code, country);
            dispatch({
                type: 'GET_TARIC_CODES_AMOUNT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_TARIC_CODES_AMOUNT_ERROR', error: e?.response?.data });
        }
    };
export const doGetTaricCodesSections: Function = (type: TaricCodesType) => async (dispatch: Function) => {
    dispatch({ type: 'GET_TARIC_CODES_SECTIONS_REQUEST' });
    try {
        const payload = await getTaricCodesSections(type);
        dispatch({
            type: 'GET_TARIC_CODES_SECTIONS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_TARIC_CODES_SECTIONS_ERROR', error: e?.response?.data });
    }
};
export const doGetEuImportTaricCodeMeasurement: Function =
    (code: string, country?: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_EU_IMPORT_TARIC_MEASUREMENT_REQUEST' });
        try {
            const payload = await getEuTaricCodeMeasurement(code, MeasureType.IMPORT, country);
            dispatch({
                type: 'GET_EU_IMPORT_TARIC_MEASUREMENT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_EU_IMPORT_TARIC_MEASUREMENT_ERROR', error: e?.response?.data });
        }
    };
export const doGetEuExportTaricCodeMeasurement: Function =
    (code: string, country?: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_EU_EXPORT_TARIC_MEASUREMENT_REQUEST' });
        try {
            const payload = await getEuTaricCodeMeasurement(code, MeasureType.EXPORT, country);
            dispatch({
                type: 'GET_EU_EXPORT_TARIC_MEASUREMENT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_EU_EXPORT_TARIC_MEASUREMENT_ERROR', error: e?.response?.data });
        }
    };
export const doGetUkImportTaricCodeMeasurement: Function =
    (code: string, country?: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_UK_IMPORT_TARIC_MEASUREMENT_REQUEST' });
        try {
            const payload = await getUkTaricCodeMeasurement(code, MeasureType.IMPORT, country);
            dispatch({
                type: 'GET_UK_IMPORT_TARIC_MEASUREMENT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_UK_IMPORT_TARIC_MEASUREMENT_ERROR', error: e?.response?.data });
        }
    };
export const doGetUkExportTaricCodeMeasurement: Function =
    (code: string, country?: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_UK_EXPORT_TARIC_MEASUREMENT_REQUEST' });
        try {
            const payload = await getUkTaricCodeMeasurement(code, MeasureType.EXPORT, country);
            dispatch({
                type: 'GET_UK_EXPORT_TARIC_MEASUREMENT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_UK_EXPORT_TARIC_MEASUREMENT_ERROR', error: e?.response?.data });
        }
    };

export const doGetEuGeneralTaricCodeMeasurement: Function =
    (code: string, country?: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_EU_GENERAL_TARIC_MEASUREMENT_REQUEST' });
        try {
            const payload = await getEuTaricCodeMeasurement(code, MeasureType.GENERAL, country);
            dispatch({
                type: 'GET_EU_GENERAL_TARIC_MEASUREMENT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_EU_GENERAL_TARIC_MEASUREMENT_ERROR', error: e?.response?.data });
        }
    };
export const doGetUkGeneralTaricCodeMeasurement: Function =
    (code: string, country?: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_UK_GENERAL_TARIC_MEASUREMENT_REQUEST' });
        try {
            const payload = await getUkTaricCodeMeasurement(code, MeasureType.GENERAL, country);
            dispatch({
                type: 'GET_UK_GENERAL_TARIC_MEASUREMENT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_UK_GENERAL_TARIC_MEASUREMENT_ERROR', error: e?.response?.data });
        }
    };
