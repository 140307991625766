import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { TextSmall } from 'components/ui/base/typography';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useDeclarationInputFocused from 'hooks/useDeclarationInputFocused';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    StyledNumberInput,
    ViewOnlyLabel,
} from '../declarations/formInput/DeclarationInput.styles';
import { getFormikProps, toPlaceholder } from 'views/declarations/utils/form-utils';
import { useLocation, useOutletContext } from 'react-router-dom';
import { TemplateCheckboxes } from 'components/ui/composed/template/TemplateCheckbox';
import { InputNumberProps, Tooltip } from 'antd';
import { debounce, kebabCase } from 'lodash';
import useDeclarations from 'hooks/useDeclarations';
import { flushSync } from 'react-dom';
import { TestProps } from 'core/utils/testTypes';
import { LinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import countries from 'utils/countries';
import { useTemplateContext } from '../template/TemplateContext';
import { useMirrorMetaContext } from '../mirroring/MirrorMetaContext';
import { useRegisterField } from '../template/CardContext';
import useFieldTemplateMetaData from '../template/useTemplateViewEditControls';
import MultipleButtons from '../declarations/MultipleButtons';
import { getMetadata } from 'react-phone-hooks';
import { Select } from 'components/ui/base/select';

const phoneCodeOptions = countries.map((country) => {
    const id = country.phone;
    const value = country.label;

    return { id, value };
});

const FieldsContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
`;

const PhoneCodeFieldContainer = styled.div`
    max-width: 150px;
    width: 100%;
    flex: 0;
`;
const PhoneNumberFieldContainer = styled.div`
    width: 100%;
    min-width: 200px;
    flex: 1;
`;

const StyledSelect = styled(Select)`
    min-width: 100px;
    max-width: 150px;

    & .ant-select-selector {
        border-radius: 4px !important;
        border: 1px solid #cecece !important;
    }
`;

export interface FormPhoneNumberProps extends InputNumberProps, TestProps {
    label?: string;
    refTooltip?: string;
    tooltip?: string | false;
    icon?: ReactNode;
    refNumber?: string;
    children?: ReactNode;
    fieldProps?: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    required?: boolean;
    maxLength?: number;
    viewOnly?: boolean;
    integerNumbers?: boolean;
    min?: string;
    max?: string;
    disabled?: boolean;
    stringMode?: boolean;
    refClicked?: (refNumber: string | number) => void;
    fieldEvents?: {
        onBlur?: (value: string | null) => void;
        onChange?: (value: number | null) => void;
    };
    value?: number;
    condensed?: boolean;
    multipleF?: {
        onAdd: () => void;
        onRemove: () => void;
        canAdd?: boolean;
        canRemove?: boolean;
    };
    specialName?: string; // Used for template meta handle of taxBox34Bis fields as of 13/04/23
}

const DeclarationPhoneNumber: FC<FormPhoneNumberProps> = ({
    label,
    refNumber,
    refTooltip,
    tooltip,
    fieldMeta: fieldMetaProp,
    fieldProps: fieldPropsProp,
    fieldHelper: fieldHelpersProp,
    required,
    viewOnly: viewOnlyProp,
    min = '0',
    disabled,
    refClicked,
    fieldEvents,
    value: valueProp,
    maxLength,
    condensed,
    multipleF,
    specialName,
    hidden,
    testId,
    ...numberInputProps
}) => {
    const { t } = useTranslation('common');
    const { setFocused } = useDeclarationInputFocused();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const { template, templateFormik, form, isViewOnly: isTemplateViewOnly } = useTemplateContext();
    const { declaration } = useDeclarations();

    const { meta, handleOpenModal } = useMirrorMetaContext();

    const isFieldMirrored = useMemo(() => {
        if (!(fieldPropsProp?.name && meta)) return undefined;

        return meta?.[fieldPropsProp?.name]?.isMirrored;
    }, [fieldPropsProp?.name, meta]);

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const showTooltip = useMemo(
        () =>
            debounce(() => {
                if (!tooltip) return;
                setTooltipVisible(true);
            }, 200),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const hideTooltip = () => {
        if (!tooltip) return;
        showTooltip?.cancel();
        setTooltipVisible(false);
    };

    useRegisterField({ path: fieldPropsProp?.name, required, hidden });
    const { isViewable, isInvisible } = useFieldTemplateMetaData(specialName ?? fieldPropsProp?.name);

    const { fieldProps, fieldMeta, fieldHelpers } = useMemo(() => {
        if (template && templateFormik && form) {
            const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
            return { ...f, fieldHelpers: f.fieldHelper };
        }

        return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
    }, [template, templateFormik, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

    const [localValue, setLocalValue] = useState<number | undefined | null>(undefined);

    const { onBlur, ...otherFieldEvents } = fieldEvents ?? {};

    const handleRefClick = useCallback(() => {
        if (refClicked) {
            refNumber ? refClicked(refNumber) : refClicked(label ?? '');
        }
    }, [refClicked, refNumber, label]);

    useEffect(() => {
        if (hidden) return;
        if (fieldProps?.value === '' && fieldHelpers) {
            fieldHelpers.setValue(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps?.value]);

    const showRefTooltip = useMemo(() => refTooltip && refTooltip.length > 0, [refTooltip]);

    const viewOnly = useMemo(() => {
        if (template) return isTemplateViewOnly;
        if (outletContext?.amendment) return isViewable;
        return viewOnlyProp || isViewable || inViewOnly || declaration?.archived;
    }, [
        template,
        isTemplateViewOnly,
        outletContext?.amendment,
        isViewable,
        viewOnlyProp,
        inViewOnly,
        declaration?.archived,
    ]);

    const handleTemplateCheckboxChange = useMemo(() => {
        return numberInputProps.onChange ?? otherFieldEvents.onChange;
    }, [numberInputProps.onChange, otherFieldEvents.onChange]);

    const handleOpenMirrorSeveringModal = useCallback(() => {
        if (!isFieldMirrored) return;

        const revertValueCallback = () => setLocalValue(valueProp ?? fieldProps?.value);

        handleOpenModal?.(revertValueCallback);
    }, [isFieldMirrored, valueProp, fieldProps?.value, handleOpenModal]);

    const extractMetadata = useCallback((data: any, checkPlus: boolean = true) => {
        const hasPlus = data?.toString()?.includes('+');
        const clean = data?.toString()?.replace('+', '') || '';

        if (checkPlus) {
            return getMetadata((hasPlus && clean) || '');
        }

        return getMetadata(clean);
    }, []);

    const values = useMemo(() => {
        const metadata = extractMetadata(fieldProps?.value);
        const code = metadata?.[2];
        const phoneNumber = fieldProps?.value?.toString().replace(`+${code?.toString()}`, '') ?? '';

        return { code: code ? `+${code}` : '', phoneNumber };
    }, [extractMetadata, fieldProps?.value]);

    const handleChange = (v: string, field: 'code' | 'phoneNumber') => {
        const prevValue = fieldProps?.value ?? '';

        if (field === 'code') {
            const metadata = extractMetadata(fieldProps?.value, v === null);
            const code = metadata?.[2];
            const newValue =
                prevValue && values.code ? prevValue.replace(`+${code}`, v ?? '') : prevValue ? `${v}${prevValue}` : v;
            fieldProps?.onChange({
                target: {
                    value: newValue,
                    name: fieldProps?.name,
                },
            });
        } else {
            const metadata = extractMetadata(fieldProps?.value);
            const code = metadata?.[2];
            const newValue = `${code ? `+${code}` : ''}${v}`;
            fieldProps?.onChange({
                target: {
                    value: newValue,
                    name: fieldProps?.name,
                },
            });
        }
    };

    const render = useMemo(
        () => (
            <>
                {viewOnly ? (
                    <>
                        <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                        {template && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                        <TextSmall data-testid={testId ?? kebabCase(label ?? fieldPropsProp?.name)}>
                            {fieldProps?.value ?? '-'}
                        </TextSmall>
                    </>
                ) : (
                    <>
                        <LabelDiv condensed={condensed}>
                            {label && (
                                <InputLabel>
                                    {label}
                                    {required && (
                                        <InputLabelRequired>{condensed ? '*' : t('mandatory')}</InputLabelRequired>
                                    )}
                                </InputLabel>
                            )}

                            {refNumber && <RefDiv>{refNumber}</RefDiv>}
                            {showRefTooltip && (
                                <IconTooltip
                                    title={label}
                                    tooltip={refTooltip ?? t('defaultTooltip')}
                                    icon={<StyledInfoCircleFilled />}
                                    tooltipClicked={handleRefClick}
                                />
                            )}
                            {isFieldMirrored && (
                                <Tooltip title={'Mirrored field'}>
                                    <LinkOutlined
                                        style={{ marginLeft: '0.8rem', color: '#00CCFF', cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            )}
                        </LabelDiv>
                        {template && (
                            <TemplateCheckboxes
                                required={required}
                                fieldPath={fieldPropsProp?.name}
                                specialName={specialName}
                                onChange={handleTemplateCheckboxChange}
                            />
                        )}
                        <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                            <InputDiv>
                                <Tooltip visible={tooltipVisible} overlay={tooltip}>
                                    <FieldsContainer>
                                        <PhoneCodeFieldContainer>
                                            <StyledSelect
                                                value={values.code}
                                                showSearch
                                                onChange={(value: any) => handleChange(value, 'code')}
                                                options={phoneCodeOptions.map((option) => ({
                                                    label: `(${option.id}) - ${option.value}`,
                                                    value: option.id,
                                                }))}
                                                filterOption={(input: string, option: any) => {
                                                    return option.label.toLowerCase().includes(input.toLowerCase());
                                                }}
                                                allowClear
                                                placeholder="Enter Code"
                                            />
                                        </PhoneCodeFieldContainer>
                                        <PhoneNumberFieldContainer>
                                            <StyledNumberInput
                                                mirrored={isFieldMirrored}
                                                onMouseOver={showTooltip}
                                                onMouseOut={hideTooltip}
                                                maxLength={maxLength}
                                                placeholder={toPlaceholder(label) ?? ''}
                                                name={fieldProps?.name}
                                                data-testid={testId ?? kebabCase(label ?? fieldPropsProp?.name)}
                                                min={min}
                                                disabled={disabled || isInvisible}
                                                status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                                                multiple={fieldProps?.multiple}
                                                checked={fieldProps?.checked}
                                                {...fieldMeta}
                                                {...otherFieldEvents}
                                                size={condensed ? 'middle' : 'large'}
                                                condensed={condensed}
                                                {...numberInputProps}
                                                onFocus={(e) => {
                                                    setFocused(true);
                                                    setLocalValue(e.target.value ? Number(e.target.value) : null);
                                                }}
                                                onBlur={(e) => {
                                                    flushSync(() => {
                                                        handleChange(e.target.value, 'phoneNumber');
                                                        setLocalValue(null);
                                                    });
                                                    setFocused(false);
                                                    // setLocalValue(null);
                                                    if (numberInputProps.onBlur) numberInputProps.onBlur(e);
                                                    if (onBlur) onBlur?.(e.target.value);
                                                    else fieldProps?.onBlur(e);
                                                    fieldHelpers?.setTouched(true);
                                                }}
                                                onChange={(value: any | null) => {
                                                    setLocalValue(value ?? '');
                                                    handleOpenMirrorSeveringModal();
                                                }}
                                                value={localValue != null ? localValue : values.phoneNumber}
                                            />
                                        </PhoneNumberFieldContainer>
                                    </FieldsContainer>
                                </Tooltip>
                                {multipleF && <MultipleButtons {...multipleF} />}
                            </InputDiv>
                            <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                                {!!fieldMeta?.error && !!fieldMeta?.touched && (
                                    <ErrorLabel>{fieldMeta?.error}</ErrorLabel>
                                )}
                            </ErrorDiv>
                        </FormItem>
                    </>
                )}
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            fieldMeta?.error,
            fieldMeta?.touched,
            fieldProps?.value,
            values,
            // value,
            onBlur,
            disabled,
            localValue,
            viewOnly,
            multipleF?.canRemove,
            multipleF?.canAdd,
        ]
    );

    if (hidden || (isInvisible && !template)) return null;

    return render;
};

export default DeclarationPhoneNumber;
