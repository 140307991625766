import { Row } from 'antd';
import { H4Style } from 'components/ui/base/typography/Typography.styles';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import DeclarationInformation from '../../common/DeclarationInformation';
import DocumentsTable from './components/DocumentsTable';
import UploadDocuments from './components/UploadDocuments';
import { DeclarationInformationWrapper, DocumentUploadButton, MainDiv } from './CustomerDocuments.styles';
import { UploadOutlined } from '@ant-design/icons';
import DocumentForm from './components/DocumentForm';
import { CmsFileAndFormAction, DeclarationDocument } from './types';
import useFormUtils from '../../../../hooks/useFormUtils';
import { DeclarationStatus } from '../../../../store/declarations/enums/common/declaration-status';
import { getDeclarationPayload } from '../../utils/declaration-utils';
import { sortBy } from 'lodash';

interface Props {
    customerId?: string;
    formId?: string;
}

const CustomerDocuments: FC<Props> = () => {
    const { t } = useTranslation('form');
    const {
        documents: cmsFiles,
        listDeclarationDocuments: listCmsFiles,
        declaration,
        declarationHistory,
        getDeclarationHistory,
    } = useDeclarations();
    const { isAis, isAes } = useFormUtils();
    const { declarationId } = useParams<{ declarationId: string }>();

    const [cmsFileAndFormAction, setCmsFileAndFormAction] = useState<CmsFileAndFormAction>({
        file: undefined,
        action: undefined,
    });
    const [declarationDocuments, setDeclarationDocuments] = useState<DeclarationDocument[] | undefined>(
        declaration?.uploadedFiles
    );

    useEffect(() => {
        if (declarationId) {
            listCmsFiles(declarationId);
            getDeclarationHistory(declarationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDeclarationDocumentBasedOnSelectedCmsFile = useMemo(
        () =>
            declarationDocuments?.find(
                (declarationDocument) => declarationDocument.fileId === cmsFileAndFormAction.file?.id
            ),
        [declarationDocuments, cmsFileAndFormAction.file?.id]
    );

    const inForm = useMemo(() => cmsFileAndFormAction.file, [cmsFileAndFormAction.file]);

    const addDeclarationDocument = (newDocument: DeclarationDocument) =>
        setDeclarationDocuments((allDocs) => allDocs && [...allDocs, newDocument]);

    const lastNotificationDeclarationHistory = useMemo(() => {
        if (!declaration) return;
        const declarationPayload = getDeclarationPayload(declaration);
        if (!(declarationHistory && declarationPayload?.notifications)) return;

        const combinedDeclarationHistoryAndNotifications = [
            ...declarationHistory.map((item) => ({
                source: 'declaration history',
                createdAt: item.createdAt,
            })),
            ...declarationPayload.notifications.map((item: any) => ({
                source: 'notifications',
                createdAt: item?.createdAt || item?.notificationDate,
            })),
        ];

        return sortBy(combinedDeclarationHistoryAndNotifications, 'createdAt')[
            combinedDeclarationHistoryAndNotifications.length - 1
        ];
    }, [declarationHistory, declaration]);

    const disabledUpload: boolean = useMemo(() => {
        if (isAis || isAes)
            return (
                declaration?.mrn === undefined ||
                declaration?.status === DeclarationStatus.RELEASED ||
                lastNotificationDeclarationHistory?.source === 'declaration history'
            );
        else return false;
    }, [declaration?.mrn, declaration?.status, isAis, lastNotificationDeclarationHistory, isAes]);

    return (
        <>
            <DeclarationInformationWrapper>
                <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
                    <DeclarationInformation />
                </div>
            </DeclarationInformationWrapper>
            <MainDiv>
                <Row justify="space-between" style={{ marginBottom: '1.5rem' }}>
                    <H4Style>{t('customerDocuments.title')}</H4Style>

                    <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
                        {!inForm && (
                            <DocumentUploadButton
                                size="large"
                                type="link"
                                onClick={() => {
                                    document.getElementById('file-upl')?.click();
                                }}
                                disabled={disabledUpload}
                            >
                                {t('customerDocuments.upload_document')} <UploadOutlined />
                            </DocumentUploadButton>
                        )}
                    </div>
                </Row>

                {!inForm ? (
                    <>
                        <UploadDocuments
                            disabled={disabledUpload}
                            declarationId={declarationId}
                            customerId={declaration?.customerId}
                            listCmsFiles={() => listCmsFiles(declarationId)}
                            setCmsFileAndFormAction={({ file, action }: CmsFileAndFormAction) =>
                                setCmsFileAndFormAction({ file, action })
                            }
                            addDeclarationDocument={addDeclarationDocument}
                        />

                        <DocumentsTable
                            declarationId={declarationId}
                            listCmsFiles={() => listCmsFiles(declarationId)}
                            setCmsFileAndFormAction={({ file, action }: CmsFileAndFormAction) =>
                                setCmsFileAndFormAction({ file, action })
                            }
                            cmsFilesList={cmsFiles?.list}
                            declarationDocuments={declarationDocuments}
                            deleteDeclarationDocument={(deletedDocument: DeclarationDocument) =>
                                setDeclarationDocuments((allDocs) =>
                                    allDocs?.filter((doc) => doc.fileId !== deletedDocument.fileId)
                                )
                            }
                            submitDeclarationDocuments={(submittedDocumentIds: string[]) =>
                                setDeclarationDocuments((allDocs) =>
                                    allDocs?.reduce((declarationDocs: DeclarationDocument[], declarationDoc) => {
                                        if (
                                            submittedDocumentIds.some(
                                                (submittedId) => submittedId === declarationDoc.fileId
                                            )
                                        ) {
                                            declarationDocs.push({ ...declarationDoc, status: 'SUBMITTED' });
                                        } else {
                                            declarationDocs.push(declarationDoc);
                                        }

                                        return declarationDocs;
                                    }, [])
                                )
                            }
                        />
                    </>
                ) : (
                    <DocumentForm
                        declarationId={declarationId}
                        listCmsFiles={() => listCmsFiles(declarationId)}
                        cmsFileAndFormAction={cmsFileAndFormAction}
                        declarationDocument={getDeclarationDocumentBasedOnSelectedCmsFile}
                        cancelForm={() => setCmsFileAndFormAction({ file: undefined, action: undefined })}
                        addDeclarationDocument={addDeclarationDocument}
                        updateDeclarationDocument={(updatedDocument: DeclarationDocument) =>
                            setDeclarationDocuments((allDocs) =>
                                allDocs?.map((declarationDoc) =>
                                    declarationDoc.id === updatedDocument.id ? updatedDocument : declarationDoc
                                )
                            )
                        }
                    />
                )}
            </MainDiv>
        </>
    );
};
export default CustomerDocuments;

export function useCustomerDocuments() {
    return useOutletContext<{
        customerId: string;
        formId: string;
    }>();
}
