export enum DeclarationFieldName {
    IE_IMPORT_H1 = 'irelandImportDeclaration',
    IE_IMPORT_H7 = 'irelandH7ImportDeclaration',
    IE_EXPORT = 'ieExportDeclaration',
    IE_ARRIVAL = 'ieArrivalAtExitDeclaration',
    IE_ENS = 'entrySummaryDeclaration',
    IE_ETD = 'ieImportElectronicTransportDocument',
    IE_TSD = 'ieImportTemporaryStorageDeclaration',
    UK = 'cdsDeclaration',
    GVMS = 'gvmsDeclaration',
    PBN = 'preBoardingNotification',
    IE_NCTS = 'ieNctsDeclaration',
    UK_NCTS = 'ukNctsDeclaration',
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
