export enum JobStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    PROPOSED = 'PROPOSED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    CLOSED = 'CLOSED',
}

export interface JobResponse {
    id: string;
    brokerId?: string;
    customerId: string;
    driverId?: string;
    referenceId: string;
    declarations: string[];
    status: JobStatus;
    createdAt: string;
    tags?: string[];
    haulierId?: string;
}

export interface DeclarationJobInfo {
    jobId: string;
    jobReferenceId: string;
    declarationType: string;
    importer: string;
    exporter: string;
    declarant: string;
    declarationsSize: number;
}
