import SaveOutlined from '@ant-design/icons/lib/icons/SaveOutlined';
import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import CustomModal from 'components/ui/base/modal/Modal';
import { H5, Text } from 'components/ui/base/typography';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useFormik } from 'formik';
import useCustomers from 'hooks/useCustomers';
import useSession from 'hooks/useSession';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotifyEmails } from 'store/declarations/declaration';
import useDeclarations from '../../../../hooks/useDeclarations';
import useGlobalOverlay from '../../../../hooks/useGlobalOverlay';
import { CheckboxDiv, EditIcon, InfoDiv, Label, MailIcon, SaveIcon, StyledCheckBox } from './SubmitModal.styles';

interface Props {
    customerId: string | undefined;
    visible: boolean;
    handleCancel: () => void;
    applySuggestedGrossMass: boolean;
}

const SubmitModal: FC<Props> = ({ customerId, visible, handleCancel, applySuggestedGrossMass }) => {
    const { declarationId } = useParams<{ declarationId: string }>();
    const { submitDeclaration } = useDeclarations();
    const { hideGlobalOverlay, showGlobalOverlay } = useGlobalOverlay();

    const { customer } = useCustomers({ customerId });
    const [editCustomerEmail, setEditCustomerEmail] = useState<boolean>(false);
    const [editDeclarantEmail, setEditDeclarantEmail] = useState<boolean>(false);

    const { userInfo } = useSession();

    const submitDeclarationRequest = async (emails?: NotifyEmails) => {
        if (!declarationId) return;

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Submitting declaration...' } });
        await submitDeclaration(declarationId, { ...emails }, applySuggestedGrossMass);
        hideGlobalOverlay();
    };

    useEffect(() => {
        if (userInfo && userInfo.email) {
            formik.setFieldValue('declarantEmail', userInfo.email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    useEffect(() => {
        if (customer && customer.contact?.email) {
            formik.setFieldValue('customerEmail', customer.contact.email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    const formik = useFormik<{
        customer: boolean;
        declarant: boolean;
        customerEmail?: string;
        declarantEmail?: string;
    }>({
        initialValues: {
            customer: false,
            declarant: false,
            declarantEmail: userInfo?.email,
            customerEmail: customer?.contact?.email,
        },
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => handleSubmitAndNotify(values),
    });

    const handleSubmitAndNotify = async (values: {
        customer: boolean;
        declarant: boolean;
        customerEmail?: string;
        declarantEmail?: string;
    }) => {
        let emails: NotifyEmails = {};
        if (values.customer) {
            emails.customerEmail = values.customerEmail;
        }
        if (values.declarant) {
            emails.clerkEmail = values.declarantEmail;
        }
        submitDeclarationRequest(emails);
        handleCancel();
    };

    const updateCustomerEmail = () => {
        setEditCustomerEmail(false);
    };

    const updateDeclarantEmail = () => {
        setEditDeclarantEmail(false);
    };

    const enableNotifyButton = formik.values.customer || formik.values.declarant;

    return (
        <CustomModal
            data-testid="declaration-confirmation-submit-modal"
            title={<H5>Confirm declaration submit</H5>}
            centered
            visible={visible}
            onCancel={handleCancel}
            width={676}
            equalButtons
            footer={[
                <Button
                    data-testid="declaration-confirmation-submit-button"
                    onClick={() => {
                        submitDeclarationRequest();
                        handleCancel();
                    }}
                    size="large"
                >
                    Submit
                </Button>,
                <Button
                    data-testid="declaration-confirmation-submit-and-notify-button"
                    disabled={!enableNotifyButton}
                    onClick={() => formik.handleSubmit()}
                    size="large"
                >
                    <Text>
                        Submit and notify <MailIcon />
                    </Text>
                </Button>,
            ]}
        >
            {customer && userInfo && (
                <>
                    <InfoDiv>
                        <Text>
                            To notify your customer and declarant about changes on declaration/routing status, please
                            select and press notify. If you dont want just hit skip.
                        </Text>
                    </InfoDiv>
                    <CheckboxDiv>
                        {!editCustomerEmail ? (
                            <Row align="middle">
                                <StyledCheckBox
                                    checked={formik.getFieldProps('customer').value as boolean}
                                    onChange={() => {
                                        const values = { ...formik.values };
                                        values.customer = !formik.values.customer;
                                        formik.setValues(values);
                                    }}
                                />
                                <Label>Customer email:</Label>
                                <Text>{formik.values.customerEmail}</Text>
                                <EditIcon onClick={() => setEditCustomerEmail(true)} />
                            </Row>
                        ) : (
                            <Row align="middle">
                                <Col flex={4}>
                                    <FormInput
                                        fieldProps={formik.getFieldProps('customerEmail')}
                                        fieldHelper={formik.getFieldHelpers('customerEmail')}
                                        label="Customer email"
                                        icon
                                    />
                                </Col>

                                <Col flex={1}>
                                    <SaveIcon onClick={() => updateCustomerEmail()}>
                                        save <SaveOutlined />
                                    </SaveIcon>
                                </Col>
                            </Row>
                        )}
                    </CheckboxDiv>
                    <CheckboxDiv>
                        {!editDeclarantEmail ? (
                            <Row align="middle">
                                <StyledCheckBox
                                    checked={formik.getFieldProps('declarant').value as boolean}
                                    onChange={() => {
                                        const values = { ...formik.values };
                                        values.declarant = !formik.values.declarant;
                                        formik.setValues(values);
                                    }}
                                />
                                <Label>Declarant email:</Label>
                                <Text>{formik.values.declarantEmail}</Text>
                                <EditIcon onClick={() => setEditDeclarantEmail(true)} />
                            </Row>
                        ) : (
                            <Row align="middle">
                                <Col flex={4}>
                                    <FormInput
                                        fieldProps={formik.getFieldProps('declarantEmail')}
                                        fieldHelper={formik.getFieldHelpers('declarantEmail')}
                                        label="Declarant email"
                                        icon
                                    />
                                </Col>

                                <Col flex={1}>
                                    <SaveIcon onClick={() => updateDeclarantEmail()}>
                                        save <SaveOutlined />
                                    </SaveIcon>
                                </Col>
                            </Row>
                        )}
                    </CheckboxDiv>
                </>
            )}
        </CustomModal>
    );
};
export default SubmitModal;
