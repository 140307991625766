import { get, set } from 'lodash';

function modify(obj: any, newObj: any) {
    Object.keys(obj).forEach(function (key) {
        delete obj[key];
    });

    Object.keys(newObj).forEach(function (key) {
        obj[key] = newObj[key];
    });
}

export const complexGet = (obj: any, path: any, hadX: boolean = false, final: any[] = []) => {
    if (!path.match(/\.x\./g)) {
        if (hadX) {
            const value = get(obj, path);
            if (value) final.push(value);
        }

        return get(obj, path);
    }

    const arr = path.split('.');
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === 'x') {
            const parents = get(obj, arr.slice(0, i));
            for (let j = 0; j < (parents?.length ?? 0); j++) {
                complexGet(parents[j], arr.slice(i + 1).join('.'), true, final);
            }
            break;
        }
    }

    return final.flat();
};

export const complexSet = (obj: any, path: any, toSet: any) => {
    if (!path.match(/\.x\./g)) {
        return set(obj, path, toSet);
    }

    if (typeof toSet === 'object') {
        if ('id' in toSet) {
            const allObjects = complexGet(obj, path);
            const theCurrentObject = allObjects?.find((a: any) => a.id === toSet.id);

            if (!theCurrentObject) return set(obj, path.replaceAll(/\.(x)\./g, '.0.'), toSet);

            modify(theCurrentObject, toSet);

            return theCurrentObject;
        }
    }

    return set(obj, path.replaceAll(/\.(x)\./g, '.0.'), toSet);
};
