import { Divider } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useJobs from 'hooks/useJobs';
import debounce from 'lodash/debounce';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { JobResponse } from 'store/jobs/job';
import JobsTable from 'views/jobs/components/tables/JobsTable';
import { SpaceBetween } from '../../components/styles/layout.styles';

export interface DeletedDeclaration {
    declarationId: string;
    jobId?: string;
}

const Jobs: FC = () => {
    const { t } = useTranslation('dashboard');
    const navigate = useNavigate();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { listJobs, createJob } = useJobs();

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listJobs(params);
        } else {
            await listJobs();
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Home',
                path: '',
            },
            {
                breadcrumbName: 'Jobs',
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewJob = () => {
        createJob().then((job: JobResponse) => navigate(`/jobs/${job.id}`, { state: { job } }));
    };

    return (
        <Container>
            <H5>Jobs</H5>
            <SpaceBetween style={{ marginTop: '1.6rem' }}>
                <div style={{ display: 'flex', gap: '15px' }}>
                    <Button size="large" type="primary" onClick={createNewJob}>
                        {t('btCreateNewJob')}
                    </Button>
                </div>
            </SpaceBetween>

            <Divider />
            <SearchBar
                inputPlaceholder="Search by job id, declaration type, status, consignor, consignee, declarant and date"
                onSearch={(value) => debouncedSearch(value)}
                onClear={() => {
                    listJobs();
                }}
            />

            <JobsTable />
        </Container>
    );
};
export default Jobs;
