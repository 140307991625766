import { FieldProps, useFormikContext } from 'formik';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaricCodesType } from 'store/taric-codes/taric-code';
import DeclarationField from 'views/declarations/common/DeclarationField';
import TaricCodeDrawer from 'views/declarations/common/taric-codes/TaricCodeDrawer';
import { TaricDetailsType } from 'views/declarations/common/taric-codes/TaricCodesCard';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id';

export const combinedNomenclatureCodesBlockValidation: {
    childValidators: {
        'commodity.classification.tsp': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.classification.tsp': {
            childValidators: {
                id: [validators.required(), validators.exact(8)],
            },
        },
    },
};

export const combinedNomenclatureCodesFields =
    combinedNomenclatureCodesBlockValidation.childValidators['commodity.classification.tsp'];

interface Props extends BlockProps<Fields> {}

const CombinedNomenclatureCodeBlock = ({
    path = 'commodity.classification.tsp',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { setFieldValue } = useFormikContext();

    const [isTaricCodeDrawerVisible, setIsTaricCodeDrawerVisible] = useState(false);

    const taricCodeDrawer = useMemo(() => {
        const taricCodeType = TaricCodesType.UK;
        return (
            <TaricCodeDrawer
                visible={isTaricCodeDrawerVisible}
                onClose={() => setIsTaricCodeDrawerVisible(false)}
                selectedCommodityCode={(taricCode) => {
                    const taric = taricCode.substring(0, 8);

                    setFieldValue(`${path as string}.id`, taric);

                    setIsTaricCodeDrawerVisible(false);
                }}
                taricCodeType={taricCodeType}
                detailsType={TaricDetailsType.IMPORT}
            />
        );
    }, [isTaricCodeDrawerVisible, path, setFieldValue]);

    const ucc = useMemo(() => '6/14', []);
    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        required={fieldOptions?.id?.required ?? true}
                        refNumber={ucc}
                        label={t('combinedNomenclatureCodeIdentifier')}
                        {...getFormikProps(field.name, form)}
                        onSearchTaricCode={() => setIsTaricCodeDrawerVisible(true)}
                        condensed
                    />
                )}
            </DeclarationField>
            {taricCodeDrawer}
        </>
    );
};

export default CombinedNomenclatureCodeBlock;
