import { ModalProps, Button, Alert } from 'antd';
import { useEffect, useState, Fragment, useMemo } from 'react';
import { H4 } from '../../../../../../components/ui/base/typography';
import { SModal, SModalHeader } from '../../../../../../components/ui/composed/template/TemplateModal';
import useDeclarationFormErrors from '../../../../../../hooks/useDeclarationFormErrors';
import useDeclarations from '../../../../../../hooks/useDeclarations';
import { ParsedField, ParsedForm } from '../../../../../../store/declaration-form-errors/ParsedForm';
import ValidationErrorContainer from '../../../../common/ValidationErrorContainer/ValidationErrorContainer';
import ukGvmsRecordParser from '../../validation/ukGvmsRecordParser';
import {
    BoldText,
    FieldContainer,
    MessageText,
    SectionCard,
    SectionMicCard,
    SectionMicField,
    SectionModal,
    SectionTitle,
    StyledDivider,
    SubmitGmrButton,
} from './GvmsPreSubmissionFormCheckModal.styles';
import moment from 'moment';
import { isArray, isBoolean, toLower } from 'lodash';
import { useAppSelector } from '../../../../../../config/hooks';
import { GvmsCardNames, GvmsFormCustomFields, GvmsFormMicCards } from '../../enums';
import useGlobalOverlay from '../../../../../../hooks/useGlobalOverlay';

const GvmsPreSubmissionFormCheckModalHeader = ({
    declarationErrors,
}: {
    declarationErrors: ParsedForm | undefined;
}) => {
    const [isValidationErrorsModalOpen, setIsValidationErrorsModalOpen] = useState<boolean>(false);
    return (
        <SModalHeader>
            <H4>GMR Pre-submission Check</H4>
            {declarationErrors?.masterDetails.length ? (
                <Alert
                    message="This GMR record contains errors."
                    type="error"
                    showIcon
                    action={
                        <Button size="small" type="text" onClick={() => setIsValidationErrorsModalOpen(true)}>
                            Details
                        </Button>
                    }
                />
            ) : null}

            <ValidationErrorContainer
                isOpen={isValidationErrorsModalOpen}
                close={() => setIsValidationErrorsModalOpen(false)}
            />
        </SModalHeader>
    );
};

const GvmsPreSubmissionFormCheckModalBody = ({
    parsedGmrRecord,
}: {
    parsedGmrRecord: ParsedForm['masterDetails'] | undefined;
}) => {
    const gvmsReferenceData = useAppSelector((state) => state.gvmsReferenceData.gvmsReferenceData);

    const portsMap = useMemo(
        () =>
            gvmsReferenceData?.ports?.reduce((map, value) => {
                map.set(value.portId, value.portDescription);
                return map;
            }, new Map()),
        [gvmsReferenceData?.ports]
    );

    const getFieldValue = (value: any, name?: string) => {
        if (!value) return '-';

        const momentDate = moment(value, moment.ISO_8601, true);
        if (momentDate.isValid() && momentDate.format() !== value && value.toString().length !== 4) {
            return moment(value).format('DD/MM/YY HH:mm');
        } else if (isBoolean(value)) {
            return value ? 'Yes' : 'No';
        } else if (isArray(value)) {
            return value.join(', ');
        } else if (
            toLower(name) === toLower(GvmsFormCustomFields.PORT_OF_ARRIVAL) ||
            toLower(name) === toLower(GvmsFormCustomFields.PORT_OF_DEPARTURE)
        ) {
            return portsMap?.get(+value);
        } else {
            return value;
        }
    };

    const getCardBody = (cardName: string, fields: ParsedField[] | undefined) => {
        const cardBaseName = cardName.replace(/\d+$/, '').trim();
        const isMic = (Object.values(GvmsFormMicCards) as string[]).includes(cardBaseName);

        return isMic ? (
            <>
                {cardName.includes('1') && <SectionTitle>{cardBaseName}</SectionTitle>}
                <SectionMicCard>
                    {fields?.map(({ name, message }, i) => (
                        <Fragment key={`${name}_${message}_${i}`}>
                            <SectionMicField>
                                <BoldText>{name}:</BoldText>
                                <MessageText>{getFieldValue(message)}</MessageText>
                            </SectionMicField>
                        </Fragment>
                    ))}
                </SectionMicCard>
                <StyledDivider />
            </>
        ) : (
            <>
                <SectionTitle>{cardName}</SectionTitle>
                {fields?.map(({ name, message }, i) => (
                    <Fragment key={`${name}_${message}_${i}`}>
                        <FieldContainer>
                            <BoldText>{name}</BoldText>
                            <MessageText>{getFieldValue(message, name)}</MessageText>
                        </FieldContainer>
                        {i !== fields.length - 1 && <StyledDivider />}
                    </Fragment>
                ))}
            </>
        );
    };

    return (
        <SectionModal>
            {parsedGmrRecord?.map(({ name, fields }, i) => (
                <SectionCard key={`${name}_${fields?.length}_${i}`}>{getCardBody(name, fields)}</SectionCard>
            ))}
        </SectionModal>
    );
};

const GvmsPreSubmissionFormCheckModal = ({
    onConfirm,
    onClose,
    ...modalProps
}: ModalProps & { onConfirm: () => Promise<boolean>; onClose: () => void }) => {
    const { hideGlobalOverlay, showGlobalOverlay } = useGlobalOverlay();
    const { declaration, submitDeclaration } = useDeclarations();
    const { declarationErrors } = useDeclarationFormErrors();

    const [parsedGmrRecord, setParsedGmrRecord] = useState<ParsedForm['masterDetails'] | undefined>(undefined);

    const transformParsedValues = ({ masterDetails }: ParsedForm) =>
        masterDetails.reduce((result: ParsedForm['masterDetails'], entry) => {
            if (entry.name === GvmsCardNames.MASTER_DETAILS || entry.name === GvmsCardNames.PLANNED_CROSSING) {
                const combinedCardIndex = result.findIndex(
                    (existingCard) => existingCard.name === 'Route & Vehicle Details'
                );

                entry.name = 'Route & Vehicle Details';
                entry.fields =
                    combinedCardIndex !== -1
                        ? [...(result[combinedCardIndex].fields as []), ...(entry.fields ?? [])]
                        : entry.fields;

                if (combinedCardIndex !== -1) {
                    result[combinedCardIndex] = entry;
                } else {
                    result.push(entry);
                }
            } else {
                result.push(entry);
            }

            return result;
        }, []);

    useEffect(() => {
        if (!declaration?.gvmsDeclaration) return;

        setParsedGmrRecord(transformParsedValues(ukGvmsRecordParser(declaration?.gvmsDeclaration)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declaration?.gvmsDeclaration]);

    const submitDeclarationRequest = async () => {
        if (!declaration?.id) return;

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Submitting record...' } });
        await submitDeclaration(declaration?.id, {});
        hideGlobalOverlay();
    };

    return (
        <SModal
            width={'75rem'}
            closable={false}
            destroyOnClose
            maskClosable={false}
            footer={
                <div>
                    <Button onClick={onClose}>Make Changes</Button>
                    <SubmitGmrButton
                        onClick={async (e) => {
                            const noErrors = await onConfirm?.();
                            if (!noErrors) return;
                            submitDeclarationRequest();
                            onClose?.();
                        }}
                        disabled={!!declarationErrors.masterDetails.length}
                    >
                        Submit GMR
                    </SubmitGmrButton>
                </div>
            }
            {...modalProps}
        >
            <GvmsPreSubmissionFormCheckModalHeader declarationErrors={declarationErrors} />
            <GvmsPreSubmissionFormCheckModalBody parsedGmrRecord={parsedGmrRecord} />
        </SModal>
    );
};

export default GvmsPreSubmissionFormCheckModal;
