import { H4Style } from '../../../../../../components/ui/base/typography/Typography.styles';
import useDeclarations from '../../../../../../hooks/useDeclarations';
import { Declaration } from '../../../../../../store/declarations/declaration';
import DeclarationInformation from '../../../../common/DeclarationInformation';
import PbnTicket from '../../../../ireland/pbn/components/PbnTicket';
import {
    DeclarationInformationWrapper,
    MainDiv,
} from '../../../../sections/customer-documents/CustomerDocuments.styles';
import UkGvmsTicket from './UkGvmsTicket';

export const barcodeDisabled = (declaration: Declaration | undefined): boolean => {
    if (declaration?.gvmsDeclaration) {
        return (
            !declaration?.gvmsDeclaration?.gmrId ||
            !declaration?.gvmsDeclaration?.gmrIdBarcode ||
            !declaration?.gvmsDeclaration?.gmrExpiredAt
        );
    } else if (declaration?.preBoardingNotification) {
        return !declaration?.preBoardingNotification?.barCode || !declaration?.preBoardingNotification?.pbnId;
    }

    return false;
};

const BarcodeView = () => {
    const { declaration } = useDeclarations();

    return (
        <>
            <DeclarationInformationWrapper>
                <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
                    <DeclarationInformation
                        options={
                            declaration?.gvmsDeclaration
                                ? {
                                      visible: {
                                          gmrId: Boolean(declaration?.gvmsDeclaration),
                                          status: Boolean(declaration?.status),
                                      },
                                  }
                                : declaration?.preBoardingNotification
                                ? {
                                      visible: {
                                          status: !!declaration?.preBoardingNotification?.status,
                                          pbnId: !!declaration?.preBoardingNotification?.pbnId,
                                          ref: true,
                                          jobId: true,
                                      },
                                  }
                                : undefined
                        }
                    />
                </div>
            </DeclarationInformationWrapper>
            <MainDiv>
                <H4Style style={{ marginBottom: '1.5rem' }}>
                    {!!declaration?.gvmsDeclaration
                        ? 'Goods Movement Reference Ticket'
                        : 'Pre-Boarding Notification Ticket'}
                </H4Style>
                {!!declaration?.gvmsDeclaration ? (
                    <UkGvmsTicket
                        gmrExpiredAt={declaration?.gvmsDeclaration?.gmrExpiredAt}
                        gmrId={declaration?.gvmsDeclaration?.gmrId}
                        gmrIdBarcode={declaration?.gvmsDeclaration?.gmrIdBarcode}
                    />
                ) : (
                    <PbnTicket
                        pbnId={declaration?.preBoardingNotification?.pbnId}
                        pbnBarcode={declaration?.preBoardingNotification?.barCode.split('data:image/png;base64, ')[1]}
                        pbnStatus={declaration?.preBoardingNotification?.status}
                    />
                )}
            </MainDiv>
        </>
    );
};

export default BarcodeView;
