import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import InvalidateModalButton from '../common/invalidate-modal/InvalidateModal';
import { disableSaveOrSubmitDeclarationButton } from '../utils/declaration-utils';
import { BtSubmit, FooterButton, FooterRow, SaveProductButton, Saving, SubmitDiv } from './Footer.styles';
import { useMatch } from 'react-router-dom';
import CancelDeclarationModalButton from '../common/CancelDeclarationModal';

interface Props {
    saveDraft: {
        trigger: Function;
        loading: boolean;
    };
    saveProduct: {
        trigger: Function;
        loading: boolean;
    };
    declarationStatus?: DeclarationStatus;
    submitDeclaration: {
        trigger: (data: { saveProduct: boolean }) => void;
    };
    cancelSaveProduct: Function;
    isSaving?: boolean;
    iconDeclarationSaved: boolean;
    disabled?: boolean;
}

const FormFooter: FC<Props> = ({
    isSaving,
    saveProduct,
    submitDeclaration,
    cancelSaveProduct,
    saveDraft,
    declarationStatus,
    iconDeclarationSaved,
    disabled = false,
}) => {
    const { setDeclarationFooterType, declarationFooter } = useDeclarationFooter();

    const { t } = useTranslation('common');
    const [renderSave, setRenderSave] = useState(isSaving);
    const { declaration, cancelCdsDeclaration } = useDeclarations();

    const inMasterDetails = useMatch('declarations/:declarationId');
    const inProductView = useMatch('declarations/:declarationId/products/:productId');

    useEffect(() => {
        if (inMasterDetails) setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
    }, [setDeclarationFooterType, inMasterDetails]);

    useEffect(() => {
        if (!isSaving) {
            setTimeout(() => {
                setRenderSave(isSaving);
            }, 2000);
        } else {
            setRenderSave(isSaving);
        }
    }, [isSaving]);

    const disabledSaveOrSubmit = useMemo(
        () => (declarationStatus ? disableSaveOrSubmitDeclarationButton(declarationStatus) : false),
        [declarationStatus]
    );

    const iconSavedInfo = useMemo(() => {
        if (iconDeclarationSaved === undefined) {
            return <></>;
        }

        return iconDeclarationSaved ? <CheckCircleOutlined /> : <EditOutlined />;
    }, [iconDeclarationSaved]);

    const footerTypeCondition: boolean = declarationFooter === DeclarationFooterType.PRODUCTS || Boolean(inProductView);

    const footerSubmitButton = useMemo(
        () =>
            !disabledSaveOrSubmit &&
            !disabled && (
                <BtSubmit
                    type="primary"
                    disabled={disabledSaveOrSubmit}
                    onClick={() => {
                        submitDeclaration.trigger({
                            saveProduct: footerTypeCondition,
                        });
                    }}
                >
                    {declaration?.gvmsDeclaration ? 'Submit GMR' : t('footer.submit')}
                </BtSubmit>
            ),
        [declaration?.gvmsDeclaration, disabled, disabledSaveOrSubmit, submitDeclaration, t, footerTypeCondition]
    );

    const footerButtons = useMemo(
        () =>
            footerTypeCondition ? (
                <Col>
                    {renderSave && <Saving>{t('footer.saving')}</Saving>}

                    {inProductView && (
                        <FooterButton onClick={() => cancelSaveProduct()}>{t('footer.backToProducts')}</FooterButton>
                    )}

                    {!!saveProduct && !disabled && (
                        <SaveProductButton
                            loading={saveProduct.loading}
                            disabled={disabledSaveOrSubmit}
                            onClick={() => {
                                saveProduct.trigger();
                            }}
                        >
                            {t('footer.saveProduct')}
                        </SaveProductButton>
                    )}

                    {!declaration?.preBoardingNotification && footerSubmitButton}
                </Col>
            ) : (
                <Col>
                    {renderSave && <Saving>{t('footer.saving')}</Saving>}

                    {!disabled && (
                        <CancelDeclarationModalButton
                            declaration={declaration}
                            cancelCdsDeclarationRequest={cancelCdsDeclaration}
                        />
                    )}

                    {!disabled && <InvalidateModalButton declaration={declaration} />}
                    {!disabledSaveOrSubmit && !disabled && (
                        <FooterButton
                            type="primary"
                            disabled={disabledSaveOrSubmit}
                            loading={saveDraft.loading}
                            onClick={() => {
                                saveDraft.trigger();
                            }}
                        >
                            {t(declaration?.status === 'DRAFT' ? 'footer.saveDraft' : 'footer.save')}
                            {iconSavedInfo}
                        </FooterButton>
                    )}
                    {!declaration?.preBoardingNotification && footerSubmitButton}
                </Col>
            ),
        [
            footerTypeCondition,
            renderSave,
            t,
            inProductView,
            saveProduct,
            disabled,
            disabledSaveOrSubmit,
            footerSubmitButton,
            declaration,
            cancelCdsDeclaration,
            saveDraft,
            iconSavedInfo,
            cancelSaveProduct,
        ]
    );

    return (
        <>
            <SubmitDiv>
                <FooterRow justify="end" align="middle" padding="1.2rem 2.4rem 1.2rem 2.4rem">
                    {footerButtons}
                </FooterRow>
            </SubmitDiv>
        </>
    );
};
export default FormFooter;
