import { DeclarationFieldName } from 'views/declarations/common/declarationType';
import addPathPrefix from '../../../../../../utils/addPathPrefix';
import { LMap } from './InvoiceModal';
import { Party } from 'views/declarations/common/parties/PartiesCard';

import { parseInvoiceResponse as IEH1Parser } from './parsers/IEH1InvoiceParser';
import { parseInvoiceResponse as IEH7Parser } from './parsers/IEH7InvoiceParser';
import { parseInvoiceResponse as IENCTSParser } from './parsers/IENCTSInvoiceParser';
import { ieH1PartiesData } from '../h1-skeleton-declaration';
import { IEH7_PartiesDataStructure } from '../../h7/ie-h7-data-structure';

export const SUGGESTED_TARICS_NUMERATION_REGEX = /\d+$/;

export const getSuggestedPath = (suggestedValuePath: string) => {
    const [_, entity] = suggestedValuePath.split('-');
    return entity;
};

export const shouldDisableProduct = (
    suggestedValuePath: string,
    selectedProductsActions: Record<string, string | undefined>
) => {
    const productPath = getSuggestedPath(suggestedValuePath)?.split('.')[0];

    const productActionIsNotSelected = !(productPath in selectedProductsActions);
    const productActionIsUndefined = selectedProductsActions[productPath] === undefined;

    if (productActionIsNotSelected || productActionIsUndefined) return true;
};

export const shouldDisableCustomer = (suggestedValuePath: string, selectedCustomers: Record<string, string[]>) => {
    const customerPath = getSuggestedPath(suggestedValuePath)?.split('.')[0];

    const customerIsNotSelected = !(customerPath in selectedCustomers);
    const customerHasNoSelectedParty = selectedCustomers[customerPath]?.length === 0;

    if (customerIsNotSelected || customerHasNoSelectedParty) return true;
};

export const getSelectedSuggestedTaric = (selectedSuggestedValuesMap: LMap, suggestedTaricPath: string) => {
    const selectedKeys = Array.from(selectedSuggestedValuesMap.keys());

    for (let i = 0; i < selectedKeys.length; i++) {
        if (selectedKeys[i].includes(suggestedTaricPath)) return selectedKeys[i];
    }
};

export const shouldDisableCommodityCode = (suggestedValuePath: string, selectedSuggestedValuesMap: LMap) => {
    const productIndex = suggestedValuePath.split('.')[0];

    const selectedCommodityCode = getSelectedSuggestedTaric(
        selectedSuggestedValuesMap,
        `${productIndex}.goodsInformation.combinedNomenclatureCode`
    );
    if (!selectedCommodityCode) return false;

    const selectedCommodityCodeIsNotCurrentOne = selectedCommodityCode !== suggestedValuePath;

    if (selectedCommodityCodeIsNotCurrentOne) return true;
    else return false;
};

export const shouldDisableTaricCode = (suggestedValuePath: string, selectedSuggestedValuesMap: LMap) => {
    const pairTypeIndex = suggestedValuePath.slice(-1);
    const pairProductIndex = suggestedValuePath.split('.')[0];

    const selectedCommodityCodePair = getSelectedSuggestedTaric(
        selectedSuggestedValuesMap,
        `${pairProductIndex}.goodsInformation.combinedNomenclatureCode${pairTypeIndex}`
    );

    if (!selectedCommodityCodePair) return true;
    else return false;
};

export const getPathPrefix = (path: string): [string, string] => {
    const [pathPrefix, ...fieldPaths] = path.split('.');
    const fieldPath = fieldPaths.join('.');

    return [pathPrefix, fieldPath];
};

export const getProductFieldPath = (
    suggestedValuePath: string,
    selectedProductsActions: Record<string, string | undefined>
): string => {
    let [suggestedValueProductPath, suggestedValueFieldPath] = getPathPrefix(suggestedValuePath);

    if (suggestedValueFieldPath.includes('combinedNomenclatureCode') || suggestedValueFieldPath.includes('taricCode'))
        suggestedValueFieldPath = suggestedValueFieldPath.replace(SUGGESTED_TARICS_NUMERATION_REGEX, '');

    const selectedProductAction = selectedProductsActions[suggestedValueProductPath];
    const selectedProductPath = selectedProductAction?.split(' ')[0];

    /**Only Update Action would be truthy*/
    if (selectedProductPath) return addPathPrefix(selectedProductPath, suggestedValueFieldPath);
    else return suggestedValuePath;
};

export const getCustomerFieldPath = (
    suggestedValuePath: string,
    selectedCustomers: Record<string, string[]>
): string => {
    const [suggestedValueCustomerPath, suggestedValueFieldPath] = getPathPrefix(suggestedValuePath);

    const customersToUpdate = selectedCustomers[suggestedValueCustomerPath];

    if (customersToUpdate?.length === 1) return addPathPrefix(customersToUpdate[0], suggestedValueFieldPath);
    else return suggestedValuePath;
};

export const getInvoiceConfig = (isFormType: any) => {
    const INVOICE_CONFIG: Record<
        Extract<
            DeclarationFieldName,
            DeclarationFieldName.IE_IMPORT_H1 | DeclarationFieldName.IE_IMPORT_H7 | DeclarationFieldName.IE_NCTS
        >,
        { parser: any; partiesDataStructure: Party[]; taricPaths: string[] }
    > = {
        [DeclarationFieldName.IE_IMPORT_H1]: {
            parser: IEH1Parser,
            partiesDataStructure: ieH1PartiesData,
            taricPaths: ['combinedNomenclatureCode', 'taricCode'],
        },
        [DeclarationFieldName.IE_IMPORT_H7]: {
            parser: IEH7Parser,
            partiesDataStructure: IEH7_PartiesDataStructure,
            taricPaths: ['commodityCodeHarmonizedSystemSubHeadingCode'],
        },
        [DeclarationFieldName.IE_NCTS]: {
            parser: IENCTSParser,
            partiesDataStructure: [
                {
                    path: 'representative',
                    header: 'Representative',
                    eoriRequired: true,
                    hasRepresentativeStatus: true,
                    refNumber: undefined,
                    hasAddress: false,
                    contactPerson: {
                        present: true,
                    },
                },
                {
                    path: 'holderOfTheTransitProcedure',
                    header: 'Holder Of The Transit Procedure',
                    refNumber: undefined,
                    contactPerson: {
                        present: true,
                    },
                },
                {
                    path: 'consignment.carrier',
                    header: 'Carrier',
                    refNumber: undefined,
                    hasAddress: false,
                    eoriRequired: true,
                    contactPerson: {
                        present: true,
                    },
                    hidden: isFormType(['D4']),
                },
                {
                    path: 'consignment.consignor',
                    header: 'Consignor',
                    refNumber: undefined,
                    contactPerson: {
                        present: true,
                    },
                    hidden: isFormType(['D2', 'D3', 'D4']),
                },
                {
                    path: 'consignment.consignee',
                    header: 'Consignee',
                    refNumber: undefined,
                    hidden: isFormType(['D4']),
                },
            ],
            taricPaths: [
                'commodity.commodityCode.harmonizedSystemSubHeadingCode',
                'commodity.commodityCode.combinedNomenclatureCode',
            ],
        },
    };

    return INVOICE_CONFIG;
};
