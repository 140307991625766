import { Col } from 'antd';
import { TextLarge } from 'components/ui/base/typography';
import { FC, useMemo } from 'react';
import { TaricCodeResponse, TaricMeasurement as Measurement } from 'store/taric-codes/taric-code';
import { TaricDetailsType } from '../../TaricCodesCard';
import { DetailsExportCol, DetailsHeading, DetailsRow } from '../TaricCode.styles';
import MeasurementInformation from './MeasurementInformation';
interface Props {
    importTaricMeasurement?: Measurement[];
    exportTaricMeasurement?: Measurement[];
    generalTaricMeasurement?: Measurement[];
    selectedCode: Pick<TaricCodeResponse, 'code' | 'description'>;
    detailsType: TaricDetailsType;
    selectedCommodityCode: (code: string, duty?: string, vat?: string) => void;
}
export const TaricMeasurement: FC<Props> = ({
    importTaricMeasurement,
    exportTaricMeasurement,
    generalTaricMeasurement,
    selectedCode,
    detailsType,
    selectedCommodityCode,
}) => {
    const handleContent = useMemo(() => {
        switch (detailsType) {
            case TaricDetailsType.BOTH:
                return (
                    <>
                        <Col span={24}>
                            <MeasurementInformation
                                selectedCommodityCode={selectedCommodityCode}
                                code={selectedCode.code}
                                title="Import"
                                taricMeasurement={importTaricMeasurement}
                            />
                        </Col>
                        <DetailsExportCol span={24}>
                            <MeasurementInformation
                                selectedCommodityCode={selectedCommodityCode}
                                code={selectedCode.code}
                                title="Export"
                                taricMeasurement={exportTaricMeasurement}
                            />
                        </DetailsExportCol>
                    </>
                );
            case TaricDetailsType.IMPORT:
                return (
                    <Col span={24}>
                        <MeasurementInformation
                            selectedCommodityCode={selectedCommodityCode}
                            code={selectedCode.code}
                            title="Import"
                            taricMeasurement={importTaricMeasurement}
                        />
                    </Col>
                );
            case TaricDetailsType.EXPORT:
                return (
                    <Col span={24}>
                        <MeasurementInformation
                            selectedCommodityCode={selectedCommodityCode}
                            code={selectedCode.code}
                            title="Export"
                            taricMeasurement={exportTaricMeasurement}
                        />
                    </Col>
                );
            case TaricDetailsType.GENERAL:
                return (
                    <Col span={24}>
                        <MeasurementInformation
                            selectedCommodityCode={selectedCommodityCode}
                            code={selectedCode.code}
                            title="General"
                            taricMeasurement={generalTaricMeasurement}
                        />
                    </Col>
                );
            default:
                return <></>;
        }
    }, [
        detailsType,
        selectedCommodityCode,
        selectedCode.code,
        importTaricMeasurement,
        exportTaricMeasurement,
        generalTaricMeasurement,
    ]);

    return (
        <DetailsHeading>
            <TextLarge>{selectedCode.description}</TextLarge>
            <DetailsRow>{handleContent}</DetailsRow>
        </DetailsHeading>
    );
};
export default TaricMeasurement;
