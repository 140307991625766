import { FormikProps, FormikProvider } from 'formik';
import { FC, useEffect } from 'react';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import DeclarationCheckbox from '../../../../components/ui/composed/declarations/formCheckbox/DeclarationCheckbox';
import styled from 'styled-components';
import moment from 'moment';
import { useAppSelector } from '../../../../config/hooks';
import UkGvmsRecordUtils, { getGvmsReferenceData } from './utils';
import { Customer } from '../../../../store/customers/customer';
import { GvmsCardNames, GvmsFormCustomFields, GvmsFormMicCards } from './enums';
import { Declaration } from '../../../../store/declarations/declaration';
import { useOutletContext } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_GVMS_REFERENCE_DATA } from '../../../../store/gvms-reference-data/reducer';
import GvmsCustomsDeclarationsDrawerButton from './components/card-drawers/GvmsCustomsDeclarationsDrawerButton';
import SearchCustomerDrawerButton from '../../../../components/ui/composed/searchCustomer/SearchCustomerDrawerButton';
import useHideEntities from '../../../../hooks/useHideEntities';
import { GVMSMessageTypes } from '../../../../store/declarations/enums/common/declaration-types';
import { isArray, omit, values, isEmpty } from 'lodash';
import { DefaultOptionType } from 'antd/lib/select';
import { GvmsReferenceData } from '../../../../store/declarations/uk/gvms-declaration';

const BoldText = styled.b`
    display: block;
    margin-bottom: 10px;
    color: rgb(0 0 0 / 78%);
`;

interface Props {
    formik: FormikProps<any>;
}

const UkGvmsForm: FC<Props> = ({ formik }) => {
    const { isFormType } = useHideEntities();
    const dispatch = useDispatch();
    const gvmsReferenceData = useAppSelector((state) => state.gvmsReferenceData.gvmsReferenceData);
    const { amendment } =
        useOutletContext<{
            amendment: boolean;
        }>() ?? {};

    useEffect(() => {
        if (gvmsReferenceData === undefined) {
            getGvmsReferenceData().then((gvmsReferenceData) => {
                const transformedRoutes = gvmsReferenceData?.routes.reduce(
                    (acc: GvmsReferenceData['transformedRoutes'], route) => {
                        const { routeDirection, carrierId, arrivalPortId, departurePortId, routeId } = route;

                        if (!acc[routeDirection]) {
                            acc[routeDirection] = {};
                        }

                        if (!acc[routeDirection][carrierId]) {
                            acc[routeDirection][carrierId] = {};
                        }

                        if (!acc[routeDirection][carrierId][arrivalPortId]) {
                            acc[routeDirection][carrierId][arrivalPortId] = {};
                        }

                        if (!acc[routeDirection][carrierId][arrivalPortId][departurePortId]) {
                            acc[routeDirection][carrierId][arrivalPortId][departurePortId] = routeId;
                        }

                        return acc;
                    },
                    {}
                );

                dispatch({
                    type: SET_GVMS_REFERENCE_DATA,
                    gvmsReferenceData: { ...gvmsReferenceData, transformedRoutes },
                });
            });
        }
    }, [gvmsReferenceData, dispatch]);

    const routeIdFormikProps = getFormikProps('plannedCrossing.routeId', formik);

    const handlePopulateRouteIdAndPortDescriptionFromEvent = (
        e: { target: { name: string; value: string } },
        option: DefaultOptionType | DefaultOptionType[]
    ) => {
        UkGvmsRecordUtils.handlePopulateRouteId(
            e.target,
            formik?.values,
            routeIdFormikProps,
            gvmsReferenceData?.routes
        );

        if (e.target.name.includes('departurePortId') || e.target.name.includes('arrivalPortId')) {
            if (isArray(option) || isArray(option?.children) || !option?.children) return;

            formik.setFieldValue(
                `${e.target.name.replace('Id', 'Description')}`,
                (option.children as string).split('- ')[1]
            );
        }
    };

    const carrierIdCodelist = gvmsReferenceData?.carriers.reduce((acc: any[], carrier) => {
        if (!formik?.values?.direction) return [];
        const availableCarrierIds = gvmsReferenceData?.transformedRoutes?.[formik?.values?.direction];
        if (availableCarrierIds && carrier.carrierId in availableCarrierIds) {
            acc.push({ id: JSON.stringify(carrier.carrierId), value: carrier.carrierName });
        }
        return acc;
    }, []);
    const arrivalPortIdCodelist = gvmsReferenceData?.ports.reduce((acc: any[], port) => {
        if (!formik?.values?.direction || !formik?.values?.gvmsRouteData?.carrierId) return [];
        const availableArrivalPortIds =
            gvmsReferenceData?.transformedRoutes?.[formik?.values?.direction]?.[
                formik.values?.gvmsRouteData?.carrierId
            ];
        if (availableArrivalPortIds && port.portId in availableArrivalPortIds) {
            acc.push({ id: JSON.stringify(port.portId), value: port.portDescription });
        }
        return acc;
    }, []);
    const departurePortIdCodelist = gvmsReferenceData?.ports.reduce((acc: any[], port) => {
        if (
            !formik?.values?.direction ||
            !formik?.values?.gvmsRouteData?.carrierId ||
            !formik?.values?.gvmsRouteData?.arrivalPortId
        )
            return [];
        const availableDeparturePortIds =
            gvmsReferenceData?.transformedRoutes?.[formik?.values?.direction]?.[
                formik?.values?.gvmsRouteData?.carrierId
            ]?.[formik?.values?.gvmsRouteData?.arrivalPortId];
        if (availableDeparturePortIds && port.portId in availableDeparturePortIds) {
            acc.push({ id: JSON.stringify(port.portId), value: port.portDescription });
        }
        return acc;
    }, []);

    return (
        <FormikProvider value={formik}>
            <NewFormCard title={GvmsCardNames.MASTER_DETAILS}>
                <FormCardContainer oneColumn>
                    <DeclarationSelect
                        label="Haulier Type"
                        {...getFormikProps('haulierType', formik)}
                        hideCodelistMenu
                        condensed
                        selectOptions={haulierTypeCodelists}
                        tooltip="The type of haulier moving the goods"
                    />
                    <DeclarationCheckbox
                        label="Unaccompanied?"
                        {...getFormikProps('isUnaccompanied', formik)}
                        condensed
                        tooltip="Set to true if the vehicle will not be accompanying the trailer(s) during the crossing, or if the vehicle is carrying a container that will be detached and loaded for the crossing."
                    />
                    <DeclarationInput
                        label="Vehicle Registration Number"
                        {...getFormikProps('vehicleRegNum', formik)}
                        condensed
                        tooltip="Vehicle registration number of the vehicle that will arrive at port. If isUnaccompanied is set to false then vehicleRegNum must be provided to check-in."
                    />
                    <MultipleDeclarationField name="trailerRegistrationNums">
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                label="Trailer Registration Number"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                tooltip="For vehicles carrying trailers, the trailer registration number of each trailer. If isUnaccompanied is set to true then trailerRegistrationNums or containerReferenceNums must be provided before check-in."
                            />
                        )}
                    </MultipleDeclarationField>
                    <MultipleDeclarationField name="containerReferenceNums">
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                label="Container Registration Number"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                tooltip="For vehicles arriving with containers that will be detached and loaded, the container reference number of each container in the movement. If isUnaccompanied is set to true then trailerRegistrationNums or containerReferenceNums must be provided before check-in."
                            />
                        )}
                    </MultipleDeclarationField>
                    <DeclarationInput
                        label="S&S MRN"
                        {...getFormikProps('sAndSMasterRefNum', formik)}
                        condensed
                        tooltip="The master reference number (MRN) for a Safety & Security declaration, where applicable."
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title={GvmsCardNames.PLANNED_CROSSING}>
                <BoldText>Details of the planned crossing.</BoldText>
                <FormCardContainer oneColumn>
                    <DeclarationSelect
                        label="Direction"
                        {...getFormikProps('direction', formik)}
                        required
                        disabled={amendment}
                        hideCodelistMenu
                        condensed
                        selectOptions={directionCodelists}
                        tooltip={
                            amendment
                                ? 'The direction of a GMR cannot be updated. If the direction is incorrect the GMR must be deleted and a new one created.'
                                : 'The direction of the movement - into or out of the UK, or between Great Britain and Northern Ireland'
                        }
                        onChange={handlePopulateRouteIdAndPortDescriptionFromEvent}
                    />

                    <DeclarationSelect
                        required
                        label={GvmsFormCustomFields.CARRIER}
                        {...getFormikProps('gvmsRouteData.carrierId', formik)}
                        condensed
                        tooltip="The id of the carrier operating that route."
                        selectOptions={carrierIdCodelist}
                        hideCodelistMenu
                        onChange={handlePopulateRouteIdAndPortDescriptionFromEvent}
                        disabled={isEmpty(carrierIdCodelist)}
                    />

                    <DeclarationSelect
                        required
                        label={GvmsFormCustomFields.PORT_OF_ARRIVAL}
                        {...getFormikProps('gvmsRouteData.arrivalPortId', formik)}
                        condensed
                        tooltip="The id of the arrival port."
                        selectOptions={arrivalPortIdCodelist}
                        hideCodelistMenu
                        onChange={handlePopulateRouteIdAndPortDescriptionFromEvent}
                        disabled={isEmpty(arrivalPortIdCodelist)}
                    />

                    <DeclarationSelect
                        required
                        label={GvmsFormCustomFields.PORT_OF_DEPARTURE}
                        {...getFormikProps('gvmsRouteData.departurePortId', formik)}
                        condensed
                        tooltip="The id of the departure port."
                        selectOptions={departurePortIdCodelist}
                        hideCodelistMenu
                        onChange={handlePopulateRouteIdAndPortDescriptionFromEvent}
                        disabled={isEmpty(departurePortIdCodelist)}
                    />

                    <DeclarationInput
                        required
                        label="Route ID"
                        {...routeIdFormikProps}
                        condensed
                        disabled
                        placeholder="Auto-generated value"
                        tooltip="The ID of the crossing route. It is generated based on the specified Port of Departure, Port of Arrival, Carrier and Direction."
                    />
                    <DeclarationDatepicker
                        showTime
                        label="Local Date & Time of Departure"
                        {...getFormikProps('plannedCrossing.localDateTimeOfDeparture', formik)}
                        condensed
                        tooltip="The planned date and time of departure, in local time of the departure port. Must not include seconds, time zone or UTC marker"
                        onChange={(date) => {
                            const fieldProps = getFormikProps(
                                'plannedCrossing.localDateTimeOfDeparture',
                                formik
                            ).fieldProps;
                            fieldProps.onChange({
                                target: {
                                    value: date === null ? null : moment(date).format('YYYY-MM-DDTHH:mm'),
                                    name: fieldProps.name,
                                },
                            });
                        }}
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title={GvmsFormMicCards.CUSTOMS_DECLARATIONS}
                path="customsDeclarations"
                initialValues={{
                    customsDeclarationId: '',
                    customsDeclarationPartId: '',
                    sAndSMasterRefNum: '',
                }}
                list={(list) => [
                    { field: 'Customs Declaration ID', value: list.customsDeclarationId },
                    { field: 'Customs Declaration Part ID', value: list.customsDeclarationPartId },
                    { field: 'S&S MRN', value: list.sAndSMasterRefNum },
                ]}
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.STANDARD_MOVEMENT_GMR)))}
                condensed
                ctaButtons={(micCardIndex) => [
                    <GvmsCustomsDeclarationsDrawerButton
                        onSelectDeclaration={(declaration: Declaration, onClose: () => void) => ({
                            onClick: () => {
                                const direction = formik?.values?.direction;
                                let value = null;

                                if (direction === 'GB_TO_NI' || direction === 'UK_OUTBOUND') {
                                    const prevDocWithDucr =
                                        declaration?.cdsDeclaration?.cdsDeclarationPayload?.goodsShipment?.previousDocument?.find(
                                            ({ typeCode }) => typeCode === 'DCR'
                                        );
                                    value = prevDocWithDucr?.id;
                                } else if (direction === 'NI_TO_GB' || direction === 'UK_INBOUND')
                                    value = declaration?.mrn;

                                formik.setFieldValue(`customsDeclarations.${micCardIndex}.customsDeclarationId`, value);
                                onClose();
                            },
                        })}
                    />,
                ]}
            >
                {(path) => (
                    <>
                        <BoldText>Details of the customs declarations within this goods load.</BoldText>
                        <FormCardContainer oneColumn>
                            <DeclarationInput
                                required
                                label="Customs Declaration ID"
                                {...getFormikProps(addPathPrefix(path, 'customsDeclarationId'), formik)}
                                condensed
                                tooltip="This is the identifier for a customs declaration from Customs Declaration Service (CDS) or CHIEF. For inbound movements declared in CDS it is a MRN, for example 19GB4S24GC3PPFGVR7. For inbound movements declared in CHIEF it is an ERN, for example 999123456C20210615. For outbound movements declared in either CDS or CHIEF it is a DUCR, for example 0GB689223596000-SE119404."
                            />
                            <DeclarationInput
                                label="Customs Declaration Part ID"
                                {...getFormikProps(addPathPrefix(path, 'customsDeclarationPartId'), formik)}
                                condensed
                                tooltip="Supported in Production environment after 5 September 2021. If a DUCR from CHIEF has been added in customsDeclarationId, this is the optional part ID. Must match the part ID for the declaration entered in CHIEF including any leading zeroes."
                            />
                            <DeclarationInput
                                label="S&S MRN"
                                {...getFormikProps(addPathPrefix(path, 'sAndSMasterRefNum'), formik)}
                                condensed
                                tooltip="The master reference number or Movement Reference Number (MRN) for a Safety & Security declaration, where applicable. This must be supplied when the S&S declaration covers individual consignments within a trailer. This applies to both ENS and EXS declarations. If there is one, include the S&S MRN for the customs declaration above here."
                            />
                        </FormCardContainer>
                    </>
                )}
            </MultipleItemsCard>

            <NewFormCard
                title="SAD Declarations"
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.STANDARD_MOVEMENT_GMR)))}
            >
                <BoldText>
                    Details of a stamped, paper-based declaration using a Single Administrative Document (SAD). Only
                    intended for use when technical issues prevent use of Transit IT systems.
                </BoldText>
                <FormCardContainer oneColumn>
                    <MultipleDeclarationField parent="sadDeclaration.sadGoods" name="sAndSMasterRefNum">
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                label="SAD Goods References"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                tooltip="The master reference number (MRN) for a Safety & Security declaration, where applicable."
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title={GvmsFormMicCards.TRANSIT_DECLARATIONS}
                path="transitDeclarations"
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.STANDARD_MOVEMENT_GMR)))}
                initialValues={{
                    transitDeclarationId: '',
                    isTSAD: '',
                    sAndSMasterRefNum: '',
                }}
                list={(list) => [
                    { field: 'Transit Declaration ID', value: list.transitDeclarationId },
                    { field: 'Is it TSAD?', value: list.isTSAD },
                    { field: 'S&S MRN', value: list.sAndSMasterRefNum },
                ]}
                condensed
            >
                {(path) => (
                    <>
                        <BoldText>Details of the transit declarations within this goods load.</BoldText>
                        <FormCardContainer oneColumn>
                            <DeclarationInput
                                label="Transit Declaration ID"
                                {...getFormikProps(addPathPrefix(path, 'transitDeclarationId'), formik)}
                                required
                                condensed
                                tooltip="This is the Transit MRN issued by the Office of Departure. Must be provided."
                            />
                            <DeclarationCheckbox
                                required
                                label="Is it TSAD?"
                                {...getFormikProps(addPathPrefix(path, 'isTSAD'), formik)}
                                condensed
                                tooltip="Set to true if the transit declaration is a TSAD, otherwise set to false (for a TAD)"
                            />
                            <DeclarationInput
                                label="S&S MRN"
                                {...getFormikProps(addPathPrefix(path, 'sAndSMasterRefNum'), formik)}
                                condensed
                                tooltip="The master reference number or Movement Reference Number (MRN) for a Safety & Security declaration, where applicable. This must be supplied when the S&S declaration covers individual Transit consignments within a trailer. This applies to both ENS and EXS declarations. If there is one, include the S&S MRN for the Transit declaration above here."
                            />
                        </FormCardContainer>
                    </>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={GvmsFormMicCards.TIR_CARNET_DECLARATIONS}
                path="tirDeclarations"
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.TIR_MOVEMENT_GMR)))}
                initialValues={{
                    tirCarnetId: '',
                    sAndSMasterRefNum: '',
                }}
                list={(list) => [
                    { field: 'TIR Carnet ID', value: list.tirCarnetId },
                    { field: 'S&S MRN', value: list.sAndSMasterRefNum },
                ]}
                condensed
            >
                {(path) => (
                    <>
                        <BoldText>
                            Details of one or more TIR Carnet declarations. Must be provided prior to check-in for TIR
                            movements.
                        </BoldText>
                        <FormCardContainer oneColumn>
                            <DeclarationInput
                                label="TIR Carnet ID"
                                {...getFormikProps(addPathPrefix(path, 'tirCarnetId'), formik)}
                                required
                                condensed
                                tooltip="The TIR Carnet ID for the carnet within a TIR goods movement"
                            />
                            <DeclarationInput
                                label="S&S MRN"
                                {...getFormikProps(addPathPrefix(path, 'sAndSMasterRefNum'), formik)}
                                condensed
                                tooltip="The master reference number or Movement Reference Number (MRN) for a Safety & Security declaration, where applicable. This must be supplied when the S&S declaration covers TIR Carnet consignments within a trailer. This applies to both ENS and EXS declarations. If there is one, include the S&S MRN for the TIR declaration above here."
                            />
                        </FormCardContainer>
                    </>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={GvmsFormMicCards.ATA_CARNET_DECLARATIONS}
                path="ataDeclarations"
                hidden={isFormType(
                    values(omit(GVMSMessageTypes, GVMSMessageTypes.UNACCOMPANIED_ATA_CARNET_CONTAINER_MOVEMENT))
                )}
                initialValues={{
                    ataCarnetId: '',
                    sAndSMasterRefNum: '',
                }}
                list={(list) => [
                    { field: 'ATA Carnet ID', value: list.ataCarnetId },
                    { field: 'S&S MRN', value: list.sAndSMasterRefNum },
                ]}
                condensed
            >
                {(path) => (
                    <>
                        <BoldText>
                            Details of one or more ATA Carnet declarations. Must be provided prior to check-in for ATA
                            movements.
                        </BoldText>
                        <FormCardContainer oneColumn>
                            <DeclarationInput
                                label="ATA Carnet ID"
                                {...getFormikProps(addPathPrefix(path, 'ataCarnetId'), formik)}
                                required
                                condensed
                                tooltip="The ATA Carnet ID for the carnet within an ATA goods movement"
                            />
                            <DeclarationInput
                                label="S&S MRN"
                                {...getFormikProps(addPathPrefix(path, 'sAndSMasterRefNum'), formik)}
                                condensed
                                tooltip="The master reference number or Movement Reference Number (MRN) for a Safety & Security declaration, where applicable. This must be supplied when the S&S declaration covers ATA Carnet consignments within a trailer. This applies to both ENS and EXS declarations. If there is one, include the S&S MRN for the TIR declaration above here."
                            />
                        </FormCardContainer>
                    </>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={GvmsFormMicCards.EIDR_DECLARATIONS}
                path="eidrDeclarations"
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.STANDARD_MOVEMENT_GMR)))}
                initialValues={{
                    traderEORI: '',
                    sAndSMasterRefNum: '',
                }}
                list={(list) => [
                    { field: 'EORI', value: list.traderEORI },
                    { field: 'S&S MRN', value: list.sAndSMasterRefNum },
                ]}
                condensed
                ctaButtons={(micCardIndex) => [
                    <SearchCustomerDrawerButton
                        size="small"
                        onSelectCustomer={({ eori }: Customer, onClose: () => void) => {
                            formik.setFieldValue(`eidrDeclarations.${micCardIndex}.traderEORI`, eori);
                            onClose();
                        }}
                    />,
                ]}
            >
                {(path) => (
                    <>
                        <BoldText>
                            Details of one or more EIDR declarations. Must be provided prior to check-in for EIDR
                            movements. For movements using a specially-issued EORI, the same EORI may be entered
                            multiple times, one entry for each ENS S&S MRN travelling under EIDR.
                        </BoldText>
                        <FormCardContainer oneColumn>
                            <DeclarationInput
                                label="EORI"
                                {...getFormikProps(addPathPrefix(path, 'traderEORI'), formik)}
                                required
                                condensed
                                tooltip="The trader's EORI"
                            />
                            <DeclarationInput
                                label="S&S MRN"
                                {...getFormikProps(addPathPrefix(path, 'sAndSMasterRefNum'), formik)}
                                condensed
                                tooltip="The master reference number or Movement Reference Number (MRN) for a Safety & Security declaration, where applicable. This must be supplied when the S&S declaration covers individual EIDR consignments within a trailer. This applies to both ENS and EXS declarations. If there is one, include the S&S MRN for the EIDR declaration above here."
                            />
                        </FormCardContainer>
                    </>
                )}
            </MultipleItemsCard>

            <NewFormCard
                title="Indirect Export Declarations"
                hidden={isFormType(
                    values(omit(GVMSMessageTypes, GVMSMessageTypes.INDIRECT_EXPORT_DECLARATIONS_MOVEMENT_GMR))
                )}
            >
                <BoldText>
                    Details of the indirect export declarations within this goods load. Only valid for NI to GB
                    movements where the goods originated from outside UK.
                </BoldText>
                <FormCardContainer oneColumn>
                    <MultipleDeclarationField parent="indirectExportDeclarations" name="eadMasterRefNum">
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                label="MRN"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                tooltip="The master or movement reference number (MRN) of the Export Accompanying Document (EAD) for this indirect export."
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard
                title="Empty Vehicle"
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.EMPTY_MOVEMENT_GMR)))}
            >
                <BoldText>
                    Declares that this is an empty movement and contains details about the empty movement. Must be
                    provided prior to check-in for empty movements.
                </BoldText>
                <FormCardContainer oneColumn>
                    <DeclarationCheckbox
                        label="Own Vehicle?"
                        {...getFormikProps('emptyVehicle.isOwnVehicle', formik)}
                        condensed
                        tooltip="Set to false if the haulier is moving the goods vehicle under a contract of carriage. Set to true if there is no contract of carriage."
                    />
                    <DeclarationInput
                        label="S&S MRN"
                        {...getFormikProps('emptyVehicle.sAndSMasterRefNum', formik)}
                        condensed
                        tooltip="The master reference number or Movement Reference Number (MRN) for a Safety & Security declaration, where applicable. This must be supplied when the S&S declaration covers a trailer that is moving under a contract of carriage. This applies to both ENS and EXS declarations."
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard
                title="Declarations by Conduct"
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.ORAL_OR_BY_CONDUCT_DECLARATION)))}
            >
                <BoldText>
                    Declares goods moving under Declaration by Conduct. Refer to the Oral and By Conduct List published
                    at{' '}
                    <a
                        href="https://www.gov.uk/government/publications/list-of-goods-applicable-to-oral-and-by-conduct-declarations"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        gov.uk
                    </a>{' '}
                    for details.
                </BoldText>
                <FormCardContainer oneColumn>
                    <DeclarationCheckbox
                        label="Own Vehicle?"
                        {...getFormikProps('dbcDeclaration.isOwnVehicle', formik)}
                        condensed
                        tooltip="Set to false if the haulier is moving the goods vehicle under a contract of carriage. Set to true if there is no contract of carriage."
                    />
                    <MultipleDeclarationField parent="dbcDeclaration.dbcGoods" name="sAndSMasterRefNum">
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                label="SAD Goods References"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                tooltip="The master reference number or Movement Reference Number (MRN) for a Safety & Security declaration, where applicable. This may be required when the S&S declaration covers a DBC that is moving under a contract of carriage. This applies to both ENS and EXS declarations."
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard
                title="Exemption Declarations"
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.EXEMPTION_MOVEMENT_RECORD)))}
            >
                <BoldText>
                    Declares Royal Mail Group or ETOE goods in the movement that do not require a customs declaration.
                </BoldText>
                <FormCardContainer oneColumn>
                    <MultipleDeclarationField parent="exemptionDeclaration.exemptedGoods" name="sAndSMasterRefNum">
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                label="Exempted Goods"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                tooltip="The MRN or DUCR (depending on direction) for an ENS or EXS Safety & Security declaration for this goods load."
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard
                title="UKC Declaration"
                hidden={isFormType(values(omit(GVMSMessageTypes, GVMSMessageTypes.UKC)))}
            >
                <BoldText>
                    Details of one UKC declaration. Must be provided prior to check-in for UKC movements.
                </BoldText>
                <FormCardContainer>
                    <DeclarationInput
                        label="EORI"
                        {...getFormikProps('ukcDeclaration.fpoEORI', formik)}
                        condensed
                        tooltip="FPO EORI"
                    />
                    <DeclarationInput
                        label="S&S MRN"
                        {...getFormikProps('ukcDeclaration.sAndSMasterRefNum', formik)}
                        condensed
                        tooltip="The master reference number or Movement Reference Number (MRN) for a Safety & Security declaration, where applicable. This must be supplied when the S&S declaration covers a trailer that is moving under a contract of carriage. This applies to both ENS and EXS declarations."
                    />
                </FormCardContainer>
            </NewFormCard>
        </FormikProvider>
    );
};

export const directionCodelists = [
    { id: 'UK_INBOUND', value: 'The movement is travelling into the UK' },
    { id: 'UK_OUTBOUND', value: 'The movement is travelling out of the UK' },
    {
        id: 'GB_TO_NI',
        value: 'The movement is travelling out of Great Britain into Northern Ireland',
    },
    {
        id: 'NI_TO_GB',
        value: 'The movement is travelling out of Northern Ireland into Great Britain',
    },
];

export const haulierTypeCodelists = [
    { id: 'STANDARD', value: 'All hauliers that are not one of the other types' },
    {
        id: 'FPO_ASN',
        value: 'Fast parcel operators that are members of the Anti-Smuggling Network',
    },
    {
        id: 'FPO_OTHER',
        value: 'Fast parcel operators that are not members of the ASN and are not moving goods with a Memorandum of Understanding',
    },
    { id: 'NATO_MOD', value: 'NATO or MoD' },
    { id: 'RMG', value: 'Royal Mail Group' },
    { id: 'ETOE', value: 'Extra-Territorial Office of Exchange' },
];

export default UkGvmsForm;
