import { Col, Row } from 'antd';
import SearchBar from 'components/ui/base/searchbar';
import OptionTooltip from 'components/ui/base/searchbar/search-options/OptionTooltip';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from 'store/customers/customer';
import CustomerDetails from './CustomerDetails';
import { CustomDiv, CustomRow, InvoiceButton, ReverseCol, SectionTitle } from './InvoiceUpload.styles';

interface Props {
    disabled: boolean;
    customer?: Customer;
    customers: Customer[];
    setShowCreateCustomer: Function;
    handleSelectCustomer: Function;
    setCustomer: Function;
    search: (query: string) => void;
}
const SelectCustomer: FC<Props> = ({
    setShowCreateCustomer,
    customer,
    customers,
    handleSelectCustomer,
    setCustomer,
    search,
    disabled,
}) => {
    const { t } = useTranslation('customs_declarations');

    const disableSelect = useMemo(() => disabled, [disabled]);
    const customerOptions = useMemo(() => {
        const options = customers.map((customer) => ({
            value: customer.id,
            label: (
                <Row justify="space-between" align="middle" wrap={false} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col>
                        <OptionTooltip data={customer.eori || (customer as any).identificationNumber} />
                    </Col>
                    <Col>
                        <OptionTooltip data={customer.name} />
                    </Col>
                    <Col>
                        <OptionTooltip data={customer.contact?.email} />
                    </Col>
                    <Col>
                        <OptionTooltip data={customer.contact?.phoneNumber} />
                    </Col>
                </Row>
            ),
        }));

        return [
            {
                label: <span>Customers</span>,
                options: options,
            },
        ];
    }, [customers]);

    return (
        <>
            <CustomRow>
                <Col span={16}>
                    <SectionTitle className={`${disableSelect && 'disabled'}`}>
                        {t('customer.searchOrCreate')}
                    </SectionTitle>
                </Col>
                <ReverseCol span={8}>
                    <InvoiceButton
                        disabled={disableSelect}
                        size="large"
                        onClick={() => {
                            setShowCreateCustomer(true);
                        }}
                    >
                        {t('customer.create')}
                    </InvoiceButton>
                </ReverseCol>

                <CustomDiv>
                    {!customer ? (
                        <>
                            {!disabled && (
                                <SearchBar
                                    disabled={disableSelect}
                                    options={customerOptions}
                                    onSelect={(value: string) => {
                                        handleSelectCustomer(value);
                                    }}
                                    onClear={() => {
                                        setCustomer(undefined);
                                    }}
                                    inputPlaceholder={t(`SearchCustomer`)}
                                    onSearch={(e) => search(e)}
                                />
                            )}
                        </>
                    ) : (
                        <CustomerDetails disabled={disableSelect} customer={customer} setCustomer={setCustomer} />
                    )}
                </CustomDiv>
            </CustomRow>
        </>
    );
};

export default SelectCustomer;
