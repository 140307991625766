import { ModalStaticFunctions } from 'antd/lib/modal/confirm';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface Interaction {
    vatRate: number;
    commodityNationalCode: string;
}

const Paragraph = styled.p`
    margin-bottom: 0.2rem;
`;

const interactions: Interaction[] = [
    { vatRate: 23, commodityNationalCode: 'VATS' },
    { vatRate: 0, commodityNationalCode: 'VATZ' },
];

const showConfirmModal = (
    modal: Omit<ModalStaticFunctions, 'warn'>,
    args: { title: string; content?: ReactNode[]; onOk: () => void; onCancel?: () => void }
) => {
    modal.confirm({
        title: args.title,
        cancelText: 'No',
        okText: 'Yes',
        content: (
            <>
                {args.content?.map((c) => (
                    <Paragraph>{c}</Paragraph>
                ))}
            </>
        ),
        cancelButtonProps: { 'data-testid': 'cancel-button' } as any,
        onCancel: args.onCancel ? args.onCancel : () => {},
        okButtonProps: { 'data-testid': 'ok-button' } as any,
        onOk: () => {
            args.onOk();
        },
    });
};

export const handleVatRateChange = (
    modal: Omit<ModalStaticFunctions, 'warn'>,
    currentCommodityNationalCodes: (string | null)[],
    newVatRate: string | null,
    onUpdate: (newVatRate: (string | null)[] | null) => void
) => {
    if (newVatRate === null) return;
    const copyOfCommodityNationalCodes = [...currentCommodityNationalCodes];

    // Finds intersection between currentCommodityNationalCodes and interactions
    const codesToRemove = currentCommodityNationalCodes.filter((code) => {
        return interactions.some((interaction) => {
            return interaction.vatRate.toString() !== newVatRate && interaction.commodityNationalCode === code;
        });
    });

    const codeToAdd = interactions.find(
        (interaction) =>
            interaction.vatRate.toString() === newVatRate &&
            !currentCommodityNationalCodes.includes(interaction.commodityNationalCode)
    );

    const updateFormValue = () => {
        onUpdate(copyOfCommodityNationalCodes);
    };

    if (codeToAdd || codesToRemove.length) {
        showConfirmModal(modal, {
            title: `Commodity National Codes change`,
            content: [
                ...(codeToAdd
                    ? [
                          <span data-testid="commodity-national-codes-suggestion-add-text">
                              Add:{' '}
                              <b data-testid="commodity-national-codes-suggestion-add-text-codes">
                                  {codeToAdd?.commodityNationalCode}
                              </b>
                          </span>,
                      ]
                    : []),
                ...(codesToRemove.length
                    ? [
                          <span data-testid="commodity-national-codes-suggestion-remove-text">
                              Remove:{' '}
                              <b data-testid="commodity-national-codes-suggestion-remove-text-codes">
                                  {codesToRemove.join(', ')}
                              </b>
                          </span>,
                      ]
                    : []),
                `Do you want to apply these changes?`,
            ],
            onCancel: () => {
                updateFormValue();
            },
            onOk: () => {
                if (codesToRemove.length) {
                    for (const codeToRemove of codesToRemove)
                        copyOfCommodityNationalCodes.splice(copyOfCommodityNationalCodes.indexOf(codeToRemove), 1);
                }

                if (codeToAdd) {
                    if (copyOfCommodityNationalCodes.length === 1 && copyOfCommodityNationalCodes[0] === null) {
                        copyOfCommodityNationalCodes[0] = codeToAdd.commodityNationalCode;
                    } else {
                        copyOfCommodityNationalCodes.push(codeToAdd.commodityNationalCode);
                    }
                }

                updateFormValue();
            },
        });
    }
};

export const handleCommodityCodeChange = (
    modal: Omit<ModalStaticFunctions, 'warn'>,
    currentVatRate: number | null,
    newCommodityCode: string,
    onUpdate: (newVatRate: number | null) => void
) => {
    const interaction = interactions.find(({ commodityNationalCode }) => commodityNationalCode === newCommodityCode);
    if (interaction && currentVatRate !== interaction.vatRate) {
        showConfirmModal(modal, {
            title: 'Vat Rate change',
            content: [
                <span data-testid="vat-rate-suggestion-text">
                    This commodity national code enforces vat rate of{' '}
                    <b data-testid="vat-rate-codes-suggestion-text-rate">{interaction.vatRate}</b>.
                </span>,
                `Do you want us to update it for you?`,
            ],
            onCancel: () => {
                onUpdate(currentVatRate);
            },
            onOk: () => {
                onUpdate(interaction.vatRate);
            },
        });
    }
};

export const removeZeroDecimals = (value: string | null) => {
    if (!value) return null;

    const decimals = value.split('.');
    if (!decimals?.[1]) return value;

    const decimalIsZero = /^0+$/.test(decimals?.[1]);
    if (!decimalIsZero) return value;

    const removedZeroDecimals = Math.trunc(+value);
    return removedZeroDecimals.toString();
};
