import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { DeclarationDocuments as CmsFile } from 'store/declarations/declaration';
import { deleteFileCms, downloadFile as getDocumentPreview } from 'store/documents/client';
import { StyledDeleteOutlined, StyledEditOutlined, StyledEyeOutlined, Container } from './../CustomerDocuments.styles';
import { useTranslation } from 'react-i18next';
import { H5Style } from '../../../../../components/ui/base/typography/Typography.styles';
import { InfoText, InfoTitle, InfoWrapper } from '../../../common/DeclarationInformation';
import ACheckbox from '../../../../../components/ui/base/checkbox/Checkbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { HollowButton } from '../../../common/box44/Box44';
import {
    deleteDocument,
    submitDocumentsAes,
    submitDocumentsAis,
    submitDocumentsUk,
    submitDocumentsNcts,
} from '../../../../../store/file-upload/client';
import { DeclarationDocument, ReducedCmsFiles } from '../types';
import { Tooltip } from 'antd';
import useCodelists from '../../../../../hooks/useCodelists';
import { format } from 'date-fns';
import { message } from 'antd';
import { DeclarationCountry } from '../../../../../store/declarations/enums/common/declaration-country';
import useFormUtils from '../../../../../hooks/useFormUtils';

interface Props {
    declarationId?: string;
    listCmsFiles?: () => void;
    setCmsFileAndFormAction?: Function;
    cmsFilesList?: CmsFile[] | undefined;
    declarationDocuments?: DeclarationDocument[] | undefined;
    deleteDeclarationDocument?: (newDoc: DeclarationDocument) => void;
    submitDeclarationDocuments?: (arg: string[]) => void;
}

const DocumentsTable: FC<Props> = ({
    declarationId,
    listCmsFiles,
    cmsFilesList,
    setCmsFileAndFormAction,
    declarationDocuments,
    deleteDeclarationDocument,
    submitDeclarationDocuments,
}) => {
    const { t } = useTranslation('form');
    const { aisCodelists, aesCodelists, nctsCodelists } = useCodelists();
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
    const { isAes, isAis, isNcts } = useFormUtils();

    const { countryLowerCase: country } = useFormUtils();

    const getBox44ValuesMap = useMemo(() => {
        const documentTypeCodelists =
            isAes && aesCodelists
                ? [
                      ...aesCodelists.transportDocumentType,
                      ...aesCodelists.supportingDocumentType,
                      ...aesCodelists.previousDocumentType,
                  ]
                : isNcts && nctsCodelists
                ? [
                      ...(nctsCodelists.previousDocumentType ?? []),
                      ...(nctsCodelists.supportingDocumentType ?? []),
                      ...(nctsCodelists.transportDocumentType ?? []),
                  ]
                : aisCodelists?.commonDocumentsType;

        return documentTypeCodelists?.reduce((map, value) => {
            map.set(value.id, value.value);
            return map;
        }, new Map());
    }, [isAes, isNcts, aisCodelists?.commonDocumentsType, aesCodelists, nctsCodelists]);

    const getTypeDescription = useCallback(
        (cmsFileType: string | undefined) => {
            const typeDescription = getBox44ValuesMap?.get(cmsFileType);

            if (!typeDescription) return undefined;

            return typeDescription;
        },
        [getBox44ValuesMap]
    );

    const handleSubmitButton = useCallback(async () => {
        if (!selectedCheckboxes.length) return message.error('Please select at least one file for submission.');

        if (selectedCheckboxes.length > 10 && country === DeclarationCountry.UK) {
            return message.error(`Can't submit more than 10 documents at once.`);
        }

        message.loading('Submitting documents.');

        if (country === DeclarationCountry.IRELAND) {
            const submitDocument = isAes ? submitDocumentsAes : isAis ? submitDocumentsAis : submitDocumentsNcts;
            await submitDocument({ documentIds: selectedCheckboxes }, declarationId);
        } else if (country === DeclarationCountry.UK) {
            await submitDocumentsUk({ documentIds: selectedCheckboxes }, declarationId);
        }
        submitDeclarationDocuments?.(selectedCheckboxes);
    }, [declarationId, selectedCheckboxes, submitDeclarationDocuments, country, isAes, isAis]);

    const groupedCmsFiles = useMemo<ReducedCmsFiles | undefined>(() => {
        const cmsFiles: ReducedCmsFiles = {
            uploadedFiles: [],
            submittedFiles: [],
        };

        const doesCorrespondToDeclarationDocument = (cmsFileId: string, statusOfDocuments: string) =>
            declarationDocuments?.some(
                (document) => document.fileId === cmsFileId && document.status === statusOfDocuments
            );

        return cmsFilesList?.reduce((reducedCmsFiles, cmsFile) => {
            if (doesCorrespondToDeclarationDocument(cmsFile.id, 'UPLOADED')) {
                cmsFiles.uploadedFiles.push(cmsFile);
            } else if (doesCorrespondToDeclarationDocument(cmsFile.id, 'SUBMITTED')) {
                cmsFiles.submittedFiles.push(cmsFile);
            }

            return reducedCmsFiles;
        }, cmsFiles);
    }, [declarationDocuments, cmsFilesList]);

    const getDeclarationDocumentBasedOnCmsFileId = useCallback(
        (cmsFileId: string) => declarationDocuments?.filter((doc) => doc.fileId === cmsFileId)?.[0],
        [declarationDocuments]
    );

    const handleActions = useCallback(
        (cmsFile: CmsFile, inSubmittedDocs: boolean) => {
            const previewOfDocumentBtn = (
                <TooltipComponent title={'Preview'}>
                    <StyledEyeOutlined onClick={() => viewDocumentAction(cmsFile)} />
                </TooltipComponent>
            );

            if (!inSubmittedDocs) {
                return (
                    <>
                        {previewOfDocumentBtn}

                        {country === DeclarationCountry.IRELAND && (
                            <TooltipComponent title={'Edit'}>
                                <StyledEditOutlined
                                    onClick={() => setCmsFileAndFormAction?.({ file: cmsFile, action: 'edit' })}
                                />
                            </TooltipComponent>
                        )}

                        <TooltipComponent title={'Delete'}>
                            <StyledDeleteOutlined
                                onClick={async () => {
                                    const declarationDoc = getDeclarationDocumentBasedOnCmsFileId(cmsFile.id);

                                    if (!(declarationDoc && country))
                                        return message.error('Unexpected error has occurred.');
                                    await deleteDocument(declarationId, declarationDoc.id);

                                    await deleteFileCms(cmsFile.id);
                                    deleteDeclarationDocument?.(declarationDoc);

                                    message.success('File deleted successfully.');
                                    listCmsFiles?.();
                                }}
                            />
                        </TooltipComponent>
                    </>
                );
            }

            return previewOfDocumentBtn;
        },
        [
            country,
            declarationId,
            deleteDeclarationDocument,
            getDeclarationDocumentBasedOnCmsFileId,
            listCmsFiles,
            setCmsFileAndFormAction,
        ]
    );

    const cmsFileRows = useCallback(
        (file: CmsFile, options: { inSubmittedDocs: boolean }) => (
            <Container inSubmittedDocs={options.inSubmittedDocs} key={file.id}>
                {!options.inSubmittedDocs && (
                    <ACheckbox
                        name={`${file.id}`}
                        onChange={(e: CheckboxChangeEvent) => {
                            if (!e.target.checked) {
                                return setSelectedCheckboxes((checkboxesNames: string[]) =>
                                    checkboxesNames.filter((checkboxName: string) => checkboxName !== e.target.name)
                                );
                            }

                            setSelectedCheckboxes((checkboxesNames: any) => [e.target.name, ...checkboxesNames]);
                        }}
                    />
                )}
                <InfoWrapper>
                    <InfoTitle>File Name:</InfoTitle>
                    <TooltipComponent title={file.filename} titlePrefix={'File name: '}>
                        <InfoText>{file.filename}</InfoText>
                    </TooltipComponent>
                </InfoWrapper>
                <InfoWrapper>
                    <InfoTitle>Type:</InfoTitle>
                    <TooltipComponent title={getTypeDescription(file.documentType)} titlePrefix={'Type: '}>
                        <InfoText>{file.documentType ?? file.contentType}</InfoText>
                    </TooltipComponent>
                </InfoWrapper>
                <InfoWrapper>
                    <InfoTitle>Identifier:</InfoTitle>
                    <InfoText>
                        {getDeclarationDocumentBasedOnCmsFileId(file.id)?.fileInfo?.documentIdentifier ?? file.id}
                    </InfoText>
                </InfoWrapper>
                <InfoWrapper>
                    <InfoTitle>Size:</InfoTitle>
                    <InfoText>{formatBytes(file.fileSize)}</InfoText>
                </InfoWrapper>
                <InfoWrapper>
                    <InfoTitle>Uploaded:</InfoTitle>
                    {file.createdAt ? <InfoText>{format(new Date(file.createdAt), 'dd/MM/yyyy')}</InfoText> : '-'}
                </InfoWrapper>
                <InfoWrapper>{handleActions(file, options.inSubmittedDocs)}</InfoWrapper>
            </Container>
        ),
        [getDeclarationDocumentBasedOnCmsFileId, getTypeDescription, handleActions]
    );

    return (
        <>
            <H5Style style={{ marginBottom: '1rem' }}>{t('customerDocuments.uploaded_documents')}</H5Style>
            {groupedCmsFiles?.uploadedFiles?.map((file) => cmsFileRows(file, { inSubmittedDocs: false }))}

            <>
                {!!groupedCmsFiles?.submittedFiles?.length && (
                    <>
                        <H5Style style={{ marginBottom: '1rem' }}>{t('customerDocuments.submitted_documents')}</H5Style>
                        {groupedCmsFiles?.submittedFiles?.map((submittedFile) =>
                            cmsFileRows(submittedFile, { inSubmittedDocs: true })
                        )}
                    </>
                )}

                {!!groupedCmsFiles?.uploadedFiles?.length && (
                    <HollowButton
                        style={{ display: 'block', marginLeft: 'auto', fontWeight: 'bold' }}
                        onClick={() => handleSubmitButton()}
                    >
                        Submit
                    </HollowButton>
                )}
            </>
        </>
    );
};
export default DocumentsTable;

export const viewDocumentAction = async (cmsFile: CmsFile | undefined) => {
    if (!cmsFile) return message.error('Unexpected error has occurred.');

    const documentPreview = await getDocumentPreview(cmsFile.id);

    if (!documentPreview.preSignedUrl) return message.error('Unexpected error has occurred.');

    window.open(documentPreview.preSignedUrl);
};

const TooltipComponent = ({
    children,
    title,
    titlePrefix,
}: {
    children: ReactNode;
    title: ReactNode;
    titlePrefix?: string;
}) => {
    if (!title) return <>{children}</>;

    const fullTitle = (
        <>
            {titlePrefix && <b>{titlePrefix}</b>}
            {title}
        </>
    );

    return (
        <Tooltip
            title={fullTitle}
            color={'white'}
            overlayInnerStyle={{
                color: 'black',
                whiteSpace: 'nowrap',
                width: 'fit-content',
                paddingLeft: '50px',
                paddingRight: '50px',
            }}
        >
            {children}
        </Tooltip>
    );
};

function formatBytes(bytes: number | undefined) {
    if (!bytes) return '- KB';

    return `${(bytes * 1000).toString().slice(0, bytes.toString().length - 3)} KB`;
}
