import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, getFullCodelistValue, handleToggleHelp } from 'views/declarations/utils/form-utils';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import useSearchCustomer from 'views/declarations/common/search-customer/useSearchCustomer';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import { transportDocument } from '../validation/H7CommonValidations';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import addPathPrefix from 'utils/addPathPrefix';
import useCodelists from '../../../../../../hooks/useCodelists';
import { validators } from 'views/declarations/uk/export/validations/validations';

const TransportDocumentsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH7TooltipsByRefNumberAndField } = useTooltips();

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix ?? 'goodsShipment.valuationInformation.transportDocument'}
            initialValues={{ transportDocumentReferenceNumber: '', transportDocumentType: '' }}
            list={(list) => [
                { field: t('referenceNumber'), value: list.transportDocumentReferenceNumber },
                {
                    field: t('type'),
                    value: getFullCodelistValue(list.transportDocumentType, aisCodelists?.transportDocument),
                },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('transportDocument')}
            validationSchema={transportDocument}
            condensed
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormInput
                        required
                        maxLength={70}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'transportDocumentReferenceNumber'), formProps)}
                        label={t('referenceNumber')}
                        tooltip={getH7TooltipsByRefNumberAndField('New', t('referenceNumber'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        onSearchCustomer={({ fieldHelpers }) => {
                            triggerShowSearchCustomerDrawer((customer) => {
                                fieldHelpers?.setValue(customer.eori);
                            });
                        }}
                        condensed
                    />
                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'transportDocumentType'), formProps)}
                        label={t('type')}
                        selectOptions={aisCodelists?.transportDocument}
                        tooltip={getH7TooltipsByRefNumberAndField('New', t('type'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                        codeValidation={[validators.maxLength(4)]}
                    />
                    <SearchCustomer.Drawer
                        onClose={triggerHideSearchCustomerDrawer}
                        show={showSearchCustomerDrawer}
                        onSelectCustomer={handleSelectCustomer}
                    />
                </FormCardContainer>
            )}
        />
    );
};

export default TransportDocumentsCard;
