import { ParsedCard, ParsedField, ParsedForm } from 'store/declaration-form-errors/ParsedForm';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';
import { parseDeclarationItems, parseParty } from './utils';
import { get } from 'lodash';

const getHarmonizedSystemSubHeadingCode = (commodityCode: string) => {
    return commodityCode.substring(0, 6);
};

const taricsParser = (parsedItemDetails: ParsedCard[] | undefined, item: any) => {
    const fields: ParsedField[] = [];

    const suggestedTaricsArrayPath = 'suggestedTarics';
    const suggestedTaricArrayValue = get(item, suggestedTaricsArrayPath) as unknown as any[];

    const commodityCodeValues: string[] = [];

    suggestedTaricArrayValue?.forEach((taricPair) => {
        const commodityCodeValue = get(taricPair, 'commodityCode');
        commodityCodeValues.push(getHarmonizedSystemSubHeadingCode(commodityCodeValue));
    });

    const commodityCodePath = 'commodityCodeHarmonizedSystemSubHeadingCode';

    const goodsInformationCommodityCode = get(item, commodityCodePath);

    if (!goodsInformationCommodityCode) {
        fields.push({
            name: `Commodity Code`,
            message: commodityCodeValues,
            path: commodityCodePath,
        });
    } else {
        const field = parsedItemDetails
            ?.find(({ name }) => name === 'Goods Information')
            ?.fields?.find((field) => field.path === commodityCodePath);
        if (field) {
            field.message = field.message?.toString().substring(0, 6);
        }
    }

    if (fields.length > 0 && parsedItemDetails) {
        parsedItemDetails.unshift({ name: 'Suggested Tarics', fields });
    }
};

export const parseInvoiceResponse = (defaultParse: ParsedForm, declaration: IrelandImportDeclaration) => {
    parseParty(defaultParse.masterDetails, declaration, 'goodsShipment.exporter');
    parseParty(defaultParse.masterDetails, declaration, 'goodsShipment.importer');
    parseParty(defaultParse.masterDetails, declaration, 'declarant');

    parseDeclarationItems(defaultParse.items, declaration, 'goodsShipment.governmentAgencyGoodsItem', taricsParser);
    const parsedDeclaration = {
        masterDetails: defaultParse.masterDetails,
        items: defaultParse.items,
    };
    return parsedDeclaration;
};
