import { Tooltip } from 'antd';
import { declarationStatusColor } from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import useDeclarations from 'hooks/useDeclarations';
import useJobs from 'hooks/useJobs';
import { get, snakeCase, startCase, toUpper } from 'lodash';
import { ReactElement, useMemo, useState } from 'react';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import styled from 'styled-components';
import { useProductTemplateContext } from 'utils/ProductTemplateContext';
import useFormUtils from '../../../hooks/useFormUtils';
import { tsdNameLabels } from '../../../store/declarations/ireland/temporary-storage-declaration';
import { gvmsNameLabels } from '../../../store/declarations/uk/gvms-declaration';
import { colors } from '../../../theme';
import UkGvmsRecordUtils from '../uk/gvms/utils';
import Box44, { SBox44Button } from './box44/Box44';
import { getLrn, getMrn } from '../utils/declaration-utils';
import MRNLookupModal from './misc/MRNLookupModal';
import SearchCustomerButton from './search-customer/SearchCustomerButton';
import useGetDeclarationMapValues from 'hooks/useGetDeclarationMapValues';

export const InfoTitle = styled.span`
    font-weight: bold;
    white-space: nowrap;
`;

export const InfoText = styled.span`
    white-space: nowrap;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Status = styled.span<{ status: DeclarationStatus | undefined }>`
    background-color: ${({ status }) => (status && declarationStatusColor[status]) || colors.lightBlue};
    padding: 0.2rem 0.8rem;
    font-weight: bold;
    border-radius: 2px;
    color: white;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 3rem;
`;

export const InfoWrapper = styled.div`
    display: flex;
    gap: 0.8rem;
    align-items: center;
`;

interface Props {
    hasBox44?: boolean | undefined;
    handleViewAllProductsBtn?: () => void;
    handleAddNewProductBtn?: () => Promise<void>;
    viewOnly?: boolean;
    options?: {
        visible?: {
            template?: boolean;
            declaration?: boolean;
            jobId?: boolean;
            ref?: boolean;
            status?: boolean;
            gmrId?: boolean;
            record?: boolean;
            pbnId?: boolean;
            mrn?: boolean;
        };
    };
}

const DeclarationInformation = ({
    hasBox44,
    handleViewAllProductsBtn,
    handleAddNewProductBtn,
    viewOnly,
    options = {
        visible: {
            declaration: true,
            jobId: true,
            ref: true,
            status: true,
            template: true,
            gmrId: false,
            pbnId: false,
        },
    },
}: Props): ReactElement => {
    const { declaration, declarationTemplate } = useDeclarations();
    const { declarationTemplate: productTemplateDeclarationTemplate } = useProductTemplateContext();
    const { productId } = useParams();
    const location = useLocation();
    const { job } = useJobs({ jobId: declaration?.jobId });
    const navigate = useNavigate();

    const map = useGetDeclarationMapValues();

    const [MRNLookupModalVisible, setMRNLookupModalVisible] = useState(false);

    const { declarationFormType } = useFormUtils();
    const jobId = job?.referenceId;
    const ref = declaration?.referenceNumber;
    const status = () => {
        const cdsRealStatus = get(declaration, 'cdsDeclaration.cdsDeclarationStatusMapping.cdsDescription');
        if (declaration?.cdsDeclaration && cdsRealStatus) {
            return toUpper(snakeCase(cdsRealStatus)) as DeclarationStatus;
        }

        const pbnStatus = get(declaration, 'preBoardingNotification.status');
        if (pbnStatus) return startCase(pbnStatus) as DeclarationStatus;

        return declaration?.status;
    };

    const [show, setShow] = useState(false);

    const inProductTemplateAdd = useMatch('customs-declarations/:country/:internalType/products/:type/new');
    const inProductTemplateEdit = useMatch('customs-declarations/:country/:internalType/products/:type/:id');
    const inProductTemplate = useMemo(
        () => inProductTemplateAdd || inProductTemplateEdit,
        [inProductTemplateAdd, inProductTemplateEdit]
    );

    const showButton = useMemo(
        () => !location.pathname.includes('products') || productId || inProductTemplate,
        [inProductTemplate, location.pathname, productId]
    );

    const templateName = useMemo(() => {
        if (productTemplateDeclarationTemplate) return productTemplateDeclarationTemplate.templateName;
        if (declarationTemplate) return declarationTemplate.templateName;
        return 'DEFAULT';
    }, [declarationTemplate, productTemplateDeclarationTemplate]);

    const mrn = useMemo(() => declaration && getMrn(declaration), [declaration]);
    const lrn = useMemo(
        () => declaration && map.lrnPath && getLrn(declaration, map.lrnPath),
        [declaration, map.lrnPath]
    );

    const showMRNLookupModal = () => {
        if (!mrn) return;
        setMRNLookupModalVisible(true);
    };
    const hideMRNLookupModal = () => {
        setMRNLookupModalVisible(false);
    };

    return (
        <Container>
            {options?.visible?.template && (
                <InfoWrapper data-testid="declaration-information-template-name">
                    <InfoTitle>Template: </InfoTitle>
                    <Tooltip overlay={templateName}>
                        <InfoText>{templateName}</InfoText>
                    </Tooltip>
                </InfoWrapper>
            )}
            {options?.visible?.declaration && (
                <InfoWrapper data-testid="declaration-information-form-type">
                    <InfoTitle>Declaration: </InfoTitle>
                    <InfoText>
                        {declaration?.ieImportTemporaryStorageDeclaration
                            ? tsdNameLabels.find((label) => label.key === declarationFormType)?.value
                            : declarationFormType}
                    </InfoText>
                </InfoWrapper>
            )}
            {options?.visible?.record && (
                <InfoWrapper data-testid="declaration-information-form-type">
                    <InfoTitle>Record: </InfoTitle>
                    <span>{gvmsNameLabels.find((label) => label.key === declarationFormType)?.value ?? '-'}</span>
                </InfoWrapper>
            )}
            {options?.visible?.jobId && (
                <InfoWrapper data-testid="declaration-information-job-id">
                    <InfoTitle>Job ID: </InfoTitle>
                    <InfoText>{jobId}</InfoText>
                </InfoWrapper>
            )}
            {options?.visible?.ref && (
                <InfoWrapper data-testid="declaration-information-ref">
                    <InfoTitle>Ref: </InfoTitle>
                    <InfoText>{ref}</InfoText>
                </InfoWrapper>
            )}
            {options?.visible?.gmrId && (
                <InfoWrapper data-testid="declaration-information-gmr-id">
                    <InfoWrapper>
                        <InfoTitle>GMR ID: </InfoTitle>
                        <span>{UkGvmsRecordUtils.addSpacesToGmrId(declaration?.gvmsDeclaration?.gmrId)}</span>
                    </InfoWrapper>
                </InfoWrapper>
            )}
            {options?.visible?.pbnId && (
                <InfoWrapper data-testid="declaration-information-pbn-id">
                    <InfoWrapper>
                        <InfoTitle>PBN ID: </InfoTitle>
                        <span>{declaration?.preBoardingNotification?.pbnId}</span>
                    </InfoWrapper>
                </InfoWrapper>
            )}
            {mrn && (
                <InfoWrapper data-testid="declaration-information-mrn">
                    <InfoTitle>MRN: </InfoTitle>
                    <Tooltip overlay={mrn ?? 'N/A'}>
                        <InfoText onClick={showMRNLookupModal}>{mrn ?? 'N/A'}</InfoText>
                    </Tooltip>
                    {mrn ? (
                        <SearchCustomerButton title="MRN Lookup" compact condensed onClick={showMRNLookupModal} />
                    ) : null}
                </InfoWrapper>
            )}
            {lrn && (
                <InfoWrapper data-testid="declaration-information-lrn">
                    <InfoTitle>LRN: </InfoTitle>
                    <Tooltip overlay={lrn}>
                        <InfoText>{lrn}</InfoText>
                    </Tooltip>
                </InfoWrapper>
            )}
            {options?.visible?.status && (
                <InfoWrapper data-testid="declaration-information-status">
                    <InfoTitle>Status: </InfoTitle>
                    <div>
                        <Status status={status()}>{status()}</Status>
                    </div>
                </InfoWrapper>
            )}
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '10px' }}>
                {handleViewAllProductsBtn && !viewOnly && (
                    <InfoWrapper>
                        <SBox44Button
                            size="small"
                            onClick={() => handleViewAllProductsBtn?.()}
                            data-testid={'view-all-product-templates-button'}
                        >
                            View all product templates
                        </SBox44Button>
                    </InfoWrapper>
                )}

                {handleAddNewProductBtn && !viewOnly && (
                    <InfoWrapper>
                        <SBox44Button
                            size="small"
                            onClick={() => handleAddNewProductBtn?.()}
                            data-testid={'add-new-product-button'}
                        >
                            Add new product
                        </SBox44Button>
                    </InfoWrapper>
                )}

                {productId && viewOnly && (
                    <InfoWrapper>
                        <SBox44Button
                            size="small"
                            onClick={() =>
                                navigate(`/declarations/${declaration?.id}/view-only/products`, {
                                    replace: true,
                                    state: { comingBackFromProductView: true },
                                })
                            }
                            data-testid={'back-to-products-list-button'}
                        >
                            Back to products list
                        </SBox44Button>
                    </InfoWrapper>
                )}

                {hasBox44 && (
                    <InfoWrapper>
                        {showButton && <Box44.Button onClick={() => setShow((prev) => !prev)} />}
                        <Box44.Modal viewOnly={viewOnly} visible={show} onCancel={() => setShow(false)} />
                    </InfoWrapper>
                )}
            </div>

            <MRNLookupModal visible={MRNLookupModalVisible} onCancel={hideMRNLookupModal} />
        </Container>
    );
};

export default DeclarationInformation;
