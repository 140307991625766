import { H6Style } from 'components/ui/base/typography/Typography.styles';
import useCountries from 'hooks/useCountries';
import { FC } from 'react';
import { Individual, IndividualType } from 'store/individuals/individual';
import styled from 'styled-components';
import { individualTypeText } from '../utils';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button';
import useSession from 'hooks/useSession';
import { notification, Tooltip, TooltipProps } from 'antd';

const Text = styled.p`
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #333333;
    margin-top: 1.6rem;
`;

const ResetPasswordTooltip = (props: Pick<TooltipProps, 'children'> & { enabled?: boolean }) => {
    if (props.enabled === false) return <>{props.children}</>;
    return <Tooltip overlay="The user is inactive">{props.children}</Tooltip>;
};

interface Props {
    individual: Individual;
}

const IndividualsDetails: FC<Props> = ({ individual }) => {
    const { getNameFromCountryCode } = useCountries();

    return (
        <>
            <H6Style>Basic Details</H6Style>

            <div style={{ marginTop: '1.6rem' }}>
                <Text>
                    Name: <b>{individual?.fullName}</b>
                </Text>
                <Text>
                    Email: <b>{individual?.email ?? '-'}</b>
                </Text>
                <Text>
                    Status: <b>{individual?.active ? 'Active' : 'Inactive'}</b>
                </Text>
                <Text>
                    Type: <b>{individual?.type ? individualTypeText[individual?.type] : '-'}</b>
                </Text>
                <Text>
                    Registered: <b>{individual?.registered ? <CheckOutlined /> : <CloseOutlined />}</b>
                </Text>
                <Text>Address:</Text>
                <Text style={{ paddingLeft: '2rem' }}>
                    Address Line 1: <b>{individual?.address?.addressLine1 ?? '-'}</b>
                </Text>
                <Text style={{ paddingLeft: '2rem' }}>
                    Address Line 2: <b>{individual?.address?.addressLine2 ?? '-'}</b>
                </Text>
                <Text>
                    PostCode: <b>{individual?.address?.postCode ?? '-'}</b>
                </Text>
                <Text>
                    Country: <b>{getNameFromCountryCode(individual?.address?.country) ?? '-'}</b>
                </Text>
                <Text>
                    City: <b>{individual?.address?.city ?? '-'}</b>
                </Text>
                <Text>
                    Phone number: <b>{individual?.phone ?? '-'}</b>
                </Text>
            </div>

            <div style={{ marginTop: '1.6rem', paddingInline: '1.6rem', display: 'flex', justifyContent: 'flex-end' }}>
                <ResetPasswordButton individual={individual} />
            </div>
        </>
    );
};

export default IndividualsDetails;

const ResetPasswordButton = (props: { individual: Individual }) => {
    const { forgotPassword, userInfo } = useSession();

    const handleResetPassword = async () => {
        await forgotPassword(props.individual?.email);
        notification.success({ message: 'Reset password email sent' });
    };

    if (userInfo?.role !== IndividualType.BROKER_ADMIN) return null;

    return (
        <ResetPasswordTooltip enabled={!props.individual.active}>
            <div>
                <Button disabled={!props.individual.active} onClick={handleResetPassword}>
                    Reset Password
                </Button>
            </div>
        </ResetPasswordTooltip>
    );
};
