import { FormModel, validators } from '../../export/validations/validations';
import { directionCodelists, haulierTypeCodelists } from '../UkGvmsForm';

const sAndSMasterRefNumRegexValidation = validators.regex(
    /^[A-Z0-9-)(/]{1,200}$/,
    'Invalid input format. Use uppercase letters, digits, hyphens, parentheses, and slashes (1-200 characters).'
);

const ukGvmsValidation = {
    childValidators: {
        gvmsRouteData: {
            childValidators: {
                departurePortId: [validators.required()],
                arrivalPortId: [validators.required()],
                carrierId: [validators.required()],
            },
        },
        direction: [
            validators.required(),
            async (_: string, b: FormModel<any>) => {
                const directionValue = b.getValues()?.direction;
                if (!directionValue) return undefined;

                const validValues = directionCodelists.map(({ id }) => id);

                if (!validValues.includes(directionValue)) {
                    return `Please select one of the following options: ${validValues.join(', ')}`;
                }

                return undefined;
            },
        ],
        haulierType: [
            async (_: string, b: FormModel<any>) => {
                const haulierTypeValue = b.getValues()?.haulierType;
                if (!haulierTypeValue) return undefined;

                const validValues = haulierTypeCodelists.map(({ id }) => id);

                if (!validValues.includes(haulierTypeValue)) {
                    return `Please select one of the following options: ${validValues.join(', ')}`;
                }

                return undefined;
            },
        ],
        isUnaccompanied: [validators.boolean()],
        vehicleRegNum: [
            validators.regex(
                /^[A-Z0-9-](?:[A-Z0-9 -]{1,30})?$/,
                'Invalid input format. Use uppercase letters, digits, hyphens, and spaces (1-30 characters).'
            ),
        ],
        trailerRegistrationNums: [
            validators.array({ min: 1, max: 2, unique: true }),
            validators.regex(
                /^[A-Z0-9-](?:[A-Z0-9 -]{1,199})?$/,
                'Invalid input format. Use uppercase letters, digits, hyphens, and spaces (1-199 characters).'
            ),
        ],
        containerReferenceNums: [
            validators.array({ unique: true }),
            validators.regex(
                /^[A-Z0-9-](?:[A-Z0-9 -]{1,199})?$/,
                'Invalid input format. Use uppercase letters, digits, hyphens, and spaces (1-199 characters).'
            ),
        ],
        plannedCrossing: {
            childValidators: {
                routeId: [
                    async (_: string, b: FormModel<any>) => {
                        const routeIdValue = b.getValues()?.plannedCrossing?.routeId;

                        if (!routeIdValue)
                            return 'Invalid Route ID Format. Please ensure the Port of Departure, Port of Arrival, Carrier, and Direction are correctly filled.';

                        return undefined;
                    },
                    validators.regex(
                        /^[A-Z0-9-]{1,200}$/,
                        'Invalid input format. Use uppercase letters, digits, and hyphens (1-200 characters).'
                    ),
                ],
                localDateTimeOfDeparture: [
                    validators.regex(
                        /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d$/,
                        'Invalid date and time format. Use yyyy-MM-ddTHH:mm.'
                    ),
                ],
            },
        },
        customsDeclarations: {
            childValidators: {
                customsDeclarationId: [validators.required(), sAndSMasterRefNumRegexValidation],
                customsDeclarationPartId: [
                    validators.regex(
                        /^[0-9]{1,3}[A-Z]?$/,
                        'Invalid input format. Use 1 to 3 digits followed by an optional uppercase letter.'
                    ),
                ],
                sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
            },
            stopAtNull: true,
        },
        sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
        sadDeclaration: {
            childValidators: {
                sadGoods: {
                    childValidators: {
                        sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
                    },
                },
                stopAtNull: true,
            },
        },
        transitDeclarations: {
            childValidators: {
                transitDeclarationId: [
                    validators.required(),
                    validators.regex(
                        /^\d{2}[A-Z]{2}[A-Z0-9]{14}$/,
                        'Invalid input format. Use 2 digits, 2 uppercase letters, and 14 alphanumeric characters.'
                    ),
                ],
                isTSAD: [validators.required(), validators.boolean()],
                sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
            },
            stopAtNull: true,
        },
        tirDeclarations: {
            childValidators: {
                tirCarnetId: [
                    validators.required(),
                    validators.regex(/^.{1,200}$/, 'Invalid input format. Use 1 to 200 characters.'),
                ],
                sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
            },
            stopAtNull: true,
        },
        ataDeclarations: {
            childValidators: {
                ataCarnetId: [
                    validators.required(),
                    validators.regex(/^.{1,200}$/, 'Invalid input format. Use 1 to 200 characters.'),
                ],
                sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
            },
            stopAtNull: true,
        },
        eidrDeclarations: {
            childValidators: {
                traderEORI: [
                    validators.required(),
                    validators.regex(
                        /^[A-Z]{2}[0-9]{12,15}$/,
                        'Invalid input format. Use 2 uppercase letters followed by 12 to 15 digits.'
                    ),
                ],
                sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
            },
            stopAtNull: true,
        },
        indirectExportDeclarations: {
            childValidators: {
                eadMasterRefNum: [
                    validators.regex(
                        /^\d{2}[A-Z]{2}[A-Z0-9]{14}$/,
                        'Invalid input format. Use 2 digits, 2 uppercase letters, and 14 alphanumeric characters.'
                    ),
                ],
            },
            stopAtNull: true,
        },
        emptyVehicle: {
            childValidators: {
                isOwnVehicle: [validators.boolean()],
                sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
            },
        },
        dbcDeclaration: {
            childValidators: {
                isOwnVehicle: [validators.boolean()],
                dbcGoods: {
                    childValidators: {
                        sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
                    },
                    stopAtNull: true,
                },
            },
        },
        exemptionDeclaration: {
            childValidators: {
                exemptedGoods: {
                    childValidators: {
                        sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
                    },
                    stopAtNull: true,
                },
            },
        },
        ukcDeclarations: {
            childValidators: {
                fpoEORI: [
                    validators.regex(
                        /^[A-Z]{2}[0-9]{12,15}$/,
                        'Invalid input format. Use 2 uppercase letters followed by 12 to 15 digits.'
                    ),
                ],
                sAndSMasterRefNum: [sAndSMasterRefNumRegexValidation],
            },
            stopAtNull: true,
        },
    },
};

export default ukGvmsValidation;
