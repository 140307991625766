import { Form } from 'antd';
import PhoneNumber from 'awesome-phonenumber';
import { FormItem } from 'components/ui/base/form';
import Notification from 'components/ui/base/notification/Notification';
import { H5Style, TextSmall, TextSmallBold } from 'components/ui/base/typography/Typography.styles';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import ProfilePicture from 'components/ui/composed/imageUpload/ProfilePicture';
import { useFormik } from 'formik';
import useCountries from 'hooks/useCountries';
import useIndividuals from 'hooks/useIndividuals';
import { useProfilePicture } from 'hooks/useProfilePicture';
import useSession from 'hooks/useSession';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    deleteIndividualProfilePicture,
    editIndividual,
    getIndividualProfilePictureDownloadPreSign,
    getIndividualProfilePictureUploadPreSign,
    uploadIndividualProfilePicture,
} from 'store/individuals/client';
import { Individual } from 'store/individuals/individual';
import CustomerAddressForm from 'views/customers/components/CustomerAddressForm';
import { uploadFile } from 'views/declarations/sections/customer-documents/components/UploadUtils';
import { InfoDiv, StyledCard, StyledInfoDiv } from '../Settings.styles';
import FormFooter from './FormFooter';

const PersonalInformation: FC = () => {
    const { t } = useTranslation('settings');
    const [edit, setEdit] = useState<boolean>(false);
    const { userInfo } = useSession();

    const individualId = userInfo?.individualId;

    const { individual, getIndividual } = useIndividuals({ individualId });

    const { getNameFromCountryCode } = useCountries();

    const formik = useFormik<Partial<Individual>>({
        initialValues: { ...individual },
        enableReinitialize: true,
        onSubmit: (values) => handleSubmit(values),
    });

    useEffect(() => {
        if (!individual && individualId) {
            getIndividual(individualId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [individual, individualId]);

    const handleSubmit = async (values: Partial<Individual>) => {
        if (!individual?.id) {
            return;
        }

        const data = { ...individual, ...values, phone: `${values.phoneCountryCode}${values.phone}` };
        const result = await editIndividual(individual.id, data);
        if (result) {
            if (result.phone) {
                const phone = new PhoneNumber(result?.phone);
                const individual = {
                    ...result,
                    phone: phone.getNumber('significant'),
                    phoneCountryCode: `+${phone.getCountryCode().toString()}`,
                };
                formik.setValues(individual);
            } else {
                formik.setValues(result);
            }
            Notification({
                type: 'success',
                messageTitle: 'Personal information',
                description: t('successMessages.personalInformation'),
            });
        }
        // TODO add error notification
    };

    useEffect(() => {
        if (individual) {
            if (individual?.phone) {
                const phone = new PhoneNumber(individual?.phone);
                const data = {
                    ...individual,
                    phone: phone.getNumber('significant'),
                    phoneCountryCode: `+${phone.getCountryCode().toString()}`,
                };
                formik.setValues(data);
            }
        } else {
            formik.setValues({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [individual]);

    const personalInfoView = useMemo(() => {
        const handleCancel = () => {
            setEdit(false);
        };

        const handleSave = async () => {
            await formik.submitForm();
            setEdit(false);
        };

        const handleEdit = () => {
            setEdit(true);
        };

        if (!edit) {
            return (
                <Form layout="vertical" style={{ width: '100%' }}>
                    <StyledInfoDiv>
                        <TextSmallBold marginRight={0.5}>{`${t('personalInformation.fullName')}:`}</TextSmallBold>
                        <TextSmall> {formik.values.fullName}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <TextSmallBold marginRight={0.5}>{`${t('personalInformation.email')}:`}</TextSmallBold>
                        <TextSmall>{formik.values.email}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <TextSmallBold marginRight={0.5}>{`${t('personalInformation.phone')}:`}</TextSmallBold>
                        <TextSmall>{`${formik.values.phoneCountryCode}${formik.values.phone}`}</TextSmall>
                    </StyledInfoDiv>
                    <StyledInfoDiv>
                        <TextSmallBold marginRight={0.5}>{`${t('personalInformation.address')}:`}</TextSmallBold>
                    </StyledInfoDiv>
                    {formik.values.address?.addressLine1 && (
                        <InfoDiv paddingLeft={2}>
                            <TextSmall>{formik.values.address?.addressLine1}</TextSmall>
                        </InfoDiv>
                    )}
                    {formik.values.address?.addressLine2 && (
                        <InfoDiv paddingLeft={2}>
                            <TextSmall>{formik.values.address.addressLine2}</TextSmall>
                        </InfoDiv>
                    )}
                    {formik.values.address?.postCode && (
                        <InfoDiv paddingLeft={2}>
                            <TextSmallBold marginRight={0.5}>{`${t('personalInformation.postCode')}:`}</TextSmallBold>
                            <TextSmall>{formik.values.address?.postCode} </TextSmall>
                        </InfoDiv>
                    )}
                    {formik.values.address?.city && (
                        <InfoDiv paddingLeft={2}>
                            <TextSmallBold marginRight={0.5}>{`${t('personalInformation.city')}:`}</TextSmallBold>
                            <TextSmall> {formik.values?.address.city} </TextSmall>
                        </InfoDiv>
                    )}
                    {formik.values.address?.country && (
                        <InfoDiv paddingLeft={2}>
                            <TextSmallBold marginRight={0.5}>{`${t('personalInformation.country')}:`}</TextSmallBold>{' '}
                            <TextSmall> {getNameFromCountryCode(formik.values?.address.country)} </TextSmall>
                        </InfoDiv>
                    )}
                    <FormItem style={{ textAlign: 'right' }}>
                        <FormFooter edit={edit} onCancel={handleCancel} onSave={handleSave} onEdit={handleEdit} />
                    </FormItem>
                </Form>
            );
        } else {
            return (
                <Form layout="vertical" style={{ width: '100%' }}>
                    <FormInput
                        label={t('personalInformation.fullName')}
                        fieldProps={formik.getFieldProps('fullName')}
                    />
                    <FormInput
                        disabled
                        label={t('personalInformation.email')}
                        fieldProps={formik.getFieldProps('email')}
                    />
                    <FormPhoneNumber
                        fieldCodeProps={formik.getFieldProps('phoneCountryCode')}
                        fieldCodeMeta={formik.getFieldMeta('phoneCountryCode')}
                        fieldNumberProps={formik.getFieldProps('phone')}
                        fieldNumberMeta={formik.getFieldMeta('phone')}
                    />
                    <CustomerAddressForm
                        fieldProps={{
                            addressLine1: formik.getFieldProps('address.addressLine1'),
                            addressLine2: formik.getFieldProps('address.addressLine2'),
                            postCode: formik.getFieldProps('address.postCode'),
                            city: formik.getFieldProps('address.city'),
                            country: formik.getFieldProps('address.country'),
                        }}
                        fieldMeta={{
                            addressLine1: formik.getFieldMeta('address.addressLine1'),
                            addressLine2: formik.getFieldMeta('address.addressLine2'),
                            postCode: formik.getFieldMeta('address.postCode'),
                            city: formik.getFieldMeta('address.city'),
                            country: formik.getFieldMeta('address.country'),
                        }}
                        label={{
                            addressLine1: 'Address Line 1',
                            addressLine2: 'Address Line 2',
                            postCode: 'Post Code',
                            city: 'City',
                            country: 'Country',
                        }}
                    />
                    <FormItem style={{ textAlign: 'right' }}>
                        <FormFooter edit={edit} onCancel={handleCancel} onSave={handleSave} onEdit={handleEdit} />
                    </FormItem>
                </Form>
            );
        }
    }, [edit, formik, t, getNameFromCountryCode]);

    const { profilePicture, handleProfilePictureDelete, handleProfilePictureUpload } = useProfilePicture({
        getPicture: () => getIndividualProfilePictureDownloadPreSign(individualId!),
        getPreSign: (file) =>
            getIndividualProfilePictureUploadPreSign(individualId!, {
                individualId: individualId!,
                pictureName: file.name,
                pictureSize: file.size,
            }),
        deletePicture: () => deleteIndividualProfilePicture(individualId!),
        uploadPicture: (preSignUrl) => uploadIndividualProfilePicture(individualId!, preSignUrl),
    });

    return (
        <>
            <StyledCard>
                <H5Style marginBottom={3.8}>{t('settings.personalInformation')}</H5Style>
                <ProfilePicture
                    profilePicture={profilePicture}
                    onUpload={handleProfilePictureUpload}
                    onDelete={handleProfilePictureDelete}
                    style={{ marginBottom: 20 }}
                />
                {personalInfoView}
            </StyledCard>
        </>
    );
};
export default PersonalInformation;
