import { IrelandBusinessRulesType, listIrelandBusinessRules } from './client';

export const doGetIrelandBusinessRules: (type: IrelandBusinessRulesType) => any =
    (type) => async (dispatch: Function) => {
        dispatch({ type: `LIST_${type}_BUSINESS_RULES_REQUEST` });
        try {
            const payload = await listIrelandBusinessRules(type);

            dispatch({
                type: `LIST_${type}_BUSINESS_RULES_SUCCESS`,
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: `LIST_${type}_BUSINESS_RULES_ERROR`, error: e?.response?.data });
        }
    };
