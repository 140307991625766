import { ColumnsType } from 'antd/lib/table';
import { Declaration } from '../../../store/declarations/declaration';
import { capitalize } from 'lodash';
import ImporterIcon from './components/icons/ImporterIcon';
import ExporterIcon from './components/icons/ExporterIcon';
import { GvmsReferenceData } from '../../../store/declarations/uk/gvms-declaration';
import UkGvmsRecordUtils from '../../declarations/uk/gvms/utils';
import moment from 'moment';
import DeclarationStatusTag from '../../../components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { HourglassOutlined, WarningOutlined } from '@ant-design/icons';
import {
    ArchiveButton,
    BarcodeButton,
    CoreTemplateButton,
    DeleteButton,
    DuplicateButton,
    UnarchiveButton,
} from './dashboard-buttons';
import { FC, useMemo } from 'react';
import { DivGap, FlexDiv } from './components/DeclarationsTable.styled';

interface ActionEvents {
    onDuplicate?: (record: Declaration) => void;
    onArchive?: (recordIds: string[]) => void;
    onUnarchive?: (record: any) => void;
    onBarcode?: (declaration: Declaration | undefined) => void;
    onDelete?: (record: any) => void;
    onCoreTemplateToggle?: (record: any) => void;
}

enum GvmsColumnsTitles {
    GMR_TYPE = 'GMR Type',
    GMR_ID = 'GMR ID',
    DIRECTION = 'Direction',
    ROUTE = 'Route',
    DEPARTURE = 'Departure',
    EXPIRY = 'Expiry',
    VEHICLE_NUMBER = 'Vehicle No.',
    USER_NAME = 'User Name',
    STATE = 'State',
    COMMANDS = 'Commands',
}

const DirectionRow: FC<{ direction: string | undefined }> = ({ direction }) => {
    const formatDirection = (direction: string): string => {
        const parts = direction.split('_');
        const formattedParts = parts.map((part) => (['NI', 'GB', 'UK'].includes(part) ? part : capitalize(part)));
        return formattedParts.join(' ');
    };

    return <span data-testid="gvms-direction-row">{direction ? formatDirection(direction) : '-'}</span>;
};

const RouteRow: FC<{
    departurePortId: string | undefined;
    arrivalPortId: string | undefined;
    gvmsReferenceDataPorts: GvmsReferenceData['ports'] | undefined;
}> = ({ departurePortId, arrivalPortId, gvmsReferenceDataPorts }) => {
    const portsMap = useMemo(
        () =>
            gvmsReferenceDataPorts?.reduce((map, value) => {
                map.set(value.portId, value.portDescription);
                return map;
            }, new Map()),
        [gvmsReferenceDataPorts]
    );

    return (
        <FlexDiv data-testid="gvms-route-row">
            <DivGap>
                <ExporterIcon color="#999999" />
                <span>{departurePortId ? portsMap?.get(+departurePortId) : '-'}</span>
            </DivGap>

            <DivGap>
                <ImporterIcon color="#999999" />
                <span>{arrivalPortId ? portsMap?.get(+arrivalPortId) : '-'}</span>
            </DivGap>
        </FlexDiv>
    );
};

const DateRow: FC<{ date: string | undefined; testId: string }> = ({ date, testId }) => {
    const momentDate = moment(date);

    if (momentDate.isValid() && date)
        return (
            <FlexDiv data-testid={testId}>
                <span>{momentDate.format('DD/MM/YYYY')}</span>
                <span style={{ color: '#999999' }}>{momentDate.format('HH:mm')}</span>
            </FlexDiv>
        );

    return <span data-testid={testId}>-</span>;
};

export const ExpiryRow: FC<{ expiryDate: string | undefined }> = ({ expiryDate }) => {
    const testId = 'gvms-expiry-row';
    const momentExpiryDate = moment(expiryDate).startOf('day');

    if (momentExpiryDate.isValid() && expiryDate) {
        const now = moment().startOf('day');

        // Calculate the difference in days, including both today and the last day.
        const remainingDaysIncludingToday = momentExpiryDate.diff(now, 'days') + 1;

        if (remainingDaysIncludingToday === 0) {
            return (
                <DivGap data-testid={testId} style={{ color: '#FFA500' }}>
                    <span>Today</span>
                </DivGap>
            );
        } else if (remainingDaysIncludingToday > 0) {
            return (
                <DivGap data-testid={testId}>
                    <span>{`${String(remainingDaysIncludingToday).padStart(2, '0')} Days`}</span>
                </DivGap>
            );
        } else {
            return (
                <DivGap data-testid={testId} style={{ color: '#F44336' }}>
                    <FlexDiv>
                        <span>Expired</span>
                        {expiryDate && (
                            <span>
                                {moment(expiryDate).format('DD/MM/YYYY')} - {moment(expiryDate).format('HH:mm')}
                            </span>
                        )}
                    </FlexDiv>
                </DivGap>
            );
        }
    }

    return (
        <DivGap data-testid={testId}>
            <span>-</span>
        </DivGap>
    );
};

const gvmsColumns = (gvmsReferenceData: GvmsReferenceData | undefined, actions: ActionEvents): ColumnsType<any> => {
    return [
        {
            title: GvmsColumnsTitles.GMR_ID,
            dataIndex: GvmsColumnsTitles.GMR_ID,
            render: (text: string, record: Declaration) => (
                <span data-testid="gvms-gmr-id-row">
                    {UkGvmsRecordUtils.addSpacesToGmrId(record?.gvmsDeclaration?.gmrId)}
                </span>
            ),
            width: 160,
        },
        {
            title: GvmsColumnsTitles.STATE,
            dataIndex: GvmsColumnsTitles.STATE,
            width: 160,
            render: (text: string, record: Declaration) => {
                const testId = 'gvms-state-row';
                const state = record?.status;
                return state ? (
                    <DeclarationStatusTag status={state} dataTestId={testId} />
                ) : (
                    <span data-testid={testId}>-</span>
                );
            },
        },
        {
            title: GvmsColumnsTitles.DIRECTION,
            dataIndex: GvmsColumnsTitles.DIRECTION,
            render: (text: string, record: Declaration) => (
                <DirectionRow direction={record?.gvmsDeclaration?.direction} />
            ),
            width: 130,
        },
        {
            title: GvmsColumnsTitles.ROUTE,
            dataIndex: GvmsColumnsTitles.ROUTE,
            render: (text: string, record: Declaration) => (
                <RouteRow
                    departurePortId={record?.gvmsDeclaration?.gvmsRouteData?.departurePortId}
                    arrivalPortId={record?.gvmsDeclaration?.gvmsRouteData?.arrivalPortId}
                    gvmsReferenceDataPorts={gvmsReferenceData?.ports}
                />
            ),
            ellipsis: true,
            width: 220,
        },
        {
            title: GvmsColumnsTitles.DEPARTURE,
            dataIndex: GvmsColumnsTitles.DEPARTURE,
            render: (text: string, record: Declaration) => (
                <DateRow
                    date={record?.gvmsDeclaration?.plannedCrossing?.localDateTimeOfDeparture}
                    testId="gvms-departure-row"
                />
            ),
            width: 95,
            ellipsis: true,
        },
        {
            title: GvmsColumnsTitles.VEHICLE_NUMBER,
            dataIndex: GvmsColumnsTitles.VEHICLE_NUMBER,
            ellipsis: true,
            render: (text: string, record: Declaration) => (
                <span data-testid="gvms-vehicle-number-row">{record?.gvmsDeclaration?.vehicleRegNum || '-'}</span>
            ),
        },
        {
            title: GvmsColumnsTitles.USER_NAME,
            dataIndex: GvmsColumnsTitles.USER_NAME,
            render: (text: string, record: Declaration) => (
                <span data-testid="gvms-name-row">{record?.individual?.name || '-'}</span>
            ),
            ellipsis: true,
        },
        {
            title: GvmsColumnsTitles.EXPIRY,
            dataIndex: GvmsColumnsTitles.EXPIRY,
            width: 150,
            render: (text: string, record: Declaration) => (
                <ExpiryRow expiryDate={record?.gvmsDeclaration?.gmrExpiredAt} />
            ),
        },
        {
            title: GvmsColumnsTitles.COMMANDS,
            dataIndex: GvmsColumnsTitles.COMMANDS,
            render: (text: string, record: Declaration) => {
                const gmrExpiryDays =
                    moment(record?.gvmsDeclaration?.gmrExpiredAt).startOf('day').diff(moment().startOf('day'), 'days') +
                    1;
                const gmrAboutToExpire = gmrExpiryDays <= 7 && gmrExpiryDays > 0;
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }} data-testid="gvms-commands-row">
                        {actions.onBarcode && (
                            <BarcodeButton
                                record={record}
                                onClick={actions.onBarcode}
                                disabled={
                                    !record?.gvmsDeclaration?.gmrId ||
                                    !record?.gvmsDeclaration?.gmrIdBarcode ||
                                    !record?.gvmsDeclaration?.gmrExpiredAt
                                }
                            />
                        )}
                        {actions.onDelete && <DeleteButton record={record} onClick={actions.onDelete} />}
                        {actions.onDuplicate && <DuplicateButton record={record} onClick={actions.onDuplicate} />}
                        {actions.onCoreTemplateToggle && (
                            <CoreTemplateButton record={record} onClick={actions.onCoreTemplateToggle} />
                        )}
                        {actions.onArchive && <ArchiveButton record={record} onClick={actions.onArchive} />}
                        {actions.onUnarchive && <UnarchiveButton record={record} onClick={actions.onUnarchive} />}
                        {gmrAboutToExpire && <HourglassOutlined style={{ color: '#ffd000', fontSize: 20 }} />}
                        {(record?.actionRequired || record?.inspectionRequired) && (
                            <WarningOutlined style={{ color: '#ffb11f', fontSize: 20 }} />
                        )}
                    </div>
                );
            },
            width: 220,
        },
    ];
};

export default gvmsColumns;
