import { useCallback, useEffect, useMemo, useState } from 'react';
import Drawer from '../../../../../components/ui/base/drawer/Drawer';
import StyledButton from '../../../common/search-customer/SearchCustomerButton';
import { Col, DrawerProps, Table } from 'antd';
import SearchBar from '../../../../../components/ui/base/searchbar/SearchBar';
import { GvmsReferenceData } from '../../../../../store/declarations/uk/gvms-declaration';
import { ColumnsType } from 'antd/lib/table';
import Fuse from 'fuse.js';
import { getFormikProps } from '../../../utils/form-utils';
import { useFormikContext } from 'formik';
import { useAppSelector } from '../../../../../config/hooks';
import UkGvmsRecordUtils from '../utils';
import { GvmsFormCustomFields } from '../enums';
import {
    hideAllDropdowns,
    showAllDropdowns,
} from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';

const GvmsCustomFieldsDrawerButton = ({ label, fieldPath }: { label: GvmsFormCustomFields; fieldPath: string }) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const handleOpenDrawer = () => {
        hideAllDropdowns();
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        showAllDropdowns();
        setDrawerOpen(false);
    };

    return (
        <>
            <StyledButton condensed title={'Codelist Menu'} onClick={handleOpenDrawer} />
            <GvmsCustomFieldsDrawer
                title={label}
                visible={drawerOpen}
                onClose={handleCloseDrawer}
                fieldPath={fieldPath}
            />
        </>
    );
};

const GvmsCustomFieldsDrawer = ({
    title,
    fieldPath,
    onClose,
    ...drawerProps
}: DrawerProps & { fieldPath: string; onClose?: () => void }) => {
    const gvmsReferenceData = useAppSelector((state) => state.gvmsReferenceData.gvmsReferenceData);
    const { getFieldHelpers, getFieldProps, values } = useFormikContext();
    const [filteredDataSourceForDrawer, setFilteredDataSourceForDrawer] = useState<
        | (
              | (GvmsReferenceData['ports'][0] & { key: React.Key })
              | (GvmsReferenceData['carriers'][0] & { key: React.Key })
          )[]
        | undefined
    >(undefined);

    const drawerDataSource = useMemo(() => {
        const dataSource =
            title === GvmsFormCustomFields.CARRIER ? gvmsReferenceData?.carriers : gvmsReferenceData?.ports;
        return dataSource?.map((obj, i) => ({ ...obj, key: `${i}_${Object.values(obj)[0]}` })) || [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gvmsReferenceData]);

    useEffect(() => {
        setFilteredDataSourceForDrawer(drawerDataSource);
    }, [drawerDataSource]);

    const handlePopulateFormField = useCallback(
        (selectedKey: React.Key) => {
            const routeIdFormikProps = getFormikProps('plannedCrossing.routeId', { getFieldHelpers, getFieldProps });
            const formFieldHelpers = getFormikProps(fieldPath, { getFieldHelpers, getFieldProps }).fieldHelper;

            const selectedRow = filteredDataSourceForDrawer?.find((codelist: any) => codelist.key === selectedKey);

            if (!selectedRow) return;

            const valueKey = 'carrierId' in selectedRow ? 'carrierId' : 'portId';
            const value = selectedRow[valueKey as keyof typeof selectedRow];

            formFieldHelpers?.setValue(String(value));
            formFieldHelpers?.setTouched(true);

            UkGvmsRecordUtils.handlePopulateRouteId(
                { name: fieldPath, value: value as any },
                values as any,
                routeIdFormikProps,
                gvmsReferenceData?.routes
            );

            onClose?.();
        },
        [
            fieldPath,
            filteredDataSourceForDrawer,
            getFieldHelpers,
            getFieldProps,
            gvmsReferenceData?.routes,
            onClose,
            values,
        ]
    );

    const drawerColumns = useMemo(
        (): ColumnsType<any> =>
            title === GvmsFormCustomFields.CARRIER
                ? [
                      {
                          title: 'Carrier ID',
                          dataIndex: 'carrierId',
                          key: 'carrierId',
                          render: (text: string, record: GvmsReferenceData['carriers'][0]) => (
                              <span>{record.carrierId || '-'}</span>
                          ),
                      },
                      {
                          title: 'Carrier Name',
                          dataIndex: 'carrierName',
                          key: 'carrierName',
                          render: (text: string, record: GvmsReferenceData['carriers'][0]) => (
                              <span>{record.carrierName || '-'}</span>
                          ),
                      },
                      {
                          title: 'Country Code',
                          dataIndex: 'countryCode',
                          key: 'countryCode',
                          render: (text: string, record: GvmsReferenceData['carriers'][0]) => (
                              <span>{record.countryCode || '-'}</span>
                          ),
                      },
                  ]
                : [
                      {
                          title: 'Port ID',
                          dataIndex: 'portId',
                          key: 'portId',
                          render: (text: string, record: GvmsReferenceData['ports'][0]) => (
                              <span>{record.portId === undefined || record.portId === null ? '-' : record.portId}</span>
                          ),
                      },
                      {
                          title: 'Port Description',
                          dataIndex: 'portDescription',
                          key: 'portDescription',
                          render: (text: string, record: GvmsReferenceData['ports'][0]) => (
                              <span>{record.portDescription || '-'}</span>
                          ),
                      },
                      {
                          title: 'Port Region',
                          dataIndex: 'portRegion',
                          key: 'portRegion',
                          render: (text: string, record: GvmsReferenceData['ports'][0]) => (
                              <span>{record.portRegion || '-'}</span>
                          ),
                      },
                      {
                          title: 'Port Country Code',
                          dataIndex: 'portCountryCode',
                          key: 'portCountryCode',
                          render: (text: string, record: GvmsReferenceData['ports'][0]) => (
                              <span>{record.portCountryCode || '-'}</span>
                          ),
                      },
                      {
                          title: 'Office Of Transit Customs Office Code',
                          dataIndex: 'officeOfTransitCustomsOfficeCode',
                          key: 'officeOfTransitCustomsOfficeCode',
                          render: (text: string, record: GvmsReferenceData['ports'][0]) => (
                              <span>
                                  {record.officeOfTransitCustomsOfficeCode === 'NotReqrd' ||
                                  !record.officeOfTransitCustomsOfficeCode
                                      ? '-'
                                      : record.officeOfTransitCustomsOfficeCode}
                              </span>
                          ),
                      },
                      {
                          title: 'CDS Port Code',
                          dataIndex: 'cdsPortCode',
                          key: 'cdsPortCode',
                          render: (text: string, record: GvmsReferenceData['ports'][0]) => (
                              <span>{record.cdsPortCode || '-'}</span>
                          ),
                      },
                  ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleSearch = useCallback(
        (input: string) => {
            if (!input) {
                setFilteredDataSourceForDrawer(drawerDataSource);
                return;
            }

            const fuse = new Fuse(drawerDataSource, { keys: drawerColumns.map((column) => column.key as string) });
            const filterResult = fuse.search(input).map((rowData: any) => rowData.item);

            setFilteredDataSourceForDrawer(filterResult);
        },
        [drawerDataSource, drawerColumns]
    );

    return (
        <Drawer title={`${title} Codelist Menu`} width={750} onClose={onClose} {...drawerProps}>
            <Col style={{ marginTop: 15 }}>
                <SearchBar
                    inputPlaceholder={`Enter ${drawerColumns.map((column) => column.title).join(', ')}`}
                    onChange={handleSearch}
                />
            </Col>
            <Col style={{ marginTop: 15 }}>
                <Table
                    dataSource={filteredDataSourceForDrawer}
                    onRow={(row) => ({
                        onClick: () => handlePopulateFormField(row.key),
                    })}
                    columns={drawerColumns}
                />
            </Col>
        </Drawer>
    );
};

export default GvmsCustomFieldsDrawerButton;
