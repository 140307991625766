import { Button, Col, Divider, Row } from 'antd';
import { H4, TextSmall, TextSmallBold } from 'components/ui/base/typography';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import { FC, Fragment } from 'react';
import { CardNameTitle, CustomModal, ProductTitle } from './ValidationErrorContainer.styles';

interface Props {
    isOpen: boolean;
    close: Function;
}

const checkIfObject = (value: any) => typeof value === 'object' && !Array.isArray(value) && value !== null;

const ValidationErrorContainer: FC<Props> = ({ isOpen, close }) => {
    const { declarationErrors } = useDeclarationFormErrors();

    return (
        <CustomModal
            title={
                <>
                    <H4>Form Errors</H4>
                </>
            }
            centered
            closable={true}
            visible={isOpen}
            onCancel={() => {
                close();
            }}
            width={800}
            cancelText="Cancel"
            zIndex={1000}
            footer={[
                <Button
                    key="goToSettings"
                    onClick={() => {
                        close();
                    }}
                    size="large"
                >
                    Close
                </Button>,
            ]}
        >
            {declarationErrors.masterDetails.map((card, i) => {
                return (
                    <Fragment key={`${card.name}_${i}`}>
                        <CardNameTitle>{card.name}</CardNameTitle>
                        <Row gutter={[16, 16]}>
                            {card.fields
                                ?.filter((entity) => !checkIfObject(entity.message))
                                .map((field, i) => {
                                    return (
                                        <Fragment key={`${field.name}_${i}`}>
                                            <Col span={8}>
                                                <TextSmallBold>{field.name}:</TextSmallBold>
                                            </Col>
                                            <Col span={16}>
                                                <TextSmall>{field.message}</TextSmall>
                                            </Col>
                                        </Fragment>
                                    );
                                })}
                        </Row>
                        <Divider />
                    </Fragment>
                );
            })}
            {declarationErrors.items.map((item) => {
                return (
                    <>
                        <ProductTitle>{`Product ${item.index}`}</ProductTitle>
                        {item?.errors?.map((error, i) => {
                            return (
                                <Fragment key={`${error.name}_${i}`}>
                                    <CardNameTitle>{error.name}</CardNameTitle>
                                    <Row gutter={[16, 16]}>
                                        {error.fields
                                            ?.filter((entity) => !checkIfObject(entity.message))
                                            .map((field, i) => {
                                                return (
                                                    <Fragment key={`${field.name}_${i}`}>
                                                        <Col span={8}>
                                                            <TextSmallBold>{field.name}:</TextSmallBold>
                                                        </Col>
                                                        <Col span={16}>
                                                            <TextSmall>{field.message}</TextSmall>
                                                        </Col>
                                                    </Fragment>
                                                );
                                            })}
                                    </Row>
                                    <Divider />
                                </Fragment>
                            );
                        })}
                    </>
                );
            })}
        </CustomModal>
    );
};

export default ValidationErrorContainer;
