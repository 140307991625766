import { WarningOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Drawer from '../../../../components/ui/base/drawer/Drawer';
import { ListPayload } from '../../../../core/http/response';
import { useRequestPromise } from '../../../../hooks/useRequest';
import { listDeclarations as listDeclarationsRequest } from '../../../../store/declarations/client';
import { DeclarationExternalEntity } from '../../../../store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from '../../../../store/declarations/enums/common/declaration-internal-type';
import { DeclarationColumnsTitles } from '../DeclarationColumns';
import DeclarationTable from '../DeclarationsTable';
import { colors } from 'theme';
import GvmsActionRequiredDrawerContent from '../../../declarations/uk/gvms/components/GvmsActionRequiredDrawerContent';

const Message = styled.div<{ loading?: boolean }>`
    color: ${colors.action};
    cursor: ${({ loading }) => (loading ? 'wait' : 'pointer')};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;

    & > * {
        color: ${colors.action};
        font-size: 23px;
    }
`;

export const filterDataColumns = (dataColumns: ColumnsType<any>) =>
    dataColumns.filter((col) => {
        if (typeof col.title !== 'string') return false;
        return [
            DeclarationColumnsTitles.DECLARATION_TYPE,
            DeclarationColumnsTitles.ID,
            DeclarationColumnsTitles.DECLARATION_STATUS,
            DeclarationColumnsTitles.LAST_MODIFIED_DATE,
        ].includes(col.title as DeclarationColumnsTitles);
    });
interface Props {
    declarationParams: {
        declarationExternalEntity: DeclarationExternalEntity[];
        declarationInternalType: DeclarationInternalType[];
    };
}

const DeclarationDashboardActionRequiredMessage = ({ declarationParams }: Props) => {
    const { pathname } = useLocation();
    const [declarationsRequireActionDrawerVisible, setDeclarationsRequireActionDrawerVisible] =
        useState<boolean>(false);

    const isGvms = pathname.includes('GVMS');

    const {
        data: declarations,
        isLoading,
        refetch: listDeclarations,
    } = useRequestPromise((params?: any) => listDeclarationsRequest(params), {
        args: [{ isActionRequired: true, ...declarationParams }],
        withSignal: true,
    });

    useEffect(() => {
        listDeclarations({ isActionRequired: true, ...declarationParams })?.catch((err) => {
            if (err.message === 'canceled') Promise.resolve();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    if (isLoading)
        return (
            <Message loading={true}>
                <Spin indicator={<LoadingOutlined />} />
                Loading {isGvms ? `GMR's` : 'declarations'} with action required...
            </Message>
        );

    if (!declarations?.list?.length) return null;

    const requiresText = isGvms ? `Inspection` : 'Documents';
    return (
        <>
            <Message onClick={() => setDeclarationsRequireActionDrawerVisible((s) => !s)}>
                {declarations?.list?.length! > 1
                    ? `Multiple ${isGvms ? `GMR's` : 'Declarations'} Require ${requiresText}`
                    : `${isGvms ? `GMR` : 'Declaration'} Requires ${requiresText}`}
                <WarningOutlined />
            </Message>

            <Drawer
                title={isGvms ? 'Action Required' : 'Declarations that require action'}
                visible={declarationsRequireActionDrawerVisible}
                onClose={() => setDeclarationsRequireActionDrawerVisible(false)}
                width={820}
                caution={true}
            >
                {isGvms ? (
                    <GvmsActionRequiredDrawerContent declarationsList={declarations?.list} />
                ) : (
                    <DeclarationTable
                        data={{ list: declarations?.list } as ListPayload<any>}
                        withPagination={false}
                        filterDataColumns={filterDataColumns}
                    />
                )}
            </Drawer>
        </>
    );
};

export default DeclarationDashboardActionRequiredMessage;
