import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { validators } from 'views/declarations/uk/export/validations/validations';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';

const AuthorisationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <>
            <FormSelect
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.customsOfficeOfDischarge`, props)}
                refNumber="4.11"
                label={t('authorisation.customsOfficeOfDischarge')}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                selectOptions={aisCodelists?.nationalCustomsOffice}
                codeValidation={[validators.exact(8)]}
                condensed
            />

            <FormSelect
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.supervisingCustomsOffice`, props)}
                refNumber="4.13"
                label={t('authorisation.supervisingCustomsOffice')}
                tooltip={getH1TooltipsByRefNumberAndField('4.13', t('authorisation.supervisingCustomsOffice'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                selectOptions={aisCodelists?.nationalCustomsOffice}
                condensed
                codeValidation={[validators.exact(8)]}
            />

            <DeclarationNumberInput
                maxLength={2}
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.period`, props)}
                refNumber="4.17"
                label={t('authorisation.period')}
                refTooltip={getH1TooltipsByRefNumberAndField('4.17', t('authorisation.period'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />

            <FormInput
                maxLength={512}
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.periodDetails`, props)}
                refNumber="4.17"
                label={t('authorisation.periodDetails')}
                tooltip={getH1TooltipsByRefNumberAndField('4.17', t('authorisation.periodDetails'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />

            <DeclarationNumberInput
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.billOfDischargeDeadline`, props)}
                refNumber="4.18"
                label={t('authorisation.billOfDischargeDeadline')}
                refTooltip={getH1TooltipsByRefNumberAndField('4.18', t('authorisation.billOfDischargeDeadline'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />

            <FormInput
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.billOfDischargeDetails`, props)}
                refNumber="4.18"
                label={t('authorisation.billOfDischargeDetails')}
                tooltip={getH1TooltipsByRefNumberAndField('4.18', t('authorisation.billOfDischargeDetails'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />

            <FormInput
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.detailsOfPlannedActivities`, props)}
                refNumber="7.5"
                label={t('authorisation.detailsOfPlannedActivities')}
                tooltip={getH1TooltipsByRefNumberAndField('7.5', t('authorisation.detailsOfPlannedActivities'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />

            <FormInput
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.additionalInformation`, props)}
                refNumber="8.5"
                label={t('authorisation.additionalInformation')}
                tooltip={getH1TooltipsByRefNumberAndField('8.5', t('authorisation.additionalInformation'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />
        </>
    );
};
export default AuthorisationCard;
