import { FC } from 'react';
import { Text } from '../../../../../../components/ui/base/typography';
import useCodelists from '../../../../../../hooks/useCodelists';
import { AesInvalidError } from '../../../../../../store/declarations/ireland/export-declaration';
import { CodeDiv, MessageDiv, ShowDetailsDiv, StyledErrorRow } from '../../DeclarationsNotificationsDrawer.styles';

const AesErrorsList: FC<
    SpecificDeclarationErrorsProps & {
        setSelectedError: any;
    }
> = ({ errors, setSelectedError }) => {
    const { aesCodelists } = useCodelists();
    return (
        <>
            {errors?.map((error: any, i: number) => {
                const isRejectedNotification = 'errorReason' in error;

                let errorMessage: string | undefined = (error as AesInvalidError).errorText;
                if (isRejectedNotification) {
                    errorMessage = aesCodelists?.functionalErrorCode.find(
                        (codelist) => codelist.id === error?.errorCode
                    )?.value;
                }

                return (
                    <StyledErrorRow justify="space-between" key={`error-row-${i}`}>
                        <CodeDiv>
                            <Text>{(isRejectedNotification ? error?.errorReason : error?.errorCode) ?? '-'}</Text>
                        </CodeDiv>
                        <MessageDiv>
                            <Text>{errorMessage ?? '-'}</Text>
                        </MessageDiv>
                        <ShowDetailsDiv>
                            <span onClick={() => setSelectedError(error)}>Show details</span>
                        </ShowDetailsDiv>
                    </StyledErrorRow>
                );
            })}
        </>
    );
};

export default AesErrorsList;
