import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';
import { SubmissionError } from './SubmissionErrorsDrawerButton';
import { DetailsGrid, FieldLink } from '../declaration-errors-drawer/declaration-errors/declaration-errors.styles';
import { Typography } from 'antd';
import { getFieldName } from 'utils/validationParserUtils';
import useProducts from 'hooks/useProducts';
import useDeclarations from 'hooks/useDeclarations';
import { useLocation, useNavigate } from 'react-router-dom';
import { goToDeclarationField } from 'views/declarations/utils/form-utils';
import { parseDeclaration } from 'views/declarations/ireland/import/h1/validation/utils';

const SubmissionErrorContainer = styled.ul``;
const SubmissionErrorRow = styled.li``;
const SubmissionErrorCode = styled.p`
    color: ${colors.lightRed};
`;

const { Text } = Typography;

interface Props {
    errors: SubmissionError[] | null | undefined;
}

interface SubmissionErrorProps {
    error: SubmissionError | null;
    onGoToField?: () => void;
}

const transformField = (field: string | null | undefined) => {
    if (field == null) return null;

    const paths = field.split('.');

    const fieldPaths: string[] = [];

    for (let path of paths) {
        if (path === 'irelandImportDeclaration') continue;

        fieldPaths.push(path);
    }

    const result = fieldPaths.join('.');
    return result;
};

const SubmissionErrorField = ({ error }: SubmissionErrorProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const inViewOnly = useMemo(() => location.pathname.includes('view-only'), [location.pathname]);
    const { products } = useProducts();
    const { declaration } = useDeclarations();

    const handleGoToField = () => {
        let fieldName = transformField(error?.field); // example: goodsShipment.governmentAgencyGoodsItem.1.governmentProcedure.currentCode

        if (fieldName == null) return;

        if (fieldName.includes('goodsShipmentItem')) {
            const productNumber = Number(fieldName.split('.')[2]) - 1;
            const product = products?.list.at(productNumber);

            fieldName = fieldName.split('.').slice(3).join('.');

            navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}/products/${product!.id}`);
        } else if (location.pathname.split('/').at(-1) !== declaration?.id) {
            navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}`);
        }
        goToDeclarationField(fieldName, 300);
    };

    const uiField = useMemo(() => {
        const field = transformField(error?.field);
        const _uiField = field && getFieldName(parseDeclaration, field);
        if (field && (!_uiField || _uiField === 'Unknown card - Unknown field')) {
            return field;
        }
        if (!field) {
            return error?.field;
        }
        return _uiField;
    }, [error?.field]);

    return (
        <>
            <DetailsGrid style={{ marginTop: '2rem' }}>
                <Text strong>Field:</Text>
                {uiField ? <FieldLink onClick={handleGoToField}>{uiField}</FieldLink> : <Text>{uiField || 'n/a'}</Text>}
            </DetailsGrid>
        </>
    );
};

const SubmissionErrorsList: FC<Props> = ({ errors }) => {
    return (
        <SubmissionErrorContainer>
            {errors?.map((error) => (
                <SubmissionErrorRow>
                    {error.code || <SubmissionErrorCode>{error.code}</SubmissionErrorCode>}
                    <SubmissionErrorField error={error}></SubmissionErrorField>
                    <span>{error?.message}</span>
                </SubmissionErrorRow>
            ))}
        </SubmissionErrorContainer>
    );
};

export default SubmissionErrorsList;
