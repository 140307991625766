import { dateStandardFormat } from 'core/utils/date';
import { parseISO } from 'date-fns';
import {
    doGetEuExportTaricCodeMeasurement,
    doGetEuGeneralTaricCodeMeasurement,
    doGetEuImportTaricCodeMeasurement,
    doGetTaricCodes,
    doGetTaricCodesAmount,
    doGetTaricCodesDetails,
    doGetTaricCodesSections,
    doGetUkExportTaricCodeMeasurement,
    doGetUkGeneralTaricCodeMeasurement,
    doGetUkImportTaricCodeMeasurement,
} from 'store/taric-codes/action';
import { TaricCodesType } from 'store/taric-codes/taric-code';
import { useAppDispatch, useAppSelector } from '../config/hooks';
const useTaricCodes = () => {
    const isLoading = useAppSelector((state) => state.taricCodes.isLoading);
    const error = useAppSelector((state) => state.taricCodes.error);
    const taricCode = useAppSelector((state) => state.taricCodes.taricCode);
    const taricCodeDetails = useAppSelector((state) => state.taricCodes.taricCodeDetails);
    const taricCodeSections = useAppSelector((state) => state.taricCodes.taricCodeSections);
    const taricCodeAmount = useAppSelector((state) => state.taricCodes.taricCodeAmount);
    const importTaricMeasurement = useAppSelector((state) => state.taricCodes.importTaricMeasurement);
    const exportTaricMeasurement = useAppSelector((state) => state.taricCodes.exportTaricMeasurement);
    const generalTaricMeasurement = useAppSelector((state) => state.taricCodes.generalTaricMeasurement);
    const dispatch = useAppDispatch();
    const getTaricCodes = (type: TaricCodesType, query: string) => {
        return dispatch(doGetTaricCodes(type, query));
    };
    const getTaricCodesDetails = (type: TaricCodesType, query: string, date?: string) => {
        if (date) {
            const d = parseISO(date);
            return dispatch(doGetTaricCodesDetails(type, query, dateStandardFormat(d)));
        }
        return dispatch(doGetTaricCodesDetails(type, query));
    };
    const getTaricCodesSections = (type: TaricCodesType) => {
        return dispatch(doGetTaricCodesSections(type));
    };
    const getTaricCodesAmount = (type: TaricCodesType, code: string, country?: string) => {
        return dispatch(doGetTaricCodesAmount(type, code, country));
    };
    const getImportTaricCodeMeasurement = (type: TaricCodesType, code: string, country?: string) => {
        if (type === TaricCodesType.EU) {
            return dispatch(doGetEuImportTaricCodeMeasurement(code, country));
        } else {
            return dispatch(doGetUkImportTaricCodeMeasurement(code, country));
        }
    };
    const getExportTaricCodeMeasurement = (type: TaricCodesType, code: string, country?: string) => {
        if (type === TaricCodesType.EU) {
            return dispatch(doGetEuExportTaricCodeMeasurement(code, country));
        } else {
            return dispatch(doGetUkExportTaricCodeMeasurement(code, country));
        }
    };
    const getGeneralTaricCodeMeasurement = (type: TaricCodesType, code: string, country?: string) => {
        if (type === TaricCodesType.EU) {
            return dispatch(doGetEuGeneralTaricCodeMeasurement(code, country));
        } else {
            return dispatch(doGetUkGeneralTaricCodeMeasurement(code, country));
        }
    };
    return {
        isLoading,
        error,
        taricCode,
        taricCodeDetails,
        taricCodeSections,
        taricCodeAmount,
        importTaricMeasurement,
        exportTaricMeasurement,
        generalTaricMeasurement,
        getTaricCodes,
        getTaricCodesDetails,
        getTaricCodesSections,
        getTaricCodesAmount,
        getImportTaricCodeMeasurement,
        getExportTaricCodeMeasurement,
        getGeneralTaricCodeMeasurement,
    };
};
export default useTaricCodes;
