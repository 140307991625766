import { parse } from 'date-fns';

const getlength = (number: number) => number.toString().length;

const getMonth = (month: number) => (getlength(month) < 2 ? `0${month}` : `${month}`);

export const dateToString = (value: any) => {
    const date = new Date(value);
    const day = date.getDate();
    const month = getMonth(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}${month}${day}`;
};

export const parseToDate = (value: string, formats: string[]): Date | undefined | null => {
    const validFormat = formats.find((format) => !isNaN(parse(value, format, new Date()).getTime()));
    if (!validFormat) return null;
    return parse(value, validFormat, new Date());
};
