const REMOVE = null;

export const irelandNctsD4ValidationItem = {
    childValidators: {
        declarationType: [],
        countryOfDispatch: REMOVE,
        countryOfDestination: REMOVE,
        referenceNumberUCR: REMOVE,
        consignee: REMOVE,
        additionalSupplyChainActor: REMOVE,
        commodity: {
            childValidators: {
                descriptionOfGoods: REMOVE,
                cusCode: REMOVE,
                commodityCode: REMOVE,
                goodsMeasure: {
                    childValidators: {
                        grossMass: REMOVE,
                        netMass: REMOVE,
                    },
                },
            },
        },
        packaging: REMOVE,
        previousDocument: REMOVE,
        supportingDocument: REMOVE,
        transportDocument: REMOVE,
        additionalReference: REMOVE,
        additionalInformation: REMOVE,
    },
};

export const irelandNctsD4ValidationHeader = {
    childValidators: {
        transitOperation: {
            childValidators: {
                declarationType: REMOVE,
                additionalDeclarationType: REMOVE,
                tirCarnetNumber: REMOVE,
                security: REMOVE,
                reducedDatasetIndicator: REMOVE,
                specificCircumstanceIndicator: REMOVE,
                bindingItinerary: REMOVE,
                limitDate: REMOVE,
            },
        },
        authorisation: REMOVE,
        customsOfficeOfDestinationDeclared: REMOVE,
        customsOfficeOfTransitDeclared: REMOVE,
        customsOfficeOfExitForTransitDeclared: REMOVE,
        guarantee: REMOVE,
        consignment: {
            childValidators: {
                countryOfDispatch: REMOVE,
                countryOfDestination: REMOVE,
                containerIndicator: REMOVE,
                inlandModeOfTransport: REMOVE,
                modeOfTransportAtTheBorder: REMOVE,
                grossMass: REMOVE,
                referenceNumberUCR: REMOVE,
                carrier: REMOVE,
                consignor: REMOVE,
                consignee: REMOVE,
                additionalSupplyChainActor: REMOVE,
                transportEquipment: REMOVE,
                countryOfRoutingOfConsignment: REMOVE,
                departureTransportMeans: REMOVE,
                previousDocument: REMOVE,
                supportingDocument: REMOVE,
                transportDocument: REMOVE,
                additionalReference: REMOVE,
                additionalInformation: REMOVE,
                houseConsignment: {
                    childValidators: {
                        countryOfDispatch: REMOVE,
                        grossMass: REMOVE,
                        referenceNumberUCR: REMOVE,
                        consignor: REMOVE,
                        consignee: REMOVE,
                        additionalSupplyChainActor: REMOVE,
                        departureTransportMeans: REMOVE,
                        previousDocument: REMOVE,
                        supportingDocument: REMOVE,
                        transportDocument: REMOVE,
                        additionalReference: REMOVE,
                        additionalInformation: REMOVE,
                        consignmentItem: irelandNctsD4ValidationItem,
                    },
                },
            },
        },
    },
};
