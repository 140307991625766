import { TablePaginationConfig, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import ConditionalWrapper from '../../../../../../components/ConditionalWrapper';
import Drawer from '../../../../../../components/ui/base/drawer/Drawer';
import { useRequestPromise } from '../../../../../../hooks/useRequest';
import { listDeclarations as listDeclarationsRequest } from '../../../../../../store/declarations/client';
import { Declaration } from '../../../../../../store/declarations/declaration';
import { DeclarationExternalEntity } from '../../../../../../store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from '../../../../../../store/declarations/enums/common/declaration-internal-type';
import { UkGvmsRecord } from '../../../../../../store/declarations/uk/gvms-declaration';
import { getTableChangeParams } from '../../../../../../utils/tableHelpers';
import { filterDataColumns } from '../../../../../custom-declaration/declaration-table/components/DeclarationDashboardActionRequiredMessage';
import DeclarationTable from '../../../../../custom-declaration/declaration-table/DeclarationsTable';
import StyledButton from '../../../../common/search-customer/SearchCustomerButton';

interface Props {
    onSelectDeclaration: (declaration: Declaration, onClose: () => void) => {};
}

const GvmsCustomsDeclarationsDrawerButton = ({ onSelectDeclaration }: Props) => {
    const formik = useFormikContext();
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const direction = (formik?.values as UkGvmsRecord)?.direction;

    return (
        <>
            <ConditionalWrapper
                condition={!direction}
                wrapper={(children) => <Tooltip title="Choose direction of the movement.">{children}</Tooltip>}
            >
                <div>
                    <StyledButton
                        condensed
                        size="small"
                        title={'Search Customs Declarations'}
                        onClick={() => setDrawerOpen(true)}
                        disabled={!direction}
                    />
                </div>
            </ConditionalWrapper>

            <GvmsCustomsDeclarationsDrawer
                visible={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onSelectDeclaration={onSelectDeclaration}
                direction={direction}
            />
        </>
    );
};

const GvmsCustomsDeclarationsDrawer = ({
    visible,
    onClose,
    onSelectDeclaration,
    direction,
}: { visible: boolean; onClose: () => void; direction: string } & Props) => {
    const getListDeclarationsParams = () => {
        const params: {
            declarationExternalEntity: DeclarationExternalEntity[];
            declarationInternalType: DeclarationInternalType[];
            hasMrn: boolean;
        } = {
            declarationExternalEntity: [DeclarationExternalEntity.CDS],
            declarationInternalType: [],
            hasMrn: true,
        };

        if (direction === 'GB_TO_NI' || direction === 'UK_OUTBOUND')
            params.declarationInternalType.push(DeclarationInternalType.EXPORT);
        else if (direction === 'NI_TO_GB' || direction === 'UK_INBOUND')
            params.declarationInternalType.push(DeclarationInternalType.IMPORT_NEW);
        else return {};

        return params;
    };

    const {
        data: declarations,
        isLoading,
        refetch: listDeclarations,
    } = useRequestPromise((params?: any) => listDeclarationsRequest(params), {
        args: [getListDeclarationsParams()],
        withSignal: true,
    });

    useEffect(() => {
        const params = getListDeclarationsParams();
        if (isEmpty(params)) return;
        listDeclarations(params)?.catch((err) => {
            if (err.message === 'canceled') Promise.resolve();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [direction]);

    const handleTableOnChange = (pagination: TablePaginationConfig) =>
        listDeclarations({ ...getListDeclarationsParams(), ...getTableChangeParams({ pagination }) });

    return (
        <Drawer title="Customs Declarations" visible={visible} onClose={onClose} width={820}>
            <DeclarationTable
                data={!direction ? null : declarations}
                filterDataColumns={filterDataColumns}
                loading={isLoading}
                onRow={(declaration) => onSelectDeclaration(declaration, onClose)}
                onChange={handleTableOnChange}
            />
        </Drawer>
    );
};

export default GvmsCustomsDeclarationsDrawerButton;
