import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { CdsExportPreviousDocument } from '../store/declarations/uk/cds-declaration';

type Props = {
    formik: FormikProps<any>;
};

const previousDocumentPath = 'goodsShipment.previousDocument';

const useCDSPreviousDocumentUtils = ({ formik }: Props) => {
    const { template, form } = useTemplateContext();

    const previousDocumentPathConstructed = useMemo(
        () => (template ? `${form}.defaults.${previousDocumentPath}` : previousDocumentPath),
        [form, template]
    );

    const previousDocument = useMemo(
        () =>
            (formik.getFieldMeta(previousDocumentPathConstructed).value ??
                []) as unknown as CdsExportPreviousDocument[],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.getFieldMeta(previousDocumentPathConstructed)]
    );

    const getIndexOfPrevDocByArg = useCallback(
        (arg: string | undefined) => previousDocument.findIndex((prevDoc) => prevDoc.typeCode === arg),
        [previousDocument]
    );

    const setDUCRMUCR = (typeCode: 'DCR' | 'MCR', code: string | undefined | null) => {
        const index = getIndexOfPrevDocByArg(typeCode);
        let newPreviousDocument = cloneDeep(previousDocument);
        if (index !== -1) {
            if (code) {
                // Update
                newPreviousDocument[index].id = code ?? undefined;
                formik.setFieldValue(`${previousDocumentPathConstructed}.${index}.id`, code);
            } else {
                // Remove
                if (index === -1) throw new Error('Cannot remove code');
                newPreviousDocument.splice(index);
            }
        } else {
            if (code) {
                // Create
                newPreviousDocument.push({ categoryCode: 'Y', typeCode, id: code ?? undefined });
                formik.setFieldValue(previousDocumentPathConstructed, newPreviousDocument);
            }
        }
        formik.setFieldValue(previousDocumentPathConstructed, newPreviousDocument);
        if (typeCode === 'DCR') formik.setFieldValue(`ducr`, code);
        return newPreviousDocument;
    };

    return { setDUCRMUCR, previousDocument, getIndexOfPrevDocByArg, previousDocumentPath };
};
export default useCDSPreviousDocumentUtils;
