import { NewButton } from 'components/ui/base/button/Button.styles';
import useDeclarations from 'hooks/useDeclarations';
import { HTMLAttributes, ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import { BtMasterInformationItemDetails } from 'views/declarations/Form.styles';
import DeclarationInput from '../declarations/formInput/DeclarationInput';
import { CloseOutlined } from '@ant-design/icons';
import { colors } from 'theme';
import { useTemplateContext } from './TemplateContext';
import { setNestedObjectValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { getRequiredMessage } from 'views/declarations/utils/validation-utils';
import { useRequestPromise } from 'hooks/useRequest';
import getAllDeclarationTemplateNames from 'utils/requests/getAllDeclarationTemplateNames';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { isEmpty } from 'lodash';
import useFormUtils from '../../../../hooks/useFormUtils';
import { SModalHeader } from './TemplateModal';

export const HeaderTitle = styled.span`
    font-weight: bold;
    white-space: nowrap;
`;
export const TemplateTitle = styled.p`
    font-size: 2rem;
    font-weight: bold;

    margin-bottom: 0;
`;
export const XButton = styled.div`
    cursor: pointer;
    margin-left: 2rem;
    font-size: 1.8rem;
    text-align: center;
    color: ${colors.lightGrey3};
    transition: color 100ms;

    :hover {
        color: initial;
        font-weight: bold;
    }
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
    active?: 'master' | 'product';
    onChangeActiveForm?: (form: 'master' | 'product') => void;
    onSave?: (templateName?: string | null) => void;
    onClose?: () => void;
    creating?: boolean;
    setEditedTemplateName?: (editedTemplateName: string | null) => void;
}

interface Form {
    templateName?: string | null;
}

const formValidation = (templateNames?: Set<string> | null) =>
    Yup.object().shape({
        templateName: Yup.string()
            .required(getRequiredMessage('field'))
            .nullable()
            .test({
                name: 'unique-name',
                message: 'This name already exists',
                test: (value, context) => {
                    if (value == null) return true;
                    return !templateNames?.has(value);
                },
            }),
    });

const TemplateHeader = (props: Props): ReactElement => {
    const { options, isViewOnly } = useTemplateContext();
    const { declaration, declarationTemplate } = useDeclarations();
    const { formType } = useFormUtils();

    const { data: templateNames } = useRequestPromise(() =>
        getAllDeclarationTemplateNames({ templateData: { ...options } })
    );

    const formik = useFormik<Form>({
        initialValues: { templateName: options?.name },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
        validateOnMount: true,
        validationSchema: formValidation(templateNames),
        onSubmit: () => {},
    });

    const toMasterInformation = () => {
        props.onChangeActiveForm?.('master');
    };

    const toItemDetails = () => {
        props.onChangeActiveForm?.('product');
    };

    const handleSave = async () => {
        const errors = await formik.validateForm();
        if (!isEmpty(errors)) {
            formik.setTouched(setNestedObjectValues(errors, true));
            return;
        }
        props.onSave?.(formik.values.templateName);
    };

    const handleClose = () => {
        props.onClose?.();
    };

    useEffect(() => {
        if (!formik.values.templateName) return;

        props.setEditedTemplateName?.(formik.values.templateName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.templateName]);

    return (
        <SModalHeader {...props}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                <TemplateTitle>Form Template Builder</TemplateTitle>

                {/* Save button (inline with the title, most right position) */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!isViewOnly && <NewButton onClick={handleSave}>Save</NewButton>}
                    <XButton onClick={handleClose}>
                        <CloseOutlined />
                    </XButton>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {!props.creating && (
                    <>
                        {/* Template information */}
                        {/* -- Template name */}
                        {/* -- Declaration type */}
                        {/* -- Country */}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Old Template Name: </span>
                                <span>{options?.name ?? declarationTemplate?.templateName}</span>
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Declaration Type: </span>
                                <span>{formType}</span>
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Country: </span>
                                <span>{options?.country ?? declaration?.declarationExternalEntity}</span>
                            </div>
                        </div>

                        {/* Template name input field */}
                        {!isViewOnly && (
                            <FormCardContainer oneColumn normal>
                                <DeclarationInput
                                    required
                                    normal
                                    condensed
                                    label="New Template Name"
                                    {...getFormikProps('templateName', formik)}
                                />
                            </FormCardContainer>
                        )}
                    </>
                )}

                {/* Declaration navigation */}
                {/* Master Information | Item Details */}
                <div>
                    <BtMasterInformationItemDetails active={props.active === 'master'} onClick={toMasterInformation}>
                        Master Information
                    </BtMasterInformationItemDetails>
                    <BtMasterInformationItemDetails active={props.active === 'product'} onClick={toItemDetails}>
                        Item Details
                    </BtMasterInformationItemDetails>
                </div>

                <FormCardContainer>
                    <HeaderTitle>Field Name</HeaderTitle>
                    <HeaderTitle style={{ margin: '0 auto' }}>View</HeaderTitle>
                    <HeaderTitle style={{ margin: '0 auto' }}>Edit</HeaderTitle>
                    <HeaderTitle>Default Value</HeaderTitle>
                </FormCardContainer>
            </div>
            <hr />
        </SModalHeader>
    );
};

export default TemplateHeader;
