import { SelectOption } from 'components/ui/composed/declarations/formSelect/DeclarationSelect';

export type Codelists = IrelandImportCodelists & IrelandExportCodelists & UkCodelists;

export interface CodelistsPayload {
    id: string;
    code: string;
    description: string;
    data: string;
    customerId: string | null;
    creatorName: string;
    createdBy: string;
    createdAt: string;
    name: string;
    type: string;
}

export interface UnLocodeExtendedPayload {
    list: CodelistsPayload[];
    pageNumber: number;
    pageSize: number;
    total: number;
}

export interface IrelandCodelistTypes {
    ais?: IrelandImportCodelists;
    aes?: IrelandExportCodelists;
    ncts?: Partial<IrelandNctsCodelists>;
}

export interface UkCodelistTypes {
    cds?: UkCodelists;
    ncts?: Partial<UkNctsCodelists>;
}

export interface IrelandNctsCodelists {
    additionalInformation: SelectOption[];
    additionalReference: SelectOption[];
    additionalSupplyChainActorRoleCode: SelectOption[];
    authorisationTypeDeparture: SelectOption[];
    authorisationTypeDestination: SelectOption[];
    businessRejectionTypeDepExp: SelectOption[];
    businessRejectionTypeDesExt: SelectOption[];
    controlResultCodeDeparture: SelectOption[];
    controlResultCodeDepartureSimplifiedExcluded: SelectOption[];
    controlResultCodeDestination: SelectOption[];
    controlType: SelectOption[];
    countryAddressPostcodeBased: SelectOption[];
    countryAddressPostcodeOnly: SelectOption[];
    countryCodesCommonTransit: SelectOption[];
    countryCodesCommunity: SelectOption[];
    countryCodesCtc: SelectOption[];
    countryCodesCustomsOfficeLists: SelectOption[];
    countryCodesForAddress: SelectOption[];
    countryCodesFullList: SelectOption[];
    countryCodesWithAddress: SelectOption[];
    countryCustomsSecurityAgreementArea: SelectOption[];
    countryGuaranteeNotValid: SelectOption[];
    countryRegime: SelectOption[];
    countryWithoutZip: SelectOption[];
    currencyCodes: SelectOption[];
    customsOfficeDeparture: SelectOption[];
    customsOfficeDestination: SelectOption[];
    customsOfficeEnquiry: SelectOption[];
    customsOfficeGuarantee: SelectOption[];
    customsOfficeRecovery: SelectOption[];
    customsOfficeTransit: SelectOption[];
    customsOfficeTransitExit: SelectOption[];
    dayInTheWeek: SelectOption[];
    declarationType: SelectOption[];
    declarationTypeAdditional: SelectOption[];
    declarationTypeItemLevel: SelectOption[];
    declarationTypeSecurity: SelectOption[];
    documentTypeExcise: SelectOption[];
    flag: SelectOption[];
    functionalErrorCodesIeCa: SelectOption[];
    geoInfoCodes: SelectOption[];
    guaranteeInvalidityReason: SelectOption[];
    guaranteeMonitoringCodes: SelectOption[];
    guaranteeType: SelectOption[];
    guaranteeTypeCtc: SelectOption[];
    guaranteeTypeEuNonTir: SelectOption[];
    guaranteeTypeWithGrn: SelectOption[];
    guaranteeTypeWithReference: SelectOption[];
    guaranteeTypeWithReferenceCredited: SelectOption[];
    hScode: SelectOption[];
    incidentCode: SelectOption[];
    invalidGuaranteeReason: SelectOption[];
    kindOfPackages: SelectOption[];
    kindOfPackagesBulk: SelectOption[];
    kindOfPackagesUnpacked: SelectOption[];
    languageByCustoms: SelectOption[];
    languageCodes: SelectOption[];
    messageTypeWithoutHeader: SelectOption[];
    messageTypes: SelectOption[];
    messageWithCorrelationIdentifier: SelectOption[];
    modificationType: SelectOption[];
    monthOfYear: SelectOption[];
    msCountry: SelectOption[];
    nationality: SelectOption[];
    nctsCountryOutsideCustomsSecurityAgreementArea: SelectOption[];
    noReleaseMotivation: SelectOption[];
    notificationType: SelectOption[];
    prefixSuffixLevel: SelectOption[];
    previousDocumentExportType: SelectOption[];
    previousDocumentType: SelectOption[];
    previousDocumentUnionGoods: SelectOption[];
    qualifierOfIdentificationIncident: SelectOption[];
    qualifierOfTheIdentification: SelectOption[];
    queryIdentifier: SelectOption[];
    rejectionCodeDepartureExport: SelectOption[];
    rejectionCodeDestinationExit: SelectOption[];
    releaseNotification: SelectOption[];
    releaseType: SelectOption[];
    representativeStatusCode: SelectOption[];
    requestedDocumentType: SelectOption[];
    responseCode: SelectOption[];
    role: SelectOption[];
    roleRequester: SelectOption[];
    specificCircumstanceIndicatorCode: SelectOption[];
    specificNotesCode: SelectOption[];
    supportingDocumentType: SelectOption[];
    trafficType: SelectOption[];
    transportChargesMethodOfPayment: SelectOption[];
    transportDocumentType: SelectOption[];
    transportModeCode: SelectOption[];
    typeOfIdentificationOfMeansOfTransport: SelectOption[];
    typeOfIdentificationofMeansOfTransportActive: SelectOption[];
    typeOfLocation: SelectOption[];
    unDangerousGoodsCode: SelectOption[];
    unLocodeExtended: SelectOption[];
    unit: SelectOption[];
    xmlErrorCodes: SelectOption[];
}

export interface IrelandExportCodelists {
    additionalDeclarationType: SelectOption[];
    additionalInformationCode: SelectOption[];
    additionalProcedure: SelectOption[];
    additionalReferenceType: SelectOption[];
    additionalSupplyChainActorRoleCode: SelectOption[];
    authorisationType: SelectOption[];
    authorisationTypeItemLevel: SelectOption[];
    businessRejectionExitType: SelectOption[];
    calculationOfTaxesMethodOfPayment: SelectOption[];
    additionalReferenceTypeNational: SelectOption[];
    commodityCusCode: SelectOption[];
    country: SelectOption[];
    countryAddressPostcodeOnly: SelectOption[];
    countryCodeEligibleForExport: SelectOption[];
    countryCodeEuTerritory: SelectOption[];
    countryCodesCommonTransitOutsideCommunity: SelectOption[];
    countryCodesCommunity: SelectOption[];
    countryCodesCountryRegimeOth: SelectOption[];
    countryCodesForAddress: SelectOption[];
    countryCommonTransit: SelectOption[];
    countryWithoutZip: SelectOption[];
    currency: SelectOption[];
    customsOfficeOfExitDeclaredReferenceNumber: SelectOption[];
    customsOfficeOfExitReferenceNumber: SelectOption[];
    customsOfficeOfExportReferenceNumber: SelectOption[];
    customsOfficeOfPresentation: SelectOption[];
    customsOfficeReferenceNumber: SelectOption[];
    declarationType: SelectOption[];
    deliveryTermsUnLocode: SelectOption[];
    diversionRejectionCode: SelectOption[];
    documentType: SelectOption[];
    enquiryInformationCode: SelectOption[];
    exitControlResultCode: SelectOption[];
    exportOperationSecurity: SelectOption[];
    exportQueryReasonCode: SelectOption[];
    flag: SelectOption[];
    functionalErrorCode: SelectOption[];
    incotermCode: SelectOption[];
    internalCurrencyUnit: SelectOption[];
    invoiceCurrency: SelectOption[];
    kindOfPackagesBulk: SelectOption[];
    kindOfPackagesUnpacked: SelectOption[];
    languageCode: SelectOption[];
    locationOfGoodsQualifierOfIdentification: SelectOption[];
    measurementUnitAndQualifier: SelectOption[];
    messageType: SelectOption[];
    messageWithCorrelationIdentifier: SelectOption[];
    nationality: SelectOption[];
    natureOfTransactionCode: SelectOption[];
    notificationType: SelectOption[];
    personConfirmingExit: SelectOption[];
    postcodeAddressCountry: SelectOption[];
    previousDocumentType: SelectOption[];
    previousProcedure: SelectOption[];
    rejectionCode: SelectOption[];
    rejectionCodeExit: SelectOption[];
    rejectionReasonType: SelectOption[];
    representativeStatusCode: SelectOption[];
    requestedProcedure: SelectOption[];
    specificCircumstanceIndicator: SelectOption[];
    summaryDeclarationRejectionReasonCode: SelectOption[];
    supportingDocumentType: SelectOption[];
    taxType: SelectOption[];
    transportChargesMethodOfPayment: SelectOption[];
    transportDocumentType: SelectOption[];
    transportMode: SelectOption[];
    typeOfAlternativeEvidence: SelectOption[];
    typeOfControls: SelectOption[];
    typeOfIdentificationOfMeansOfTransport: SelectOption[];
    typeOfIdentificationOfMeansOfTransportActive: SelectOption[];
    typeOfLocation: SelectOption[];
    typeOfPackages: SelectOption[];
    unDangerousGoodsCode: SelectOption[];
    warehouseType: SelectOption[];
}

export interface UkCodelists {
    airportCodes: SelectOption[];
    allowedAdditionalDocuments: SelectOption[];
    containerCategories: SelectOption[];
    countryGroupCategories: SelectOption[];
    countryRegionSubRoleTypes: SelectOption[];
    countryTypes: SelectOption[];
    currencyTypes: SelectOption[];
    customsWarehouseTypes: SelectOption[];
    dangerousGoodsTypes: SelectOption[];
    declarationTypes: SelectOption[];
    declaredGuaranteeTypes: SelectOption[];
    deferredPaymentDocuments: SelectOption[];
    ditrpaLicensingDocumentTypes: SelectOption[];
    documentCategories: SelectOption[];
    documentExemptionTypes: SelectOption[];
    dutyTaxFeeTypes: SelectOption[];
    exchangeRateCurrencies: SelectOption[];
    exitCustomsOffices: SelectOption[];
    exportCertificates: SelectOption[];
    exportDeclaredGuaranteeTypes: SelectOption[];
    exportDocumentCategories: SelectOption[];
    exportPreviousDocuments: SelectOption[];
    exportSpecialMentions: SelectOption[];
    exportSpecialProcedures: SelectOption[];
    ftiUnLocodes: SelectOption[];
    goodsItemValuationAdjustmentTypes: SelectOption[];
    gvmsaIcodes: SelectOption[];
    headerValuationAdjustmentTypes: SelectOption[];
    identifyableDocuments: SelectOption[];
    importCertificates: SelectOption[];
    importPreviousDocuments: SelectOption[];
    importPreviousProcedures: SelectOption[];
    importRequestedProcedures: SelectOption[];
    importSpecialMentions: SelectOption[];
    importSpecialProcedures: SelectOption[];
    incoTermCodes: SelectOption[];
    locationIdentificationTypes: SelectOption[];
    locationTypes: SelectOption[];
    measureUnitQualifiers: SelectOption[];
    measureUnitTypes: SelectOption[];
    packageTypes: SelectOption[];
    partyRoleAuthorizationTypes: SelectOption[];
    partyRoleStatusTypes: SelectOption[];
    partySubRoleTypes: SelectOption[];
    paymentMethodTypes: SelectOption[];
    preferenceTypes: SelectOption[];
    procedureCombinations: SelectOption[];
    reasonDocuments: SelectOption[];
    specialProcedureTypes: SelectOption[];
    specificCircumstanceTypes: SelectOption[];
    statusDocuments: SelectOption[];
    supervisingCustomsOffices: SelectOption[];
    tradeMovementTypes: SelectOption[];
    transactionNatureTypes: SelectOption[];
    transportMeansIdentificationTypes: SelectOption[];
    transportModeTypes: SelectOption[];
    transportPaymentMethodTypes: SelectOption[];
    unLocodes: SelectOption[];
    valuationIndicatorTypes: SelectOption[];
    valuationMethodTypes: SelectOption[];
    vatPartySubRoleTypes: SelectOption[];
    phoneCodeList: SelectOption[];
}

export interface UkNctsCodelists {
    additionalInformation: SelectOption[];
    additionalReference: SelectOption[];
    additionalSupplyChainActorRoleCode: SelectOption[];
    authorisationTypeDeparture: SelectOption[];
    authorisationTypeDestination: SelectOption[];
    businessRejectionTypeDepExp: SelectOption[];
    businessRejectionTypeDesExt: SelectOption[];
    controlResultCodeDeparture: SelectOption[];
    controlResultCodeDepartureSimplifiedExcluded: SelectOption[];
    controlResultCodeDestination: SelectOption[];
    controlType: SelectOption[];
    countryAddressPostcodeBased: SelectOption[];
    countryAddressPostcodeOnly: SelectOption[];
    countryCodesCommonTransit: SelectOption[];
    countryCodesCommunity: SelectOption[];
    countryCodesCtc: SelectOption[];
    countryCodesCustomsOfficeLists: SelectOption[];
    countryCodesForAddress: SelectOption[];
    countryCodesFullList: SelectOption[];
    countryCodesWithAddress: SelectOption[];
    countryCustomsSecurityAgreementArea: SelectOption[];
    countryGuaranteeNotValid: SelectOption[];
    countryRegime: SelectOption[];
    countryWithoutZip: SelectOption[];
    currencyCodes: SelectOption[];
    customsOfficeDeparture: SelectOption[];
    customsOfficeDestination: SelectOption[];
    customsOfficeEnquiry: SelectOption[];
    customsOfficeGuarantee: SelectOption[];
    customsOfficeRecovery: SelectOption[];
    customsOfficeTransit: SelectOption[];
    customsOfficeTransitExit: SelectOption[];
    dayInTheWeek: SelectOption[];
    declarationType: SelectOption[];
    declarationTypeAdditional: SelectOption[];
    declarationTypeItemLevel: SelectOption[];
    declarationTypeSecurity: SelectOption[];
    documentTypeExcise: SelectOption[];
    flag: SelectOption[];
    functionalErrorCodesIeCa: SelectOption[];
    geoInfoCodes: SelectOption[];
    guaranteeInvalidityReason: SelectOption[];
    guaranteeMonitoringCodes: SelectOption[];
    guaranteeType: SelectOption[];
    guaranteeTypeCtc: SelectOption[];
    guaranteeTypeEuNonTir: SelectOption[];
    guaranteeTypeWithGrn: SelectOption[];
    guaranteeTypeWithReference: SelectOption[];
    guaranteeTypeWithReferenceCredited: SelectOption[];
    hScode: SelectOption[];
    incidentCode: SelectOption[];
    invalidGuaranteeReason: SelectOption[];
    kindOfPackages: SelectOption[];
    kindOfPackagesBulk: SelectOption[];
    kindOfPackagesUnpacked: SelectOption[];
    languageByCustoms: SelectOption[];
    languageCodes: SelectOption[];
    messageTypeWithoutHeader: SelectOption[];
    messageTypes: SelectOption[];
    messageWithCorrelationIdentifier: SelectOption[];
    modificationType: SelectOption[];
    monthOfYear: SelectOption[];
    msCountry: SelectOption[];
    nationality: SelectOption[];
    nctsCountryOutsideCustomsSecurityAgreementArea: SelectOption[];
    noReleaseMotivation: SelectOption[];
    notificationType: SelectOption[];
    prefixSuffixLevel: SelectOption[];
    previousDocumentExportType: SelectOption[];
    previousDocumentType: SelectOption[];
    previousDocumentUnionGoods: SelectOption[];
    qualifierOfIdentificationIncident: SelectOption[];
    qualifierOfTheIdentification: SelectOption[];
    queryIdentifier: SelectOption[];
    rejectionCodeDepartureExport: SelectOption[];
    rejectionCodeDestinationExit: SelectOption[];
    releaseNotification: SelectOption[];
    releaseType: SelectOption[];
    representativeStatusCode: SelectOption[];
    requestedDocumentType: SelectOption[];
    responseCode: SelectOption[];
    role: SelectOption[];
    roleRequester: SelectOption[];
    specificCircumstanceIndicatorCode: SelectOption[];
    specificNotesCode: SelectOption[];
    supportingDocumentType: SelectOption[];
    trafficType: SelectOption[];
    transportChargesMethodOfPayment: SelectOption[];
    transportDocumentType: SelectOption[];
    transportModeCode: SelectOption[];
    typeOfIdentificationOfMeansOfTransport: SelectOption[];
    typeOfIdentificationofMeansOfTransportActive: SelectOption[];
    typeOfLocation: SelectOption[];
    unDangerousGoodsCode: SelectOption[];
    unLocodeExtended: SelectOption[];
    unit: SelectOption[];
    xmlErrorCodes: SelectOption[];
}

export enum UkCodelistsNamesForServer {
    airportCodes = 'AIRPORT_CODES',
    allowedAdditionalDocuments = 'ALLOWED_ADDITIONAL_DOCUMENTS',
    containerCategories = 'CONTAINER_CATEGORIES',
    countryGroupCategories = 'COUNTRY_GROUP_CATEGORIES',
    countryRegionSubRoleTypes = 'COUNTRY_REGION_SUB_ROLE_TYPES',
    countryTypes = 'COUNTRY_TYPES',
    currencyTypes = 'CURRENCY_TYPES',
    customsWarehouseTypes = 'CUSTOMS_WAREHOUSE_TYPES',
    dangerousGoodsTypes = 'DANGEROUS_GOODS_TYPES',
    declarationTypes = 'DECLARATION_TYPES',
    declaredGuaranteeTypes = 'DECLARED_GUARANTEE_TYPES',
    deferredPaymentDocuments = 'DEFERRED_PAYMENT_DOCUMENTS',
    ditrpaLicensingDocumentTypes = 'DITRPA_LICENSING_DOCUMENT_TYPES',
    documentCategories = 'DOCUMENT_CATEGORIES',
    documentExemptionTypes = 'DOCUMENT_EXEMPTION_TYPES',
    dutyTaxFeeTypes = 'DUTY_TAX_FEE_TYPES',
    exchangeRateCurrencies = 'EXCHANGE_RATE_CURRENCIES',
    exitCustomsOffices = 'EXIT_CUSTOMS_OFFICES',
    exportCertificates = 'EXPORT_CERTIFICATES',
    exportDeclaredGuaranteeTypes = 'EXPORT_DECLARED_GUARANTEE_TYPES',
    exportDocumentCategories = 'EXPORT_DOCUMENT_CATEGORIES',
    exportPreviousDocuments = 'EXPORT_PREVIOUS_DOCUMENTS',
    exportSpecialMentions = 'EXPORT_SPECIAL_MENTIONS',
    exportSpecialProcedures = 'EXPORT_SPECIAL_PROCEDURES',
    ftiUnLocodes = 'FTI_UNLOCODES',
    goodsItemValuationAdjustmentTypes = 'GOODS_ITEM_VALUATION_ADJUSTMENT_TYPES',
    gvmsaIcodes = 'GVMSA_ICODES',
    headerValuationAdjustmentTypes = 'HEADER_VALUATION_ADJUSTMENT_TYPES',
    identifyableDocuments = 'IDENTIFYABLE_DOCUMENTS',
    importCertificates = 'IMPORT_CERTIFICATES',
    importPreviousDocuments = 'IMPORT_PREVIOUS_DOCUMENTS',
    importPreviousProcedures = 'IMPORT_PREVIOUS_PROCEDURES',
    importRequestedProcedures = 'IMPORT_REQUESTED_PROCEDURES',
    importSpecialMentions = 'IMPORT_SPECIAL_MENTIONS',
    importSpecialProcedures = 'IMPORT_SPECIAL_PROCEDURES',
    incoTermCodes = 'INCOTERM_CODES',
    locationIdentificationTypes = 'LOCATION_IDENTIFICATION_TYPES',
    locationTypes = 'LOCATION_TYPES',
    measureUnitQualifiers = 'MEASURE_UNIT_QUALIFIERS',
    measureUnitTypes = 'MEASURE_UNIT_TYPES',
    packageTypes = 'PACKAGE_TYPES',
    partyRoleAuthorizationTypes = 'PARTY_ROLE_AUTHORIZATION_TYPES',
    partyRoleStatusTypes = 'PARTY_ROLE_STATUS_TYPES',
    partySubRoleTypes = 'PARTY_SUB_ROLE_TYPES',
    paymentMethodTypes = 'PAYMENT_METHOD_TYPES',
    preferenceTypes = 'PREFERENCE_TYPES',
    procedureCombinations = 'PROCEDURE_COMBINATIONS',
    reasonDocuments = 'REASON_DOCUMENTS',
    specialProcedureTypes = 'SPECIAL_PROCEDURE_TYPES',
    specificCircumstanceTypes = 'SPECIFIC_CIRCUMSTANCE_TYPES',
    statusDocuments = 'STATUS_DOCUMENTS',
    supervisingCustomsOffices = 'SUPERVISING_CUSTOMS_OFFICES',
    tradeMovementTypes = 'TRADE_MOVEMENT_TYPES',
    transactionNatureTypes = 'TRANSACTION_NATURE_TYPES',
    transportMeansIdentificationTypes = 'TRANSPORT_MEANS_IDENTIFICATION_TYPES',
    transportModeTypes = 'TRANSPORT_MODE_TYPES',
    transportPaymentMethodTypes = 'TRANSPORT_PAYMENT_METHOD_TYPES',
    unLocodes = 'UNLOCODES',
    valuationIndicatorTypes = 'VALUATION_INDICATOR_TYPES',
    valuationMethodTypes = 'VALUATION_METHOD_TYPES',
    vatPartySubRoleTypes = 'VAT_PARTY_SUB_ROLE_TYPES',
    phoneCodeList = 'PHONE_CODE_LIST',
}

export interface IrelandImportCodelists {
    additionalDeclarationType: SelectOption[];
    additionalFiscalRefRoleCode: SelectOption[];
    additionalInformationCode: SelectOption[];
    additionalProcedure: SelectOption[];
    additionsDeductionsCode: SelectOption[];
    authorisationTypeCode: SelectOption[];
    baseMeasurement: SelectOption[];
    commonDocumentsType: SelectOption[];
    controlAgency: SelectOption[];
    controlResult: SelectOption[];
    controlType: SelectOption[];
    countryCodesEU: SelectOption[];
    countryCodesFullList: SelectOption[];
    currencies: SelectOption[];
    declarationRejectionReason: SelectOption[];
    destinationRegionCode: SelectOption[];
    errorTypes: SelectOption[];
    flag: SelectOption[];
    guaranteeType: SelectOption[];
    identificationOfGoodsCode: SelectOption[];
    importDeclarationDatasetCodes: SelectOption[];
    incotermCode: SelectOption[];
    indentificationOfGoodsCode: SelectOption[];
    kindOfPackages: SelectOption[];
    legalBasis: SelectOption[];
    locationIdentificationQualifier: SelectOption[];
    locationOfGoodsCode: SelectOption[];
    locationType: SelectOption[];
    meansIdentityType: SelectOption[];
    nationalCustomsOffice: SelectOption[];
    natureOfTransaction: SelectOption[];
    nomenclatureCode: SelectOption[];
    nomenclatureCodeCN: SelectOption[];
    nomenclatureCodeHS: SelectOption[];
    preference: SelectOption[];
    previousDocumentType: SelectOption[];
    procedureCode: SelectOption[];
    processingProcedureCode: SelectOption[];
    quotaNo: SelectOption[];
    representativeStatusCode: SelectOption[];
    supplyChainActorRoleCode: SelectOption[];
    taricAdditionalCode: SelectOption[];
    taricNationalAdditionalCode: SelectOption[];
    taxPaymentMethod: SelectOption[];
    taxType: SelectOption[];
    temporaryStorageDatasetCodes: SelectOption[];
    transportDocument: SelectOption[];
    transportMode: SelectOption[];
    typeOfDeclaration: SelectOption[];
    valuationIndicators: SelectOption[];
    valuationMethod: SelectOption[];
    warehouseType: SelectOption[];
    phoneCodeList: SelectOption[];
}

export enum IrelandCodelistsNamesForServer {
    // AIS code list names
    additionalDeclarationType = 'ADDITIONAL_DECLARATION_TYPE',
    additionalFiscalRefRoleCode = 'ADDITIONAL_FISCAL_REF_ROLE_CODE',
    additionalInformationCode = 'ADDITIONAL_INFORMATION_CODE',
    additionalProcedure = 'ADDITIONAL_PROCEDURE',
    additionsDeductionsCode = 'ADDITIONS_DEDUCTIONS_CODE',
    authorisationTypeCode = 'AUTHORISATION_TYPE_CODE',
    baseMeasurement = 'BASE_MEASUREMENT',
    commonDocumentsType = 'COMMON_DOCUMENTS_TYPE',
    controlAgency = 'CONTROL_AGENCY',
    controlResult = 'CONTROL_RESULT',
    controlType = 'CONTROL_TYPE',
    countryCodesEU = 'COUNTRY_CODES_EU',
    countryCodesFullList = 'COUNTRY_CODES_FULL_LIST',
    currencies = 'CURRENCIES',
    declarationRejectionReason = 'DECLARATION_REJECTION_REASON',
    destinationRegionCode = 'DESTINATION_REGION_CODE',
    errorTypes = 'ERROR_TYPES',
    flag = 'FLAG',
    guaranteeType = 'GUARANTEE_TYPE',
    identificationOfGoodsCode = 'IDENTIFICATION_OF_GOODS_CODE',
    importDeclarationDatasetCodes = 'IMPORT_DECLARATION_DATASET_CODES',
    incotermCode = 'INCOTERM_CODE',
    indentificationOfGoodsCode = 'INDENTIFICATION_OF_GOODS_CODE',
    kindOfPackages = 'KIND_OF_PACKAGES',
    legalBasis = 'LEGAL_BASIS',
    locationIdentificationQualifier = 'LOCATION_IDENTIFICATION_QUALIFIER',
    locationOfGoodsCode = 'LOCATION_OF_GOODS_CODE',
    locationType = 'LOCATION_TYPE',
    meansIdentityType = 'MEANS_IDENTITY_TYPE',
    nationalCustomsOffice = 'NATIONAL_CUSTOMS_OFFICE',
    natureOfTransaction = 'NATURE_OF_TRANSACTION',
    nomenclatureCode = 'NOMENCLATURE_CODE',
    nomenclatureCodeCN = 'NOMENCLATURE_CODE_CN',
    nomenclatureCodeHS = 'NOMENCLATURE_CODE_HS',
    preference = 'PREFERENCE',
    previousDocumentType = 'PREVIOUS_DOCUMENT_TYPE',
    procedureCode = 'PROCEDURE_CODE',
    processingProcedureCode = 'PROCESSING_PROCEDURE_CODE',
    quotaNo = 'QUOTA_NO',
    representativeStatusCode = 'REPRESENTATIVE_STATUS_CODE',
    supplyChainActorRoleCode = 'SUPPLY_CHAIN_ACTOR_ROLE_CODE',
    taricAdditionalCode = 'TARIC_ADDITIONAL_CODE',
    taricNationalAdditionalCode = 'TARIC_NATIONAL_ADDITIONAL_CODE',
    taxPaymentMethod = 'TAX_PAYMENT_METHOD',
    taxType = 'TAX_TYPE',
    temporaryStorageDatasetCodes = 'TEMPORARY_STORAGE_DATASET_CODES',
    transportDocument = 'TRANSPORT_DOCUMENT',
    transportMode = 'TRANSPORT_MODE',
    typeOfDeclaration = 'TYPE_OF_DECLARATION',
    valuationIndicators = 'VALUATION_INDICATORS',
    valuationMethod = 'VALUATION_METHOD',
    warehouseType = 'WAREHOUSE_TYPE',
    phoneCodeList = 'PHONE_CODE_LIST',

    // AES code list names
    additionalReferenceType = 'ADDITIONAL_REFERENCE_TYPE',
    additionalSupplyChainActorRoleCode = 'ADDITIONAL_SUPPLY_CHAIN_ACTOR_ROLE_CODE',
    authorisationType = 'AUTHORISATION_TYPE',
    authorisationTypeItemLevel = 'AUTHORISATION_TYPE_ITEM_LEVEL',
    businessRejectionExitType = 'BUSINESS_REJECTION_EXIT_TYPE',
    calculationOfTaxesMethodOfPayment = 'CALCULATION_OF_TAXES_METHOD_OF_PAYMENT',
    additionalReferenceTypeNational = 'CL380_NCL_ADDITIONAL_REFERENCE_TYPE_NATIONAL',
    commodityCusCode = 'COMMODITY_CUS_CODE',
    country = 'COUNTRY',
    countryAddressPostcodeOnly = 'COUNTRY_ADDRESS_POSTCODE_ONLY',
    countryCodeEligibleForExport = 'COUNTRY_CODE_ELIGIBLE_FOR_EXPORT',
    countryCodeEuTerritory = 'COUNTRY_CODE_EU_TERRITORY',
    countryCodesCommonTransitOutsideCommunity = 'COUNTRY_CODES_COMMON_TRANSIT_OUTSIDE_COMMUNITY',
    countryCodesCommunity = 'COUNTRY_CODES_COMMUNITY',
    countryCodesCountryRegimeOth = 'COUNTRY_CODES_COUNTRY_REGIME_OTH',
    countryCodesForAddress = 'COUNTRY_CODES_FOR_ADDRESS',
    countryCommonTransit = 'COUNTRY_COMMON_TRANSIT',
    countryWithoutZip = 'COUNTRY_WITHOUT_ZIP',
    currency = 'CURRENCY',
    customsOfficeOfExitDeclaredReferenceNumber = 'CUSTOMS_OFFICE_OF_EXIT_DECLARED_REFERENCE_NUMBER',
    customsOfficeOfExitReferenceNumber = 'CUSTOMS_OFFICE_OF_EXIT_REFERENCE_NUMBER',
    customsOfficeOfExportReferenceNumber = 'CUSTOMS_OFFICE_OF_EXPORT_REFERENCE_NUMBER',
    customsOfficeOfPresentation = 'CUSTOMS_OFFICE_OF_PRESENTATION',
    customsOfficeReferenceNumber = 'CUSTOMS_OFFICE_REFERENCE_NUMBER',
    declarationType = 'DECLARATION_TYPE',
    deliveryTermsUnLocode = 'DELIVERY_TERMS_UN_LOCODE',
    diversionRejectionCode = 'DIVERSION_REJECTION_CODE',
    documentType = 'DOCUMENT_TYPE',
    enquiryInformationCode = 'ENQUIRY_INFORMATION_CODE',
    exitControlResultCode = 'EXIT_CONTROL_RESULT_CODE',
    exportOperationSecurity = 'EXPORT_OPERATION_SECURITY',
    exportQueryReasonCode = 'EXPORT_QUERY_REASON_CODE',
    functionalErrorCode = 'FUNCTIONAL_ERROR_CODE',
    internalCurrencyUnit = 'INTERNAL_CURRENCY_UNIT',
    invoiceCurrency = 'INVOICE_CURRENCY',
    kindOfPackagesBulk = 'KIND_OF_PACKAGES_BULK',
    kindOfPackagesUnpacked = 'KIND_OF_PACKAGES_UNPACKED',
    languageCode = 'LANGUAGE_CODE',
    locationOfGoodsQualifierOfIdentification = 'LOCATION_OF_GOODS_QUALIFIER_OF_IDENTIFICATION',
    measurementUnitAndQualifier = 'MEASUREMENT_UNIT_AND_QUALIFIER',
    messageType = 'MESSAGE_TYPE',
    messageWithCorrelationIdentifier = 'MESSAGE_WITH_CORRELATION_IDENTIFIER',
    nationality = 'NATIONALITY',
    natureOfTransactionCode = 'NATURE_OF_TRANSACTION_CODE',
    notificationType = 'NOTIFICATION_TYPE',
    personConfirmingExit = 'PERSON_CONFIRMING_EXIT',
    postcodeAddressCountry = 'POSTCODE_ADDRESS_COUNTRY',
    previousProcedure = 'PREVIOUS_PROCEDURE',
    rejectionCode = 'REJECTION_CODE',
    rejectionCodeExit = 'REJECTION_CODE_EXIT',
    rejectionReasonType = 'REJECTION_REASON_TYPE',
    requestedProcedure = 'REQUESTED_PROCEDURE',
    specificCircumstanceIndicator = 'SPECIFIC_CIRCUMSTANCE_INDICATOR',
    summaryDeclarationRejectionReasonCode = 'SUMMARY_DECLARATION_REJECTION_REASON_CODE',
    supportingDocumentType = 'SUPPORTING_DOCUMENT_TYPE',
    transportChargesMethodOfPayment = 'TRANSPORT_CHARGES_METHOD_OF_PAYMENT',
    transportDocumentType = 'TRANSPORT_DOCUMENT_TYPE',
    typeOfAlternativeEvidence = 'TYPE_OF_ALTERNATIVE_EVIDENCE',
    typeOfControls = 'TYPE_OF_CONTROLS',
    typeOfIdentificationOfMeansOfTransport = 'TYPE_OF_IDENTIFICATION_OF_MEANS_OF_TRANSPORT',
    typeOfIdentificationOfMeansOfTransportActive = 'TYPE_OF_IDENTIFICATION_OF_MEANS_OF_TRANSPORT_ACTIVE',
    typeOfLocation = 'TYPE_OF_LOCATION',
    typeOfPackages = 'TYPE_OF_PACKAGES',
    unDangerousGoodsCode = 'UN_DANGEROUS_GOODS_CODE',

    //NCTS code list names
    additionalInformation = 'ADDITIONAL_INFORMATION',
    additionalReference = 'ADDITIONAL_REFERENCE',
    businessRejectionTypeDepExp = 'BUSINESS_REJECTION_TYPE_DEP_EXP',
    businessRejectionTypeDesExt = 'BUSINESS_REJECTION_TYPE_DES_EXT',
    controlResultCodeDeparture = 'CONTROL_RESULT_CODE_DEPARTURE',
    countryAddressPostcodeBased = 'COUNTRY_ADDRESS_POSTCODE_BASED',
    countryCodesCommonTransit = 'COUNTRY_CODES_COMMON_TRANSIT',
    countryCodesCtc = 'COUNTRY_CODES_CTC',
    countryCodesCustomsOfficeLists = 'COUNTRY_CODES_CUSTOMS_OFFICE_LISTS',
    countryCustomsSecurityAgreementArea = 'COUNTRY_CUSTOMS_SECURITY_AGREEMENT_AREA',
    countryGuaranteeNotValid = 'COUNTRY_GUARANTEE_NOT_VALID',
    customsOfficeDeparture = 'CUSTOMS_OFFICE_DEPARTURE',
    customsOfficeDestination = 'CUSTOMS_OFFICE_DESTINATION',
    customsOfficeEnquiry = 'CUSTOMS_OFFICE_ENQUIRY',
    customsOfficeGuarantee = 'CUSTOMS_OFFICE_GUARANTEE',
    customsOfficeRecovery = 'CUSTOMS_OFFICE_RECOVERY',
    customsOfficeTransit = 'CUSTOMS_OFFICE_TRANSIT',
    customsOfficeTransitExit = 'CUSTOMS_OFFICE_TRANSIT_EXIT',
    declarationTypeAdditional = 'DECLARATION_TYPE_ADDITIONAL',
    declarationTypeItemLevel = 'DECLARATION_TYPE_ITEM_LEVEL',
    declarationTypeSecurity = 'DECLARATION_TYPE_SECURITY',
    functionalErrorCodesIeCa = 'FUNCTIONAL_ERROR_CODES_IECA',
    guaranteeInvalidityReason = 'GUARANTEE_INVALIDITY_REASON',
    guaranteeMonitoringCodes = 'GUARANTEE_MONITORING_CODES',
    guaranteeTypeWithReference = 'GUARANTEE_TYPE_WITH_REFERENCE',
    hScode = 'HS_CODE',
    incidentCode = 'INCIDENT_CODE',
    invalidGuaranteeReason = 'INVALID_GUARANTEE_REASON',
    languageByCustoms = 'LANGUAGE_BY_CUSTOMS',
    messageTypes = 'MESSAGE_TYPES',
    noReleaseMotivation = 'NO_RELEASE_MOTIVATION',
    previousDocumentExportType = 'PREVIOUS_DOCUMENT_EXPORT_TYPE',
    previousDocumentUnionGoods = 'PREVIOUS_DOCUMENT_UNION_GOODS',
    qualifierOfIdentificationIncident = 'QUALIFIER_OF_IDENTIFICATION_INCIDENT',
    qualifierOfTheIdentification = 'QUALIFIER_OF_THE_IDENTIFICATION',
    queryIdentifier = 'QUERY_IDENTIFIER',
    rejectionCodeDepartureExport = 'REJECTION_CODE_DEPARTURE_EXPORT',
    rejectionCodeDestinationExit = 'REJECTION_CODE_DESTINATION_EXIT',
    roleRequester = 'ROLE_REQUESTER',
    specificCircumstanceIndicatorCode = 'SPECIFIC_CIRCUMSTANCE_INDICATOR_CODE',
    transportModeCode = 'TRANSPORT_MODE_CODE',
    unLocodeExtended = 'UN_LOCODE_EXTENDED',
    unit = 'UNIT',
}
