import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { ParsedCard, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { CdsDeclaration, CdsDeclarationPayloadPayload } from 'store/declarations/uk/cds-declaration';
import {
    parseFieldErrorsForCard,
    parseFieldErrorsForCdsCustomerCard,
    parseFieldErrorsForMultipleCard,
} from 'utils/validationParserUtils';

const parseValidationErrors = (validation: FormikErrors<CdsDeclaration>) => {
    const masterDetailsErrors: ParsedCard[] = [];
    // TODO Put card parsers
    parseFieldErrorsForCard(
        'Master Details',
        [
            { typeCode: 'Declaration Type' },
            { additionalTypeCode: 'Additional Declaration Type' },
            { specificCircumstancesCodeCode: 'Specific Circumstances Indicator' },
            { goodsItemQuantity: 'Number of items' },
            { 'goodsShipment.ucr.traderAssignedReferenceID': 'UCR' },
            { 'consignment.freight.paymentMethodCode': 'Method of payment for transport charges' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCdsCustomerCard('Exporter', 'exporter', masterDetailsErrors, validation);
    parseFieldErrorsForCdsCustomerCard('Consignor', 'goodsShipment.consignor', masterDetailsErrors, validation);
    parseFieldErrorsForCdsCustomerCard('Consignee', 'goodsShipment.consignee', masterDetailsErrors, validation);
    parseFieldErrorsForCdsCustomerCard('Declarant', 'declarant', masterDetailsErrors, validation);
    parseFieldErrorsForCdsCustomerCard('Representative', 'agent', masterDetailsErrors, validation);
    parseFieldErrorsForCdsCustomerCard('Carrier', 'consignment.carrier', masterDetailsErrors, validation);
    parseFieldErrorsForCdsCustomerCard('Importer', 'goodsShipment.importer', masterDetailsErrors, validation);
    parseFieldErrorsForCdsCustomerCard('Seller', 'goodsShipment.seller', masterDetailsErrors, validation);
    parseFieldErrorsForCdsCustomerCard('Buyer', 'goodsShipment.buyer', masterDetailsErrors, validation);

    parseFieldErrorsForMultipleCard(
        'Holder of the authorisation identification No.',
        'authorisationHolder',
        [{ id: 'Identification number' }, { categoryCode: 'Authorisation type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Identification of Warehouse',
        [
            { 'goodsShipment.warehouse.id': 'Warehouse Identifier' },
            { 'goodsShipment.warehouse.typeCode': 'Warehouse type' },
            { 'goodsShipment.warehouse': '' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Total Amount Invoiced',
        [
            { invoiceAmount: 'Total Amount Invoiced - Amount' },
            { invoiceAmountCurrencyId: 'Total Amount Invoiced - Currency' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Exchange Rate',
        [{ 'currencyExchange.rateNumeric': 'Exchange Rate' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Country of Destination',
        [
            { 'goodsShipment.destination.countryCode': 'Country of destination code' },
            { 'goodsShipment.exportCountry.id': 'Country of dispatch / export code' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Place of Loading - Airport Code',
        [{ 'goodsShipment.consignment.loadingLocation.id': 'Place of Loading - Airport Code' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Location of Goods',
        [
            { 'goodsShipment.consignment.goodsLocation.id': 'Additional Identifier' },
            { 'goodsShipment.consignment.goodsLocation.name': 'Identification of location' },
            { 'goodsShipment.consignment.goodsLocation.typeCode': 'Goods Location Type' },
            { 'goodsShipment.consignment.goodsLocation.address.countryCode': 'Country' },
            { 'goodsShipment.consignment.goodsLocation.address.typeCode': 'Goods Location Qualifier' },
            { 'goodsShipment.consignment.goodsLocation': '' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Supervising customs office',
        [{ 'supervisingOffice.id': 'Supervising customs office' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Customs Office of Presentation',
        [{ 'presentationOffice.id': 'Customs Office of Presentation' }, { 'exitOffice.id': 'Customs Office of Exit' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Gross Mass',
        [
            { totalGrossMassMeasure: 'Gross Mass' },
            { totalPackageQuantity: 'Total Packages' },
            { 'goodsShipment.consignment.containerCode': 'Container' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Mode of Transport At The Border',
        [
            { 'borderTransportMeans.modeCode': 'Mode of Transport At The Border' },
            { 'goodsShipment.consignment.departureTransportMeans.modeCode': 'Inland mode of transport' },
            {
                'borderTransportMeans.registrationNationalityCode':
                    'Nationality of active means of transport crossing the border',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Identity of means of transport on arrival',
        [
            { 'goodsShipment.consignment.arrivalTransportMeans.identificationTypeCode': 'Type of Identification' },
            { 'goodsShipment.consignment.arrivalTransportMeans.id': 'Identification Number' },
            { 'goodsShipment.consignment.arrivalTransportMeans': '' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Identity of means of transport at departure',
        [
            { 'goodsShipment.consignment.departureTransportMeans.identificationTypeCode': 'Type of Identification' },
            { 'goodsShipment.consignment.departureTransportMeans.id': 'Identification Number' },
            { 'goodsShipment.consignment.departureTransportMeans': '' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Identity of active means of transport crossing the border',
        [
            { 'borderTransportMeans.identificationTypeCode': 'Type of Identification' },
            { 'borderTransportMeans.id': 'Identification Number' },
            { borderTransportMeans: '' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Countries Of Routing Codes',
        'consignment.itinerary',
        [{ sequenceNumeric: 'Sequence Numeric' }, { routingCountryCode: 'Routing Country Code' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Guarantee Reference',
        'obligationGuarantee',
        [
            { securityDetailsCode: 'Guarantee Type' },
            { referenceId: 'GRN' },
            { id: 'Other Guarantee reference' },
            { accessCode: 'Access Code' },
            { amountAmount: 'Amount of import duty' },
            { amountAmountCurrencyId: 'Currency' },
            { 'guaranteeOffice.id': 'Customs office of guarantee' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Nature of Transaction',
        [{ 'goodsShipment.transactionNatureCode': 'Nature of Transaction' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard('DUCR/MUCR', [{ ducr: 'DUCR' }], masterDetailsErrors, validation);

    parseFieldErrorsForMultipleCard(
        'Previous Documents',
        'goodsShipment.previousDocument',
        [
            { categoryCode: 'Previous Document Category' },
            { typeCode: 'Previous Document Type' },
            { id: 'Previous Document Reference' },
            { lineNumeric: 'Previous Document Identifier' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'additionalInformation',
        [
            { statementCode: 'Additional Information Code' },
            { statementDescription: 'Additional Information Description' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Deferred Payment',
        'additionalDocument',
        [{ id: 'Deferred Payment ID' }, { categoryAndType: 'Category and Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Fiscal References Identification',
        'goodsShipment.domesticDutyTaxParty',
        [{ id: 'Identifier' }, { roleCode: 'Code' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Delivery Terms',
        [
            { 'goodsShipment.tradeTerms.conditionCode': 'INCOTERM code' },
            { 'goodsShipment.tradeTerms.locationId': 'UN/LOCODE code' },
            { 'goodsShipment.tradeTerms.locationName': 'Country Code + Location Name' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additions and Deductions',
        'goodsShipment.customsValuation.chargeDeduction',
        [
            { otherChargeDeductionAmount: 'Value' },
            { otherChargeDeductionAmountCurrencyId: 'Currency' },
            { chargesTypeCode: 'Type' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Transport Equipment',
        'goodsShipment.consignment.transportEquipment',
        [{ id: 'Container Identification Number' }, { seal: 'Seal' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Consignment Items',
        'consignment.consignmentItem',
        [{ 'freight.paymentMethodCode': 'Method of payment for transport charges' }],
        masterDetailsErrors,
        validation
    );

    const itemsErrors: ParsedItem[] = [];
    parseDeclarationItems(itemsErrors, validation);

    return {
        masterDetails: masterDetailsErrors,
        items: itemsErrors,
    };
};

const parseDeclarationItems = (errors: ParsedItem[], validation: FormikErrors<CdsDeclarationPayloadPayload>) => {
    const products = get(validation, 'goodsShipment.governmentAgencyGoodsItem');

    if (!Array.isArray(products)) return;

    products?.forEach((itemErrors: any, index: number) => {
        const item = { index: index + 1, errors: [] };

        parseDeclarationItem(item.errors, itemErrors);

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseCdsProductTemplate = (validation: FormikErrors<CdsDeclarationPayloadPayload>) => {
    const productTemplateErrors: ParsedCard[] = [];

    parseDeclarationItem(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseDeclarationItem = (parsed: ParsedCard[], errors: FormikErrors<CdsDeclarationPayloadPayload>) => {
    parseFieldErrorsForCard(
        'Commodity Codes',
        [
            { 'commodity.classification.cv.id': 'CUS Code' },
            { 'commodity.classification.tsp.id': 'Combined Nomenclature Code' },
            { 'commodity.classification.trc.id': 'Taric Code' },
            { 'commodity.classification.tra.id': 'Taric Additional Code' },
            { 'commodity.classification.gn.id': 'National Additional Code' },
            { 'commodity.classification': '' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Goods Information',
        [
            { 'commodity.description': 'Description of Goods' },
            { 'ucr.traderAssignedReferenceID': 'UCR' },
            { 'destination.countryCode': 'Country of destination code' },
            { 'exportCountry.id': 'Country of dispatch / export code' },
            { 'commodity.goodsMeasure.netNetWeightMeasure': 'Net Mass' },
            { 'commodity.goodsMeasure.tariffQuantity': 'Supplementary Units' },
            { 'commodity.goodsMeasure.grossMassMeasure': 'Gross Mass' },
            { statisticalValueAmount: 'Statistical Value - Amount' },
            { statisticalValueAmountCurrencyId: 'Statistical Value - Currency' },
            { 'commodity.dangerousGoods.undgid': 'UN Dangerous Goods code' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Procedure',
        [
            { 'governmentProcedure.currentCode': 'Procedure Code' },
            { 'governmentProcedure.previousCode': 'Previous Procedure Code' },
            { 'governmentProcedure.additionalCurrentCode': 'Additional Procedure Code' },
            { governmentProcedure: '' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCdsCustomerCard('Consignor', 'consignment.consignor', parsed, errors);
    parseFieldErrorsForCdsCustomerCard('Consignee', 'consignee', parsed, errors);
    parseFieldErrorsForCdsCustomerCard('Exporter', 'consignor', parsed, errors);
    parseFieldErrorsForCdsCustomerCard('Seller', 'seller', parsed, errors);
    parseFieldErrorsForCdsCustomerCard('Buyer', 'buyer', parsed, errors);

    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'additionalInformation',
        [
            { statementCode: 'Additional Information Code' },
            { statementDescription: 'Additional Information Description' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Additional Document',
        'additionalDocument',
        [
            { code: 'Documents Produced - Document Code' },
            { id: 'Documents Produced - Document Identifier' },
            { lpcoExemptionCode: 'Documents Produced - Document Status' },
            { name: 'Documents Produced - Document Status Reason' },
            { effectiveDateTime: 'Writing Off - Date of Validity' },
            { 'submitter.name': 'Writing Off - Issuing Authority' },
            { 'writeOff.amountAmount': 'Writing Off - Measurement Unit & Qualifier' },
            { 'writeOff.quantityQuantity': 'Writing Off - Quantity' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Nature of Transaction',
        [{ transactionNatureCode: 'Nature of Transaction' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Packages',
        'packaging',
        [
            { typeCode: 'Type of packages' },
            { quantityQuantity: 'Number of Packages' },
            { marksNumbersID: 'Shipping Marks' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Previous Documents',
        'previousDocument',
        [
            { categoryCode: 'Previous Document Category' },
            { typeCode: 'Previous Document Type' },
            { id: 'Previous Document Reference' },
            { lineNumeric: 'Goods Item Identifier' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Additional supply chain actor(s) Ident. No.',
        'aeoMutualRecognitionParty',
        [{ id: 'Identification Number' }, { roleCode: 'Role Code' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Additional Fiscal References Identification',
        'domesticDutyTaxParty',
        [{ id: 'Identifier' }, { roleCode: 'Code' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Duty Tax Fee',
        'commodity.dutyTaxFee',
        [
            { typeCode: 'Calculation of Taxes - Tax type' },
            { specificTaxBaseQuantity: 'Calculation of Taxes - Tax Base - Quantity' },
            { specificTaxBaseQuantityCurrencyId: 'Calculation of Taxes - Tax Base - Unit and Qualifier' },
            { 'payment.paymentAmount': 'Calculation of Taxes - Payable tax amount' },
            { 'payment.paymentAmountCurrencyId': 'Calculation of Taxes - Payable tax amount - Currency' },
            { 'payment.taxAssessedAmount': 'Calculation of Taxes - Total' },
            { 'payment.taxAssessedAmountCurrencyId': 'Calculation of Taxes - Total - Currency' },
            { 'payment.methodCode': 'Calculation of Taxes - Method of Payment' },
            {
                quotaOrderId: 'Quota Order Number',
            },
            {
                dutyRegimeCode: 'Preference Code',
            },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Additions and Deductions',
        'customsValuation.chargeDeduction',
        [
            { otherChargeDeductionAmount: 'Value' },
            { otherChargeDeductionAmountCurrencyId: 'Currency' },
            { chargesTypeCode: 'Type' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Valuation Indicators',
        [{ 'valuationAdjustment.additionCode': 'Valuation Indicators' }],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Item Price',
        [
            { 'commodity.invoiceLine.itemChargeAmount': 'Amount' },
            { 'commodity.invoiceLine.itemChargeAmountCurrencyId': 'Currency' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Valuation Method',
        [{ 'customsValuation.methodCode': 'Valuation Method' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Country of Origin',
        'origin',
        [{ countryCode: 'Country of Origin - Country Code' }, { typeCode: 'Country of Origin - Type Code' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Transport Equipment',
        'commodity.transportEquipment',
        [{ id: 'Container Identification Number' }],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Preference Code',
        [
            {
                'commodity.dutyTaxFee.dutyRegimeCode': 'Preference Code',
            },
        ],
        parsed,
        errors
    );
};

export default parseValidationErrors;
