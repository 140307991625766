import config from 'config';
import { ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import {
    MeasureType,
    TaricCode,
    TaricCodeAmount,
    TaricCodeSection,
    TaricCodesType,
    TaricMeasurement,
} from './taric-code';
export const getTaricCodes = (type: TaricCodesType, query: string): Promise<string[]> =>
    axiosClient
        .get<ListResponse<string>>(`${config.cmsUrl}/taric/codes`, { params: { type, query } })
        .then((response) => response.data.payload.list);
export const getTaricCodesDetails = (type: TaricCodesType, query?: string, date?: string): Promise<TaricCode[]> =>
    axiosClient
        .get<ListResponse<TaricCode>>(`${config.cmsUrl}/taric/codes`, {
            params: { type, query, date },
        })
        .then((response) => response.data.payload.list);
export const getTaricCodesAmount = (type: TaricCodesType, code: string, country?: string): Promise<TaricCodeAmount> =>
    axiosClient
        .get<SuccessResponse<TaricCodeAmount>>(`${config.cmsUrl}/taric/codes/amounts`, {
            params: { type, code, country },
        })
        .then((response) => response.data.payload);
export const getTaricCodesSections = (type: TaricCodesType): Promise<TaricCodeSection[]> =>
    axiosClient
        .get<ListResponse<TaricCodeSection>>(`${config.cmsUrl}/taric/codes/sections`, {
            params: { type },
        })
        .then((response) => response.data.payload.list);
export const getEuTaricCodeMeasurement = (
    code: string,
    measureType: MeasureType,
    country?: string
): Promise<TaricMeasurement[]> =>
    axiosClient
        .get<ListResponse<TaricMeasurement>>(`${config.cmsUrl}/taric/codes/measures/eu`, {
            params: { code, measureType, country },
        })
        .then((response) => response.data.payload.list);
export const getUkTaricCodeMeasurement = (
    code: string,
    measureType: MeasureType,
    country?: string
): Promise<TaricMeasurement[]> =>
    axiosClient
        .get<ListResponse<TaricMeasurement>>(`${config.cmsUrl}/taric/codes/measures/uk`, {
            params: { code, measureType, country },
        })
        .then((response) => response.data.payload.list);
