import { FormikProps } from 'formik';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { FC, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import DeclarationTabContent from 'views/declarations/common/declaration-view/DeclarationTabContent';
import useFormUtils from '../../../../hooks/useFormUtils';

const DeclarationForm: FC<{}> = () => {
    const { formik, productsFormik, cancelProducts, clearCancel } = useDeclarationForm() ?? {};

    const { declarationId } = useParams<{ declarationId: string }>();
    const { declaration } = useDeclarations();
    const { listDeclarationProducts } = useProducts();
    const { countryLowerCase: country, formType, internalType } = useFormUtils();

    useEffect(() => {
        if (declaration?.gvmsDeclaration || declaration?.preBoardingNotification) return;

        if (declarationId && declaration?.id === declarationId) {
            listDeclarationProducts({ declarationId, country, formType, internalType });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declaration, declarationId]);

    if (!declaration) return null;
    return (
        <DeclarationTabContent
            formik={formik}
            declaration={declaration}
            productsFormik={productsFormik!}
            cancelProducts={cancelProducts!}
            clearCancel={clearCancel}
        />
    );
};
export default DeclarationForm;

export function useDeclarationForm() {
    return useOutletContext<{
        formik: FormikProps<any>;
        productsFormik?: FormikProps<any>;
        cancelProducts?: boolean;
        clearCancel: Function;
    }>();
}
