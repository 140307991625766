import { Divider, Switch } from 'antd';
import { StyledInfoCircleFilled } from 'App.styles';
import useDeclarations from 'hooks/useDeclarations';
import useGetDeclarationMapValues from 'hooks/useGetDeclarationMapValues';
import useProducts from 'hooks/useProducts';
import { get } from 'lodash';
import { FC, useMemo } from 'react';
import { colors } from 'theme';
import { getDeclarationPayload } from '../utils/declaration-utils';
import {
    calculateGrossMass,
    missingGrossMassFromAllItems,
    missingGrossMassFromMaster,
} from './declaration-view/utils/grossMassUtils';

const GrossMassSuggestion: FC<
    | { withSwitch: true; onToggle: (switched: boolean) => void; toggled: boolean }
    | { withSwitch?: false; onToggle?: never; toggled?: never }
> = (props) => {
    const { declaration } = useDeclarations();
    const { products } = useProducts();
    const { ...mapper } = useGetDeclarationMapValues();

    const tableData = useMemo(() => {
        if (!mapper.itemItemAmountPath || !mapper.grossMassPath || !mapper.itemGrossMassPath) return [];

        const totalGrossMass = parseInt(get(getDeclarationPayload(declaration), mapper.grossMassPath) ?? 0);

        const table: [ITEM: string, ITEM_AMOUNT: string | number, GROSS_MASS: string | number][] = [
            ['Total (Master level)', '', totalGrossMass],
        ];

        const itemAmountsSum = (products?.list as any[])?.reduce((acc: number, product: any) => {
            return acc + parseInt(get(product, mapper.itemItemAmountPath!) ?? 0);
        }, 0);

        products?.list.forEach((product, i) => {
            const itemAmount = parseInt(get(product, mapper.itemItemAmountPath!) ?? 0);
            const itemCurrency = mapper.itemItemCurrencyPath ? get(product, mapper.itemItemCurrencyPath) : null;
            table.push([
                `Item ${i + 1}`,
                [itemAmount, itemCurrency].join(' '),
                calculateGrossMass(totalGrossMass, itemAmount, itemAmountsSum),
            ]);
        });

        table.push(['', itemAmountsSum, '']);

        return table;
    }, [
        mapper.itemItemAmountPath,
        mapper.grossMassPath,
        mapper.itemGrossMassPath,
        mapper.itemItemCurrencyPath,
        declaration,
        products?.list,
    ]);

    const showSuggestion = useMemo(() => {
        const masterGrossMass = mapper.grossMassPath && get(getDeclarationPayload(declaration), mapper.grossMassPath);
        const grossMassMasterMissing = missingGrossMassFromMaster(
            getDeclarationPayload(declaration),
            mapper.grossMassPath
        );
        const grossMassAllItemsMissing = missingGrossMassFromAllItems(products?.list, mapper.itemGrossMassPath);

        const case1 = !grossMassMasterMissing && grossMassAllItemsMissing;

        const hasGrossMass = masterGrossMass ? Number(masterGrossMass) > 0 : false;

        return hasGrossMass && case1;
    }, [declaration, mapper.grossMassPath, mapper.itemGrossMassPath, products?.list]);

    if (!showSuggestion) return null;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <StyledInfoCircleFilled color="warning" style={{ color: 'orange', margin: 0 }} />
                            <h3 style={{ margin: 0 }}>Gross mass suggestion</h3>
                        </div>

                        {props.withSwitch && (
                            <Switch
                                unCheckedChildren="Don't apply"
                                checkedChildren="Apply"
                                onChange={(toggled) => props.onToggle?.(toggled)}
                                checked={props.toggled}
                                data-testid="gross-mass-suggestion-switch"
                            />
                        )}
                    </div>
                    <p style={{ margin: 0, fontSize: '1.25rem', color: colors.darkGrey }}>
                        This is a calculated suggestion for distributing the gross mass of the items based on the
                        collective gross mass.
                    </p>
                </div>

                <table>
                    <tbody>
                        <tr>
                            <th></th>
                            <th style={{ textAlign: 'initial' }}>Item Amount</th>
                            <th style={{ textAlign: 'initial' }}>Gross Mass</th>
                        </tr>
                        {tableData.map((row, i) => {
                            const [item, itemAmount, grossMass] = row;
                            return (
                                <tr>
                                    <td data-testid={`row-${i}-column-item`}>
                                        {item.includes('Total') ? (
                                            <b data-testid={`row-${i}-column-item-total`}>{item}</b>
                                        ) : (
                                            item
                                        )}
                                    </td>
                                    <td data-testid={`row-${i}-column-item_amount`}>
                                        {i === tableData.length - 1 ? (
                                            <b data-testid={`row-${i}-column-item_amount-total`}>{itemAmount}</b>
                                        ) : (
                                            itemAmount
                                        )}
                                    </td>
                                    <td data-testid={`row-${i}-column-gross_mass`}>
                                        {item.includes('Total') ? (
                                            <b data-testid={`row-${i}-column-gross_mass-total`}>{grossMass}</b>
                                        ) : (
                                            grossMass
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Divider />
        </>
    );
};

export default GrossMassSuggestion;
