import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import { message, notification, Row } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { Text } from 'components/ui/base/typography';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BrowseSpan,
    CloseIcon,
    InboxOutlinedIcon,
    InvoiceButton,
    PreviewIcon,
    SectionTitle,
} from './InvoiceUpload.styles';

interface Props {
    disabled: boolean;
    uploadedInvoiceFile: RcFile | null;
    onUploadedInvoiceFile: Dispatch<SetStateAction<RcFile | null>>;
}

const SelectInvoiceFile: FC<Props> = ({ disabled, uploadedInvoiceFile, onUploadedInvoiceFile }) => {
    const { t } = useTranslation('customs_declarations');

    const handleDelete = () => {
        onUploadedInvoiceFile(null);
        notification.success({ message: 'File deleted successfully.' });
    };

    const customUploadingRequest = (options: any) => {
        message.loading('File is uploading.', 1);

        onUploadedInvoiceFile(options.file);
        message.success(`${options.file.name} file uploaded successfully.`);
    };

    useEffect(() => {
        if (disabled) onUploadedInvoiceFile(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2.5rem' }}>
                <SectionTitle className={disabled ? 'disabled' : ''}>{t('uploadYourDocs')}</SectionTitle>
                {!uploadedInvoiceFile && (
                    <InvoiceButton
                        size="large"
                        disabled={disabled}
                        onClick={() => document.getElementById('invoice-upload-dragger')?.click()}
                    >
                        {t('upload')}
                    </InvoiceButton>
                )}
            </div>

            <div style={{ marginBottom: '3.5rem' }}>
                {uploadedInvoiceFile ? (
                    <>
                        <Row align="middle">
                            <CloseIcon onClick={handleDelete} />
                            <Text>
                                <FilePdfOutlined /> {uploadedInvoiceFile?.name}
                            </Text>
                        </Row>
                        <Row>
                            <PreviewIcon /> {t('invoice-uploaded-with-success')}
                        </Row>
                    </>
                ) : (
                    <div>
                        {!disabled && (
                            <Dragger
                                id="invoice-upload-dragger"
                                showUploadList={false}
                                customRequest={customUploadingRequest}
                            >
                                <p className="ant-upload-text">
                                    <InboxOutlinedIcon /> {t('drag-file-to-upload-or')}
                                    <BrowseSpan>{t('browse')}</BrowseSpan>
                                    {t('for-a-file-on-your-computer')}
                                </p>
                            </Dragger>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default SelectInvoiceFile;
