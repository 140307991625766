import { FormikProps, FormikProvider } from 'formik';
import { FC } from 'react';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import DocumentsAdditionalInfoCard from './cards/DocumentsAdditionalInfoCard';
import LocationOfGoods from './cards/LocationOfGoods';
import MasterDetailsCard from './cards/MasterDetailsCard';
import PreviousDocumentCard from './cards/PreviousDocumentCard';
import TransportCostsCard from './cards/TransportCostsCard';
import TransportDocumentsCard from './cards/TransportDocumentsCard';
import { IEH7_PartiesDataStructure } from './ie-h7-data-structure';

interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
}

const IrelandH7Form: FC<Props> = ({ formik, toggleHelp, viewOnly }) => {
    return (
        <FormikProvider value={formik}>
            <MasterDetailsCard
                viewOnly={viewOnly}
                keyCard="master-details-card"
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={4}
            />

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={IEH7_PartiesDataStructure}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                }}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={2}
                keyCard={`parties-card`}
                condensed
            />

            <DocumentsAdditionalInfoCard
                viewOnly={viewOnly}
                keyCard={`document-additionalinfo-card`}
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={5}
            />

            <PreviousDocumentCard
                viewOnly={viewOnly}
                keyCard={`previous-document-card`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={6}
            />

            <TransportDocumentsCard
                viewOnly={viewOnly}
                keyCard={`transport-documents-card`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={7}
            />

            <TransportCostsCard
                keyCard={`transport-costs-card`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                errors={formik.errors}
                cardTotal={9}
                cardNumber={8}
            />
            <LocationOfGoods
                keyCard={`location-goods-card`}
                viewOnly={viewOnly}
                propsPathPrefix="goodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={9}
            />
        </FormikProvider>
    );
};
export default IrelandH7Form;
