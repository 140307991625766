import useSession from 'hooks/useSession';
import { useRoutes } from 'react-router-dom';
import useDeclarations from '../../hooks/useDeclarations';
import routesConfig from './routes';

const AppRouter = () => {
    const { userInfo } = useSession();
    const { declaration } = useDeclarations();
    const routesConfiguration = routesConfig(userInfo, declaration);
    const routes = useRoutes(routesConfiguration);
    return routes;
};
export default AppRouter;
