import Drawer from 'components/ui/base/drawer/Drawer';
import { FormikProps, FormikProvider } from 'formik';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationProducts from 'hooks/useDeclarationProducts';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration } from 'store/declarations/declaration';
import { Box44Context } from 'views/declarations/common/box44/Box44';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import DeclarationsProductsTemplates from 'views/declarations/common/DeclarationsProductsTemplates';
import MasterProductDeclarationNav from 'views/declarations/common/MasterProductDeclarationNav';
import { StyledHeader } from 'views/declarations/Form.styles';
import FormHelperDrawer from 'views/declarations/tooltips-help/FormHelpDrawer';
import useDeclarations from 'hooks/useDeclarations';
import { box44PathAndFieldNames } from '../box44/box-44-utils';
import { ProductContext } from './ProductContext';
import { FormSection } from './DeclarationView';
import useFormUtils from '../../../../hooks/useFormUtils';
import useGetDeclarationMapValues from '../../../../hooks/useGetDeclarationMapValues';
import { DeclarationCountry } from '../../../../store/declarations/enums/common/declaration-country';
import { MessageTypes } from '../../../../store/declarations/enums/common/declaration-types';
import { NctsDeclaration } from '../../../../store/declarations/ireland/ncts-declaration';
import { getDeclarationType } from 'views/declarations/utils/declaration-utils';
import useCodelists from 'hooks/useCodelists';

interface Props {
    formik: FormikProps<any>;
    productsFormik: FormikProps<any>;
    declaration: Declaration;
    cancelProducts: boolean;
    clearCancel: Function;
}

interface FormHelp {
    visible: boolean;
    section: number | string | undefined;
}

const DeclarationTabContent: FC<Props> = ({ formik, productsFormik, declaration, cancelProducts, clearCancel }) => {
    const { setDeclarationFooterType } = useDeclarationFooter();
    const { declarationTemplate } = useDeclarations();
    const { declarationId }: { declarationId?: string } = useParams();
    const location = useLocation();
    const { formType, internalType, countryLowerCase: country } = useFormUtils();
    const { transformData } = useGetDeclarationMapValues();
    const declarationType = getDeclarationType(declaration);
    const { getCodeLists } = useCodelists();
    const customerId = declaration.individual?.customerId;

    const [selectedBt, setSelectedBt] = useState<FormSection>(
        location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAILS : FormSection.PRODUCTS
    );
    const [isDeclarationProductTemplatesDrawerVisible, setDeclarationProductTemplatesDrawerVisible] =
        useState<boolean>(false);

    const [formHelpDrawer, setFormHelpDrawer] = useReducer(
        (initialState: FormHelp, newState: Partial<FormHelp>) => ({ ...initialState, ...newState }),
        { visible: false, section: undefined }
    );

    const { listDeclarationProducts, createDeclarationProduct, getDeclarationProduct } = useProducts();
    const { addProductTemplatesToDeclaration } = useDeclarationProducts();
    const { declarationValidation, setFormAction } = useDeclarationValidation();
    const controlProduct = useContext(ProductContext);
    const navigate = useNavigate();

    const { saveAsDraft, amendment } = useOutletContext<{ saveAsDraft: Function; amendment: boolean }>() ?? {};

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAILS : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    useEffect(() => {
        getCodeLists(country, declarationType, customerId);
    }, [getCodeLists, country, declarationType, customerId]);

    useEffect(() => {
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cancelProducts) {
            productsFormik.resetForm();
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelProducts]);

    useEffect(() => {
        if (selectedBt === FormSection.MASTER_DETAILS) {
            if (!declarationValidation.formAction) {
                setFormAction(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const handleCreateDeclarationProduct = useCallback(async () => {
        controlProduct.setProductId?.(undefined);
        productsFormik.resetForm();
        setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
        if (!declarationId) return;

        let form: Record<string, string> = {};
        if (declaration?.ieNctsDeclaration || declaration?.ukNctsDeclaration)
            form.houseConsignmentId = (formik.values as NctsDeclaration)?.consignment?.houseConsignment?.[0]?.id;

        const product = await createDeclarationProduct({
            templateData: declarationTemplate?.template?.product.defaults ?? form,
            declarationId,
            formType,
            internalType,
            country,
        });
        listDeclarationProducts({ declarationId, formType, internalType, country });
        navigate(`/declarations/${declarationId}/products/${product.id}`);
    }, [
        controlProduct,
        productsFormik,
        setDeclarationFooterType,
        declarationId,
        createDeclarationProduct,
        declarationTemplate?.template?.product.defaults,
        listDeclarationProducts,
        navigate,
        formType,
        internalType,
        country,
        declaration?.ieNctsDeclaration,
        declaration?.ukNctsDeclaration,
        formik.values,
    ]);

    const handleAddProductTemplatesToDeclaration = async (productTemplateIds: string[]) => {
        setDeclarationProductTemplatesDrawerVisible(false);
        if (!(declaration.id && declarationId)) return;
        await addProductTemplatesToDeclaration({
            declarationId: declaration.id,
            productTemplateIds,
            formType,
            internalType,
            country,
        });

        await listDeclarationProducts({ declarationId, formType, internalType, country });
        navigate(`/declarations/${declarationId}/products`);
    };

    const handleDeclarationView = useMemo(() => {
        const handleToggleHelp = (refNumber: number | string) =>
            setFormHelpDrawer({ visible: true, section: refNumber });

        const handleEditDeclarationProduct = async (productId: string) => {
            const response = await getDeclarationProduct({
                declarationId: declaration.id!,
                productId,
                formType,
                internalType,
                country,
            });
            if (!response) return;
            if (controlProduct.setProductId) {
                controlProduct.setProductId(response.id);
            }

            if (transformData?.product?.forClient) {
                let transformedProduct = transformData?.product?.forClient(response);

                if (country === DeclarationCountry.UK)
                    transformedProduct = transformedProduct.governmentAgencyGoodsItem;

                productsFormik.setValues(transformedProduct);
            }

            navigate(`/declarations/${declarationId}/products/${response.id}`);
        };

        return (
            <Outlet
                context={{
                    formik: formik,
                    productsFormik: productsFormik,
                    toggleHelp: handleToggleHelp,
                    onEdit: handleEditDeclarationProduct,
                    saveAsDraft,
                    amendment,
                }}
            />
        );
    }, [
        saveAsDraft,
        formik,
        productsFormik,
        declaration.id,
        declarationId,
        getDeclarationProduct,
        controlProduct,
        navigate,
        country,
        formType,
        internalType,
        transformData?.product,
        amendment,
    ]);

    const box44Path = useMemo(
        () =>
            !location.pathname.includes('products')
                ? box44PathAndFieldNames[formType as MessageTypes]?.masterPath
                : box44PathAndFieldNames[formType as MessageTypes]?.productPath,
        [location.pathname, formType]
    );
    const formikForProvider = useMemo(
        () => (!location.pathname.includes('products') ? formik : productsFormik),
        [formik, location.pathname, productsFormik]
    );

    return (
        <>
            <FormHelperDrawer
                section={formHelpDrawer.section}
                isVisible={formHelpDrawer.visible}
                onClose={() => setFormHelpDrawer({ visible: false })}
            />
            <StyledHeader>
                <FormikProvider value={formikForProvider}>
                    <Box44Context.Provider
                        value={{
                            documentTypeName: box44PathAndFieldNames[formType as MessageTypes]?.documentTypeName,
                            documentIdentifierName:
                                box44PathAndFieldNames[formType as MessageTypes]?.documentIdentifierName,
                            path: box44Path,
                        }}
                    >
                        <MasterProductDeclarationNav
                            selectedBt={selectedBt}
                            handleCreateDeclarationProduct={handleCreateDeclarationProduct}
                            setShowProducts={setDeclarationProductTemplatesDrawerVisible}
                        />
                    </Box44Context.Provider>
                </FormikProvider>
            </StyledHeader>
            <DeclarationFormTabContent>{handleDeclarationView}</DeclarationFormTabContent>
            <Drawer
                title={`View all ${formType} product templates`}
                width="786"
                visible={isDeclarationProductTemplatesDrawerVisible}
                onClose={() => {
                    setDeclarationProductTemplatesDrawerVisible(false);
                }}
            >
                {isDeclarationProductTemplatesDrawerVisible && (
                    <DeclarationsProductsTemplates
                        addProductsToDeclaration={handleAddProductTemplatesToDeclaration}
                        declarationTemplate={declarationTemplate}
                    />
                )}
            </Drawer>
        </>
    );
};
export default DeclarationTabContent;
