export interface DigitalCertificate {
    id: string;
    requestIdentifier: string;
    digitalCertificateType: string;
    title: string;
    filename: string;
    createdAt: string;
}

export interface UpdateDigitalCertificate {
    requestIdentifier: string;
    password: string;
}

export interface UploadDigitalCertificateRequest {
    type: DigitalCertificateType;
    file: File;
    password: string;
}

export enum DigitalCertificateType {
    AIS = 'AIS',
    AEP_ICS = 'AEP_ICS',
    AES = 'AES',
    NCTS = 'NCTS',
}

export enum DigitalCertificateSortParameter {
    ID = 'ID',
    TYPE = 'TYPE',
    TITLE = 'TITLE',
    FILENAME = 'FILENAME',
    CREATED_AT = 'CREATED_AT',
}

export const digitalCertificateType = [
    { id: DigitalCertificateType.AIS, value: 'AIS' },
    { id: DigitalCertificateType.AEP_ICS, value: 'AEP/ICS' },
    { id: DigitalCertificateType.AES, value: 'AES' },
    { id: DigitalCertificateType.NCTS, value: 'NCTS' },
] as const;
