export enum GvmsFormCustomFields {
    PORT_OF_DEPARTURE = 'Port of Departure',
    PORT_OF_ARRIVAL = 'Port of Arrival',
    CARRIER = 'Carrier',
}

export enum GvmsFormMicCards {
    CUSTOMS_DECLARATIONS = 'Customs Declarations',
    TRANSIT_DECLARATIONS = 'Transit Declarations',
    TIR_CARNET_DECLARATIONS = 'TIR Carnet Declarations',
    ATA_CARNET_DECLARATIONS = 'ATA Carnet Declarations',
    EIDR_DECLARATIONS = `Entry in Declarant's Records (EIDR)`,
}

export enum GvmsCardNames {
    MASTER_DETAILS = 'Master Details',
    PLANNED_CROSSING = 'Planned Crossing',
}
