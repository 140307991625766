import { FormikProps } from 'formik';
import { FC } from 'react';
import NewFormCard, { FormCardContainer } from '../../common/cards/NewFormCard';
import { getFormikProps } from '../../utils/form-utils';
import { Radio, RadioChangeEvent } from 'antd';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import {
    InputLabel,
    LabelDiv,
} from '../../../../components/ui/composed/declarations/formInput/DeclarationInput.styles';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import DeclarationCheckbox from '../../../../components/ui/composed/declarations/formCheckbox/DeclarationCheckbox';
import PbnCustomsDeclarationDrawerButton from './components/PbnCustomsDeclarationsDrawerButton';

interface Props {
    formik: FormikProps<any>;
}

const PbnForm: FC<Props> = ({ formik }) => {
    return (
        <>
            <NewFormCard title="Master Details">
                <FormCardContainer oneColumn>
                    <>
                        <LabelDiv condensed>
                            <InputLabel>Movement Direction</InputLabel>
                        </LabelDiv>
                        <div>
                            <Radio.Group
                                value={formik?.values?.direction}
                                onChange={(e: RadioChangeEvent) => formik.setFieldValue('direction', e.target.value)}
                            >
                                <Radio value={'IN_IRELAND'}>IRE Inbound</Radio>
                                <Radio value={'OUT_IRELAND'}>IRE Outbound</Radio>
                            </Radio.Group>
                        </div>
                    </>
                    {formik.values.direction === 'IN_IRELAND' && (
                        <>
                            <DeclarationInput
                                label="Email Address"
                                required
                                {...getFormikProps('emailId', formik)}
                                condensed
                            />

                            <DeclarationInput
                                required
                                label="Mobile Number 1"
                                {...getFormikProps('mobileNum1', formik)}
                                condensed
                            />

                            <DeclarationInput
                                required
                                label="Mobile Number 2"
                                {...getFormikProps('mobileNum2', formik)}
                                condensed
                            />
                        </>
                    )}
                    <DeclarationCheckbox
                        label="Vehicle is Empty?"
                        {...getFormikProps('emptyVehicle', formik)}
                        condensed
                        tooltip="Check this box if you are declaring that your vehicle/trailer is empty."
                    />
                    {!formik?.values?.emptyVehicle && (
                        <MultipleDeclarationField
                            parent="declaration"
                            name="mrn"
                            getCtaButtons={(declarationFieldIndex: number | null) => [
                                <PbnCustomsDeclarationDrawerButton
                                    onSelectDeclaration={(declarationMrn: string | undefined, onClose: () => void) => ({
                                        onClick: () => {
                                            formik.setFieldValue(
                                                `declaration.${declarationFieldIndex}.mrn`,
                                                declarationMrn
                                            );
                                            onClose();
                                        },
                                    })}
                                />,
                            ]}
                        >
                            {(fieldProps, controls) => (
                                <DeclarationInput
                                    required
                                    label="Customs Declarations"
                                    {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                    multipleF={controls}
                                    condensed
                                    tooltip="Please add the MRNs for each declaration that covers the goods that you are carrying and/or the authorisation no. for empty, return packaging and/or the CN37 code for postal movements or authorisation for Piggyback trailer or ATA Carnet."
                                />
                            )}
                        </MultipleDeclarationField>
                    )}
                </FormCardContainer>
            </NewFormCard>
        </>
    );
};

export default PbnForm;
