import { Col } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TaricCodeResponse } from 'store/taric-codes/taric-code';
import {
    CodeButton,
    CollapseIcon,
    DescriptionCol,
    ExpandIcon,
    HighlightedText,
    ListText,
    StyledRow,
} from '../TaricCode.styles';
interface Props {
    code: TaricCodeResponse;
    selectedCode: (code: Pick<TaricCodeResponse, 'code' | 'description'>) => void;
    margin: string;
    searchQuery: string | undefined;
}
const ListRow: FC<Props> = ({ code, selectedCode, margin, searchQuery }) => {
    const [collapsed, setCollapsed] = useState(true);

    const increaseMargin = useCallback((margin: string) => {
        return `${+margin + 1}`;
    }, []);

    const hasSearchedWord = useMemo(() => {
        const queryTrimmed = searchQuery?.toLocaleLowerCase().trim();

        if (queryTrimmed) {
            return true;
        }
    }, [searchQuery]);

    useEffect(() => {
        if (hasSearchedWord) {
            setCollapsed(false);
        } else {
            setCollapsed(true);
        }
    }, [hasSearchedWord]);

    const getHighlightedText = useCallback(
        (text: string) => {
            const queryTrimmed = searchQuery?.toLocaleLowerCase().trim();
            if (!!queryTrimmed) {
                const highlight = text.split(' ').map((c, i) =>
                    c?.toLowerCase().includes(queryTrimmed) ? (
                        <HighlightedText key={`highlighted-text-${c}-${i}`} highlighted>
                            {c}
                        </HighlightedText>
                    ) : (
                        <HighlightedText key={`not-highlighted-text-${c}-${i}`}>{c}</HighlightedText>
                    )
                );
                return text.toLowerCase().includes(queryTrimmed) ? <>{highlight.map((t) => t)}</> : <>{text}</>;
            } else {
                return <>{text}</>;
            }
        },
        [searchQuery]
    );
    const listChildren = useCallback(
        (code: TaricCodeResponse, margin: string) =>
            code?.children?.map((c, i) =>
                !c.children ? (
                    <>
                        <StyledRow
                            margin={increaseMargin(margin)}
                            key={`list-row-!children-${c?.description}-${i}-${c?.code}`}
                            align="middle"
                            onClick={() => selectedCode(c)}
                        >
                            <Col span={20}>
                                <ListText> {getHighlightedText(c?.description)}</ListText>
                            </Col>
                            <Col span={3}>
                                <CodeButton>{getHighlightedText(c?.code)}</CodeButton>
                            </Col>
                        </StyledRow>
                    </>
                ) : (
                    <ListRow
                        searchQuery={searchQuery}
                        key={`list-row-children-${c?.description}-${i}-${c?.code}`}
                        code={c}
                        selectedCode={selectedCode}
                        margin={increaseMargin(margin)}
                    />
                )
            ),
        [selectedCode, increaseMargin, searchQuery, getHighlightedText]
    );
    const handleIcon = useCallback(
        (code: TaricCodeResponse) => {
            if (code?.children) {
                if (collapsed) {
                    return <ExpandIcon />;
                } else {
                    return <CollapseIcon />;
                }
            }
        },
        [collapsed]
    );
    const onClick = () => {
        if (!code?.children) {
            selectedCode(code);
        }
        setCollapsed(!collapsed);
    };
    return (
        <>
            <StyledRow margin={margin} align="middle" onClick={() => onClick()} wrap={false}>
                <DescriptionCol span={20}>
                    <ListText>
                        {handleIcon(code)}
                        {getHighlightedText(code?.description)}
                    </ListText>
                </DescriptionCol>
                <Col span={4}>
                    <CodeButton>{getHighlightedText(code?.code)}</CodeButton>
                </Col>
            </StyledRow>
            {!collapsed && listChildren(code, increaseMargin(margin))}
        </>
    );
};
export default ListRow;
