import { FC, useMemo } from 'react';
import { BusinessRule } from 'store/business-rules/business-rule';
import { AisBusinessRuleRow } from './ais-documentation/AisBusinessRuleRow';
import { BusinessRuleCode, StyledBusinessRuleRow } from './BusinessRules.styles';
import { DocumentationType } from './Help';

export const BusinessRulesList: FC<{ type: DocumentationType; rules: BusinessRule[] }> = ({ type, rules }) => {
    const rows = useMemo(() => {
        switch (type) {
            case DocumentationType.AIS:
                return rules.map((businessRule) => {
                    return <AisBusinessRuleRow key={businessRule.code} rule={businessRule as BusinessRule} />;
                });
            default:
                return <></>;
        }
    }, [type, rules]);
    return (
        <>
            <StyledBusinessRuleRow>
                <BusinessRuleCode>Rule code:</BusinessRuleCode>
                <span>Error message</span>
            </StyledBusinessRuleRow>
            {rows}
        </>
    );
};
