import { Row, UploadProps, notification } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import {
    DivDownloadTemplate,
    DivInformationTemplateFirstRow,
    InboxOutlinedIcon,
    BrowseSpan,
    SectionTitle,
    BulkUploadButton,
    CloseIcon,
    PreviewIcon,
} from 'views/custom-declaration/products/bulk-upload/ListBulkUploadFiles.styles';
import FileExcelOutlined from '@ant-design/icons/lib/icons/FileExcelOutlined';
import { TextExtraSmall } from 'components/ui/base/typography';
import Notification from 'components/ui/base/notification/Notification';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Typography } from 'antd';

const { Text: AntText } = Typography;

export type BulkUploadUploadFunc = (
    options: Parameters<NonNullable<UploadProps<any>['customRequest']>>[0]
) => any | Promise<any>;
export type BulkUploadDownloadFunc = () => Promise<{ preSignedUrl: string } | null>;

export enum FileType {
    XLS = 'XLS',
    XLSX = 'XLSX',
    CSV = 'CSV',
}

type Props = {
    disabled?: boolean;
    onUpload?: BulkUploadUploadFunc;
    onDownload?: BulkUploadDownloadFunc;
    onDelete?: () => void;
    hasDontOverrideFirstRowText?: boolean;
    withNotification?: boolean;
    fileType?: FileType[] | FileType;
    showFile?: boolean;
};

const BulkUpload = ({
    disabled = false,
    onDownload,
    onUpload,
    onDelete,
    hasDontOverrideFirstRowText = false,
    withNotification = true,
    fileType,
    showFile = false,
}: Props) => {
    const { t } = useTranslation('customs_declarations');

    const [file, setFile] = useState<File>();

    const handleUpload = async (options: Parameters<BulkUploadUploadFunc>[0]) => {
        const fileExt = (options.file as File).name.split('.').at(-1)?.toLowerCase();

        if (fileType) {
            if (
                // There is no file extension OR
                !fileExt ||
                // The file extension doesn't match the allowed file types OR
                (typeof fileType === 'string' && fileType.toLowerCase() !== fileExt) ||
                // The file extension doesn't match the allowed file types (Array)
                (Array.isArray(fileType) && !fileType.map((type) => type.toLowerCase()).includes(fileExt))
            ) {
                Notification({
                    type: 'error',
                    messageTitle: t('bulk-upload.file-extension-error-message.title'),
                    description: t('bulk-upload.file-extension-error-message.description'),
                });
                return;
            }
        }

        const response = await onUpload?.(options);

        if (withNotification) {
            if (response) {
                Notification({
                    type: 'success',
                    messageTitle: t('bulk-upload.file-handle-success-message.title'),
                    description: t('bulk-upload.file-handle-success-message.description'),
                });

                setFile(options.file as File);
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('bulk-upload.file-handle-error-message.title'),
                    description: t('bulk-upload.file-handle-error-message.description'),
                });
            }
        }
    };

    const handleDownload = async () => {
        const response = await onDownload?.();

        if (response) {
            window.open(response.preSignedUrl);
        }
    };

    const handleDelete = async () => {
        setFile(undefined);
        notification.success({ message: 'File deleted successfully.' });
        onDelete?.();
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2.5rem' }}>
                <SectionTitle className={`${disabled ? 'disabled' : ''}`}>
                    {t('bulk-upload.title-section')}
                </SectionTitle>
                {!file && (
                    <BulkUploadButton
                        size="large"
                        disabled={disabled}
                        onClick={() => {
                            document.getElementById('file-upl')?.click();
                        }}
                    >
                        {t('bulk-upload.upload-spreadsheet-file')} <FileExcelOutlined />
                    </BulkUploadButton>
                )}
            </div>
            <div>
                {file ? (
                    <>
                        <Row align="middle">
                            <CloseIcon onClick={handleDelete} />
                            <AntText>
                                <FilePdfOutlined /> {file?.name}
                            </AntText>
                        </Row>
                        <Row>
                            <PreviewIcon /> {t('bulk-upload.success-message')}
                        </Row>
                    </>
                ) : (
                    <div>
                        {!disabled && (
                            <Dragger id="file-upl" maxCount={1} customRequest={handleUpload} showUploadList={false}>
                                <p className="ant-upload-text">
                                    <InboxOutlinedIcon /> {t('drag-file-to-upload-or')}
                                    <BrowseSpan>{t('browse')}</BrowseSpan>
                                    {t('for-a-file-on-your-computer')}
                                </p>
                            </Dragger>
                        )}
                    </div>
                )}
                {!disabled && (
                    <DivDownloadTemplate>
                        <AntText
                            style={{ color: 'inherit' }}
                            onClick={() => !disabled && handleDownload()}
                            disabled={disabled}
                        >
                            <FileExcelOutlined /> {t('bulk-upload.download-spreadsheet-template-link')}
                        </AntText>
                    </DivDownloadTemplate>
                )}
                {hasDontOverrideFirstRowText && (
                    <DivInformationTemplateFirstRow>
                        <TextExtraSmall>{t('bulk-upload.information-template-first-row')}</TextExtraSmall>
                    </DivInformationTemplateFirstRow>
                )}
            </div>
        </>
    );
};

export default BulkUpload;
