import { Form } from 'antd';
import PhoneNumber from 'awesome-phonenumber';
import Button from 'components/ui/base/button';
import { FormItem } from 'components/ui/base/form';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { useFormik } from 'formik';
import useCountries from 'hooks/useCountries';
import useDrivers from 'hooks/useDrivers';
import useSession from 'hooks/useSession';
import { FC, useEffect } from 'react';
import { Driver, DriverStatus } from 'store/drivers/driver';
import * as Yup from 'yup';
import { getNumberValidation } from '../../declarations/utils/validation-utils';

interface Props {
    closeDrawer: () => void;
    handleOk: (newCargo?: any) => void;
    handleEdit?: (cargo?: any) => void;
    handleCreateError?: Function;
    handleEditError?: Function;
    driver?: Driver;
}

const formValidation = Yup.object().shape({
    fullName: Yup.string().required('Full name is missing.'),
    email: Yup.string().required('Email is missing.').email('Email is invalid'),
    address: Yup.object({
        addressLine1: Yup.string().required().nullable(),
        addressLine2: Yup.string().nullable(),
        postCode: Yup.string().required().nullable(),
        city: Yup.string().required().nullable(),
        country: Yup.string().required().nullable(),
    }),
    phoneNumber: getNumberValidation().required('Phone number is missing.'),
    phoneCode: Yup.string().required('Phone code is missing.'),
    insuranceCertificateNumber: Yup.string().required('Insurance certificate number is missing.'),
    driverLicenceNumber: Yup.string().required('Driver licence number is missing.'),
});

const CreateDriver: FC<Props> = ({ closeDrawer, handleOk, driver, handleCreateError, handleEditError }) => {
    const { getFieldProps, resetForm, getFieldMeta, handleSubmit, isValid, getFieldHelpers, setValues } = useFormik<
        Partial<Driver>
    >({
        initialValues: driver || ({} as Driver),
        validationSchema: formValidation,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => createDriverSubmit(values),
    });
    const { createDriver, editDriver } = useDrivers();
    const { customerId } = useSession();
    const { countries } = useCountries();

    const createDriverSubmit = async (values: Partial<Driver> & { phoneCode?: string; phoneNumber?: string }) => {
        let phone;

        if (values?.phoneCode && values?.phoneNumber) {
            phone = `${values.phoneCode}${values.phoneNumber}`;
        }
        const driverRequest: Partial<Driver> = {
            ...values,
            customerId: customerId!,
            phone: phone,
        };

        if (driverRequest && driverRequest.id) {
            const response = await editDriver(driverRequest as Driver);

            if (response && response.id) {
                handleOk();
            }
        } else {
            driverRequest.status = DriverStatus.NEW;
            const response = await createDriver(driverRequest as Driver);
            if (response && response.id) {
                handleOk();
            }
        }
    };

    const onCloseButton = () => {
        closeDrawer();
        resetForm();
    };

    useEffect(() => {
        if (driver?.phone) {
            const phone = new PhoneNumber(driver.phone);
            const updateDriver = {
                ...driver,
                phoneCode: `+${phone.getCountryCode().toString()}`,
                phoneNumber: phone.getNumber('significant'),
            };
            setValues(updateDriver);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driver]);

    return (
        <Form layout="vertical">
            <FormInput
                label="Full Name"
                fieldProps={getFieldProps('fullName')}
                fieldMeta={getFieldMeta('fullName')}
                fieldHelper={getFieldHelpers('fullName')}
            />
            <FormInput
                label="Email Address"
                fieldProps={getFieldProps('email')}
                fieldMeta={getFieldMeta('email')}
                fieldHelper={getFieldHelpers('email')}
            />
            <FormPhoneNumber
                fieldNumberProps={getFieldProps('phoneNumber')}
                fieldCodeProps={getFieldProps('phoneCode')}
                fieldCodeMeta={getFieldMeta('phoneCode')}
                fieldNumberMeta={getFieldMeta('phoneNumber')}
            />
            <FormInput
                label="Driver Licence Number"
                fieldProps={getFieldProps('driverLicenceNumber')}
                fieldMeta={getFieldMeta('driverLicenceNumber')}
                fieldHelper={getFieldHelpers('driverLicenceNumber')}
            />
            <FormInput
                label="Insurance Certificate Number"
                fieldProps={getFieldProps('insuranceCertificateNumber')}
                fieldMeta={getFieldMeta('insuranceCertificateNumber')}
                fieldHelper={getFieldHelpers('insuranceCertificateNumber')}
            />
            <FormInput
                label="Address Line1"
                fieldProps={getFieldProps('address.addressLine1')}
                fieldMeta={getFieldMeta('address.addressLine1')}
            />
            <FormInput
                label="Address Line2"
                fieldProps={getFieldProps('address.addressLine2')}
                fieldMeta={getFieldMeta('address.addressLine2')}
            />
            <FormInput
                label="Post Code"
                fieldProps={getFieldProps('address.postCode')}
                fieldMeta={getFieldMeta('address.postCode')}
            />
            <FormInput
                label="City"
                fieldProps={getFieldProps('address.city')}
                fieldMeta={getFieldMeta('address.city')}
            />
            <FormSelect
                label="Country"
                fieldProps={getFieldProps('address.country')}
                fieldMeta={getFieldMeta('address.country')}
                selectOptions={countries.list.map((obj) => {
                    return {
                        id: obj.countryCode,
                        value: obj.name,
                    };
                })}
            />
            <FormItem style={{ textAlign: 'right', marginTop: '3.6rem' }}>
                <Button size="large" style={{ marginRight: '1.6rem' }} onClick={() => onCloseButton()}>
                    Cancel
                </Button>
                <Button
                    disabled={!isValid}
                    size="large"
                    type="primary"
                    onClick={async () => {
                        handleSubmit();
                    }}
                >
                    Confirm
                </Button>
            </FormItem>
        </Form>
    );
};
export default CreateDriver;
