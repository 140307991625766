import { get } from 'lodash';
import { ParsedCard, ParsedField, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { parseDeclarationCustomer } from '../../validation/utils';

const countCustomerNumber = (array: ParsedCard[]) =>
    array.reduce((acc, parsedCard) => {
        if (parsedCard.name.includes('Customer')) acc = acc + 1;
        return acc;
    }, 1);

export const parseParty = (masterDetailsParsedCards: ParsedCard[], declaration: any, path: string) => {
    const customerValue = get(declaration, path) as any;

    const customerCount = countCustomerNumber(masterDetailsParsedCards);

    const fields: ParsedField[] = parseDeclarationCustomer(customerValue, '', `${path}-customer${customerCount}`);

    if (fields.length === 0) return;
    else
        masterDetailsParsedCards.splice(customerCount, 0, {
            name: `Customer ${customerCount}`,
            fields,
        });
};

export const parseDeclarationItems = (
    parsedItemDetails: ParsedItem[],
    declaration: any,
    path: string,
    taricsParser: (parsedItemDetails: ParsedCard[] | undefined, item: any) => void,
    itemsValueArg?: any[]
) => {
    const itemsValue = itemsValueArg ? itemsValueArg : (get(declaration, path) as unknown as any[]) || [];

    itemsValue.forEach((_, index) => {
        const item = parsedItemDetails[index] || { errors: [] };
        taricsParser(item.errors, itemsValue[index]);

        const customItemsPathPrefix = `${path}-product${index}`;

        const fields: ParsedField[] = [];

        item.errors?.forEach((parsedCard: ParsedCard) =>
            parsedCard.fields?.forEach((parsedField: ParsedField) =>
                fields.push({ ...parsedField, path: `${customItemsPathPrefix}.${parsedField.path}` })
            )
        );

        const itemFieldsWithPathPrefixes = {
            index: index + 1,
            fields,
        };

        if (item.errors && item.errors.length > 0) {
            parsedItemDetails[index] = itemFieldsWithPathPrefixes;
        }
    });
};
