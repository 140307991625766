import * as Yup from 'yup';
import {
    getCustomerValidation,
    CUSTOMS_OFFICE,
    getEoriValidation,
    getExactLengthValidation,
    getMaxValidation,
    getRequiredMessage,
    invalidEoriMessage,
    TARIC_CODE_TYPE,
    getFloatValidation,
    getNumberOfItemsValidation,
    getNumberMaxDigitsValidation,
    getMinCharactersValidation,
} from '../../../../utils/validation-utils';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';

const addressSchema = (required: boolean) =>
    required
        ? Yup.object({
              postCode: getMaxValidation('postCode', 9).required(getRequiredMessage('postCode')).nullable().template(),
              streetAndNumber: getMaxValidation('streetAndNumber', 70)
                  .required(getRequiredMessage('streetAndNumber'))
                  .nullable()
                  .template(),
              city: getMaxValidation('city', 35).required(getRequiredMessage('city')).nullable().template(),
              country: getMaxValidation('country', 2).required(getRequiredMessage('country')).nullable().template(),
          })
        : Yup.object({
              postCode: getMaxValidation('postCode', 9).nullable().template(),
              streetAndNumber: getMaxValidation('streetAndNumber', 70).nullable().template(),
              city: getMaxValidation('city', 35).nullable().template(),
              country: getMaxValidation('country', 2).nullable().template(),
          });

export const firstPlaceOfUse = () =>
    Yup.object()
        .shape({
            identificationOfLocation: getMaxValidation('identificationOfLocation', 35).nullable().template(),
            qualifierIdentification: getExactLengthValidation('qualifierIdentification', 1).nullable().template(),
            additionalIdentifier: getExactLengthValidation('additionalIdentifier', 3).nullable().template(),
            locationTypeCode: getExactLengthValidation('locationTypeCode', 1).nullable().template(),
            address: addressSchema(false).nullable(),
        })
        .nullable();

const placeOfUse = () => {
    return Yup.array().of(firstPlaceOfUse());
};

const ownerOfTheGoodsSchema = () => Yup.array().of(getCustomerValidation());

const authorisationSchema = Yup.object({
    customsOfficeOfDischarge: Yup.string().matches(CUSTOMS_OFFICE, invalidEoriMessage).nullable().template(),

    supervisingCustomsOffice: Yup.string().matches(CUSTOMS_OFFICE, invalidEoriMessage).nullable().template(),
    period: getMaxValidation('period', 2).nullable().template(),
    periodDetails: getMaxValidation('periodDetails', 512).nullable().template(),
    billOfDischargeDeadline: getMaxValidation('billOfDischargeDeadline', 2).nullable().template(),
    billOfDischargeDetails: getMaxValidation('billOfDischargeDetails', 512).nullable().template(),
    detailsOfPlannedActivities: getMaxValidation('detailsOfPlannedActivities', 512).nullable().template(),
    additionalInformation: getMaxValidation('additionalInformation', 512).nullable().template(),
    firstPlaceOfUse: firstPlaceOfUse().nullable(),
    placeOfUse: placeOfUse().nullable(),
    ownerOfTheGoods: ownerOfTheGoodsSchema().nullable(),
    rateOfYield: getMaxValidation('rateOfYield', 512).nullable().template(),
    processedProductsCommodityCode: getMaxValidation('processedProductsCommodityCode', 8).nullable().template(),
    processedProductsDescriptionOfGoods: getMaxValidation('processedProductsDescriptionOfGoods', 512)
        .nullable()
        .template(),
    identificationOfGoodsCode: getExactLengthValidation('identificationOfGoodsCode', 1).nullable().template(),
    identification: getMaxValidation('identification', 512).nullable().template(),
    economicConditionsProcessingProcedure: getMaxValidation('economicConditionsProcessingProcedure', 2)
        .nullable()
        .template(),
    economicConditionsDetails: getMaxValidation('economicConditionsDetails', 512).nullable().template(),
}).nullable();

export const additionalInformationSchema = () =>
    Yup.object().shape({
        additionalInformationCode: getExactLengthValidation('additionalInformationCode', 5).nullable().template(),
        additionalInformationText: getMaxValidation('additionalInformationText', 512).nullable().template(),
    });

export const simplifiedDeclarationDocumentWritingOffObject = Yup.object().shape({
    previousDocumentType: getMaxValidation('previousDocumentType', 5).nullable().template(),
    previousDocumentIdentifier: getMaxValidation('previousDocumentIdentifier', 35).nullable().template(),
    previousDocumentLineId: getMaxValidation('previousDocumentLineId', 5).nullable().template(),
});

const simplifiedDeclarationDocumentWritingOff = () =>
    Yup.array()
        .of(simplifiedDeclarationDocumentWritingOffObject)
        .test({
            name: 'at-least-one-previous-document',
            message: 'At least one previous document is required',
            test: (value, context) => {
                const data: IrelandImportDeclaration = (context as any).from.at(-1).value;
                const atLeastOneRequired = ['A', 'D', 'Y'].includes(data.additionalDeclarationType ?? '');
                if (atLeastOneRequired && !value?.length) return false;
                return true;
            },
        });

const additionsDeductions = () =>
    Yup.array().of(
        Yup.object().shape({
            additionsDeductionsCode: getExactLengthValidation('additionsDeductionsCode', 2).nullable().template(),
            amount: getMaxValidation('amount', 16).nullable().template(),
        })
    );

const supplyChainActor = () =>
    Yup.array().of(
        Yup.object().shape({
            roleCode: getMaxValidation('roleCode', 3).nullable().template(),
            traderIdentification: getMaxValidation('traderIdentification', 17).nullable().template(),
        })
    );

export const packaging = () =>
    Yup.object().shape({
        typePackage: getMaxValidation('typePackage', 2)
            .required(getRequiredMessage('typePackage'))
            .nullable()
            .template(),
        packageNumber: getNumberMaxDigitsValidation('packageNumber', 8)
            .required(getRequiredMessage('packageNumber'))
            .nullable()
            .template(),
        shippingMarks: getMaxValidation('shippingMarks', 512)
            .required(getRequiredMessage('shippingMarks'))
            .nullable()
            .template(),
    });

const nationalAdditionalCommodityCode = () =>
    Yup.array().of(getMaxValidation('nationalAdditionalCommodityCode', 4).nullable().template()).template();

const goodsInformation = () =>
    Yup.object()
        .shape({
            netMass: getMaxValidation('netMass', 16)
                .required(getRequiredMessage('netMass'))
                .nullable()
                .template()
                .test('netMass-not-larger-grossMass', 'Net mass cannot be larger than gross mass', function (value) {
                    if (!value || !this.parent.grossMass) {
                        return true;
                    }
                    const numberValue = parseFloat(value);
                    return this.parent.grossMass >= numberValue;
                }),
            supplementaryUnits: getFloatValidation('supplementaryUnits', 16, 6).nullable().template(),
            grossMass: getFloatValidation('grossMass', 16, 6).nullable().template(),
            goodsDescription: getMaxValidation('goodsDescription', 512)
                .required(getRequiredMessage('goodsDescription'))
                .nullable()
                .template(),
            cusCode: getExactLengthValidation('cusCode', 8).nullable().template(),
            combinedNomenclatureCode: getMaxValidation('combinedNomenclatureCode', 8)
                .required(getRequiredMessage('combinedNomenclatureCode'))
                .nullable()
                .template(),
            taricCode: getExactLengthValidation('taricCode', 2)
                .required(getRequiredMessage('taricCode'))
                .matches(TARIC_CODE_TYPE, 'Taric code should have two integer.')
                .nullable()
                .template(),
            taricAdditionalCode: Yup.array().of(Yup.string().nullable().template()).nullable().template(),
            typeGoods: getMaxValidation('typeGoods', 2).nullable().template(),
            packaging: Yup.array().of(packaging()).nullable(),
            nationalAdditionalCommodityCode: nationalAdditionalCommodityCode().nullable(),
        })
        .nullable();

const additionalProceduresSchema = () =>
    Yup.array().of(
        Yup.object({
            additionalProcedure: getExactLengthValidation('additionalProcedure', 3).nullable().template(),
        }).nullable()
    );
const containerIdentificationNumber = () =>
    Yup.array().of(getMaxValidation('containerIdentificationNumber', 17).nullable().template()).template();

export const additionalFiscalReference = () => Yup.array().of(additionalFiscalReferenceObj);

export const additionalFiscalReferenceObj = Yup.object()
    .shape({
        vatNumber: getEoriValidation('referenceId').required(getRequiredMessage('referenceId')),
        roleCode: getMaxValidation('referenceVat', 3)
            .required(getRequiredMessage('referenceVat'))
            .nullable()
            .template(),
    })
    .nullable();

export const simplifiedDeclarationDocumentWritingOffItem = Yup.object()
    .shape({
        previousDocumentType: getMaxValidation('previousDocumentType', 5).nullable().template(),
        previousDocumentIdentifier: getMaxValidation('previousDocumentIdentifier', 35).nullable().template(),
        previousDocumentLineId: getMaxValidation('previousDocumentLineId', 5).nullable().template(),
        taricAdditionalCode: getExactLengthValidation('taricAdditionalCode', 4).nullable().template(),
        measurementUnitAndQualifier: getMaxValidation('measurementUnitAndQualifier', 6).nullable().template(),
        quantity: getMaxValidation('quantity', 16).nullable().template(),
        typeOfPackages: getMaxValidation('typeOfPackages', 2).nullable().template(),
        numberOfPackages: getMaxValidation('numberOfPackages', 8).nullable().template(),
    })
    .nullable();

export const simplifiedDeclarationDocumentWritingOffItems = () =>
    Yup.array().of(simplifiedDeclarationDocumentWritingOffItem);

const taxBox43Bis = () =>
    Yup.array().of(
        Yup.object()
            .shape({
                boxTaxType: getExactLengthValidation('boxTaxType', 3).nullable().template(),
                boxAmount: getMaxValidation('boxAmount', 16).nullable().template(),
                boxTaxRate: getMaxValidation('boxTaxRate', 17).nullable().template(),
                boxTaxPayableAmount: getMaxValidation('boxTaxPayableAmount', 16).nullable().template(),
                boxTaxPaymentMethod: getExactLengthValidation('boxTaxPaymentMethod', 1).nullable().template(),
            })
            .nullable()
    );

const getStatisticalValueValidation = () => {
    return getNumberMaxDigitsValidation('statisticalValue', 16)
        .nullable()
        .template()
        .test({
            name: 'statistical-value',
            message: `The value entered in DE '8/6 Statistical value' must be positive number and match the following calculation: (DE '4/14 Item price/amount' divided by DE '4/15 Exchange rate') plus (the sum of Additions in DE '4/9 Additions and deductions') minus (the sum of Deductions in DE '4/9 Additions and deductions').  excluding 1X, BA, BF & BB.`,
            test: (value, context) => {
                if (value == null) return true;
                return value >= 0;
            },
        });
};

export const goodsShipmentItemSchemaRequired = Yup.object()
    .shape({
        procedureCode: getExactLengthValidation('procedureCode', 2).nullable().template(),
        previousProcedureCode: getExactLengthValidation('previousProcedureCode', 2).nullable().template(),
        ucr: getMaxValidation('ucr', 35).nullable().template(),
        taxTotalAmount: getMaxValidation('taxTotalAmount', 35).nullable().template(),
        itemAmount: getMaxValidation('itemAmount', 16)
            .nullable()
            .template()
            .test({
                name: 'item-amount',
                message: 'This field is required',
                test: (value, context) => {
                    const data: IrelandImportDeclaration = (context as any).from.at(-1).value;
                    const messageType = data.messageType;
                    const additionalDeclarationType = data.additionalDeclarationType;
                    if (
                        ['H1', 'H3', 'H4', 'H5'].includes(messageType ?? '') &&
                        ['A', 'D'].includes(additionalDeclarationType ?? '')
                    ) {
                        return true;
                    }
                    return false;
                },
            }),
        valuationMethod: getExactLengthValidation('valuationMethod', 1)
            .required(getRequiredMessage('valuationMethod'))
            .nullable()
            .template(),
        preference: getExactLengthValidation('preference', 3)
            .required(getRequiredMessage('preference'))
            .nullable()
            .template(),
        countryDestination: getExactLengthValidation('countryDestination', 2).nullable().template(),
        regionDestination: getExactLengthValidation('regionDestination', 2).nullable().template(),
        countryDispatch: getExactLengthValidation('countryDispatch', 2).nullable().template(),
        countryOrigin: getExactLengthValidation('countryOrigin', 2)
            .required(getRequiredMessage('countryOrigin'))
            .nullable()
            .template(),
        countryPreferentialOrigin: getMaxValidation('countryPreferentialOrigin', 4).nullable().template(),
        acceptanceDate: Yup.date().nullable().template(),
        quotaOrderNumber: getExactLengthValidation('quotaOrderNumber', 6).nullable().template(),
        transactionNature: getMaxValidation('transactionNature', 2).nullable().template(),
        statisticalValue: getStatisticalValueValidation(),
        goodsInformation: goodsInformation().nullable(),
        additionalProcedure: additionalProceduresSchema().nullable(),
        containerIdentificationNumber: containerIdentificationNumber().nullable(),
        simplifiedDeclarationDocumentWritingOff: simplifiedDeclarationDocumentWritingOffItems().nullable(),
        producedDocumentsWritingOff: Yup.object({
            N935: Yup.array()
                .of(getMinCharactersValidation('invoiceNumber', 5).nullable().template())
                .nullable()
                .template(),
        }).nullable(),
        additionalInformation: additionalInformationSchema().nullable(),
        taxBox43Bis: taxBox43Bis().nullable(),
        additionsDeductions: additionsDeductions().nullable(),
        supplyChainActor: supplyChainActor().nullable(),
        additionalFiscalReference: additionalFiscalReference().nullable(),
        exporter: getCustomerValidation().nullable(),
        seller: getCustomerValidation().nullable(),
        buyer: getCustomerValidation().nullable(),
    })
    .nullable();

const goodsShipmentItem = () => Yup.array().of(goodsShipmentItemSchemaRequired);

const locationGoods = () =>
    Yup.object()
        .shape({
            identificationOfLocation: getMaxValidation('identificationOfLocation', 35)
                .required(getRequiredMessage('identificationOfLocation'))
                .nullable()
                .template(),
            additionalIdentifier: getExactLengthValidation('additionalIdentifier', 3).nullable().template(),
            locationTypeCode: getExactLengthValidation('locationTypeCode', 1)
                .required(getRequiredMessage('locationTypeCode'))
                .nullable()
                .template(),
            qualifierIdentification: getExactLengthValidation('qualifierIdentification', 1)
                .required(getRequiredMessage('qualifierIdentification'))
                .nullable()
                .template(),
            address: Yup.object()
                .shape({
                    country: getMaxValidation('country', 2).nullable().template(),
                })
                .nullable(),
        })
        .nullable();

const goodsShipmentSchema = Yup.object({
    importer: getCustomerValidation({ eori: { required: true } }).nullable(),
    seller: getCustomerValidation().nullable(),
    buyer: getCustomerValidation().nullable(),
    additionalInformation: additionalInformationSchema().nullable(),
    simplifiedDeclarationDocumentWritingOff: simplifiedDeclarationDocumentWritingOff().nullable(),
    additionsDeductions: additionsDeductions().nullable(),
    supplyChainActor: supplyChainActor().nullable(),
    goodsShipmentItem: goodsShipmentItem().required(getRequiredMessage('goodsShipmentItem')).nullable(),
    ucr: getMaxValidation('ucr', 35).nullable().template(),
    warehouseType: getExactLengthValidation('warehouseType', 1).nullable().template(),
    warehouseIdentifier: getMaxValidation('warehouseIdentifier', 35).nullable().template(),
    incotermCode: getExactLengthValidation('incotermCode', 3)
        .required(getRequiredMessage('incotermCode'))
        .nullable()
        .template(),
    unlocode: getEoriValidation('unlocode').template(),
    countryCode: getExactLengthValidation('countryCode', 2).nullable().template(),
    place: getMaxValidation('place', 35).nullable().template(),
    transportCostsCurrency: getMaxValidation('transportCostsCurrency', 3).nullable().template(),
    transportCostsAmount: getMaxValidation('transportCostsAmount', 16).nullable().template(),
    countryDestination: getExactLengthValidation('countryDestination', 2)
        .required(getRequiredMessage('countryDestination'))
        .nullable()
        .template(),
    regionDestination: getMaxValidation('regionDestination', 9).nullable().template(),
    countryDispatch: getExactLengthValidation('countryDispatch', 2)
        .required(getRequiredMessage('countryDispatch'))
        .nullable()
        .template(),
    container: Yup.boolean().nullable(),
    inlandBorderTransportMode: getExactLengthValidation('inlandBorderTransportMode', 1).nullable().template(),
    meansIdentificationType: getExactLengthValidation('meansIdentificationType', 2)
        .required(getRequiredMessage('meansIdentificationType'))
        .nullable()
        .template(),
    meansIdentificationNumber: getMaxValidation('meansIdentificationNumber', 35)
        .required(getRequiredMessage('meansIdentificationNumber'))
        .nullable()
        .template(),
    transactionNature: getMaxValidation('transactionNature', 2).nullable().template(),
    locationGoods: locationGoods().nullable(),
    producedDocumentsWritingOff: Yup.object()
        .shape({
            N935: Yup.array().of(getMinCharactersValidation('invoiceNumber', 5).nullable()).nullable().template(),
            N934: Yup.array().nullable().template(),
            '1A01': Yup.array().nullable().template(),
            N740: Yup.array().nullable().template(),
            N741: Yup.array().nullable().template(),
            N703: Yup.array().nullable().template(),
            N705: Yup.array().nullable().template(),
            N730: Yup.array().nullable().template(),
            N704: Yup.array().nullable().template(),
            N714: Yup.array().nullable().template(),
            '1D24': Yup.array().nullable().template(),
            '1D94': Yup.array().nullable().template(),
            '1D95': Yup.array().nullable().template(),
            '1Q35': Yup.array().nullable().template(),
        })
        .nullable(),
    acceptanceDate: Yup.date().nullable().template(),
    containerIdentificationNumber: containerIdentificationNumber().nullable(),
}).nullable();

export const authorisationHolder = Yup.array()
    .of(
        Yup.object()
            .shape({
                authorisationTypeCode: getMaxValidation('authorisationTypeCode', 4).nullable().template(),
                authorisationHolderIdentification: getMaxValidation('authorisationHolderIdentification', 17)
                    .nullable()
                    .template(),
            })
            .nullable()
    )
    .nullable();

export const guaranteeReferenceValidation = Yup.object()
    .shape({
        grn: getMaxValidation('grn', 24).nullable().template(),
        otherGuaranteeReference: getMaxValidation('otherGuaranteeReference', 35).nullable().template(),
        accessCode: getMaxValidation('accessCode', 4).nullable().template(),
        currency: getExactLengthValidation('currency', 3).nullable().template(),
        amount: getMaxValidation('amount', 16).nullable().template(),
        guaranteeCustomsOffice: getExactLengthValidation('guaranteeCustomsOffice', 8)
            .matches(CUSTOMS_OFFICE, invalidEoriMessage)
            .nullable()
            .template(),
    })
    .nullable();

export const guaranteeReferences = Yup.array().of(guaranteeReferenceValidation).nullable();

export const guaranteeSchema = Yup.array().of(
    Yup.object()
        .shape({
            guaranteeType: getMaxValidation('guaranteeType', 1).nullable().template(),
            guaranteeReference: guaranteeReferences,
        })
        .nullable()
);

const h1Validation = (numberOfItems?: number) =>
    Yup.object().shape({
        validationRequired: Yup.boolean().nullable(),
        declarationType: Yup.string().required(getRequiredMessage('declarationType')).nullable().template(),
        numberOfItems: getNumberOfItemsValidation(numberOfItems)
            .required(getRequiredMessage('numberOfItems'))
            .nullable()
            .template(),
        additionalDeclarationType: Yup.string()
            .required('Additional declaration type is required.')
            .nullable()
            .template(),
        deferredPayment: getMaxValidation('deferredPayment', 35).nullable().template(),
        invoiceCurrency: getMaxValidation('invoiceCurrency', 3)
            .required(getRequiredMessage('invoiceCurrency'))
            .nullable()
            .template(),
        invoiceAmount: getMaxValidation('invoiceAmount', 16).nullable().template(),
        internalCurrency: getMaxValidation('internalCurrency', 3).nullable().template(),
        exchangeRate: getMaxValidation('exchangeRate', 12).nullable().template(),
        borderTransportMode: getMaxValidation('borderTransportMode', 2).nullable().template(),
        activeBorderTransportMeansNationality: getMaxValidation('activeBorderTransportMeansNationality', 2)
            .nullable()
            .template(),
        presentationCustomsOffice: getExactLengthValidation('presentationCustomsOffice', 8).nullable().template(),
        supervisingCustomsOffice: Yup.string().matches(CUSTOMS_OFFICE, invalidEoriMessage).nullable().template(),
        customsOfficeLodgement: getExactLengthValidation('customsOfficeLodgement', 8)
            .required(getRequiredMessage('customsOfficeLodgement'))
            .nullable()
            .template(),
        representativeIdentification: getMaxValidation('representativeIdentification', 17).nullable().template(),
        representativeStatus: getMaxValidation('representativeStatus', 1).nullable().template(),
        personProvidingGuarantee: getEoriValidation(),
        personPayingCustomsDuty: getEoriValidation().required(getRequiredMessage('personPayingCustomsDuty')),
        preferredPaymentMethod: getMaxValidation('preferredPaymentMethod', 1)
            .required(getRequiredMessage('preferredPaymentMethod'))
            .nullable()
            .template(),
        exporter: getCustomerValidation().nullable(),
        declarant: getCustomerValidation({ eori: { required: true } }).nullable(),
        representative: getCustomerValidation().nullable(),
        authorisation: authorisationSchema.nullable(),
        goodsShipment: goodsShipmentSchema,
        guarantee: guaranteeSchema.nullable(),
        authorisationHolder: authorisationHolder,
        airwayBill: getMaxValidation('airwayBill', 255).nullable().template(),
        masterAirwayBill: getMaxValidation('masterAirwayBill', 255).nullable().template(),
        houseWayBill: getMaxValidation('houseWayBill', 255).nullable().template(),
        billOfLading: getMaxValidation('billOfLading', 255).nullable().template(),
        roadConsignmentNote: getMaxValidation('roadConsignmentNote', 255).nullable().template(),
        masterBillOfLading: getMaxValidation('masterBillOfLading', 255).nullable().template(),
        houseBillOfLading: getMaxValidation('houseBillOfLading', 255).nullable().template(),
        dv1Declaration: getMaxValidation('dv1Declaration', 255).nullable().template(),
        vatFreeAuthorisation: getMaxValidation('vatFreeAuthorisation', 255).nullable().template(),
        roroShipId: getMaxValidation('roroShipId', 8).nullable().template(),
        roroUnaccompaniedTrailerReg: getMaxValidation('roroUnaccompaniedTrailerReg', 32).nullable().template(),
        textilesCertOfOrigin: getMaxValidation('textilesCertOfOrigin', 255).nullable().template(),
        grossMass: getFloatValidation('grossMass', 16, 6).nullable().template(),
    });

export default h1Validation;
