import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { Customer } from 'store/customers/customer';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import useSearchCustomer from 'views/declarations/common/search-customer/useSearchCustomer';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import {
    getBox44FieldPath,
    getDateFormatForBox44,
    h7Box44KeyNames,
    h7PathBox44,
    handleBox44Field,
} from 'views/declarations/common/box44/box-44-utils';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import useProducts from 'hooks/useProducts';
import useNumberOfItemsModal from 'views/declarations/common/declaration-view/utils/useNumberOfItemsModal';
import { validators } from 'views/declarations/uk/export/validations/validations';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';

const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH7TooltipsByRefNumber, getH7TooltipsByField, getH7TooltipsByRefNumberAndField } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();
    const { createIrelandH7ImportProduct, listIrelandH7Products } = useProducts();

    const getTooltip = useCallback(
        (sadBox: string, label: string) => {
            return getH7TooltipsByRefNumberAndField(sadBox, label);
        },
        [getH7TooltipsByRefNumberAndField]
    );

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createIrelandH7ImportProduct,
        listProducts: listIrelandH7Products,
    });

    return (
        <NewFormCard title="Master Details">
            <FormCardContainer>
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionalDeclarationType`, props)}
                    refNumber="1.2"
                    label={t('additionalDeclarationType')}
                    tooltip={getH7TooltipsByRefNumber('1.2')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={aisCodelists?.additionalDeclarationType}
                    condensed
                    codeValidation={[validators.maxLength(3)]}
                />
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`customsOfficeLodgement`, props)}
                    refNumber="1.11"
                    label={t('customsOfficeLodgement')}
                    tooltip={getH7TooltipsByRefNumber('1.11')}
                    selectOptions={aisCodelists?.nationalCustomsOffice}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.exact(8)]}
                />
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`deferredPayment`, props)}
                    label={t('deferredPayment')}
                    tooltip={getH7TooltipsByField(t('deferredPayment'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    onSearchCustomer={({ fieldHelpers }) => {
                        triggerShowSearchCustomerDrawer((customer: Customer) => {
                            fieldHelpers?.setValue(customer.eori);
                        });
                    }}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`preferredPaymentMethod`, props)}
                    label={t('preferredPaymentMethod')}
                    tooltip={getH7TooltipsByField(t('preferredPaymentMethod'))}
                    selectOptions={aisCodelists?.taxPaymentMethod}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.exact(1)]}
                />
                <FormInput
                    maxLength={17}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`representativeIdentificationNumber`, props)}
                    refNumber="3.20"
                    label={t('representativeIdNumber')}
                    tooltip={getH7TooltipsByRefNumber('3.20')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    onSearchCustomer={({ fieldHelpers }) =>
                        triggerShowSearchCustomerDrawer((customer: Customer) => {
                            fieldHelpers?.setValue(customer.eori);
                        })
                    }
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`representativeStatus`, props)}
                    refNumber="3.4"
                    label={t('representativeStatus')}
                    tooltip={getH7TooltipsByRefNumber('3.4')}
                    selectOptions={aisCodelists?.representativeStatusCode}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.exact(1)]}
                />
                <FormInput
                    viewOnly={props.viewOnly}
                    maxLength={35}
                    {...getFormikProps(`goodsShipment.documentsAuthorisations.referenceNumberUcr`, props)}
                    refNumber="2.4"
                    label={t('referenceNumberUCR')}
                    tooltip={getH7TooltipsByRefNumberAndField('2.4', t('referenceNumberUCR'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`numberOfItems`, props)}
                    fieldEvents={{
                        onBlur(value) {
                            handleNumberOfItems(Number(value));
                        },
                    }}
                    label={t('numberOfItems')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                {modalContextHolder}
                <FormInput
                    viewOnly={props.viewOnly}
                    label={t('vatSpacialArrangementAuthorisation')}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h7PathBox44, keyNames: h7Box44KeyNames, type: '1A06' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h7PathBox44,
                                keyNames: h7Box44KeyNames,
                                type: '1A06',
                            },
                            { template, form }
                        )
                    }
                    refNumber="1A06"
                    condensed
                />

                <FormInput
                    maxLength={512}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h7PathBox44, keyNames: h7Box44KeyNames, type: 'N935' }),
                        props
                    )}
                    onChange={(e) => {
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h7PathBox44,
                                keyNames: h7Box44KeyNames,
                                type: 'N935',
                            },
                            { template, form }
                        );
                    }}
                    label={t('Invoice Number')}
                    tooltip={getH7TooltipsByRefNumber('N935') !== 'N/A' ? getH7TooltipsByRefNumber('N935') : undefined}
                    refNumber="N935"
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationDatepicker
                    showTime
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h7PathBox44, keyNames: h7Box44KeyNames, type: '1D24' }),
                        props
                    )}
                    onChange={(value) =>
                        handleBox44Field(
                            getDateFormatForBox44(value),
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h7PathBox44,
                                keyNames: h7Box44KeyNames,
                                type: '1D24',
                            },
                            { template, form }
                        )
                    }
                    refNumber="1D24"
                    label={t('estimatedTimeOfArrival')}
                    tooltip={getH7TooltipsByRefNumber('1D24')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    numbersAndLetters
                    viewOnly={props.viewOnly}
                    maxLength={255}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h7PathBox44, keyNames: h7Box44KeyNames, type: 'N705' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h7PathBox44,
                                keyNames: h7Box44KeyNames,
                                type: 'N705',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N705"
                    label={t('billOfLading')}
                    tooltip={getTooltip('N705', t('billOfLading'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    numbersAndLetters
                    viewOnly={props.viewOnly}
                    maxLength={255}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h7PathBox44, keyNames: h7Box44KeyNames, type: 'N703' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h7PathBox44,
                                keyNames: h7Box44KeyNames,
                                type: 'N703',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N703"
                    label={t('houseWayBill')}
                    tooltip={getTooltip('N703', t('houseWayBill'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <SearchCustomer.Drawer
                    onClose={triggerHideSearchCustomerDrawer}
                    show={showSearchCustomerDrawer}
                    onSelectCustomer={handleSelectCustomer}
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.grossMass`, props)}
                    refNumber="6.5"
                    label={t('goodsItems.grossMass')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default MasterDetailsCard;
