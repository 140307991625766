import { cloneDeep, merge } from 'lodash';
import { validators } from 'views/declarations/uk/export/validations/validations';
import DeepPartial from 'types/DeepPartial';
import {
    getPartyValidator,
    irelandExportB1ValidationHeader,
    irelandExportB1ValidationItem,
} from '../../export/validation/IrelandExportB1Validation';

const REMOVE = null as any;

// -- Item
const item: DeepPartial<typeof irelandExportB1ValidationItem> = {
    childValidators: {
        additionalReference: REMOVE,
        statisticalValue: REMOVE,
        natureOfTransaction: REMOVE,
        procedure: REMOVE,
        authorisation: {
            childValidators: {
                ...irelandExportB1ValidationItem.childValidators.authorisation.childValidators,
                holderOfAuthorisation: [validators.eori()],
            },
        },
        additionalInformation: REMOVE,
        supportingDocument: REMOVE,
        transportDocument: {
            childValidators: {
                type: [validators.exact(4)],
                referenceNumber: [validators.maxLength(70)],
            },
            stopAtNull: true,
        },
        additionalSupplyChainActor: REMOVE,
        transportCharges: REMOVE,
        origin: {
            childValidators: {
                countryOfOrigin: REMOVE,
                regionOfDispatch: REMOVE,
            },
        },
        commodity: {
            childValidators: {
                descriptionOfGoods: REMOVE,
                dangerousGoods: REMOVE,
                cusCode: REMOVE,
                commodityCode: REMOVE,
                goodsMeasure: {
                    childValidators: {
                        supplementaryUnits: REMOVE,
                        netMass: [validators.float(16, 6)],
                    },
                },
                calculationOfTaxes: REMOVE,
            },
        },
        consignee: REMOVE,
        consignor: REMOVE,
        countryOfDestination: REMOVE,
        countryOfExport: REMOVE,
        previousDocument: REMOVE,
        packaging: {
            childValidators: {
                ...irelandExportB1ValidationItem.childValidators.packaging.childValidators,
                typeOfPackages: [validators.maxLength(5)],
            },
            selfValidators: REMOVE,
            stopAtNull: true,
        },
    },
};
export const arrivalAtExitValidationItem = merge(cloneDeep(irelandExportB1ValidationItem), item);

// --- Header
const header: DeepPartial<typeof irelandExportB1ValidationHeader | any> = {
    childValidators: {
        exportOperation: {
            childValidators: {
                arrivalNotificationDateAndTime: [validators.required()],
                arrivalNotificationPlace: [validators.maxLength(35)],
                storingFlag: [validators.required(), validators.number(), validators.exact(1)],
                discrepanciesExist: [validators.required(), validators.number(), validators.exact(1)],
                declarationType: REMOVE,
                additionalDeclarationType: REMOVE,
                presentationOfTheGoodsDateAndTime: REMOVE,
                security: REMOVE,
                specificCircumstanceIndicator: REMOVE,
                totalAmountInvoiced: REMOVE,
                invoiceCurrency: REMOVE,
            },
        },
        currencyExchange: REMOVE,
        customsOfficeOfPresentation: REMOVE,
        deferredPayment: REMOVE,
        exporter: REMOVE,
        declarant: REMOVE,
        representative: REMOVE,
        customsOfficeOfExitDeclared: REMOVE,
        customsOfficeOfExitActual: {
            childValidators: {
                referenceNumber: [validators.required(), validators.exact(8)],
            },
        },
        customsOfficeOfExport: REMOVE,
        goodsShipment: {
            childValidators: {
                supportingDocument: REMOVE,
                additionalInformation: REMOVE,
                natureOfTransaction: REMOVE,
                warehouse: REMOVE,
                deliveryTerms: REMOVE,
                additionalReference: REMOVE,
                additionalSupplyChainActor: REMOVE,
                countryOfDestination: REMOVE,
                countryOfExport: REMOVE,
                consignment: {
                    childValidators: {
                        grossMass: REMOVE,
                        countryOfRoutingOfConsignment: REMOVE,
                        carrier: REMOVE,
                        exitCarrier: getPartyValidator({ contactPerson: { present: true } }),
                        consignee: REMOVE,
                        consignor: REMOVE,
                        containerIndicator: REMOVE,
                        inlandModeOfTransport: REMOVE,
                        departureTransportMeans: REMOVE,
                        transportCharges: REMOVE,
                        transportEquipment: {
                            childValidators: {
                                containerIdentificationNumber: [validators.maxLength(17)],
                                numberOfSeals: [validators.number(), validators.maxLength(4)],
                                seal: {
                                    childValidators: {
                                        identifier: [validators.maxLength(20)],
                                    },
                                    stopAtNull: true,
                                },
                                goodsReference: {
                                    childValidators: {
                                        declarationGoodsItemNumber: [validators.number(), validators.maxLength(5)],
                                    },
                                    stopAtNull: true,
                                },
                            },
                            stopAtNull: true,
                        },
                        locationOfGoods: {
                            childValidators: {
                                ...irelandExportB1ValidationHeader.childValidators.goodsShipment.childValidators
                                    .consignment.childValidators.locationOfGoods.childValidators,
                                typeOfLocation: [validators.required(), validators.exact(1)],
                                qualifierOfIdentification: [validators.required(), validators.exact(1)],
                                unLocode: [validators.maxLength(4)],
                            },
                            selfValidators: [validators.minLength(1)],
                            stopAtNull: true,
                        },
                    },
                },
                goodsItem: arrivalAtExitValidationItem,
                previousDocument: REMOVE,
            },
        },
    },
};
export const arrivalAtExitValidationHeader = merge(cloneDeep(irelandExportB1ValidationHeader), header);
