import { Alert } from 'antd';

const AlertPresentDocuments = () => {
    return (
        <Alert
            message={'Revenue has requested documents to be physically presented at a Customs Office'}
            closable
            type="warning"
            showIcon
        />
    );
};
export default AlertPresentDocuments;
