import { get } from 'lodash';

export const missingGrossMassFromAllItems = (products: any[] | undefined, path: string | undefined) => {
    return path && products?.every((product: any) => !get(product, path));
};

export const missingGrossMassFromSomeItems = (products: any[] | undefined, path: string | undefined) => {
    return path && products?.some((product: any) => !get(product, path));
};

export const missingGrossMassFromMaster = (declaration: any, path: string | undefined) => {
    return path && !get(declaration, path);
};

export const calculateGrossMass = (totalGrossMass: number, itemAmount: number, itemAmountsSum: number) => {
    const grossMass = totalGrossMass * (itemAmount / itemAmountsSum);
    return parseFloat(grossMass.toString()).toFixed(2);
};
