import { Tooltip, notification } from 'antd';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProps, useFormikContext } from 'formik';
import { ReactElement, useEffect, useMemo } from 'react';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import { ThunderboltOutlined } from '@ant-design/icons';
import useDeclarations from 'hooks/useDeclarations';
import { getFormikProps } from '../../../utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';
import { useOutletContext } from 'react-router-dom';
import { HollowButton } from 'views/declarations/common/box44/Box44';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import useCDSPreviousDocumentUtils from '../../../../../hooks/useCdsPreviousDocumentUtils';
import { generateDucr } from '../../../../../store/declarations/client';

const ducrValidation: ValidationSchema<'ducr'> = {
    childValidators: {
        ducr: [validators.required()],
    },
};

export const ducrCardValidation = {
    selfValidators: [ducrValidation],
};

interface Props {
    hide: boolean;
}

const DUCRMUCRCard = ({ hide }: Props): ReactElement => {
    const mainFormik: FormikProps<any> = useFormikContext();
    const { declaration } = useDeclarations();
    const { template, templateFormik } = useTemplateContext();

    const { saveAsDraft } =
        useOutletContext<{
            saveAsDraft: (withNotification: boolean, paramData?: Record<string, string>) => Promise<any>;
        }>() ?? {};

    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

    const formik = useMemo(
        () => (template && templateFormik ? templateFormik : mainFormik),
        [mainFormik, template, templateFormik]
    );

    const { previousDocument, getIndexOfPrevDocByArg, setDUCRMUCR } = useCDSPreviousDocumentUtils({ formik });

    useEffect(() => {
        const dcrIndex = getIndexOfPrevDocByArg('DCR');
        const mcrIndex = getIndexOfPrevDocByArg('MCR');

        if (dcrIndex !== -1) formik.setFieldValue('ducr', previousDocument[dcrIndex]?.id);
        if (mcrIndex !== -1) formik.setFieldValue('mucr', previousDocument[mcrIndex]?.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousDocument, getIndexOfPrevDocByArg]);

    const handleDucrGeneration = async () => {
        if (!declaration?.id || hide) return;
        try {
            const ducr = await generateDucr(declaration.id);
            setDUCRMUCR('DCR', ducr);
            notification.success({ message: 'DUCR generated and filled' });
        } catch {
            notification.error({
                message: '"Exporter - Identification Number" is required for DUCR generation',
            });
        } finally {
            hideGlobalOverlay();
        }
    };

    return (
        <NewFormCard title="DUCR/MUCR" hidden={hide}>
            <FormCardContainer>
                <DeclarationInput
                    label="DUCR"
                    {...getFormikProps(`ducr`, formik)}
                    onBlur={(e) => setDUCRMUCR('DCR', e.target.value)}
                    condensed
                    disabled
                    after={
                        <Tooltip
                            overlay={
                                template
                                    ? 'DUCR is unique for each declaration and should be generated while submission.'
                                    : 'Generate DUCR'
                            }
                        >
                            <HollowButton
                                shape="circle"
                                icon={<ThunderboltOutlined />}
                                disabled={template}
                                onClick={() => {
                                    showGlobalOverlay({
                                        type: 'LoadingOverlay',
                                        payload: { message: 'Generating DUCR...' },
                                    });
                                    saveAsDraft(false).then(handleDucrGeneration);
                                }}
                            />
                        </Tooltip>
                    }
                    required
                />
                <DeclarationInput
                    label="MUCR"
                    {...getFormikProps(`mucr`, formik)}
                    onBlur={(e) => setDUCRMUCR('MCR', e.target.value)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default DUCRMUCRCard;
