import { DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import { handleCurrency, handleNullValues } from 'components/ui/base/table/TableUtils';
import { FC, useCallback, useMemo } from 'react';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { colors } from 'theme';
import { ListPayload } from '../../../../core/http/response';
import useFormUtils from '../../../../hooks/useFormUtils';
import { TableChangeParams } from '../../../../utils/tableHelpers';
import { ProductsErrors } from '../../sections/products/DeclarationProductsTable';
import { ActionButton, InvalidIcon } from './IrelandProductsTable.styles';
import getNCTSProductsTableColumns from './columns/NCTSProductsTableColumns';

interface Props {
    data:
        | ListPayload<H1GoodsShipmentItem>
        | ListPayload<H7GoodsShipmentItem>
        | ListPayload<IrelandExportItem>
        | undefined
        | null;
    onDelete?: (id: string) => void;
    onEdit?: (id: string) => void;
    onAddToTemplate?: (item: string) => void;
    loading?: boolean;
    viewOnly?: boolean;
    errors?: ProductsErrors;
    onChange: (params: TableChangeParams) => void;
}

const IrelandProductsTable: FC<Props> = ({
    data,
    onDelete,
    onEdit,
    onAddToTemplate,
    loading,
    viewOnly,
    errors,
    onChange,
}) => {
    const { formType, internalType } = useFormUtils();

    const checkInvalid = useCallback((index: number) => (errors ? !!errors[index] : false), [errors]); //TODO find by IDs

    const getInvalidStyle = useCallback(
        (index: number) => {
            return checkInvalid(index) ? { style: { background: `${colors.invalid}`, border: 'none' } } : {};
        },
        [checkInvalid]
    );

    const handleTableValues = useCallback(
        (index: number, value?: string | number) => {
            const style = getInvalidStyle(index);
            return { props: style, children: handleNullValues(value) };
        },
        [getInvalidStyle]
    );

    const handleInvalidIcon = useCallback(
        (index: number) => {
            const style = getInvalidStyle(index);
            return checkInvalid(index)
                ? {
                      props: style,
                      children: <InvalidIcon />,
                  }
                : { props: style, children: <></> };
        },
        [getInvalidStyle, checkInvalid]
    );

    const handleCommodityCode = useCallback((commodity?: string, taric?: string) => {
        if (commodity && taric) {
            return `${commodity}${taric}`;
        } else if (commodity) {
            return `${commodity}`;
        } else {
            return '-';
        }
    }, []);

    const columns: ColumnsType<any> = useMemo(() => {
        if (internalType === DeclarationInternalType.NCTS) return getNCTSProductsTableColumns(handleTableValues);

        return [
            {
                title: 'Commodity Code',
                dataIndex: 'commodity_code',
                key: 'commodity_code',
                render: (
                    text,
                    record: H1GoodsShipmentItem | H7GoodsShipmentItem | IrelandExportItem,
                    index: number
                ) => {
                    if (internalType === DeclarationInternalType.EXPORT) {
                        const data = record as IrelandExportItem;
                        return handleTableValues(index, data?.commodity?.commodityCode?.combinedNomenclatureCode);
                    } else if (internalType === DeclarationInternalType.NCTS) {
                        const data = record as any;
                        return handleTableValues(
                            index,
                            handleCommodityCode(
                                data?.goodsInformation?.combinedNomenclatureCode,
                                data.goodsInformation?.taricCode
                            )
                        );
                    } else {
                        if (formType === DeclarationName.H7) {
                            const data = record as H7GoodsShipmentItem;
                            return handleTableValues(index, data?.commodityCodeHarmonizedSystemSubHeadingCode);
                        } else {
                            const data = record as H1GoodsShipmentItem;
                            return handleTableValues(
                                index,
                                handleCommodityCode(
                                    data?.goodsInformation?.combinedNomenclatureCode,
                                    data.goodsInformation?.taricCode
                                )
                            );
                        }
                    }
                },
                sorter: (a: any, b: any) => {
                    if (internalType === DeclarationInternalType.EXPORT) {
                        return a.commodity?.nomenclatureCode - b.commodity?.nomenclatureCode;
                    } else {
                        if (formType === DeclarationName.H7) {
                            return (
                                a.commodityCodeHarmonizedSystemSubHeadingCode -
                                b.commodityCodeHarmonizedSystemSubHeadingCode
                            );
                        } else {
                            return `${a?.goodsInformation?.combinedNomenclatureCode}${a.goodsInformation?.taricCode}`.localeCompare(
                                `${b?.goodsInformation?.combinedNomenclatureCode}${b.goodsInformation?.taricCode}`
                            );
                        }
                    }
                },
            },
            {
                title: 'Description of Goods',
                dataIndex: 'description_of_goods',
                key: 'description_of_goods',
                render: (
                    text,
                    record: H1GoodsShipmentItem | H7GoodsShipmentItem | IrelandExportItem,
                    index: number
                ) => {
                    if (internalType === DeclarationInternalType.EXPORT) {
                        const data = record as IrelandExportItem;
                        return handleTableValues(index, data.commodity?.descriptionOfGoods);
                    } else {
                        if (formType === DeclarationName.H7) {
                            const data = record as H7GoodsShipmentItem;
                            return handleTableValues(index, data?.descriptionOfGoods);
                        } else {
                            const data = record as H1GoodsShipmentItem;
                            return handleTableValues(index, data?.goodsInformation?.goodsDescription);
                        }
                    }
                },
                sorter: (a: any, b: any) => {
                    if (internalType === DeclarationInternalType.EXPORT) {
                        return a.packaging?.description ?? '-'.localeCompare(b.packaging?.description ?? '-');
                    } else {
                        if (formType === DeclarationName.H7) {
                            return a.descriptionOfGoods ?? '-'.localeCompare(b.descriptionOfGoods ?? '-');
                        } else {
                            return (
                                a.goodsInformation?.goodsDescription ??
                                '-'.localeCompare(b.goodsInformation?.goodsDescription ?? '-')
                            );
                        }
                    }
                },
            },
            {
                title: 'Item Price',
                dataIndex: 'item_price',
                key: 'item_price',
                render: (
                    text,
                    record: H1GoodsShipmentItem | H7GoodsShipmentItem | IrelandExportItem,
                    index: number
                ) => {
                    if (internalType === DeclarationInternalType.EXPORT) {
                        return <span>-</span>;
                    } else {
                        if (formType === DeclarationName.H7) {
                            const data = record as H7GoodsShipmentItem;
                            return handleTableValues(
                                index,
                                handleCurrency(
                                    data?.itemAmountInvoicedIntrinsicValue?.valueAmount,
                                    data?.itemAmountInvoicedIntrinsicValue?.valueCurrency
                                )
                            );
                        } else {
                            const data = record as H1GoodsShipmentItem;
                            return handleTableValues(index, data?.itemAmount);
                        }
                    }
                },
                sorter: (a: any, b: any) => {
                    if (formType === DeclarationName.H7) {
                        return (
                            a.itemAmountInvoicedIntrinsicValue?.valueAmount -
                            b.itemAmountInvoicedIntrinsicValue?.valueAmount
                        );
                    } else {
                        return a.itemAmount - b.itemAmount;
                    }
                },
            },
            {
                title: 'Tag',
                dataIndex: 'tag',
                key: 'tag',
                render: (text, record: H1GoodsShipmentItem | H7GoodsShipmentItem | IrelandExportItem, index: number) =>
                    handleTableValues(index, '-'),
                sorter: true,
            },
        ];
    }, []);

    const commandsColumn: ColumnsType<any> = [
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (text, record: H1GoodsShipmentItem | H7GoodsShipmentItem | IrelandExportItem, index: number) => ({
                props: getInvalidStyle(index),
                children: (
                    <>
                        {viewOnly ? (
                            formType !== 'ARRIVAL' && (
                                <ActionButton
                                    invalid={checkInvalid(index)}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onAddToTemplate?.(record.id!);
                                    }}
                                >
                                    Add to templates
                                </ActionButton>
                            )
                        ) : (
                            <>
                                {formType !== 'ARRIVAL' && (
                                    <ActionButton
                                        size="small"
                                        invalid={checkInvalid(index)}
                                        marginRight="1.2"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onAddToTemplate?.(record.id!);
                                        }}
                                    >
                                        Add to templates
                                    </ActionButton>
                                )}
                                <ActionButton
                                    size="small"
                                    invalid={checkInvalid(index)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete && onDelete(record.id!);
                                    }}
                                >
                                    Delete <DeleteOutlined />
                                </ActionButton>
                            </>
                        )}
                    </>
                ),
            }),
        },
    ];

    const columnIcon: ColumnsType<any> = [
        {
            dataIndex: 'icon',
            key: 'icon',
            render: (text, record, index: number) => handleInvalidIcon(index),
        },
    ];

    const rowSelection: TableRowSelection<any> = {
        renderCell: (value, record, index: number, originNode: React.ReactNode) => {
            return { props: getInvalidStyle(index), children: originNode };
        },
    };

    const templateColumns = useMemo(() => {
        if (onAddToTemplate) {
            return [...columns, ...commandsColumn, ...columnIcon];
        }

        return [...columns, ...columnIcon];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onAddToTemplate]);

    return (
        <Table
            rowKey="id"
            dataSource={data?.list}
            columns={templateColumns}
            loading={loading}
            rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        event.stopPropagation();
                        return onEdit && onEdit(record.id!);
                    },
                };
            }}
            onChange={(pagination, _, sorter) => onChange({ pagination, sorter })}
            pagination={{
                current: (data?.pageNumber || 0) + 1,
                total: data?.total,
                pageSize: data?.pageSize,
                position: ['bottomCenter'],
            }}
        />
    );
};
export default IrelandProductsTable;
