import { NctsDeclarationNotification } from 'store/declarations/ireland/ncts-declaration';

const RawData = (props: { notification: NctsDeclarationNotification['notification']; rawMessage?: string }) => {
    return (
        <div>
            <span>Notification: {props.notification?.messageType}</span>
            <pre>{props.rawMessage || JSON.stringify(props.notification, null, 2)}</pre>
        </div>
    );
};

export default RawData;
