export const aesDeclarationStatuses = {
    SUBMITTED: 'SUBMITTED',
    INVALID: 'INVALID',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    REGISTERED: 'REGISTERED',
    UNDER_CONTROL: 'UNDER_CONTROL',
    DRAFT: 'DRAFT',
    GOODS_RELEASED_FOR_EXPORT: 'GOODS_RELEASED_FOR_EXPORT',
    DIVERSION_REJECTED: 'DIVERSION_REJECTED',
    REGISTERED_AND_WAITING_FOR_PRESENTATION_OF_GOODS: 'REGISTERED_AND_WAITING_FOR_PRESENTATION_OF_GOODS',
    GOODS_RELEASED_FOR_IMMEDIATE_LEAVE: 'GOODS_RELEASED_FOR_IMMEDIATE_LEAVE',
    EXPORTED: 'EXPORTED',
    EXPORTED_STOPPED_AT_EXIT: 'EXPORTED_STOPPED_AT_EXIT',
    CANCELLED: 'CANCELLED',
    INVALIDATED: 'INVALIDATED',
    GOODS_NOT_ALLOWED_TO_EXIT: 'GOODS_NOT_ALLOWED_TO_EXIT',
    NOT_RELEASED_FOR_EXPORT: 'NOT_RELEASED_FOR_EXPORT',
    GOODS_READY_TO_BE_RELEASED: 'GOODS_READY_TO_BE_RELEASED',
    EXITED: 'EXITED',
    EXPORT_STOPPED_DISCREPANCIES_AT_EXIT: 'EXPORT_STOPPED_DISCREPANCIES_AT_EXIT',
    GOODS_PRESENTED_AT_EXIT: 'GOODS_PRESENTED_AT_EXIT',
} as const;

export const aisDeclarationStatuses = {
    DRAFT: 'DRAFT',
    INVALID: 'INVALID',
    SUBMITTED: 'SUBMITTED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    REGISTERED: 'REGISTERED',
    NOT_RELEASED: 'NOT_RELEASED',
    UNDER_PAYMENT: 'UNDER_PAYMENT',
    UNDER_CONTROL: 'UNDER_CONTROL',
    AWAITING_RISK: 'AWAITING_RISK',
    AWAITING_SUPPLEMENTARY: 'AWAITING_SUPPLEMENTARY',
    AWAITING_TARIFF_CALCULATION: 'AWAITING_TARIFF_CALCULATION',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    RELEASED: 'RELEASED',
} as const;

export const cdsDeclarationStatuses = {
    DRAFT: 'DRAFT',
    INVALID: 'INVALID',
    DECLARATION_VALIDATION: 'DECLARATION_VALIDATION',
    GOODS_RELEASED: 'GOODS_RELEASED',
    DECLARATION_CLEARED: 'DECLARATION_CLEARED',
    DECLARATION_INVALIDATION: 'DECLARATION_INVALIDATION',
    DECLARATION_REJECTED: 'DECLARATION_REJECTED',
    RE_ARRIVED_DECLARATION_IS_SEIZED: 'RE_ARRIVED_DECLARATION_IS_SEIZED',
    ADVANCE_DECLARATION_REGISTRATION: 'ADVANCE_DECLARATION_REGISTRATION',
    DECLARATION_ACCEPTANCE: 'DECLARATION_ACCEPTANCE',
    DECLARATION_ACCEPTED_AT_GOODS_ARRIVAL: 'DECLARATION_ACCEPTED_AT_GOODS_ARRIVAL',
    DECLARATION_REJECTED_AT_ARRIVAL: 'DECLARATION_REJECTED_AT_ARRIVAL',
    DECLARATION_CORRECTED: 'DECLARATION_CORRECTED',
    DECLARATION_SUPPLEMENTED: 'DECLARATION_SUPPLEMENTED',
    DECLARATION_RISKED: 'DECLARATION_RISKED',
    CUSTOMS_POSITION_DETERMINED: 'CUSTOMS_POSITION_DETERMINED',
    DECLARATION_CLEARED_AFTER_GOODS_RELEASE: 'DECLARATION_CLEARED_AFTER_GOODS_RELEASE',
    DECLARATION_RECEIVED: 'DECLARATION_RECEIVED',
    GOODS_EXIT_RESULTS_RECEIVED: 'GOODS_EXIT_RESULTS_RECEIVED',
    COMPLETED_SUCCESSFULLY: 'COMPLETED_SUCCESSFULLY',
    MANUAL_TASK_RAISED: 'MANUAL_TASK_RAISED',
    DEPARTED: 'DEPARTED',
    FRUSTRATED: 'FRUSTRATED',
    DECLARATION_UNDER_RE_ARRIVALS_RISK: 'DECLARATION_UNDER_RE_ARRIVALS_RISK',
    MUCR_SOE_0: 'MUCR_SOE_0',
} as const;

export const gvmsRecordStatuses = {
    EMBARKED: 'EMBARKED',
    CHECKED_IN: 'CHECKED_IN',
    OPEN: 'OPEN',
    FINALISED: 'FINALISED',
    DRAFT: 'DRAFT',
    NOT_FINALISABLE: 'NOT_FINALISABLE',
    INVALID: 'INVALID',
} as const;

export const nctsDeclarationStatuses = {
    SUBMITTED: 'SUBMITTED',
    INVALID: 'INVALID',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    REGISTERED: 'REGISTERED',
    DRAFT: 'DRAFT',
    MOVEMENT_RELEASED: 'MOVEMENT_RELEASED',
    AAR_CREATED: 'AAR_CREATED',
    ARRIVAL_ACCEPTED: 'ARRIVAL_ACCEPTED',
    UNLOADING: 'UNLOADING',
    UNLOADING_REMARKS: 'UNLOADING_REMARKS',
    GUARANTEE_UNDER_AMENDMENT: 'GUARANTEE_UNDER_AMENDMENT',
    GUARANTEE_UNDER_REGISTRATION: 'GUARANTEE_UNDER_REGISTRATION',
    IDLE: 'IDLE',
    NOT_RELEASED_FOR_TRANSIT: 'NOT_RELEASED_FOR_TRANSIT',
    UNDER_AMENDMENT_REQUEST_DUE_TO_EFBT: 'UNDER_AMENDMENT_REQUEST_DUE_TO_EFBT',
    INCIDENT_REGISTERED: 'INCIDENT_REGISTERED',
    MOVEMENT_WRITTEN_OFF: 'MOVEMENT_WRITTEN_OFF',
    UNDER_RECOVERY_PROCEDURE: 'UNDER_RECOVERY_PROCEDURE',
    PRE_LODGED: 'PRE_LODGED',
    MOVEMENT_UNDER_RESOLUTION: 'MOVEMENT_UNDER_RESOLUTION',
    UNDER_RELEASE_REQUEST: 'UNDER_RELEASE_REQUEST',
    AWAITING_TARIFF_CALCULATION: 'AWAITING_TARIFF_CALCULATION',
    AWAITING_RISK: 'AWAITING_RISK',
    UNDER_PAYMENT: 'UNDER_PAYMENT',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    UNDER_CONTROL: 'UNDER_CONTROL',
    UNDER_REVIEW: 'UNDER_REVIEW',
    AWAITING_SUPPLEMENTARY: 'AWAITING_SUPPLEMENTARY',
    DIVERSION_REJECTED: 'DIVERSION_REJECTED',
} as const;

export const baseDeclarationStatuses = {
    DRAFT: 'DRAFT',
    SUBMITTED: 'SUBMITTED',
    INVALID: 'INVALID',
    REJECTED: 'REJECTED',
    AWAITING_TARIFF_CALCULATION: 'AWAITING_TARIFF_CALCULATION',
    ACCEPTED: 'ACCEPTED',
    AWAITING_RISK: 'AWAITING_RISK',
    UNDER_PAYMENT: 'UNDER_PAYMENT',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    UNDER_CONTROL: 'UNDER_CONTROL',
    RELEASED: 'RELEASED',
    REGISTERED: 'REGISTERED',
    NOT_RELEASED: 'NOT_RELEASED',
    UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
    INVALIDATE: 'INVALIDATE',
    RISKED: 'RISKED',
} as const;

export const DeclarationStatus = {
    ...baseDeclarationStatuses,
    ...aesDeclarationStatuses,
    ...aisDeclarationStatuses,
    ...cdsDeclarationStatuses,
    ...gvmsRecordStatuses,
    ...nctsDeclarationStatuses,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DeclarationStatus = typeof DeclarationStatus[keyof typeof DeclarationStatus];
