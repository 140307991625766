import { Col, Divider, Row, Radio, RadioChangeEvent } from 'antd';
import Alert from 'components/ui/base/alert/Alert';
import Button from 'components/ui/base/button/Button';
import Drawer from 'components/ui/base/drawer/Drawer';
import Modal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import Searchbar from 'components/ui/base/searchbar';
import { H4, H5 } from 'components/ui/base/typography';
import useProductsTemplates from 'hooks/useProductsTemplates';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import ProductTemplatesTable, { Product } from './components/ProductTemplatesTable';
import CustomModal from 'components/ui/base/modal/Modal';
import BulkUploadDrawer from './bulk-upload/BulkUploadDrawer';
import ProductBulkUploadModal from './bulk-upload/ProductBulkUploadModal';
import useRequest from 'hooks/useRequest';
import { createBulkProducts, createUkBulkProducts } from 'store/products-templates/client';
import { DeclarationInternalType } from '../../../store/declarations/enums/common/declaration-internal-type';
import { useRequestPromise } from 'hooks/useRequest';
import TemplatesTable from 'views/templates/TemplatesTable';
import styled from 'styled-components';
import { getTemplates } from 'store/template/client';
import {
    AISMessageTypes,
    ENSMessageTypes,
    MessageTypes,
} from '../../../store/declarations/enums/common/declaration-types';
import useBreadcrumb from '../../../hooks/useBreadcrumb';
import { capitalize } from 'lodash';

const TableWrapper = styled.div`
    height: calc(100% - (100vh - 100%));

    .ant-table-wrapper {
        height: 100%;
        overflow: auto;
    }
`;

const templateFormValues = {
    ireland: {
        IMPORT: {
            H1: '',
            H7: '',
            I1: '',
        },
        EXPORT: {
            B1: '',
            B2: '',
            B3: '',
            B4: '',
            C1: '',
            C2: '',
        },
        ENS: {
            ENS: '',
        },
        NCTS: {
            D1: '',
            D2: '',
            D3: '',
            D4: '',
        },
    },
    uk: {
        IMPORT: { H1: '', H2: '' },
        EXPORT: { B1: '' },
        NCTS: {
            D1: '',
            D2: '',
            D3: '',
            D4: '',
        },
    },
} as Record<DeclarationCountry, Record<DeclarationInternalType, Record<MessageTypes, ''>>>;

export interface DeleteProductTemplates {
    modalVisible: boolean;
    productTemplateIds: string[];
}

const ProductTemplatesDashboard: FC<{}> = () => {
    const { country, internalType, type } = useParams<{
        country: DeclarationCountry;
        internalType: DeclarationInternalType;
        type: MessageTypes;
    }>();
    const { t } = useTranslation('customs_declarations');
    const { productsTemplates, listProductTemplates, deleteProductTemplate } = useProductsTemplates({});
    const navigate = useNavigate();
    const { setBreadcrumbRoutes } = useBreadcrumb();

    const [deleteProductTemplates, setDeleteProductTemplates] = useReducer(
        (initialState: DeleteProductTemplates, newState: Partial<DeleteProductTemplates>) => ({
            ...initialState,
            ...newState,
        }),
        { modalVisible: false, productTemplateIds: [] }
    );
    const closeDeleteProductTemplatesModal = useCallback(
        () => setDeleteProductTemplates({ modalVisible: false, productTemplateIds: [] }),
        []
    );

    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [showProductBulkUpload, setShowProductBulkUpload] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const bulkRequest = country === DeclarationCountry.UK ? createUkBulkProducts : createBulkProducts;
    const { doRequest: createBulk } = useRequest(bulkRequest);
    const [messages, setMessages] = useState<{ message: string; type: 'success' | 'error' }[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined);
    const handleTemplateSelect = (templateId: string | undefined) => {
        setSelectedTemplateId(templateId);
    };

    const { data: declarationTemplates, refetch: listDeclarationTemplates } = useRequestPromise(async () => {
        if (!type) throw new Error('No declaration type provided');
        return getTemplates(
            country ?? 'ireland',
            (internalType?.toLowerCase() as 'import' | 'export' | undefined) ?? 'import',
            type,
            { size: 9999 }
        )?.then((payload) => payload.list);
    });

    useEffect(() => {
        listDeclarationTemplates();
        listProductTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, internalType, type]);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Customs Declarations',
                path: '',
            },
            {
                breadcrumbName: t(`custom${country}`),
                path: '',
            },
            {
                breadcrumbName: 'Product Templates',
                path: '',
            },
            {
                breadcrumbName: `${
                    type === ENSMessageTypes.ENS ? internalType : `${capitalize(internalType)} ${type}`
                }`,
                path: '',
            },
        ]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, type]);

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listProductTemplates(params);
        } else {
            await listProductTemplates();
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    const handleDeleteProductTemplates = () =>
        Promise.all(deleteProductTemplates.productTemplateIds.map(deleteProductTemplate))
            .then(() => {
                Notification({
                    type: 'success',
                    ...(deleteProductTemplates.productTemplateIds.length > 1
                        ? {
                              messageTitle: t('product-template.multiple-delete-success-title'),
                              description: t('product-template.multiple-delete-success-message'),
                          }
                        : {
                              messageTitle: t('product-template.delete-success-title'),
                              description: t('product-template.delete-success-message'),
                          }),
                });
                listProductTemplates();
            })
            .catch(() =>
                Notification({
                    type: 'error',
                    ...(deleteProductTemplates.productTemplateIds.length > 1
                        ? {
                              messageTitle: t('product.delete-error-title'),
                              description: t('product-template.multiple-delete-error-message'),
                          }
                        : {
                              messageTitle: t('product.delete-error-title'),
                              description: t('product-template.delete-error-message'),
                          }),
                })
            )
            .finally(closeDeleteProductTemplatesModal);

    const handleCreateBulk = async () => {
        const productsWithTags = products.map((p) => ({ ...p, tags: selectedTags }));
        const createBulkData = await createBulk(productsWithTags);

        if (createBulkData !== null) {
            listProductTemplates();
            setShowProductBulkUpload(false);
            showMessage(t('bulk-upload.success-message'), 'success');
        } else {
            showMessage(t('bulk-upload.error-message'), 'error');
        }
    };

    const handleUploadedProducts = (products: Product[]) => {
        setProducts(products);
        setShowBulkUpload(false);
        setShowProductBulkUpload(true);
    };

    const showAlert = useMemo(() => {
        return messages?.map(
            (message, i) =>
                message?.type &&
                message?.message && (
                    <Alert
                        key={i}
                        type={message.type}
                        message={message.message}
                        showIcon
                        style={{ marginBottom: '2.4rem' }}
                        closable
                    />
                )
        );
    }, [messages]);

    const showMessage = (message: string, type: 'success' | 'error') => {
        setMessages((prev) => [...prev, { message, type }]);
    };

    const [declarationTemplateDrawerShown, setDeclarationTemplateDrawerShown] = useState(false);
    const openDeclarationTemplateDrawer = () => {
        setDeclarationTemplateDrawerShown(true);
    };
    const closeDeclarationTemplateDrawer = () => {
        setDeclarationTemplateDrawerShown(false);
        setSelectedTemplateId(undefined);
    };

    const redirectToAddProductView = useCallback(() => {
        let url = `/customs-declarations/${country}/${internalType}/products/${type}/new`;
        if (selectedTemplateId) url = url.concat(`?declarationTemplateId=${selectedTemplateId}`);
        navigate(url);
    }, [country, internalType, navigate, selectedTemplateId, type]);

    const productsButtons = useMemo(() => {
        return (
            <div>
                <Button
                    size="large"
                    type="primary"
                    style={{ marginRight: '1.6rem' }}
                    onClick={() => {
                        openDeclarationTemplateDrawer();
                    }}
                >
                    {t(`addNewProduct`, { type })}
                </Button>
                {type === AISMessageTypes.H1 && country === DeclarationCountry.IRELAND && (
                    <Button size="large" type="primary" onClick={() => setShowBulkUpload(true)}>
                        {t('bulk-upload.add-bulk-h1-upload')}
                    </Button>
                )}
            </div>
        );
    }, [t, type, country]);

    const handleInternalTypeChange = ({ target: { value } }: RadioChangeEvent) => {
        if (!country) return;
        const type = Object.keys(templateFormValues[country][value as DeclarationInternalType]).at(0);
        navigate(`/customs-declarations/${country}/${value}/products/${type}`);
    };
    const handleFormTypeChange = ({ target: { value } }: RadioChangeEvent) => {
        navigate(`/customs-declarations/${country}/${internalType}/products/${value}`);
    };

    const internalTypeOptions = useMemo(() => {
        if (!country) return undefined;
        const keys = templateFormValues[country];
        if (!keys) return undefined;

        return Object.keys(keys)?.map((ft) => ({
            value: ft,
            label: ft,
        }));
    }, [country]);

    const formTypeOptions = useMemo(() => {
        if (!internalType || !country) return undefined;
        const keys = templateFormValues[country][internalType];
        if (!keys) return undefined;

        return Object.keys(keys)?.map((ft) => ({
            value: ft,
            label: ft,
        }));
    }, [internalType, country]);

    return (
        <>
            <H5>
                {country === DeclarationCountry.IRELAND
                    ? `${t(`productsFromIrelandCustoms`)}`
                    : `${t(`productsFromUkCustoms`)}`}
            </H5>
            <div style={{ display: 'flex', gap: '15px', marginTop: '15px' }}>{productsButtons}</div>
            <Divider />

            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                <Radio.Group
                    options={internalTypeOptions}
                    value={internalType}
                    onChange={handleInternalTypeChange}
                    optionType="button"
                    buttonStyle="solid"
                />
                <Radio.Group
                    options={formTypeOptions}
                    value={type}
                    onChange={handleFormTypeChange}
                    optionType="button"
                    buttonStyle="solid"
                />
            </div>

            {showAlert}
            <Row gutter={16} wrap={false} style={{ marginTop: '3.2rem' }}>
                <Col flex="auto">
                    <Searchbar
                        inputPlaceholder="Search products"
                        onClear={() => listProductTemplates()}
                        onSearch={(value) => debouncedSearch(value)}
                    />
                </Col>
            </Row>

            <ProductTemplatesTable
                showCommands
                source={productsTemplates}
                declarationTemplatesList={declarationTemplates}
                deleteProductTemplatesIds={deleteProductTemplates.productTemplateIds}
                setDeleteProductTemplates={setDeleteProductTemplates}
            />
            <Modal
                title={
                    deleteProductTemplates.productTemplateIds.length > 1 ? (
                        <H5>{t('product-template.multiple-delete-title')}</H5>
                    ) : (
                        <H5>{t('product-template.delete-title')}</H5>
                    )
                }
                centered
                visible={deleteProductTemplates.modalVisible}
                onOk={handleDeleteProductTemplates}
                onCancel={closeDeleteProductTemplatesModal}
                width={762}
                contentText={
                    deleteProductTemplates.productTemplateIds.length > 1
                        ? t('product-template.multiple-delete-text')
                        : t('product-template.delete-text')
                }
            />
            <Drawer
                title={'Bulk Upload'}
                width="1003"
                visible={showBulkUpload}
                onClose={() => setShowBulkUpload(false)}
            >
                <BulkUploadDrawer setUploadedProducts={handleUploadedProducts} />
            </Drawer>
            <CustomModal
                title={<H4>Product Bulk Upload</H4>}
                visible={showProductBulkUpload}
                width={1143}
                testId="continue-modal"
                confirmText="Continue"
                cancelText="Cancel"
                onCancel={() => setShowProductBulkUpload(false)}
                onOk={() => handleCreateBulk()}
            >
                <ProductBulkUploadModal
                    products={products}
                    selectedTags={selectedTags}
                    onTagsSelected={setSelectedTags}
                />
            </CustomModal>
            {country && internalType && (
                <Drawer
                    title="Select declaration template"
                    visible={declarationTemplateDrawerShown}
                    onClose={closeDeclarationTemplateDrawer}
                    width="60rem"
                    destroyOnClose
                >
                    <Button
                        type="primary"
                        style={{ marginLeft: 'auto', display: 'block' }}
                        size="large"
                        onClick={() => {
                            redirectToAddProductView();
                            closeDeclarationTemplateDrawer();
                        }}
                    >
                        Create product template
                    </Button>
                    <TableWrapper>
                        <TemplatesTable
                            options={{ visible: { templateName: true } }}
                            withPagination={false}
                            viewOnly
                            country={country}
                            internalType={internalType?.toUpperCase() as DeclarationInternalType}
                            formType={type as MessageTypes}
                            onRowClick={handleTemplateSelect}
                            type="product-templates"
                        />
                    </TableWrapper>
                </Drawer>
            )}
        </>
    );
};

export default ProductTemplatesDashboard;
