import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthenticationContainer, ImageDivBackground } from './Auth.styles';
import useSession from 'hooks/useSession';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { notification } from 'antd';

const AuthenticationSignIn: FC = () => {
    const { oauth } = useSession();
    const navigate = useNavigate();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    useEffect(() => {
        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Logging in...' } });
        oauth()
            .then(() => {
                navigate('/home');
            })
            .catch(() => {
                navigate('/SignIn');
                notification.error({ message: 'There is no user with this account.' });
            })
            .finally(() => {
                hideGlobalOverlay();
            });
    }, []);

    return (
        <>
            <AuthenticationContainer>
                <ImageDivBackground pageName="signIn" />
                <Outlet />
            </AuthenticationContainer>
        </>
    );
};
export default AuthenticationSignIn;
