import { Declaration } from '../../../../../../store/declarations/declaration';
import { DeclarationStatus } from '../../../../../../store/declarations/enums/common/declaration-status';
import {
    isDeclarationRefundDepositRequested,
    isDeclarationRefundRemissionRequested,
} from '../../../../utils/declaration-utils';
import InformationAlert from '../../../InformationAlert';
import AlertInsufficientFunds from './AlertInsufficientFunds';
import AlertInvalidationRequested from './AlertInvalidationRequested';
import AlertPresentDocuments from './AlertPresentDocuments';
import AlertRefundDepositRequested from './AlertRefundDepositRequested';
import AlertRefundRemissionRequested from './AlertRefundRemissionRequested';
import AlertUploadDocuments from './AlertSubmitDocuments';
import ExternalSystemInvalidationRequested from './ExternalSystemInvalidationRequested';

interface Props {
    declaration: Declaration | undefined;
    hasRightCertificate: boolean | undefined;
}
const AlertManager = ({ declaration, hasRightCertificate }: Props) => {
    return (
        <>
            {isDeclarationRefundRemissionRequested(declaration) && <AlertRefundRemissionRequested />}
            {isDeclarationRefundDepositRequested(declaration) && <AlertRefundDepositRequested />}
            {declaration?.status === DeclarationStatus.INSUFFICIENT_FUNDS && <AlertInsufficientFunds />}
            {Boolean(declaration?.irelandImportDeclaration?.documentsUploadRequested) && <AlertUploadDocuments />}
            {Boolean(declaration?.irelandImportDeclaration?.documentsPresentationRequested) && (
                <AlertPresentDocuments />
            )}
            {Boolean(declaration?.irelandImportDeclaration?.userInvalidationSubmitted) && (
                <AlertInvalidationRequested />
            )}
            {Boolean(declaration?.irelandImportDeclaration?.revenueInvalidationRequested) && (
                <ExternalSystemInvalidationRequested />
            )}
            {hasRightCertificate === undefined ? false : !hasRightCertificate && <InformationAlert />}
        </>
    );
};

export default AlertManager;
