import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { ProductsActions } from './actionType';
import { ProductTemplate } from './products';

interface ProductTemplateEntities {
    [key: string]: ProductTemplate;
}

export interface ProductsTemplateState {
    isLoading: boolean;
    error?: ErrorResponse;
    products: ListPayload<ProductTemplate>;
    entities: ProductTemplateEntities;
    tags: string[];
}

export const productsTemplateInitialState: Readonly<ProductsTemplateState> = {
    isLoading: false,
    products: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
    tags: [],
};

const productsTemplate: Reducer<ProductsTemplateState, ProductsActions> = (
    state = productsTemplateInitialState,
    action
): ProductsTemplateState => {
    switch (action.type) {
        case 'LIST_PRODUCTS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'LIST_PRODUCTS_REQUEST_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                products: action.payload,
            };
        case 'LIST_PRODUCTS_REQUEST_ERROR':
            return {
                ...state,
                isLoading: false,
                products: {
                    list: [],
                    pageNumber: 0,
                    pageSize: 10,
                    total: 0,
                },
                error: action.error,
            };
        case 'SAVE_PRODUCT_TEMPLATE':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'SAVE_PRODUCT_TEMPLATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id!]: action.payload },
            };
        case 'SAVE_PRODUCT_TEMPLATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'UPDATE_PRODUCT_TEMPLATE':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'UPDATE_PRODUCT_TEMPLATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id!]: action.payload },
            };
        case 'UPDATE_PRODUCT_TEMPLATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_PRODUCT_TEMPLATE':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_PRODUCT_TEMPLATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id!]: action.payload },
            };
        case 'GET_PRODUCT_TEMPLATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'LIST_PRODUCT_TEMPLATE_TAGS':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'LIST_PRODUCT_TEMPLATE_TAGS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                tags: [...action.payload],
            };
        case 'LIST_PRODUCT_TEMPLATE_TAGS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_PRODUCT_TEMPLATE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'DELETE_PRODUCT_TEMPLATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
            };
        case 'DELETE_PRODUCT_TEMPLATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'LIST_UK_PRODUCTS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_UK_PRODUCTS_REQUEST_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                products: action.payload,
            };
        case 'LIST_UK_PRODUCTS_REQUEST_ERROR':
            return {
                ...state,
                isLoading: false,
                products: {
                    list: [],
                    pageNumber: 0,
                    pageSize: 10,
                    total: 0,
                },
                error: action.error,
            };
        case 'UPDATE_UK_PRODUCT_TEMPLATE':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'UPDATE_UK_PRODUCT_TEMPLATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id!]: action.payload },
            };
        case 'UPDATE_UK_PRODUCT_TEMPLATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_UK_PRODUCT_TEMPLATE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DELETE_UK_PRODUCT_TEMPLATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
            };
        case 'DELETE_UK_PRODUCT_TEMPLATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default productsTemplate;
