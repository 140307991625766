export const white = '#FFFFFF';
export const black = '#3D3D3D';
export const darkBlack = '#161616';
export const darkBlack2 = '#333333';
export const yellow = '#FFDF40';
export const lightYellow = '#FFF1E6';
export const red = '#FF0000';
export const lightRed = '#FF6961';
export const green = '#34DDBF';
export const lightGreen = '#3DBA02';
export const lightGreen1 = '#E8F6E3';
export const lightGreen2 = '#B3E1A3';
export const lightGreen3 = '#C9EABD';
export const polarGreen = '#52C41A';
export const lightGreenA70 = 'rgba(61, 186, 2, 0.7)';
export const lightBlue = '#10C6F7';
export const lightBlue1 = '#E6ECFD';
export const lightBlue2 = '#F0F4FF';
export const blueLink = '#1890FF';
export const grey = '#C4C4C4';
export const darkGrey = '#969696';
export const lightGrey = '#F6F6F6';
export const lightGrey2 = '#EFEFEF';
export const lightGrey3 = '#828282';
export const lightGrey4 = '#d3d3d3';
export const lightGrey5 = '#DCDCDC';
export const darkGrey2 = '#CECECE';
export const purpleBlue = '#0040E4';
export const purpleBlue1 = '#82A5FF';
export const darkPurple = '#383752';
export const lightPurple = '#f0f5ff';
export const lightPurple1 = '#edf2ff';
export const purpleBlueA15 = 'rgba(9, 0, 255, 0.15)';
export const orange = '#F29100';
export const orange1 = '#FFA500';
export const darkOrange = '#FFB122';
export const lightOrange = '#FFC966';
export const lightOrange1 = '#FFDB99';
export const lightOrange2 = '#FFF6E6';
export const pink = '#FF9C97';
export const lightPink = '#FFCAC8';
export const darkGreen = '#74C857';
export const darkGreen1 = '#3DBA02';

//tags
export const magnolia = '#eae4e9';
export const linen = '#fff1e6';
export const palePink = '#fde2e4';
export const mimiPink = '#fad2e1';
export const mintCream = '#e2ece9';
export const powderBlue = '#bee1e6';
export const isabelLine = '#f0efeb';
export const lavenderWeb = '#dfe7fd';
export const periwinkleCrayola = '#cddafd';

//feedback
export const success = '#219653';
export const error = '#EB5757';
export const warning = '#F2C94C';
export const info = '#2F80ED';
export const invalid = '#FDEFEF';
export const action = '#ffb426';
