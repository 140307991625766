import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationsActions } from './actionTypes';

export interface DeclarationProductState {
    isLoading: boolean;
    error?: ErrorResponse;
    declaration?: Declaration;
}

export const initialDeclarationProductState: DeclarationProductState = {
    isLoading: false,
};

const declarationProduct: Reducer<DeclarationProductState, DeclarationsActions> = (
    state = initialDeclarationProductState,
    action
): DeclarationProductState => {
    switch (action.type) {
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.payload,
            };
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H7_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H7_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H7_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_EXPORT_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_EXPORT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_EXPORT_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default: {
            return state;
        }
    }
};

export default declarationProduct;
