import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { message } from 'antd';
import { confirmUploadFile } from 'store/documents/client';
import { DeclarationCountry } from '../../../../../store/declarations/enums/common/declaration-country';
import { createDocumentUk } from '../../../../../store/file-upload/client';
import { DeclarationDocuments as CmsFile } from '../../../../../store/declarations/declaration';
import { DeclarationDocument } from '../types';
import axios from 'axios';

interface DataForRequests {
    uploadedFileData: UploadFile<any>;
    customerId: string;
    declarationId: string;
}

export const uploadFile = (file: File | undefined | null, url: string | undefined | null) => {
    if (!file) return Promise.reject();

    let data: File | FormData = file;
    let contentType = file.type;
    if (!contentType) {
        contentType = 'multipart/form-data';
        data = new FormData();
        data.append('file', file);
    }

    if (!url) return Promise.reject();
    return axios.put(url, data, {
        headers: { 'Content-Type': contentType },
    });
};

export const beforeUpload = async (
    file: RcFile,
    customerId: string,
    declarationId: string,
    preUpload: Function,
    isAes: boolean
) => {
    const defaultFileTypes = { PDF: 'application/pdf', PNG: 'image/png', JPEG: 'image/jpeg' };
    let allowedFileTypes: Record<string, string> = { ...defaultFileTypes, TXT: 'text/plain', JPG: 'image/jpg' };

    if (isAes) {
        allowedFileTypes = {
            ...defaultFileTypes,
            GIF: 'image/gif',
            ZIP: 'application/x-zip-compressed',
            DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        };

        if (file.size > 10000000) {
            message.error(`File ${file.name} couldn't be uploaded because it surpasses the file size limit: 10MB.`);
            return false;
        }
    }

    const { dontComply } = filesComplyWithTypes([file], Object.values(allowedFileTypes));

    if (dontComply.length) {
        message.error(
            `File ${dontComply.map(({ name }) => name).join(', ')} couldn't be uploaded because it do not
                    comply with the allowed field types: ${Object.keys(allowedFileTypes).join(', ')}`
        );
        return false;
    }
    await preUpload({
        fileSize: file.size,
        customerId,
        declarationId,
        fileName: file.name,
    });
    message.loading('File is uploading.', 1);
    return false;
};

export const handleOnChange = async (
    dataForRequests: DataForRequests,
    listCmsFiles: Function,
    setCmsFileAndFormAction: Function,
    country: DeclarationCountry,
    addDeclarationDocument: (newDoc: DeclarationDocument) => void
) => {
    const {
        status: uploadedFileStatus,
        name: uploadedFileName,
        size: uploadedFileSize,
    } = dataForRequests.uploadedFileData;

    switch (uploadedFileStatus) {
        case 'uploading':
            break;
        case 'done':
            const cmsFileUploadResponse = (await confirmUploadFile({
                fileName: uploadedFileName,
                fileSize: uploadedFileSize,
                customerId: dataForRequests.customerId,
                declarationId: dataForRequests.declarationId,
            })) as unknown as { payload: CmsFile };

            if (country === DeclarationCountry.IRELAND) {
                setCmsFileAndFormAction?.({ file: cmsFileUploadResponse.payload, action: 'upload' });
            } else if (country === DeclarationCountry.UK) {
                addDeclarationDocument(
                    await createDocumentUk(
                        {
                            fileId: cmsFileUploadResponse.payload.id,
                            fileReference: cmsFileUploadResponse.payload.link,
                            fileName: cmsFileUploadResponse.payload.filename,
                        },
                        dataForRequests.declarationId
                    )
                );
            }

            listCmsFiles?.();
            message.success(`${uploadedFileName} file uploaded successfully.`);
            break;
        case 'error':
            message.error(`${uploadedFileName} file upload failed.`);
            break;
    }
};

const filesComplyWithTypes = (files: UploadFile<any>[] | RcFile[], types: string[]) => {
    const comply = [];
    const dontComply = [];

    for (const file of files) {
        if (!file.type) {
            dontComply.push(file);
        } else if (types.includes(file.type)) {
            comply.push(file);
        } else {
            dontComply.push(file);
        }
    }

    return { comply, dontComply };
};
