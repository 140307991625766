import { Modal, ModalProps } from 'antd';
import { useParams } from 'react-router-dom';
import { H4Style } from '../../../components/ui/base/typography/Typography.styles';
import { Declaration } from '../../../store/declarations/declaration';
import { DeclarationInternalType } from '../../../store/declarations/enums/common/declaration-internal-type';
import PbnTicket from '../../declarations/ireland/pbn/components/PbnTicket';
import UkGvmsTicket from '../../declarations/uk/gvms/components/barcode/UkGvmsTicket';

const BarcodeModal = ({ declaration, ...modalProps }: ModalProps & { declaration: Declaration | undefined }) => {
    const { type } = useParams();
    return (
        <Modal centered width={900} footer={null} {...modalProps}>
            <H4Style style={{ marginBottom: '1.5rem' }}>
                {type === DeclarationInternalType.GVMS
                    ? 'Goods Movement Reference Ticket'
                    : 'Pre-Boarding Notification Ticket'}
            </H4Style>
            {type === DeclarationInternalType.GVMS ? (
                <UkGvmsTicket
                    gmrExpiredAt={declaration?.gvmsDeclaration?.gmrExpiredAt}
                    gmrId={declaration?.gvmsDeclaration?.gmrId}
                    gmrIdBarcode={declaration?.gvmsDeclaration?.gmrIdBarcode}
                />
            ) : (
                <PbnTicket
                    pbnId={declaration?.preBoardingNotification?.pbnId}
                    pbnBarcode={declaration?.preBoardingNotification?.barCode.split('data:image/png;base64, ')[1]}
                    pbnStatus={declaration?.preBoardingNotification?.status}
                />
            )}
        </Modal>
    );
};

export default BarcodeModal;
