import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, getFullCodelistValue, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { getEoriValidation, getMaxValidation } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';
import SearchCustomer from '../../../../../common/search-customer/SearchCustomer';
import useSearchCustomer from '../../../../../common/search-customer/useSearchCustomer';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../../common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';
import useCodelists from '../../../../../../../hooks/useCodelists';
import { validators } from 'views/declarations/uk/export/validations/validations';

const FiscalReferenceSchema = Yup.object().shape({
    additionalFiscalReferenceVatIdentificationNumber: getEoriValidation('referenceId'),
    additionalFiscalReferenceRole: getMaxValidation('referenceVat', 3).nullable(),
});

const FiscalReferenceCard: FC<
    DeclarationFormCardProps & {
        isRequired?: boolean;
    }
> = ({
    propsPathPrefix,
    getFieldProps: declarationGetFieldProps,
    getFieldHelpers: declarationGetFieldHelpers,
    viewOnly,
    defaultOpen,
    expandAll,
    keyCard,
    toggleHelp,
    isRequired,
}) => {
    const { getH7TooltipsByRefNumberAndField } = useTooltips();
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();
    const getTooltip = useCallback(
        (sadBox: string, label: string): string | undefined => getH7TooltipsByRefNumberAndField(sadBox, label),
        [getH7TooltipsByRefNumberAndField]
    );

    return (
        <MultipleItemsCard
            defaultOpen={defaultOpen}
            viewOnly={viewOnly}
            title={t('additionalFiscalReference.title')}
            keyCard={keyCard}
            initialValues={{ additionalFiscalReferenceRole: '', additionalFiscalReferenceVatIdentificationNumber: '' }}
            list={(values) => [
                {
                    field: t('referenceVat'),
                    value: getFullCodelistValue(
                        values?.additionalFiscalReferenceRole,
                        aisCodelists?.additionalFiscalRefRoleCode
                    ),
                },
                { field: t('referenceId'), value: values.additionalFiscalReferenceVatIdentificationNumber },
            ]}
            path={propsPathPrefix ?? ''}
            getFieldProps={declarationGetFieldProps}
            getFieldHelpers={declarationGetFieldHelpers}
            expandAll={expandAll}
            condensed
            validationSchema={FiscalReferenceSchema}
            form={(props, path) => (
                <FormCardContainer>
                    <FormSelect
                        required={isRequired}
                        {...getFormikProps(addPathPrefix(path, 'additionalFiscalReferenceRole'), props)}
                        refNumber="3.40"
                        label={t('referenceVat')}
                        tooltip={getTooltip('3.40', t('referenceVat'))}
                        refClicked={(ref) => handleToggleHelp(ref, { toggleHelp })}
                        selectOptions={aisCodelists?.additionalFiscalRefRoleCode}
                        condensed
                        codeValidation={[validators.exact(3)]}
                    />
                    <FormInput
                        required={isRequired}
                        maxLength={17}
                        {...getFormikProps(
                            addPathPrefix(path, 'additionalFiscalReferenceVatIdentificationNumber'),
                            props
                        )}
                        refNumber="3.40"
                        tooltip={getTooltip('3.40', t('referenceId'))}
                        refClicked={(ref) => handleToggleHelp(ref, { toggleHelp })}
                        label={t('referenceId')}
                        onSearchCustomer={({ fieldHelpers }) => {
                            triggerShowSearchCustomerDrawer((customer) => {
                                fieldHelpers?.setValue(customer.eori);
                            });
                        }}
                        condensed
                    />
                    <SearchCustomer.Drawer
                        onClose={triggerHideSearchCustomerDrawer}
                        show={showSearchCustomerDrawer}
                        onSelectCustomer={handleSelectCustomer}
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default FiscalReferenceCard;
