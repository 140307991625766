import useProducts from 'hooks/useProducts';
import useModal from 'antd/lib/modal/useModal';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { useParams } from 'react-router-dom';
import { JSXElementConstructor, ReactElement } from 'react';
import useDeclarations from '../../../../../hooks/useDeclarations';
import useFormUtils from '../../../../../hooks/useFormUtils';

interface Props {
    createProduct: (product: any, declarationId: string) => Promise<any>;
    listProducts: (declarationId: string) => Promise<any>;
    saveAsDraft?: (withNotification: boolean, data?: any) => Promise<any>;
    initialData?: Object;
}

type ReturnType = [(numberOfItems: number) => void, ReactElement<any, string | JSXElementConstructor<any>>];

const useNumberOfItemsModal = ({ createProduct, listProducts, saveAsDraft, initialData }: Props): ReturnType => {
    const { declarationId } = useParams();
    const { products } = useProducts();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const [modal, modalContextHolder] = useModal();
    const { getDeclaration } = useDeclarations();
    const { isAis, isNcts, isUkNcts } = useFormUtils();

    const handleNumberOfItemsBlur = (value: number | null) => {
        if (!declarationId) return;
        const createdProducts = products?.total || 0;

        if (value && value > createdProducts) {
            const productsToCreate = value - createdProducts;

            modal.confirm({
                content: <>Do you want to create {productsToCreate} product(s)?</>,
                okText: 'Yes',
                onOk: () => createProducts(productsToCreate, value),
                cancelText: 'No',
            });
        }
    };

    const createProducts = async (productsToCreate: number, inputValue: number) => {
        if (!declarationId) throw new Error('CRITICAL: No declaration ID');
        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Creating products...' } });
        await Promise.all(
            Array(productsToCreate)
                .fill(undefined)
                .map(() => createProduct(initialData || {}, declarationId))
        );
        const saveAsDraftParams =
            isAis || isNcts || isUkNcts ? { numberOfItems: inputValue } : { goodsItemQuantity: inputValue };
        await saveAsDraft?.(false, saveAsDraftParams);
        await (!!saveAsDraft && getDeclaration(declarationId));
        await listProducts(declarationId);
        hideGlobalOverlay();
    };

    return [handleNumberOfItemsBlur, modalContextHolder];
};

export default useNumberOfItemsModal;
