import { sortBy } from 'lodash';
import { IrelandEnsNotification } from '../../../../store/declarations/ireland/entry-summary-notification';
import {
    AesInvalidNotification,
    AesNotification,
    AesRejectedNotification,
} from '../../../../store/declarations/ireland/export-declaration';
import { IrelandNotification } from '../../../../store/declarations/ireland/ireland-notification';
import { CdsExportNotification } from '../../../../store/declarations/uk/cds-declaration';
import { GmrNotification } from '../../../../store/declarations/uk/gvms-declaration';
import { DeclarationsPayloads } from '../../utils/declaration-utils';

const extractNotificationsData = (declarationPayload: DeclarationsPayloads | undefined | null) => {
    if (!declarationPayload) return {};
    if (!declarationPayload?.notifications) return {};

    const lastNotificationIndex = declarationPayload.notifications.length - 1;

    const sortedNotifications = declarationPayload.notifications.some(
        (declarationNotification) =>
            'notificationDate' in declarationNotification ||
            'createdAt' in declarationNotification ||
            'issueDateTime' in declarationNotification
    )
        ? sortBy(declarationPayload.notifications, ['notificationDate', 'createdAt', 'issueDateTime'])
        : declarationPayload.notifications;

    const latestNotification = sortedNotifications?.[lastNotificationIndex];

    const declarationNotificationErrors =
        (latestNotification as IrelandNotification | undefined)?.submissionErrors ?? // Ireland Import
        (latestNotification as IrelandEnsNotification | undefined)?.rejectedNotification?.errors ?? // ENS
        (latestNotification as CdsExportNotification | undefined)?.validationMessages ?? // CDS Export
        (((latestNotification as AesNotification)?.notification as AesRejectedNotification)?.functionalError ||
            ((latestNotification as AesNotification)?.notification as AesInvalidNotification)?.xmlError) ?? // Ireland Export
        (latestNotification as GmrNotification)?.message?.ruleFailures ?? // GMR
        (latestNotification?.notification?.XMLError || latestNotification?.notification?.FunctionalError); // Ireland NCTS;

    return { sortedNotifications, latestNotification, declarationNotificationErrors };
};

export default extractNotificationsData;
