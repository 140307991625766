import { SelectOption } from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { AESMessageTypes } from '../../../../../store/declarations/enums/common/declaration-types';

export const ieExportFormTypeMap = {
    [AESMessageTypes.B1]: {
        declarationTypeValue: 'EX',
        filterRequestedProcedureCodelist: (codelist: SelectOption[] | undefined) =>
            codelist?.filter(({ id }) => ['10', '11', '23', '31'].includes(id)),
    },
    [AESMessageTypes.B2]: {
        declarationTypeValue: 'EX',
        filterRequestedProcedureCodelist: (codelist: SelectOption[] | undefined) =>
            codelist?.filter(({ id }) => ['21', '22'].includes(id)),
    },
    [AESMessageTypes.B3]: {
        declarationTypeValue: 'CO',
        filterRequestedProcedureCodelist: (codelist: SelectOption[] | undefined) =>
            codelist?.filter(({ id }) => ['76', '77'].includes(id)),
    },
    [AESMessageTypes.B4]: { declarationTypeValue: 'CO', requestedProcedureValue: '10' },
    [AESMessageTypes.C1]: {
        filterAdditionalDeclarationTypeCodelist: (codelist: SelectOption[] | undefined) =>
            codelist?.filter(({ id }) => ['B', 'C', 'E', 'F'].includes(id)),
    },
    [AESMessageTypes.C2]: {},
} as Record<AESMessageTypes, Record<string, any>>;
