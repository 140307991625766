import { FormAction } from 'views/declarations/utils/form-utils';

export const doSetDeclarationValidation: Function =
    (declarationValidation: FormAction | null) => (dispatch: Function) => {
        dispatch({
            type: 'SET_DECLARATION_VALIDATION',
            value: declarationValidation,
        });
    };

export const doResetDeclarationValidation: Function = () => (dispatch: Function) => {
    dispatch({ type: 'RESET_DECLARATION_VALIDATION' });
};

export const doSetHasUnsavedChanges: Function = (value: boolean) => (dispatch: Function) => {
    dispatch({ type: 'SET_HAS_UNSAVED_CHANGES', value });
};
