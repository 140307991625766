import { CloseCircleOutlined } from '@ant-design/icons';
import './AccessDeniedMessage.styles.css';

const AccessDeniedMessage = () => {
    return (
        <div className="access-denied-message">
            <CloseCircleOutlined className="error-icon" />
            <div className="error-text">
                <h2>Access Denied</h2>
                <p>Sorry, you do not have permission to access this page.</p>
            </div>
        </div>
    );
};

export default AccessDeniedMessage;
