import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { TemplateResponse } from './template';
import { AESMessageTypes, MessageTypes, NCTSMessageTypes } from '../declarations/enums/common/declaration-types';

const isAes = (internalType: string, declarationType: string) =>
    internalType === 'export' && Object.values(AESMessageTypes).includes(declarationType as AESMessageTypes);

const isAisH7 = (internalType: string, declarationType: string) =>
    internalType === 'import' && declarationType === 'H7';

const isUkNcts = (internalType: string, declarationType: string) =>
    internalType === 'ncts' && Object.values(NCTSMessageTypes).includes(declarationType as NCTSMessageTypes);

const isAisI1 = (internalType: string, declarationType: string) =>
    internalType === 'import' && declarationType === 'I1';

export interface ArchivedTemplatesParams {
    declarationExternalType: string;
    declarationInternalType: string;
    declarationName?: string;
}

enum DeclarationInternalType {
    IMPORT = 'IMPORT',
    NCTS = 'NCTS',
}

enum DeclarationExternalEntity {
    REVENUE = 'REVENUE',
    HMRC = 'HMRC',
}

export const getTemplates = (
    country: 'ireland' | 'uk',
    internalType: 'import' | 'export' | 'ens' | 'ncts',
    declarationType: MessageTypes,
    params?: Partial<PaginatedParams>
): Promise<ListPayload<TemplateResponse>> | undefined => {
    let urlPath;

    if (country === 'ireland') {
        if (isAes(internalType, declarationType)) {
            urlPath = `/ie/${internalType}?${new URLSearchParams({
                declarationName: declarationType,
            })}`;
        } else if (internalType === 'import' || internalType === 'ens') {
            urlPath = `/${country}/${internalType}`;
            if (isAisH7(internalType, declarationType)) urlPath = urlPath.concat('/h7');
            else if (isAisI1(internalType, declarationType)) {
                const params = {
                    page: 0,
                    size: 10,
                    sortDirection: 'DESC',
                    sort: 'ID',
                    declarationInternalType: 'IMPORT',
                    declarationName: declarationType,
                };
                return axiosClient
                    .get<ListResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}`, { params })
                    .then((response) => response.data.payload);
            }
        } else if (internalType === 'ncts') {
            urlPath = `/ie/${internalType}?${new URLSearchParams({
                declarationName: declarationType,
            })}`;
        } else {
            return;
        }
    } else {
        if (isUkNcts(internalType, declarationType)) {
            const params = {
                page: 0,
                size: 10,
                sortDirection: 'DESC',
                sort: 'ID',
                declarationExternalType: 'HMRC',
                declarationInternalType: 'NCTS',
                declarationName: declarationType,
            };
            return axiosClient
                .get<ListResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}`, { params })
                .then((response) => response.data.payload);
        } else {
            urlPath = `/cds/${internalType}?${new URLSearchParams({
                declarationName: declarationType,
            })}`;
        }
    }

    return axiosClient
        .get<ListResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}${urlPath}`, { params })
        .then((response) => response.data.payload);
};

export const getArchivedTemplates = (params: ArchivedTemplatesParams): Promise<ListPayload<TemplateResponse>> =>
    axiosClient
        .get<ListResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}/archive`, { params })
        .then((response) => response.data.payload);

export const archiveTemplate = (declarationTemplateId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.declarationTemplatesUrl}/archive/${declarationTemplateId}`)
        .then((response) => response.data);
export const unarchiveTemplate = (declarationTemplateId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.declarationTemplatesUrl}/unarchive/${declarationTemplateId}`)
        .then((response) => response.data);

export const createTemplate = (
    country: 'ireland' | 'uk',
    internalType: 'import' | 'export' | 'ens' | 'ncts',
    declarationType: MessageTypes,
    template: Partial<TemplateResponse>
) => {
    let urlPath;
    if (country === 'ireland') {
        if (internalType === 'export' || internalType === 'ncts') {
            urlPath = `/ie/${internalType}`;
        } else {
            urlPath = `/${country}/${internalType}`;
            if (declarationType === 'H7') {
                urlPath = urlPath.concat('/h7');
            } else if (declarationType === 'I1') {
                const { declarationName, templateName, template: templateData } = template;
                return axiosClient.post(
                    `${config.declarationTemplatesUrl}?declarationInternalType=${DeclarationInternalType.IMPORT}&declarationExternalEntity=${DeclarationExternalEntity.REVENUE}`,
                    {
                        declarationName,
                        templateName,
                        template: templateData,
                    }
                );
            }
        }
    } else {
        if (isUkNcts(internalType, declarationType)) {
            const { declarationName, templateName, template: templateData } = template;
            return axiosClient.post(
                `${config.declarationTemplatesUrl}?declarationInternalType=${DeclarationInternalType.NCTS}&declarationExternalEntity=${DeclarationExternalEntity.HMRC}`,
                {
                    declarationName,
                    templateName,
                    template: templateData,
                }
            );
        } else urlPath = `/cds/${internalType}`;
    }

    const { declarationName, templateName, template: templateData } = template;
    return axiosClient.post(`${config.declarationTemplatesUrl}${urlPath}`, {
        declarationName,
        templateName,
        template: templateData,
    });
};
