import { Typography } from 'antd';
import { FC, useMemo } from 'react';
import { getFieldName } from '../../../../../utils/validationParserUtils';
import { DetailsLabel, DetailsLabelDiv, DetailsValue, StyledErrorRow } from '../DeclarationsNotificationsDrawer.styles';
import { ParsedCard, ParsedItem } from '../../../../../store/declaration-form-errors/ParsedForm';
import { FormikErrors } from 'formik';

const ListErrorsRows: FC<{
    section: string;
    fields: string[];
    handleOnClickField?: (value: string) => void;
    parseDeclaration: (validation: FormikErrors<any>) => {
        masterDetails: ParsedCard[];
        items: ParsedItem[];
    };
    itemsPath: string;
}> = ({ fields, handleOnClickField, section, parseDeclaration, itemsPath }) => {
    const constructedFields = useMemo(() => {
        return fields.map((field) => ({
            ui: getFieldName(parseDeclaration, field, itemsPath),
            path: field,
        }));
    }, [fields, parseDeclaration, itemsPath]);

    return (
        <>
            <StyledErrorRow>
                <DetailsLabelDiv>
                    <DetailsLabel>{section}</DetailsLabel>
                </DetailsLabelDiv>
                <DetailsLabelDiv style={{ flex: 1 }}>
                    {constructedFields.map(({ ui, path }) => {
                        return (
                            <Typography.Link key={ui ?? path} onClick={() => handleOnClickField?.(path)}>
                                <DetailsValue>{`${ui ?? path ?? '-'}`}</DetailsValue>
                            </Typography.Link>
                        );
                    })}
                </DetailsLabelDiv>
            </StyledErrorRow>
        </>
    );
};

export default ListErrorsRows;
