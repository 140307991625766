import { Declaration, Notifiable } from '../declaration';
import { DeclarationExternalEntity } from '../enums/common/declaration-external-entity';
import { DeclarationInternalType } from '../enums/common/declaration-internal-type';
import { TSDMessageTypes } from '../enums/common/declaration-types';

export const tsdNameLabels = [
    {
        key: TSDMessageTypes.G4,
        value: 'G4',
    },
    {
        key: TSDMessageTypes.G4G3,
        value: 'G4G3',
    },
] as const;

export interface IrelandTemporaryStorageDeclarationPayload extends Declaration {
    declarationExternalEntity: DeclarationExternalEntity.REVENUE;
    declarationInternalType: DeclarationInternalType.TEMPORARY;
    isCoreTemplate?: boolean;
    ieImportTemporaryStorageDeclaration?: IrelandTemporaryStorageDeclaration;
}

export interface IrelandTemporaryStorageDeclaration extends Notifiable<any> {
    declaration: {
        msgType: string;
        lrn25: string;
        customsOffices: {
            supervisingCustomsOffice527: string;
            customsOfficeLodgement: string;
        };
        parties: {
            declarant: {
                declarant318: string;
            };
            representative: {
                representative320: string;
                representativeStatus321: string;
            };
        };
    };
    goodsShipment: {
        documentsAuthorisations: {
            simplifiedDeclarationDocumentWritingOff201: {
                previousDocumentType: string;
                previousDocumentIdentifier: string;
                previousDocumentLineId: string;
            }[];
            additionalInformation22: {
                additionalInformationCode: string;
                additionalInformationText: string;
            }[];
            producedDocumentsWritingOff203: {
                documentType: string;
                documentIdentifier: string;
            }[];
            ucr24: string;
            warehouseIdentification27: {
                warehouseType: string;
                warehouseIdentifier: string;
            };
        };
        datesPlaces: {
            locationGoods523: {
                identificationOfLocation: string;
                qualifierIdentification: string;
                additionalIdentifier: string;
                locationTypeCode: string;
                address: {
                    city: string;
                    countryCode: string;
                    streetAndNumber: string;
                    postcode: string;
                };
            };
        };
        transportInformation: {
            arrivalTransportMeansId79: {
                identificationType: string;
                identificationNumber: string;
            };
            containerIdentificationNumber710: {
                containerIdentificationNumber: string;
            }[];
            seal: {
                sealNumber718: string;
                sealIdentifier718: {
                    sealIdentifier: string;
                }[];
            };
        };
        grossMass65: number;
        supplyChainActor337: {
            roleCode: string;
            traderIdentification: string;
        }[];
        presentation: {
            presentationTrader330: string;
            firstEntryCustomsOffice524: string;
            activeBorderTransportMeansId714: {
                identificationType: string;
                identificationNumber: string;
            };
        };
        goodsShipmentItem: {
            id: string;
            goodsItemNumber16: string;
            documentsAuthorisations: {
                simplifiedDeclarationDocumentWritingOff201: {
                    previousDocumentType: string;
                    previousDocumentIdentifier: string;
                    previousDocumentLineId: string;
                }[];
                additionalInformation22: {
                    additionalInformationCode: string;
                    additionalInformationText: string;
                }[];
                producedDocumentsWritingOff203: {
                    documentType: string;
                    documentIdentifier: string;
                }[];
                ucr24: string;
            };
            supplyChainActor337: {
                roleCode: string;
                traderIdentification: string;
            }[];
            goodsInformation: {
                goodsDescription68: string;
                grossMass65: number;
                packaging: {
                    typePackage69: string;
                    packageNumber610: string;
                    shippingMarks611: string;
                }[];
                cusCode613: string;
                commodityCode: {
                    combinedNomenclatureCode614: string;
                };
            };
            transportInformation: {
                containerIdentificationNumber710: {
                    containerIdentificationNumber: string;
                }[];
            };
        }[];
    };
}
