import { Divider, Radio, RadioChangeEvent } from 'antd';
import { TemplateContextProvider } from 'components/ui/composed/template/TemplateContext';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import Container from 'components/ui/base/container';
import TemplatesTable from './TemplatesTable';
import { SpaceBetween } from 'components/styles/layout.styles';
import { H5 } from 'components/ui/base/typography';
import Button from 'components/ui/base/button/Button';
import CreateTemplateDrawer from './components/CreateTemplateDrawer';
import TemplateModal from 'components/ui/composed/template/TemplateModal';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import StatusSegmented from '../declarations/common/dashboard/StatusSegmented';
import { useLocation } from 'react-router-dom';
import { AISMessageTypes, MessageTypes } from '../../store/declarations/enums/common/declaration-types';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { startCase, toLower } from 'lodash';

const templateFormValues = {
    IRELAND: {
        IMPORT: ['H1', 'H7', 'I1'],
        EXPORT: ['B1', 'B2', 'B3', 'B4', 'C1', 'C2'],
        ENS: [],
        NCTS: ['D1', 'D2', 'D3', 'D4'],
    },
    UK: {
        IMPORT: ['H1', 'H2'],
        EXPORT: ['B1'],
        NCTS: ['D1', 'D2', 'D3', 'D4'],
    },
} as Record<string, Record<string, string[]>>;

export type TemplateDashboardState = {
    country: DeclarationCountry;
    internalType: DeclarationInternalType;
    formType: MessageTypes;
};

const TemplateDashboard = (): ReactElement => {
    const location = useLocation();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const [visible, setVisible] = useState<boolean>(false);
    const [country, setCountry] = useState<DeclarationCountry>(DeclarationCountry.IRELAND);
    const [internalType, setInternalType] = useState<DeclarationInternalType>(DeclarationInternalType.IMPORT as any);
    const [formType, setFormType] = useState<MessageTypes>(AISMessageTypes.H1);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Templates',
                path: '',
            },
            {
                breadcrumbName: `${startCase(country)} Templates`,
                path: '',
            },
            {
                breadcrumbName: `${startCase(toLower(internalType))} ${formType || ''}`,
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, internalType, formType]);

    const atArchivedPath = useMemo(() => location.pathname.includes('archived'), [location.pathname]);

    const internalTypeOptions = useMemo(() => {
        if (!country) return undefined;

        return Object.keys(templateFormValues?.[country.toUpperCase()]).map((ft) => ({
            value: ft,
            label: ft,
        }));
    }, [country]);

    const formTypeOptions = useMemo(() => {
        if (!country || !internalType) return undefined;
        return templateFormValues?.[country.toUpperCase()]?.[internalType]?.map((ft) => ({
            value: ft,
            label: ft,
        }));
    }, [country, internalType]);

    const handleTypesOnCountryChange = (country: DeclarationCountry) => {
        const countryValues = templateFormValues?.[country.toUpperCase()];

        const internalTypeDefault = Object.keys(countryValues)[0];
        setInternalType(internalTypeDefault as DeclarationInternalType);

        const formType = countryValues?.[internalTypeDefault as any][0];
        setFormType(formType as MessageTypes);
    };

    const handleFormTypeOnInternalTypeChange = (internalType: DeclarationInternalType) => {
        const countryValues = templateFormValues?.[country.toUpperCase()];

        const internalTypeState = Object.keys(countryValues)?.find((i) => i === internalType);

        const formType = countryValues?.[internalTypeState as any][0];
        setFormType(formType as MessageTypes);
    };

    const handleOpen = () => {
        setVisible(true);
    };
    const handleClose = () => {
        setVisible(false);
    };

    const handleCountryChange = ({ target: { value } }: RadioChangeEvent) => {
        const parseValue = (value: any) => {
            switch (value) {
                case 'ireland':
                case 'uk':
                    return value;
                default:
                    return 'ireland';
            }
        };
        const country = parseValue(value);
        setCountry(country);
        handleTypesOnCountryChange(country);
    };
    const handleInternalTypeChange = ({ target: { value } }: RadioChangeEvent) => {
        setInternalType(value);
        handleFormTypeOnInternalTypeChange(value);
    };
    const handleFormTypeChange = ({ target: { value } }: RadioChangeEvent) => {
        setFormType(value);
    };

    const handleStateChangeAfterCreatedTemplate = ({ country, internalType, formType }: TemplateDashboardState) => {
        setCountry(((country as string) === 'cds' ? 'uk' : 'ireland') as DeclarationCountry);
        setInternalType(internalType.toUpperCase() as DeclarationInternalType);
        setFormType(formType);
    };

    return (
        <Container>
            <H5>Submission Form Templates</H5>

            <SpaceBetween style={{ marginTop: '1.6rem' }}>
                <Button size="large" type="primary" onClick={handleOpen} disabled={atArchivedPath}>
                    Create New Template
                </Button>
                <StatusSegmented value={atArchivedPath ? 'archived' : 'active'} />
            </SpaceBetween>

            <TemplateContextProvider onCreateTemplateListing={handleStateChangeAfterCreatedTemplate}>
                <CreateTemplateDrawer visible={visible} onClose={handleClose} />
            </TemplateContextProvider>
            <Divider />
            <div>
                <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                    <Radio.Group
                        options={[
                            { value: 'ireland', label: 'Ireland' },
                            { value: 'uk', label: 'Uk' },
                        ]}
                        value={country}
                        optionType="button"
                        buttonStyle="solid"
                        onChange={handleCountryChange}
                    />
                    <Radio.Group
                        options={internalTypeOptions}
                        value={internalType}
                        optionType="button"
                        buttonStyle="solid"
                        onChange={handleInternalTypeChange}
                    />
                    <Radio.Group
                        options={formTypeOptions}
                        value={formType?.toUpperCase()}
                        optionType="button"
                        buttonStyle="solid"
                        onChange={handleFormTypeChange}
                    />
                </div>
                <TemplateContextProvider>
                    <TemplatesTable
                        country={country}
                        internalType={internalType}
                        formType={formType}
                        atArchivedPath={atArchivedPath}
                    />
                    <TemplateModal />
                </TemplateContextProvider>
            </div>
        </Container>
    );
};

export default TemplateDashboard;
