import { useDispatch } from 'react-redux';
import { doClearDeclarationFooter, doSetDeclarationFooter } from 'store/declaration-footer/action';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { useAppSelector } from '../config/hooks';

const useDeclarationFooter = () => {
    const dispatch = useDispatch();
    const declarationFooter = useAppSelector((state) => state.declarationFooter.type);

    const setDeclarationFooterType = (type: DeclarationFooterType) => {
        if (declarationFooter !== type) {
            dispatch(doSetDeclarationFooter(type));
        }
    };

    const clearDeclarationFooterType = () => {
        dispatch(doClearDeclarationFooter());
    };

    return {
        declarationFooter,
        setDeclarationFooterType,
        clearDeclarationFooterType,
    };
};

export default useDeclarationFooter;
