export enum FilterType {
    DATE = 'Date',
    DECLARATION_STATUS = 'Declaration status',
    DECLARATION_TYPE = 'Declaration type',
    AUTHOR = 'Author',
}

export interface FilterResult {
    type: FilterType;
    value: any[];
}
