import { find, isEqual, keys, pick } from 'lodash';
import { GvmsReferenceData } from '../../../../store/declarations/uk/gvms-declaration';
import axiosClient from 'config/axios';
import { SuccessResponse } from '../../../../core/http/response';
import config from '../../../../config';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { flushSync } from 'react-dom';

export const getGvmsReferenceData = async () => {
    const response = await axiosClient.get<SuccessResponse<GvmsReferenceData>>(
        `${config.declarationsUrl}/uk/gvms/declarations/reference-data`
    );
    return response?.data?.payload;
};

type HandlePopulateRouteId = (
    target: { name: string; value: string },
    formikValues: {
        gvmsRouteData: {
            arrivalPortId?: string;
            departurePortId?: string;
            carrierId?: string;
        };
        direction: string | null;
    },
    routeIdFormikProps: {
        fieldProps: FieldInputProps<any>;
        fieldMeta: FieldMetaProps<any> | undefined;
        fieldHelper: FieldHelperProps<any>;
    },
    gvmsReferenceDataRoutes: GvmsReferenceData['routes'] | undefined
) => void;

class UkGvmsRecordUtils {
    static handlePopulateRouteId: HandlePopulateRouteId = (
        { name, value },
        formikValues,
        routeIdFormikProps,
        gvmsReferenceDataRoutes
    ) => {
        const { gvmsRouteData, direction } = formikValues ?? {};

        const searchData = {
            arrivalPortId: gvmsRouteData?.arrivalPortId ? +gvmsRouteData.arrivalPortId : null,
            carrierId: gvmsRouteData?.carrierId ? +gvmsRouteData.carrierId : null,
            departurePortId: gvmsRouteData?.departurePortId ? +gvmsRouteData.departurePortId : null,
            routeDirection: direction,
        };

        const _name = name === 'direction' ? 'routeDirection' : name.split('.')[1];
        searchData[_name as keyof typeof searchData] = (_name === 'routeDirection' ? value : +value) as any;

        const { fieldHelper } = routeIdFormikProps ?? {};

        if (!Object.values(searchData).every((value) => value !== undefined && value !== null)) {
            fieldHelper.setValue(null);
            return;
        }

        const result = find(gvmsReferenceDataRoutes, (item: GvmsReferenceData['routes'][0]) =>
            isEqual(pick(item, keys(searchData)), searchData)
        );

        flushSync(() => fieldHelper.setValue(result?.routeId ?? null));
        fieldHelper.setTouched(true);
    };

    static addSpacesToGmrId = (gmrId: string | undefined) => {
        if (!gmrId) return '-';
        const matchResult = gmrId.match(/(.{3})(.{1})(.{4})(.{4})/);
        return matchResult ? matchResult.slice(1).join(' ') : gmrId;
    };
}

export default UkGvmsRecordUtils;
