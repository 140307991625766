import { FormModel, validators } from '../../export/validations/validations';
import { getPartyValidator } from '../../../ireland/export/validation/IrelandExportB1Validation';

export const ukNctsD1ValidationItem = {
    childValidators: {
        houseConsignmentId: [validators.required()],
        declarationType: [validators.maxLength(5)],
        countryOfDispatch: [validators.exact(2)],
        countryOfDestination: [validators.exact(2)],
        referenceNumberUCR: [validators.maxLength(35)],
        consignee: getPartyValidator(),
        additionalSupplyChainActor: {
            childValidators: {
                role: [validators.required(), validators.maxLength(3)],
                identificationNumber: [validators.required(), validators.maxLength(17)],
            },
            stopAtNull: true,
        },
        commodity: {
            childValidators: {
                descriptionOfGoods: [validators.required()],
                cusCode: [validators.exact(9)],
                commodityCode: {
                    childValidators: {
                        harmonizedSystemSubHeadingCode: [validators.required(), validators.exact(6)],
                        combinedNomenclatureCode: [validators.exact(2)],
                    },
                },
                dangerousGoods: {
                    childValidators: { unNumber: [validators.required(), validators.exact(4)] },
                    stopAtNull: true,
                },
                goodsMeasure: {
                    childValidators: {
                        grossMass: [validators.number(), validators.float(16, 6)],
                        netMass: [validators.number(), validators.float(16, 6)],
                        supplementaryUnits: [validators.number(), validators.float(16, 6)],
                    },
                },
            },
            stopAtNull: true,
        },
        packaging: {
            childValidators: {
                typeOfPackages: [validators.required(), validators.exact(2)],
                numberOfPackages: [validators.number(), validators.maxLength(8)],
                shippingMarks: [],
            },
            selfValidators: [validators.minLength(1)],
            stopAtNull: true,
        },
        previousDocument: {
            childValidators: {
                referenceNumber: [validators.required(), validators.maxLength(70)],
                type: [validators.required(), validators.maxLength(70)],
                complementOfInformation: [validators.maxLength(35)],
            },
            stopAtNull: true,
        },
        supportingDocument: {
            childValidators: {
                referenceNumber: [validators.required(), validators.maxLength(70)],
                type: [validators.required(), validators.maxLength(70)],
                documentLineItemNumber: [validators.maxLength(5), validators.number()],
                complementOfInformation: [validators.maxLength(35)],
            },
            stopAtNull: true,
        },
        transportDocument: {
            childValidators: {
                referenceNumber: [validators.required(), validators.maxLength(70)],
                type: [validators.required(), validators.exact(4)],
            },
            stopAtNull: true,
        },
        additionalReference: {
            childValidators: {
                referenceNumber: [validators.maxLength(70)],
                type: [validators.required(), validators.exact(4)],
            },
            stopAtNull: true,
        },
        additionalInformation: {
            childValidators: { code: [validators.required(), validators.exact(5)], text: [] },
            stopAtNull: true,
        },
        transportCharges: {
            childValidators: {
                methodOfPayment: [
                    validators.required(),
                    validators.exact(1),
                    validators.regex(/[A-Z]*/, 'Expecting [A-Z]* format'),
                ],
            },
        },
    },
};

export const ukNctsD1ValidationHeader = {
    childValidators: {
        numberOfItems: [
            // validators.required(),
            validators.number(),
            validators.min(1),
            validators.maxLength(3),
            async (_: string, b: FormModel<any>) => {
                const itemsLength = b.getValues()?.goodsShipment?.goodsShipmentItem?.length;
                const numberOfItemsField = b.getValues()?.numberOfItems;
                if (itemsLength === undefined) return undefined;
                if (Number(itemsLength) !== Number(numberOfItemsField)) {
                    return 'Number of items from the input does not match the number of items in the declaration.';
                }
                return undefined;
            },
        ],
        messageSender: [validators.required()],
        transitOperation: {
            childValidators: {
                declarationType: [validators.required(), validators.maxLength(5)],
                additionalDeclarationType: [validators.required(), validators.exact(1)],
                tirCarnetNumber: [validators.maxLength(12)],
                presentationOfTheGoodsDateAndTime: [],
                security: [validators.required(), validators.number(), validators.exact(1)],
                reducedDatasetIndicator: [validators.required(), validators.number(), validators.exact(1)],
                specificCircumstanceIndicator: [validators.exact(3)],
                communicationLanguageAtDeparture: [validators.exact(2)],
                bindingItinerary: [validators.required(), validators.number(), validators.exact(1)],
                limitDate: [],
            },
        },
        authorisation: {
            childValidators: {
                referenceNumber: [validators.required(), validators.maxLength(4)],
                type: [validators.required(), validators.maxLength(35)],
            },
            stopAtNull: true,
        },
        customsOfficeOfDeparture: {
            childValidators: { referenceNumber: [validators.required(), validators.exact(8)] },
        },
        customsOfficeOfDestinationDeclared: {
            childValidators: { referenceNumber: [validators.required(), validators.exact(8)] },
        },
        customsOfficeOfTransitDeclared: {
            childValidators: {
                referenceNumber: [validators.required(), validators.exact(8)],
                arrivalDateAndTimeEstimated: [],
            },
            stopAtNull: true,
        },
        customsOfficeOfExitForTransitDeclared: {
            childValidators: { referenceNumber: [validators.exact(8)] },
            stopAtNull: true,
        },
        holderOfTheTransitProcedure: getPartyValidator({ contactPerson: { present: true, required: true } }),
        representative: getPartyValidator({
            eoriRequired: true,
            contactPerson: { present: true, required: true },
            status: { present: true, required: true },
        }),
        guarantee: {
            childValidators: {
                guaranteeType: [validators.required(), validators.exact(1)],
                otherGuaranteeReference: [validators.maxLength(35)],
                guaranteeReference: {
                    childValidators: {
                        grn: [validators.maxLength(24)],
                        accessCode: [validators.maxLength(4)],
                        amountToBeCovered: [validators.number(), validators.float(16, 2)],
                        currency: [validators.exact(3)],
                    },
                    stopAtNull: true,
                },
            },
            selfValidators: [validators.minLength(1)],
            stopAtNull: true,
        },
        consignment: {
            childValidators: {
                countryOfDispatch: [validators.exact(2)],
                countryOfDestination: [validators.exact(2)],
                containerIndicator: [validators.number(), validators.exact(1), validators.required()],
                inlandModeOfTransport: [validators.number(), validators.exact(1)],
                modeOfTransportAtTheBorder: [validators.exact(1)],
                grossMass: [validators.required(), validators.float(16, 6)],
                referenceNumberUCR: [validators.maxLength(35)],
                carrier: getPartyValidator({ eoriRequired: true, contactPerson: { present: true, required: true } }),
                consignor: getPartyValidator({ contactPerson: { present: true, required: true } }),
                consignee: getPartyValidator(),
                additionalSupplyChainActor: {
                    childValidators: {
                        role: [validators.required(), validators.maxLength(3)],
                        identificationNumber: [validators.required(), validators.maxLength(17)],
                    },
                    stopAtNull: true,
                },
                transportEquipment: {
                    childValidators: {
                        containerIdentificationNumber: [validators.maxLength(17)],
                        numberOfSeals: [validators.required(), validators.number(), validators.maxLength(4)],
                        seal: {
                            childValidators: { identifier: [validators.required(), validators.maxLength(20)] },
                            stopAtNull: true,
                        },
                        goodsReference: {
                            childValidators: {
                                declarationGoodsItemNumber: [
                                    validators.required(),
                                    validators.number(),
                                    validators.maxLength(5),
                                ],
                            },
                            stopAtNull: true,
                        },
                    },
                    stopAtNull: true,
                },
                locationOfGoods: {
                    childValidators: {
                        typeOfLocation: [validators.required(), validators.exact(1)],
                        qualifierOfIdentification: [validators.required(), validators.exact(1)],
                        authorisationNumber: [validators.maxLength(35)],
                        additionalIdentifier: [validators.maxLength(4)],
                        unLocode: [validators.maxLength(17)],
                        customsOffice: {
                            childValidators: { referenceNumber: [validators.exact(8), validators.required()] },
                        },
                        gnss: {
                            childValidators: {
                                latitude: [
                                    validators.required(),
                                    validators.maxLength(17),
                                    validators.regex(
                                        /^[+-]?([0-8]?[0-9]\.[0-9]{5,7}|90.000000?0?)$/,
                                        'Invalid input format. Use a number from -90.0000000 to 90.0000000 with a precision of 5 to 7 decimal places. The number should not start with 0 unless it is exactly 0.'
                                    ),
                                ],
                                longitude: [
                                    validators.required(),
                                    validators.maxLength(17),
                                    validators.regex(
                                        /^[+-]?((0?[0-9]?|1[0-7])[0-9]\.[0-9]{5,7}|180.000000?0?)$/,
                                        'Invalid input format. Use a number from -180.0000000 to 180.0000000 with a precision of 5 to 7 decimal places. The number should not start with 0 unless it is exactly 0.'
                                    ),
                                ],
                            },
                        },
                        economicOperator: {
                            childValidators: {
                                identificationNumber: [validators.eori(), validators.required()],
                            },
                        },
                        address: {
                            childValidators: {
                                streetAndNumber: [validators.required()],
                                country: [validators.required()],
                                city: [validators.required()],
                            },
                        },
                        postcodeAddress: {
                            childValidators: {
                                country: [validators.required()],
                                postcode: [validators.required()],
                            },
                        },
                        contactPerson: {
                            childValidators: {
                                name: [validators.required()],
                                phoneNumber: [validators.required()],
                            },
                        },
                    },
                },
                countryOfRoutingOfConsignment: {
                    childValidators: { country: [validators.required(), validators.exact(2)] },
                    stopAtNull: true,
                },
                departureTransportMeans: {
                    childValidators: {
                        typeOfIdentification: [validators.number(), validators.exact(2)],
                        identificationNumber: [validators.maxLength(35)],
                        nationality: [validators.exact(2)],
                    },
                    stopAtNull: true,
                },
                activeBorderTransportMeans: {
                    childValidators: {
                        customsOfficeAtBorderReferenceNumber: [validators.exact(8)],
                        typeOfIdentification: [validators.number(), validators.exact(2)],
                        identificationNumber: [validators.maxLength(35)],
                        nationality: [validators.exact(2)],
                        conveyanceReferenceNumber: [validators.maxLength(17)],
                    },
                    stopAtNull: true,
                },
                placeOfLoading: {
                    childValidators: {
                        unLocode: [validators.maxLength(17)],
                        country: [validators.exact(2)],
                        location: [validators.maxLength(35)],
                    },
                },
                placeOfUnloading: {
                    childValidators: {
                        unLocode: [validators.maxLength(17)],
                        country: [validators.exact(2)],
                        location: [validators.maxLength(35)],
                    },
                },
                previousDocument: {
                    childValidators: {
                        referenceNumber: [validators.required(), validators.exact(4)],
                        type: [validators.required(), validators.maxLength(70)],
                        complementOfInformation: [validators.maxLength(35)],
                    },
                    stopAtNull: true,
                },
                supportingDocument: {
                    childValidators: {
                        referenceNumber: [validators.required(), validators.exact(4)],
                        type: [validators.required(), validators.maxLength(70)],
                        documentLineItemNumber: [validators.maxLength(5), validators.number()],
                        complementOfInformation: [validators.maxLength(35)],
                    },
                    stopAtNull: true,
                },
                transportDocument: {
                    childValidators: {
                        referenceNumber: [validators.required(), validators.maxLength(70)],
                        type: [validators.required(), validators.exact(4)],
                    },
                    stopAtNull: true,
                },
                additionalReference: {
                    childValidators: {
                        referenceNumber: [validators.maxLength(70)],
                        type: [validators.required(), validators.exact(4)],
                    },
                    stopAtNull: true,
                },
                additionalInformation: {
                    childValidators: { code: [validators.required(), validators.exact(5)], text: [] },
                    stopAtNull: true,
                },
                transportCharges: {
                    childValidators: {
                        methodOfPayment: [
                            validators.exact(1),
                            validators.required(),
                            validators.regex(/[A-Z]*/, 'Expecting [A-Z]* format'),
                        ],
                    },
                },
                houseConsignment: {
                    childValidators: {
                        countryOfDispatch: [validators.exact(2)],
                        grossMass: [validators.required(), validators.float(16, 6)],
                        referenceNumberUCR: [validators.maxLength(35)],
                        consignor: getPartyValidator({ contactPerson: { present: true } }),
                        consignee: getPartyValidator(),
                        additionalSupplyChainActor: {
                            childValidators: {
                                role: [validators.required(), validators.maxLength(3)],
                                identificationNumber: [validators.required(), validators.maxLength(17)],
                            },
                            stopAtNull: true,
                        },
                        departureTransportMeans: {
                            childValidators: {
                                typeOfIdentification: [validators.number(), validators.exact(2)],
                                identificationNumber: [validators.maxLength(35)],
                                nationality: [validators.exact(2)],
                            },
                            stopAtNull: true,
                        },
                        previousDocument: {
                            childValidators: {
                                referenceNumber: [validators.required(), validators.exact(4)],
                                type: [validators.required(), validators.maxLength(70)],
                                complementOfInformation: [validators.maxLength(35)],
                            },
                            stopAtNull: true,
                        },
                        supportingDocument: {
                            childValidators: {
                                referenceNumber: [validators.required(), validators.exact(4)],
                                type: [validators.required(), validators.maxLength(70)],
                                documentLineItemNumber: [validators.maxLength(5), validators.number()],
                                complementOfInformation: [validators.maxLength(35)],
                            },
                            stopAtNull: true,
                        },
                        transportDocument: {
                            childValidators: {
                                referenceNumber: [validators.required(), validators.maxLength(70)],
                                type: [validators.required(), validators.exact(4)],
                            },
                            stopAtNull: true,
                        },
                        additionalReference: {
                            childValidators: {
                                referenceNumber: [validators.maxLength(70)],
                                type: [validators.required(), validators.exact(4)],
                            },
                            stopAtNull: true,
                        },
                        additionalInformation: {
                            childValidators: { code: [validators.required(), validators.exact(5)], text: [] },
                            stopAtNull: true,
                        },
                        transportCharges: {
                            childValidators: {
                                methodOfPayment: [
                                    validators.required(),
                                    validators.exact(1),
                                    validators.regex(/[A-Z]*/, 'Expecting [A-Z]* format'),
                                ],
                            },
                        },
                        consignmentItem: ukNctsD1ValidationItem,
                    },
                    selfValidators: [validators.minLength(1)],
                    stopAtNull: true,
                },
            },
        },
    },
};
