import { FormModel, validators } from '../../../uk/export/validations/validations';

const pbnValidation = {
    childValidators: {
        direction: [],
        emailId: [
            async (_: string, b: FormModel<any>) => {
                const directionValue = b.getValues()?.direction;
                const emailValue = b.getValues()?.emailId;

                if (directionValue === 'OUT_IRELAND') return undefined;
                else if (directionValue === 'IN_IRELAND' && !emailValue) return 'Email Address is required.';

                return undefined;
            },
            validators.email(),
        ],
        mobileNum1: [
            async (_: string, b: FormModel<any>) => {
                const directionValue = b.getValues()?.direction;
                const mobileNum1Value = b.getValues()?.mobileNum1;

                if (directionValue === 'OUT_IRELAND') return undefined;
                else if (directionValue === 'IN_IRELAND' && !mobileNum1Value) return 'Mobile Number 1 is required.';

                return undefined;
            },
            validators.regex(
                /^\+?\d{10,15}$/,
                'Please enter a valid phone number e.g. 0871234567 or +447123456789 (International format).'
            ),
        ],
        mobileNum2: [
            async (_: string, b: FormModel<any>) => {
                const directionValue = b.getValues()?.direction;
                const mobileNum2Value = b.getValues()?.mobileNum2;

                if (directionValue === 'OUT_IRELAND') return undefined;
                else if (directionValue === 'IN_IRELAND' && !mobileNum2Value) return 'Mobile Number 2 is required.';

                return undefined;
            },
            validators.regex(
                /^\+?\d{10,15}$/,
                'Please enter a valid phone number e.g. 0871234567 or +447123456789 (International format).'
            ),
        ],
        declaration: {
            childValidators: {
                mrn: [validators.required()],
            },
            stopAtNull: true,
        },
    },
};

export default pbnValidation;
