export class MimeMap {
    static mimeMap = {
        'text/csv': 'csv',
        'application/pdf': 'pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/msword': 'doc',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/vnd.ms-excel': 'xls',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/zip': 'zip',
        'application/x-rar-compressed': 'rar',
        'application/x-7z-compressed': '7z',
        'application/x-tar': 'tar',
        'application/x-bzip2': 'bz2',
        'application/x-gzip': 'gz',
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'audio/mpeg': 'mp3',
        'video/mp4': 'mp4',
        'text/plain': 'txt',
        'application/json': 'json',
    } as const;

    public static getExtension(mimeType: keyof typeof this.mimeMap) {
        return MimeMap.mimeMap[mimeType as keyof typeof this.mimeMap];
    }

    public static getMimeType(extension: typeof this.mimeMap[keyof typeof this.mimeMap]) {
        return Object.keys(MimeMap.mimeMap).find(
            (key) => MimeMap.mimeMap[key as keyof typeof this.mimeMap] === extension
        )!;
    }
}
