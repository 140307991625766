import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { ParsedCard, ParsedItem } from '../../../../../store/declaration-form-errors/ParsedForm';
import { NctsDeclaration } from '../../../../../store/declarations/ireland/ncts-declaration';
import {
    parseFieldErrorsForAesCustomerCard,
    parseFieldErrorsForCard,
    parseFieldErrorsForMultipleCard,
} from '../../../../../utils/validationParserUtils';

const ukNctsValidationErrorsParser = (validation: FormikErrors<any>, options?: { withoutCustomers?: boolean }) => {
    const masterDetailsErrors: ParsedCard[] = [];

    parseFieldErrorsForCard(
        'Transit Operation',
        [
            { messageSender: 'Message Sender' },
            { 'transitOperation.declarationType': 'Declaration type' },
            { 'transitOperation.additionalDeclarationType': 'Additional declaration type' },
            { 'transitOperation.tirCarnetNumber': 'TIR Carnet number' },
            { 'transitOperation.presentationOfTheGoodsDateAndTime': 'Presentation of the goods date and time' },
            { 'transitOperation.security': 'Security' },
            { 'transitOperation.reducedDatasetIndicator': 'Reduced dataset indicator' },
            { 'transitOperation.specificCircumstanceIndicator': 'Specific circumstance indicator' },
            { 'transitOperation.communicationLanguageAtDeparture': 'Communication language at departure' },
            { 'transitOperation.bindingItinerary': 'Binding itinerary' },
            { 'transitOperation.limitDate': 'limitDate' },
            { numberOfItems: 'Number of Items' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Authorisation',
        'authorisation',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Customs Office Of Departure',
        [{ 'customsOfficeOfDeparture.referenceNumber': 'Reference Number' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Customs Office Of Destination Declared',
        [{ 'customsOfficeOfDestinationDeclared.referenceNumber': 'Reference Number' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Customs Office Of Transit Declared',
        'customsOfficeOfTransitDeclared',
        [{ referenceNumber: 'Reference Number' }, { arrivalDateAndTimeEstimated: 'Arrival date and time (estimated)' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Customs Office Of Exit For Transit Declared',
        [{ 'customsOfficeOfExitForTransitDeclared.referenceNumber': 'Reference Number' }],
        masterDetailsErrors,
        validation
    );

    if (!options?.withoutCustomers) {
        parseFieldErrorsForAesCustomerCard('Representative', 'representative', masterDetailsErrors, validation);
        parseFieldErrorsForAesCustomerCard(
            'Holder Of The Transit Procedure',
            'holderOfTheTransitProcedure',
            masterDetailsErrors,
            validation
        );
        parseFieldErrorsForAesCustomerCard('Carrier', 'consignment.carrier', masterDetailsErrors, validation);
        parseFieldErrorsForAesCustomerCard('Consignor', 'consignment.consignor', masterDetailsErrors, validation);
        parseFieldErrorsForAesCustomerCard('Consignee', 'consignment.consignee', masterDetailsErrors, validation);
    }

    parseFieldErrorsForMultipleCard(
        'Guarantee',
        'guarantee',
        [
            { guaranteeType: 'Guarantee type' },
            { otherGuaranteeReference: 'Other guarantee reference' },
            { guaranteeReference: 'Guarantee Reference' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Guarantee Reference',
        'guaranteeReference',
        [
            { grn: 'GRN' },
            { accessCode: 'Access code' },
            { amountToBeCovered: 'Amount to be covered' },
            { currency: 'Currency' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Consignment',
        [
            { 'consignment.countryOfDispatch': 'Country of dispatch' },
            { 'consignment.countryOfDestination': 'Country of destination' },
            { 'consignment.containerIndicator': 'Container indicator' },
            { 'consignment.inlandModeOfTransport': 'Inland mode of transport' },
            { 'consignment.modeOfTransportAtTheBorder': 'Mode of transport at the border' },
            { 'consignment.grossMass': 'Gross mass' },
            { 'consignment.referenceNumberUCR': 'Reference number UCR' },
            { 'consignment.countryOfRoutingOfConsignment.[0].country': 'Country of Routing of Consignment' },
            { 'consignment.transportCharges.methodOfPayment': 'Transport Charges' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Supply Chain Actor',
        'consignment.additionalSupplyChainActor',
        [{ role: 'Role' }, { identificationNumber: 'Identification number' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Transport Equipment',
        'consignment.transportEquipment',
        [
            { containerIdentificationNumber: 'Container identification number' },
            { numberOfSeals: 'Number of seals' },
            { 'goodsReference.[0].declarationGoodsItemNumber': 'Goods Reference - Declaration goods item number' },
            { 'seal.[0].identifier': 'Seal - Identifier' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Location Of Goods',
        [
            { 'consignment.locationOfGoods.typeOfLocation': 'Type of location' },
            { 'consignment.locationOfGoods.qualifierOfIdentification': 'Qualifier of identification' },
            { 'consignment.locationOfGoods.authorisationNumber': 'Authorisation number' },
            { 'consignment.locationOfGoods.additionalIdentifier': 'Additional identifier' },
            { 'consignment.locationOfGoods.unLocode': 'UN LOCODE' },
            { 'consignment.locationOfGoods.customsOffice.referenceNumber': 'Customs Office - Reference number' },
            { 'consignment.locationOfGoods.gnss.latitude': 'GNSS - Latitude' },
            { 'consignment.locationOfGoods.gnss.longitude': 'GNSS - Longitude' },
            {
                'consignment.locationOfGoods.economicOperator.identificationNumber':
                    'Economic Operator - Identification Number',
            },
            { 'consignment.locationOfGoods.address.streetAndNumber': 'Address - Street and Number' },
            { 'consignment.locationOfGoods.address.postcode': 'Address - Postcode' },
            { 'consignment.locationOfGoods.address.city': 'Address - City' },
            { 'consignment.locationOfGoods.address.country': 'Address - Country' },
            { 'consignment.locationOfGoods.postcodeAddress.houseNumber': 'Postcode Address - House Number' },
            { 'consignment.locationOfGoods.postcodeAddress.postcode': 'Postcode Address - Postcode' },
            { 'consignment.locationOfGoods.postcodeAddress.country': 'Postcode Address - Country' },
            { 'consignment.locationOfGoods.contactPerson.name': 'Contact Person - Name' },
            { 'consignment.locationOfGoods.contactPerson.phoneNumber': 'Contact Person - Phone Number' },
            { 'consignment.locationOfGoods.contactPerson.emailAddress': 'Contact Person - E-mail Address' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Departure Transport Means',
        'consignment.departureTransportMeans',
        [
            { typeOfIdentification: 'Type of Identification' },
            { identificationNumber: 'Identification Number' },
            { nationality: 'Nationality' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Active Border Transport Means',
        'consignment.activeBorderTransportMeans',
        [
            { customsOfficeAtBorderReferenceNumber: 'Customs office at border reference number' },
            { typeOfIdentification: 'Type of identification' },
            { identificationNumber: 'Identification Number' },
            { nationality: 'Nationality' },
            { conveyanceReferenceNumber: 'Conveyance reference number' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Place of Loading',
        [
            { 'consignment.placeOfLoading.unLocode': 'UN LOCODE' },
            { 'consignment.placeOfLoading.country': 'Country' },
            { 'consignment.placeOfLoading.location': 'Location' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Place ofUnloading',
        [
            { 'consignment.placeOfUnloading.unLocode': 'UN LOCODE' },
            { 'consignment.placeOfUnloading.country': 'Country' },
            { 'consignment.placeOfUnloading.location': 'Location' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Previous Document',
        'consignment.previousDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { complementOfInformation: 'Complement of information' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Supporting Document',
        'consignment.supportingDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { complementOfInformation: 'Complement of information' },
            { documentLineItemNumber: 'Document Line Item Number' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Transport Document',
        'consignment.transportDocument',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Reference',
        'consignment.additionalReference',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'consignment.additionalInformation',
        [{ code: 'Code' }, { text: 'Text' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'House Consignment',
        'consignment.houseConsignment',
        [
            { countryOfDispatch: 'Country of dispatch' },
            { grossMass: 'Gross mass' },
            { referenceNumberUCR: 'Code' },
            { consignor: 'Consignor' },
            { consignee: 'Consignee' },
            { additionalSupplyChainActor: 'Additional Supply Chain Actor' },
            { departureTransportMeans: 'Departure Transport Means' },
            { previousDocument: 'Previous Document' },
            { supportingDocument: 'Supporting Document' },
            { transportDocument: 'Transport Document' },
            { additionalReference: 'Additional Reference' },
            { additionalInformation: 'Additional Information' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForAesCustomerCard(
        'Consignor',
        'consignment.houseConsignment.consignor',
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForAesCustomerCard(
        'Consignee',
        'consignment.houseConsignment.consignee',
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Supply Chain Actor',
        'consignment.houseConsignment.additionalSupplyChainActor',
        [{ role: 'Role' }, { identificationNumber: 'Identification number' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Departure Transport Means',
        'consignment.houseConsignment.departureTransportMeans',
        [
            { typeOfIdentification: 'Type of Identification' },
            { identificationNumber: 'Identification Number' },
            { nationality: 'Nationality' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Previous Document',
        'consignment.houseConsignment.previousDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { complementOfInformation: 'Complement of information' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Supporting Document',
        'consignment.houseConsignment.supportingDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { complementOfInformation: 'Complement of information' },
            { documentLineItemNumber: 'Document Line Item Number' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Transport Document',
        'consignment.houseConsignment.transportDocument',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Reference',
        'consignment.houseConsignment.additionalReference',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'consignment.houseConsignment.additionalInformation',
        [{ code: 'Code' }, { text: 'Text' }],
        masterDetailsErrors,
        validation
    );

    const itemsErrors: ParsedItem[] = [];
    parseDeclarationItems(itemsErrors, validation);

    return {
        masterDetails: masterDetailsErrors,
        items: itemsErrors,
    };
};

export const parseNctsProductTemplate = (validation: FormikErrors<NctsDeclaration>) => {
    const productTemplateErrors: ParsedCard[] = [];

    parseDeclarationItem(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseDeclarationItems = (errors: ParsedItem[], validation: FormikErrors<any>) => {
    const houseConsignment = get(validation, 'consignment.houseConsignment') as any[] | undefined;
    const products = houseConsignment
        ?.map((hc) => hc.consignmentItem)
        .filter(Boolean)
        .flat();

    if (!Array.isArray(products)) return;

    products?.forEach((itemErrors: any, index: number) => {
        const item = { index: index + 1, errors: [] };

        parseDeclarationItem(item.errors, itemErrors);

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

const parseDeclarationItem = (parsed: ParsedCard[], errors: FormikErrors<any>) => {
    parseFieldErrorsForCard(
        'Goods Information',
        [
            { 'commodity.cusCode': 'CUS code' },
            { 'commodity.commodityCode.harmonizedSystemSubHeadingCode': 'Harmonized System sub-heading code' },
            { 'commodity.commodityCode.combinedNomenclatureCode': 'Combined nomenclature code' },
            { declarationType: 'Declaration type' },
            { countryOfDispatch: 'Country of dispatch' },
            { countryOfDestination: 'Country of destination' },
            { referenceNumberUCR: 'Reference number UCR' },
            { 'commodity.descriptionOfGoods': 'Description of goods' },
            { 'commodity.dangerousGoods.[0].unNumber': 'Dangerous Goods - UN Number' },
            { 'commodity.goodsMeasure.grossMass': 'Gross mass' },
            { 'commodity.goodsMeasure.netMass': 'Net mass' },
            { 'commodity.goodsMeasure.supplementaryUnits': 'Supplementary units' },
            { 'transportCharges.methodOfPayment': 'Method of payment' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForAesCustomerCard('Consignee', 'consignee', parsed, errors);

    parseFieldErrorsForMultipleCard(
        'Additional Supply Chain Actor',
        'additionalSupplyChainActor',
        [{ role: 'Role' }, { identificationNumber: 'Identification number' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Packaging',
        'packaging',
        [
            { typeOfPackages: 'Type of Packages' },
            { numberOfPackages: 'Number of Packages' },
            { shippingMarks: 'Shipping Marks' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Previous Document',
        'previousDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { complementOfInformation: 'Complement of information' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Supporting Document',
        'supportingDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { complementOfInformation: 'Complement of information' },
            { documentLineItemNumber: 'Document Line Item Number' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Transport Document',
        'transportDocument',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Additional Reference',
        'additionalReference',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'additionalInformation',
        [{ code: 'Code' }, { text: 'Text' }],
        parsed,
        errors
    );
};

export default ukNctsValidationErrorsParser;
