import { Reducer } from 'redux';
import { CodelistsActions } from './actionType';
import { IrelandCodelistTypes, UkCodelistTypes, UkNctsCodelists } from './code';

export interface CodelistsState {
    irelandCodelists?: IrelandCodelistTypes;
    ukCodelists?: UkCodelistTypes;
    UkNctsCodelists?: UkNctsCodelists;
    ieUnloPagination?: { page: number; count: number };
    ukUnloPagination?: { page: number; count: number };
}

export const codelistsInitialState: Readonly<CodelistsState> = {};

const codelists: Reducer<CodelistsState, CodelistsActions> = (
    state = codelistsInitialState,
    action: CodelistsActions
) => {
    switch (action.type) {
        case 'GET_IRELAND_CODELISTS_SUCCESS':
            return {
                ...state,
                irelandCodelists: {
                    ...state.irelandCodelists,
                    ...action.payload,
                    ncts: {
                        ...action.payload?.ncts,
                        unLocodeExtended: [...(state.irelandCodelists?.ncts?.unLocodeExtended ?? [])],
                    },
                },
            };
        case 'GET_IRELAND_UNLOCODEEXTENDED_CODELISTS_SUCCESS':
            return {
                ...state,
                irelandCodelists: {
                    ...state.irelandCodelists,
                    ncts: {
                        ...state.irelandCodelists?.ncts,
                        unLocodeExtended: [
                            ...(state.irelandCodelists?.ncts?.unLocodeExtended ?? []),
                            ...(action.payload.list ?? []),
                        ],
                    },
                },
                ieUnloPagination: {
                    page: action.payload.pageNumber ?? 0,
                    count: action.payload.total ?? 0,
                },
            };
        case 'GET_IRELAND_CODELISTS_ERROR':
            return {
                ...state,
                irelandCodelists: action.payload,
            };
        case 'GET_UK_CODELISTS_REQUEST':
            return {
                ...state,
            };
        case 'GET_UK_CODELISTS_SUCCESS':
            return {
                ...state,
                ukCodelists: {
                    ...state.ukCodelists,
                    ...action.payload,
                    ncts: {
                        ...action.payload?.ncts,
                        unLocodeExtended: [...(state.ukCodelists?.ncts?.unLocodeExtended ?? [])],
                    },
                },
            };
        case 'GET_UK_UNLOCODEEXTENDED_CODELISTS_SUCCESS':
            return {
                ...state,
                ukCodelists: {
                    ...state.ukCodelists,
                    ncts: {
                        ...state.ukCodelists?.ncts,
                        unLocodeExtended: [
                            ...(state.ukCodelists?.ncts?.unLocodeExtended ?? []),
                            ...(action.payload.list ?? []),
                        ],
                    },
                },
                ukUnloPagination: {
                    page: action.payload.pageNumber ?? 0,
                    count: action.payload.total ?? 0,
                },
            };
        case 'GET_UK_CODELISTS_ERROR':
            return {
                ...state,
                ukCodelists: action.payload,
            };
        default:
            return state;
    }
};

export default codelists;
