import { FC } from 'react';
import { H4Style } from '../../../../../components/ui/base/typography/Typography.styles';
import Base64Image from '../../../common/Base64Image';
import { Ticket } from '../../../uk/gvms/components/barcode/UkGvmsTicket';
import { startCase } from 'lodash';
interface Props {
    pbnId: string | undefined;
    pbnBarcode: string | undefined;
    pbnStatus: string | undefined;
}

const PbnTicket: FC<Props> = ({ pbnId, pbnBarcode, pbnStatus }) => {
    return (
        <Ticket>
            <H4Style style={{ color: 'white' }}>Present this Pre-Boarding Notification at the port.</H4Style>
            <H4Style style={{ color: 'white' }}>{startCase(pbnStatus)}</H4Style>
            <H4Style style={{ color: 'white' }}>{pbnId}</H4Style>
            <Base64Image base64String={pbnBarcode} alt="barcode" />
        </Ticket>
    );
};

export default PbnTicket;
