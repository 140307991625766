import { FC } from 'react';

const Base64Image: FC<{ base64String: string | undefined; alt: string }> = ({ base64String, alt = 'Image' }) => {
    if (!base64String) {
        return null;
    }
    const dataURI = `data:image/png;base64,${base64String}`;
    return <img src={dataURI} alt={alt} />;
};

export default Base64Image;
