import { ReactElement } from 'react';
import styled from 'styled-components';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isEmpty } from 'lodash';

const ButtonsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

interface Props {
    onAdd: () => void;
    onRemove: () => void;
    canAdd?: boolean;
    canRemove?: boolean;
    ctaButtons?: JSX.Element[];
}

export const MultipleFieldsButton = styled(Button)`
    background-color: transparent;
    border-color: #cecece;
    border-radius: 4px;

    color: #cecece;
    font-weight: 400;

    transition: 100ms;

    :focus {
        background-color: transparent;
        border-color: link;
        color: #cecece;
    }

    :hover {
        background-color: initial;
        border-color: link;
        color: #cecece;
    }
`;

const MultipleButtons = ({ onAdd, onRemove, canAdd = true, canRemove, ctaButtons }: Props): ReactElement => {
    return (
        <ButtonsContainer>
            {canRemove && <MultipleFieldsButton onClick={onRemove} icon={<MinusOutlined />} />}
            {canAdd && <MultipleFieldsButton onClick={onAdd} icon={<PlusOutlined />} />}
            {!isEmpty(ctaButtons) && ctaButtons}
        </ButtonsContainer>
    );
};

export default MultipleButtons;
