import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FieldProps, useFormikContext, FieldArray } from 'formik';
import { isEmpty } from 'lodash';
import { ReactNode, ReactElement, useMemo, useEffect } from 'react';
import addPathPrefix from 'utils/addPathPrefix';
import { getFormikProps } from '../utils/form-utils';
import DeclarationField, { DeclarationFieldProps } from './DeclarationField';

interface Controls {
    onAdd: () => void;
    onRemove: () => void;
    canAdd?: boolean;
    canRemove?: boolean;
    ctaButtons?: JSX.Element[];
}

interface Props extends DeclarationFieldProps {
    children: (fieldProps: FieldProps<any>, controls: Controls, index: number) => ReactNode;
    max?: number;
    parent?: string | null;
    getCtaButtons?: (declarationFieldIndex: number | null) => JSX.Element[];
}

const MultipleDeclarationField = ({
    children,
    name,
    validation,
    pathPrefix,
    max,
    parent,
    getCtaButtons,
}: Props): ReactElement => {
    const _formik = useFormikContext();
    const { form, template, templateFormik } = useTemplateContext();
    const formik = useMemo(
        () => (template && templateFormik ? templateFormik : _formik),
        [_formik, template, templateFormik]
    );

    const path = useMemo(() => {
        return addPathPrefix(addPathPrefix(template && `${form}.defaults`, pathPrefix), parent ? parent : name);
    }, [form, name, parent, pathPrefix, template]);

    const { fieldProps, fieldHelper } = useMemo(() => {
        return getFormikProps(path, formik);
    }, [formik, path]);

    useEffect(() => {
        if (isEmpty(fieldProps.value)) {
            fieldHelper.setValue([parent ? { [name]: null } : null]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldHelper, fieldProps.value]);

    const reachedMaxNumberOfFields = useMemo(() => fieldProps.value?.length === max, [fieldProps.value?.length, max]);

    return (
        <>
            <FieldArray
                name={path}
                render={(arrayHelpers) => (
                    <>
                        {(fieldProps.value ?? [])?.map((_: any, index: number) => (
                            <DeclarationField
                                key={index}
                                pathPrefix={pathPrefix}
                                name={
                                    parent
                                        ? addPathPrefix(addPathPrefix(parent, index.toString()), name)
                                        : addPathPrefix(name, index.toString())
                                }
                                validation={validation}
                            >
                                {(_fieldProps) =>
                                    children(
                                        _fieldProps,
                                        {
                                            onAdd: () => {
                                                arrayHelpers.insert(index + 1, parent ? { [name]: null } : null);
                                                if (template) {
                                                    const meta = {
                                                        ...(templateFormik?.getFieldProps(`${form}.meta`).value ?? {}),
                                                    };

                                                    meta[`${parent || name}.${index + 1}.${name}`] = {
                                                        isViewable: false,
                                                        isEditable: true,
                                                    };

                                                    templateFormik?.setFieldValue(`${form}.meta`, meta);
                                                }
                                            },
                                            onRemove: () => {
                                                arrayHelpers.remove(index);
                                                if (template) {
                                                    const meta = {
                                                        ...templateFormik?.getFieldProps(`${form}.meta`).value,
                                                    };
                                                    const fieldMeta = Object.fromEntries(
                                                        Object.entries(meta).filter(([key]) =>
                                                            key.startsWith(parent || name)
                                                        )
                                                    );

                                                    // Delete all the meta data for the field
                                                    Object.keys(fieldMeta).forEach((key) => {
                                                        delete meta[key];
                                                    });

                                                    // Delete the meta data for the deleted item
                                                    const keys = Object.keys(fieldMeta).filter((key) =>
                                                        key.startsWith(`${parent || name}.${index}`)
                                                    );
                                                    keys.forEach((key) => {
                                                        delete fieldMeta[key];
                                                    });

                                                    // Rearrange the meta data for the remaining items
                                                    const newMeta = Object.fromEntries(
                                                        Object.entries(fieldMeta).map(([key, value], i) => {
                                                            const keySplit = key.split('.');
                                                            const currentIndex = parseInt(
                                                                keySplit[keySplit.length - 2]
                                                            );
                                                            if (currentIndex < index) return [key, value];
                                                            keySplit[keySplit.length - 2] = `${currentIndex - 1}`;
                                                            return [keySplit.join('.'), value];
                                                        })
                                                    );

                                                    templateFormik?.setFieldValue(`${form}.meta`, {
                                                        ...meta,
                                                        ...newMeta,
                                                    });
                                                }
                                            },
                                            canAdd: !reachedMaxNumberOfFields,
                                            canRemove: fieldProps.value?.length > 1,
                                            ctaButtons: getCtaButtons?.(index),
                                        },
                                        index
                                    )
                                }
                            </DeclarationField>
                        ))}
                    </>
                )}
            />
        </>
    );
};

export default MultipleDeclarationField;
