import { Button, Col, InputRef, Row, Spin } from 'antd';
import Drawer from 'components/ui/base/drawer/Drawer';
import FormInput from 'components/ui/composed/declarations/formInput';
import FormSelect from 'components/ui/composed/declarations/formSelect';
import FormDatepicker from 'components/ui/composed/formDatepicker/FormDatepicker';
import CountrySelect from 'components/ui/composed/selects/CountrySelect';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import useTaricCodes from 'hooks/useTaricCodes';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MeasureType, TaricCodesType } from 'store/taric-codes/taric-code';
import SearchCustomer from '../search-customer/SearchCustomer';
import ExpandedSection from './components/details/ExpandedSection';
import TaricCodesSections from './components/section/TaricCodesSections';
import { Content, CountryCol, DateDiv, InfoDiv, SearchCol, StyledH6 } from './components/TaricCode.styles';
import { TaricDetailsType } from './TaricCodesCard';
interface Props {
    visible: boolean;
    onClose: Function;
    taricCodeType?: TaricCodesType;
    selectedCommodityCode: (code: string, duty?: string, vat?: string) => void;
    detailsType: TaricDetailsType;
}
const TaricCodeDrawer: FC<Props> = ({
    visible,
    onClose,
    taricCodeType = TaricCodesType.EU,
    selectedCommodityCode,
    detailsType,
}) => {
    const {
        taricCodeDetails,
        taricCode,
        taricCodeSections,
        getTaricCodesDetails,
        getTaricCodes,
        getTaricCodesSections,
        isLoading,
    } = useTaricCodes();

    const [showDetails, setShowDetails] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const currentDate = useMemo(() => new Date().toISOString(), []);

    useEffect(() => {
        if (!taricCodeSections?.length) {
            getTaricCodesSections(taricCodeType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getFieldHelpers, getFieldProps, values } = useFormik<{
        date: string;
        country?: string;
        measureType: MeasureType;
    }>({
        initialValues: { date: currentDate, measureType: MeasureType.IMPORT },
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const searchDescription = useCallback(
        async (query: string) => {
            setSearching(true);
            if (query) {
                await getTaricCodesDetails(taricCodeType, query, values?.date);
                setShowDetails(true);
                setSearching(false);
            }
        },
        [getTaricCodesDetails, taricCodeType, values]
    );

    const querySearch = useCallback(
        async (query: string) => {
            let taricCodes: string[] | undefined;

            if (query) {
                taricCodes = await getTaricCodes(taricCodeType, query.trim());
                setSearchQuery(query);
            } else {
                setSearchQuery('');
            }

            if (taricCodes?.length) {
                searchDescription(taricCodes[0]);
            } else {
                setShowDetails(false);
            }
        },
        [getTaricCodes, searchDescription, taricCodeType]
    );

    const handleSearchChange = (e: any) => {
        const value = e.target.value;
        setSearchQuery(value);
    };

    const searchInputRef = useRef<InputRef>(null);

    useEffect(() => {
        const onKeyDown = (e: any) => {
            if (e.key === 'Enter') {
                querySearch(e.target.value);
            }
        };

        const input = searchInputRef.current?.input;
        input?.addEventListener('keydown', onKeyDown);
        return () => input?.removeEventListener('keydown', onKeyDown);
    }, [querySearch, searchQuery]);

    const handleSearch = useMemo(() => {
        return (
            <SearchCol span={14}>
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    {taricCode && (
                        <div style={{ width: '100%' }}>
                            <FormInput
                                label="Commodity code or Description Search"
                                value={searchQuery}
                                ref={searchInputRef}
                                placeholder="Enter the name of the goods or the commodity code"
                                size="large"
                                onChange={handleSearchChange}
                            />
                        </div>
                    )}
                    <SearchCustomer.Button onClick={() => querySearch(searchQuery)} condensed compact title="Search" />
                </div>
            </SearchCol>
        );
    }, [taricCode, searchQuery, querySearch]);

    const handleCounty = useMemo(() => {
        return (
            <CountryCol span={6}>
                <CountrySelect
                    placeholder="Search or select country"
                    label="Trade Country"
                    fieldProps={getFieldProps('country')}
                    fieldHelper={getFieldHelpers('country')}
                />
            </CountryCol>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldProps]);

    const handleDate = useMemo(() => {
        const date = new Date(getFieldProps('date').value);
        const dateFormatted = format(date, "dd MMMM 'of' yyyy");
        return (
            <Col span={14}>
                <Row>
                    <InfoDiv>
                        <StyledH6>{`The tariff is the most recent (${dateFormatted})`}</StyledH6>
                    </InfoDiv>
                    <DateDiv>
                        <FormDatepicker fieldProps={getFieldProps('date')} />
                    </DateDiv>
                </Row>
            </Col>
        );
    }, [getFieldProps]);

    const handleContent = useMemo(() => {
        if (showDetails) {
            return taricCodeDetails ? (
                <ExpandedSection
                    searching={searching}
                    searchQuery={searchQuery}
                    detailsType={getFieldProps('measureType').value}
                    taricCodesDetails={taricCodeDetails}
                    back={() => setShowDetails(false)}
                    taricCodeType={taricCodeType}
                    country={getFieldProps('country').value}
                    selectedCommodityCode={selectedCommodityCode}
                />
            ) : (
                <></>
            );
        } else {
            if (taricCodeSections) {
                return (
                    <TaricCodesSections
                        sections={taricCodeSections}
                        searchCode={(c) => {
                            setSearchQuery('');
                            searchDescription(c);
                        }}
                    />
                );
            }
        }
    }, [
        showDetails,
        taricCodeDetails,
        taricCodeSections,
        getFieldProps,
        searchDescription,
        selectedCommodityCode,
        taricCodeType,
        searching,
        searchQuery,
    ]);

    const measureTypeInput = useMemo(() => {
        return (
            <Col>
                <FormSelect
                    fieldProps={getFieldProps('measureType')}
                    fieldHelper={getFieldHelpers('measureType')}
                    defaultValue="IMPORT"
                    label="Measure Type"
                    allowClear
                    hideCodelistMenu
                    selectOptions={[
                        { value: 'Import', id: 'IMPORT' },
                        { value: 'Export', id: 'EXPORT' },
                        { value: 'General', id: 'GENERAL' },
                    ]}
                />
            </Col>
        );
    }, [getFieldHelpers, getFieldProps]);

    return (
        <Drawer title="Taric code" visible={visible} onClose={() => onClose()} width={1094}>
            <Content>
                <Row wrap={false} gutter={12}>
                    {handleSearch}
                    {handleCounty}
                    {measureTypeInput}
                </Row>
                <Row>{handleDate}</Row>
                <Spin spinning={isLoading}>{handleContent}</Spin>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
                    <Button
                        type="link"
                        href="https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp?Lang=en"
                        target="_blank"
                    >
                        External EU TARIC Consultation Tool
                    </Button>
                </div>
            </Content>
        </Drawer>
    );
};
export default TaricCodeDrawer;
