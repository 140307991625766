import { FC, useEffect, useState } from 'react';
import { FilterResult } from './filter-result';
import FilterCard from './components/FilterCard';
import { useLocation } from 'react-router-dom';
import Button from 'components/ui/base/button/Button';
import { DownOutlined } from '@ant-design/icons';
import { listIndividuals } from 'store/individuals/client';
import { useRequestPromise } from 'hooks/useRequest';

interface Props {
    filterState: FilterResult[];
    onFilterChange: (filters: FilterResult[]) => void;
}

export const Filter: FC<Props> = ({ filterState, onFilterChange }) => {
    const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);

    const location = useLocation();
    useEffect(() => {
        setShowFilterDropdown(false);
    }, [location.pathname]);

    const { data: authors } = useRequestPromise(listIndividuals, { args: [{ page: 0, size: 200 }] });

    return (
        <>
            <Button size="large" onClick={() => setShowFilterDropdown((s: boolean) => !s)} style={{ borderRadius: 5 }}>
                Filter <DownOutlined />
            </Button>
            {showFilterDropdown && (
                <FilterCard
                    filterState={filterState}
                    handleClose={() => setShowFilterDropdown(false)}
                    onFilter={(filterChangeParams) => {
                        const filterChangeParamsTypeIndex = filterState.findIndex(
                            (f) => f.type === filterChangeParams.type
                        );

                        if (filterChangeParamsTypeIndex === -1) {
                            // If the filter doesn't exist in the state, add it.
                            onFilterChange([...filterState, filterChangeParams]);
                        } else {
                            // If the filter exists, update it.
                            const newFilters = [...filterState];
                            newFilters[filterChangeParamsTypeIndex] = { ...filterChangeParams };
                            onFilterChange(newFilters);
                        }
                    }}
                    authors={authors?.list}
                />
            )}
        </>
    );
};
export default Filter;
