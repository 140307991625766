import { Reducer, useReducer } from 'react';

export enum TaricEvalStatus {
    BASIC_INFO_RECEIVED = 'BASIC_INFO_RECEIVED',
    TARIC_SUGGESTIONS_RECEIVED = 'TARIC_SUGGESTIONS_RECEIVED',
    TEE_UPDATED_WITH_SUBMITTED_DATA = 'TEE_UPDATED_WITH_SUBMITTED_DATA',
    FAILED = 'FAILED',
    ACCEPTED = 'ACCEPTED',
}

type State = {
    status: TaricEvalStatus | null;
    payload: any | null;
    items: any[] | null;
};

type Action =
    | { type: 'UPDATE_STATUS'; status: State['status'] }
    | { type: 'UPDATE_PAYLOAD'; payload: State['payload'] }
    | {
          type: 'SET_DECLARATION';
          payload: State['payload'];
          items: State['items'];
      };

const initialState: State = {
    status: null,
    payload: null,
    items: null,
};

const reducer: Reducer<State, Action> = (state, action): State => {
    switch (action.type) {
        case 'UPDATE_STATUS':
            return {
                ...state,
                status: action.status,
            };
        case 'UPDATE_PAYLOAD':
            return {
                ...state,
                payload: action.payload,
            };
        case 'SET_DECLARATION':
            return {
                ...state,
                payload: action.payload,
                items: action.items,
            };
    }
};

const useInvoiceReducer = () => useReducer(reducer, initialState);

export default useInvoiceReducer;
