import { FC } from 'react';

interface ImporterIconProps {
    color?: string;
}

const ImporterIcon: FC<ImporterIconProps> = ({ color = 'black' }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 8L2 8M2 8L5.5 4.5M2 8L5.5 11.5" stroke={color} />
            <path d="M10 2L14 2L14 14L10 14" stroke={color} />
        </svg>
    );
};

export default ImporterIcon;
