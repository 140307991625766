import { FormikProps, FormikProvider } from 'formik';
import useCodelists from '../../../../hooks/useCodelists';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import NewFormCard, { FormCardContainer } from '../../common/cards/NewFormCard';
import { getFormikProps, getFullCodelistValue } from '../../utils/form-utils';
import { validators } from '../../uk/export/validations/validations';
import CountrySelect from '../../../../components/ui/composed/declarations/CountrySelect';
import addPathPrefix from '../../../../utils/addPathPrefix';
import PartiesCard from '../../common/parties/PartiesCard';
import MultipleItemsCard from '../../common/MultipleItemsCard';
import MultipleDeclarationField from '../../common/MultipleDeclarationField';
import DeclarationNumberInput from '../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useHideEntities from '../../../../hooks/useHideEntities';
import useDeclarations from '../../../../hooks/useDeclarations';
import { pick } from 'lodash';

interface Props {
    formik: FormikProps<any>;
}

const IrelandNctsProductsSection = ({ formik }: Props) => {
    const { nctsCodelists } = useCodelists();
    const { isFormType } = useHideEntities();
    const { declaration } = useDeclarations();

    const houseConsignmentItemAssociationCodelist = declaration?.ieNctsDeclaration?.consignment?.houseConsignment.map(
        ({ id }, index) => ({ id: `House Consignment ${index + 1}`, value: id })
    );

    const handleHouseConsignmentItemAssociation = (_: any, option: any) => {
        const houseConsignmentValues = declaration?.ieNctsDeclaration?.consignment?.houseConsignment[option?.key];

        if (!houseConsignmentValues) return;

        const valuesToPopulate = pick(houseConsignmentValues, [
            'countryOfDispatch',
            'referenceNumberUCR',
            'consignee',
            'additionalSupplyChainActor',
            'previousDocument',
            'supportingDocument',
            'transportDocument',
            'additionalReference',
            'additionalInformation',
            'transportCharges',
        ]);

        formik.setValues({ ...valuesToPopulate, houseConsignmentId: houseConsignmentValues.id });
    };

    return (
        <FormikProvider value={formik}>
            <NewFormCard title="Consignment Item">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Consignment Item"
                        {...getFormikProps('houseConsignmentId', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                        selectOptions={houseConsignmentItemAssociationCodelist}
                        hideCodelistMenu
                        onChange={handleHouseConsignmentItemAssociation}
                    />
                </FormCardContainer>
            </NewFormCard>
            <NewFormCard title="Goods Information">
                <FormCardContainer>
                    <DeclarationInput
                        label="CUS code"
                        {...getFormikProps('commodity.cusCode', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationSelect
                        required
                        label="Harmonized System sub-heading code"
                        {...getFormikProps('commodity.commodityCode.harmonizedSystemSubHeadingCode', formik)}
                        codeValidation={[validators.exact(6)]}
                        condensed
                        selectOptions={nctsCodelists?.hScode}
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationInput
                        label="Combined nomenclature code"
                        {...getFormikProps('commodity.commodityCode.combinedNomenclatureCode', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationSelect
                        label="Declaration type"
                        {...getFormikProps('declarationType', formik)}
                        codeValidation={[validators.maxLength(5)]}
                        condensed
                        selectOptions={nctsCodelists?.declarationTypeItemLevel}
                        hidden={isFormType(['D4'])}
                    />
                    <CountrySelect
                        label="Country of dispatch"
                        {...getFormikProps('countryOfDispatch', formik)}
                        condensed
                        hidden={isFormType(['D2', 'D3', 'D4'])}
                    />
                    <CountrySelect
                        label="Country of destination"
                        {...getFormikProps('countryOfDestination', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationInput
                        label="Reference number UCR"
                        {...getFormikProps('referenceNumberUCR', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationInput
                        required
                        label="Description of goods"
                        {...getFormikProps('commodity.descriptionOfGoods', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <MultipleDeclarationField parent="commodity.dangerousGoods" name="unNumber">
                        {(fieldProps, controls) => (
                            <DeclarationSelect
                                label="Dangerous Goods - UN Number"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                selectOptions={nctsCodelists?.unDangerousGoodsCode}
                                codeValidation={[validators.exact(6)]}
                            />
                        )}
                    </MultipleDeclarationField>
                    <DeclarationInput
                        label="Gross mass"
                        {...getFormikProps('commodity.goodsMeasure.grossMass', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationInput
                        required
                        label="Net mass"
                        {...getFormikProps('commodity.goodsMeasure.netMass', formik)}
                        condensed
                        hidden={isFormType(['D2', 'D3', 'D4'])}
                    />
                    <DeclarationInput
                        label="Supplementary units"
                        {...getFormikProps('commodity.goodsMeasure.supplementaryUnits', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        label="Method of payment"
                        {...getFormikProps('transportCharges.methodOfPayment', formik)}
                        condensed
                        selectOptions={nctsCodelists?.transportChargesMethodOfPayment}
                        codeValidation={[validators.exact(1)]}
                    />
                </FormCardContainer>
            </NewFormCard>

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'consignee',
                        header: 'Consignee',
                        refNumber: undefined,
                        hidden: isFormType(['D4']),
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.city',
                        country: 'address.country',
                        name: 'name',
                        postCode: 'address.postcode',
                        streetAndNumber: 'address.streetAndNumber',
                    },
                    eori: 'identificationNumber',
                    contactPerson: {
                        name: 'contactPerson.name',
                        phoneNumber: 'contactPerson.phoneNumber',
                        email: 'contactPerson.emailAddress',
                    },
                    additional: {
                        representativeStatus: 'status',
                    },
                }}
                condensed
            />

            <MultipleItemsCard
                title="Additional Supply Chain Actor"
                path="additionalSupplyChainActor"
                initialValues={{ role: '', identificationNumber: '' }}
                list={(list) => [
                    {
                        field: 'Role',
                        value: getFullCodelistValue(list.role, nctsCodelists?.additionalSupplyChainActorRoleCode),
                    },
                    {
                        field: 'Identification number',
                        value: list.identificationNumber,
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Role"
                            {...getFormikProps(addPathPrefix(path, 'role'), formik)}
                            required
                            condensed
                            selectOptions={nctsCodelists?.additionalSupplyChainActorRoleCode}
                            codeValidation={[validators.maxLength(3)]}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            required
                            label="Identification number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Packaging"
                path="packaging"
                initialValues={{ typeOfPackages: '', numberOfPackages: '', shippingMarks: '' }}
                list={(list) => [
                    {
                        field: 'Type of Packages',
                        value: getFullCodelistValue(list.typeOfPackages, nctsCodelists?.kindOfPackages),
                    },
                    { field: 'Number of Packages', value: list.numberOfPackages },
                    { field: 'Shipping Marks', value: list.shippingMarks },
                ]}
                condensed
                hidden={isFormType(['D4'])}
                required
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            required
                            label="Type of Packages"
                            {...getFormikProps(addPathPrefix(path, 'typeOfPackages'), formik)}
                            codeValidation={[validators.exact(2)]}
                            condensed
                            selectOptions={nctsCodelists?.kindOfPackages}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationNumberInput
                            label="Number of Packages"
                            {...getFormikProps(addPathPrefix(path, 'numberOfPackages'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Shipping Marks"
                            {...getFormikProps(addPathPrefix(path, 'shippingMarks'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Previous Document"
                path="previousDocument"
                initialValues={{
                    referenceNumber: '',
                    type: '',
                    typeOfPackages: '',
                    numberOfPackages: '',
                    measurementUnitAndQualifier: '',
                    quantity: '',
                    goodsItemNumber: '',
                    complementOfInformation: '',
                }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, nctsCodelists?.previousDocumentType),
                    },
                    {
                        field: 'Type of Packages',
                        value: getFullCodelistValue(list.typeOfPackages, nctsCodelists?.kindOfPackages),
                    },
                    { field: 'Number of Packages', value: list.numberOfPackages },
                    {
                        field: 'Measurement Unit and Qualifier',
                        value: getFullCodelistValue(list.measurementUnitAndQualifier, nctsCodelists?.unit),
                    },
                    { field: 'Quantity', value: list.quantity },
                    { field: 'Goods Item Number', value: list.goodsItemNumber },
                    { field: 'Complement Of Information', value: list.complementOfInformation },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={nctsCodelists?.previousDocumentType}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            label="Type of Packages"
                            {...getFormikProps(addPathPrefix(path, 'typeOfPackages'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={nctsCodelists?.kindOfPackages}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationNumberInput
                            label="Number of Packages"
                            {...getFormikProps(addPathPrefix(path, 'numberOfPackages'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            label="Measurement Unit and Qualifier"
                            {...getFormikProps(addPathPrefix(path, 'measurementUnitAndQualifier'), formik)}
                            codeValidation={[validators.maxLength(4)]}
                            condensed
                            selectOptions={nctsCodelists?.unit}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationNumberInput
                            label="Quantity"
                            {...getFormikProps(addPathPrefix(path, 'quantity'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationNumberInput
                            label="Goods Item Number"
                            {...getFormikProps(addPathPrefix(path, 'goodsItemNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Complement of information"
                            {...getFormikProps(addPathPrefix(path, 'complementOfInformation'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Supporting Document"
                path="supportingDocument"
                initialValues={{
                    referenceNumber: '',
                    type: '',
                    documentLineItemNumber: '',
                    complementOfInformation: '',
                }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, nctsCodelists?.supportingDocumentType),
                    },
                    { field: 'Complement of information', value: list.complementOfInformation },
                    { field: 'Document Line Item Number', value: list.documentLineItemNumber },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={nctsCodelists?.supportingDocumentType}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Complement of information"
                            {...getFormikProps(addPathPrefix(path, 'complementOfInformation'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationNumberInput
                            label="Document Line Item Number"
                            {...getFormikProps(addPathPrefix(path, 'documentLineItemNumber'), formik)}
                            condensed
                            hidden={isFormType(['D2', 'D3', 'D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Transport Document"
                path="transportDocument"
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, nctsCodelists?.transportDocumentType),
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            required
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            required
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={nctsCodelists?.transportDocumentType}
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Reference"
                path="additionalReference"
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, nctsCodelists?.additionalReference),
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={nctsCodelists?.additionalReference}
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Information"
                path="additionalInformation"
                initialValues={{ code: '', text: '' }}
                list={(list) => [
                    {
                        field: 'Code',
                        value: getFullCodelistValue(list.code, nctsCodelists?.additionalInformation),
                    },
                    { field: 'Text', value: list.text },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Code"
                            {...getFormikProps(addPathPrefix(path, 'code'), formik)}
                            required
                            codeValidation={[validators.exact(5)]}
                            condensed
                            selectOptions={nctsCodelists?.additionalInformation}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Text"
                            {...getFormikProps(addPathPrefix(path, 'text'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
        </FormikProvider>
    );
};

export default IrelandNctsProductsSection;
