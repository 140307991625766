import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'components/ui/base/button/Button';
import { DownOutlined } from '@ant-design/icons';
import { FilterCard } from './components/Filter.styles';
import FilterOptions from './components/FilterOptions';
import styled from 'styled-components';
import { colors } from 'theme';

const FilterBody = styled.div`
    padding: 0 1.6rem;
`;
const FilterHeader = styled.div`
    padding: 0 1.6rem 1.6rem;
    border-bottom: 1px solid ${colors.darkGrey2};
    margin-bottom: 1.6rem;
    font-size: 16px;
`;

const FilterDropdown = (props: {
    filters: (
        state: Record<string, any>,
        setState: (name: string, value: any) => void
    ) => { label: string; content: ReactNode }[];
}) => {
    const [show, setShow] = useState(false);
    const [filter, setFilter] = useState<string | null>(null);

    const [state, setState] = useState<Record<string, any>>({});

    const cardRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const closeDropdown = (e: any) =>
            cardRef.current !== null && !cardRef.current?.contains(e.target) && setShow(false);

        window.addEventListener('mousedown', closeDropdown);
        return () => window.removeEventListener('mouseDown', closeDropdown);
    }, [cardRef]);

    const updateState = (name: string, value: any) => {
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleToggle = () => {
        setFilter(null);
        setShow((prev) => !prev);
    };

    const handleSelect = (value: string) => {
        setFilter(value);
    };

    const filters = useMemo(() => props.filters(state, updateState), [props, state]);

    return (
        <div>
            <Button size="large" onClick={() => handleToggle()} style={{ borderRadius: 5 }}>
                Filter <DownOutlined />
            </Button>
            <FilterCard ref={cardRef as any} data-testid={'filter-card'} style={{ display: show ? 'block' : 'none' }}>
                {!filter ? (
                    <FilterOptions options={filters.map((filter) => filter.label)} onSelect={handleSelect} />
                ) : (
                    <>
                        <FilterHeader>{filter}</FilterHeader>
                        <FilterBody>{filters.find((f) => f.label === filter)?.content}</FilterBody>
                    </>
                )}
            </FilterCard>
        </div>
    );
};

export default FilterDropdown;
