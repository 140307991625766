import Table from 'components/ui/base/table/Table';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useCallback, useMemo, useReducer } from 'react';
import { getConversationId, HistoryUtils } from './historyUtils';
import { HistoryViewContainer, HistoryStatusContainer } from './components/styledComponents';
import { H5 } from 'components/ui/base/typography';
import { orderBy } from 'lodash';
import { DeclarationNotificationsDrawer } from 'views/declarations/common/declaration-errors-drawer/DeclarationNotificationsDrawerButton';
import { AesNotification } from '../../../../store/declarations/ireland/export-declaration';

export type Column = {
    key?: string;
    date: string;
    user: string;
    action: string;
    description?: string;
    notificationType?: string;
    errors?: Object;
    conversationId?: string[];
    rawMessage?: string;
};
interface ErrorsDrawer {
    drawerOpen: boolean;
    errors: Object | undefined;
    showRawData: boolean;
}

const DeclarationHistoryTable: FC<{}> = () => {
    const { declarationHistory, declaration } = useDeclarations();

    const [errorsDrawer, setErrorsDrawer] = useReducer(
        (initialState: ErrorsDrawer, newState: Partial<ErrorsDrawer>) => ({
            ...initialState,
            ...newState,
        }),
        { drawerOpen: false, errors: undefined, showRawData: false }
    );

    const getAesDescription = useCallback(
        (notification: AesNotification) => {
            if (!declaration?.ieExportDeclaration) return '';

            if (!notification?.additionalProperties?.messageName) return notification.messageType;
            // If external system has problems, additionalProperties will be null

            return `${notification.messageType} - ${notification.additionalProperties.messageName}`;
        },
        [declaration?.ieExportDeclaration]
    );

    const currentKeys = useMemo(() => {
        let keys: Column = {} as Column;

        if (
            declaration?.irelandImportDeclaration ||
            declaration?.irelandH7ImportDeclaration ||
            declaration?.entrySummaryDeclaration
        ) {
            keys = {
                date: 'notificationDate',
                action: 'type',
                description: 'message',
                user: 'user',
                errors: 'errors',
                conversationId: ['transactionId'],
                rawMessage: 'rawMessage',
            };
        } else if (declaration?.cdsDeclaration) {
            keys = {
                date: 'issueDateTime',
                action: 'statusMessage',
                notificationType: 'functionCode',
                description: 'message',
                user: 'user',
                errors: 'errors',
                rawMessage: 'rawMessage',
                conversationId: ['transactionId', 'conversationId'],
            };
        } else if (
            declaration?.ieExportDeclaration ||
            declaration?.gvmsDeclaration ||
            declaration?.ieArrivalAtExitDeclaration ||
            declaration?.ieNctsDeclaration ||
            declaration?.ukNctsDeclaration
        ) {
            keys = {
                date: 'createdAt',
                action: 'type',
                description: 'messageType',
                user: 'user',
                errors: 'errors',
                rawMessage: 'rawMessage',
                conversationId: ['message.messageId', 'transactionId'],
            };
        }

        return keys;
    }, [
        declaration?.ieArrivalAtExitDeclaration,
        declaration?.cdsDeclaration,
        declaration?.entrySummaryDeclaration,
        declaration?.irelandH7ImportDeclaration,
        declaration?.irelandImportDeclaration,
        declaration?.ieExportDeclaration,
        declaration?.gvmsDeclaration,
        declaration?.ieNctsDeclaration,
        declaration?.ukNctsDeclaration,
    ]);

    const history = useMemo(() => {
        const statusHistory =
            declaration?.irelandImportDeclaration?.notifications ??
            declaration?.irelandH7ImportDeclaration?.notifications ??
            declaration?.entrySummaryDeclaration?.notifications ??
            declaration?.cdsDeclaration?.notifications ??
            declaration?.ieExportDeclaration?.notifications ??
            declaration?.gvmsDeclaration?.notifications ??
            declaration?.ieArrivalAtExitDeclaration?.notifications ??
            declaration?.ieNctsDeclaration?.notifications ??
            declaration?.ukNctsDeclaration?.notifications ??
            [];

        const _formHistory = declarationHistory ?? [];
        let formHistory: any[] = [];
        formHistory = _formHistory.map((h, i) => {
            const conversationId = getConversationId(h, currentKeys.conversationId);
            return {
                user: h.creatorName,
                key: `form-history-${i}`,
                [currentKeys.action]: h.type,
                [currentKeys.date]: h.createdAt,
                ...(conversationId.key ? ({ [conversationId.key]: conversationId.value } as any) : {}),
            };
        }) as Column[];

        const history = [
            ...statusHistory.map((h, i) => ({
                ...h,
                key: `status-history-${i}`,
                user: 'Customs',
                errors: h,
                ...(declaration?.ieExportDeclaration && { messageType: getAesDescription(h as AesNotification) }),
            })),
            ...formHistory,
        ];

        return orderBy(history, currentKeys.date, 'desc');
    }, [
        currentKeys.action,
        currentKeys.conversationId,
        currentKeys.date,
        declaration?.cdsDeclaration?.notifications,
        declaration?.entrySummaryDeclaration?.notifications,
        declaration?.gvmsDeclaration?.notifications,
        declaration?.ieArrivalAtExitDeclaration?.notifications,
        declaration?.ieExportDeclaration,
        declaration?.ieNctsDeclaration?.notifications,
        declaration?.irelandH7ImportDeclaration?.notifications,
        declaration?.irelandImportDeclaration?.notifications,
        declaration?.ukNctsDeclaration?.notifications,
        declarationHistory,
        getAesDescription,
    ]);

    const openErrorDrawer = ({ errors, showRawData }: { errors: Object; showRawData: boolean }) => {
        setErrorsDrawer({ errors, drawerOpen: true, showRawData });
    };
    const closeErrorDrawer = () => {
        setErrorsDrawer({ errors: undefined, drawerOpen: false });
    };

    const historyColumns = useMemo(
        () => HistoryUtils.columns.statusHistory(currentKeys, openErrorDrawer, declaration),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentKeys]
    );

    return (
        <HistoryViewContainer>
            <HistoryStatusContainer>
                <H5>Status History</H5>
                <Table
                    pagination={false}
                    columns={historyColumns}
                    dataSource={history}
                    onRow={() => {
                        return {
                            'data-testid': `status-history-table-row`,
                            onClick: () => {},
                        };
                    }}
                />
            </HistoryStatusContainer>
            <DeclarationNotificationsDrawer
                visible={errorsDrawer.drawerOpen}
                onClose={closeErrorDrawer}
                declaration={errorsDrawer.errors as any}
                showRawData={errorsDrawer.showRawData}
            />
        </HistoryViewContainer>
    );
};

export default DeclarationHistoryTable;
