import { Modal, ModalProps, Form, Input, notification } from 'antd';
import { H5 } from 'components/ui/base/typography';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Declaration } from '../../../store/declarations/declaration';
import { DeclarationStatus } from '../../../store/declarations/enums/common/declaration-status';
import { FooterButton } from '../sections/view-only/ViewOnlyTab';
import { getDeclarationPayload } from '../utils/declaration-utils';
import { HollowButton } from './box44/Box44';
import { InfoText } from './DeclarationInformation';

const PresentationNotificationModalButton = ({
    declaration,
    declarationId,
    importPresentationNotificationRequest,
}: {
    declaration: Declaration | undefined;
    declarationId: string | undefined;
    importPresentationNotificationRequest: (
        declarationId: string,
        type: string,
        data: Record<string, any>
    ) => Promise<Declaration>;
}) => {
    const { t } = useTranslation('common');
    const [presentationNotificationModalVisible, setPresentationNotificationModalVisible] = useState<boolean>(false);
    const showPresentationNotificationModal = useCallback(() => setPresentationNotificationModalVisible(true), []);
    const hidePresentationNotificationModal = useCallback(() => setPresentationNotificationModalVisible(false), []);

    const currentDeclaration = declaration ? getDeclarationPayload(declaration) : undefined;

    const showPresentationNotification = useMemo(
        () =>
            declaration?.status === DeclarationStatus.REGISTERED &&
            currentDeclaration &&
            'additionalDeclarationType' in currentDeclaration &&
            currentDeclaration.additionalDeclarationType === 'D',
        [currentDeclaration, declaration?.status]
    );

    const handlePresentationNotification = useCallback(
        (data?: { presentationCustomsOffice?: string }) => {
            if (!declarationId) return Promise.reject();

            let message: string;
            if (!data?.presentationCustomsOffice) {
                message = 'Missing Presentation Person!';
                notification.error({ message });
                return Promise.reject({ message });
            }

            const type = declaration?.irelandH7ImportDeclaration ? 'h7' : '';
            return importPresentationNotificationRequest(declarationId, type, data)
                .then((data: Declaration) => {
                    message = 'Presentation Notification sent successfully!';
                    notification.success({ message });
                    return { ...data, message };
                })
                .catch((error: any) => {
                    message = 'An error occurred while sending presentation notification!';
                    notification.error({ message });
                    return { ...error, message };
                })
                .finally(() => {
                    hidePresentationNotificationModal();
                });
        },
        [
            declaration?.irelandH7ImportDeclaration,
            importPresentationNotificationRequest,
            declarationId,
            hidePresentationNotificationModal,
        ]
    );

    return (
        <>
            {showPresentationNotification && (
                <FooterButton onClick={showPresentationNotificationModal}>
                    {t('footer.presentation_notification')}
                </FooterButton>
            )}
            <PresentationNotificationModal
                visible={presentationNotificationModalVisible}
                onClose={hidePresentationNotificationModal}
                handlePresentationNotification={handlePresentationNotification}
            />
        </>
    );
};

export default PresentationNotificationModalButton;
interface Props extends ModalProps {
    onClose: () => void;
    handlePresentationNotification: (data?: { presentationCustomsOffice?: string }) => Promise<void>;
}

const PresentationNotificationModal = ({
    onClose,
    handlePresentationNotification,
    ...modalProps
}: Props): ReactElement => {
    return (
        <Modal
            title={<H5>Presentation Notification</H5>}
            onCancel={onClose}
            footer={[
                <HollowButton onClick={onClose} size="large">
                    Close
                </HollowButton>,
                <HollowButton form="presentationNotification" htmlType="submit" inverted size="large">
                    Send
                </HollowButton>,
            ]}
            {...modalProps}
        >
            <InfoText>Please populate Presentation Person and send Presentation Notification.</InfoText>
            <Form
                style={{ marginTop: '1rem', height: 100 }}
                id="presentationNotification"
                layout="vertical"
                onFinish={handlePresentationNotification}
            >
                <Form.Item
                    name="presentationCustomsOffice"
                    label="Presentation Person:"
                    rules={[
                        {
                            required: true,
                            message: 'Presentation Person must be at least 8 characters.',
                            type: 'string',
                            min: 8,
                        },
                    ]}
                >
                    <Input style={{ resize: 'none' }} placeholder="Enter presentation person" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
