import { SearchOutlined as SearchOutlinedAnt } from '@ant-design/icons';
import { Col, Input as AntInput } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';
import { TextSmall } from '../typography';

export const StyledAutoComplete = styled.div`
    .ant-select {
        width: 100%;
        background: ${colors.lightGrey};
        border: 1px solid ${colors.lightGrey};
        box-sizing: border-box;
        border-radius: 4px;
        &:hover {
            background: ${colors.white};
            border: 1px solid ${colors.purpleBlue1};
            border-radius: 4px;
        }

        &.disabled {
            cursor: not-allowed;
            color: ${colors.darkGrey2};
        }
        .ant-select-clear {
            color: ${colors.purpleBlue};
            height: 2.1rem;
            width: 2.1rem;
            font-size: 2.1rem;
            margin-top: -1rem;
        }
    }
`;

export const SearchOutlined = styled(SearchOutlinedAnt)`
    color: ${colors.purpleBlue};
    margin-left: 1rem;
    margin-right: 1rem;
`;

export const Input = styled(AntInput)`
    height: 4rem;
    border-radius: 8px;
    background: ${colors.lightGrey};
`;

export const OptionText = styled(TextSmall)`
    width: 12rem;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
`;

export const OptionsCol = styled(Col)`
    width: 14rem;
`;
