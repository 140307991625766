import useTaricCodes from 'hooks/useTaricCodes';
import { FC, useEffect, useState } from 'react';
import { TaricCode, TaricCodeResponse, TaricCodesType } from 'store/taric-codes/taric-code';
import { TaricDetailsType } from '../../TaricCodesCard';
import ListTaricCodes from './ListTaricCodes';
import ListTaricMeasurement from './ListTaricMeasurement';
interface Props {
    taricCodesDetails: TaricCode[];
    back: () => void;
    taricCodeType: TaricCodesType;
    country: string;
    selectedCommodityCode: (code: string, duty?: string, vat?: string) => void;
    detailsType: TaricDetailsType;
    searching: boolean;
    searchQuery: string;
}
const ExpandedSection: FC<Props> = ({
    taricCodesDetails,
    back,
    taricCodeType,
    country,
    selectedCommodityCode,
    detailsType,
    searching,
    searchQuery,
}) => {
    const { getImportTaricCodeMeasurement, getExportTaricCodeMeasurement, getGeneralTaricCodeMeasurement } =
        useTaricCodes();
    const [selectedCode, setSelectedCode] = useState<Pick<TaricCodeResponse, 'code' | 'description'>>();

    useEffect(() => {
        if (searching) {
            setSelectedCode(undefined);
        }
    }, [searching]);

    useEffect(() => {
        if (selectedCode) {
            if (detailsType === TaricDetailsType.IMPORT) {
                getImportTaricCodeMeasurement(taricCodeType, selectedCode.code, country);
            } else if (detailsType === TaricDetailsType.EXPORT) {
                getExportTaricCodeMeasurement(taricCodeType, selectedCode.code, country);
            } else {
                getGeneralTaricCodeMeasurement(taricCodeType, selectedCode.code, country);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCode, country, taricCodeType, detailsType]);

    return (
        <>
            {!selectedCode ? (
                <ListTaricCodes
                    back={back}
                    taricCodesDetails={taricCodesDetails}
                    searchQuery={searchQuery}
                    setSelectedCode={setSelectedCode}
                />
            ) : (
                <ListTaricMeasurement
                    detailsType={detailsType}
                    setSelectedCode={setSelectedCode}
                    selectedCommodityCode={selectedCommodityCode}
                    selectedCode={selectedCode}
                />
            )}
        </>
    );
};
export default ExpandedSection;
