import { useParams } from 'react-router-dom';
import useDeclarationFormErrors from './useDeclarationFormErrors';
import useProductTemplateFormErrors from './useProductTemplateFormErrors';
import useProducts from 'hooks/useProducts';
import { useMemo } from 'react';
import { ErrorType } from 'store/declaration-form-errors/ParsedForm';

const useGetErrors = () => {
    const { declarationErrors } = useDeclarationFormErrors();
    const { productTemplateErrors } = useProductTemplateFormErrors();
    const { productId, type: productTemplateType } = useParams<{ productId: string; type: string }>();
    const { products } = useProducts();

    const productIndex = useMemo(
        () => (products?.list as any).findIndex(({ id }: { id: string }) => id === productId) + 1,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [productId]
    );

    const errors = useMemo<ErrorType[] | undefined>(() => {
        if (productId) {
            return declarationErrors.items[productIndex]?.errors;
        } else if (productTemplateType) {
            return productTemplateErrors.template;
        } else {
            return declarationErrors.masterDetails;
        }
    }, [
        declarationErrors.items,
        declarationErrors.masterDetails,
        productTemplateErrors.template,
        productTemplateType,
        productId,
        productIndex,
    ]);

    return {
        errors,
    };
};

export default useGetErrors;
