import { useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { H5Style } from '../../../../../components/ui/base/typography/Typography.styles';
import FormInput from '../../../../../components/ui/composed/declarations/formInput';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormTextArea from '../../../../../components/ui/composed/formTextArea/FormTextArea';
import { FormCardContainer } from '../../../common/cards/NewFormCard';
import * as Yup from 'yup';
import { getRequiredMessage } from '../../../utils/validation-utils';
import useCodelists from '../../../../../hooks/useCodelists';
import { HollowButton } from '../../../common/box44/Box44';
import { DocumentFormButtonsDiv } from '../CustomerDocuments.styles';
import { CloseOutlined, CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import {
    createDocumentAis,
    updateDocumentAis,
    createDocumentAes,
    updateDocumentAes,
    createDocumentNcts,
    updateDocumentNcts,
} from '../../../../../store/file-upload/client';
import { deleteFileCms, updateCmsFile } from '../../../../../store/documents/client';
import { CmsFileAndFormAction, DeclarationDocument } from '../types';
import CustomModal from 'components/ui/base/modal/Modal';
import { H5 } from '../../../../../components/ui/base/typography';
import { viewDocumentAction } from './DocumentsTable';
import { message } from 'antd';
import useFormUtils from '../../../../../hooks/useFormUtils';

const documentFormValidationSchemaCommon = {
    filename: Yup.string().required(getRequiredMessage('fileName')),
    documentType: Yup.string().required(getRequiredMessage('documentType')),
};

const documentFormValidationSchemaAis = {
    documentIdentifier: Yup.string().required(getRequiredMessage('documentIdentifier')),
    documentReference: Yup.string().required(getRequiredMessage('documentReference')),
    documentDescription: Yup.string().required(getRequiredMessage('documentDescription')),
};

const documentFormValidationSchemaAes = {
    description: Yup.string().required(getRequiredMessage('description')),
    documentComplementaryInformation: Yup.string().required(getRequiredMessage('documentComplementaryInformation')),
};

const documentFormValidationSchemaNcts = {
    documentComplementaryInformation: Yup.string().required(getRequiredMessage('documentComplementaryInformation')),
    description: Yup.string().required(getRequiredMessage('description')),
};

interface Props {
    declarationId: string | undefined;
    listCmsFiles: Function;
    cmsFileAndFormAction: CmsFileAndFormAction;
    declarationDocument: DeclarationDocument | undefined;
    cancelForm: Function;
    addDeclarationDocument: (newDoc: DeclarationDocument) => void;
    updateDeclarationDocument: (updatedDoc: DeclarationDocument) => void;
}

const DocumentForm = ({
    cmsFileAndFormAction,
    cancelForm,
    declarationId,
    declarationDocument,
    listCmsFiles,
    addDeclarationDocument,
    updateDeclarationDocument,
}: Props) => {
    const { t } = useTranslation('form');
    const { aisCodelists, aesCodelists, nctsCodelists } = useCodelists();
    const [isDeleteCmsFileModalVisible, setIsDeleteCmsFileModalVisible] = useState<boolean>(false);
    const { isAes, isAis, isNcts } = useFormUtils();

    const formikInitialValues = useMemo(
        () => ({
            // uploaded file / CMS requests values
            fileSize: cmsFileAndFormAction.file?.fileSize,
            filename: cmsFileAndFormAction.file?.filename,
            documentType: cmsFileAndFormAction.file?.documentType,
            link: cmsFileAndFormAction.file?.link,
            // uploaded document / AIS/AES/NCTS requests values
            ...(isAes || isNcts
                ? {
                      description: declarationDocument?.fileInfo.description,
                      documentComplementaryInformation: declarationDocument?.fileInfo.documentComplementaryInformation,
                  }
                : {
                      documentReference: declarationDocument?.fileInfo.documentReference,
                      documentIdentifier: declarationDocument?.fileInfo.documentIdentifier,
                      documentDescription: declarationDocument?.fileInfo.documentDescription,
                  }),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isAes, isNcts]
    );

    const formik = useFormik<any>({
        initialValues: formikInitialValues ?? {},
        validateOnMount: true,
        validationSchema: Yup.object().shape({
            ...documentFormValidationSchemaCommon,
            ...(isAes
                ? documentFormValidationSchemaAes
                : isAis
                ? documentFormValidationSchemaAis
                : documentFormValidationSchemaNcts),
        }),
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const handleSubmit = useCallback(async () => {
        const errors = await formik.validateForm();
        formik.setTouched(errors as any);

        if (!isEmpty(errors) || !declarationId) return;

        const documentBody = {
            fileId: cmsFileAndFormAction.file?.id,
            fileReference: formik.values.link,
            fileName: formik.values.filename,
            fileInfo: {
                documentType: formik.values.documentType,
                ...(isAes || isNcts
                    ? {
                          documentComplementaryInformation: formik.values.documentComplementaryInformation,
                          description: formik.values.description,
                      }
                    : {
                          documentReference: formik.values.documentReference,
                          documentIdentifier: formik.values.documentIdentifier,
                          documentDescription: formik.values.documentDescription,
                      }),
            },
        };

        const cmsFileBody = {
            id: cmsFileAndFormAction.file?.id,
            type: formik.values.documentType,
            filename: formik.values.filename,
            description: formik.values.documentDescription || formik.values.description,
        };

        if (cmsFileAndFormAction.action === 'upload') {
            const createDocument = isAes ? createDocumentAes : isAis ? createDocumentAis : createDocumentNcts;
            addDeclarationDocument(await createDocument(documentBody, declarationId));
        } else if (cmsFileAndFormAction.action === 'edit') {
            const updateDocument = isAes ? updateDocumentAes : isAis ? updateDocumentAis : updateDocumentNcts;
            updateDeclarationDocument(
                await updateDocument(
                    { ...documentBody, id: declarationDocument?.id, status: declarationDocument?.status },
                    declarationId
                )
            );
            message.success('File updated successfully.');
        }

        await updateCmsFile(cmsFileBody.id, cmsFileBody);
        cancelForm?.();
        listCmsFiles?.();
    }, [
        isAes,
        isAis,
        isNcts,
        formik,
        declarationId,
        cancelForm,
        listCmsFiles,
        cmsFileAndFormAction,
        declarationDocument,
        addDeclarationDocument,
        updateDeclarationDocument,
    ]);

    const refNumber = '2.3';
    const documentTypeCodelists =
        isAes && aesCodelists
            ? [
                  ...aesCodelists.transportDocumentType,
                  ...aesCodelists.supportingDocumentType,
                  ...aesCodelists.previousDocumentType,
              ]
            : isNcts && nctsCodelists
            ? [
                  ...(nctsCodelists.previousDocumentType ?? []),
                  ...(nctsCodelists.supportingDocumentType ?? []),
                  ...(nctsCodelists.transportDocumentType ?? []),
              ]
            : aisCodelists?.commonDocumentsType;
    return (
        <>
            <H5Style style={{ marginBottom: '1rem' }}>{t('customerDocuments.upload_document')}</H5Style>
            <FormCardContainer oneColumn>
                <FormInput
                    disabled
                    label={'File Size'}
                    fieldProps={formik.getFieldProps('fileSize')}
                    fieldMeta={formik.getFieldMeta('fileSize')}
                    condensed
                    refNumber={refNumber}
                    tooltip="File Size"
                />
                <FormInput
                    required
                    label={'File Name'}
                    fieldProps={formik.getFieldProps('filename')}
                    fieldMeta={formik.getFieldMeta('filename')}
                    condensed
                    refNumber={refNumber}
                    tooltip="File Name"
                />
                <FormSelect
                    label={'Document Type'}
                    fieldProps={formik.getFieldProps('documentType')}
                    fieldMeta={formik.getFieldMeta('documentType')}
                    condensed
                    required
                    refNumber={refNumber}
                    tooltip="Document Type"
                    selectOptions={documentTypeCodelists}
                    hideCodelistMenu
                />
                {isAes || isNcts ? (
                    <>
                        <FormInput
                            label={'Document Complementary Information'}
                            fieldProps={formik.getFieldProps('documentComplementaryInformation')}
                            fieldMeta={formik.getFieldMeta('documentComplementaryInformation')}
                            condensed
                            required
                            refNumber={refNumber}
                            tooltip="Document Complementary Information"
                        />
                        <FormTextArea
                            required
                            label={'Description'}
                            fieldProps={formik.getFieldProps('description')}
                            fieldMeta={formik.getFieldMeta('description')}
                            refNumber={refNumber}
                            tooltip="Description"
                            condensed
                            rows={5}
                        />
                    </>
                ) : (
                    <>
                        <FormInput
                            label={'Document Identifier'}
                            fieldProps={formik.getFieldProps('documentIdentifier')}
                            fieldMeta={formik.getFieldMeta('documentIdentifier')}
                            condensed
                            required
                            refNumber={refNumber}
                            tooltip="Document Identifier"
                        />
                        <FormTextArea
                            label={'Document Reference'}
                            fieldProps={formik.getFieldProps('documentReference')} //
                            fieldMeta={formik.getFieldMeta('documentReference')}
                            required
                            refNumber={refNumber}
                            tooltip="Document Reference"
                            condensed
                            rows={6}
                        />
                        <FormTextArea
                            required
                            label={'Document Description'}
                            fieldProps={formik.getFieldProps('documentDescription')}
                            fieldMeta={formik.getFieldMeta('documentDescription')}
                            refNumber={refNumber}
                            tooltip="Document Description"
                            condensed
                            rows={5}
                        />
                    </>
                )}
            </FormCardContainer>
            <DocumentFormButtonsDiv>
                <HollowButton size="small" onClick={() => viewDocumentAction(cmsFileAndFormAction.file)}>
                    Preview <EyeOutlined />
                </HollowButton>
                <HollowButton
                    size="small"
                    onClick={() =>
                        cmsFileAndFormAction.action === 'upload' ? setIsDeleteCmsFileModalVisible(true) : cancelForm?.()
                    }
                >
                    Cancel <CloseOutlined />
                </HollowButton>
                <HollowButton size="small" onClick={() => handleSubmit()}>
                    {cmsFileAndFormAction.action === 'upload' ? 'Add' : 'Update'} <CheckOutlined />
                </HollowButton>
            </DocumentFormButtonsDiv>
            <CustomModal
                title={<H5>You will delete the uploaded file.</H5>}
                centered
                visible={isDeleteCmsFileModalVisible}
                onOk={async () => {
                    await deleteFileCms(cmsFileAndFormAction.file?.id!);
                    cancelForm?.();
                }}
                onCancel={() => setIsDeleteCmsFileModalVisible(false)}
                width={762}
                contentText={'If you delete the uploaded file, you will lose all the information associated with it.'}
            />
        </>
    );
};

export default DocumentForm;
