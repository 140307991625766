import { DeleteOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import Avatar from 'components/ui/base/avatar/Avatar';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import TagsMenu from 'components/ui/composed/tagsMenu/TagsMenu';
import { ListPayload } from 'core/http/response';
import { format } from 'date-fns';
import useSession from 'hooks/useSession';
import { FC, useState } from 'react';
import {
    activeInactiveTypeLabel,
    Individual,
    IndividualSortParameter,
    IndividualType,
} from 'store/individuals/individual';
import { CommandButton } from '../../../components/ui/composed/dashboard/DashboardTable';
import { TableChangeParams } from '../../../utils/tableHelpers';

interface Props {
    data?: ListPayload<Individual>;
    onDelete: (ids: string[]) => void;
    onEdit: (id: string) => void;
    onDetails: (id: string) => void;
    onResendInvite: (id: string) => void;
    onChange: (params: TableChangeParams) => void;
    loading: boolean;
    handleUpdateRole: (role: IndividualType, id: string) => void;
    handleUpdateStatus: Function;
    roleSelectOptions: any;
}

const IndividualsTable: FC<Props> = ({
    data,
    onDelete,
    onEdit,
    onDetails,
    onChange,
    onResendInvite,
    loading,
    handleUpdateRole,
    handleUpdateStatus,
    roleSelectOptions,
}) => {
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const { userInfo } = useSession();

    const rowSelection = {
        onChange: (selectedRowKeys: unknown, selectedRows: Individual[]) => {
            const ids = selectedRows.map((c) => c.id);
            setDeleteIds(ids);
        },
    };

    const canResendInvite = (individual: Individual) => !individual.registered;

    const columns: ColumnsType<any> = [
        {
            title: 'User',
            dataIndex: IndividualSortParameter.FULL_NAME,
            key: IndividualSortParameter.FULL_NAME,
            align: 'left',
            onCell: (record: Individual, rowIndex) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Individual, index: number) =>
                record.fullName ? (
                    <span>
                        <Avatar name={record.fullName} />
                        {record.fullName}
                    </span>
                ) : (
                    <span>-</span>
                ),
            sorter: true,
        },
        {
            title: 'Role',
            dataIndex: IndividualSortParameter.TYPE,
            key: IndividualSortParameter.TYPE,
            align: 'left',
            render: (text, record: Individual) =>
                record.type ? (
                    <TagsMenu
                        disabled={record.id === userInfo?.individualId}
                        value={record.type}
                        updateValue={(value: IndividualType) => handleUpdateRole(value, record.id)}
                        selectOptions={roleSelectOptions}
                    />
                ) : (
                    <span>-</span>
                ),
            sorter: true,
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (text: string, record: Individual) =>
                record.type ? (
                    <TagsMenu
                        disabled={record.id === userInfo?.individualId}
                        value={record.active ? 'Active' : 'Inactive'}
                        updateValue={(value: string) => handleUpdateStatus(value, record.id)}
                        selectOptions={activeInactiveTypeLabel}
                    />
                ) : (
                    <span>-</span>
                ),
        },
        {
            title: 'Email',
            dataIndex: IndividualSortParameter.EMAIL,
            key: IndividualSortParameter.EMAIL,
            align: 'left',
            onCell: (record: Individual, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Individual, index: number) =>
                record.email ? <span>{record.email}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Last Login',
            dataIndex: IndividualSortParameter.LAST_LOGIN,
            key: IndividualSortParameter.LAST_LOGIN,
            align: 'left',
            onCell: (record: Individual, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Individual, index: number) =>
                record.lastLogin ? format(new Date(record.lastLogin), 'PP') : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Added on',
            dataIndex: IndividualSortParameter.CREATED_AT,
            key: IndividualSortParameter.CREATED_AT,
            align: 'left',
            onCell: (record: Individual, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Individual, index: number) =>
                record.createdAt ? format(new Date(record.createdAt), 'PP') : <span>-</span>,

            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            align: 'left',
            render: (text: string, record: Individual, index: number) => {
                return (
                    <>
                        {canResendInvite(record) && (
                            <CommandButton
                                data-testid={`resend-invite-${record.id}`}
                                tooltip="Resend Invite"
                                icon={<RedoOutlined />}
                                onClick={() => onResendInvite(record.id)}
                            />
                        )}
                        <CommandButton
                            data-testid={`edit-${record.id}`}
                            tooltip="Edit"
                            icon={<EditOutlined />}
                            onClick={() => onEdit(record.id)}
                        />
                        <CommandButton
                            data-testid={`delete-${record.id}`}
                            tooltip="Delete"
                            icon={<DeleteOutlined />}
                            onClick={() => onDelete([record.id])}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Table
                rowKey="id"
                style={{ marginTop: '2.5rem' }}
                columns={columns}
                dataSource={data?.list}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: true,
                    pageSize: data?.pageSize,
                    // position: ['bottomCenter'],
                    pageSizeOptions: ['10', '20', '50'],
                }}
                onChange={(pagination, _, sorter) => onChange({ pagination, sorter })}
                loading={loading}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};
export default IndividualsTable;
