import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, getFullCodelistValue, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../../common/MultipleItemsCard';
import { validators } from 'views/declarations/uk/export/validations/validations';

const TaxBoxCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{
                boxTaxType: '',
                boxAmount: '',
                boxTaxRate: '',
                boxTaxPayableAmount: '',
                boxTaxPaymentMethod: '',
            }}
            list={(list) => [
                {
                    field: t('boxCalcTaxes.boxTaxType'),
                    value: getFullCodelistValue(list.boxTaxType, aisCodelists?.taxType),
                },
                {
                    field: t('boxCalcTaxes.boxAmount'),
                    value: list.boxAmount,
                },
                {
                    field: t('boxCalcTaxes.boxTaxRate'),
                    value: list.boxTaxRate,
                },
                {
                    field: t('boxCalcTaxes.boxTaxPayableAmount'),
                    value: list.boxTaxPayableAmount,
                },
                {
                    field: t('boxCalcTaxes.boxTaxPaymentMethod'),
                    value: list.boxTaxPaymentMethod,
                },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={t('boxCalcTaxes.title')}
            condensed
            refNumber={['4.3', '4.4', '4.5', '4.6']}
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'boxTaxType'), formProps)}
                        refNumber="4.3"
                        label={t('boxCalcTaxes.boxTaxType')}
                        selectOptions={aisCodelists?.taxType}
                        tooltip={getH1TooltipsByRefNumberAndField('4.3', t('boxCalcTaxes.boxTaxType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                        codeValidation={[validators.exact(3)]}
                    />
                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'boxAmount'), formProps)}
                        refNumber="4.4"
                        label={t('boxCalcTaxes.boxAmount')}
                        refTooltip={getH1TooltipsByRefNumberAndField('4.4', t('boxCalcTaxes.boxAmount'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'boxTaxRate'), formProps)}
                        refNumber="4.5"
                        label={t('boxCalcTaxes.boxTaxRate')}
                        refTooltip={getH1TooltipsByRefNumberAndField('4.5', t('boxCalcTaxes.boxTaxRate'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <DeclarationNumberInput
                        disabled
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'boxTaxPayableAmount'), formProps)}
                        refNumber="4.6"
                        label={t('boxCalcTaxes.boxTaxPayableAmount')}
                        refTooltip={getH1TooltipsByRefNumberAndField('4.6', t('boxCalcTaxes.boxTaxPayableAmount'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <DeclarationNumberInput
                        disabled
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'boxTaxPaymentMethod'), formProps)}
                        refNumber="4.6"
                        label={t('boxCalcTaxes.boxTaxPaymentMethod')}
                        refTooltip={getH1TooltipsByRefNumberAndField('4.6', t('boxCalcTaxes.boxTaxPaymentMethod'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default TaxBoxCard;
