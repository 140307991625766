import Drawer from 'components/ui/base/drawer/Drawer';
import { FC } from 'react';
import { ErrorResponse } from 'core/http/response';
import {
    IndividualImportErrorContainer,
    IndividualImportErrorRow,
    IndividualImportErrorText,
} from './components/IndividualDetails.styles';

interface Props {
    individualImportError?: ErrorResponse | undefined;
    open?: boolean;
    onClose?: () => void;
}

const IndividualsImportErrorsDrawer: FC<Props> = ({ individualImportError, open = false, onClose }) => {
    const handleClose = (): void => {
        onClose?.();
    };

    return (
        <Drawer title="Individual Import Errors" open={open} onClose={handleClose} width={820} warning={true}>
            <SubmissionErrorsList
                errors={individualImportError?.subErrors
                    ?.filter((error) => error.category === 'VALIDATION')
                    .map((error) => ({ field: error.field, message: error.message }))}
            />
        </Drawer>
    );
};

export interface IndividualImportError {
    message: string;
}
interface Props {
    errors?: IndividualImportError[] | undefined;
}
const SubmissionErrorsList: FC<Props> = ({ errors }) => {
    return (
        <IndividualImportErrorContainer>
            {errors?.map((error) => (
                <IndividualImportErrorRow>
                    <IndividualImportErrorText>{error?.message}</IndividualImportErrorText>
                </IndividualImportErrorRow>
            ))}
        </IndividualImportErrorContainer>
    );
};
export default IndividualsImportErrorsDrawer;
