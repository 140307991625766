import { Select, Tag, Typography, Input, Checkbox } from 'antd';
import { ReactElement, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { fromExternalFieldNameToInternal, getFieldName } from 'utils/validationParserUtils';
import DeclarationField from 'views/declarations/common/DeclarationField';
import parseValidationErrors from 'views/declarations/uk/parseValidationErrors';
import { AmendmentContext, AmendmentRecord } from './CdsAmendmentModal';
import amendmentTypeCodeCodeList from '../../utils/amendmentTypeCodeList';
import { get } from 'lodash';

const { Text } = Typography;
const { TextArea } = Input;

const Error = styled(Text)`
    color: red;
    font-size: 1.25rem;
`;

interface Props {
    amendment: AmendmentRecord;
    index: number;
}

const CdsAmendmentRecord = ({ amendment, index }: Props): ReactElement => {
    const amendmentContext = useContext(AmendmentContext);
    const actionTagColor = amendment.action === 'ADD' ? 'green' : amendment.action === 'EDIT' ? 'gold' : 'red';
    const valueColor = amendment.action === 'ADD' ? 'success' : amendment.action === 'EDIT' ? 'warning' : 'danger';

    const modifyPathForCdsExport = (path: string) => {
        if (path.includes('cdsExportGovernmentAgencyGoodsItem') && path.includes('governmentAgencyGoodsItem')) {
            const split = path.split('.');
            return [split[0], split[3], split[2], ...split.slice(4)].join('.');
        }
        return path;
    };

    const amendedFieldName = useMemo(() => {
        const path = modifyPathForCdsExport(amendment.path);

        const fieldName = getFieldName(parseValidationErrors, path, 'governmentAgencyGoodsItem');
        if (fieldName === 'Unknown card - Unknown field' || !fieldName)
            return fromExternalFieldNameToInternal(amendment.path);
        return fieldName;
    }, [amendment.path]);

    return (
        <>
            <div>
                <Text italic>{amendedFieldName}</Text>
            </div>
            <div>
                <Text type={'secondary'}>
                    {get(amendmentContext?.previousValues, fromExternalFieldNameToInternal(amendment.path) ?? '') ??
                        '-'}
                </Text>
            </div>
            <div>
                <Text type={valueColor}>{amendment.action === 'DELETE' ? '-' : amendment.value}</Text>
            </div>
            <div>
                <Tag color={actionTagColor} style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                    {amendment.action}
                </Tag>
            </div>
            <div>
                <DeclarationField name={`${index}.amendmentReasonCode`}>
                    {({ field, meta, form }) => (
                        <>
                            <Select
                                {...field}
                                onChange={(value) =>
                                    field.onChange({ target: { name: field.name, value, touched: field.checked } })
                                }
                                onBlur={() => form.setFieldTouched(field.name)}
                                placeholder="Choose an amendment type code"
                                options={amendmentTypeCodeCodeList}
                                style={{ width: '100%' }}
                            />
                            {meta.error && meta.touched && <Error>{meta.error}</Error>}
                        </>
                    )}
                </DeclarationField>
            </div>
            <div>
                <DeclarationField name={`${index}.reason`}>
                    {({ field }) => (
                        <TextArea
                            {...field}
                            placeholder="Reason for the amendment"
                            rows={2}
                            style={{ resize: 'none' }}
                        />
                    )}
                </DeclarationField>
            </div>
            <div style={{ display: 'grid', justifyContent: 'center' }}>
                <DeclarationField name={`${index}.include`}>
                    {({ field }) => (
                        <Checkbox
                            style={{ height: 'min-content' }}
                            defaultChecked
                            checked={field.checked ?? field.value ?? true}
                            {...field}
                        />
                    )}
                </DeclarationField>
            </div>
        </>
    );
};

export default CdsAmendmentRecord;
