import { FC } from 'react';
import { ValidationSchema } from 'views/declarations/uk/export/validations/validations';
import { AnySchema } from 'yup';
import EnsProductSection from '../../declarations/ireland/ens/EnsProductSection';
import { goodsItems as ieEnsProductValidationSchema } from '../../declarations/ireland/ens/validation/irelandEnsValidation';
import { parseEnsProductTemplate } from '../../declarations/ireland/ens/validation/parseValidationErrors';
import IrelandH1ProductsSection from '../../declarations/ireland/import/h1/IrelandH1ProductsSection';
import { parseH1ProductTemplate } from '../../declarations/ireland/import/h1/validation/utils';
import { goodsShipmentItemSchemaRequired as ieH1ProductValidationSchema } from '../../declarations/ireland/import/h1/validation/ValidationSchema';
import IrelandH7ProductsSection from '../../declarations/ireland/import/h7/IrelandH7ProductsSection';
import governmentAgencyGoodsItem from '../../declarations/ireland/import/h7/validation/H7ProductValidationSchema';
import { parseH7ProductTemplate } from '../../declarations/ireland/import/h7/validation/utils';
import { parseCdsProductTemplate } from '../../declarations/uk/parseValidationErrors';
import UkProductsSection, { cdsExportProductSectionValidation } from '../../declarations/uk/UkProductsSection';
import { cdsImportH1ProductValidation } from '../../declarations/uk/import/h1/validation/cdsImportH1Validation';
import { parseExportProductTemplate } from 'views/declarations/ireland/export/validation/IrelandExportValidationErrorsParser';
import IrelandExportProductsSection from 'views/declarations/ireland/export/IrelandExportProductsSection';
import { irelandExportB1ValidationItem } from 'views/declarations/ireland/export/validation/IrelandExportB1Validation';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { irelandExportB2ValidationItem } from 'views/declarations/ireland/export/validation/IrelandExportB2Validation';
import { irelandExportB3ValidationItem } from 'views/declarations/ireland/export/validation/IrelandExportB3Validation';
import { irelandExportB4ValidationItem } from 'views/declarations/ireland/export/validation/IrelandExportB4Validation';
import { irelandExportC1ValidationItem } from 'views/declarations/ireland/export/validation/IrelandExportC1Validation';
import { irelandExportC2ValidationItem } from 'views/declarations/ireland/export/validation/IrelandExportC2Validation';
import { cdsImportH2ProductValidation } from '../../declarations/uk/import/h2/validation/cdsImportH2Validation';
import { parseNctsProductTemplate } from '../../declarations/ireland/ncts/validation/IrelandNctsValidationErrorsParser';
import IrelandNctsProductsSection from '../../declarations/ireland/ncts/IrelandNctsProductsSection';
import { irelandNctsD1ValidationItem } from '../../declarations/ireland/ncts/validation/IrelandNctsD1Validation';
import { irelandNctsD2ValidationItem } from '../../declarations/ireland/ncts/validation/IrelandNctsD2Validaton';
import { irelandNctsD3ValidationItem } from '../../declarations/ireland/ncts/validation/IrelandNctsD3Validation';
import { irelandNctsD4ValidationItem } from '../../declarations/ireland/ncts/validation/IrelandNctsD4Validation';
import { parseNctsProductTemplate as parseUkNctsProdcutTemplate } from 'views/declarations/uk/ncts/validation/UkNctsValidationErrorsParser';
import UkNctsProductsSection from 'views/declarations/uk/ncts/UkNctsProductsSection';
import { ukNctsD1ValidationItem } from 'views/declarations/uk/ncts/validation/UkNctsD1Validation';
import { ukNctsD2ValidationItem } from 'views/declarations/uk/ncts/validation/UkNctsD2Validation';
import { ukNctsD3ValidationItem } from 'views/declarations/uk/ncts/validation/UkNctsD3Validation';
import { ukNctsD4ValidationItem } from 'views/declarations/uk/ncts/validation/UkNctsD4Validation';
import { parseImportI1ProductTemplate } from 'views/declarations/ireland/import/i1/validation/irelandI1ValidationErrorsParser';
import IrelandI1ProductSection from 'views/declarations/ireland/import/i1/IrelandI1ProductsSection';

export type ProductTypeMapParams = {
    validationErrorsParser: Function;
    validationSchema?: AnySchema;
    validate?: ValidationSchema;
    productSection: FC<any>;
};

type ProductTypeMapHelpers = Record<
    string,
    Partial<Record<DeclarationInternalType, Record<string, Partial<ProductTypeMapParams>>>>
>;

export const productTypeMapHelpers: ProductTypeMapHelpers = {
    ireland: {
        IMPORT: {
            default: {},
            H1: {
                validationErrorsParser: parseH1ProductTemplate,
                validationSchema: ieH1ProductValidationSchema,
                productSection: IrelandH1ProductsSection,
            },
            H7: {
                validationErrorsParser: parseH7ProductTemplate,
                validationSchema: governmentAgencyGoodsItem,
                productSection: IrelandH7ProductsSection,
            },
            I1: {
                validationErrorsParser: parseImportI1ProductTemplate,
                validationSchema: ieH1ProductValidationSchema, // TODO [i1]
                productSection: IrelandI1ProductSection,
            },
        },
        EXPORT: {
            default: {
                validationErrorsParser: parseExportProductTemplate,
                productSection: IrelandExportProductsSection,
            },
            B1: { validate: irelandExportB1ValidationItem },
            B2: { validate: irelandExportB2ValidationItem },
            B3: { validate: irelandExportB3ValidationItem },
            B4: { validate: irelandExportB4ValidationItem },
            C1: { validate: irelandExportC1ValidationItem },
            C2: { validate: irelandExportC2ValidationItem },
        },
        ENS: {
            default: {
                validationErrorsParser: parseEnsProductTemplate,
                validationSchema: ieEnsProductValidationSchema,
                productSection: EnsProductSection,
            },
        },
        NCTS: {
            default: {
                validationErrorsParser: parseNctsProductTemplate,
                productSection: IrelandNctsProductsSection,
            },
            D1: { validate: irelandNctsD1ValidationItem },
            D2: { validate: irelandNctsD2ValidationItem },
            D3: { validate: irelandNctsD3ValidationItem },
            D4: { validate: irelandNctsD4ValidationItem },
        },
    },
    uk: {
        IMPORT: {
            default: {},
            H1: {
                validationErrorsParser: parseCdsProductTemplate,
                productSection: UkProductsSection,
                validate: cdsImportH1ProductValidation,
            },
            H2: {
                validationErrorsParser: parseCdsProductTemplate,
                productSection: UkProductsSection,
                validate: cdsImportH2ProductValidation,
            },
        },
        EXPORT: {
            default: {},
            B1: {
                validationErrorsParser: parseCdsProductTemplate,
                productSection: UkProductsSection,
                validate: cdsExportProductSectionValidation,
            },
        },
        NCTS: {
            default: {
                validationErrorsParser: parseUkNctsProdcutTemplate,
                productSection: UkNctsProductsSection,
            },
            D1: { validate: ukNctsD1ValidationItem },
            D2: { validate: ukNctsD2ValidationItem },
            D3: { validate: ukNctsD3ValidationItem },
            D4: { validate: ukNctsD4ValidationItem },
        },
    },
};
