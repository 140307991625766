import StatusTag from 'components/ui/base/status/StatusTag';
import { enumToText } from 'core/utils/enum-to-text';
import { FC } from 'react';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { colors } from 'theme';

interface Props {
    status: DeclarationStatus;
    multiple?: boolean;
    dataTestId?: string;
}
const gvmsSpecificDeclarationStatusColors = {
    [DeclarationStatus.EMBARKED]: colors.darkGreen,
    [DeclarationStatus.CHECKED_IN]: colors.lightGreen2,
    [DeclarationStatus.OPEN]: colors.purpleBlue,
    [DeclarationStatus.FINALISED]: colors.lightBlue,
    [DeclarationStatus.NOT_FINALISABLE]: colors.lightRed,
};

const cdsSpecificDeclarationStatusColors = {
    [DeclarationStatus.MUCR_SOE_0]: colors.lightBlue,
    [DeclarationStatus.DECLARATION_VALIDATION]: colors.green,
    [DeclarationStatus.GOODS_RELEASED]: colors.polarGreen,
    [DeclarationStatus.DECLARATION_CLEARED]: colors.lightYellow,
    [DeclarationStatus.DECLARATION_INVALIDATION]: colors.red,
    [DeclarationStatus.DECLARATION_REJECTED]: colors.lightRed,
    [DeclarationStatus.RE_ARRIVED_DECLARATION_IS_SEIZED]: colors.orange,
    [DeclarationStatus.ADVANCE_DECLARATION_REGISTRATION]: colors.yellow,
    [DeclarationStatus.DECLARATION_ACCEPTANCE]: colors.lightGreen,
    [DeclarationStatus.DECLARATION_ACCEPTED_AT_GOODS_ARRIVAL]: colors.lightGreen3,
    [DeclarationStatus.DECLARATION_REJECTED_AT_ARRIVAL]: colors.purpleBlue,
    [DeclarationStatus.DECLARATION_CORRECTED]: colors.lightOrange,
    [DeclarationStatus.DECLARATION_SUPPLEMENTED]: colors.lightGrey,
    [DeclarationStatus.DECLARATION_RISKED]: colors.darkOrange,
    [DeclarationStatus.CUSTOMS_POSITION_DETERMINED]: colors.blueLink,
    [DeclarationStatus.DECLARATION_CLEARED_AFTER_GOODS_RELEASE]: colors.grey,
    [DeclarationStatus.DECLARATION_RECEIVED]: colors.darkGrey,
    [DeclarationStatus.GOODS_EXIT_RESULTS_RECEIVED]: colors.darkBlack2,
    [DeclarationStatus.COMPLETED_SUCCESSFULLY]: colors.success,
    [DeclarationStatus.MANUAL_TASK_RAISED]: colors.info,
    [DeclarationStatus.DEPARTED]: colors.darkGreen,
    [DeclarationStatus.FRUSTRATED]: colors.error,
    [DeclarationStatus.DECLARATION_UNDER_RE_ARRIVALS_RISK]: colors.warning,
};

const aisSpecificDeclarationStatusColors = {
    [DeclarationStatus.AWAITING_SUPPLEMENTARY]: colors.periwinkleCrayola,
};

const aesSpecificDeclarationStatusColors = {
    [DeclarationStatus.DIVERSION_REJECTED]: colors.lightGreen3,
    [DeclarationStatus.REGISTERED_AND_WAITING_FOR_PRESENTATION_OF_GOODS]: colors.lightBlue,
    [DeclarationStatus.GOODS_RELEASED_FOR_IMMEDIATE_LEAVE]: colors.lightGreen,
    [DeclarationStatus.GOODS_PRESENTED_AT_EXIT]: colors.lightGreen,
    [DeclarationStatus.EXPORTED]: colors.green,
    [DeclarationStatus.EXPORTED_STOPPED_AT_EXIT]: colors.orange,
    [DeclarationStatus.CANCELLED]: colors.lightRed,
    [DeclarationStatus.INVALIDATED]: colors.red,
    [DeclarationStatus.GOODS_NOT_ALLOWED_TO_EXIT]: colors.purpleBlueA15,
    [DeclarationStatus.NOT_RELEASED_FOR_EXPORT]: colors.purpleBlue,
    [DeclarationStatus.GOODS_RELEASED_FOR_EXPORT]: colors.polarGreen,
    [DeclarationStatus.GOODS_READY_TO_BE_RELEASED]: colors.yellow,
    [DeclarationStatus.EXITED]: colors.lightBlue,
    [DeclarationStatus.EXPORT_STOPPED_DISCREPANCIES_AT_EXIT]: colors.lightRed,
};

const nctsSpecificDeclarationStatusColors = {
    [DeclarationStatus.MOVEMENT_RELEASED]: colors.darkGreen,
    [DeclarationStatus.AAR_CREATED]: colors.lightGreen,
    [DeclarationStatus.ARRIVAL_ACCEPTED]: colors.lightGreen,
    [DeclarationStatus.UNLOADING]: colors.lightRed,
    [DeclarationStatus.UNLOADING_REMARKS]: colors.lightRed,
    [DeclarationStatus.GUARANTEE_UNDER_AMENDMENT]: colors.lightOrange,
    [DeclarationStatus.GUARANTEE_UNDER_REGISTRATION]: colors.lightOrange,
    [DeclarationStatus.IDLE]: colors.orange,
    [DeclarationStatus.NOT_RELEASED_FOR_TRANSIT]: colors.red,
    [DeclarationStatus.UNDER_AMENDMENT_REQUEST_DUE_TO_EFBT]: colors.orange,
    [DeclarationStatus.INCIDENT_REGISTERED]: colors.red,
    [DeclarationStatus.MOVEMENT_WRITTEN_OFF]: colors.red,
    [DeclarationStatus.UNDER_RECOVERY_PROCEDURE]: colors.lightOrange,
    [DeclarationStatus.PRE_LODGED]: colors.lightGreen,
    [DeclarationStatus.MOVEMENT_UNDER_RESOLUTION]: colors.yellow,
    [DeclarationStatus.UNDER_RELEASE_REQUEST]: colors.lightYellow,
    [DeclarationStatus.UNDER_REVIEW]: colors.lightOrange,
};

export const declarationStatusColor = {
    [DeclarationStatus.DRAFT]: colors.grey,
    [DeclarationStatus.SUBMITTED]: colors.lightGreenA70,
    [DeclarationStatus.REJECTED]: colors.lightRed,
    [DeclarationStatus.INVALID]: colors.lightRed,
    [DeclarationStatus.AWAITING_TARIFF_CALCULATION]: colors.lightOrange,
    [DeclarationStatus.ACCEPTED]: colors.lightGreen2,
    [DeclarationStatus.AWAITING_RISK]: colors.lightOrange,
    [DeclarationStatus.UNDER_PAYMENT]: colors.lightOrange1,
    [DeclarationStatus.INSUFFICIENT_FUNDS]: colors.pink,
    [DeclarationStatus.UNDER_CONTROL]: colors.darkOrange,
    [DeclarationStatus.RELEASED]: colors.darkGreen,
    [DeclarationStatus.REGISTERED]: colors.lightGreenA70,
    [DeclarationStatus.NOT_RELEASED]: colors.lightPink,
    [DeclarationStatus.UPLOAD_DOCUMENTS]: colors.grey,
    [DeclarationStatus.INVALIDATE]: colors.black,
    [DeclarationStatus.RISKED]: colors.darkOrange,
    ...aesSpecificDeclarationStatusColors,
    ...aisSpecificDeclarationStatusColors,
    ...cdsSpecificDeclarationStatusColors,
    ...gvmsSpecificDeclarationStatusColors,
    ...nctsSpecificDeclarationStatusColors,
};

const DeclarationStatusTag: FC<Props> = ({ status, multiple, dataTestId }) => {
    if (multiple) {
        return <StatusTag color={colors.grey} status="Multiple" />;
    }
    return <StatusTag color={declarationStatusColor[status]} status={enumToText(status)} dataTestId={dataTestId} />;
};
export default DeclarationStatusTag;
