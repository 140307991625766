import {
    getIrelandCodeLists,
    getUkCodeLists,
    getIeUnLocodeExtendedCodelist,
    getUkUnLocodeExtendedCodelist,
} from './client';
import { Codelists, CodelistsPayload, IrelandCodelistTypes, UkCodelistTypes, UnLocodeExtendedPayload } from './code';
import { camelCase } from 'lodash';
import { DeclarationTypes } from 'store/declarations/enums/common/declaration-types';
import { SelectOption } from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
const AES_CODE_SUBSTRING = /cl\d{3}(Cl|NCl)/i;

const transformCodelistsForClient = (response: CodelistsPayload[]): IrelandCodelistTypes & UkCodelistTypes => {
    const codelistsObject: any = {};

    for (let i = 0; i < response.length; i++) {
        const codelistType = camelCase(response[i].type) as keyof IrelandCodelistTypes & UkCodelistTypes;
        let codelistName = camelCase(response[i].name) as keyof Codelists;

        if (AES_CODE_SUBSTRING.test(codelistName)) {
            const withRemovedSubstring = codelistName.replace(AES_CODE_SUBSTRING, '');
            codelistName = (withRemovedSubstring[0].toLowerCase() + withRemovedSubstring.slice(1)) as keyof Codelists;
        }

        if (codelistType && !codelistsObject[codelistType]) {
            codelistsObject[codelistType] = {};
        }

        if (codelistName && !codelistsObject[codelistType][codelistName]) {
            codelistsObject[codelistType][codelistName] = [];
        }

        codelistsObject[codelistType][codelistName].push({
            id: response[i].code,
            value: response[i].description,
            type: response[i].type,
            name: codelistName,
            idServer: response[i].id,
            creatorName: response[i].creatorName,
            ...JSON.parse(response[i].data),
        });
    }

    return codelistsObject;
};

const transformUnLocodeExtendedCodelistForClient = (
    payload: UnLocodeExtendedPayload
): { list: SelectOption[]; pageNumber: number; total: number } => {
    const { list, pageNumber, total } = payload;

    const transformedData = list.map((item) => ({
        id: item.code,
        value: item.description,
        type: item.type,
        name: item.name,
        idServer: item.id,
        creatorName: item.creatorName,
        ...JSON.parse(item.data || '{}'),
    }));

    return {
        list: transformedData,
        pageNumber,
        total,
    };
};

export const doGetIrelandCodelists: Function = (type: DeclarationTypes | undefined) => async (dispatch: Function) => {
    dispatch({ type: 'GET_IRELAND_CODELISTS_REQUEST' });

    try {
        const response = transformCodelistsForClient(await getIrelandCodeLists(type));

        dispatch({ type: 'GET_IRELAND_CODELISTS_SUCCESS', payload: response });
    } catch (e: any) {
        dispatch({ type: 'GET_IRELAND_CODELISTS_ERROR', payload: {} });
    }
};

export const doGetUkCodelists: Function = (type: DeclarationTypes | undefined) => async (dispatch: Function) => {
    dispatch({ type: 'GET_UK_CODELISTS_REQUEST' });

    try {
        const response = transformCodelistsForClient(await getUkCodeLists(type));

        dispatch({ type: 'GET_UK_CODELISTS_SUCCESS', payload: response });
    } catch (e: any) {
        dispatch({ type: 'GET_UK_CODELISTS_ERROR', payload: {} });
    }
};

export const doGetIeUnLocodeExtendedCodelist: Function =
    (customerId: string | undefined, pagination: { page: number; size: number }) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_CODELISTS_REQUEST' });

        try {
            const response = await getIeUnLocodeExtendedCodelist(customerId, pagination);
            const transformedData = transformUnLocodeExtendedCodelistForClient(response);

            dispatch({ type: 'GET_IRELAND_UNLOCODEEXTENDED_CODELISTS_SUCCESS', payload: transformedData });
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_CODELISTS_ERROR', payload: {} });
        }
    };

export const doGetUkUnLocodeExtendedCodelist: Function =
    (customerId: string | undefined, pagination: { page: number; size: number }) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_CODELISTS_REQUEST' });

        try {
            const response = await getUkUnLocodeExtendedCodelist(customerId, pagination);
            const transformedData = transformUnLocodeExtendedCodelistForClient(response);

            dispatch({ type: 'GET_UK_UNLOCODEEXTENDED_CODELISTS_SUCCESS', payload: transformedData });
        } catch (e: any) {
            dispatch({ type: 'GET_UK_CODELISTS_ERROR', payload: {} });
        }
    };
