import SearchBar from 'components/ui/base/searchbar';
import getDeclarationsOptions from '../../../components/ui/base/searchbar/search-options/DeclarationsOptions';
import { listDeclarations } from 'store/declarations/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DeclarationInternalType } from '../../../store/declarations/enums/common/declaration-internal-type';
import { DeclarationExternalEntity } from '../../../store/declarations/enums/common/declaration-external-entity';
import { Declaration } from '../../../store/declarations/declaration';
import useGlobalOverlay from '../../../hooks/useGlobalOverlay';
import { Col, Row } from 'antd';
import { BlueText, CloseIcon, CustomDiv, CustomRow, SectionTitle } from './InvoiceUpload.styles';
import { Text } from 'components/ui/base/typography';
import { debounce } from 'lodash';

const SelectDeclaration = ({
    disabled,
    selectedDeclarationMrn,
    setSelectedDeclaration,
}: {
    disabled: boolean;
    selectedDeclarationMrn: string | undefined;
    setSelectedDeclaration: Dispatch<SetStateAction<{ id: string; mrn: string } | undefined>>;
}) => {
    const { hideGlobalOverlay, showGlobalOverlay } = useGlobalOverlay();
    const [declarations, setDeclarations] = useState<Declaration[] | undefined>(undefined);

    const listDeclarationsRequest = (query?: string) =>
        listDeclarations({
            query: query ? encodeURIComponent(query) : undefined,
            size: 9999,
            hasMrn: true,
            declarationInternalType: DeclarationInternalType.EXPORT,
            declarationExternalEntity: DeclarationExternalEntity.REVENUE,
        })
            .then((data) => setDeclarations(data.list))
            .finally(() => {
                hideGlobalOverlay();
            });

    useEffect(() => {
        if (disabled) return;
        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Loading declarations...' } });
        listDeclarationsRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    const debouncedSearch = debounce((query: string) => {
        listDeclarationsRequest(query);
    }, 700);

    return (
        <>
            <CustomRow>
                <Col span={16}>
                    <SectionTitle className={`${disabled && 'disabled'}`}>Select Declaration</SectionTitle>
                </Col>

                <CustomDiv>
                    {selectedDeclarationMrn ? (
                        <Row align="middle">
                            <CloseIcon
                                className={`${disabled && 'disabled'}`}
                                onClick={() => {
                                    if (!disabled) {
                                        setSelectedDeclaration(undefined);
                                    }
                                }}
                            />
                            <Text>
                                Declaration MRN: <BlueText>{selectedDeclarationMrn}</BlueText>
                            </Text>
                        </Row>
                    ) : (
                        <>
                            {declarations && !disabled && (
                                <SearchBar
                                    options={getDeclarationsOptions(declarations, (declaration: Declaration) => [
                                        declaration.id,
                                        declaration.mrn,
                                    ])}
                                    onSelect={(value: [string, string]) => {
                                        setSelectedDeclaration({ id: value[0], mrn: value[1] });
                                    }}
                                    inputPlaceholder="Search Declaration"
                                    onSearch={(e) => debouncedSearch(e)}
                                />
                            )}
                        </>
                    )}
                </CustomDiv>
            </CustomRow>
        </>
    );
};

export default SelectDeclaration;
