import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { FilterResult, FilterType } from './filter-result';

export const getFiltersForDeclaration = (filters: FilterResult[]) => {
    const result: {
        startDate: string;
        endDate: string;
        declarationStatus: DeclarationStatus[];
        declarationType: DeclarationName[];
        individualIds: string[];
    } = { startDate: '', endDate: '', declarationStatus: [], declarationType: [], individualIds: [] };

    filters.forEach((filter) => {
        if (!filter.value) return;

        switch (filter.type) {
            case FilterType.DATE:
                result.startDate = filter.value[0];
                result.endDate = filter.value[1];
                break;
            case FilterType.DECLARATION_STATUS:
                result.declarationStatus = filter.value as DeclarationStatus[];
                break;
            case FilterType.DECLARATION_TYPE:
                result.declarationType = filter.value as DeclarationName[];
                break;
            case FilterType.AUTHOR:
                result.individualIds = filter.value.map((v) => v.id) as string[];
                break;
            default:
                break;
        }
    });

    return result;
};
