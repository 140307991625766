import { cdsImportH2PartiesCardValidation } from '../../../../common/parties/cds/PartiesBlockValidations';
import { smallerAdditionalDocumentBlockValidation } from '../../../export/blocks/AdditionalDocumentBlock';
import { cdsImportAdditionalFiscalReferencesBlockValidation } from '../../../export/blocks/AdditionalFiscalReferencesBlock';
import { cdsImportAdditionalInformationBlockValidation } from '../../../export/blocks/AdditionalInformationBlock';
import {
    cdsImportAdditionalSupplyChainActorBlockProductValidation,
    cdsImportAdditionalSupplyChainActorBlockValidation,
} from '../../../export/blocks/AdditionalSupplyChainActorBlock';
import { holderOfAuthorisationIdentificationNumberRequiredBlockValidation } from '../../../export/blocks/HolderOfAuthorisationIdentificationNumberBlock';
import { previousDocumentsBlockOptionalValidation } from '../../../export/blocks/PreviousDocumentsBlock';
import { countryOfDestinationCardOptionalValidation } from '../../../export/cards/CountryOfDestinationCard';
import { cdsImportOfficesCardValidation } from '../../../export/cards/CustomsOfficeCard';
import { cdsImportGrossMassCardValidation } from '../../../export/cards/GrossMassCard';
import { identificationOfWarehouseCardRequiredValidation } from '../../../export/cards/IdentificationOfWarehouseCard';
import { identityOfMeansOfTransportOnArrivalCardValidation } from '../../../export/cards/IdentityOfMeansOfTransport';
import { locationOfGoodsCardValidation } from '../../../export/cards/LocationOfGoodsCard';
import { cdsImportMasterDetailsCardValidation } from '../../../export/cards/MasterDetailsCard';
import { smallerModeOfTransportCardValidation } from '../../../export/cards/ModeOfTransportCard';
import {
    natureOfTransactionCardProductValidation,
    natureOfTransactionCardValidation,
} from '../../../export/cards/NatureOfTransactionCard';
import { cdsImportCommodityCodesCardValidation } from '../../../export/cards/Products/CommodityCodesCard';
import { cdsImportItemFieldsCardValidation } from '../../../export/cards/Products/ItemFieldsCard';
import { cdsImportPackagingCardValidation } from '../../../export/cards/Products/PackagingCard';
import { procedureCardValidation } from '../../../export/cards/Products/ProcedureCard';
import { supervisingCustomsOfficeCardRequiredValidation } from '../../../export/cards/SupervisingCustomsOfficeCard';
import {
    cdsImportTransportEquipmentCardValidation,
    transportEquipmentCardValidationProduct,
} from '../../../export/cards/TransportEquipmentCard';
import { guaranteeReferenceCardValidation } from '../../../UkForm';
import {
    additionalFiscalReferencesCardValidation,
    countryOfOriginCardValidation,
    previousDocumentsCardValidation,
} from '../../../UkProductsSection';
import { ValidationSchema } from '../../../export/validations/validations';
import { smallerDutyTaxFeeCardValidation } from '../../cards/Products/DutyTaxFeeCard';

export const cdsImportH2ProductValidation: ValidationSchema = {
    selfValidators: [
        cdsImportCommodityCodesCardValidation,
        cdsImportItemFieldsCardValidation,
        procedureCardValidation,
        cdsImportAdditionalInformationBlockValidation,
        smallerAdditionalDocumentBlockValidation,
        natureOfTransactionCardProductValidation,
        cdsImportPackagingCardValidation,
        previousDocumentsCardValidation,
        cdsImportAdditionalSupplyChainActorBlockProductValidation,
        additionalFiscalReferencesCardValidation,
        smallerDutyTaxFeeCardValidation,
        countryOfOriginCardValidation,
        transportEquipmentCardValidationProduct,
    ],
};

const partiesCardValidation = {
    selfValidators: [cdsImportH2PartiesCardValidation],
};

export const cdsImportH2Validation: ValidationSchema = {
    childValidators: {
        'goodsShipment.governmentAgencyGoodsItem': {
            selfValidators: [cdsImportH2ProductValidation],
            stopAtNull: true,
        },
    },
    selfValidators: [
        cdsImportMasterDetailsCardValidation,
        partiesCardValidation,
        holderOfAuthorisationIdentificationNumberRequiredBlockValidation,
        identificationOfWarehouseCardRequiredValidation,
        countryOfDestinationCardOptionalValidation,
        locationOfGoodsCardValidation,
        supervisingCustomsOfficeCardRequiredValidation,
        cdsImportOfficesCardValidation,
        cdsImportGrossMassCardValidation,
        smallerModeOfTransportCardValidation,
        identityOfMeansOfTransportOnArrivalCardValidation,
        guaranteeReferenceCardValidation,
        natureOfTransactionCardValidation,
        previousDocumentsBlockOptionalValidation,
        cdsImportAdditionalInformationBlockValidation,
        cdsImportAdditionalSupplyChainActorBlockValidation,
        cdsImportAdditionalFiscalReferencesBlockValidation,
        cdsImportTransportEquipmentCardValidation,
    ],
};
