import config from 'config';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { MessageTypes } from 'store/declarations/enums/common/declaration-types';
import axiosClient from '../../config/axios';
import { BusinessRule } from './business-rule';

export type IrelandBusinessRulesType = 'AIS' | 'AES';

export const listIrelandBusinessRules = (type: IrelandBusinessRulesType): Promise<ListPayload<BusinessRule>> => {
    return axiosClient
        .get<ListResponse<BusinessRule>>(
            `${config.resourcesUrl}/revenue/ie/business-rules/conditions?${new URLSearchParams({
                type,
            })}`
        )
        .then((response) => response.data.payload);
};

export interface BusinessRulesStatus {
    id: string;
    declarationExternalEntity: DeclarationExternalEntity;
    declarationInternalType: DeclarationInternalType;
    declarationMessageType: MessageTypes;
    active: boolean;
    createdAt: string;
    updatedAt: string;
}
export interface CreateBusinessRuleStatusPayload extends Omit<BusinessRulesStatus, 'id' | 'createdAt' | 'updatedAt'> {}
export interface UpdateBusinessRuleStatusPayload extends Omit<BusinessRulesStatus, 'createdAt' | 'updatedAt'> {}

export const getBusinessRulesValidationStatus = async () =>
    axiosClient
        .get<SuccessResponse<BusinessRulesStatus[]>>(`${config.declarationsUrl}/business-rules/validations`)
        .then((response) => response.data.payload);

export const setBusinessRulesValidationStatus = async (
    payload: (CreateBusinessRuleStatusPayload | UpdateBusinessRuleStatusPayload)[]
) =>
    axiosClient
        .post<SuccessResponse<BusinessRulesStatus[]>>(`${config.declarationsUrl}/business-rules/validations`, payload)
        .then((response) => response.data.payload);

export const setAllBusinessRulesValidationStatus = async (status: boolean) =>
    axiosClient
        .post<SuccessResponse<any>>(`${config.declarationsUrl}/business-rules/validations/enable?isActive=${status}`)
        .then((response) => response.data.payload);
