import config from 'config';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Customer, CustomerParams } from './customer';

// TODO add query params
export const listCustomers = (params?: Partial<CustomerParams>): Promise<ListPayload<Customer>> =>
    axiosClient
        .get<ListResponse<Customer>>(`${config.cmsUrl}/customers`, { params })
        .then((response) => response.data.payload);

export const listArchivedCustomers = (params?: Partial<CustomerParams>): Promise<ListPayload<Customer>> =>
    axiosClient
        .get<ListResponse<Customer>>(`${config.cmsUrl}/customers/archived`, { params })
        .then((response) => response.data.payload);

export const createCustomer = (customer: Customer): Promise<Customer> =>
    axiosClient
        .post<SuccessResponse<Customer>>(`${config.cmsUrl}/customers`, customer)
        .then((response) => response.data.payload);

export const editCustomer = (customerId: string, customer: Partial<Customer>): Promise<Customer> =>
    axiosClient
        .put<SuccessResponse<Customer>>(`${config.cmsUrl}/customers/${customerId}`, customer)
        .then((response) => response.data.payload);

export const getCustomer = (customerId: string): Promise<Customer> =>
    axiosClient
        .get<SuccessResponse<Customer>>(`${config.cmsUrl}/customers/${customerId}`)
        .then((response) => response.data.payload);

export const deleteCustomer = (customerId: string): Promise<SuccessResponse<void>> =>
    axiosClient.delete<SuccessResponse<void>>(`${config.cmsUrl}/customers/${customerId}`).then((response) => {
        return response.data;
    });

export const connectToHmrc = (customerID: string): Promise<any> =>
    axiosClient.get<any>(`${config.cmsUrl}/customers/cds/redirect?id=${customerID}`).then((response) => {
        return response;
    });

export const saveHmrcToken = (customerID: string, code: string): Promise<any> =>
    axiosClient.get<any>(`${config.cmsUrl}/customers/cds/save?id=${customerID}&code=${code}`).then((response) => {
        return response;
    });

export const disconnectToHmrc = (customerID: string): Promise<any> =>
    axiosClient.get<any>(`${config.cmsUrl}/customers/cds/disconnect?id=${customerID}`).then((response) => {
        return response;
    });

export const archiveCustomer = (customerId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.cmsUrl}/customers/archive/${customerId}`)
        .then((response) => response.data);
export const unarchiveCustomer = (customerId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.cmsUrl}/customers/unarchive/${customerId}`)
        .then((response) => response.data);

export const getCustomerProfilePictureUploadPreSign = (
    customerId: string,
    params: { customerId: string; pictureName: string; pictureSize: number }
): Promise<string> =>
    axiosClient
        .get<SuccessResponse<string>>(`${config.cmsUrl}/customers/profile-picture/${customerId}/upload`, {
            params,
        })
        .then((response) => {
            return response.data.payload;
        });

export const getCustomerProfilePictureDownloadPreSign = (customerId: string): Promise<string | null> =>
    axiosClient
        .get<SuccessResponse<string>>(`${config.cmsUrl}/customers/profile-picture/${customerId}/download`)
        .then((response) => {
            return response.data.payload;
        })
        .catch((error) => {
            if (error.response?.status === 404) return null;
            throw error;
        });

export const uploadCustomerProfilePicture = (customerId: string, s3key: string): Promise<string> =>
    axiosClient
        .post<SuccessResponse<Customer & { profilePictureLink: string }>>(
            `${config.cmsUrl}/customers/profile-picture/${customerId}`,
            undefined,
            { params: { customerId, s3key } }
        )
        .then((response) => {
            return response.data.payload.profilePictureLink;
        });

export const deleteCustomerProfilePicture = (customerId: string): Promise<void> =>
    axiosClient
        .delete<void>(`${config.cmsUrl}/customers/profile-picture/${customerId}`)
        .then((response) => response.data);
