import { Button, Collapse, Divider, Typography } from 'antd';
import {
    IrelandEnsNotification,
    IrelandEnsSubmissionErrors,
} from 'store/declarations/ireland/entry-summary-notification';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonText, DetailsGrid, Error, FieldLink, MainGrid } from '../declaration-errors.styles';
import { NotificationHeader } from '../cds/UkExportDeclarationErrors';
import { isEmpty, sortBy } from 'lodash';
import { LinkButton } from '../../../../../../components/ui/base/button/Button.styles';
import { useTranslation } from 'react-i18next';
import useDeclarations from '../../../../../../hooks/useDeclarations';
import { DeclarationHistory } from '../../../../../../store/declarations/declaration';
import EnsCodeToFieldMap from 'views/declarations/ireland/ens/ens-code-to-field-map';
import { getFieldName } from 'utils/validationParserUtils';
import ieEnsParseValidationErrors from 'views/declarations/ireland/ens/validation/parseValidationErrors';
import { goToDeclarationField } from 'views/declarations/utils/form-utils';
import useProducts from 'hooks/useProducts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShowRawDataDiv } from '../../DeclarationsNotificationsDrawer.styles';
import config from 'config/config';

const CONDITIONS_CODE_URL =
    'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.revenue.ie%2Fen%2Fonline-services%2Fsupport%2Fsoftware-developers%2Fdocuments%2Fimport-control-system%2Fvalidation-error-codes.xls&wdOrigin=BROWSELINK';
const RULE_DETAILS_URL =
    'https://www.revenue.ie/en/online-services/support/software-developers/documents/import-control-system/import-control-system.pdf';

const { Text, Title, Link } = Typography;

const ErrorsList = ({
    errors,
    error,
    onShowDetails,
}: {
    errors?: IrelandEnsSubmissionErrors[];
    error?: IrelandEnsSubmissionErrors | null;
    onShowDetails?: (error: IrelandEnsSubmissionErrors) => void;
}) => {
    const getErrorCode = useCallback((description: string | undefined) => description?.substring(6), []);

    if (error) {
        const { errorReason, externalField } = error;
        return (
            <MainGrid columns="150px 80px auto max-content">
                <Text>{externalField ?? 'n/a'}</Text>
                {errorReason ? (
                    <Error style={{ width: 100 }}>{getErrorCode(errorReason)}</Error>
                ) : (
                    <Error>{'n/a'}</Error>
                )}
                <Text></Text>
            </MainGrid>
        );
    }

    return (
        <>
            {errors?.map((_error) => {
                const { externalField, errorReason } = _error;
                return (
                    <MainGrid columns="150px 80px auto max-content">
                        <Text>{externalField ?? 'n/a'}</Text>
                        {errorReason ? (
                            <Error style={{ width: 100 }}>{getErrorCode(errorReason)}</Error>
                        ) : (
                            <Error>{'n/a'}</Error>
                        )}
                        <Text></Text>
                        <div>
                            <ButtonText onClick={() => onShowDetails?.(_error)}>Show details</ButtonText>
                        </div>
                    </MainGrid>
                );
            })}
        </>
    );
};

const DetailedError = ({
    error,
    onBack: handleBack,
    onClose,
    errorsRender,
}: {
    error: IrelandEnsSubmissionErrors | null;
    onBack: () => void;
    onClose?: () => void;
    errorsRender: ReactNode;
}) => {
    const { products } = useProducts();
    const { declaration } = useDeclarations();
    const navigate = useNavigate();
    const location = useLocation();
    const inViewOnly = useMemo(() => location.pathname.includes('view-only'), [location.pathname]);

    const fieldName = useMemo(() => {
        if (!error) return;
        let name = EnsCodeToFieldMap.get(error.errorReason.substring(0, 6))?.name;
        if (!name) return;

        const itemRegex = new RegExp(/GDS(\d+)/, 'g');
        const itemNumber = itemRegex.exec(error.externalField);
        if (itemNumber != null) {
            name = `goodsShipment.goodsShipmentItem.${itemNumber}.${name}`;
        }

        return name;
    }, [error]);
    const field = useMemo(() => {
        if (!error) return undefined;
        let name = fieldName;
        if (name) {
            name = getFieldName(ieEnsParseValidationErrors, name, 'goodsShipment.goodsShipmentItem');
        } else {
            name = error.externalField;
        }
        return name;
    }, [error, fieldName]);
    const handleGoToField = useCallback(() => {
        if (!field || !error) return;

        let name = EnsCodeToFieldMap.get(error.errorReason.substring(0, 6))?.name;
        if (!name) return;

        if (name.includes('goodsShipment.goodsShipmentItem')) {
            const productNumber = Number(name.split('.')[2]) - 1;
            const product = products?.list.at(productNumber);

            name = name.split('.').slice(3).join('.');

            navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}/products/${product!.id}`);
        } else if (location.pathname.split('/').at(-1) !== declaration?.id) {
            navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}`);
        }

        onClose?.();

        goToDeclarationField(name, 100);
    }, [declaration?.id, error, field, inViewOnly, location.pathname, navigate, onClose, products?.list]);
    return (
        <>
            <Button onClick={handleBack}>Back to list</Button>
            <Title level={3} style={{ marginTop: '2rem', fontWeight: 700 }}>
                Error details
            </Title>
            <DetailsGrid style={{ marginTop: '2rem' }}>
                <Text strong>Field:</Text>
                {field ? <FieldLink onClick={handleGoToField}>{field}</FieldLink> : <Text>{field ?? 'n/a'}</Text>}
            </DetailsGrid>
            <Divider />
            <>
                <NotificationHeader
                    columnsStyle="150px 80px auto max-content"
                    columns={['External Field', 'Error Code', 'Description', '']}
                />
                {errorsRender}
            </>
            <Link style={{ marginTop: '5rem', display: 'inline-block' }} href={CONDITIONS_CODE_URL} target="_blank">
                ENS validation error codes
            </Link>
        </>
    );
};

const PreviousErrors = ({ notifications }: { notifications?: IrelandEnsNotification[] }): ReactElement | null => {
    const { declarationId } = useParams();
    const { declarationHistory, getDeclarationHistory } = useDeclarations();
    const lastDateOfSubmit = useMemo(() => getLastDateOfSubmit(declarationHistory), [declarationHistory]);
    const [error, setError] = useState<IrelandEnsSubmissionErrors | null>(null);

    useEffect(() => {
        if (declarationId) getDeclarationHistory(declarationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const notificationsWithErrorsAfterSubmit = useMemo(() => {
        const _notifications = notifications && [...notifications];
        _notifications?.pop();
        _notifications?.reverse();
        return _notifications?.filter(
            (notification) =>
                lastDateOfSubmit &&
                notification?.notificationDate &&
                !isEmpty(notification.submissionErrors) &&
                lastDateOfSubmit < notification?.notificationDate
        );
    }, [notifications, lastDateOfSubmit]);

    const defaultActiveKey = useMemo(() => {
        return Array(notificationsWithErrorsAfterSubmit?.length)
            .fill(null)
            .map((_, index) => index);
    }, [notificationsWithErrorsAfterSubmit?.length]);

    const notificationsList = useMemo(() => {
        if (!notificationsWithErrorsAfterSubmit || !notificationsWithErrorsAfterSubmit.length)
            return 'No previous errors';
        return (
            <Collapse defaultActiveKey={defaultActiveKey}>
                {notificationsWithErrorsAfterSubmit?.map((notification, index) => {
                    return (
                        <Collapse.Panel
                            header={new Date(notification.notificationDate ?? '').toLocaleString()}
                            key={index}
                        >
                            {error ? (
                                <DetailedError
                                    error={error}
                                    onBack={() => setError(null)}
                                    errorsRender={<ErrorsList error={error} />}
                                />
                            ) : (
                                <>
                                    <NotificationHeader />
                                    <ErrorsList
                                        errors={notification.submissionErrors}
                                        onShowDetails={(err: IrelandEnsSubmissionErrors) => setError(err)}
                                    />
                                </>
                            )}
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        );
    }, [defaultActiveKey, error, notificationsWithErrorsAfterSubmit]);

    if (isEmpty(notificationsList)) return null;

    return (
        <Collapse defaultActiveKey="1">
            <Collapse.Panel header="Previous errors" key={1}>
                {notificationsList}
            </Collapse.Panel>
        </Collapse>
    );
};

const IrelandEnsDeclarationErrors = ({
    errors,
    notifications,
    onClose,
    latestNotification,
    showRawData: showRawDataProp = false,
}: SpecificDeclarationErrorsProps) => {
    const { t } = useTranslation('common');
    const [selectedError, setSelectedError] = useState<IrelandEnsSubmissionErrors | null>();
    const [showRawData, setShowRawData] = useState<boolean>(showRawDataProp);

    if (!!selectedError) {
        return (
            <DetailedError
                error={selectedError}
                onBack={() => setSelectedError(null)}
                onClose={onClose}
                errorsRender={<ErrorsList error={selectedError} />}
            />
        );
    }

    if (!!showRawData) {
        return (
            <>
                {!showRawDataProp && <Button onClick={() => setShowRawData(false)}>Back to list</Button>}
                <Title level={3} style={{ marginTop: '2rem', fontWeight: 700 }}>
                    Error details
                </Title>
                <div>{latestNotification?.rawMessage}</div>
            </>
        );
    }

    return (
        <>
            {config.isPIT && (
                <ShowRawDataDiv>
                    <ButtonText onClick={() => setShowRawData(true)}>Show raw data</ButtonText>
                </ShowRawDataDiv>
            )}
            <LinkButton href={RULE_DETAILS_URL} target="_blank" style={{ marginBottom: 20 }}>
                {t('ruleDetails')}
            </LinkButton>
            <NotificationHeader
                columnsStyle="150px 80px auto max-content"
                columns={['External Field', 'Error Code', 'Description', '']}
            />
            <ErrorsList errors={errors} onShowDetails={(err: IrelandEnsSubmissionErrors) => setSelectedError(err)} />
            <div style={{ marginTop: '3rem' }}>
                <PreviousErrors notifications={notifications} />
            </div>
        </>
    );
};

export default IrelandEnsDeclarationErrors;

export const getLastDateOfSubmit = (declarationHistory: DeclarationHistory[] | undefined) => {
    if (!declarationHistory) return;

    const filteredDeclarationHistory = declarationHistory?.filter((history) => history.type === 'SUBMIT');
    const lastDeclarationHistoryIndex = declarationHistory?.length && declarationHistory?.length - 1;

    const dateOfLastSubmit = sortBy(filteredDeclarationHistory, 'createdAt')[lastDeclarationHistoryIndex!]?.createdAt;

    return dateOfLastSubmit;
};
