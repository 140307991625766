import { FormikProps, FormikProvider } from 'formik';
import { FC } from 'react';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import { TaricDetailsType } from 'views/declarations/common/taric-codes/TaricCodesCard';
import { updateIrelandH1ImportTaricCode } from 'views/declarations/common/taric-codes/utils';
import AdditionalInformationCard from './cards/AdditionalInformationCard';
import ExtraItemDetailsCard from './cards/products/ExtraItemDetailsCard';
import GoodsInformationCard from './cards/products/GoodsInformationCard';
import ItemDetailsCard from './cards/products/ItemDetailsCard';
import ItemValueDetailsCard from './cards/products/ItemValueDetailsCard';
import OtherItemDetailsCard from './cards/products/OtherItemDetailsCard';
import PackagesCard from './cards/products/PackagesCard';
import SimplifiedDeclarationDocumentWritingOffItemCard from './cards/products/SimplifiedDeclarationDocumentWritingOffItemCard';
import SupplyChainActorCard from './cards/SupplyChainActorCard';
import AdditionsDeductionsCard from './cards/AdditionsDeductionsCard';
import ContainerIdentificationCard from './cards/ContainerIdentificationCard';
import TaxBoxCard from './cards/products/TaxBoxCard';
import FiscalReferenceCard from '../../../common/fiscal-reference/FiscalReferenceCard';
import { DeclarationName } from '../../../../../store/declarations/enums/common/declaration-name';

interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    productTemplate?: boolean;
    selectCard?: (id: string) => void;
}

const IrelandH1ProductsSection: FC<Props> = ({ toggleHelp, formik, viewOnly, productTemplate, selectCard }) => {
    return (
        <FormikProvider value={formik}>
            <GoodsInformationCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={1}
                keyCard={'goods-information-card'}
                selectCard={selectCard}
                box44Items={formik.getFieldProps('producedDocumentsWritingOff').value}
                country={DeclarationCountry.IRELAND}
                detailsType={productTemplate ? TaricDetailsType.BOTH : TaricDetailsType.IMPORT}
                updateTaricCode={(code) => updateIrelandH1ImportTaricCode(formik, code)}
            />
            <ItemDetailsCard
                viewOnly={viewOnly}
                formik={formik}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={2}
                keyCard={'item-details-card'}
                selectCard={selectCard}
            />

            <PackagesCard
                viewOnly={viewOnly}
                keyCard={`package-card`}
                propsPathPrefix={`goodsInformation.packaging`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={3}
                selectCard={selectCard}
            />
            <ItemValueDetailsCard
                viewOnly={viewOnly}
                keyCard={`item-value-details-card`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={4}
                selectCard={selectCard}
            />
            <OtherItemDetailsCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={5}
                keyCard={`other-item-details-card`}
                selectCard={selectCard}
            />

            <ExtraItemDetailsCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={7}
                keyCard={`extra-item-details-card`}
                selectCard={selectCard}
            />
            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                declarationType={DeclarationName.H1}
                parties={[
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: '3.1',
                    },
                    {
                        path: 'seller',
                        header: 'Seller',
                        refNumber: '3.24',
                    },
                    {
                        path: 'buyer',
                        header: 'Buyer',
                        refNumber: '3.26',
                    },
                ]}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                }}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={8}
                keyCard={`parties-card`}
                condensed
            />

            <SimplifiedDeclarationDocumentWritingOffItemCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                keyCard={`simplified-declaration-document-writing-off-item-card`}
                cardTotal={11}
                cardNumber={9}
                selectCard={selectCard}
            />
            <AdditionalInformationCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                propsPathPrefix="additionalInformation"
                keyCard={`additional-information-card`}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={10}
                selectCard={selectCard}
            />

            <SupplyChainActorCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                propsPathPrefix="supplyChainActor"
                keyCard={`supply-chain-actor`}
                toggleHelp={toggleHelp}
                cardTotal={11}
                formik={formik}
                cardNumber={11}
                selectCard={selectCard}
            />

            <AdditionsDeductionsCard
                productDisabled={true}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                propsPathPrefix="additionsDeductions"
                keyCard={`addition-deductions`}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={12}
                selectCard={selectCard}
            />

            <FiscalReferenceCard
                formik={formik}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                propsPathPrefix="additionalFiscalReference"
                keyCard={`addition-deductions`}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={13}
                selectCard={selectCard}
                declarationCountry={DeclarationCountry.IRELAND}
            />

            <TaxBoxCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                propsPathPrefix="taxBox43Bis"
                keyCard={`tax-box`}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={14}
                selectCard={selectCard}
            />

            <ContainerIdentificationCard
                formik={formik}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                keyCard={`addition-deductions`}
                toggleHelp={toggleHelp}
                cardTotal={11}
                cardNumber={15}
                selectCard={selectCard}
            />
        </FormikProvider>
    );
};
export default IrelandH1ProductsSection;
