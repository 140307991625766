import { ParsedCard } from '../../../../../store/declaration-form-errors/ParsedForm';
import { UkGvmsRecord } from '../../../../../store/declarations/uk/gvms-declaration';
import { parseFieldErrorsForCard } from '../../../../../utils/validationParserUtils';

const pbnRecordParser = (record: UkGvmsRecord) => {
    const masterDetailsParsedCards: ParsedCard[] = [];

    parseFieldErrorsForCard(
        'Master Details',
        [
            { direction: 'Movement Direction' },
            { emailId: 'Email Address' },
            { mobileNum1: 'Mobile Number 1' },
            { mobileNum2: 'Mobile Number 2' },
            { emptyVehicle: 'Vehicle is Empty?' },
            { sAndSMasterRefNum: 'S&S MRN' },
            { 'declaration.0.mrn': 'Customs Declarations 1' },
            { 'declaration.1.mrn': 'Customs Declarations 2' },
            { 'declaration.2.mrn': 'Customs Declarations 3' },
            { 'declaration.3.mrn': 'Customs Declarations 4' },
            { 'declaration.4.mrn': 'Customs Declarations 5' },
            { 'declaration.5.mrn': 'Customs Declarations 6' },
            { 'declaration.6.mrn': 'Customs Declarations 7' },
            { 'declaration.7.mrn': 'Customs Declarations 8' },
            { 'declaration.8.mrn': 'Customs Declarations 9' },
            { 'declaration.9.mrn': 'Customs Declarations 10' },
            // Hotfix - Handle all indexes of MultipleDeclarationField
        ],
        masterDetailsParsedCards,
        record
    );

    return {
        masterDetails: masterDetailsParsedCards,
        items: [],
    };
};

export default pbnRecordParser;
