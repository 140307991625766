import config from 'config';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { DeclarationJobInfo, JobResponse } from './job';

export const createJob = (customerId?: string): Promise<JobResponse> =>
    axiosClient.post<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs`, { customerId }).then((response) => {
        return response.data.payload;
    });

export const listJobs = (params: any): Promise<ListPayload<JobResponse>> =>
    axiosClient.get<ListResponse<JobResponse>>(`${config.jobsUrl}/jobs`, { params }).then((response) => {
        return response.data.payload;
    });

export const listJobsWithDeclarations = (params: {
    page: number;
    size: number;
}): Promise<ListPayload<DeclarationJobInfo>> => {
    return axiosClient
        .get<ListResponse<DeclarationJobInfo>>(`${config.declarationsUrl}/declarations/job/list/info`, { params })
        .then((response) => {
            return response.data.payload;
        });
};

export const getJob = (jobId: string): Promise<JobResponse> =>
    axiosClient.get<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs/${jobId}`).then((response) => {
        return response.data.payload;
    });

export const editJob = (jobId: string, job: JobResponse): Promise<JobResponse> =>
    axiosClient.put<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs/${jobId}`, job).then((response) => {
        return response.data.payload;
    });

export const listTags = (params?: any): Promise<string[]> =>
    axiosClient.get<string[]>(`${config.jobsUrl}/jobs/tags`, { params }).then((response) => {
        return response.data;
    });

export const deleteJob = (jobId: string): Promise<void> =>
    axiosClient.delete<SuccessResponse<void>>(`${config.jobsUrl}/jobs/${jobId}`).then((response) => {
        return response.data.payload;
    });

export const assignJob = (jobId: string, job: JobResponse): Promise<JobResponse> =>
    axiosClient.put<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs/${jobId}/assign`, job).then((response) => {
        return response.data.payload;
    });

export const closeJob = (jobId: string): Promise<JobResponse> =>
    axiosClient.put<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs/${jobId}/close`).then((response) => {
        return response.data.payload;
    });

export const acceptJob = (jobId: string): Promise<JobResponse> =>
    axiosClient.put<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs/${jobId}/accept`).then((response) => {
        return response.data.payload;
    });

export const startJob = (jobId: string): Promise<JobResponse> =>
    axiosClient.put<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs/${jobId}/start`).then((response) => {
        return response.data.payload;
    });

export const rejectJob = (jobId: string): Promise<JobResponse> =>
    axiosClient.put<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs/${jobId}/reject`).then((response) => {
        return response.data.payload;
    });

export const completeJob = (jobId: string): Promise<JobResponse> =>
    axiosClient.put<SuccessResponse<JobResponse>>(`${config.jobsUrl}/jobs/${jobId}/complete`).then((response) => {
        return response.data.payload;
    });
