import { TextExtraSmallBold } from 'components/ui/base/typography';
import { InputLabelRequired } from 'components/ui/composed/formInput/FormInput.styles';
import { useFormik } from 'formik';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import DeclarationInput from 'components/ui/composed/declarations/formInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect';

type Props = {
    formik: Pick<ReturnType<typeof useFormik>, 'getFieldProps' | 'getFieldMeta'>;
};

const CustomerContactForm = (props: Props) => {
    return (
        <>
            <DeclarationInput
                required
                label="Contact name"
                fieldMeta={props.formik.getFieldMeta('name')}
                fieldProps={props.formik.getFieldProps('name')}
            />
            <p>
                <InputLabelRequired>*</InputLabelRequired>{' '}
                <TextExtraSmallBold>An email and/or Phone number is required</TextExtraSmallBold>
            </p>
            <DeclarationInput
                required
                label="Email Address"
                fieldMeta={props.formik.getFieldMeta('email')}
                fieldProps={props.formik.getFieldProps('email')}
            />
            <FormPhoneNumber
                required
                fieldCodeProps={props.formik.getFieldProps('phoneCountryCode')}
                fieldCodeMeta={props.formik.getFieldMeta('phoneCountryCode')}
                fieldNumberProps={props.formik.getFieldProps('phoneNumber')}
                fieldNumberMeta={props.formik.getFieldMeta('phoneNumber')}
            />
            <DeclarationSelect
                required
                hideKeys
                label="Role"
                fieldMeta={props.formik.getFieldMeta('role')}
                fieldProps={props.formik.getFieldProps('role')}
                selectOptions={[
                    {
                        id: 'logistics-coordinator',
                        value: 'Logistics Coordinator',
                    },
                    {
                        id: 'fleet-manager',
                        value: 'Fleet Manager',
                    },
                    {
                        id: 'warehouse-manager',
                        value: 'Warehouse Manager',
                    },
                    {
                        id: 'operations-manager',
                        value: 'Operations Manager',
                    },
                    {
                        id: 'admin',
                        value: 'Admin',
                    },
                    {
                        id: 'other',
                        value: 'Other',
                    },
                ]}
                hideCodelistMenu
            />
        </>
    );
};

export default CustomerContactForm;
