import { MessageTypes } from '../declarations/enums/common/declaration-types';

export interface MetaData {
    isViewable?: boolean;
    isEditable?: boolean;
}
export interface TemplatePartData {
    defaults: Record<string, string>;
    meta: Record<string, MetaData>;
}
export interface TemplateData {
    master: TemplatePartData;
    product: TemplatePartData;
}
export interface Template {
    id?: string;
    templateName?: string;
    template?: TemplateData;
    declarationInternalType?: string;
    declarationExternalType?: string;
    declarationName?: MessageTypes;
    customerId?: string;
}
export type TemplateResponse = Template & Pick<Required<Template>, 'id'>;

export enum TemplateSortParameter {
    ID = 'ID',
    TEMPLATE_NAME = 'TEMPLATE_NAME',
    DECLARATION_NAME = 'DECLARATION_NAME',
    DECLARATION_EXTERNAL_TYPE = 'DECLARATION_EXTERNAL_TYPE',
    CUSTOMER_ID = 'CUSTOMER_ID',
}
