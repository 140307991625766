import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useCurrencies from 'hooks/useCurrencies';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCurrenciesToSelect } from '../selects/utils';
import FormSelect, { GroupSelectOption, SelectOption } from './formSelect/DeclarationSelect';
import { SelectProps } from 'antd';
import useCodelists from '../../../../hooks/useCodelists';
import useFormUtils from '../../../../hooks/useFormUtils';
import { TestProps } from 'core/utils/testTypes';
import config from 'config/config';

export interface CurrencyProps extends SelectProps, TestProps {
    refNumber?: string;
    label?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    required?: boolean;
    viewOnly?: boolean;
    disabled?: boolean;
    tooltip?: string;
    refClicked?: (refNumber: string | number) => void;
    condensed?: boolean;
    hidden?: boolean;
    selectOptions?: Readonly<SelectOption[]> | Readonly<GroupSelectOption>;
}

/**
 * @argument selectOptions -  used only for AES
 */
const CurrencySelect: FC<CurrencyProps> = ({
    fieldProps,
    refNumber,
    label,
    fieldMeta,
    required,
    fieldHelper,
    viewOnly,
    disabled,
    tooltip,
    refClicked,
    condensed,
    testId,
    selectOptions,
    ...selectProps
}) => {
    const { t } = useTranslation('form');
    const { currencies } = useCurrencies();
    const { aisCodelists, cdsCodelists, aesCodelists } = useCodelists();

    const { isAis, isCds, isAes } = useFormUtils();

    const currencyCodelist = useMemo(() => {
        if (!config.isPIT) {
            if (selectOptions) return selectOptions;
            else if (isAis) {
                return aisCodelists?.currencies;
            } else if (isCds) {
                return cdsCodelists?.currencyTypes;
            } else if (isAes) {
                return aesCodelists?.invoiceCurrency;
            } else {
                return normalizeCurrenciesToSelect(currencies.list);
            }
        } else {
            if (isCds) {
                return [
                    { id: 'USD', value: 'US Dollar' },
                    { id: 'EUR', value: 'Euro' },
                    { id: 'GBP', value: 'Sterling' },
                ];
            } else {
                return [
                    { id: 'USD', value: 'US Dollar' },
                    { id: 'EUR', value: 'Euro' },
                ];
            }
        }
    }, [
        selectOptions,
        currencies.list,
        aisCodelists?.currencies,
        cdsCodelists?.currencyTypes,
        isAis,
        isCds,
        isAes,
        aesCodelists?.invoiceCurrency,
    ]);

    return (
        <FormSelect
            disabled={disabled}
            viewOnly={viewOnly}
            required={required}
            refNumber={refNumber}
            label={t(label || 'currency')}
            selectOptions={currencyCodelist}
            fieldProps={fieldProps}
            fieldMeta={fieldMeta}
            fieldHelper={fieldHelper}
            refClicked={refClicked}
            tooltip={tooltip}
            condensed={condensed}
            testId={testId}
            hideCodelistMenu
            {...selectProps}
        />
    );
};

export default CurrencySelect;
