import { Form, notification } from 'antd';
import PhoneNumber from 'awesome-phonenumber';
import Button from 'components/ui/base/button';
import { FormItem } from 'components/ui/base/form';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { useFormik } from 'formik';
import useLoadingButton from 'hooks/useLoadingButton';
import useRequest from 'hooks/useRequest';
import useSession from 'hooks/useSession';
import { FC, useEffect, useMemo } from 'react';
import { createIndividual, editIndividual } from 'store/individuals/client';
import { Individual } from 'store/individuals/individual';
import * as Yup from 'yup';
import { PaginatedParams } from '../../../core/http/pagination';
import useIndividuals from '../../../hooks/useIndividuals';
import { getNumberValidation } from '../../declarations/utils/validation-utils';
import CustomerAddressForm from 'views/customers/components/CustomerAddressForm';

interface Props {
    closeDrawer: () => void;
    individual?: Individual;
    registeredUsers?: Individual[];
    paginator?: Partial<PaginatedParams>;
    roleSelectOptions?: any;
}

const individualSchema = (registeredUsers: Individual[] | undefined, editingUser?: Individual) => {
    return Yup.object().shape({
        fullName: Yup.string().required('Full name is required.'),
        email: Yup.string()
            .email('Email is invalid.')
            .required('Email is required.')
            .test('email-used', 'This email already exists.', (val: any) => {
                if (editingUser && val === editingUser.email) {
                    return true;
                }
                return val ? !registeredUsers?.some((user: Individual) => user.email === val) : true;
            }),
        type: Yup.string().required('Role is required.'),
        phoneCountryCode: Yup.string().nullable(),
        phoneNumber: getNumberValidation().when(
            'Phone number is required',
            (phoneCountryCode) =>
                phoneCountryCode &&
                getNumberValidation()
                    .required('Phone number is required')
                    .test('Phone number test', 'Phone number is invalid', (value?: number) => {
                        if (value) {
                            const phoneNumber = new PhoneNumber(`${phoneCountryCode}${value}`);
                            return phoneNumber.isValid();
                        }
                        return false;
                    })
        ),
    });
};

const CreateNewIndividual: FC<Props> = ({ closeDrawer, individual, registeredUsers, paginator, roleSelectOptions }) => {
    const { userInfo } = useSession();
    const { listIndividuals } = useIndividuals();

    const { getFieldProps, resetForm, getFieldMeta, submitForm, setValues, isValid, getFieldHelpers, errors } =
        useFormik<Partial<Individual & { phoneNumber?: string; phoneCountryCode?: string }>>({
            initialValues: {},
            validationSchema: individualSchema(registeredUsers, individual),
            validateOnMount: true,
            enableReinitialize: true,
            validateOnChange: true,
            onSubmit: (values) => createNewIndividualSubmit(values),
        });
    const { loading, handleOnPressButton } = useLoadingButton({ onSubmit: submitForm });
    const { doRequest: doCreateIndividual } = useRequest(createIndividual);

    const { doRequest: doEditIndividual } = useRequest(editIndividual);

    const createNewIndividualSubmit = async (
        values: Partial<Individual & { phoneCountryCode?: string; phoneNumber?: string }>
    ) => {
        let phone;

        if (values?.phoneCountryCode && values?.phoneNumber) {
            phone = `${values.phoneCountryCode}${values.phoneNumber}`;
        }

        const body = {
            fullName: values.fullName,
            address: values.address,
            phone: phone,
            email: values.email,
            type: values.type,
            isInvited: true,
            customerId: userInfo?.customerId,
        };

        if (individual && individual.id) {
            doEditIndividual(individual.id, body)
                .then(() => {
                    notification.success({ message: 'User has been successfully edited!' });
                    listIndividuals(paginator);
                    closeDrawer();
                })
                .catch(() => {
                    notification.error({ message: 'Failed to edit customer!' });
                });
        } else {
            doCreateIndividual(body as Individual)
                .then(() => {
                    notification.success({ message: 'User has been successfully created!' });
                    listIndividuals(paginator);
                    closeDrawer();
                })
                .catch(() => {
                    notification.error({ message: 'Failed to create customer!' });
                });
        }
    };

    useEffect(() => {
        if (individual) {
            setValues(individual);
            if (individual.phone) {
                const phone = new PhoneNumber(individual.phone);
                const data = {
                    ...individual,
                    phoneCountryCode: `+${phone.getCountryCode().toString()}`,
                    phoneNumber: phone.getNumber('significant'),
                };
                setValues(data);
            }
        } else {
            setValues({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [individual]);

    const onCloseButton = () => {
        closeDrawer();
        resetForm();
    };

    const emailDisabled = useMemo(() => {
        return !!individual && !!individual.id;
    }, [individual]);

    return (
        <Form layout="vertical">
            <FormInput
                required
                label="Full name"
                placeholder="Enter User Name"
                fieldMeta={getFieldMeta('fullName')}
                fieldProps={getFieldProps('fullName')}
            />
            <FormInput
                disabled={emailDisabled}
                required
                label="Email Address"
                placeholder="Enter Email Address"
                fieldMeta={getFieldMeta('email')}
                fieldProps={getFieldProps('email')}
            />

            {emailDisabled && (
                <FormInput
                    disabled={true}
                    label="Registered"
                    fieldMeta={getFieldMeta('registered')}
                    fieldProps={{
                        ...getFieldProps('registered'),
                        value: individual?.registered ? 'Yes' : 'No',
                    }}
                />
            )}

            <FormSelect
                required
                label="Role"
                hideKeys
                disabled={individual?.id === userInfo?.individualId}
                placeholder="Select Role"
                fieldMeta={getFieldMeta('type')}
                fieldProps={getFieldProps('type')}
                fieldHelper={getFieldHelpers('type')}
                selectOptions={roleSelectOptions}
            />

            <CustomerAddressForm
                fieldProps={{
                    addressLine1: getFieldProps('address.addressLine1'),
                    addressLine2: getFieldProps('address.addressLine2'),
                    postCode: getFieldProps('address.postCode'),
                    city: getFieldProps('address.city'),
                    country: getFieldProps('address.country'),
                }}
                fieldMeta={{
                    addressLine1: getFieldMeta('address.addressLine1'),
                    addressLine2: getFieldMeta('address.addressLine2'),
                    postCode: getFieldMeta('address.postCode'),
                    city: getFieldMeta('address.city'),
                    country: getFieldMeta('address.country'),
                }}
                label={{
                    addressLine1: 'Address Line 1',
                    addressLine2: 'Address Line 2',
                    postCode: 'Post Code',
                    city: 'City',
                    country: 'Country',
                }}
                required={{
                    addressLine1: false,
                    postCode: false,
                    city: false,
                    country: false,
                }}
            />

            <FormPhoneNumber
                fieldCodeProps={getFieldProps('phoneCountryCode')}
                fieldCodeMeta={getFieldMeta('phoneCountryCode')}
                fieldCodeHelper={getFieldHelpers('phoneCountryCode')}
                fieldNumberProps={getFieldProps('phoneNumber')}
                fieldNumberMeta={getFieldMeta('phoneNumber')}
                placeholder="Enter Phone Number"
            />

            <FormItem style={{ textAlign: 'right', marginTop: '3.6rem' }}>
                <Button size="large" style={{ marginRight: '1.6rem' }} onClick={() => onCloseButton()}>
                    Cancel
                </Button>
                <Button disabled={!isValid} size="large" type="primary" onClick={handleOnPressButton} loading={loading}>
                    Confirm
                </Button>
            </FormItem>
        </Form>
    );
};
export default CreateNewIndividual;
