import { TextSmall, TextSmallBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const GreyText = styled(TextSmall)`
    color: ${colors.darkGrey};
    margin-bottom: 0.8rem;
`;

export const Icon = styled.img`
    margin-right: 1rem;
`;

export const StyledBoldText = styled(TextSmallBold)`
    margin-right: 2.35rem;
`;

export const StyledLink = styled.a`
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #1890ff;
`;

export const DivDownloadTemplate = styled.div`
    color: ${colors.blueLink};
    display: flex;
    justify-content: end;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;
export const IndividualImportErrorContainer = styled.ul``;
export const IndividualImportErrorRow = styled.li`
    list-style-type: none;
`;
export const IndividualImportErrorText = styled.p``;
