import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { TextExtraSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

interface ActiveProp {
    active: boolean;
}

export const StyledSpan = styled.span`
    margin-right: 0.8rem;
`;

export const InfoDiv = styled.div<{ paddingLeft?: number }>`
    display: flex;
    margin-bottom: 0.8rem;
    padding-left: ${({ paddingLeft }) => (paddingLeft ? `${paddingLeft}rem` : 0)};
`;

export const StyledDiv = styled.div<ActiveProp>`
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 4rem;
    width: 23.2rem;
    padding-left: 2.8rem;
    color: ${({ active }) => (active ? colors.white : colors.darkPurple)};
    background: ${({ active }) => (active ? colors.darkPurple : colors.white)};
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.4rem;
    line-height: 2.2rem;
    &:hover {
        color: ${({ active }) => (active ? colors.white : colors.darkPurple)};
    }
`;

export const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.8rem 3.1rem;
    width: 71.1rem;
    min-height: 25.6rem;
    border: 1px solid ${colors.lightGrey2};
    box-sizing: border-box;
    border-radius: 4px;
`;

export const StyledInfoDiv = styled.div`
    margin-bottom: 1.6rem;
    display: flex;
`;

export const StyledTextExtraSmall = styled(TextExtraSmall)`
    color: ${colors.lightGrey3};
    font-weight: ${fonts.weight.regular};
    white-space: pre-wrap;
`;

export const HmrcIntro = styled(Col)`
    text-align: justify;
`;

export const RowConnectToHmrc = styled(Row)`
    margin-top: 9.1rem;
`;

export const ConnectHmrc = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${colors.purpleBlue};
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const DisconnectHmrc = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${colors.purpleBlue};
    margin-top: 4.4rem;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const HmrcConnectedContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

export const Break = styled.div`
    flex-basis: 100%;
    height: 0;
`;

export const ConnectedIcons = styled(CheckCircleFilled)`
    font-size: 5.2rem;
    color: ${colors.polarGreen};
    margin-top: 5.3rem;
    margin-bottom: 2.4rem;
`;

export const SettingsRow = styled(Row)`
    height: calc(100% - 8.6rem);
`;

export const ContentContainer = styled.div`
    width: calc(100% - 39rem);
    margin-left: 4.8rem;
    margin-top: 5.7rem;
`;

export const NavContainer = styled.div`
    width: 32.4rem;
    border-right: 1px solid ${colors.lightGrey2};
    margin-top: 1.3rem;
`;
