import { TR015VNotification } from 'store/declarations/ireland/ncts-declaration';
import { ErrorRow } from '../../declaration-errors/ais/RevenueImportErrorDetails';
import moment from 'moment';

const TR015V = (props: { notification: TR015VNotification['notification'] }) => {
    return (
        <div>
            <h2>Declaration</h2>
            <div>
                <ErrorRow
                    label="Declaration Acknowledgment Date: "
                    value={moment(props.notification.Declaration.declarationAcknowledgmentDate).format()}
                />
            </div>
        </div>
    );
};

export default TR015V;
