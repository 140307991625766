import { Col } from 'antd';
import { TextBold, TextLarge } from 'components/ui/base/typography';
import { FC, useMemo } from 'react';
import { TaricCodeSection } from 'store/taric-codes/taric-code';
import { SectionsRow } from '../TaricCode.styles';
import ListSection from './ListSection';
interface Props {
    sections: TaricCodeSection[];
    searchCode: (code: string) => void;
}
const TaricCodesSections: FC<Props> = ({ sections, searchCode }) => {
    const listSections = useMemo(
        () =>
            sections?.map((s, i) => (
                <ListSection
                    key={`taric-code-sections-${s.title}-${i}-${s.chapters}-${s.number}`}
                    section={s}
                    searchCode={searchCode}
                />
            )),
        [sections, searchCode]
    );
    return (
        <>
            <TextLarge>All Sections</TextLarge>
            <SectionsRow align="middle">
                <Col span={20}>
                    <TextBold>Section title</TextBold>
                </Col>
                <Col span={2}>
                    <TextBold>Section</TextBold>
                </Col>
                <Col span={2}>
                    <TextBold>Chapters</TextBold>
                </Col>
            </SectionsRow>
            {listSections}
        </>
    );
};
export default TaricCodesSections;
