import { Col, Row } from 'antd';
import SearchBar from 'components/ui/base/searchbar';
import OptionTooltip from 'components/ui/base/searchbar/search-options/OptionTooltip';
import { Text } from 'components/ui/base/typography';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationJobInfo } from 'store/jobs/job';
import {
    BlueText,
    CloseIcon,
    CustomDiv,
    CustomRow,
    InvoiceButton,
    ReverseCol,
    SectionTitle,
} from './InvoiceUpload.styles';

interface Props {
    disabled: boolean;
    createNewJob: Function;
    search: (query: string) => void;
    jobs?: DeclarationJobInfo[];
    onSelect?: (jobId: string) => void;
    onCancel?: () => void;
    onClear?: () => void;
    selectedJobId?: string;
}
const SelectJob: FC<Props> = (props) => {
    const { t } = useTranslation('customs_declarations');

    const jobsOptions = useMemo(() => {
        const getNumberOfDeclarationsOfJob = (jobId: string) => {
            const job = props.jobs?.find((j) => j.jobId === jobId);
            if (job) {
                return job.declarationsSize;
            }
            return 0;
        };
        const getJobExporter = (jobId: string) => {
            const job = props.jobs?.find((j) => j.jobId === jobId);
            if (!job) return '-';

            return job.exporter;
        };

        const getJobImporter = (jobId: string) => {
            const job = props.jobs?.find((j) => j.jobId === jobId);
            if (!job) return '-';

            return job.importer;
        };

        const options = props.jobs?.map((job) => ({
            value: job.jobId,
            label: (
                <Row justify="space-between" align="middle" wrap={false} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={4}>
                        <OptionTooltip data={job.jobReferenceId} />
                    </Col>
                    <Col>
                        <OptionTooltip data={`${getNumberOfDeclarationsOfJob(job.jobId)} declarations`} />
                    </Col>
                    <Col>
                        <OptionTooltip data={getJobExporter(job.jobId)} />
                    </Col>
                    <Col>
                        <OptionTooltip data={getJobImporter(job.jobId)} />
                    </Col>
                </Row>
            ),
        }));
        return [
            {
                label: <span>jobs</span>,
                options: options ?? [],
            },
        ];
    }, [props.jobs]);

    const selectedJobReferenceId = useMemo(() => {
        const job = props.jobs?.find((j) => j.jobId === props.selectedJobId);
        if (job) {
            return job.jobReferenceId;
        }
        return '';
    }, [props.jobs, props.selectedJobId]);

    return (
        <>
            <CustomRow>
                <Col span={16}>
                    <SectionTitle className={`${props.disabled && 'disabled'}`}>{t('job.searchOrCreate')}</SectionTitle>
                </Col>
                <ReverseCol span={8}>
                    <InvoiceButton
                        disabled={props.disabled}
                        size="large"
                        onClick={() => {
                            props.createNewJob();
                        }}
                    >
                        {t('job.create')}
                    </InvoiceButton>
                </ReverseCol>

                <CustomDiv>
                    {props.selectedJobId ? (
                        <Row align="middle">
                            <CloseIcon
                                className={`${props.disabled && 'disabled'}`}
                                onClick={() => {
                                    if (!props.disabled) {
                                        props.onCancel?.();
                                    }
                                }}
                            />
                            <Text>
                                {t('jobId')}: <BlueText>{selectedJobReferenceId}</BlueText>
                            </Text>
                        </Row>
                    ) : (
                        <SearchBar
                            options={jobsOptions}
                            onSelect={(value: string) => {
                                props.onSelect?.(value);
                            }}
                            onClear={() => {
                                props.onClear?.();
                            }}
                            inputPlaceholder={t(`job.searchJob`)}
                            onSearch={(e) => props.search(e)}
                        />
                    )}
                </CustomDiv>
            </CustomRow>
        </>
    );
};

export default SelectJob;
