import { FC, useCallback } from 'react';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useDeclarations from 'hooks/useDeclarations';
import Notification from 'components/ui/base/notification/Notification';
import FormInput from 'components/ui/composed/formInput/FormInput';
import { TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { getRequiredMessage } from 'views/declarations/utils/validation-utils';

const InfoText = styled(TextSmall)`
    margin-bottom: 1rem;
`;

const formValidation = Yup.object().shape({
    reason: Yup.string().required(getRequiredMessage('reason')),
});

interface Props {
    onCancel: () => void;
}

const InvalidateForm: FC<Props> = ({ onCancel }) => {
    const { invalidateDeclaration, error, isLoading, declaration } = useDeclarations();
    const { t } = useTranslation('customs_declarations');

    const formik = useFormik<{
        reason: string;
    }>({
        initialValues: {
            reason: '',
        },
        validationSchema: formValidation,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmit(values);
        },
        onReset: () => {
            onCancel();
        },
    });

    const handleInvalidate = useCallback(
        async (reason: string) => {
            if (!(declaration?.id && declaration?.declarationInternalType)) return;
            const res = await invalidateDeclaration(declaration.id, reason, declaration.declarationInternalType);
            if (res && !error && !isLoading) {
                onCancel();
                Notification({
                    type: 'success',
                    messageTitle: t('invalidate.invalidate-success-title'),
                    description: t('invalidate.invalidate-success-message'),
                });
                formik.resetForm();
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('invalidate.invalidate-error-title'),
                    description: t('invalidate.invalidate-error-message'),
                });
            }
        },
        [
            declaration?.id,
            onCancel,
            t,
            error,
            isLoading,
            invalidateDeclaration,
            declaration?.declarationInternalType,
            formik,
        ]
    );

    const handleSubmit = useCallback(
        async (values: { reason: string }) => {
            await handleInvalidate(values.reason);
        },
        [handleInvalidate]
    );

    return (
        <FormikProvider value={formik}>
            <form id="invalidateForm" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <InfoText>Please enter the reason to invalidate the declaration.</InfoText>
                <FormInput
                    label="Reason"
                    fieldProps={formik.getFieldProps('reason')}
                    fieldMeta={formik.getFieldMeta('reason')}
                />
            </form>
        </FormikProvider>
    );
};

export default InvalidateForm;
