import { Col, Divider, Row } from 'antd';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { BtMasterGoodsNotSelected, BtMasterGoodsSelected, InvalidIcon, NumberItemsRounderInfo } from '../Form.styles';
import { FormAction } from '../utils/form-utils';
import { isEmpty } from 'lodash';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import DeclarationInformation from './DeclarationInformation';
import useDeclarations from 'hooks/useDeclarations';

interface Props {
    selectedBt: number;
    setNewProduct?: Function;
    handleCreateDeclarationProduct?: () => Promise<void>;
    setShowProducts?: Function;
    viewOnly?: boolean;
    amendment?: boolean;
}
export const MasterProductDeclarationNav: FC<Props> = ({
    selectedBt,
    setNewProduct,
    handleCreateDeclarationProduct,
    setShowProducts,
    viewOnly,
    amendment,
}) => {
    const { setDeclarationFooterType } = useDeclarationFooter();
    const { declaration } = useDeclarations();
    const {
        setFormAction,
        setHasUnsavedChanges,
        declarationValidation: { hasUnsavedChanges },
    } = useDeclarationValidation({
        formAction: null,
    });
    const navigate = useNavigate();
    const { declarationId }: { declarationId?: string } = useParams();
    const { products } = useProducts();
    const { declarationErrors: formDeclarationErrors } = useDeclarationFormErrors();

    const hasMasterDetailsError = useMemo(
        () => !isEmpty(formDeclarationErrors.masterDetails),
        [formDeclarationErrors.masterDetails]
    );
    const hasProductsError = useMemo(() => !isEmpty(formDeclarationErrors.items), [formDeclarationErrors.items]);

    const invalidMasterDetailsIcon = useMemo(() => hasMasterDetailsError && <InvalidIcon />, [hasMasterDetailsError]);
    const invalidProductIcon = useMemo(() => hasProductsError && <InvalidIcon />, [hasProductsError]);

    const goToProducts = useCallback(() => {
        setHasUnsavedChanges(false);
        navigate(`/declarations/${declarationId}${viewOnly ? '/view-only/products' : '/products'}`);
        setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
    }, [declarationId, navigate, setDeclarationFooterType, setHasUnsavedChanges, viewOnly]);

    const goToMasterDetails = useCallback(() => {
        setHasUnsavedChanges(false);
        setNewProduct?.(false);
        navigate(`/declarations/${declarationId}${viewOnly ? '/view-only' : ''}`);
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
    }, [declarationId, navigate, setDeclarationFooterType, setHasUnsavedChanges, setNewProduct, viewOnly]);

    const saveChangesAndMove = useCallback(() => {
        const canSave = hasUnsavedChanges && (!viewOnly || (viewOnly && amendment));
        if (selectedBt === 0) {
            if (canSave) setFormAction(FormAction.DRAFT); // save declaration
            goToProducts();
        } else {
            if (canSave) setFormAction(FormAction.PRODUCT); // save product
            goToMasterDetails();
        }
    }, [amendment, goToMasterDetails, goToProducts, hasUnsavedChanges, selectedBt, setFormAction, viewOnly]);

    return (
        <>
            <Row
                justify="space-between"
                align="middle"
                style={{ gap: '2rem', position: 'relative', marginBottom: '0.8rem' }}
            >
                {selectedBt === 0 ? (
                    <>
                        <div>
                            <Col>
                                <BtMasterGoodsSelected error={hasMasterDetailsError}>
                                    Master Information {invalidMasterDetailsIcon}
                                </BtMasterGoodsSelected>
                                {!(declaration?.gvmsDeclaration || declaration?.preBoardingNotification) && (
                                    <BtMasterGoodsNotSelected
                                        error={hasProductsError}
                                        onClick={() => {
                                            saveChangesAndMove();
                                        }}
                                    >
                                        Item Details {invalidProductIcon}
                                        {!invalidProductIcon && products?.list && (
                                            <NumberItemsRounderInfo title="Number of products">
                                                {products.total}
                                            </NumberItemsRounderInfo>
                                        )}
                                    </BtMasterGoodsNotSelected>
                                )}
                            </Col>
                        </div>

                        <DeclarationInformation
                            options={
                                declaration?.gvmsDeclaration
                                    ? {
                                          visible: {
                                              gmrId: !!declaration?.gvmsDeclaration,
                                              status: !!declaration?.status,
                                              record: !!declaration?.gvmsDeclaration?.messageType,
                                          },
                                      }
                                    : declaration?.preBoardingNotification
                                    ? {
                                          visible: {
                                              status: !!declaration?.preBoardingNotification?.status,
                                              pbnId: !!declaration?.preBoardingNotification?.pbnId,
                                              ref: true,
                                              jobId: true,
                                          },
                                      }
                                    : undefined
                            }
                            viewOnly={viewOnly}
                            hasBox44={
                                !!declaration?.irelandH7ImportDeclaration || !!declaration?.irelandImportDeclaration
                            }
                        />
                    </>
                ) : (
                    <>
                        <Col>
                            <BtMasterGoodsNotSelected
                                error={hasMasterDetailsError}
                                onClick={() => {
                                    saveChangesAndMove();
                                }}
                            >
                                Master Information {invalidMasterDetailsIcon}
                            </BtMasterGoodsNotSelected>
                            <BtMasterGoodsSelected error={hasProductsError}>
                                Item Details {invalidProductIcon}
                                {!invalidProductIcon && products?.list && (
                                    <NumberItemsRounderInfo title="Number of products">
                                        {products.total}
                                    </NumberItemsRounderInfo>
                                )}
                            </BtMasterGoodsSelected>
                        </Col>

                        <DeclarationInformation
                            viewOnly={viewOnly}
                            hasBox44={
                                !!declaration?.irelandH7ImportDeclaration ||
                                !!declaration?.irelandImportDeclaration ||
                                !!declaration?.entrySummaryDeclaration
                            }
                            handleViewAllProductsBtn={() => setShowProducts?.(true)}
                            handleAddNewProductBtn={handleCreateDeclarationProduct}
                        />
                    </>
                )}
            </Row>
            <Divider
                style={{
                    border: `1px solid rgb(196 196 196 / 44%)`,
                    margin: '0 0 1.7rem 0',
                    position: 'absolute',
                    bottom: 0,
                }}
            />
        </>
    );
};
export default MasterProductDeclarationNav;
