import { DatePicker } from 'antd';
import { FC } from 'react';
import { DateFilter as FilterContent } from './Filter.styles';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface Props {
    selectedDate?: (dates: string[]) => void;
}

export const DateFilter: FC<Props> = ({ selectedDate }) => {
    return (
        <FilterContent style={{ display: 'flex', justifyContent: 'center' }}>
            <RangePicker
                getPopupContainer={(trigger) => trigger.parentElement!}
                onChange={(_: any, dateStrings: [string, string]) => selectedDate?.(dateStrings)}
                allowClear
                style={{ width: '-webkit-fill-available' }}
                disabledDate={(currentDate) => moment() < currentDate}
            />
        </FilterContent>
    );
};

export default DateFilter;
