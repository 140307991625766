import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';

import { Input, notification, Table } from 'antd';
import {
    ContainerOutlined,
    DownloadOutlined,
    UploadOutlined,
    SearchOutlined,
    CloseOutlined,
    ToTopOutlined,
} from '@ant-design/icons';

import Container from 'components/ui/base/container';
import { H5 } from 'components/ui/base/typography';

import { CommandButton } from '../../components/ui/composed/dashboard/DashboardTable';
import axiosClient from '../../config/axios';
import { useSearchParams } from 'react-router-dom';
import useDebounce from 'utils/useDebounce';
import { PresignedUrlResponse } from 'store/documents/document';
import { uploadFile } from 'views/declarations/sections/customer-documents/components/UploadUtils';
import { SharedFile } from './file-types';
import { MimeMap } from 'views/declarations/utils/mimeMap';
import { Segment, StyledStatusSegmented } from 'views/declarations/common/dashboard/StatusSegmented';
import Button from 'components/ui/base/button';
import { SpaceBetween } from 'components/styles/layout.styles';
import Divider from 'components/ui/base/divider';

type Params = {
    pagination: { current: number; pageSize: number };
    filters?: Record<string, string[]>;
    sorter?: {
        column: {
            title: string;
            dataIndex: string;
            key: string;
            sorter: boolean;
            filters: { text: string; value: string }[];
        };
        columnKey: string;
        field: string;
        order: 'ascend' | 'descend' | undefined;
    };
    search?: string;
    archived?: boolean;
};

const stringifyValues = (obj: Record<string, any>): Record<string, string> => {
    const newObj = {} as any;
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object') {
            newObj[key] = JSON.stringify(obj[key]);
        } else {
            newObj[key] = obj[key];
        }
    });
    return newObj;
};

const toSearchParams = (obj: Record<string, any>): URLSearchParams => {
    const searchParams = new URLSearchParams();
    Object.entries(obj).forEach(([key, value]) => {
        if (!value) return;
        if (value instanceof Array) {
            value.forEach((v) => searchParams.append(key, v));
        } else if (value instanceof Object) {
            searchParams.append(key, JSON.stringify(value));
        } else {
            searchParams.append(key, value);
        }
    });
    return searchParams;
};

const defaultParams = {
    pagination: { current: 1, pageSize: 10, total: 0 },
    archived: false,
};

const FilesPage: FC = () => {
    const [files, setFiles] = useState<SharedFile[]>([]);
    const [searchParams, setSearchParams] = useSearchParams(stringifyValues(defaultParams));
    const [paginationTotal, setPaginationTotal] = useState<number>(defaultParams.pagination.total);

    const params: Params = useMemo(() => {
        const params = {} as any;
        searchParams.forEach((value, key) => {
            if (value.match(/[{}[\]]/g)) {
                params[key] = JSON.parse(value);
            } else {
                params[key] = value;
            }
        });
        return { ...params, archived: params.archived === 'true' ? true : false };
    }, [searchParams]);

    const updateParams = (newParams: Partial<Params>): Params => {
        const p = { ...params, ...newParams };
        setSearchParams(stringifyValues(p));
        return p;
    };

    const getFiles = async (params: Params) => {
        try {
            const urlParams: Record<string, any> = {
                sortDirection:
                    params.sorter?.order === 'ascend' ? 'ASC' : params.sorter?.order === 'descend' ? 'DESC' : undefined,
                sharedFileSortParameter: params.sorter?.column?.key,
                page: params.pagination.current - 1,
                size: params.pagination.pageSize,
                fileName: params.filters?.['FILE_NAME'] ? params.filters?.['FILE_NAME'] : undefined,
                contentType: params.filters?.['CONTENT_TYPE']?.length ? params.filters?.['CONTENT_TYPE'] : undefined,
                creatorName: params.filters?.['CREATOR_NAME'] ? params.filters?.['CREATOR_NAME'] : undefined,
                archived: params.archived,
            };

            const urlSearchParams = toSearchParams(urlParams);

            const files = await axiosClient
                .get('/cms/shared/files', { params: urlSearchParams })
                .then((res) => res.data.payload);

            setFiles(files.list);
            setPaginationTotal(files.total);
        } catch (error) {}
    };

    const upload: ChangeEventHandler<HTMLInputElement> = async (e) => {
        const files = Array.from(e.target.files as unknown as File[]);
        const file = files[0];

        const preSignUrlPayload = {
            fileSize: file.size,
            fileName: file.name,
        };

        try {
            const { preSignedUrl }: PresignedUrlResponse = await axiosClient
                .get('/cms/shared/files/upload', { params: preSignUrlPayload })
                .then((res) => res.data.payload);

            await uploadFile(file, preSignedUrl);

            const match = preSignedUrl.match(/\/([^/?]+)\?/);
            const uuid = match?.[1];
            await axiosClient.post('/cms/shared/files', {
                s3key: uuid,
                ...preSignUrlPayload,
            });

            updateParams(defaultParams);
            getFiles(defaultParams);

            notification.success({ message: 'Files uploaded successfully' });
        } catch (error) {
            console.error(error);
        } finally {
            e.target.value = '';
        }
    };

    useEffect(() => {
        getFiles(params);

        document.getElementById('upload')?.addEventListener('change', upload as any);
        return () => document.getElementById('upload')?.removeEventListener('change', upload as any);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpload = () => {
        document.getElementById('upload')?.click();
    };

    const handleArchive = async (record: any) => {
        try {
            await axiosClient.post(`/cms/shared/files/archive/${record.id}`);
            getFiles(params);

            notification.success({ message: 'File archived successfully' });
        } catch (error) {}
    };

    const handleUnarchive = async (record: any) => {
        try {
            await axiosClient.post(`/cms/shared/files/unarchive/${record.id}`);
            getFiles(params);

            notification.success({ message: 'File unarchived successfully' });
        } catch (error) {}
    };

    const handleDownload = async (record: any) => {
        try {
            const data = await axiosClient
                .get(`/cms/shared/files/${record.id}/download`)
                .then((res) => res.data.payload);
            window.open(data.preSignedUrl);
        } catch (error) {}
    };

    const handleSearch = useDebounce((confirm: (params?: { closeDropdown: boolean }) => void) => {
        confirm({ closeDropdown: false });
    }, 300);

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const newParams = { pagination, filters, sorter };
        updateParams(newParams);
        getFiles({ ...params, ...newParams });
    };

    return (
        <Container>
            <H5>Shared Files</H5>
            <SpaceBetween style={{ marginTop: '1.6rem' }}>
                <Button size="large" type="primary" icon={<UploadOutlined />} onClick={handleUpload}>
                    Upload
                    <input id="upload" type="file" hidden />
                </Button>
                <StyledStatusSegmented
                    options={[
                        { value: 'active' as any, label: <Segment>active</Segment> },
                        { value: 'archived' as any, label: <Segment>archived</Segment> },
                    ]}
                    onChange={(value: any) => {
                        setSearchParams(
                            stringifyValues({ ...defaultParams, archived: value === 'archived' ? true : false })
                        );
                        getFiles({ ...defaultParams, archived: value === 'archived' ? true : false });
                    }}
                    value={params.archived === true ? 'archived' : 'active'}
                />
            </SpaceBetween>

            <Divider />

            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <Table
                    dataSource={files}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'fileName',
                            key: 'FILE_NAME',
                            sorter: true,
                            sortOrder: params.sorter?.columnKey === 'FILE_NAME' ? params.sorter?.order : null,
                            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                                <div style={{ padding: '1rem' }}>
                                    <Input
                                        prefix={<SearchOutlined />}
                                        suffix={
                                            <Button
                                                type="link"
                                                icon={<CloseOutlined />}
                                                onClick={(e) => {
                                                    setSelectedKeys(null as any);
                                                    confirm();
                                                }}
                                            />
                                        }
                                        onChange={(e) => {
                                            setSelectedKeys(e.target.value as any);
                                            handleSearch?.(confirm);
                                        }}
                                        value={selectedKeys as any}
                                        placeholder="Search"
                                    />
                                </div>
                            ),
                            filteredValue: params.filters?.['FILE_NAME'] || null,
                        },
                        {
                            title: 'Type',
                            dataIndex: 'contentType',
                            key: 'CONTENT_TYPE',
                            sorter: true,
                            render: (text: string) => MimeMap.getExtension(text as any),
                            filters: Object.entries(MimeMap.mimeMap)
                                .filter(([_, value]) => ['pdf', 'docx', 'xlsx', 'csv', 'png', 'jpg'].includes(value))
                                .map(([key, value]) => ({ text: value, value: key })),
                            filteredValue: params.filters?.['CONTENT_TYPE'] || null,
                            sortOrder: params.sorter?.columnKey === 'CONTENT_TYPE' ? params.sorter?.order : null,
                        },
                        {
                            title: 'Creator',
                            dataIndex: ['creator', 'fullName'],
                            key: 'CREATOR_NAME',
                            sorter: true,
                            sortOrder: params.sorter?.columnKey === 'CREATOR_NAME' ? params.sorter?.order : null,
                            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                                <div style={{ padding: '1rem' }}>
                                    <Input
                                        prefix={<SearchOutlined />}
                                        suffix={
                                            <Button
                                                type="link"
                                                icon={<CloseOutlined />}
                                                onClick={(e) => {
                                                    setSelectedKeys(null as any);
                                                    confirm();
                                                }}
                                            />
                                        }
                                        onChange={(e) => {
                                            setSelectedKeys(e.target.value as any);
                                            handleSearch?.(confirm);
                                        }}
                                        value={selectedKeys as any}
                                        placeholder="Search"
                                    />
                                </div>
                            ),
                            filteredValue: params.filters?.['CREATOR_NAME'] || null,
                        },
                        {
                            title: 'Created at',
                            dataIndex: 'createdAt',
                            key: 'CREATED_AT',
                            render: (text: string) => new Date(text).toLocaleString(),
                            sorter: true,
                            sortOrder: params.sorter?.columnKey === 'CREATED_AT' ? params.sorter?.order : null,
                        },
                        {
                            title: 'Actions',
                            key: 'actions',
                            render: (_: any, record: any) => {
                                return (
                                    <div style={{ display: 'flex', gap: '1rem' }}>
                                        {params.archived ? (
                                            <CommandButton
                                                data-testid={`${record.id}-unarchive-button-row`}
                                                tooltip="Unarchive"
                                                icon={<ToTopOutlined />}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleUnarchive(record);
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <CommandButton
                                                    data-testid={`${record.id}-archive-button-row`}
                                                    tooltip="Archive"
                                                    icon={<ContainerOutlined />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleArchive(record);
                                                    }}
                                                />
                                                <CommandButton
                                                    data-testid={`${record.id}-download-button-row`}
                                                    tooltip="Download"
                                                    icon={<DownloadOutlined />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDownload(record);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                );
                            },
                        },
                    ]}
                    pagination={{
                        ...params.pagination,
                        total: paginationTotal,
                    }}
                    onChange={handleTableChange}
                />
            </div>
        </Container>
    );
};

export default FilesPage;
