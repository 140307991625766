import { cloneDeep, merge } from 'lodash';

import DeepPartial from 'types/DeepPartial';
import { ukNctsD1ValidationHeader, ukNctsD1ValidationItem } from './UkNctsD1Validation';

const REMOVE = null as any;

// -- Item
const item: DeepPartial<typeof ukNctsD1ValidationItem> = {
    childValidators: {
        countryOfDispatch: REMOVE,
        commodity: {
            childValidators: {
                goodsMeasure: {
                    childValidators: {
                        netMass: REMOVE,
                    },
                },
            },
        },
        supportingDocument: {
            childValidators: {
                complementOfInformation: REMOVE,
            },
        },
    },
};
export const ukNctsD2ValidationItem = merge(cloneDeep(ukNctsD1ValidationItem), item);

// --- Header
const header: DeepPartial<typeof ukNctsD1ValidationHeader> = {
    childValidators: {
        transitOperation: {
            childValidators: {
                tirCarnetNumber: REMOVE,
            },
        },
        consignment: {
            childValidators: {
                countryOfDispatch: REMOVE,

                inlandModeOfTransport: REMOVE,
                consignor: REMOVE,

                supportingDocument: {
                    childValidators: {
                        complementOfInformation: REMOVE,
                    },
                },

                houseConsignment: {
                    childValidators: {
                        countryOfDispatch: REMOVE,

                        consignor: REMOVE,

                        supportingDocument: {
                            childValidators: {
                                complementOfInformation: REMOVE,
                            },
                        },
                    },
                },
            },
        },
    },
};
export const ukNctsD2ValidationHeader = merge(cloneDeep(ukNctsD1ValidationHeader), header);
