import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Eori } from 'store/eori/eori';
import DeclarationInput, { FormInputProps } from '../declarations/formInput/DeclarationInput';

interface Props extends FormInputProps {
    fieldProps: FieldInputProps<string>;
    fieldMeta?: FieldMetaProps<string>;
    fieldHelper: FieldHelperProps<string>;
    disabled?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    aboveInput?: boolean;
    onApply?: (eori: Eori) => void;
    hasValues?: boolean;
    onError?: () => void;
    onSuccess?: () => void;
    declaration?: boolean;
    refNumber?: string;
    label?: string;
    tooltip?: string;
    viewOnly?: boolean;
    refClicked?: (refNumber: string | number) => void;
    condensed?: boolean;
}

export const FormEori: FC<Props> = (props) => {
    const { aboveInput, onApply, hasValues, onError, onSuccess, declaration, ...remainingProps } = props;

    const [success, setSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (isLoading === undefined) return;
        setSuccess(!props.fieldMeta?.error && !!props.fieldProps.value && !!props.fieldMeta?.touched && !isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fieldMeta?.error, isLoading]);

    useEffect(() => {
        const subscriberId = window.EoriService.eventHandler.subscribe(
            `checking-eori-${props.fieldProps.name}`,
            (data: { loading: boolean; error: boolean }) => {
                if (!data) return;
                setIsLoading(data?.loading);
                data?.error ? onError?.() : onSuccess?.();
            },
            true
        );

        return () => {
            window.EoriService.eventHandler.unsubscribe(subscriberId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DeclarationInput
            eoriSuccess={success}
            maxLength={16}
            label="EORI"
            {...remainingProps}
            isSearching={isLoading}
            condensed={props.condensed}
        />
    );
};

export default FormEori;
