import { FormikProps, FormikProvider } from 'formik';
import { FC, useEffect } from 'react';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { getFormikProps, getFullCodelistValue } from 'views/declarations/utils/form-utils';
import { validators } from 'views/declarations/uk/export/validations/validations';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import addPathPrefix from 'utils/addPathPrefix';
import useHideEntities from 'hooks/useHideEntities';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import useCodelists from '../../../../hooks/useCodelists';
import { ieExportFormTypeMap } from './utils/form-utils';
import useFormUtils from '../../../../hooks/useFormUtils';
import { AESMessageTypes } from '../../../../store/declarations/enums/common/declaration-types';

interface Props {
    formik: FormikProps<any>;
}

const IrelandExportProductsSection: FC<Props> = ({ formik }) => {
    const { formType } = useFormUtils();
    const { isFormType } = useHideEntities();
    const { aesCodelists } = useCodelists();

    useEffect(() => {
        if (!formType && formType !== AESMessageTypes.B4) return;

        const { requestedProcedureValue } = ieExportFormTypeMap[formType as AESMessageTypes] || {};
        const alreadyPopulated = formik?.values?.procedure?.requestedProcedure;

        if (requestedProcedureValue && !alreadyPopulated) {
            formik.setFieldValue('procedure.requestedProcedure', requestedProcedureValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    return (
        <FormikProvider value={formik}>
            <NewFormCard title="Commodity Codes" hidden={isFormType(['C2', 'ARRIVAL'])}>
                <FormCardContainer>
                    <DeclarationInput
                        label="Harmonized System Sub-Heading Code"
                        {...getFormikProps('commodity.commodityCode.harmonizedSystemSubHeadingCode', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        condensed
                    />
                    <DeclarationInput
                        label="Combined Nomenclature Code"
                        {...getFormikProps('commodity.commodityCode.combinedNomenclatureCode', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        condensed
                    />
                    <MultipleDeclarationField
                        parent="commodity.commodityCode.taricAdditionalCode"
                        name="taricAdditionalCode"
                    >
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                label="TARIC Additional Code"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                hidden={isFormType(['B4', 'C2', 'ARRIVAL'])}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>
                    <MultipleDeclarationField
                        parent="commodity.commodityCode.nationalAdditionalCode"
                        name="nationalAdditionalCode"
                    >
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                label="National Additional Code"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                hidden={isFormType(['B4', 'C2', 'ARRIVAL'])}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>

            {/* Group 11 - Message Information (including Procedure Codes) */}
            <NewFormCard title="Goods Information">
                <FormCardContainer>
                    <DeclarationNumberInput
                        label="Statistical Value"
                        {...getFormikProps('statisticalValue', formik)}
                        condensed
                        hidden={isFormType(['ARRIVAL'])}
                    />
                    <DeclarationSelect
                        label="Nature of Transaction"
                        {...getFormikProps('natureOfTransaction', formik)}
                        codeValidation={[validators.number(), validators.maxLength(2)]}
                        hidden={isFormType(['ARRIVAL'])}
                        condensed
                        selectOptions={aesCodelists?.natureOfTransactionCode}
                    />
                    <DeclarationSelect
                        label="Reference Number/UCR"
                        {...getFormikProps('referenceNumberUCR', formik)}
                        hidden={isFormType(['B3', 'C2'])}
                        condensed
                        codeValidation={[validators.maxLength(35)]}
                        selectOptions={aesCodelists?.transportDocumentType}
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Procedure" hidden={isFormType(['C2', 'ARRIVAL'])}>
                <FormCardContainer>
                    <DeclarationSelect
                        label="Requested Procedure"
                        {...getFormikProps('procedure.requestedProcedure', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        disabled={isFormType(['B4'])}
                        codeValidation={[validators.number(), validators.exact(2)]}
                        condensed
                        selectOptions={
                            ieExportFormTypeMap?.[formType as AESMessageTypes]?.filterRequestedProcedureCodelist?.(
                                aesCodelists?.requestedProcedure
                            ) || aesCodelists?.requestedProcedure
                        }
                    />
                    <DeclarationSelect
                        label="Previous Procedure"
                        {...getFormikProps('procedure.previousProcedure', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        codeValidation={[validators.number(), validators.exact(2)]}
                        condensed
                        selectOptions={aesCodelists?.previousProcedure}
                    />
                    <MultipleDeclarationField parent="procedure.additionalProcedure" name="additionalProcedure">
                        {(fieldProps, controls) => (
                            <DeclarationSelect
                                label="Additional Procedure"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                hidden={isFormType(['C2', 'ARRIVAL'])}
                                codeValidation={[validators.exact(3)]}
                                multipleF={controls}
                                condensed
                                selectOptions={aesCodelists?.additionalProcedure}
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>

            {/* Group 12 - References of Messages, Documents, Certificates, Authorisations */}
            <MultipleItemsCard
                title="Previous Document"
                path="previousDocument"
                initialValues={{
                    referenceNumber: '',
                    type: '',
                    typeOfPackages: '',
                    numberOfPackages: '',
                    measurementUnitAndQualifier: '',
                    quantity: '',
                    goodsItemNumber: '',
                }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.previousDocumentType),
                    },
                    {
                        field: 'Type of Packages',
                        value: getFullCodelistValue(list.typeOfPackages, aesCodelists?.typeOfPackages),
                    },
                    { field: 'Number of Packages', value: list.numberOfPackages },
                    {
                        field: 'Measurement Unit and Qualifier',
                        value: getFullCodelistValue(
                            list.measurementUnitAndQualifier,
                            aesCodelists?.measurementUnitAndQualifier
                        ),
                    },
                    { field: 'Quantity', value: list.quantity },
                    { field: 'Goods Item Number', value: list.goodsItemNumber },
                ]}
                condensed
                hidden={isFormType(['ARRIVAL'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.previousDocumentType}
                        />
                        <DeclarationSelect
                            label="Type of Packages"
                            {...getFormikProps(addPathPrefix(path, 'typeOfPackages'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.typeOfPackages}
                        />
                        <DeclarationNumberInput
                            label="Number of Packages"
                            {...getFormikProps(addPathPrefix(path, 'numberOfPackages'), formik)}
                            condensed
                        />
                        <DeclarationSelect
                            label="Measurement Unit and Qualifier"
                            {...getFormikProps(addPathPrefix(path, 'measurementUnitAndQualifier'), formik)}
                            codeValidation={[validators.maxLength(4)]}
                            condensed
                            selectOptions={aesCodelists?.measurementUnitAndQualifier}
                        />
                        <DeclarationNumberInput
                            label="Quantity"
                            {...getFormikProps(addPathPrefix(path, 'quantity'), formik)}
                            condensed
                        />
                        <DeclarationNumberInput
                            label="Goods Item Number"
                            {...getFormikProps(addPathPrefix(path, 'goodsItemNumber'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Information"
                path="additionalInformation"
                hidden={isFormType(['C2', 'ARRIVAL'])}
                initialValues={{ code: '', text: '' }}
                list={(list) => [
                    {
                        field: 'Code',
                        value: getFullCodelistValue(list.code, aesCodelists?.additionalInformationCode),
                    },
                    { field: 'Text', value: list.text },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Code"
                            {...getFormikProps(addPathPrefix(path, 'code'), formik)}
                            required
                            codeValidation={[validators.exact(5)]}
                            condensed
                            selectOptions={aesCodelists?.additionalInformationCode}
                        />
                        <DeclarationInput
                            label="Text"
                            {...getFormikProps(addPathPrefix(path, 'text'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Supporting Documents"
                path="supportingDocument"
                hidden={isFormType(['C2', 'ARRIVAL'])}
                initialValues={{
                    referenceNumber: '',
                    type: '',
                    issuingAuthorityName: '',
                    measurementUnitAndQualifier: '',
                    quantity: '',
                    validityDate: '',
                    currency: '',
                    documentLineItemNumber: '',
                    amount: '',
                }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.supportingDocumentType),
                    },
                    { field: 'Issuing Authority Name', value: list.issuingAuthorityName },
                    {
                        field: 'Measurement Unit & Qualifier',
                        value: getFullCodelistValue(
                            list.measurementUnitAndQualifier,
                            aesCodelists?.measurementUnitAndQualifier
                        ),
                    },
                    { field: 'Quantity', value: list.quantity },
                    { field: 'Date of Validity', value: list.validityDate },
                    { field: 'Currency', value: list.currency },
                    { field: 'Document Line Item Number', value: list.documentLineItemNumber },
                    { field: 'Amount', value: list.amount },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.supportingDocumentType}
                        />
                        <DeclarationInput
                            label="Issuing Authority Name"
                            {...getFormikProps(addPathPrefix(path, 'issuingAuthorityName'), formik)}
                            condensed
                        />
                        <DeclarationSelect
                            label="Measurement Unit & Qualifier"
                            {...getFormikProps(addPathPrefix(path, 'measurementUnitAndQualifier'), formik)}
                            codeValidation={[validators.maxLength(4)]}
                            condensed
                            selectOptions={aesCodelists?.measurementUnitAndQualifier}
                        />
                        <DeclarationNumberInput
                            label="Quantity"
                            {...getFormikProps(addPathPrefix(path, 'quantity'), formik)}
                            condensed
                        />
                        <DeclarationDatepicker
                            label="Date of Validity"
                            {...getFormikProps(addPathPrefix(path, 'validityDate'), formik)}
                            condensed
                        />
                        <CurrencySelect
                            label="Currency"
                            {...getFormikProps(addPathPrefix(path, 'currency'), formik)}
                            condensed
                        />
                        <DeclarationNumberInput
                            label="Document Line Item Number"
                            {...getFormikProps(addPathPrefix(path, 'documentLineItemNumber'), formik)}
                            condensed
                        />
                        <DeclarationNumberInput
                            label="Amount"
                            {...getFormikProps(addPathPrefix(path, 'amount'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Reference"
                path="additionalReference"
                hidden={isFormType(['B3', 'B4', 'C2', 'ARRIVAL'])}
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.additionalReferenceType),
                    },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.additionalReferenceType}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Transport Document"
                path="transportDocument"
                hidden={isFormType(['B3', 'C2'])}
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.transportDocumentType),
                    },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required={formType !== 'ARRIVAL'}
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required={formType !== 'ARRIVAL'}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.transportDocumentType}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Authorisation"
                path="authorisation"
                initialValues={{ type: '', referenceNumber: '', holderOfAuthorisation: '' }}
                list={(list) => [
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.authorisationType),
                    },
                    { field: 'Reference Number', value: list.referenceNumber },
                    { field: 'Holder of Authorisation', value: list.holderOfAuthorisation },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.maxLength(4)]}
                            condensed
                            selectOptions={aesCodelists?.authorisationType}
                        />
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationInput
                            label="Holder of Authorisation"
                            {...getFormikProps(addPathPrefix(path, 'holderOfAuthorisation'), formik)}
                            required={formType !== 'ARRIVAL'}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            {/* Group 13 - Parties */}
            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'consignor',
                        header: 'Consignor',
                        refNumber: undefined,
                        hidden: isFormType(['B2', 'B3', 'B4', 'C2', 'ARRIVAL']),
                    },
                    {
                        path: 'consignee',
                        header: 'Consignee',
                        refNumber: undefined,
                        hidden: isFormType(['C2', 'ARRIVAL']),
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.city',
                        country: 'address.country',
                        name: 'name',
                        postCode: 'address.postcode',
                        streetAndNumber: 'address.streetAndNumber',
                    },
                    eori: 'identificationNumber',
                }}
                condensed
            />

            <MultipleItemsCard
                title="Additional Supply Chain Actor"
                path="additionalSupplyChainActor"
                hidden={isFormType(['C2', 'ARRIVAL'])}
                initialValues={{ role: '', identificationNumber: '' }}
                list={(list) => [
                    {
                        field: 'Role',
                        value: getFullCodelistValue(list.role, aesCodelists?.additionalSupplyChainActorRoleCode),
                    },
                    { field: 'Identification Number', value: list.identificationNumber },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Role"
                            {...getFormikProps(addPathPrefix(path, 'role'), formik)}
                            required
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aesCodelists?.additionalSupplyChainActorRoleCode}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            required
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            {/* Group 14 - Valuation Information / Taxes */}
            <NewFormCard title="Valuation Information / Taxes" hidden={isFormType(['ARRIVAL'])}>
                <FormCardContainer>
                    <DeclarationSelect
                        label="Transport Charges - Method of Payment"
                        {...getFormikProps('transportCharges.methodOfPayment', formik)}
                        hidden={isFormType(['B3', 'B4', 'C2', 'ARRIVAL'])}
                        codeValidation={[validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.transportChargesMethodOfPayment}
                    />
                    <DeclarationNumberInput
                        label="Total Duties and Taxes Amount"
                        {...getFormikProps('commodity.calculationOfTaxes.totalDutiesAndTaxesAmount', formik)}
                        condensed
                        hidden={isFormType(['ARRIVAL'])}
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Duties and Taxes"
                path="commodity.calculationOfTaxes.dutiesAndTaxes"
                required
                initialValues={{
                    taxType: '',
                    payableTaxAmount: '',
                    methodOfPayment: '',
                    taxBase: [
                        { taxRate: '', measurementUnitAndQualifier: '', quantity: '', amount: '', taxAmount: '' },
                    ],
                }}
                list={(list) => [
                    { field: 'Tax Type', value: getFullCodelistValue(list.taxType, aesCodelists?.taxType) },
                    { field: 'Payable Tax', value: list.payableTaxAmount },
                    {
                        field: 'Method of Payment',
                        value: getFullCodelistValue(
                            list.methodOfPayment,
                            aesCodelists?.calculationOfTaxesMethodOfPayment
                        ),
                    },
                    {
                        field: 'Tax Bases',
                        value: list.taxBase?.map((taxBase, i) => (
                            <div key={`tax-base-${i}`} style={{ display: 'flex', gap: 10 }}>
                                <div>Tax Rate: {taxBase.taxRate || '-'}</div>
                                <div>
                                    {getFullCodelistValue(
                                        taxBase.measurementUnitAndQualifier,
                                        aesCodelists?.measurementUnitAndQualifier
                                    )}
                                </div>
                                <div>Quantity: {taxBase.quantity || '-'}</div>
                                <div>Amount: {taxBase.amount || '-'}</div>
                                <div>Tax Amount: {taxBase.taxAmount || '-'}</div>
                            </div>
                        )),
                    },
                ]}
                condensed
                hidden={isFormType(['ARRIVAL'])}
            >
                {(path) => (
                    <>
                        <FormCardContainer>
                            <DeclarationSelect
                                label="Tax Type"
                                {...getFormikProps(addPathPrefix(path, 'taxType'), formik)}
                                required
                                codeValidation={[validators.exact(3)]}
                                condensed
                                selectOptions={aesCodelists?.taxType}
                            />
                            <DeclarationNumberInput
                                label="Payable Tax"
                                {...getFormikProps(addPathPrefix(path, 'payableTaxAmount'), formik)}
                                required
                                condensed
                            />
                            <DeclarationSelect
                                label="Method of Payment"
                                {...getFormikProps(addPathPrefix(path, 'methodOfPayment'), formik)}
                                required
                                codeValidation={[validators.exact(1)]}
                                condensed
                                selectOptions={aesCodelists?.calculationOfTaxesMethodOfPayment}
                            />
                        </FormCardContainer>
                        <MultipleItemsCard
                            title="Tax Base"
                            path={addPathPrefix(path, 'taxBase')}
                            required
                            initialValues={{
                                taxRate: '',
                                measurementUnitAndQualifier: '',
                                quantity: '',
                                amount: '',
                                taxAmount: '',
                            }}
                            list={(list) => [
                                { field: 'Tax Rate', value: list.taxRate },
                                { field: 'Measurement Unit and Qualifier', value: list.measurementUnitAndQualifier },
                                { field: 'Quantity', value: list.quantity },
                                { field: 'Amount', value: list.amount },
                                { field: 'Tax Amount', value: list.taxAmount },
                            ]}
                            condensed
                            style={{ marginTop: 10 }}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationNumberInput
                                        label="Tax Rate"
                                        {...getFormikProps(addPathPrefix(path, 'taxRate'), formik)}
                                        required
                                        condensed
                                    />
                                    <DeclarationSelect
                                        label="Measurement Unit and Qualifier"
                                        {...getFormikProps(addPathPrefix(path, 'measurementUnitAndQualifier'), formik)}
                                        codeValidation={[validators.maxLength(4)]}
                                        condensed
                                        selectOptions={aesCodelists?.measurementUnitAndQualifier}
                                    />
                                    <DeclarationNumberInput
                                        label="Quantity"
                                        {...getFormikProps(addPathPrefix(path, 'quantity'), formik)}
                                        condensed
                                    />
                                    <DeclarationNumberInput
                                        label="Amount"
                                        {...getFormikProps(addPathPrefix(path, 'amount'), formik)}
                                        condensed
                                    />
                                    <DeclarationNumberInput
                                        label="Tax Amount"
                                        {...getFormikProps(addPathPrefix(path, 'taxAmount'), formik)}
                                        required
                                        condensed
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>
                    </>
                )}
            </MultipleItemsCard>

            {/* Group 16 - Places / Countries / Regions */}
            <NewFormCard title="Places / Countries / Regions" hidden={isFormType(['ARRIVAL'])}>
                <FormCardContainer>
                    <CountrySelect
                        label="Country of Destination"
                        {...getFormikProps('countryOfDestination', formik)}
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        condensed
                        selectOptions={aesCodelists?.country}
                    />
                    <CountrySelect
                        label="Country of Export"
                        {...getFormikProps('countryOfExport', formik)}
                        hidden={isFormType(['B4', 'C2', 'ARRIVAL'])}
                        condensed
                        selectOptions={aesCodelists?.countryCodesCommunity}
                    />
                    <CountrySelect
                        label="Country of Origin"
                        {...getFormikProps('origin.countryOfOrigin', formik)}
                        hidden={isFormType(['C2'])}
                        condensed
                        selectOptions={aesCodelists?.country}
                    />
                    <DeclarationInput
                        label="Region of Dispatch"
                        {...getFormikProps('origin.regionOfDispatch', formik)}
                        condensed
                        hidden={isFormType(['ARRIVAL'])}
                    />
                </FormCardContainer>
            </NewFormCard>

            {/* Group 18 - Goods Identification */}
            <NewFormCard title="Goods Identification" hidden={isFormType(['C2'])}>
                <FormCardContainer>
                    <DeclarationNumberInput
                        label="Net Mass"
                        {...getFormikProps('commodity.goodsMeasure.netMass', formik)}
                        required={isFormType(['B1', 'B2', 'B3', 'C1'])} // TODO check required always?
                        hidden={isFormType(['C2'])}
                        condensed
                    />
                    <DeclarationNumberInput
                        label="Supplementary Units"
                        {...getFormikProps('commodity.goodsMeasure.supplementaryUnits', formik)}
                        hidden={isFormType(['C1', 'C2', 'ARRIVAL'])}
                        condensed
                    />
                    <DeclarationNumberInput
                        label="Gross Mass"
                        {...getFormikProps('commodity.goodsMeasure.grossMass', formik)}
                        hidden={isFormType(['C2'])}
                        condensed
                    />
                    <DeclarationInput
                        label="Description of Goods"
                        {...getFormikProps('commodity.descriptionOfGoods', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        condensed
                    />
                    <MultipleDeclarationField parent="commodity.dangerousGoods" name="unNumber">
                        {(fieldProps, controls) => (
                            <DeclarationSelect
                                label="Dangerous Goods - UN Number"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                hidden={isFormType(['B3', 'B4', 'C2', 'ARRIVAL'])}
                                multipleF={controls}
                                condensed
                                selectOptions={aesCodelists?.unDangerousGoodsCode}
                                codeValidation={[validators.exact(4)]}
                            />
                        )}
                    </MultipleDeclarationField>
                    <DeclarationSelect
                        label="CUS Code"
                        {...getFormikProps('commodity.cusCode', formik)}
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        codeValidation={[validators.exact(9)]}
                        condensed
                        selectOptions={aesCodelists?.commodityCusCode}
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Packaging"
                path="packaging"
                required={formType !== 'ARRIVAL'}
                hidden={isFormType(['C2'])}
                initialValues={{ typeOfPackages: '', numberOfPackages: '', shippingMarks: '' }}
                list={(list) => [
                    {
                        field: 'Type of Packages',
                        value: getFullCodelistValue(list.typeOfPackages, aesCodelists?.typeOfPackages),
                    },
                    { field: 'Number of Packages', value: list.numberOfPackages },
                    { field: 'Shipping Marks', value: list.shippingMarks },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type of Packages"
                            {...getFormikProps(addPathPrefix(path, 'typeOfPackages'), formik)}
                            required={formType !== 'ARRIVAL'}
                            hidden={isFormType(['C2'])}
                            codeValidation={[validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.typeOfPackages}
                        />
                        <DeclarationNumberInput
                            label="Number of Packages"
                            {...getFormikProps(addPathPrefix(path, 'numberOfPackages'), formik)}
                            hidden={isFormType(['C2'])}
                            condensed
                        />
                        <DeclarationNumberInput
                            label="Shipping Marks"
                            {...getFormikProps(addPathPrefix(path, 'shippingMarks'), formik)}
                            hidden={isFormType(['C2'])}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
        </FormikProvider>
    );
};
export default IrelandExportProductsSection;
