import { LinkButton } from 'components/ui/base/button/Button.styles';
import Drawer from 'components/ui/base/drawer/Drawer';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorResponse } from '../../../../core/http/response';
import useFormUtils from '../../../../hooks/useFormUtils';
import { ENSMessageTypes } from '../../../../store/declarations/enums/common/declaration-types';
import SubmissionErrorsList from './SubmissionErrorsList';
import { WarningOutlined } from '@ant-design/icons';
import { ErrorButton } from '../declaration-errors-drawer/DeclarationNotificationsDrawerButton';

export interface SubmissionError {
    code?: string;
    message: string;
    field: string;
}

const SubmissionErrorsDrawerButton = ({ declarationError }: { declarationError: ErrorResponse | undefined }) => {
    const [showSubmissionErrorsDrawer, setShowSubmissionErrorsDrawer] = useState<boolean>(false);

    useEffect(() => {
        setShowSubmissionErrorsDrawer(true);
    }, []);

    return (
        <>
            <ErrorButton onClick={() => setShowSubmissionErrorsDrawer(true)}>
                View submission errors <WarningOutlined />
            </ErrorButton>
            <SubmissionErrorsDrawer
                declarationError={declarationError}
                open={showSubmissionErrorsDrawer}
                onClose={() => setShowSubmissionErrorsDrawer(false)}
            />
        </>
    );
};

interface Props {
    declarationError: ErrorResponse | undefined;
    open?: boolean;
    onClose?: () => void;
}

const SubmissionErrorsDrawer: FC<Props> = ({ declarationError, open = false, onClose }) => {
    const { t } = useTranslation('common');
    const { isAis, declarationFormType } = useFormUtils();

    const handleClose = (): void => {
        onClose?.();
    };

    let link = '',
        text = '';
    if (isAis) {
        link =
            'https://www.revenue.ie/en/online-services/support/software-developers/documents/ais/ais-business-rules-and-conditions.pdf';
        text = 'Business Rules and Conditions';
    } else if (declarationFormType === ENSMessageTypes.ENS) {
        link =
            'https://www.revenue.ie/en/online-services/support/software-developers/documents/import-control-system/import-control-system.pdf';
        text = t('conditionsDocument');
    }

    return (
        <Drawer title="Declaration errors" visible={open} onClose={handleClose} width={820} warning={true}>
            <LinkButton href={link} target="_blank">
                {text}
            </LinkButton>
            <SubmissionErrorsList
                errors={declarationError?.subErrors
                    ?.filter((error) => error.category === 'VALIDATION')
                    .map((error) => ({ field: error.field, message: error.message }))}
            />
        </Drawer>
    );
};

export default SubmissionErrorsDrawerButton;
