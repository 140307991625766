import { useReducer } from 'react';
import { Customer } from 'store/customers/customer';

type State = { open: boolean; customer: Customer | null };
type Action = { type: 'open'; customer: Customer } | { type: 'close' };

export const useCustomerDetailsReducer = () =>
    useReducer(
        (state: State, action: Action): State => {
            switch (action.type) {
                case 'open':
                    return { open: true, customer: action.customer };
                case 'close':
                    return { open: false, customer: null };
                default:
                    return state;
            }
        },
        { open: false, customer: null }
    );
