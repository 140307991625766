import { DrawerProps as ADrawerProps } from 'antd';
import { FC, ReactNode } from 'react';
import { H5 } from '../typography';
import { ADrawer, CautionTitle, CloseIcon, WarningIcon, WarningTitle } from './Drawer.style';

export interface DrawerProps extends ADrawerProps {
    title: string | ReactNode;
    warning?: boolean;
    caution?: boolean;
    bodyPaddingRight?: number;
}

const Drawer: FC<DrawerProps> = (props) => {
    const { warning, caution, title, placement, bodyPaddingRight = 40, ...drawerProps } = { ...props };
    return (
        <ADrawer
            placement={placement || 'right'}
            drawerStyle={{ paddingLeft: 40, paddingRight: 0, paddingTop: 40, paddingBottom: 40 }}
            headerStyle={{
                borderBottom: 0,
                paddingTop: 0,
                paddingBottom: 24,
                paddingLeft: 0,
                paddingRight: 0,
                marginRight: 40,
            }}
            bodyStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: bodyPaddingRight, paddingBottom: 0 }}
            {...drawerProps}
            title={
                warning ? (
                    <WarningTitle>
                        {title} <WarningIcon />
                    </WarningTitle>
                ) : caution ? (
                    <CautionTitle>
                        {title}
                        <WarningIcon />
                    </CautionTitle>
                ) : (
                    <H5>{title}</H5>
                )
            }
            closeIcon={<CloseIcon />}
        >
            {props.children}
        </ADrawer>
    );
};

export default Drawer;
