import { Col, Dropdown, Menu, Row } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from '../../../store/declarations/enums/common/declaration-internal-type';
import { getDeclarationNameLabels } from '../utils';
import {
    CloseIcon,
    CustomRow,
    DownIcon,
    FileIcon,
    InvoiceButton,
    ReverseCol,
    SectionTitle,
} from './InvoiceUpload.styles';

interface Props {
    setFormType: Function;
    disableFormType: boolean;
    selectedFormTypeLabel?: string;
}
const SelectFormType: FC<Props> = ({ setFormType, disableFormType, selectedFormTypeLabel }) => {
    const { t } = useTranslation('customs_declarations');
    const { country, type } = useParams<{ country: DeclarationCountry; type: DeclarationInternalType }>();

    const disable = useMemo(() => disableFormType, [disableFormType]);

    const selectedFormOption = useMemo(() => {
        return selectedFormTypeLabel ? (
            <Row align="middle">
                <CloseIcon
                    className={`${disable && 'disabled'}`}
                    onClick={() => {
                        if (!disable) {
                            setFormType(undefined);
                        }
                    }}
                    disabled={disable}
                />
                <FileIcon /> {selectedFormTypeLabel}
            </Row>
        ) : (
            <></>
        );
    }, [selectedFormTypeLabel, setFormType, disable]);

    return (
        <>
            <CustomRow>
                <Col span={16}>
                    <SectionTitle className={`${disable && 'disabled'}`}>{t('selectForm')}</SectionTitle>
                </Col>
                <ReverseCol span={8}>
                    <Dropdown
                        disabled={disable}
                        overlay={
                            <Menu onClick={(a) => setFormType(a.key)}>
                                {getDeclarationNameLabels(country, type).map((name) => (
                                    <Menu.Item style={{ maxWidth: '30rem' }} key={name.key}>
                                        {name.value}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }
                    >
                        <InvoiceButton size="large">
                            <DownIcon /> {t('selectForm')}
                        </InvoiceButton>
                    </Dropdown>
                </ReverseCol>
                {selectedFormOption}
            </CustomRow>
        </>
    );
};

export default SelectFormType;
