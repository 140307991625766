import { Col } from 'antd';
import { TextBold, TextSmallBold } from 'components/ui/base/typography';
import { FC, useCallback } from 'react';
import { TaricCode } from 'store/taric-codes/taric-code';
import { CodeHeading } from '../TaricCode.styles';
import BackButton from './BackButton';
import ListRow from './ListRow';
interface Props {
    back: () => void;
    taricCodesDetails: TaricCode[];
    searchQuery: string;
    setSelectedCode: Function;
}
export const ListTaricCodes: FC<Props> = ({ back, taricCodesDetails, searchQuery, setSelectedCode }) => {
    const getSection = useCallback((code: TaricCode) => {
        return <TextSmallBold>{`Section ${code?.section}`}</TextSmallBold>;
    }, []);
    return (
        <>
            <BackButton
                onClick={() => {
                    back();
                }}
            />
            <CodeHeading>
                <Col span={20}>
                    <TextBold>Choose the heading that best matches your goods</TextBold>
                </Col>
                <Col span={4}>
                    <TextBold>Commodity code</TextBold>
                </Col>
            </CodeHeading>
            {taricCodesDetails?.map((code, index) => (
                <>
                    {getSection(code)}
                    {code.taricCodeResponses.map((res, i) => (
                        <ListRow
                            searchQuery={searchQuery}
                            key={`taric-section-expanded-row-${index}-${i}-${res.description}`}
                            margin="0"
                            code={res}
                            selectedCode={(c) => setSelectedCode(c)}
                        />
                    ))}
                </>
            ))}
        </>
    );
};
export default ListTaricCodes;
