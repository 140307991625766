import { cloneDeep, get, set } from 'lodash';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';

type Box44 = {
    documentType: string;
    documentIdentifier: string;
};

export const makeTransformation = (declaration: any, ...transformationFunctions: ((declaration: any) => any)[]) => {
    let _declaration = cloneDeep(declaration);
    transformationFunctions.forEach((func) => {
        _declaration = func(_declaration);
    });
    return _declaration;
};

const IrelandImportDeclarationUtils = {
    autofillImporterDeclarant: (declaration: any | undefined) => {
        if (declaration?.declarant) {
            const { id, eori, statusCode, ...address } = declaration.declarant;
            if (eori) declaration.declarant = { id, statusCode, eori };
            else declaration.declarant = { id, statusCode, ...address };
        }
        if (declaration?.goodsShipment?.importer) {
            const { id, eori, statusCode, ...address } = declaration.goodsShipment.importer;
            if (eori) declaration.goodsShipment.importer = { id, statusCode, eori };
            else declaration.goodsShipment.importer = { id, statusCode, ...address };
        }
        return declaration;
    },
    box44ToObject: (box44: any[] | undefined, structure: Box44) => {
        if (!Array.isArray(box44)) return box44;
        return box44?.reduce((acc, entry) => {
            if (!acc[entry[structure.documentType]]) acc[entry[structure.documentType]] = [];
            acc[entry[structure.documentType]].push(entry[structure.documentIdentifier]);
            return acc;
        }, {} as Record<string, string[]>);
    },
    box44ToArray: (box44: Record<string, string[]> | undefined, structure: Box44) => {
        if (!box44 || !Object.keys(box44).length) return [];
        if (Array.isArray(box44)) return box44;
        return Object.entries(box44).reduce((acc, [key, value]) => {
            value.forEach((v) => {
                if (v != null) {
                    acc.push({ [structure.documentType]: key, [structure.documentIdentifier]: v });
                }
            });
            return acc;
        }, [] as any[]);
    },
    box44Transform: (func: Function, box44Path: string, box44KeyNames: any, data: any) => {
        const box44 = get(data, box44Path);
        const newBox44 = func(box44, box44KeyNames);
        return set(data, box44Path, newBox44);
    },
    additionsAndDeductions: (declaration: IrelandImportDeclaration) => {
        const additionsDeductions = declaration.goodsShipment?.additionsDeductions;

        additionsDeductions?.forEach((additionDeduction: any) => {
            const float = parseFloat(additionDeduction.amount);

            if (isNaN(float)) additionDeduction.amount = null;
            else additionDeduction.amount = float.toFixed(2);
        });

        return declaration;
    },
};

export default IrelandImportDeclarationUtils;
