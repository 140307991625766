import { CC055CNotification } from 'store/declarations/ireland/ncts-declaration';
import { ErrorRow } from '../../declaration-errors/ais/RevenueImportErrorDetails';
import moment from 'moment';

const CC055C = (props: { notification: CC055CNotification['notification'] }) => {
    return (
        <div>
            <h2>Transit Operation</h2>
            <div>
                <ErrorRow label="MRN" value={props.notification.transitOperation.mrn ?? '-'} />
                <ErrorRow
                    label="Declaration Acceptance Date"
                    value={
                        props.notification.transitOperation.declarationAcceptanceDate
                            ? moment(props.notification.transitOperation.declarationAcceptanceDate).format()
                            : '-'
                    }
                />
            </div>

            <h2>Customs Office Of Departure</h2>
            <div>
                <ErrorRow
                    label="Reference Number"
                    value={props.notification.customsOfficeOfDeparture.referenceNumber ?? '-'}
                />
            </div>

            <h2>Holder of the Transit Procedure</h2>
            <div>
                <ErrorRow
                    label="Identification Number"
                    value={props.notification.holderOfTheTransitProcedure.identificationNumber ?? '-'}
                />
                <ErrorRow label="Name" value={props.notification.holderOfTheTransitProcedure.name ?? '-'} />
                <ErrorRow
                    label="Country"
                    value={props.notification.holderOfTheTransitProcedure.address.country ?? '-'}
                />
                <ErrorRow label="City" value={props.notification.holderOfTheTransitProcedure.address.city ?? '-'} />
                <ErrorRow
                    label="Postcode"
                    value={props.notification.holderOfTheTransitProcedure.address.postcode ?? '-'}
                />
                <ErrorRow
                    label="Street and Number"
                    value={props.notification.holderOfTheTransitProcedure.address.streetAndNumber ?? '-'}
                />
            </div>

            <h2>Guarantee References</h2>
            <div>
                {props.notification.guaranteeReference.map((item, index) => (
                    <div key={index}>
                        <ErrorRow label="GRN" value={item.grn ?? '-'} />

                        <h3>Invalid Guarantee Reasons</h3>
                        {item.invalidGuaranteeReason.map((reason, index) => (
                            <div key={index}>
                                <ErrorRow label="Code" value={reason.code ?? '-'} />
                                <ErrorRow label="Text" value={reason.text ?? '-'} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CC055C;
