import { Segmented, SegmentedProps } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/lib/segmented';
import { ReactElement, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

export const Segment = (props: { children: ReactNode }): ReactElement => {
    return (
        <div style={{ padding: '0.15em 1.5em' }}>
            <b>{props.children}</b>
        </div>
    );
};

type Props = Omit<SegmentedProps, 'options'> & {
    additionalOptions?: (SegmentedValue | SegmentedLabeledOption)[];
    absoluteNavPath?: string;
    onChangeExtension?: (status: SegmentedValue) => void;
};

export const StyledStatusSegmented = styled(Segmented)`
    border-radius: 8px;
    padding: 0.2em;
    .ant-segmented-item,
    .ant-segmented-item-selected,
    .ant-segmented-thumb {
        border-radius: 8px;
    }
`;

const StatusSegmented = ({
    additionalOptions,
    absoluteNavPath,
    onChangeExtension,
    ...segmentedProps
}: Props): ReactElement => {
    const navigate = useNavigate();

    const handleStatusChange = useCallback(
        (status: SegmentedValue) => {
            const absoluteOrRelativePath = absoluteNavPath || './';

            if (status === 'archived') {
                navigate(absoluteOrRelativePath + 'archived');
            } else if (status === 'core-declarations') {
                navigate(absoluteOrRelativePath + 'core-declarations');
            } else {
                navigate('./..');
            }

            onChangeExtension?.(status);
        },
        [navigate, onChangeExtension, absoluteNavPath]
    );

    return (
        <StyledStatusSegmented
            options={[
                { label: <Segment>active</Segment>, value: 'active' },
                ...(additionalOptions || []),
                { label: <Segment>archived</Segment>, value: 'archived' },
            ]}
            onChange={handleStatusChange}
            {...(segmentedProps as any)}
        />
    );
};

export default StatusSegmented;
