import { Tooltip } from 'antd';
import { FC, ReactElement, useEffect, useRef, useState, cloneElement } from 'react';
import ConditionalWrapper from './ConditionalWrapper';

interface Props {
    children: NonNullable<ReactElement>;
    tooltipTitle: string | undefined;
}

const EllipsisTextTooltip: FC<Props> = ({ children, tooltipTitle }) => {
    const valueRef = useRef<HTMLSpanElement | null>(null);
    const [valueHasEllipsis, setValueHasEllipsis] = useState<boolean>(false);

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (valueRef.current) {
                setValueHasEllipsis(valueRef.current.scrollWidth > valueRef.current.clientWidth);
            }
        });

        if (valueRef.current && tooltipTitle) {
            observer.observe(valueRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [tooltipTitle]);

    return (
        <ConditionalWrapper
            condition={valueHasEllipsis}
            wrapper={(children) => <Tooltip title={tooltipTitle}>{children}</Tooltip>}
        >
            {cloneElement(children, { ref: valueRef })}
        </ConditionalWrapper>
    );
};

export default EllipsisTextTooltip;
