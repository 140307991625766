import Button from 'components/ui/base/button';
import CustomModal from 'components/ui/base/modal/Modal';
import { H5, Text } from 'components/ui/base/typography';
import { FC, useState } from 'react';
import { Declaration } from '../../../../store/declarations/declaration';
import { IrelandH7ImportDeclaration } from '../../../../store/declarations/ireland/h7-import-declaration';
import { IrelandImportDeclaration } from '../../../../store/declarations/ireland/import-declaration';
import { FooterButton } from '../../sections/view-only/ViewOnlyTab';
import { canDeclarationBeInvalidated, getDeclarationPayload } from '../../utils/declaration-utils';
import useFormUtils from '../../../../hooks/useFormUtils';
import InvalidateForm from './InvalidateForm';
import InvalidateFormNcts from './InvalidateFormNcts';

interface Props {
    visible: boolean;
    onCancel: () => void;
}

const InvalidateModalButton = ({ declaration }: { declaration: Declaration | undefined }) => {
    const [invalidateModalOpen, setInvalidateModalOpen] = useState<boolean>(false);

    const showInvalidateButton =
        declaration &&
        ((getDeclarationPayload(declaration) as IrelandImportDeclaration | IrelandH7ImportDeclaration)
            ?.revenueInvalidationRequested ||
            canDeclarationBeInvalidated(declaration?.status)) &&
        declaration?.declarationExternalEntity !== 'CDS';

    return (
        <>
            {showInvalidateButton && (
                <FooterButton onClick={() => setInvalidateModalOpen(true)}>Invalidate</FooterButton>
            )}
            <InvalidateModal visible={invalidateModalOpen} onCancel={() => setInvalidateModalOpen(false)} />
        </>
    );
};

export default InvalidateModalButton;

const InvalidateModal: FC<Props> = ({ visible, onCancel }) => {
    const { isNcts } = useFormUtils();

    const handleCancel = () => {
        onCancel();
    };

    const formComponent = isNcts ? (
        <InvalidateFormNcts onCancel={handleCancel} />
    ) : (
        <InvalidateForm onCancel={handleCancel} />
    );

    return (
        <CustomModal
            title={<H5>Invalidate declaration</H5>}
            centered
            visible={visible}
            onCancel={onCancel}
            width={676}
            equalButtons
            footer={[
                <Button htmlType="reset" form="invalidateForm" size="large">
                    Cancel
                </Button>,
                <Button type="primary" form="invalidateForm" htmlType="submit" size="large">
                    <Text>Invalidate</Text>
                </Button>,
            ]}
        >
            {formComponent}
        </CustomModal>
    );
};
