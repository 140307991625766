import { LinkOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { ColumnsType, ColumnGroupType, ColumnType } from 'antd/lib/table';
import Tooltip from 'components/ui/base/tooltip/Tooltip';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { enumToText } from 'core/utils/enum-to-text';
import { FC, useMemo } from 'react';
import { Declaration, DeclarationSortParameters } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import {
    getDeclarantName,
    getMessageTypeByDeclaration,
    getDeclarationPayload,
    getExporter,
    getImporter,
    getMrn,
    getReferenceNumber,
} from 'views/declarations/utils/declaration-utils';
import DeclarationDocumentsStatusTag from '../../../components/ui/composed/declarations/declaration-documents/DeclarationDocumentsStatusTag';
import { DivTableActionInline } from '../DeclarationDashboard.styles';
import DeclarationDate from './components/DeclarationDate';
import { FlexDiv, SpanEllipsis, SpanMR5Icon, TooltipDiv } from './components/DeclarationsTable.styled';
import DescriptionOfGoods from './components/DescriptionOfGoods';
import ExporterIcon from './components/icons/ExporterIcon';
import ImporterIcon from './components/icons/ImporterIcon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { get, snakeCase, toUpper } from 'lodash';
import { CdsDeclaration } from '../../../store/declarations/uk/cds-declaration';
import { barcodeDisabled } from '../../declarations/uk/gvms/components/barcode/BarcodeView';
import {
    ArchiveButton,
    DeleteButton,
    DuplicateButton,
    MirrorButton,
    PrintButton,
    UnarchiveButton,
    BarcodeButton,
    CoreTemplateButton,
} from './dashboard-buttons';
import { gvmsNameLabels } from '../../../store/declarations/uk/gvms-declaration';
import { tsdNameLabels } from '../../../store/declarations/ireland/temporary-storage-declaration';

interface ActionEvents {
    onDuplicate?: (record: any) => void;
    onArchive?: (recordIds: string[]) => void;
    onPrint?: (recordId: string) => void;
    onMirror?: (record: any) => void;
    onDelete?: (record: any) => void;
    onUnarchive?: (record: any) => void;
    onBarcode?: (record: any) => void;
    onCoreTemplateToggle?: (record: any) => void;
    filterDataColumns?: (dataColumns: ColumnsType<any>) => (ColumnGroupType<any> | ColumnType<any>)[];
}

export enum DeclarationColumnsTitles {
    DECLARATION_TYPE = 'Decl.Type',
    ID = 'Id',
    DESCRIPTION_OF_GOODS = 'Descr. of goods',
    DECLARATION_STATUS = 'Decl. Status',
    IMPORTER_AND_EXPORTER = 'Importer & Exporter',
    AGENT_AND_DECLARANT = 'Agent & Declarant',
    DOCUMENTS = 'Documents',
    LAST_MODIFIED_DATE = 'Last Modified date',
    COMMANDS = 'Commands',
}

export const getDeclarationTitle = (declaration: Declaration | undefined) => {
    if (!declaration) return '';
    const internalType = getInternalType(declaration);
    let messageType = getMessageTypeByDeclaration(declaration);

    if (declaration?.preBoardingNotification || declaration?.entrySummaryDeclaration) return internalType;

    if (declaration?.gvmsDeclaration) {
        const foundVal = gvmsNameLabels.find((label) => label.key === messageType)?.value;
        messageType = foundVal || 'Unknown';
    } else if (declaration?.ieImportTemporaryStorageDeclaration) {
        const foundVal = tsdNameLabels.find((label) => label.key === messageType)?.value;
        messageType = foundVal || 'Unknown';
    }

    return `${internalType} (${messageType})`;
};

const getInternalType = (declaration: Declaration) => {
    if (declaration.declarationInternalType === 'IMPORT_NEW') {
        return enumToText('IMPORT');
    }
    return enumToText(declaration.declarationInternalType);
};

const getDeclarationType = (declaration: Declaration) => {
    const country = declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE ? 'Ireland' : 'UK';
    return (
        <FlexDiv>
            <SpanEllipsis>{country}</SpanEllipsis>
            <SpanEllipsis>{getDeclarationTitle(declaration)}</SpanEllipsis>
        </FlexDiv>
    );
};

const FullItemDescription: FC<{ declaration: Declaration }> = ({ declaration }) => (
    <TooltipDiv>
        <DescriptionOfGoods fullDescription declaration={declaration} />
    </TooltipDiv>
);

const DeclarationIds: FC<{ declaration: Declaration }> = ({ declaration }) => {
    const mrn = getMrn(declaration) ?? 'N/A';
    const ref = getReferenceNumber(declaration);
    return (
        <FlexDiv>
            <SpanEllipsis>Ref: {ref}</SpanEllipsis>
            <SpanEllipsis>MRN: {mrn}</SpanEllipsis>
        </FlexDiv>
    );
};

const DeclarationDescription: FC<{ declaration: Declaration }> = ({ declaration }) => {
    return (
        <FlexDiv>
            <DescriptionOfGoods declaration={declaration} />
        </FlexDiv>
    );
};

const ImporterAndExporterInformation: FC<{ declaration: Declaration; dataTestId?: string }> = ({
    declaration,
    dataTestId,
}) => {
    let payload = useMemo(() => getDeclarationPayload(declaration), [declaration]);
    if (!payload) return <></>;

    payload = (payload as CdsDeclaration)?.cdsDeclarationPayload ?? payload;

    const importer = getImporter(payload as any);
    const exporter = getExporter(payload as any);

    return (
        <FlexDiv data-testid={dataTestId}>
            <Row align="middle" wrap={false}>
                <SpanMR5Icon>
                    <ImporterIcon />
                </SpanMR5Icon>
                <SpanEllipsis> {importer?.name ?? importer?.eori ?? 'N/A'}</SpanEllipsis>
            </Row>
            <Row align="middle" wrap={false}>
                <SpanMR5Icon>
                    <ExporterIcon />
                </SpanMR5Icon>
                <SpanEllipsis> {exporter?.name ?? exporter?.eori ?? 'N/A'}</SpanEllipsis>
            </Row>
        </FlexDiv>
    );
};

const AgentAndDeclarant: FC<{ declaration: any; dataTestId?: string }> = ({ declaration, dataTestId }) => {
    const agent = declaration?.individual?.name ?? '-';
    const payload = getDeclarationPayload(declaration);
    const declarant = getDeclarantName(payload as any);
    return (
        <FlexDiv data-testid={dataTestId}>
            <SpanEllipsis>{agent}</SpanEllipsis>
            <SpanEllipsis>{declarant}</SpanEllipsis>
        </FlexDiv>
    );
};

const StyledLink = styled(Link)`
    transition: 100ms;
    border-radius: 50%;
    padding: 0.75rem;
    display: flex;

    :hover {
        background-color: #e6e9ed;
    }
`;

const dataColumns: ColumnsType<any> = [
    {
        title: DeclarationColumnsTitles.DECLARATION_TYPE,
        dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
        key: DeclarationSortParameters.MESSAGE_TYPE,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-type-row`;
            return record ? (
                {
                    children: (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }} data-testid={dataTestId}>
                            <SpanEllipsis>{getDeclarationType(record)}</SpanEllipsis>
                            {record.parentDeclarationId && (
                                <StyledLink
                                    to={`/declarations/${record.parentDeclarationId}`}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <LinkOutlined />
                                </StyledLink>
                            )}
                        </div>
                    ),
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: DeclarationColumnsTitles.ID,
        dataIndex: DeclarationSortParameters.ID,
        key: DeclarationSortParameters.ID,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-id-row`;
            return record ? (
                {
                    children: (
                        <SpanEllipsis data-testid={dataTestId}>
                            <DeclarationIds declaration={record} />
                        </SpanEllipsis>
                    ),
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: DeclarationColumnsTitles.DESCRIPTION_OF_GOODS,
        dataIndex: DeclarationSortParameters.DESCRIPTION_OF_GOODS,
        key: DeclarationSortParameters.DESCRIPTION_OF_GOODS,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-description-row`;
            return record ? (
                {
                    children: (
                        <Tooltip
                            placement="bottomLeft"
                            color="white"
                            overlay={<FullItemDescription declaration={record} />}
                        >
                            <SpanEllipsis data-testid={dataTestId}>
                                <DeclarationDescription declaration={record} />
                            </SpanEllipsis>
                        </Tooltip>
                    ),
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },

    {
        title: DeclarationColumnsTitles.DECLARATION_STATUS,
        dataIndex: DeclarationSortParameters.STATUS,
        key: DeclarationSortParameters.STATUS,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-status-row`;

            let status;

            const cdsRealStatus = get(record, 'cdsDeclaration.cdsDeclarationStatusMapping.cdsDescription');
            if (record?.cdsDeclaration && cdsRealStatus) {
                status = toUpper(snakeCase(cdsRealStatus));
            } else {
                status = record.status;
            }

            return record.status ? (
                {
                    children: <DeclarationStatusTag status={status} dataTestId={dataTestId} />,
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },

    {
        title: DeclarationColumnsTitles.IMPORTER_AND_EXPORTER,
        dataIndex: DeclarationSortParameters.EXPORTER,
        key: DeclarationSortParameters.EXPORTER,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-importer-and-exporter-row`;
            return record ? (
                {
                    children: <ImporterAndExporterInformation declaration={record} dataTestId={dataTestId} />,
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: DeclarationColumnsTitles.AGENT_AND_DECLARANT,
        dataIndex: DeclarationSortParameters.DECLARANT,
        key: DeclarationSortParameters.DECLARANT,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-agent-and-declarant-row`;
            return record ? (
                {
                    children: <AgentAndDeclarant declaration={record} dataTestId={dataTestId} />,
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: DeclarationColumnsTitles.DOCUMENTS,
        dataIndex: DeclarationSortParameters.DOCUMENTS,
        key: DeclarationSortParameters.DOCUMENTS,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-documents-row`;
            return record.status ? (
                {
                    children: (
                        <DeclarationDocumentsStatusTag
                            documentsUploadRequested={
                                record?.irelandImportDeclaration?.documentsUploadRequested ??
                                record?.irelandH7ImportDeclaration?.documentsUploadRequested ??
                                record?.cdsDeclaration?.documentsUploadRequested ??
                                record?.actionRequired
                            }
                            uploadedDocuments={record.uploadedFiles}
                            dataTestId={dataTestId}
                        />
                    ),
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: DeclarationColumnsTitles.LAST_MODIFIED_DATE,
        dataIndex: DeclarationSortParameters.CREATED_AT,
        key: DeclarationSortParameters.CREATED_AT,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-last-modified-date-row`;
            return record.createdAt ? (
                {
                    children: (
                        <Tooltip
                            placement="bottomLeft"
                            color="white"
                            overlay={<DeclarationDate createdDate declaration={record} />}
                        >
                            <SpanEllipsis data-testid={dataTestId}>
                                <DeclarationDate declaration={record} />
                            </SpanEllipsis>
                        </Tooltip>
                    ),
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
];

const getActionsColumns = (actions: ActionEvents) => {
    return [
        {
            title: DeclarationColumnsTitles.COMMANDS,
            dataIndex: 'Actions',
            key: 'Actions',
            width: 180,
            align: 'right' as any,
            render: (text: string, record: any) => {
                return {
                    children: (
                        <DivTableActionInline data-testid="declaration-commands-row">
                            {actions.onBarcode && (
                                <BarcodeButton
                                    record={record}
                                    onClick={actions.onBarcode}
                                    disabled={barcodeDisabled(record)}
                                />
                            )}
                            {actions.onPrint && <PrintButton record={record} onClick={actions.onPrint} />}
                            {actions.onMirror && <MirrorButton record={record} onClick={actions.onMirror} />}
                            {actions.onDelete && <DeleteButton record={record} onClick={actions.onDelete} />}
                            {actions.onDuplicate && record.declarationInternalType !== 'ARRIVAL' && (
                                <DuplicateButton record={record} onClick={actions.onDuplicate} />
                            )}
                            {actions.onCoreTemplateToggle && (
                                <CoreTemplateButton record={record} onClick={actions.onCoreTemplateToggle} />
                            )}
                            {actions.onArchive && <ArchiveButton record={record} onClick={actions.onArchive} />}
                            {actions.onUnarchive && <UnarchiveButton record={record} onClick={actions?.onUnarchive} />}
                        </DivTableActionInline>
                    ),
                };
            },
        },
    ];
};

const dashboardColumns = (actions: ActionEvents) => {
    const { filterDataColumns, ...actionButtons } = actions;

    const _dataColumns = !!filterDataColumns ? filterDataColumns(dataColumns) : dataColumns;

    return Object.values(actionButtons).some((action) => action)
        ? [..._dataColumns, ...getActionsColumns(actionButtons)]
        : [..._dataColumns];
};

export default dashboardColumns;
