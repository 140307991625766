import { Col, Row } from 'antd';
import { TextSmall, TextSmallBold } from 'components/ui/base/typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, Customer } from 'store/customers/customer';
import { colors } from 'theme';
import { CloseIcon } from './InvoiceUpload.styles';

interface Props {
    disabled: boolean;
    customer?: Customer;
    setCustomer: Function;
}
const CustomerDetails: FC<Props> = ({ customer, setCustomer, disabled }) => {
    const { t } = useTranslation('customs_declarations');

    const getAddressInline = (address: Address) =>
        address
            ? Object.values(address)
                  .filter((a) => !!a)
                  .join(',')
            : '';

    return (
        <>
            {customer && (
                <Row>
                    <Col>
                        <CloseIcon
                            className={`${disabled && 'disabled'}`}
                            onClick={() => {
                                if (!disabled) {
                                    setCustomer(undefined);
                                }
                            }}
                        />
                    </Col>
                    <Col>
                        <Row>
                            <TextSmallBold style={{ marginRight: '0.5rem' }}> {t('name')}:</TextSmallBold>
                            <TextSmall>{customer?.name}</TextSmall>
                        </Row>

                        <Row>
                            <TextSmallBold style={{ marginRight: '0.5rem' }}> {t('Address')}:</TextSmallBold>
                            <TextSmall>{getAddressInline(customer.address)}</TextSmall>
                        </Row>
                        <Row>
                            <TextSmallBold style={{ marginRight: '0.5rem' }}> {t('eori')}:</TextSmallBold>
                            <TextSmall style={{ color: colors.blueLink }}>
                                {customer.eori || (customer as any).identificationNumber}
                            </TextSmall>
                        </Row>
                    </Col>
                </Row>
            )}
        </>
    );
};
export default CustomerDetails;
