import { Party } from 'views/declarations/common/parties/PartiesCard';

export const IEH7_DataStructure = {
    goodsShipment: {
        valuationInformation: {},
        exporter: {},
        importer: {},
        locationOfGoodsAddress: {},
        documentsAuthorisations: {
            supportingDocument: {},
        },
    },
    declarant: {},
};

export const IEH7_ItemDataStructure = {
    additionalProcedure: [],
    documentsAuthorisations: {
        previousDocument: [],
        supportingDocument: [],
        additionalInformation: [],
    },
    valuationInformation: {
        transportDocument: [],
    },
    exporter: {},
    itemAmountInvoicedIntrinsicValue: {},
    dutiesAndTaxes: [],
    additionalFiscalReference: [],
};

export const IEH7_PartiesDataStructure: Party[] = [
    {
        path: 'goodsShipment.exporter',
        header: 'Exporter',
        refNumber: '3.1',
    },
    {
        path: 'goodsShipment.importer',
        header: 'Importer',
        refNumber: '3.15',
    },
    {
        path: 'declarant',
        header: 'Declarant',
        refNumber: '3.17',
        eoriRequired: true,
    },
];
