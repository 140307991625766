import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Individual } from './individual';

// TODO add query params
export const listIndividuals = (
    params?: Partial<PaginatedParams> & { active?: boolean }
): Promise<ListPayload<Individual>> =>
    axiosClient
        .get<ListResponse<Individual>>(`${config.cmsUrl}/individuals`, { params })
        .then((response) => response.data.payload);

export const createIndividual = (individual: Individual): Promise<Individual> =>
    axiosClient
        .post<SuccessResponse<Individual>>(`${config.cmsUrl}/individuals`, individual)
        .then((response) => response.data.payload);

export const editIndividual = (individualId: string, individual: Partial<Individual>): Promise<Individual> =>
    axiosClient
        .put<SuccessResponse<Individual>>(`${config.cmsUrl}/individuals/${individualId}`, individual)
        .then((response) => response.data.payload);

export const getIndividual = (individualId: string): Promise<Individual> =>
    axiosClient
        .get<SuccessResponse<Individual>>(`${config.cmsUrl}/individuals/${individualId}`)
        .then((response) => response.data.payload);

export const deleteIndividual = (individualId: string): Promise<SuccessResponse<void>> =>
    axiosClient.delete<SuccessResponse<void>>(`${config.cmsUrl}/individuals/${individualId}`).then((response) => {
        return response.data;
    });

export const getIndividualProfilePictureUploadPreSign = (
    individualId: string,
    params: { individualId: string; pictureName: string; pictureSize: number }
): Promise<string> =>
    axiosClient
        .get<SuccessResponse<string>>(`${config.cmsUrl}/individuals/profile-picture/${individualId}/upload`, {
            params,
        })
        .then((response) => {
            return response.data.payload;
        });

export const getIndividualProfilePictureDownloadPreSign = (individualId: string): Promise<string | null> =>
    axiosClient
        .get<SuccessResponse<string>>(`${config.cmsUrl}/individuals/profile-picture/${individualId}/download`)
        .then((response) => {
            return response.data.payload;
        })
        .catch((error) => {
            if (error.response?.status === 404) return null;
            throw error;
        });

export const uploadIndividualProfilePicture = (individualId: string, s3key: string): Promise<string> =>
    axiosClient
        .post<SuccessResponse<Individual & { profilePictureLink: string }>>(
            `${config.cmsUrl}/individuals/profile-picture/${individualId}`,
            undefined,
            { params: { individualId, s3key } }
        )
        .then((response) => {
            return response.data.payload.profilePictureLink;
        });

export const deleteIndividualProfilePicture = (individualId: string): Promise<void> =>
    axiosClient
        .delete<void>(`${config.cmsUrl}/individuals/profile-picture/${individualId}`)
        .then((response) => response.data);
