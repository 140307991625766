import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProvider } from 'formik';
import useTooltips from 'hooks/useTooltips';
import { get, set } from 'lodash';
import { FC, FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getBox44FieldPath,
    h1Box44KeyNames,
    h1PathBox44,
    handleBox44Field,
} from 'views/declarations/common/box44/box-44-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import AutoFillModal from '../components/AutoFillModal';
import useNumberOfItemsModal from 'views/declarations/common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from 'hooks/useProducts';
import useCodelists from '../../../../../../hooks/useCodelists';
import { validators } from 'views/declarations/uk/export/validations/validations';
import { useOutletContext } from 'react-router-dom';

const PREV_DOC_PATH = 'goodsShipment.simplifiedDeclarationDocumentWritingOff';

const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumber } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();
    const { createIrelandImportProduct, listIrelandH1Products } = useProducts();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);
    const [invoiceNumberChanged, setInvoiceNumberChanged] = useState<boolean>(false);

    const { saveAsDraft } =
        useOutletContext<{
            saveAsDraft: (withNotification: boolean, data?: unknown) => Promise<unknown>;
        }>() ?? {};

    const openModal = (value: string | null) => {
        setInvoiceNumberChanged(false);
        setOpen(true);
        setValue(value);
    };
    const closeModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };

    const formik = useMemo(() => {
        if (template && templateFormik) return templateFormik;
        return props.formik;
    }, [props.formik, template, templateFormik]);

    const prevDocumentsIndex = useMemo(() => {
        const prevDocsRecords = formik?.getFieldProps(PREV_DOC_PATH).value;

        if (!prevDocsRecords) return 0;

        const prevDocWithType380Index = prevDocsRecords?.findIndex(
            (prevDoc: any) => prevDoc?.previousDocumentType === '380'
        );

        if (prevDocWithType380Index !== -1) return prevDocWithType380Index;

        return prevDocsRecords.length;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.getFieldProps(PREV_DOC_PATH).value]);

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => {
            let _value = value;
            let _field = field;

            if (field.includes(PREV_DOC_PATH)) {
                const path = template ? `master.defaults.${PREV_DOC_PATH}` : PREV_DOC_PATH;
                let previousDocuments: any[] | undefined = get(values, path);
                previousDocuments = previousDocuments ? [...previousDocuments] : [];
                if (!value) {
                    previousDocuments.splice(prevDocumentsIndex, 1);
                } else {
                    previousDocuments[prevDocumentsIndex] = {
                        previousDocumentType: '380',
                        previousDocumentIdentifier: value,
                    };
                }
                _value = previousDocuments as any;
                _field = path;
            } else if (field.includes(h1PathBox44)) {
                const path = template ? `master.defaults.${h1PathBox44}` : h1PathBox44;
                let box44 = { ...((get(values, path) as Record<string, string[]> | undefined) ?? {}) };
                if (!value) {
                    delete box44.N730;
                } else {
                    box44.N730 = [value];
                }
                _value = box44 as any;
                _field = path;
            }

            return set(acc, _field, _value);
        }, values);
        formik?.setValues(newValues);
    };

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createIrelandImportProduct,
        listProducts: listIrelandH1Products,
        saveAsDraft,
    });

    const invoiceNumberFormikProps = useMemo(
        () => getFormikProps(getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N935' }), props),
        [props]
    );

    const handleInvoiceNumberBlur = useCallback(
        (e?: FocusEvent<HTMLInputElement>) => {
            const value = e?.target?.value ?? invoiceNumberFormikProps.fieldMeta?.value;
            /**
             * NOTE
             * Cheating the error validation by doing another validation
             * that is not connected to the actual validations.
             * Going in the "right" route will required more code and
             * it will become unnecessarily complicated.
             */
            if (!invoiceNumberChanged) return;
            const invoiceNumberError = value.length > 0 && value.length < 5;
            if (invoiceNumberError) return;
            openModal(value);
        },
        [invoiceNumberChanged, invoiceNumberFormikProps.fieldMeta?.value]
    );

    useEffect(() => {
        const changeSubscriberId = window.EventBus.subscribe('invoiceNumberChanged', setInvoiceNumberChanged);
        const invoiceModalSavedSubscriberId = window.EventBus.subscribe('invoiceModalSaved', () =>
            handleInvoiceNumberBlur()
        );
        return () => {
            window.EventBus.unsubscribe(changeSubscriberId);
            window.EventBus.unsubscribe(invoiceModalSavedSubscriberId);
        };
    }, [handleInvoiceNumberBlur]);

    return (
        <FormikProvider value={props.formik!}>
            <NewFormCard title={t('masterDetails')}>
                <FormCardContainer>
                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`declarationType`, props)}
                        refNumber="1.1"
                        label={t('declarationType')}
                        tooltip={getH1TooltipsByRefNumber('1.1')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={aisCodelists?.typeOfDeclaration}
                        disabled={props.amendment}
                        condensed
                        codeValidation={[validators.exact(2)]}
                    />

                    <FormSelect
                        required
                        disabled={props.amendment}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`additionalDeclarationType`, props)}
                        refNumber="1.2"
                        label={t('additionalDeclarationType')}
                        tooltip={getH1TooltipsByRefNumber('1.2')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={aisCodelists?.additionalDeclarationType}
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />

                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`goodsShipment.ucr`, props)}
                        refNumber="2.4"
                        label={t('ucr')}
                        tooltip={getH1TooltipsByRefNumber('2.4')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`numberOfItems`, props)}
                        fieldEvents={{
                            onBlur(value) {
                                handleNumberOfItems(Number(value));
                            },
                        }}
                        required
                        label={t('numberOfItems')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    {modalContextHolder}

                    <FormInput
                        maxLength={512}
                        viewOnly={props.viewOnly}
                        {...invoiceNumberFormikProps}
                        onChange={(e) => {
                            handleBox44Field(
                                e.target.value,
                                template && templateFormik ? templateFormik : props,
                                {
                                    path: h1PathBox44,
                                    keyNames: h1Box44KeyNames,
                                    type: 'N935',
                                },
                                { template, form }
                            );
                            setInvoiceNumberChanged(true);
                        }}
                        label={t('Invoice Number')}
                        tooltip={
                            getH1TooltipsByRefNumber('N935') !== 'N/A' ? getH1TooltipsByRefNumber('N935') : undefined
                        }
                        refNumber="N935"
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        onBlur={handleInvoiceNumberBlur}
                        condensed
                    />
                    <AutoFillModal
                        visible={open}
                        onCancel={closeModal}
                        fields={[
                            { label: t('roadConsignmentNote'), name: `${h1PathBox44}.N730.0` },
                            {
                                label: t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier'),
                                name: `${PREV_DOC_PATH}.${prevDocumentsIndex}.previousDocumentIdentifier`,
                            },
                        ]}
                        value={value}
                        prevValue={prevValue}
                        onOk={autoFill}
                    />

                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`grossMass`, props)}
                        refNumber="6.5"
                        label={t('goodsItems.grossMass')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>
        </FormikProvider>
    );
};

export default MasterDetailsCard;
