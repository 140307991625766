import { CloseCircleFilled, FileOutlined } from '@ant-design/icons';
import { StyledInfoCircleFilled } from 'App.styles';
import Button from 'components/ui/base/button';
import Divider from 'components/ui/base/divider';
import { H5, H6 } from 'components/ui/base/typography';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarationProductTaxes from 'hooks/useDeclarationProductTaxes';
import useDeclarations from 'hooks/useDeclarations';
import useGetDeclarationMapValues from 'hooks/useGetDeclarationMapValues';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    generateIrelandClearanceDraft,
    generateIrelandSadDraft,
    getIrelandClearanceDraft,
    getIrelandSadDraft,
} from 'store/declarations/client';
import { taxPaymentMethodLabels } from 'store/declarations/enums/ireland/tax-payment-method';
import { colors } from 'theme';
import useFormUtils from '../../../hooks/useFormUtils';
import { getDeclarationPayload } from '../utils/declaration-utils';
import CommodityCodeTable from './CommodityCodeTable';
import { missingGrossMassFromAllItems, missingGrossMassFromMaster } from './declaration-view/utils/grossMassUtils';
import GrossMassSuggestion from './GrossMassSuggestions';
import {
    Alert,
    CustomModal,
    ErrorGenerateDocument,
    HyperLinkDiv,
    LoadingDocuments,
    PreviewSadText,
    TotalInformationLabel,
    TotalInformationParam,
    TotalInformationSection,
    TotalInformationTitle,
} from './ModalPreviewSadAndClearanceSlip.styles';
import ValidationErrorContainer from './ValidationErrorContainer/ValidationErrorContainer';

interface Props {
    visible: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onToggle: (switched: boolean) => void;
    toggled: boolean;
}

interface Documents {
    sadDoc?: Document;
    clearanceslip?: Document;
}

interface Document {
    fileName?: string;
    type?: string;
    blob: Blob;
}

const ModalPreviewSadAndClearanceSlip: FC<Props> = ({ visible, onClose, onConfirm, onToggle, toggled }) => {
    const { declarationId } = useParams();
    const { commodityCodeTaxes, getDeclarationProductTaxes } = useDeclarationProductTaxes(declarationId);
    const { declarationErrors } = useDeclarationFormErrors();
    const [documents, setDocuments] = useState<Documents>({});
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
    const { isAis } = useFormUtils();
    const [isValidationErrorsModalOpen, setIsValidationErrorsModalOpen] = useState<boolean>(false);

    const methodOfPayment = useMemo(() => {
        return (
            taxPaymentMethodLabels.find((e) => e.id === commodityCodeTaxes?.methodOfPayment) || { id: '', value: '' }
        );
    }, [commodityCodeTaxes]);

    const generateSadDocument = async (declarationId: string) => {
        const sadDoc = await generateIrelandSadDraft(declarationId).then(() =>
            getIrelandSadDraft(declarationId, 'DRAFT')
        );

        return sadDoc;
    };

    const generateClearanceDocument = async (declarationId: string) => {
        const clearances = await generateIrelandClearanceDraft(declarationId).then(() =>
            getIrelandClearanceDraft(declarationId, 'DRAFT')
        );

        return clearances;
    };

    const handleFetchDocuments = useCallback(async (declarationId: string) => {
        setLoadingDocuments(true);

        const sadDoc = await generateSadDocument(declarationId);

        const clearances = await generateClearanceDocument(declarationId);

        setDocuments({
            sadDoc: sadDoc,
            clearanceslip: clearances,
        });
        setLoadingDocuments(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (declarationId && visible && isAis) {
            handleFetchDocuments(declarationId);
            getDeclarationProductTaxes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, handleFetchDocuments, declarationId, isAis]);

    const handleOpenLink = (blob?: Blob) => {
        if (blob) {
            window.open(URL.createObjectURL(blob));
        }
    };

    const declarationWithErrors = useMemo(() => {
        return declarationErrors.items.length > 0 || declarationErrors.masterDetails.length > 0;
    }, [declarationErrors]);

    const errors = useMemo(() => {
        if (declarationWithErrors) {
            return (
                <Alert
                    message="This declaration contains errors."
                    type="error"
                    showIcon
                    action={
                        <Button size="small" type="text" onClick={() => setIsValidationErrorsModalOpen(true)}>
                            Details
                        </Button>
                    }
                />
            );
        }
        return <></>;
    }, [declarationWithErrors, setIsValidationErrorsModalOpen]);

    const sadFile = useMemo(() => {
        const { sadDoc } = documents;

        if (!sadDoc) {
            return (
                <ErrorGenerateDocument>
                    <CloseCircleFilled /> Error generating SAD
                </ErrorGenerateDocument>
            );
        }

        const name = sadDoc?.fileName ?? 'SADdocument';
        const type = sadDoc?.type ?? '.pdf';
        const fullName = `${name}${type}`;

        return (
            <HyperLinkDiv onClick={() => handleOpenLink(documents?.sadDoc?.blob)}>
                <FileOutlined /> {fullName}
            </HyperLinkDiv>
        );
    }, [documents]);

    const clearanceDoc = useMemo(() => {
        const { clearanceslip } = documents;

        if (!clearanceslip) {
            return (
                <ErrorGenerateDocument>
                    <CloseCircleFilled /> Error generating Clearance slip
                </ErrorGenerateDocument>
            );
        }

        const name = clearanceslip?.fileName ?? 'Clearanceslip';
        const type = clearanceslip?.type ?? '.pdf';
        const fullName = `${name}${type}`;

        return (
            <HyperLinkDiv onClick={() => handleOpenLink(documents?.clearanceslip?.blob)}>
                <FileOutlined /> {fullName}
            </HyperLinkDiv>
        );
    }, [documents]);

    return (
        <>
            <CustomModal
                title={
                    <>
                        <H5>Declaration Documents</H5>
                        {errors}
                    </>
                }
                centered
                closable={true}
                visible={visible}
                onCancel={onClose}
                width={800}
                zIndex={900}
                cancelText="Cancel"
                footer={[
                    <Button key="goToSettings" onClick={onClose} size="large">
                        Cancel
                    </Button>,
                    <Button
                        type="primary"
                        disabled={declarationWithErrors}
                        key="goToDeclaration"
                        onClick={onConfirm}
                        size="large"
                    >
                        Confirm
                    </Button>,
                ]}
            >
                {isAis && (
                    <>
                        <PreviewSadText>
                            Now you can view the SAD and Clearance slip documents. Please make sure all is correct
                            before you confirm.
                        </PreviewSadText>
                        {loadingDocuments ? (
                            <div>
                                <LoadingDocuments />
                            </div>
                        ) : (
                            <div>
                                {sadFile}
                                {clearanceDoc}
                            </div>
                        )}
                        <Divider />
                    </>
                )}
                <GrossMassSuggestion withSwitch onToggle={onToggle} toggled={toggled} />
                <GrossMassSumInfo />
                <H6>Local Vat/Duty Summary</H6>
                <CommodityCodeTable data={commodityCodeTaxes} />
                <TotalInformationSection>
                    <TotalInformationTitle>Total Information</TotalInformationTitle>
                    <TotalInformationParam>
                        <TotalInformationLabel>Total Statistical Value: </TotalInformationLabel>
                        {commodityCodeTaxes?.totalStatisticalValue?.toFixed(2)}
                    </TotalInformationParam>
                    <TotalInformationParam>
                        <TotalInformationLabel>Total Liability: </TotalInformationLabel>
                        {commodityCodeTaxes?.totalLiability?.toFixed(2)}
                    </TotalInformationParam>
                    <TotalInformationParam>
                        <TotalInformationLabel>Method of Payment: </TotalInformationLabel>
                        {`${methodOfPayment?.id} - ${methodOfPayment?.value}`}
                    </TotalInformationParam>
                    <TotalInformationParam>
                        <TotalInformationLabel>Deferred Payment: </TotalInformationLabel>
                        {commodityCodeTaxes?.customsDutyPayer}
                    </TotalInformationParam>
                </TotalInformationSection>
            </CustomModal>

            <ValidationErrorContainer
                isOpen={isValidationErrorsModalOpen}
                close={() => setIsValidationErrorsModalOpen(false)}
            />
        </>
    );
};

const GrossMassSumInfo = () => {
    const { declaration } = useDeclarations();
    const mapper = useGetDeclarationMapValues();
    const { products } = useProducts();

    const show = useMemo(() => {
        const grossMassMasterMissing = missingGrossMassFromMaster(
            getDeclarationPayload(declaration),
            mapper.grossMassPath
        );
        const grossMassAllItemsMissing = missingGrossMassFromAllItems(products?.list, mapper.itemGrossMassPath);

        return grossMassMasterMissing && !grossMassAllItemsMissing;
    }, [declaration, mapper.grossMassPath, mapper.itemGrossMassPath, products?.list]);

    if (!show) return null;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <div style={{ display: 'flex', gap: '12px' }}>
                        <StyledInfoCircleFilled color="warning" style={{ color: 'orange', margin: 0 }} />
                        <h3 style={{ margin: 0 }}>Gross mass</h3>
                    </div>
                    <p style={{ margin: 0, fontSize: '1.25rem', color: colors.darkGrey }}>
                        We will sum the gross mass of all items and populate the master gross mass field with it.
                    </p>
                </div>
            </div>
            <Divider />
        </>
    );
};

export default ModalPreviewSadAndClearanceSlip;
