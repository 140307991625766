import {
    FieldHelperProps,
    FieldInputProps,
    FieldMetaProps,
    FormikConfig,
    FormikProps,
    FormikProvider,
    FormikValues,
    useFormikContext,
} from 'formik';
import { cloneDeep, get, isEmpty, kebabCase } from 'lodash';
import { CSSProperties, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import NewFormCard from './cards/NewFormCard';
import FormCard from './cards/FormCard';
import ConditionalWrapper from 'components/ConditionalWrapper';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { ButtonProps, Divider } from 'antd';
import { HollowButton } from './box44/Box44';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import addPathPrefix from 'utils/addPathPrefix';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useProducts from 'hooks/useProducts';
import { useDeclarationContext } from 'utils/DeclarationContext';
import useDeclarations from 'hooks/useDeclarations';
import { useLocation, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

const MicWrapper = styled.div<{ formIsVisible: boolean }>`
    position: ${({ formIsVisible }) => (formIsVisible ? 'static' : 'absolute')};
    opacity: ${({ formIsVisible }) => (formIsVisible ? 1 : 0)};
    top: ${({ formIsVisible }) => (formIsVisible ? 'auto' : '-9999px')};
    left: ${({ formIsVisible }) => (formIsVisible ? 'auto' : '-9999px')};
`;

const AddItemButton = (props: ButtonProps): ReactElement => {
    return (
        <HollowButton size="small" {...props}>
            Add Item +
        </HollowButton>
    );
};

export interface FormProps {
    getFieldProps: (name: string) => FieldInputProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
}

type DataList = { field: string; value: string | undefined }[][];

interface Props<TValue extends FormikValues> extends Partial<DeclarationFormCardProps> {
    initialValues: FormikConfig<TValue>['initialValues'];
    validationSchema?: FormikConfig<TValue>['validationSchema'];
    path: string;
    title: string;
    list: (obj: TValue) => { field: string; value?: string | ReactNode[] | string[] }[];
    form?: (props: FormProps, path?: string) => ReactElement;
    children?: (path: string | null) => ReactNode;
    required?: boolean;
    formik?: FormikProps<any>;
    condensed?: boolean;
    onAdd?: (value: TValue) => void;
    onEdit?: (value: TValue) => void;
    onDelete?: (index: number) => void;
    beforeDelete?: (index: number) => void | false;
    refNumber?: string | string[];
    requiredFields?: string[];
    cardless?: boolean;
    style?: CSSProperties;
    ctaButtons?: (micCardIndex: number | undefined) => JSX.Element[];
}

const MultipleItemsCard = <TValue extends FormikValues>(props: Props<TValue>) => {
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const { form } = useDeclarationContext();
    const { declaration } = useDeclarations();
    const { form: templateForm, template, templateFormik, isViewOnly: isTemplateViewOnly } = useTemplateContext();
    const { products } = useProducts();
    const formik = useFormikContext<any>();

    const [status, setStatus] = useState<'IDLE' | 'ADDING' | 'EDITING'>('IDLE');
    const [recordToEdit, setRecordToEdit] = useState<{ index: number; value: any }>();
    const [dataList, setDataList] = useState<DataList | null>(null);

    const convertToDataList = useCallback(
        (data: any[] | undefined | null) => (Array.isArray(data) ? (data?.map(props.list) as DataList) : []),
        [props.list]
    );

    const path = useMemo(
        () => (template ? addPathPrefix(`${templateForm}.defaults`, props.path) : props.path),
        [props.path, template, templateForm]
    );

    const formIsVisible = useMemo(() => status === 'EDITING' || status === 'ADDING', [status]);

    const formikToUse = useMemo(
        () => (template && templateFormik ? templateFormik : formik),
        [formik, template, templateFormik]
    );

    const items: any[] = useMemo(
        () => formikToUse.getFieldProps(path)?.value ?? [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formikToUse.getFieldProps(path)?.value]
    );

    const isViewOnly = useMemo(() => {
        if (outletContext?.amendment) return false;
        return props.viewOnly || inViewOnly;
    }, [props.viewOnly, inViewOnly, outletContext?.amendment]);

    const updateDataList = () => {
        setDataList(convertToDataList(items));
    };

    const deleteTemplateMeta = useCallback(
        (index: number) => {
            const meta = cloneDeep(templateFormik?.getFieldProps(`${templateForm}.meta`).value);
            const fieldMeta = Object.fromEntries(Object.entries(meta).filter(([key]) => key.startsWith(props.path)));

            // Delete all the meta data for the field
            Object.keys(fieldMeta).forEach((key) => {
                delete meta[key];
            });

            // Delete the meta data for the deleted item
            const keys = Object.keys(fieldMeta).filter((key) => key.startsWith(`${props.path}.${index}`));
            keys.forEach((key) => {
                delete fieldMeta[key];
            });

            // Rearrange the meta data for the remaining items
            const newMeta = Object.fromEntries(
                Object.entries(fieldMeta).map(([key, value], i) => {
                    const keySplit = key.split('.');
                    const currentIndex = parseInt(keySplit[keySplit.length - 2]);
                    if (currentIndex < index) return [key, value];
                    keySplit[keySplit.length - 2] = `${currentIndex - 1}`;
                    return [keySplit.join('.'), value];
                })
            );

            templateFormik?.setFieldValue(`${templateForm}.meta`, { ...meta, ...newMeta });
        },
        [props.path, templateForm, templateFormik]
    );

    const triggerAddFlow = () => {
        if (status === 'ADDING') return;
        setStatus('ADDING');
        if (recordToEdit !== undefined) setRecordToEdit(undefined);
        const newItems = [...items];
        newItems.push(props.initialValues);

        formikToUse.setFieldValue(path, newItems);

        // Template handle
        if (template) {
            const meta = cloneDeep(templateFormik?.getFieldProps(`${templateForm}.meta`).value);
            const fields = Object.keys(props.initialValues);

            fields.forEach((field) => {
                meta[`${props.path}.${newItems.length - 1}.${field}`] = { isViewable: false, isEditable: true };
            });

            templateFormik?.setFieldValue(`${templateForm}.meta`, meta);
        }
    };
    const triggerEditFlow = (index: number) => {
        if (status === 'ADDING') {
            const newItems = [...items];
            newItems.pop();
            formikToUse.setFieldValue(path, newItems);
        }

        setStatus('EDITING');
        setRecordToEdit({
            index,
            value: formikToUse.getFieldProps(addPathPrefix(path, index.toString()))?.value,
        });
    };
    const cancelFlow = useCallback(() => {
        setStatus('IDLE');
        setRecordToEdit(undefined);
        const newItems = [...items];
        if (status === 'EDITING' && recordToEdit) newItems[recordToEdit?.index] = recordToEdit?.value;
        else if (status === 'ADDING') {
            if (template) deleteTemplateMeta(newItems.length - 1);
            newItems.pop();
        }
        formikToUse.setFieldValue(path, newItems);
        return newItems;
    }, [items, status, recordToEdit, formikToUse, path, template, deleteTemplateMeta]);

    const deleteRecord = (index: number) => {
        const values = [...items];
        values.splice(index, 1);

        formikToUse.getFieldHelpers(path).setValue(values);

        props.onDelete?.(index);

        if (index === recordToEdit?.index) {
            setRecordToEdit(undefined);
            setStatus('IDLE');
        }

        /**
         * If deleting an item while adding we don't want to show
         * the added record in the data list so it is removed
         * from the data list but stays in the data itself
         */
        const newValues = [...values];
        if (status === 'ADDING') {
            newValues.pop();
        }

        if (template) deleteTemplateMeta(index);

        setDataList(convertToDataList(newValues));
    };

    const handleAddEditButton = useCallback(async () => {
        setStatus('IDLE');
        const list = formikToUse.getFieldProps(path)?.value;
        setDataList(convertToDataList(list));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertToDataList, formikToUse.getFieldProps(path)?.value]);

    useEffect(() => {
        const subscriberId = window.EventBus.subscribe('afterDeclarationSave', () => {
            handleAddEditButton();
        });
        return () => window.EventBus.unsubscribe(subscriberId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleAddEditButton]);

    /**
     * Update data list when the products or declaration changes
     */
    useEffect(() => {
        if (status === 'IDLE') {
            updateDataList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, form, declaration, items]);

    const regularFormPathIndex = useMemo(
        (): number => (status === 'EDITING' ? recordToEdit?.index : items.length ? items.length - 1 : 0) ?? 0,
        [items.length, recordToEdit?.index, status]
    );

    const regularForm = useMemo(() => {
        const micPath = addPathPrefix(props.path, regularFormPathIndex.toString());
        return (
            <FormikProvider value={formikToUse}>
                {props.form ? props.form(formikToUse, micPath) : props.children?.(micPath)}
            </FormikProvider>
        );
    }, [formikToUse, props, regularFormPathIndex]);

    const { declarationErrors } = useDeclarationFormErrors();

    const errors = useMemo(() => {
        if (isEmpty(declarationErrors.masterDetails) && isEmpty(declarationErrors.items)) return [];
        return get(formikToUse.errors, path);
    }, [declarationErrors, formikToUse.errors, path]);

    const cardButtons = useMemo(() => {
        if (isViewOnly) return null;

        const buttons: JSX.Element[] = [];

        if (!isTemplateViewOnly && props.ctaButtons && formIsVisible) {
            const ctaButtons = props.ctaButtons(regularFormPathIndex);
            ctaButtons.forEach((button) => buttons.push(button));
        }

        if (!isTemplateViewOnly)
            buttons.push(
                <AddItemButton
                    key={`add-item-button-${path}`}
                    id={`${kebabCase(props.title)}-add-item-button`}
                    onClick={triggerAddFlow}
                />
            );

        return buttons;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isViewOnly, path, props.title, template, isTemplateViewOnly, formIsVisible, props.ctaButtons, items]);

    if (props.hidden) return null;

    return (
        <ConditionalWrapper
            condition={!props.condensed && !props.cardless}
            wrapper={(children) => (
                <FormCard
                    defaultOpen={props.defaultOpen}
                    viewOnly={isViewOnly}
                    cardNumber={props.cardNumber}
                    expandAll={props.expandAll}
                    total={props.cardTotal}
                    keyCard={props.keyCard}
                    title={props.title}
                >
                    {children}
                </FormCard>
            )}
        >
            <ConditionalWrapper
                condition={props.condensed && !props.cardless}
                wrapper={(children) => (
                    <NewFormCard
                        title={props.title}
                        mic={{ path: path, required: props.required }}
                        refNumber={props.refNumber}
                        cardButtons={cardButtons}
                        error={typeof errors === 'string' ? errors : undefined}
                    >
                        {children}
                    </NewFormCard>
                )}
            >
                <>
                    <MicWrapper formIsVisible={!!formIsVisible}>
                        {formIsVisible ? regularForm : null}
                        {!isViewOnly && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    gap: '1rem',
                                    marginTop: '1rem',
                                }}
                            >
                                <HollowButton
                                    onClick={cancelFlow}
                                    size="small"
                                    id={`${kebabCase(props.title)}-cancel-button`}
                                >
                                    Cancel <CloseOutlined height={'min-content'} />
                                </HollowButton>
                                <HollowButton
                                    onClick={handleAddEditButton}
                                    size="small"
                                    id={`${kebabCase(props.title)}-${!!recordToEdit ? 'edit' : 'add'}-button`}
                                >
                                    {recordToEdit !== undefined ? (
                                        <>
                                            Confirm <CheckOutlined />
                                        </>
                                    ) : (
                                        <>
                                            Add <CheckOutlined />
                                        </>
                                    )}
                                </HollowButton>
                            </div>
                        )}
                        <Divider style={{ marginTop: '1rem' }} />
                    </MicWrapper>

                    <div style={{ marginTop: dataList?.length ? '1rem' : 0 }}>
                        <CardList
                            data={dataList ?? []}
                            errors={errors as any[]}
                            onDelete={(index: number) => {
                                if (props.beforeDelete?.(index) === false) return;
                                deleteRecord(index);
                            }}
                            onEdit={triggerEditFlow}
                            condensed
                            viewOnly={(isViewOnly || isTemplateViewOnly) ?? false}
                            cardTitle={kebabCase(props.title)}
                        />
                    </div>
                </>
            </ConditionalWrapper>
        </ConditionalWrapper>
    );
};
export default MultipleItemsCard;
