import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import { Button } from 'antd';
import { TextExtraSmall, TextExtraSmallBold } from 'components/ui/base/typography';
import { FC, useMemo, useState } from 'react';
import { CountryResponses, TaricMeasurement } from 'store/taric-codes/taric-code';
import styled from 'styled-components';
import {
    FlexDiv,
    Measurement,
    MeasurementInfo,
    ListText,
    MeasurementDiv,
    SelectCodeButton,
    Title,
    Measurements,
} from '../TaricCode.styles';
interface Props {
    title: string;
    code: string;
    taricMeasurement?: TaricMeasurement[];
    selectedCommodityCode: (code: string, duty?: string, vat?: string) => void;
}
export const MeasurementInformation: FC<Props> = ({ taricMeasurement, title, code, selectedCommodityCode }) => {
    const hasMeasurement = useMemo(() => !!taricMeasurement?.length, [taricMeasurement]);

    const getLegalBase = (legalBase: string | undefined | null) => {
        if (!legalBase) return null;
        const legalBaseSplit = legalBase.split(' ');
        return legalBaseSplit[0][0] + legalBaseSplit[1];
    };

    return (
        <>
            <Title>{title}</Title>
            <Measurements>
                <FlexDiv>
                    <ListText>{code}</ListText>
                    <SelectCodeButton
                        onClick={(e) => {
                            e.preventDefault();
                            selectedCommodityCode(code);
                        }}
                    >
                        <TextExtraSmall>
                            Select commodity code <CopyOutlined />
                        </TextExtraSmall>
                    </SelectCodeButton>
                </FlexDiv>
                <>
                    {hasMeasurement &&
                        taricMeasurement?.map((m, index) => (
                            <MeasurementDiv key={`${m.journey}-${index}`}>
                                <TextExtraSmallBold>{m.journey}</TextExtraSmallBold>
                                {m.countryResponses.map((c, i) => (
                                    <Measurement key={`${c.measureType}-${i}`}>
                                        <MeasurementInfo>
                                            <ListText>
                                                {c.measureType}
                                                {!!c.duty && (
                                                    <>
                                                        : <b>{c.duty ?? '-'}</b>
                                                    </>
                                                )}
                                            </ListText>

                                            {!!c.exclusions.length && (
                                                <ListText style={{ color: 'orangered' }}>
                                                    Exclusions: {c.exclusions.join(', ')}
                                                </ListText>
                                            )}

                                            {!!c.conditions.length && (
                                                <MeasurementConditions conditions={c.conditions} />
                                            )}
                                        </MeasurementInfo>
                                        <MeasurementInfo>
                                            <ListText>{getLegalBase(c.legalBase) ?? '-'}</ListText>
                                        </MeasurementInfo>
                                    </Measurement>
                                ))}
                            </MeasurementDiv>
                        ))}
                </>
            </Measurements>
        </>
    );
};

const MeasurementConditions = (props: { conditions: CountryResponses['conditions'] }) => {
    const [show, setShow] = useState(false);

    const formatCertificate = (certificate: string | undefined) => {
        if (!certificate) return '';

        const letter = certificate[0];
        const numbers = certificate.match(/\d+/)?.[0];
        return `${letter} ${numbers}`;
    };

    const certificatesWithDescriptions = useMemo(
        () =>
            props.conditions
                .filter((condition) => condition.certificateDescription)
                .map((condition) => ({
                    name: condition.certificate,
                    description: condition.certificateDescription,
                })),
        [props.conditions]
    );

    return (
        <>
            <Button
                type="link"
                size="small"
                onClick={() => setShow((prev) => !prev)}
                style={{ maxWidth: 'min-content' }}
            >
                <ListText>{show ? 'Hide conditions' : 'Show conditions'}</ListText>
            </Button>
            {show && (
                <>
                    <RegularTable>
                        {props.conditions.map((condition, index) => (
                            <tr key={index}>
                                <td>{condition.number}</td>
                                <td>{formatCertificate(condition.certificate)}</td>
                                <td>{condition.action}</td>
                                <td>{condition.condition}</td>
                            </tr>
                        ))}
                    </RegularTable>

                    {!!certificatesWithDescriptions.length && (
                        <>
                            <ListText>
                                Additional information/Documents produced/Certificates and authorisations
                            </ListText>

                            <RegularTable>
                                {certificatesWithDescriptions.map((certificate, index) => (
                                    <tr key={index}>
                                        <td>{certificate.name}</td>
                                        <td>{certificate.description}</td>
                                    </tr>
                                ))}
                            </RegularTable>
                        </>
                    )}
                </>
            )}
        </>
    );
};

const RegularTable = styled.table`
    width: fit-content;

    & td,
    th {
        padding: 0.15rem 0.5rem;
        border: 1px solid black;
        font-size: 12px;
    }
`;

export default MeasurementInformation;
