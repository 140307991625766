import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTemplateContext } from '../components/ui/composed/template/TemplateContext';
import useDeclarations from './useDeclarations';

const useFormUtils = () => {
    const { declaration } = useDeclarations();
    const { options: submissionFormTemplate } = useTemplateContext();
    const {
        country: productTemplateCountry,
        internalType: productTemplateInternalType,
        type: productTemplateFormType,
        declarationId,
    } = useParams();

    const country = useMemo(
        () =>
            submissionFormTemplate?.country ??
            productTemplateCountry?.toUpperCase() ??
            (declarationId &&
            (declaration?.declarationExternalEntity === 'CDS' || declaration?.declarationExternalEntity === 'HMRC')
                ? 'UK'
                : 'IRELAND'),
        [declarationId, declaration, submissionFormTemplate?.country, productTemplateCountry]
    );

    const internalType = useMemo(() => {
        const internalType =
            submissionFormTemplate?.declarationType ??
            productTemplateInternalType ??
            (declarationId && declaration?.declarationInternalType);

        return internalType === 'IMPORT_NEW' ? 'IMPORT' : internalType;
    }, [
        declarationId,
        declaration?.declarationInternalType,
        productTemplateInternalType,
        submissionFormTemplate?.declarationType,
    ]);

    const externalEntity = useMemo(
        () =>
            (declarationId && declaration?.declarationExternalEntity) ??
            (submissionFormTemplate &&
                (submissionFormTemplate?.country === 'UK'
                    ? submissionFormTemplate?.declarationType === 'NCTS'
                        ? 'HMRC'
                        : 'CDS'
                    : 'REVENUE')) ??
            (productTemplateCountry === 'uk' ? (internalType === 'NCTS' ? 'HMRC' : 'CDS') : 'REVENUE'),
        [
            declarationId,
            declaration?.declarationExternalEntity,
            submissionFormTemplate,
            productTemplateCountry,
            internalType,
        ]
    );

    const declarationFormType = useMemo(() => {
        if (declaration?.irelandH7ImportDeclaration) return 'H7';
        if (declaration?.entrySummaryDeclaration) return 'ENS';
        if (declaration?.ieArrivalAtExitDeclaration) return 'ARRIVAL';

        return (
            declaration?.cdsDeclaration?.messageType ??
            declaration?.irelandImportDeclaration?.messageType ??
            declaration?.ieExportDeclaration?.messageType ??
            declaration?.gvmsDeclaration?.messageType ??
            declaration?.ieImportTemporaryStorageDeclaration?.declaration?.msgType ??
            declaration?.ieNctsDeclaration?.messageType ??
            declaration?.ukNctsDeclaration?.messageType ??
            declaration?.ieImportElectronicTransportDocument?.declaration?.messageType
        );
    }, [declaration]);

    const formType = useMemo(
        () => submissionFormTemplate?.formType ?? productTemplateFormType ?? (declarationId && declarationFormType),
        [declarationId, declarationFormType, submissionFormTemplate?.formType, productTemplateFormType]
    );

    const isCds = country === 'UK' && internalType !== 'NCTS';
    const isAis = country === 'IRELAND' && internalType === 'IMPORT';
    const isAes = country === 'IRELAND' && internalType === 'EXPORT';
    const isArrival = country === 'IRELAND' && internalType === 'ARRIVAL';
    const isNcts = country === 'IRELAND' && internalType === 'NCTS';
    const isUkNcts = country === 'UK' && internalType === 'NCTS';

    const formTypeForCds = `${country}_${internalType}_${formType}`;

    const countryLowerCase = useMemo(() => country.toLowerCase(), [country]);

    return {
        isAes,
        isCds,
        isAis,
        isArrival,
        isNcts,
        isUkNcts,
        formTypeForCds,
        internalType,
        formType,
        externalEntity,
        country,
        declarationFormType,
        countryLowerCase,
    };
};

export default useFormUtils;
