import { WarningOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button';
import Modal from 'components/ui/base/modal/Modal';
import { H5 } from 'components/ui/base/typography';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import useFormUtils from '../../../hooks/useFormUtils';

interface Props {
    visible: boolean;
    onClose: () => void;
}

const WarningSubmitModal: FC<Props> = ({ visible, onClose }) => {
    const navigate = useNavigate();
    const { countryLowerCase: country } = useFormUtils();

    const title = useMemo(
        () => (country === DeclarationCountry.IRELAND ? 'Digital certificates' : 'HMRC access'),
        [country]
    );

    const redirect = useMemo(
        () => (country === DeclarationCountry.IRELAND ? '/settings/digital-certificate' : '/settings/hmrc'),
        [country]
    );

    const content = useMemo(
        () =>
            country === DeclarationCountry.IRELAND
                ? 'You need to upload digital certificates to submit the declaration. Check your personal settings to proceed.'
                : 'You need to configure HMRC access to submit the declaration. Check you personal settings to proceed.',
        [country]
    );

    return (
        <Modal
            title={
                <H5>
                    <WarningOutlined style={{ marginRight: '1.75rem' }} /> {title}
                </H5>
            }
            centered
            visible={visible}
            onOk={() => {}}
            onCancel={onClose}
            width={553}
            cancelText="Go to Declaration"
            footer={[
                <Button key="goToSettings" onClick={() => navigate(redirect)} size="large">
                    Go to Settings
                </Button>,
                <Button key="goToDeclaration" onClick={onClose} size="large">
                    Go to Declaration
                </Button>,
            ]}
            contentText={content}
        />
    );
};

export default WarningSubmitModal;
