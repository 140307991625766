import { DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import { handleCurrency, handleNullValues } from 'components/ui/base/table/TableUtils';
import { FC, useCallback } from 'react';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { CdsGovernmentAgencyGoodsItem } from 'store/declarations/uk/cds-declaration';
import { colors } from 'theme';
import { ActionButton, InvalidIcon } from 'views/declarations/ireland/common/IrelandProductsTable.styles';
import { ListPayload } from '../../../../core/http/response';
import useFormUtils from '../../../../hooks/useFormUtils';
import { TableChangeParams } from '../../../../utils/tableHelpers';
import { ProductsErrors } from '../../sections/products/DeclarationProductsTable';

interface Props {
    data: ListPayload<CdsGovernmentAgencyGoodsItem> | undefined | null;
    onDelete?: (id: string) => void;
    onEdit: (id: string) => void;
    onAddToTemplate?: (item: string) => void;
    viewOnly?: boolean;
    errors?: ProductsErrors;
    onChange: (params: TableChangeParams) => void;
}

const UkProductsTable: FC<Props> = ({ data, onDelete, onEdit, onAddToTemplate, viewOnly, errors, onChange }) => {
    const { internalType } = useFormUtils();
    const checkInvalid = useCallback((index: number) => errors && !!errors[index], [errors]);

    const getInvalidStyle = useCallback(
        (index: number) => {
            return checkInvalid(index) ? { style: { background: `${colors.invalid}`, border: 'none' } } : {};
        },
        [checkInvalid]
    );

    const handleTableValues = useCallback(
        (index: number, value?: string | number) => {
            const style = getInvalidStyle(index);
            return { props: style, children: handleNullValues(value) };
        },
        [getInvalidStyle]
    );

    const handleInvalidIcon = useCallback(
        (index: number) => {
            const style = getInvalidStyle(index);
            return checkInvalid(index)
                ? {
                      props: style,
                      children: <InvalidIcon />,
                  }
                : { props: style, children: <></> };
        },
        [getInvalidStyle, checkInvalid]
    );

    const columns: ColumnsType<CdsGovernmentAgencyGoodsItem> = [
        {
            title: 'Commodity Code',
            dataIndex: 'commodity_code',
            key: 'commodity_code',
            render: (text, record: CdsGovernmentAgencyGoodsItem, index: number) => {
                if (internalType === DeclarationInternalType.EXPORT) {
                    const data = record as any;
                    return handleTableValues(
                        index,
                        data?.governmentAgencyGoodsItem?.commodity?.classification?.[0]?.id
                    );
                } else {
                    const data = record as any;
                    return handleTableValues(index, data?.commodityCombinedNomenclatureCodeIdentifier);
                }
            },
            sorter: (a: any, b: any) => {
                if (internalType === DeclarationInternalType.EXPORT) {
                    return a.baseCommodityCode.localeCompare(b.baseCommodityCode);
                } else {
                    return a.commodityCombinedNomenclatureCodeIdentifier.localeCompare(
                        b.commodityCombinedNomenclatureCodeIdentifier
                    );
                }
            },
        },
        {
            title: 'Description of Goods',
            dataIndex: 'description_of_goods',
            key: 'description_of_goods',
            render: (text, record: CdsGovernmentAgencyGoodsItem, index: number) => {
                if (internalType === DeclarationInternalType.EXPORT) {
                    const data = record as any;
                    return handleTableValues(index, data?.governmentAgencyGoodsItem?.commodity?.description);
                } else {
                    const data = record;
                    return handleTableValues(index, data?.goodsDescription);
                }
            },
            sorter: (a: any, b: any) => a.goodsDescription.localeCompare(b.goodsDescription),
        },
        {
            title: 'Item Price',
            dataIndex: 'item_price',
            key: 'item_price',
            render: (text, record: any, index: number) => {
                const statisticalValueAmount = record?.governmentAgencyGoodsItem?.statisticalValueAmount as any;
                const statisticalValueAmountCurrencyId =
                    record?.governmentAgencyGoodsItem?.statisticalValueAmountCurrencyId;
                return handleTableValues(
                    index,
                    handleCurrency(statisticalValueAmount, statisticalValueAmountCurrencyId)
                );
            },
            sorter: (a: any, b: any) => a.itemPriceAmount - b.itemPriceAmount,
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            render: (text, record: CdsGovernmentAgencyGoodsItem, index: number) => handleTableValues(index, '-'),
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (text, record: CdsGovernmentAgencyGoodsItem, index: number) => ({
                props: getInvalidStyle(index),
                children: (
                    <>
                        {viewOnly ? (
                            <ActionButton
                                invalid={checkInvalid(index)}
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onAddToTemplate && onAddToTemplate(record.id!);
                                }}
                            >
                                Add to templates
                            </ActionButton>
                        ) : (
                            <>
                                <ActionButton
                                    size="small"
                                    invalid={checkInvalid(index)}
                                    marginRight="1.2"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onAddToTemplate && onAddToTemplate(record.id!);
                                    }}
                                >
                                    Add to templates
                                </ActionButton>

                                <ActionButton
                                    size="small"
                                    invalid={checkInvalid(index)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete && onDelete(record.id!);
                                    }}
                                >
                                    Delete <DeleteOutlined />
                                </ActionButton>
                            </>
                        )}
                    </>
                ),
            }),
        },
        {
            dataIndex: 'icon',
            key: 'icon',
            render: (text, record, index: number) => handleInvalidIcon(index),
        },
    ];

    const rowSelection: TableRowSelection<any> = {
        renderCell: (value, record, index: number, originNode: React.ReactNode) => {
            return { props: getInvalidStyle(index), children: originNode };
        },
    };

    return (
        <Table
            rowKey="id"
            dataSource={data?.list}
            columns={columns}
            rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        event.stopPropagation();
                        return onEdit && onEdit(record.id!);
                    },
                };
            }}
            onChange={(pagination, _, sorter) => onChange({ pagination, sorter })}
            pagination={{
                current: (data?.pageNumber || 0) + 1,
                total: data?.total,
                pageSize: data?.pageSize,
                position: ['bottomCenter'],
            }}
        />
    );
};
export default UkProductsTable;
