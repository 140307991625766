import { FC } from 'react';
import { Text } from '../../../../../../components/ui/base/typography';
import { CodeDiv, MessageDiv, ShowDetailsDiv, StyledErrorRow } from '../../DeclarationsNotificationsDrawer.styles';

const AisErrorsList: FC<
    SpecificDeclarationErrorsProps & {
        setSelectedError: any;
    }
> = ({ errors, setSelectedError }) => {
    return (
        <>
            {errors?.map((error: any, i: number) => (
                <StyledErrorRow justify="space-between" key={`error-row-${i}`}>
                    <CodeDiv>
                        <Text>{error?.businessRuleCode ?? '-'}</Text>
                    </CodeDiv>
                    <MessageDiv>
                        <Text>{error?.externalErrorMessage ?? '-'}</Text>
                    </MessageDiv>
                    <ShowDetailsDiv>
                        <span onClick={() => setSelectedError(error)}>Show details</span>
                    </ShowDetailsDiv>
                </StyledErrorRow>
            ))}
        </>
    );
};

export default AisErrorsList;
