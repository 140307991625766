import { TablePaginationConfig, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import ConditionalWrapper from '../../../../../components/ConditionalWrapper';
import Drawer from '../../../../../components/ui/base/drawer/Drawer';
import { MultipleFieldsButton } from '../../../../../components/ui/composed/declarations/MultipleButtons';
import { useRequestPromise } from '../../../../../hooks/useRequest';
import { listDeclarations as listDeclarationsRequest } from '../../../../../store/declarations/client';
import { Declaration } from '../../../../../store/declarations/declaration';
import { DeclarationExternalEntity } from '../../../../../store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from '../../../../../store/declarations/enums/common/declaration-internal-type';
import { IrelandPbnRecord } from '../../../../../store/declarations/ireland/pbn-declaration';
import { getTableChangeParams } from '../../../../../utils/tableHelpers';
import { filterDataColumns } from '../../../../custom-declaration/declaration-table/components/DeclarationDashboardActionRequiredMessage';
import DeclarationTable from '../../../../custom-declaration/declaration-table/DeclarationsTable';
import { SearchOutlined } from '@ant-design/icons';

interface Props {
    onSelectDeclaration: (declarationMrn: Declaration['mrn'], onClose: () => void) => {};
}

const PbnCustomsDeclarationDrawerButton: FC<Props> = ({ onSelectDeclaration }) => {
    const formik = useFormikContext();
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const direction = (formik?.values as IrelandPbnRecord)?.direction;

    return (
        <>
            <ConditionalWrapper
                condition={!direction}
                wrapper={(children) => (
                    <Tooltip title="Choose direction of the movement." placement="topRight">
                        {children}
                    </Tooltip>
                )}
            >
                <div>
                    <MultipleFieldsButton
                        icon={<SearchOutlined />}
                        onClick={() => setDrawerOpen(true)}
                        disabled={!direction}
                    />
                </div>
            </ConditionalWrapper>

            <PbnCustomsDeclarationDrawer
                visible={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onSelectDeclaration={onSelectDeclaration}
                direction={direction}
            />
        </>
    );
};

const PbnCustomsDeclarationDrawer: FC<{ visible: boolean; onClose: () => void; direction: string } & Props> = ({
    visible,
    onClose,
    onSelectDeclaration,
    direction,
}) => {
    const getListDeclarationsParams = () => {
        const params: {
            declarationExternalEntity: DeclarationExternalEntity[];
            declarationInternalType: DeclarationInternalType[];
            hasMrn: boolean;
        } = {
            declarationExternalEntity: [DeclarationExternalEntity.REVENUE],
            declarationInternalType: [],
            hasMrn: true,
        };

        if (direction === 'IN_IRELAND') params.declarationInternalType.push(DeclarationInternalType.IMPORT);
        else if (direction === 'OUT_IRELAND') params.declarationInternalType.push(DeclarationInternalType.EXPORT);
        else return {};

        return params;
    };

    const {
        data: declarations,
        isLoading,
        refetch: listDeclarations,
    } = useRequestPromise((params?: any) => listDeclarationsRequest(params), {
        args: [getListDeclarationsParams()],
        withSignal: true,
    });

    useEffect(() => {
        const params = getListDeclarationsParams();
        if (isEmpty(params)) return;
        listDeclarations(params)?.catch((err) => {
            if (err.message === 'canceled') Promise.resolve();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [direction]);

    const handleTableOnChange = (pagination: TablePaginationConfig) =>
        listDeclarations({ ...getListDeclarationsParams(), ...getTableChangeParams({ pagination }) });

    return (
        <Drawer title="Customs Declarations" visible={visible} onClose={onClose} width={820}>
            <DeclarationTable
                data={!direction ? null : declarations}
                filterDataColumns={filterDataColumns}
                loading={isLoading}
                onRow={(declaration) => onSelectDeclaration(declaration.mrn, onClose)}
                onChange={handleTableOnChange}
            />
        </Drawer>
    );
};

export default PbnCustomsDeclarationDrawerButton;
