import styled from 'styled-components';
import { Layout } from 'antd';
import { colors, device, fonts } from 'theme';

export const StyledSider = styled(Layout.Sider)`
    overflow: auto;
    height: 100vh;
    position: sticky;
    background-color: ${colors.darkPurple} !important;
    .ant-menu {
        background-color: ${colors.darkPurple} !important;
        background: ${colors.darkPurple} !important;

        &.ant-menu-root {
            margin-top: 3.2rem;
            margin-left: 2rem !important;
            margin-right: 2rem !important;

            &.ant-menu-inline {
                // 265px sidebar width, minus 20x2 margins... this could be much better...
                // TODO this also breaks the animation...
                width: 225px;
            }
        }

        .ant-menu-item {
            background: ${colors.darkPurple};
            border-radius: 8px !important;
            padding-right: 0px !important;
        }

        .disabled-link {
            color: ${colors.darkGrey};
            cursor: default !important;
            text-decoration: none;
        }
    }

    svg {
        height: 18px;
        width: 18px;
        vertical-align: inherit;
    }

    .ant-menu-item-selected {
        background-color: #ebebee !important; // add to colors
        a {
            color: ${colors.darkPurple} !important;
            font-weight: ${fonts.weight.bold} !important;
        }
        svg {
            color: ${colors.darkPurple} !important;
            fill: ${colors.darkPurple} !important;
        }
    }

    .ant-menu-item-selected:has(span.disabled-link) {
        background-color: ${colors.darkPurple} !important;
    }

    @media ${device.tablet} {
        display: none;
    }
`;

export const SidebarLogoDiv = styled.div`
    margin-top: 1rem;
`;

export const LogoH1 = styled.h1`
    margin-left: 2.4rem;
    color: ${colors.white};
`;

export const LogoSmall = styled.h1`
    text-align: center;
`;
