import { Row } from 'antd';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { enumToText } from 'core/utils/enum-to-text';
import { Declaration } from 'store/declarations/declaration';
import {
    getDeclarantName,
    getDeclarationCountry,
    getMessageTypeByDeclaration,
    getDeclarationPayload,
} from 'views/declarations/utils/declaration-utils';
import { OptionsCol } from '../SearchBar.style';
import OptionTooltip from './OptionTooltip';

const getDeclarationsOptions = (
    list: Declaration[],
    onSelectDeclaration?: (declaration: Declaration) => [string | undefined, string | undefined]
) => {
    const getDeclarationType = (declaration: Declaration) => {
        const internalType = enumToText(declaration.declarationInternalType);
        const country = getDeclarationCountry(declaration);
        return `${country}/${internalType}`;
    };

    const getDeclarant = (declaration: Declaration) => {
        const payload = getDeclarationPayload(declaration);
        return getDeclarantName(payload as any);
    };

    const renderItem = (declaration: Declaration) => ({
        value: onSelectDeclaration?.(declaration) || `/declarations/${declaration.id}`,
        label: (
            <Row justify="space-between" align="middle" wrap={false} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <OptionsCol>
                    <OptionTooltip
                        data={`${getDeclarationType(declaration)} - ${getMessageTypeByDeclaration(declaration)}`}
                    />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={declaration.mrn} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={declaration.referenceNumber} />
                </OptionsCol>
                <OptionsCol>
                    <DeclarationStatusTag status={declaration.status} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={getDeclarant(declaration)} />
                </OptionsCol>
            </Row>
        ),
    });

    const declarations = () => list.map((c) => renderItem(c));

    const options = [
        {
            label: <span>Declarations</span>,
            options: declarations(),
        },
    ];

    return options;
};
export default getDeclarationsOptions;
