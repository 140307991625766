import useBusinessRules from 'hooks/useBusinessRules';
import { FC, useEffect, useMemo } from 'react';
import { SubmissionErrors } from 'store/declarations/ireland/ireland-notification';
import AisBusinessRuleRow from 'views/documentation/ais-documentation/AisBusinessRuleRow';
import {
    DetailsLabel,
    DetailsLabelDiv,
    DetailsValue,
    DetailsValueDiv,
    StyledErrorRow,
    StyledHr,
    Title,
} from '../../DeclarationsNotificationsDrawer.styles';
import { goToDeclarationField } from 'views/declarations/utils/form-utils';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { useLocation, useNavigate } from 'react-router-dom';
import ListErrorsRows from '../ListErrorsRows';
import { parseDeclaration as irelandImportValidationErrorsParser } from 'views/declarations/ireland/import/h1/validation/utils';
import { useFormikContext } from 'formik';

export const ErrorRow: FC<{ label: string; value?: string | number }> = ({ label, value }) => {
    return (
        <>
            <StyledErrorRow>
                <DetailsLabelDiv>
                    <DetailsLabel>{label}</DetailsLabel>
                </DetailsLabelDiv>
                <DetailsValueDiv>
                    <DetailsValue>{`${value ?? '-'}`}</DetailsValue>
                </DetailsValueDiv>
            </StyledErrorRow>
        </>
    );
};

export const RevenueImportErrorDetails: FC<{
    details: SubmissionErrors | undefined;
    onClose?: () => void;
}> = ({ details, onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const inViewOnly = useMemo(() => location.pathname.includes('view-only'), [location.pathname]);
    const { products } = useProducts();
    const { declaration } = useDeclarations();
    const { aisRules, getIrelandBusinessRules } = useBusinessRules();
    const { setFieldTouched, getFieldHelpers } = useFormikContext();

    useEffect(() => {
        if (!aisRules) {
            getIrelandBusinessRules('AIS');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const businessRule = useMemo(() => {
        const r = aisRules?.find((p) => p.code === details?.businessRuleCode);

        if (!r) return <></>;

        return (
            <>
                <StyledHr></StyledHr>
                <Title>Revenue business rule</Title>
                <AisBusinessRuleRow rule={r} />
                <span>
                    <a
                        href="https://www.revenue.ie/en/online-services/support/software-developers/documents/ais/ais-business-rules-and-conditions.pdf"
                        rel="noreferrer"
                        target="_blank"
                    >
                        AIS Business Rules and Conditions
                    </a>
                </span>
            </>
        );
    }, [details, aisRules]);

    const handleOnClickField = async (field: string) => {
        if (field) {
            if (field.includes('goodsShipment.goodsShipmentItem')) {
                const productNumber = Number(field.split('.')[2]) - 1;
                const product = products?.list.at(productNumber);
                field = field.split('.').slice(3).join('.');

                navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}/products/${product!.id}`);
            } else if (location.pathname.split('/').at(-1) !== declaration?.id) {
                navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}`);
            }
            onClose?.();
            goToDeclarationField(field, 100);
        }

        getFieldHelpers(field).setError(details?.irelandTranslationTable?.message ?? '-');
        setFieldTouched(field, true, false);
    };

    return (
        <>
            <Title>Error details</Title>
            <ErrorRow label="Code:" value={details?.irelandTranslationTable?.code ?? '-'} />
            <ErrorRow label="Value:" value={details?.value ?? '-'} />
            <ListErrorsRows
                section="Fields"
                fields={details?.fields ?? []}
                handleOnClickField={handleOnClickField}
                parseDeclaration={irelandImportValidationErrorsParser}
                itemsPath={'goodsShipment.goodsShipmentItem'}
            />
            <ErrorRow label="Message:" value={details?.irelandTranslationTable?.message ?? '-'} />
            <ErrorRow label="Error Message:" value={details?.message ?? '-'} />
            <ErrorRow label="Remarks:" value={details?.remarks ?? '-'} />
            <ErrorRow label="Date:" value={details?.date ?? '-'} />
            <ErrorRow label="Pattern Required:" value={details?.patternRequired ?? '-'} />
            <ErrorRow label="Error Reason:" value={details?.errorReason ?? '-'} />
            <ErrorRow label="External Field:" value={details?.externalField ?? '-'} />
            <ErrorRow label="External Error Message:" value={details?.externalErrorMessage ?? '-'} />
            <ListErrorsRows
                section="Missing Documents:"
                fields={details?.missingDocuments ?? []}
                parseDeclaration={irelandImportValidationErrorsParser}
                itemsPath={'goodsShipment.goodsShipmentItem'}
            />
            {details?.businessRuleCode ? businessRule : <></>}
        </>
    );
};
