import { useAppDispatch, useAppSelector } from 'config/hooks';
import { PaginatedParams } from 'core/http/pagination';
import { useCallback } from 'react';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { doListArchivedTemplates, doListTemplates } from 'store/template/action';
import { MessageTypes } from '../store/declarations/enums/common/declaration-types';
import { ArchivedTemplatesParams } from '../store/template/client';

const useTemplates = () => {
    const templates = useAppSelector((state) => state.templates.templates);
    const dispatch = useAppDispatch();

    const listTemplates = useCallback(
        (
            country: DeclarationCountry,
            internalType: DeclarationInternalType,
            formType: MessageTypes,
            params?: Partial<PaginatedParams>
        ) => dispatch(doListTemplates(country, internalType.toLowerCase() as any, formType, params) as any),
        [dispatch]
    );

    const listArchivedTemplates = useCallback(
        (params: ArchivedTemplatesParams) => dispatch(doListArchivedTemplates(params) as any),
        [dispatch]
    );

    return {
        templates,
        listTemplates,
        listArchivedTemplates,
    };
};
export default useTemplates;
