import { DeclarationFooterType } from './declaration-footer';

export const doSetDeclarationFooter: Function = (type: DeclarationFooterType) => (dispatch: Function) => {
    dispatch({
        type: 'SET_DECLARATION_FOOTER',
        payload: type,
    });
};

export const doClearDeclarationFooter: Function = () => (dispatch: Function) => {
    dispatch({ type: 'CLEAR_DECLARATION_FOOTER' });
};
