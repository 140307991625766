import Fuse from 'fuse.js';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Individual } from 'store/individuals/individual';
import { DeclarationInternalType } from '../../../../../store/declarations/enums/common/declaration-internal-type';
import { FilterResult, FilterType } from '../filter-result';
import AuthorFilter from './AuthorFilter';
import DateFilter from './DateFilter';
import DeclarationStatusFilter from './DeclarationStatusFilter';
import DeclarationTypeFilter from './DeclarationTypeFilter';
import { FilterCard as Card, FilterHeader, Input } from './Filter.styles';
import FilterOptions from './FilterOptions';

const defaultFilterOptions = (type: DeclarationInternalType | undefined) =>
    type === DeclarationInternalType.PBN || !type
        ? [FilterType.DATE]
        : [FilterType.DATE, FilterType.DECLARATION_STATUS, FilterType.DECLARATION_TYPE, FilterType.AUTHOR];

interface FilterProps {
    filterState: FilterResult[];
    handleClose: Function;
    onFilter: (filter: FilterResult) => void;
    authors: Individual[] | undefined;
}

export const FilterCard: FC<FilterProps> = ({ filterState, handleClose, onFilter, authors }) => {
    const { type } = useParams<{ type: DeclarationInternalType }>();
    const isGvms = type === DeclarationInternalType.GVMS;

    const [selectedFilter, setSelectedFilter] = useState<string>('');
    const [filterOptions, setFilterOptions] = useState<string[]>(defaultFilterOptions(type));

    const cardRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const closeDropdown = (e: any) => {
            if (cardRef.current && !cardRef.current.contains(e.target)) handleClose();
        };

        window.addEventListener('mousedown', closeDropdown);
        return () => window.removeEventListener('mousedown', closeDropdown);
    }, [handleClose]);

    const handleSearchInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setSelectedFilter(value);
            if (value) {
                const options = { includeMatches: true };
                const fuse = new Fuse(filterOptions, options);
                const result = fuse.search(value);
                const filter = result.map((obj: any) => obj.matches[0].value);
                setFilterOptions(filter);
            } else {
                setFilterOptions(defaultFilterOptions(type));
            }
        },
        [filterOptions, setSelectedFilter, setFilterOptions, type]
    );

    const getFilterValue = useCallback(
        (filterType: FilterType) => filterState.find((filter) => filter?.type === filterType)?.value as any,
        [filterState]
    );

    const handleView = useMemo(() => {
        const handleSelect = (option: string) => {
            setSelectedFilter(option);
        };

        const handleDate = (dates: string[]) => {
            onFilter({ type: FilterType.DATE, value: dates });
        };

        const handleStatus = (status: string[]) => {
            onFilter({ type: FilterType.DECLARATION_STATUS, value: status });
        };

        const handleType = (type: string[]) => {
            onFilter({ type: FilterType.DECLARATION_TYPE, value: type });
        };

        if (!selectedFilter) {
            return <FilterOptions options={filterOptions} onSelect={handleSelect} />;
        } else {
            switch (selectedFilter) {
                case FilterType.DATE:
                    return <DateFilter selectedDate={handleDate} />;
                case FilterType.DECLARATION_STATUS:
                    return (
                        <DeclarationStatusFilter
                            selectedStatus={handleStatus}
                            value={getFilterValue(FilterType.DECLARATION_STATUS)}
                        />
                    );
                case FilterType.DECLARATION_TYPE:
                    return (
                        <DeclarationTypeFilter
                            selectedType={handleType}
                            value={getFilterValue(FilterType.DECLARATION_TYPE)}
                        />
                    );
                case FilterType.AUTHOR:
                    return (
                        <AuthorFilter
                            authors={authors}
                            onSelect={(value) => onFilter({ type: FilterType.AUTHOR, value })}
                            value={getFilterValue(FilterType.AUTHOR)}
                            cardRef={cardRef}
                        />
                    );
                default:
                    <></>;
            }
        }
    }, [selectedFilter, onFilter, filterOptions, getFilterValue, authors]);

    return (
        <Card ref={cardRef as any} data-testid={'filter-card'}>
            <FilterHeader>
                <Input
                    autoFocus
                    placeholder="Filter by ..."
                    onChange={handleSearchInputChange}
                    value={isGvms ? selectedFilter.replace('Declaration', 'Record') : selectedFilter}
                    data-testid={'filter-card-search'}
                />
            </FilterHeader>
            {handleView}
        </Card>
    );
};

export default FilterCard;
