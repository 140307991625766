import FilesPage from 'views/files/FilesPage';
import { Route } from '../routes';

const filesRoutes: Route[] = [
    {
        path: 'shared-files',
        name: 'Shared Files',
        element: <FilesPage />,
    },
];

export default filesRoutes;
