import { notification, Tabs, Modal, Button, Divider } from 'antd';
import { ARadio } from 'components/ui/base/radio/Radio';
import { RadioGroup } from 'components/ui/composed/declarations/customerEoriAddressRadioButton/CustomerEoriAddressRadioButton.styles';
import { FC, FocusEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Customer } from 'store/customers/customer';
import { fillCustomerFromListOfCustomers, getFormikProps } from 'views/declarations/utils/form-utils';
import { DeclarationFormCardProps } from '../declaration.form.card';
import PartiesAddressForm from './PartiesAddressForm';
import PartiesEoriForm from './PartiesEoriForm';
import PartiesEoriFormProps from './PartiesEoriFormProps';
import PartiesAddressFormProps from './PartiesAddressFormProps';
import NewFormCard, { FormCardContainer } from '../cards/NewFormCard';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import addPathPrefix from 'utils/addPathPrefix';
import useDeclarations from 'hooks/useDeclarations';
import { useDeclarationContext } from 'utils/DeclarationContext';
import { capitalize, get, isEmpty, set } from 'lodash';
import { useProductTemplateContext } from 'utils/ProductTemplateContext';
import AutoFillModal, { Field } from '../../ireland/import/h1/components/AutoFillModal';
import { FormikProps, FormikProvider, useFormikContext } from 'formik';
import { deleteLevelValues, levelHasValues } from './partiesLevelsValidationUtils';
import CardSection from '../cards/CardSection';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { CustomerLevelValidationData } from '../../ireland/import/h1/invoice/InvoiceModal';
import useFormUtils from '../../../../hooks/useFormUtils';
import { deepObjHasTruthyNonObjValue } from '../../../../core/utils/objects';
import { IrelandExportDeclaration } from '../../../../store/declarations/ireland/export-declaration';
import DeclarationPhoneNumber from 'components/ui/composed/formPhoneNumber/DeclarationPhoneNumber';
import useGetErrors from 'hooks/useGetErrors';
import { TabDiv } from 'views/declarations/Form.styles';
import { getCardErrors, getTabsWithErrors } from 'views/declarations/utils/validation-utils';

const toTabKey = (tab: string) => {
    return `${tab.toLowerCase()}-tab-key`;
};
const toHeader = (tabKey: string | undefined) => {
    return capitalize(tabKey?.split('-')[0]);
};

export const addToItemOfUcc = (ucc: string | undefined, amount: number) => {
    let uccGroup, uccItem;
    let parts: string[] = [];
    let delimiter;

    if (ucc === undefined) return undefined;

    if (ucc.includes('/')) {
        delimiter = '/';
    } else if (ucc.includes('.')) {
        delimiter = '.';
    } else {
        return "Can't parse";
    }

    parts = ucc.split(delimiter);

    uccGroup = parts[0];
    uccItem = parts[1];

    return `${uccGroup}${delimiter}${Number(uccItem) + amount}`;
};

export interface Party {
    path: string;
    header: string;
    refNumber: string | undefined;
    eoriRequired?: boolean;
    hasPhoneNumber?: boolean;
    hasRepresentativeStatus?: boolean;
    contactPerson?: {
        present?: boolean;
        hidden?: boolean;
    };
    eoriAutofill?: Field[];
    hidden?: boolean;
    hasAddress?: boolean;
    disabled?: boolean;
}

export interface Address {
    name: string;
    streetAndNumber: string;
    country: string;
    postCode: string;
    city: string;
}
export interface ContactPerson {
    name: string;
    phoneNumber: string;
    email: string;
}

export interface AdditionalPaths {
    representativeStatus?: string;
    phoneNumber?: string;
    phoneNumberCode?: string;
}

export interface Paths {
    eori?: string;
    eoriByPath?: (path: string) => string;
    address?: Address;
    contactPerson?: ContactPerson;
    additional?: AdditionalPaths;
}

enum ToggleState {
    EORI,
    ADDRESS,
}

export interface SpecificPartiesCardProps {
    parties: Party[];
    paths: Paths;
    labels?: Partial<Paths>;
    PartiesEoriForm?: FC<PartiesEoriFormProps>;
    PartiesAddressForm?: FC<PartiesAddressFormProps>;
    condensed?: boolean;
}

export interface PartiesCardProps extends SpecificPartiesCardProps, DeclarationFormCardProps {}

const PartiesCard = ({
    parties,
    paths,
    labels,
    PartiesAddressForm: PartiesAddressFormProp,
    PartiesEoriForm: PartiesEoriFormProp,
    condensed,
    formik: declarationFormik,
    ...formCardProps
}: PartiesCardProps): ReactElement | null => {
    const [toggle, setToggle] = useState<ToggleState>(ToggleState.EORI);
    const [isChoosePartyLevelModalOpen, setChoosePartyLevelModalOpen] = useState<boolean>(false);
    const { template, templateFormik, form: templateForm, isViewOnly } = useTemplateContext();
    const { declarationTemplate: productTemplateDeclarationTemplate } = useProductTemplateContext();
    const { declarationTemplate } = useDeclarations();
    const { form } = useDeclarationContext();
    const declarationFormikContext: FormikProps<any> = useFormikContext();
    const { isAes } = useFormUtils();

    const unhiddenParties = useMemo(() => parties?.filter((party) => !party.hidden), [parties]);

    const templateData = useMemo(
        () => productTemplateDeclarationTemplate?.template ?? declarationTemplate?.template,
        [productTemplateDeclarationTemplate, declarationTemplate?.template]
    );

    const EoriForm = useMemo(() => PartiesEoriFormProp ?? PartiesEoriForm, [PartiesEoriFormProp]);
    const AddressForm = useMemo(() => PartiesAddressFormProp ?? PartiesAddressForm, [PartiesAddressFormProp]);

    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(undefined);

    // Sets the toggle based on whether eori or address section is available for the party
    const handleToggleForParty = (partyHeader: string) => {
        if (visibleEoriAddressPerParty[partyHeader]) {
            if (toggle === ToggleState.ADDRESS && !visibleEoriAddressPerParty[partyHeader]?.address) {
                setToggle(ToggleState.EORI);
            } else if (toggle === ToggleState.EORI && !visibleEoriAddressPerParty[partyHeader]?.eori) {
                setToggle(ToggleState.ADDRESS);
            }
        }
    };

    const changeParty = (activeKey: string) => {
        const partyHeader = toHeader(activeKey);
        handleToggleForParty(partyHeader);
        setActiveTabKey(activeKey);
    };

    const getPartyFieldsMeta = (partyPath: Party['path']) => {
        const _templateData = template ? templateFormik?.values : templateData;
        const _form = template || productTemplateDeclarationTemplate ? templateForm : form;
        if (!_templateData || !_form) return null;

        const meta = _templateData?.[_form]?.meta ?? {};
        const partyMeta = Object.entries(meta)?.filter(([key]) => new RegExp(`^${partyPath}`, 'g').exec(key)?.length);
        return Object.fromEntries(partyMeta);
    };

    /**
     * Construct eori and address visibility per party from template meta configuration
     */
    const visibleEoriAddressPerParty = useMemo(
        () =>
            unhiddenParties?.reduce((acc, party) => {
                const partyFieldsMeta = getPartyFieldsMeta(party.path);
                if (template || !partyFieldsMeta || !Object.keys(partyFieldsMeta)?.length) {
                    acc[party.header] = { eori: true, address: party.hasAddress ?? true };
                    return acc;
                }

                const eoriMeta = Object.entries(partyFieldsMeta).find(([key]) =>
                    key.includes((paths.eori || paths.eoriByPath?.(party.path)) as string)
                );
                const eoriVisible = !eoriMeta || !!eoriMeta?.[1].isEditable || !!eoriMeta?.[1].isViewable;
                const addressMeta = Object.entries(partyFieldsMeta)?.filter(([key]) =>
                    Object.values({ ...paths.additional, ...paths.address }).some((path) => key.includes(path))
                );
                const addressVisible =
                    !addressMeta.length ||
                    addressMeta.some(([_, value]) => !value || value.isEditable || value.isViewable);

                acc[party.header] = { eori: eoriVisible, address: party.hasAddress ?? addressVisible };

                return acc;
            }, {} as Record<string, { eori: boolean; address: boolean } | undefined>),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [form, templateData]
    );

    /**
     * Filter the visible parties based on the template meta configuration
     */
    const visibleParties = useMemo(
        () =>
            unhiddenParties?.filter((party) => {
                const partyVisibleEoriAddress = visibleEoriAddressPerParty[party.header];
                return !partyVisibleEoriAddress || partyVisibleEoriAddress.eori || partyVisibleEoriAddress.address;
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [form, templateData, unhiddenParties]
    );

    /**
     * Set the first visible party as the active tab key
     */
    useEffect(() => {
        if (!visibleParties[0]) return;
        const partyHeader = visibleParties[0].header;
        setActiveTabKey(toTabKey(visibleParties[0].header));
        handleToggleForParty(partyHeader);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);

    const customerWithAutoFill = useMemo(
        () =>
            unhiddenParties?.reduce((customers: string[], party) => {
                if (party.eoriAutofill) customers.push(party.header);
                return customers;
            }, []),
        [unhiddenParties]
    );

    const openAutoFillModal = useCallback(
        (value: any | null) => {
            const activeTabPartyHasAutoFill = customerWithAutoFill.some(
                (customer) => customer === toHeader(activeTabKey!)
            );

            if (!activeTabPartyHasAutoFill || prevValue === value) return;

            setOpen(true);
            setValue(value);
        },
        [activeTabKey, customerWithAutoFill, prevValue]
    );
    const closeAutoFillModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => set(acc, field, value), values);
        formik?.setValues(newValues);
    };

    const formik = useMemo(() => {
        if (template && templateFormik) return templateFormik;
        return declarationFormik ?? declarationFormikContext;
    }, [declarationFormik, template, templateFormik, declarationFormikContext]);

    const autoFillFields = unhiddenParties?.reduce((fields: Field[], party) => {
        if (party.eoriAutofill) {
            party.eoriAutofill.forEach((autoFill) => fields.push(autoFill));
        }

        return fields;
    }, []);

    const activeTabPartyPath = useMemo(() => {
        const activeTabParty = unhiddenParties.find((party) => party.header === toHeader(activeTabKey!));

        return template ? addPathPrefix(`${templateForm}.defaults`, activeTabParty?.path) : activeTabParty?.path;
    }, [activeTabKey, unhiddenParties, template, templateForm]);

    const addressLevelFieldPaths = useMemo(
        () => ({
            ...paths.address,
            phoneNumber: paths.additional?.phoneNumber,
            phoneNumberCode: paths.additional?.phoneNumberCode,
        }),
        [paths.additional?.phoneNumber, paths.additional?.phoneNumberCode, paths.address]
    );
    const eoriLevelFieldPaths = useMemo(
        () => ({
            eori: paths.eori || paths.eoriByPath?.(activeTabKey!),
            representativeStatus: paths.additional?.representativeStatus,
        }),
        [activeTabKey, paths]
    );

    const getAllValuesOfCurrentParty = useCallback(
        (blurredField: { name?: string; value?: string }) => {
            if (!activeTabPartyPath) return;
            const allLevelValues = get(formik.values, activeTabPartyPath);

            if (!blurredField.name) return;
            const newValue = { [blurredField?.name?.replace(activeTabPartyPath + '.', '')]: blurredField?.value };

            return { ...allLevelValues, ...newValue };
        },
        [activeTabPartyPath, formik.values]
    );

    const checkIfBothLevelsHaveValues = useCallback(
        (blurredField: { name?: string; value?: string }) =>
            !template &&
            levelHasValues(addressLevelFieldPaths, getAllValuesOfCurrentParty(blurredField)) &&
            levelHasValues(eoriLevelFieldPaths, getAllValuesOfCurrentParty(blurredField)) &&
            setChoosePartyLevelModalOpen(true),
        [addressLevelFieldPaths, eoriLevelFieldPaths, getAllValuesOfCurrentParty, template]
    );

    useEffect(() => {
        const closeChoosePartyLevelModal = (e: KeyboardEvent) =>
            e.key === 'Escape' && setChoosePartyLevelModalOpen(false);

        window.addEventListener('keyup', closeChoosePartyLevelModal);

        return () => window.removeEventListener('keyup', closeChoosePartyLevelModal);
    }, [setChoosePartyLevelModalOpen]);

    const handleEoriBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            openAutoFillModal(e.target.value);
            checkIfBothLevelsHaveValues({ name: e.target.name, value: e.target.value });
        },
        [checkIfBothLevelsHaveValues, openAutoFillModal]
    );
    const handleAddressBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            checkIfBothLevelsHaveValues({ name: e.target.name, value: e.target.value });
        },
        [checkIfBothLevelsHaveValues]
    );

    const [isInvoiceChoosePartyLevelModalOpen, setIsInvoiceChoosePartyLevelModalOpen] = useState<boolean>(false);
    const [invoiceCustomersLevelValidation, setInvoiceCustomersLevelValidation] = useState<
        CustomerLevelValidationData[] | undefined
    >(undefined);

    const disableSavedCustomer = (
        arr: CustomerLevelValidationData[] | undefined,
        customerPath: string
    ): CustomerLevelValidationData[] | undefined =>
        arr?.map((obj) => {
            if (obj.customerPath === customerPath) return { ...obj, disabled: true };
            else return obj;
        });

    const handleDisableCustomersLevelValidation = useCallback(
        (customerPath: string) => {
            const beforehandState = disableSavedCustomer(invoiceCustomersLevelValidation, customerPath);
            if (beforehandState?.every((customer) => customer.disabled)) setIsInvoiceChoosePartyLevelModalOpen(false);

            setInvoiceCustomersLevelValidation((state) => (state ? disableSavedCustomer(state, customerPath) : state));
        },
        [invoiceCustomersLevelValidation]
    );

    useEffect(() => {
        const invoiceModalSavedSubscriberId = window.EventBus.subscribe(
            'invoiceModalSaved',
            (data: { customerLevelValidation: CustomerLevelValidationData[] }) =>
                data.customerLevelValidation.forEach((savedCustomer) => {
                    if (
                        levelHasValues(savedCustomer.addressLevelPaths, savedCustomer.allCustomerValues) &&
                        levelHasValues(savedCustomer.eoriLevelPaths, savedCustomer.allCustomerValues)
                    ) {
                        setInvoiceCustomersLevelValidation((state) => {
                            const customer = { ...savedCustomer, disabled: false };
                            return state ? [...state, customer] : [customer];
                        });
                        setIsInvoiceChoosePartyLevelModalOpen(true);
                    }
                })
        );
        return () => window.EventBus.unsubscribe(invoiceModalSavedSubscriberId);
    }, []);

    const handleChangeForm = useCallback((e: any) => {
        setToggle(e.target.value);
    }, []);

    const contactPersonRequired = useMemo(() => {
        if (!isAes) return false;

        return deepObjHasTruthyNonObjValue(
            (declarationFormikContext?.values as IrelandExportDeclaration)?.representative?.contactPerson
        );
    }, [isAes, declarationFormikContext.values]);

    const handleSelectCustomer = (customer: Customer, onClose: () => void) => {
        if (!activeTabPartyPath) return;

        const { filledAddress, filledEori } = fillCustomerFromListOfCustomers(
            customer,
            template ? addPathPrefix(`${templateForm}.defaults`, activeTabPartyPath) : activeTabPartyPath,
            template ? templateFormik?.getFieldHelpers : formCardProps.getFieldHelpers,
            { eori: paths.eori || paths.eoriByPath?.(activeTabKey!), ...paths.address },
            { customerMeta: getPartyFieldsMeta(activeTabPartyPath), template },
            toggle === ToggleState.EORI ? 'eori' : 'address'
        );

        onClose();

        if (filledEori) {
            setToggle(ToggleState.EORI);
            openAutoFillModal(customer.eori);
        } else if (filledAddress) setToggle(ToggleState.ADDRESS);
        else
            notification.warning({
                message: 'Could not fill party',
                description: 'Fields are hidden or no customer data to fill',
            });
    };

    //logic for handle errors for parties

    const { errors } = useGetErrors();

    const partiesErrors = getCardErrors(errors, 'Parties');
    const erorrsNames = getTabsWithErrors(partiesErrors);

    const addTabErrorClass = (party: Party) => {
        return erorrsNames.has(party.header) ? 'tab-error' : '';
    };

    const tabItems = visibleParties?.map((party) => ({
        label: <TabDiv className={addTabErrorClass(party)}>{party.header}</TabDiv>,
        key: toTabKey(party.header),
        children: (
            <>
                {!template && (
                    <RadioGroup value={toggle} onChange={handleChangeForm}>
                        {visibleEoriAddressPerParty[party.header]?.eori && (
                            <ARadio value={ToggleState.EORI}>Eori number</ARadio>
                        )}
                        {visibleEoriAddressPerParty[party.header]?.address && (
                            <ARadio value={ToggleState.ADDRESS}>Customer Address</ARadio>
                        )}
                    </RadioGroup>
                )}
                {(toggle === 0 || template) && (
                    <EoriForm
                        refNumber={party.refNumber}
                        eoriRequired={party.eoriRequired}
                        eoriLabel={labels?.eori}
                        paths={paths}
                        party={party}
                        condensed={condensed}
                        onBlur={handleEoriBlur}
                        disabled={party.disabled}
                        {...formCardProps}
                    />
                )}
                {template && (
                    <span
                        style={{
                            display: 'block',
                            marginBottom: isViewOnly ? '1rem' : '0.5rem',
                        }}
                    />
                )}
                {(toggle === 1 || (template && visibleEoriAddressPerParty[party.header]?.address)) && (
                    <AddressForm
                        refNumber={party.refNumber}
                        paths={paths}
                        party={party}
                        condensed={condensed}
                        disabled={party.disabled}
                        onBlur={handleAddressBlur}
                        {...formCardProps}
                    />
                )}
                {party.contactPerson?.present && !party.contactPerson.hidden && (
                    <FormCardContainer>
                        <CardSection title="Contact Person">
                            {paths.contactPerson?.name && (
                                <DeclarationInput
                                    label="Name"
                                    required={contactPersonRequired}
                                    {...getFormikProps(addPathPrefix(party.path, paths.contactPerson?.name), formik)}
                                    disabled={party.disabled}
                                    condensed
                                />
                            )}
                            {paths.contactPerson?.phoneNumber && (
                                <DeclarationPhoneNumber
                                    required={contactPersonRequired}
                                    label="Phone Number"
                                    {...getFormikProps(
                                        addPathPrefix(party.path, paths.contactPerson?.phoneNumber),
                                        formik
                                    )}
                                    disabled={party.disabled}
                                    condensed
                                />
                            )}
                            {paths.contactPerson?.email && (
                                <DeclarationInput
                                    label="Email"
                                    {...getFormikProps(addPathPrefix(party.path, paths.contactPerson?.email), formik)}
                                    disabled={party.disabled}
                                    condensed
                                />
                            )}
                        </CardSection>
                    </FormCardContainer>
                )}
            </>
        ),
    }));

    if (isEmpty(unhiddenParties)) return null;

    return (
        <>
            {formik && (
                <FormikProvider value={formik}>
                    <AutoFillModal
                        visible={open}
                        value={value}
                        prevValue={prevValue}
                        fields={autoFillFields}
                        onCancel={closeAutoFillModal}
                        onOk={autoFill}
                    />
                </FormikProvider>
            )}

            <NewFormCard
                title="Parties"
                special={{ partyPath: activeTabPartyPath }}
                onSelectCustomer={handleSelectCustomer}
            >
                <Tabs onChange={changeParty} activeKey={activeTabKey} items={tabItems}></Tabs>
            </NewFormCard>

            <Modal
                title={null}
                closable={false}
                visible={isChoosePartyLevelModalOpen}
                footer={
                    <>
                        <Button type="primary" onClick={() => setChoosePartyLevelModalOpen(false)}>
                            Ignore
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                deleteLevelValues(activeTabPartyPath, addressLevelFieldPaths, formik);
                                setChoosePartyLevelModalOpen(false);
                            }}
                        >
                            EORI
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                deleteLevelValues(activeTabPartyPath, eoriLevelFieldPaths, formik);
                                setChoosePartyLevelModalOpen(false);
                            }}
                        >
                            Address
                        </Button>
                    </>
                }
            >
                <div>
                    {toHeader(activeTabKey!)} has information on both levels - EORI and Address. Please choose only one
                    level.
                </div>
            </Modal>
            <Modal
                title={'Customers have information on both levels - EORI and Address. Please choose only one.'}
                visible={isInvoiceChoosePartyLevelModalOpen}
                footer={null}
                maskClosable={false}
                onCancel={() => setIsInvoiceChoosePartyLevelModalOpen(false)}
            >
                <div style={{ padding: 20 }}>
                    {invoiceCustomersLevelValidation?.map((savedCustomer, i) => (
                        <>
                            <div
                                key={`${savedCustomer.customerHeader}${i}`}
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '35% repeat(2, 100px)',
                                    gap: '10px',
                                }}
                            >
                                {savedCustomer.customerHeader}
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        deleteLevelValues(
                                            savedCustomer.customerPath,
                                            savedCustomer.addressLevelPaths,
                                            formik
                                        );
                                        handleDisableCustomersLevelValidation(savedCustomer.customerPath);
                                    }}
                                    disabled={savedCustomer.disabled}
                                >
                                    EORI
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        deleteLevelValues(
                                            savedCustomer.customerPath,
                                            savedCustomer.eoriLevelPaths,
                                            formik
                                        );
                                        handleDisableCustomersLevelValidation(savedCustomer.customerPath);
                                    }}
                                    disabled={savedCustomer.disabled}
                                >
                                    Address
                                </Button>
                            </div>
                            <Divider />
                        </>
                    ))}
                </div>
            </Modal>
        </>
    );
};

export default PartiesCard;
