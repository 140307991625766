import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Declaration } from '../../../../../../store/declarations/declaration';
import { DeclarationStatus } from '../../../../../../store/declarations/enums/common/declaration-status';
import { IrelandImportDeclaration } from '../../../../../../store/declarations/ireland/import-declaration';
import InvoiceFormHeader from './InvoiceFormHeader';
import InvoiceModal from './InvoiceModal';

const InvoiceModalButton = ({
    declaration,
    saveAsDraft,
    formikValues,
}: {
    declaration: Declaration | undefined;
    saveAsDraft?: Function;
    formikValues: IrelandImportDeclaration;
}) => {
    const location = useLocation();
    const comingFromCreateDecPage = (location.state as { comingFromCreateDecPage: boolean })?.comingFromCreateDecPage;
    const [showInvoiceModal, setShowInvoiceModal] = useState<boolean | undefined>(comingFromCreateDecPage);

    useEffect(() => {
        if (declaration?.status !== DeclarationStatus.DRAFT) setShowInvoiceModal(false);
    }, [declaration?.status]);

    useEffect(() => {
        window.history.replaceState({}, document.title);
        return () => setShowInvoiceModal(false);
    }, []);

    const declarationHasInvoice = useMemo(
        () => declaration?.uploadedFiles?.some((uploadedFile) => uploadedFile.invoice),
        [declaration]
    );

    return (
        <>
            {declarationHasInvoice && <InvoiceFormHeader disabled={!(showInvoiceModal && declarationHasInvoice)} />}

            {showInvoiceModal && declarationHasInvoice && (
                <InvoiceModal formikValues={formikValues} declarationId={declaration?.id} saveAsDraft={saveAsDraft} />
            )}
        </>
    );
};

export default InvoiceModalButton;
