import { InputProps as AntInputProps } from 'antd';
import { TestProps } from 'core/utils/testTypes';
import { ChangeEventHandler, forwardRef } from 'react';
import { StyledInput } from './Input.styles';

export interface InputProps extends AntInputProps, TestProps {
    addonAfter?: React.ReactNode;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    allowClear?: boolean;
    bordered?: boolean;
    placeholder?: string;
    id?: string;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    disabled?: boolean;
    style?: React.CSSProperties;
    maxLength?: number;
}

const AInput = forwardRef((props: InputProps, ref) =>
    props.type === 'password' ? (
        <StyledInput.Password {...props} ref={ref as any}></StyledInput.Password>
    ) : (
        <StyledInput {...props} ref={ref as any}></StyledInput>
    )
);
export default AInput;
