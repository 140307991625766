import { get } from 'lodash';
import { ParsedCard, ParsedItem } from '../../../../../store/declaration-form-errors/ParsedForm';
import { parseFieldErrorsForCard, parseFieldErrorsForMultipleCard } from '../../../../../utils/validationParserUtils';

const irelandEtdValidationErrorsParser = (validation: any) => {
    const masterDetailsErrors: ParsedCard[] = [];

    parseFieldErrorsForCard(
        'Customs Offices',
        [
            { 'declaration.transitDeclaration13': 'Transit declaration/Proof of customs status type' },
            { 'declaration.customsOffices.customsOfficeLodgement': 'Customs office of lodgement' },
            {
                'declaration.customsOffices.customsOfficeOfDestination56': 'Customs office of destination',
            },
            { numberOfItems: 'Number of Items' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Goods Information',
        [
            { 'declaration.goodsInformation.grossMass65': 'Gross mass' },
            { 'declaration.goodsInformation.totalPackageNumber618': 'Total packages' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Parties',
        [
            {
                'declaration.parties.holderOfTheTransitProcedure.holderOfTheTransitProcedure323':
                    'Identification Number',
            },
            { 'declaration.parties.representative.representative320': 'Identification Number' },
            { 'goodsShipment.parties.consignee.consignee310': 'Identification Number' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Simplified Declaration / Previous Documents / Writing-off',
        'goodsShipment.documentsAuthorisations.simplifiedDeclarationDocumentWritingOff201',
        [
            { previousDocumentType: 'Previous Document Type' },
            { previousDocumentIdentifier: 'Previous Document Reference' },
            { previousDocumentLineId: 'Declaration Line Number Previously Recorded' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'goodsShipment.documentsAuthorisations.additionalInformation22',
        [
            { additionalInformationCode: 'Additional Statement Code' },
            { additionalInformationText: 'Additional Statement Text' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Documents Produced / Certificates and Authorisations / Additional References',
        'goodsShipment.documentsAuthorisations.producedDocumentsWritingOff203',
        [{ documentType: 'Type' }, { documentIdentifier: 'Id' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Reference Number',
        [{ 'goodsShipment.documentsAuthorisations.ucr24': 'UCR' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Supply Chain Actor',
        'goodsShipment.supplyChainActor337',
        [{ roleCode: 'Role Code' }, { traderIdentification: 'Identifier' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Dates and Places',
        [
            {
                'goodsShipment.datesPlaces.countryDestination58': 'Country of destination code',
            },
            { 'goodsShipment.datesPlaces.transportationPlace.unlocode': 'UN/LOCODE' },
            { 'goodsShipment.datesPlaces.transportationPlace.countryCode': 'Country code' },
            { 'goodsShipment.datesPlaces.transportationPlace.place': 'Location' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Transport Information',
        [
            {
                'goodsShipment.transportInformation.departureTransportMeans77.identificationType':
                    'Type of identification',
            },
            {
                'goodsShipment.transportInformation.departureTransportMeans77.identificationNumber':
                    'Identification number',
            },
            {
                'goodsShipment.transportInformation.nationality78': 'Nationality',
            },
            {
                'goodsShipment.transportInformation.containerIdentificationNumber710.0.containerIdentificationNumber':
                    'Container Identification Number 1',
            },
            {
                'goodsShipment.transportInformation.containerIdentificationNumber710.1.containerIdentificationNumber':
                    'Container Identification Number 2',
            },
            {
                'goodsShipment.transportInformation.containerIdentificationNumber710.2.containerIdentificationNumber':
                    'Container Identification Number 3',
            },
            {
                'goodsShipment.transportInformation.containerIdentificationNumber710.3.containerIdentificationNumber':
                    'Container Identification Number 4',
            },
            {
                'goodsShipment.transportInformation.containerIdentificationNumber710.4.containerIdentificationNumber':
                    'Container Identification Number 5',
            },
            {
                'goodsShipment.transportInformation.seal.sealNumber718': 'Number of Seals',
            },
            {
                'goodsShipment.transportInformation.seal.sealIdentifier718.[0].sealIdentifier': 'Seal Identifier 1',
            },
            {
                'goodsShipment.transportInformation.seal.sealIdentifier718.[1].sealIdentifier': 'Seal Identifier 2',
            },
            {
                'goodsShipment.transportInformation.seal.sealIdentifier718.[2].sealIdentifier': 'Seal Identifier 3',
            },
            {
                'goodsShipment.transportInformation.seal.sealIdentifier718.[3].sealIdentifier': 'Seal Identifier 4',
            },
            {
                'goodsShipment.transportInformation.seal.sealIdentifier718.[4].sealIdentifier': 'Seal Identifier 5',
            },
        ],
        masterDetailsErrors,
        validation
    );

    const itemsErrors: ParsedItem[] = [];
    parseDeclarationItems(itemsErrors, validation);

    return {
        masterDetails: masterDetailsErrors,
        items: itemsErrors,
    };
};

const parseDeclarationItems = (errors: ParsedItem[], validation: any) => {
    const products = get(validation, 'goodsShipment.goodsShipmentItem');

    if (!Array.isArray(products)) return;

    products?.forEach((itemErrors: any, index: number) => {
        const item = { index: index + 1, errors: [] };

        parseDeclarationItem(item.errors, itemErrors);

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

const parseDeclarationItem = (parsed: ParsedCard[], validation: any) => {
    parseFieldErrorsForCard(
        'Goods Information',
        [
            {
                'goodsInformation.cusCode613': 'CUS Code',
            },
            {
                'goodsInformation.commodityCode.combinedNomenclatureCode614':
                    'Commodity Code - Combined Nomenclature Code',
            },
            { transitDeclaration13: 'Transit declaration/Proof of customs status type' },
            { 'goodsInformation.goodsDescription68': 'Description of Goods' },
            { 'goodsInformation.grossMass65': 'Gross Mass' },
        ],
        parsed,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Simplified Declaration / Previous Documents / Writing-off',
        'documentsAuthorisations.simplifiedDeclarationDocumentWritingOff201',
        [
            { previousDocumentType: 'Previous Document Type' },
            { previousDocumentIdentifier: 'Previous Document Reference' },
            { previousDocumentLineId: 'Declaration Line Number Previously Recorded' },
        ],
        parsed,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'documentsAuthorisations.additionalInformation22',
        [
            { additionalInformationCode: 'Additional Statement Code' },
            { additionalInformationText: 'Additional Statement Text' },
        ],
        parsed,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Documents Produced / Certificates and Authorisations / Additional References',
        'documentsAuthorisations.producedDocumentsWritingOff203',
        [{ documentType: 'documentType' }, { documentIdentifier: 'documentIdentifier' }],
        parsed,
        validation
    );

    parseFieldErrorsForCard('Reference Number', [{ 'documentsAuthorisations.ucr24': 'UCR' }], parsed, validation);

    parseFieldErrorsForCard(
        'Parties',
        [{ 'parties.consignee.consignee310': 'Identification Number' }],
        parsed,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Supply Chain Actor',
        'parties.supplyChainActor337',
        [{ roleCode: 'Role Code' }, { traderIdentification: 'Identifier' }],
        parsed,
        validation
    );

    parseFieldErrorsForCard(
        'Dates and Places',
        [{ 'datesPlaces.countryDestination58': 'Country of destination code' }],
        parsed,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Packaging',
        'goodsInformation.packaging',
        [
            { typePackage69: 'Type of Packages' },
            { packageNumber610: 'Number of Packages' },
            { shippingMarks611: 'Shipping Marks' },
        ],
        parsed,
        validation
    );

    parseFieldErrorsForCard(
        'Transport Information',
        [
            {
                'transportInformation.departureTransportMeans77.identificationType': 'Type of identification',
            },
            {
                'transportInformation.departureTransportMeans77.identificationNumber': 'Identification number',
            },
            {
                'transportInformation.nationality78': 'Nationality',
            },
            {
                'transportInformation.containerIdentificationNumber710.0.containerIdentificationNumber':
                    'Container Identification Number 1',
            },
            {
                'transportInformation.containerIdentificationNumber710.1.containerIdentificationNumber':
                    'Container Identification Number 2',
            },
            {
                'transportInformation.containerIdentificationNumber710.2.containerIdentificationNumber':
                    'Container Identification Number 3',
            },
            {
                'transportInformation.containerIdentificationNumber710.3.containerIdentificationNumber':
                    'Container Identification Number 4',
            },
            {
                'transportInformation.containerIdentificationNumber710.4.containerIdentificationNumber':
                    'Container Identification Number 5',
            },
        ],
        parsed,
        validation
    );
};

export default irelandEtdValidationErrorsParser;
