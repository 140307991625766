import { ListPayload } from 'core/http/response';
import { FC, useState } from 'react';
import { sortBy } from 'lodash';
import { Declaration } from '../../../../../store/declarations/declaration';
import UkGvmsRecordUtils from '../utils';
import { ExpiryRow } from '../../../../custom-declaration/declaration-table/GvmsColumns';
import { colors } from '../../../../../theme';
import styled from 'styled-components';
import moment from 'moment';
import { GmrActionRequiredNotification } from '../../../../../store/declarations/uk/gvms-declaration';
import { BackToList, Title } from '../../../common/declaration-errors-drawer/DeclarationsNotificationsDrawer.styles';
import { ErrorRow } from '../../../common/declaration-errors-drawer/declaration-errors/ais/RevenueImportErrorDetails';
import { Divider } from 'antd';
import { ErrorButton } from '../../../common/declaration-errors-drawer/DeclarationNotificationsDrawerButton';

const Container = styled.div`
    display: grid;
    grid-template-columns: 45% 35% 20%;
    margin-bottom: 1.5rem;
`;

interface Props {
    declarationsList: ListPayload<Declaration>['list'] | undefined;
}

const GvmsActionRequiredDrawerContent: FC<Props> = ({ declarationsList }) => {
    const [selectedError, setSelectedError] = useState<GmrActionRequiredNotification | undefined>(undefined);

    if (!selectedError)
        return (
            <>
                {sortBy(declarationsList, 'gvmsDeclaration.gmrExpiredAt').map((declaration) => (
                    <Container>
                        <div style={{ color: '#ffb426' }}>
                            {UkGvmsRecordUtils.addSpacesToGmrId(declaration?.gvmsDeclaration?.gmrId)}:{'   '}
                            <span style={{ color: 'black' }}>Inspection Required</span>
                        </div>

                        <div style={{ display: 'flex', gap: 5 }}>
                            <span>Expiry:</span>
                            <ExpiryRow expiryDate={declaration?.gvmsDeclaration?.gmrExpiredAt} />
                            <span>on {moment(declaration?.gvmsDeclaration?.gmrExpiredAt).format('DD/MM/YYYY')}</span>
                        </div>

                        <ErrorButton
                            color={colors.blueLink}
                            onClick={() =>
                                setSelectedError(
                                    declaration?.gvmsDeclaration?.notifications?.find(
                                        (notification) =>
                                            (notification as GmrActionRequiredNotification)?.inspectionRequired
                                    ) as GmrActionRequiredNotification | undefined
                                )
                            }
                        >
                            View Details
                        </ErrorButton>
                    </Container>
                ))}
            </>
        );
    else
        return (
            <>
                <BackToList onClick={() => setSelectedError(undefined)}>Back to list</BackToList>
                <Title>Rule error details</Title>
                {selectedError?.ruleFailures.map((rule, i) => (
                    <div key={`${rule.code}_${i}`}>
                        <ErrorRow label="Code:" value={rule.code ?? '-'} />
                        <ErrorRow label="Technical Message:" value={rule.technicalMessage ?? '-'} />
                        <ErrorRow label="Field:" value={rule.field ?? '-'} />
                        <ErrorRow label="Value:" value={rule.value ?? '-'} />
                        <Divider />
                    </div>
                ))}
            </>
        );
};

export default GvmsActionRequiredDrawerContent;
