import { PhoneOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import Avatar from 'components/ui/base/avatar/Avatar';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { TextExtraSmallTable } from 'components/ui/base/typography';
import { ListPayload } from 'core/http/response';
import { FC, useCallback } from 'react';
import { Customer, CustomerSortParameter } from 'store/customers/customer';
import CustomerDetails from 'views/customers/components/CustomerDetails';
import useCustomers from '../../../../hooks/useCustomers';
import { getTableChangeParams } from '../../../../utils/tableHelpers';
import { useCustomerDetailsReducer } from './CustomerDetailsReducer';

interface Props {
    data?: ListPayload<Customer & { jobs: number }>;
    loading: boolean;
    handleSelect?: (customer: Customer) => void;
}

const CustomersTable: FC<Props> = ({ data, loading, handleSelect }) => {
    const { listCustomers } = useCustomers();
    const columns: ColumnsType<any> = [
        {
            title: 'Company Name',
            dataIndex: CustomerSortParameter.NAME,
            key: CustomerSortParameter.NAME,
            align: 'left',
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.name ? (
                    <TextExtraSmallTable>
                        <Avatar name={record.name}></Avatar>
                        {record.name}
                    </TextExtraSmallTable>
                ) : (
                    <TextExtraSmallTable>-</TextExtraSmallTable>
                ),
            sorter: true,
        },
        {
            title: 'EORI',
            dataIndex: CustomerSortParameter.EORI,
            key: CustomerSortParameter.EORI,
            align: 'left',
            sorter: true,
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.eori ? <TextExtraSmallTable>{record.eori}</TextExtraSmallTable> : <span>-</span>,
        },
        {
            title: 'Email',
            dataIndex: CustomerSortParameter.EMAIL,
            key: CustomerSortParameter.EMAIL,
            align: 'left',
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.contact?.email ? (
                    <TextExtraSmallTable>{record.contact.email}</TextExtraSmallTable>
                ) : (
                    <TextExtraSmallTable>-</TextExtraSmallTable>
                ),
            sorter: true,
        },
        {
            title: 'Phone Number',
            dataIndex: CustomerSortParameter.PHONE,
            key: CustomerSortParameter.PHONE,
            align: 'left',
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.contact?.phoneNumber ? (
                    <TextExtraSmallTable>
                        <i style={{ marginRight: '0.9rem' }}>
                            <PhoneOutlined />
                        </i>
                        {record.contact.phoneNumber}
                    </TextExtraSmallTable>
                ) : (
                    <TextExtraSmallTable>-</TextExtraSmallTable>
                ),
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            align: 'center',
            render: (text: string, record: Customer & { jobs: number }, index: number) => {
                return (
                    <>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSelect?.(record);
                            }}
                        >
                            Assign Customer
                        </Button>
                    </>
                );
            },
        },
    ];

    const [state, dispatch] = useCustomerDetailsReducer();

    const handleRowClick = useCallback(
        (record: Customer) => {
            dispatch({ type: 'open', customer: record });
        },
        [dispatch]
    );

    return (
        <>
            <Table
                rowKey="id"
                style={{ marginTop: '2.5rem' }}
                columns={columns}
                dataSource={data?.list}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            handleRowClick?.(record);
                        },
                    };
                }}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                }}
                onChange={(pagination, _, sorter) => listCustomers(getTableChangeParams({ pagination, sorter }))}
                loading={loading}
            />
            <Drawer
                title="Customers Details"
                width="627"
                visible={state.open}
                onClose={() => dispatch({ type: 'close' })}
            >
                <CustomerDetails customer={state.customer ?? undefined} />
            </Drawer>
        </>
    );
};
export default CustomersTable;
