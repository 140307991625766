import { TemplateResponse } from 'components/ui/composed/template/TemplateContext';
import config from 'config';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import { DeclarationHistory, NotifyEmails } from 'store/declarations/declaration';
import { parseFileName } from 'utils/download-utils';
import axiosClient from '../../config/axios';
import { Declaration, DeclarationDocuments, DeclarationDocumentsFilterFields } from './declaration';
import {
    ElectronicTransportDocument,
    ElectronicTransportDocumentPayload,
} from './ireland/electronic-transport-document';
import { IrelandEntrySummaryDeclarationPayload } from './ireland/entry-summary-declaration';
import { IrelandExportDeclarationPayload } from './ireland/export-declaration';
import { IrelandH7ImportDeclarationPayload } from './ireland/h7-import-declaration';
import { IrelandImportDeclarationPayload } from './ireland/import-declaration';
import { IrelandTemporaryStorageDeclarationPayload } from './ireland/temporary-storage-declaration';
import { CdsDeclarationPayload } from './uk/cds-declaration';
import { AesAmendmentData, AmendmentSummaryData, SubmitAmendmentRequestBody } from './common/amendment-summary-data';
import { ArrivalAtExitDeclarationPayload } from './ireland/arrival-at-exit-declaration';
import { NctsDeclaration } from './ireland/ncts-declaration';

export type MRNLookup = {
    declarationStatus: string;
    controlStatus: string;
    mrn: string;
    dateOfEnquiry: string;
    timeOfEnquiry: string;
    dateOfLastUpdate: string;
    timeOfLastUpdate: string;
};

// TODO change any
export const listDeclarations = (params?: any): Promise<ListPayload<Declaration>> => {
    const { signal, ...restParams } = params ?? {};
    return axiosClient
        .get<ListResponse<Declaration>>(`${config.declarationsUrl}/declarations`, {
            params: restParams,
            signal,
        })
        .then((response) => response.data.payload);
};

export const deleteDeclaration = (declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(`${config.declarationsUrl}/declarations/${declarationId}`)
        .then((response) => {
            return response.data;
        });

export const listArchivedDeclarations = (params?: any): Promise<ListPayload<Declaration>> => {
    const { signal, ...restParams } = params ?? {};
    return axiosClient
        .get<ListResponse<Declaration>>(`${config.declarationsUrl}/declarations/archive`, {
            params: restParams,
            signal,
        })
        .then((response) => response.data.payload);
};

export const getDeclaration = (declarationId: string): Promise<Declaration> => {
    return axiosClient
        .get<SuccessResponse<Declaration>>(`${config.declarationsUrl}/declarations/${declarationId}`)
        .then((response) => response.data.payload);
};

export const getTemplate = (templateId: string): Promise<TemplateResponse> => {
    return axiosClient
        .get<SuccessResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}/${templateId}`)
        .then((response) => response.data.payload);
};

export const createIrelandImportDeclaration = (
    customerId: string,
    declaration: IrelandImportDeclarationPayload,
    templateId?: string
): Promise<IrelandImportDeclarationPayload> => {
    return axiosClient
        .post<SuccessResponse<IrelandImportDeclarationPayload>>(
            `${config.declarationsUrl}/ireland/import/declarations`,
            { ...declaration, customerId, templateId },
            { params: { messageType: declaration.irelandImportDeclaration?.messageType } }
        )
        .then((response) => response.data.payload);
};

export const createIrelandH1ImportDeclarationWithFile = (
    file: File,
    jobId: string,
    customerId: string
): Promise<IrelandImportDeclarationPayload> =>
    axiosClient
        .post<SuccessResponse<IrelandImportDeclarationPayload>>(
            `${config.declarationsUrl}/ireland/import/declarations/bulkImportWithProducts`,
            file,
            {
                params: {
                    filename: file.name,
                    jobId,
                    customerId,
                },
                headers: { 'content-type': file.type },
            }
        )
        .then((response) => response.data.payload);

export const downloadIrelandH1ImportDeclarationSubmissionDetails = () =>
    axiosClient
        .get(`${config.declarationsUrl}/ireland/import/declarations/downloadImportWithProductsTemplate`)
        .then((response) => response.data.payload);

export const createIrelandH7ImportDeclaration = (
    customerId: string,
    declaration: IrelandH7ImportDeclarationPayload,
    templateId?: string
): Promise<IrelandH7ImportDeclarationPayload> => {
    return axiosClient
        .post<SuccessResponse<IrelandH7ImportDeclarationPayload>>(
            `${config.declarationsUrl}/ireland/import/declarations/h7`,
            {
                ...declaration,
                customerId,
                templateId,
            }
        )
        .then((response) => response.data.payload);
};

export const createIrelandExportDeclaration = (
    customerId: string,
    declaration: IrelandExportDeclarationPayload,
    templateId?: string
): Promise<IrelandExportDeclarationPayload> => {
    return axiosClient
        .post<SuccessResponse<IrelandExportDeclarationPayload>>(`${config.declarationsUrl}/ie/export/declarations`, {
            ...declaration,
            templateId,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const createCdsDeclaration = (
    customerId: string,
    declaration: CdsDeclarationPayload,
    templateId?: string
): Promise<CdsDeclarationPayload> => {
    return axiosClient
        .post<SuccessResponse<CdsDeclarationPayload>>(
            `${config.declarationsUrl}/uk/declarations`,
            { ...declaration, customerId, templateId },
            { params: { messageType: declaration.cdsDeclaration?.messageType } }
        )
        .then((response) => response.data.payload);
};

export const createGvmsRecord = (customerId: string, gvmsRecord: any): Promise<any> => {
    return axiosClient
        .post<SuccessResponse<any>>(`${config.declarationsUrl}/uk/gvms/declarations`, {
            ...gvmsRecord,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const createPbnRecord = (customerId: string, pbnRecord: any): Promise<any> => {
    return axiosClient
        .post<SuccessResponse<any>>(`${config.declarationsUrl}/ie/pbn`, {
            ...pbnRecord,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const updateIrelandImportDeclaration = (
    customerId: string,
    declarationId: string,
    declaration: IrelandImportDeclarationPayload,
    calculateGrossMass?: boolean
): Promise<IrelandImportDeclarationPayload> => {
    return axiosClient
        .put<SuccessResponse<IrelandImportDeclarationPayload>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}`,
            {
                ...declaration,
                customerId,
            },
            {
                params: { calculateGrossMass },
            }
        )
        .then((response) => response.data.payload);
};

export const updateIrelandH7ImportDeclaration = (
    customerId: string,
    declarationId: string,
    declaration: IrelandH7ImportDeclarationPayload,
    calculateGrossMass?: boolean
): Promise<IrelandH7ImportDeclarationPayload> => {
    return axiosClient
        .put<SuccessResponse<IrelandH7ImportDeclarationPayload>>(
            `${config.declarationsUrl}/ireland/import/declarations/h7/${declarationId}`,
            {
                ...declaration,
                customerId,
            },
            {
                params: { calculateGrossMass },
            }
        )
        .then((response) => response.data.payload);
};

export const updateIrelandExportDeclaration = (
    customerId: string,
    declarationId: string,
    declaration: IrelandExportDeclarationPayload
): Promise<IrelandExportDeclarationPayload> => {
    return axiosClient
        .put<SuccessResponse<IrelandExportDeclarationPayload>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}`,
            {
                ...declaration,
                customerId,
            }
        )
        .then((response) => response.data.payload);
};

export const updateCdsDeclaration = (
    customerId: string,
    declarationId: string,
    declaration: CdsDeclarationPayload
): Promise<CdsDeclarationPayload> => {
    return axiosClient
        .put<SuccessResponse<CdsDeclarationPayload>>(`${config.declarationsUrl}/uk/declarations/${declarationId}`, {
            ...declaration,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const updateGvmsRecord = (customerId: string, declarationId: string, gvmsRecord: any): Promise<any> => {
    return axiosClient
        .put<SuccessResponse<any>>(`${config.declarationsUrl}/uk/gvms/declarations/${declarationId}`, {
            ...gvmsRecord,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const updatePbnRecord = (customerId: string, declarationId: string, pbnRecord: any): Promise<any> => {
    return axiosClient
        .put<SuccessResponse<any>>(`${config.declarationsUrl}/ie/pbn/${declarationId}`, {
            ...pbnRecord,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const submitDeclaration = (
    declarationId: string,
    emails: NotifyEmails,
    applySuggestedGrossMass?: boolean
): Promise<Declaration> => {
    let url = `${config.declarationsUrl}/declarations/${declarationId}/submit`;
    if (applySuggestedGrossMass) {
        url += '?calculateGrossMass=true';
    }
    return axiosClient
        .post<SuccessResponse<Declaration>>(url, {}, { params: { ...emails } })
        .then((response) => response.data.payload);
};

export const archiveDeclaration = (declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.declarationsUrl}/declarations/archive/${declarationId}`)
        .then((response) => response.data);
export const unarchiveDeclaration = (declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.declarationsUrl}/declarations/unarchive/${declarationId}`)
        .then((response) => response.data);

export const getDeclarationDocuments = (
    params: DeclarationDocumentsFilterFields
): Promise<ListPayload<DeclarationDocuments>> => {
    return axiosClient
        .get<ListResponse<DeclarationDocuments>>(`${config.cmsUrl}/documents`, { params })
        .then((response) => response.data.payload);
};

export const toggleCoreTemplateStatus = (declarationId: string): Promise<Declaration> => {
    return axiosClient
        .put<SuccessResponse<Declaration>>(`${config.declarationsUrl}/declarations/${declarationId}/template/toggle`)
        .then((response) => response.data.payload);
};

export const createIrelandTSDDeclaration = (
    customerId: string,
    declaration: IrelandTemporaryStorageDeclarationPayload
): Promise<IrelandTemporaryStorageDeclarationPayload> => {
    return axiosClient
        .post<SuccessResponse<IrelandTemporaryStorageDeclarationPayload>>(
            `${config.declarationsUrl}/ireland/import/temporary-storage`,
            {
                ...declaration,
                customerId,
            }
        )
        .then((response) => response.data.payload);
};

export const createIrelandNctsDeclaration = (
    customerId: string,
    declaration: NctsDeclaration
): Promise<NctsDeclaration> => {
    return axiosClient
        .post<SuccessResponse<NctsDeclaration>>(`${config.declarationsUrl}/ie/ncts/declarations`, {
            ...declaration,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const createUkNctsDeclaration = (customerId: string, declaration: NctsDeclaration): Promise<NctsDeclaration> => {
    return axiosClient
        .post<SuccessResponse<NctsDeclaration>>(`${config.declarationsUrl}/uk/ncts/declarations`, {
            ...declaration,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const updateIrelandTSDDeclaration = (
    customerId: string,
    declarationId: string,
    declaration: IrelandTemporaryStorageDeclarationPayload
) => {
    return axiosClient
        .put<SuccessResponse<IrelandTemporaryStorageDeclarationPayload>>(
            `${config.declarationsUrl}/ireland/import/temporary-storage/${declarationId}`,
            {
                ...declaration,
                customerId,
            }
        )
        .then((response) => response.data.payload);
};

export const updateNctsDeclaration = (customerId: string, declarationId: string, declaration: NctsDeclaration) => {
    return axiosClient
        .put<SuccessResponse<NctsDeclaration>>(`${config.declarationsUrl}/ie/ncts/declarations/${declarationId}`, {
            ...declaration,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const updateUkNctsDeclaration = (customerId: string, declarationId: string, declaration: NctsDeclaration) => {
    return axiosClient
        .put<SuccessResponse<NctsDeclaration>>(`${config.declarationsUrl}/uk/ncts/declarations/${declarationId}`, {
            ...declaration,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const createIrelandENSDeclaration = (
    customerId: string,
    declaration: IrelandEntrySummaryDeclarationPayload,
    templateId?: string
): Promise<IrelandEntrySummaryDeclarationPayload> => {
    return axiosClient
        .post<SuccessResponse<IrelandEntrySummaryDeclarationPayload>>(`${config.declarationsUrl}/ireland/ens`, {
            ...declaration,
            templateId,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const updateIrelandENSDeclaration = (
    declarationId: string,
    declaration: IrelandEntrySummaryDeclarationPayload
) => {
    return axiosClient
        .put<SuccessResponse<IrelandEntrySummaryDeclarationPayload>>(
            `${config.declarationsUrl}/ireland/ens/${declarationId}`,
            {
                ...declaration,
            }
        )
        .then((response) => response.data.payload);
};

export const generateIrelandSadDraft = (declarationId: string): Promise<string> => {
    return axiosClient
        .put<SuccessResponse<string>>(`declarations-service/declarations/${declarationId}/generate/sad/draft`)
        .then((response) => response.data.payload);
};

export const generateIrelandClearanceDraft = (declarationId: string): Promise<string> => {
    return axiosClient
        .put<SuccessResponse<string>>(
            `declarations-service/declarations/${declarationId}/generate/clearance-slip/draft`
        )
        .then((response) => response.data.payload);
};

export const getIrelandSadDraft = (
    declarationId: string,
    pdfType?: string
): Promise<{ blob: Blob; fileName?: string; type?: string }> => {
    const type = pdfType ? pdfType : 'FINAL';
    return axiosClient({
        method: 'get',
        url: `${config.declarationsUrl}/declarations/${declarationId}/export/sad?pdfType=${type}`,
        responseType: 'arraybuffer',
    }).then((response) => {
        const file = parseFileName(response);
        return {
            blob: new Blob([response.data], { type: 'application/pdf' }),
            fileName: file?.fileName,
            type: file?.type,
        };
    });
};

export const getIrelandClearanceDraft = (
    declarationId: string,
    pdfType?: string
): Promise<{ blob: Blob; fileName?: string; type?: string }> => {
    const type = pdfType ? pdfType : 'FINAL';
    return axiosClient({
        method: 'get',
        url: `${config.declarationsUrl}/declarations/${declarationId}/export/clearance-slip?pdfType=${type}`,
        responseType: 'arraybuffer',
    }).then((response) => {
        const file = parseFileName(response);
        return {
            blob: new Blob([response.data], { type: 'application/pdf' }),
            fileName: file?.fileName,
            type: file?.type,
        };
    });
};
export const duplicateDeclaration = (
    declarationId: string,
    customerId: string,
    jobId: string
): Promise<Declaration> => {
    return axiosClient
        .post<SuccessResponse<Declaration>>(`${config.declarationsUrl}/declarations/${declarationId}/duplicate`, {
            jobId,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const invalidateDeclaration = (declarationId: string, reason: string): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/invalidate`,
            {
                reason,
            }
        )
        .then((response) => response.data.payload);
};

export const invalidateNctsDeclaration = (
    declarationId: string,
    decision: boolean,
    justification: string,
    initiatedByCustoms: boolean
): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/ie/ncts/declarations/${declarationId}/invalidate`,
            {
                decision,
                justification,
                initiatedByCustoms,
            }
        )
        .then((response) => response.data.payload);
};

export const invalidateUkNctsDeclaration = (
    declarationId: string,
    decision: boolean,
    justification: string,
    initiatedByCustoms: boolean
): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/uk/ncts/declarations/${declarationId}/invalidate`,
            {
                decision,
                justification,
                initiatedByCustoms,
            }
        )
        .then((response) => response.data.payload);
};

export const invalidateExportDeclaration = (declarationId: string, reason: string): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/invalidate`,
            {
                reason,
            }
        )
        .then((response) => response.data.payload);
};

export const cancelCdsDeclaration = (declarationId: string, reason: string): Promise<Declaration> => {
    return axiosClient
        .post<SuccessResponse<Declaration>>(`${config.declarationsUrl}/uk/declarations/${declarationId}/cancellation`, {
            reason,
        })
        .then((response) => response.data.payload);
};

export const submitImportPresentationNotification = (
    declarationId: string,
    type?: string,
    body?: {}
): Promise<Declaration> => {
    let url = `/ireland/import/declarations/${declarationId}/presentation`;

    if (type) {
        url = `/ireland/import/declarations/${type}/${declarationId}/presentation`;
    }

    return axiosClient
        .post<SuccessResponse<Declaration>>(`${config.declarationsUrl}${url}`, body)
        .then((response) => response.data.payload);
};

// ! This handles both H1-H6 and H7.
export const submitIrelandImportDocuments = (
    declarationId: string,
    documentIds: string[]
): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/documents`,
            { documentIds }
        )
        .then((response) => response.data.payload);
};

export const amendIrelandImportDeclaration = (
    declarationId: string,
    detailsAmended: string
): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/amend`,
            { detailsAmended }
        )
        .then((response) => response.data.payload);
};

export const amendIrelandExportDeclaration = (declarationId: string): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/amend`
        )
        .then((response) => response.data.payload);
};

export const amendGmrRecord = (declarationId: string): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/uk/gvms/declarations/${declarationId}/amend`
        )
        .then((response) => response.data.payload);
};

export const cancelAmendmentIrelandExportDeclaration = (declarationId: string) =>
    axiosClient
        .post<Promise<Declaration>>(`${config.declarationsUrl}/ie/export/declarations/${declarationId}/cancelAmendment`)
        .then((response) => response.data);

export const cancelAmendmentGmrRecord = (declarationId: string) =>
    axiosClient
        .post<Promise<Declaration>>(`${config.declarationsUrl}/uk/gvms/declarations/${declarationId}/cancelAmendment`)
        .then((response) => response.data);

export const amendIrelandEntrySummaryDeclaration = (
    declarationId: string,
    detailsAmended: string
): Promise<DeclarationHistory> => {
    return axiosClient
        .post<SuccessResponse<DeclarationHistory>>(
            `${config.declarationsUrl}/ireland/ens/declarations/${declarationId}/amend`,
            { detailsAmended }
        )
        .then((response) => response.data.payload);
};

export const getDeclarationHistory = (declarationId: string): Promise<DeclarationHistory[]> => {
    return axiosClient
        .get<SuccessResponse<DeclarationHistory[]>>(`${config.declarationsUrl}/declarations/${declarationId}/history`)
        .then((response) => response.data.payload);
};

export const createIrelandEtdDeclaration = (
    customerId: string,
    declaration: ElectronicTransportDocument
): Promise<IrelandEntrySummaryDeclarationPayload> => {
    return axiosClient
        .post<SuccessResponse<IrelandEntrySummaryDeclarationPayload>>(`${config.declarationsUrl}/ie/import/etd`, {
            ...declaration,
            customerId,
        })
        .then((response) => response.data.payload);
};

export const updateIrelandEtdDeclaration = (
    customerId: string,
    declarationId: string,
    declaration: ElectronicTransportDocumentPayload
): Promise<IrelandEntrySummaryDeclarationPayload> => {
    return axiosClient
        .put<SuccessResponse<IrelandEntrySummaryDeclarationPayload>>(
            `${config.declarationsUrl}/ie/import/etd/${declarationId}`,
            {
                ...declaration,
                customerId,
            }
        )
        .then((response) => response.data.payload);
};

export const createIrelandArrivalAtExitDeclaration = (
    customerId: string,
    declaration: ArrivalAtExitDeclarationPayload
): Promise<ArrivalAtExitDeclarationPayload> => {
    return axiosClient
        .post<SuccessResponse<ArrivalAtExitDeclarationPayload>>(
            `${config.declarationsUrl}/ie/arrival-exit/declarations/${declaration.id}`,
            {
                ...declaration,
                customerId,
            }
        )
        .then((response) => response.data.payload);
};

export const updateIrelandArrivalAtExitDeclaration = (
    customerId: string,
    declarationId: string,
    declaration: ArrivalAtExitDeclarationPayload
): Promise<ArrivalAtExitDeclarationPayload> => {
    return axiosClient
        .put<SuccessResponse<ArrivalAtExitDeclarationPayload>>(
            `${config.declarationsUrl}/ie/arrival-exit/declarations/${declarationId}`,
            {
                ...declaration,
                customerId,
            }
        )
        .then((response) => response.data.payload);
};

export const prepareCdsAmendment = async (declarationId: string): Promise<AmendmentSummaryData> => {
    const response = await axiosClient.get<SuccessResponse<AmendmentSummaryData>>(
        `${config.declarationsUrl}/uk/declarations/${declarationId}/prepareAmendment`
    );
    return response.data.payload;
};

export const submitCdsAmendmentRequest = async (
    declarationId: string,
    body: SubmitAmendmentRequestBody
): Promise<AmendmentSummaryData> => {
    const response = await axiosClient.put<SuccessResponse<AmendmentSummaryData>>(
        `${config.declarationsUrl}/uk/declarations/${declarationId}/submitAmendmentRequest`,
        { ...body }
    );
    return response.data.payload;
};

export const prepareAesAmendment = async (declarationId: string) => {
    const response = await axiosClient.get<AesAmendmentData>(
        `${config.declarationsUrl}/ie/export/declarations/${declarationId}/prepareAmendment`
    );
    return response.data;
};

export const prepareGmrAmendment = async (declarationId: string) => {
    const response = await axiosClient.get<AesAmendmentData>(
        `${config.declarationsUrl}/uk/gvms/declarations/${declarationId}/prepareAmendment`
    );
    return response.data;
};

export const generateDucr = async (declarationId: string) => {
    const response = await axiosClient.get<SuccessResponse<string>>(
        `${config.declarationsUrl}/uk/declarations/${declarationId}/generateDucr`
    );
    return response.data.payload;
};

export const lookupIEMRN = async (declarationId: string) => {
    const response = await axiosClient.get<SuccessResponse<MRNLookup>>(
        `${config.declarationsUrl}/ie/mrn/lookup/${declarationId}`
    );
    return response.data.payload;
};
