import { message } from 'antd';
import { curry, set } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SModal } from '../../../../../../components/ui/composed/template/TemplateModal';
import { ParsedForm } from '../../../../../../store/declaration-form-errors/ParsedForm';
import InvoiceBody from './InvoiceBody';
import InvoiceHeader from './InvoiceHeader';
import axiosClient from 'config/axios';
import { SuccessResponse } from '../../../../../../core/http/response';
import config from '../../../../../../config';
import { IrelandImportDeclaration } from '../../../../../../store/declarations/ireland/import-declaration';
import useGlobalOverlay from '../../../../../../hooks/useGlobalOverlay';
import useGetDeclarationMapValues from '../../../../../../hooks/useGetDeclarationMapValues';
import {
    removeEmptyObjects,
    removeEmptyObjectsFromDeclarationArrays,
    trimWhiteSpaces,
} from '../../../../utils/form-utils';
import useProducts, { CreateProductFunction, UpdateProductFunction } from '../../../../../../hooks/useProducts';
import IrelandImportDeclarationUtils, { makeTransformation } from '../../utils';
import { h1Box44KeyNames, h1PathBox44 } from '../../../../common/box44/box-44-utils';
import { getInvoiceConfig, SUGGESTED_TARICS_NUMERATION_REGEX } from './invoice-utils';
import {
    getDeclarationItems,
    getDeclarationPayload,
    getDeclarationPayloadItems,
} from 'views/declarations/utils/declaration-utils';
import useInvoiceReducer from './reducer/useInvoiceReducer';
import useHideEntities from 'hooks/useHideEntities';
import { complexSet } from 'utils/complexGetSet';
import h1Validation from '../validation/ValidationSchema';

const REFRESH_DELAY_TIME = 10000;

export enum TaricEvalStatus {
    BASIC_INFO_RECEIVED = 'BASIC_INFO_RECEIVED',
    TARIC_SUGGESTIONS_RECEIVED = 'TARIC_SUGGESTIONS_RECEIVED',
    TEE_UPDATED_WITH_SUBMITTED_DATA = 'TEE_UPDATED_WITH_SUBMITTED_DATA',
    FAILED = 'FAILED',
    ACCEPTED = 'ACCEPTED',
}

interface Props {
    formikValues?: any;
    declarationId?: string;
    saveAsDraft?: Function;
}

type TaricEvaluationResponse = { irelandImportDeclaration: IrelandImportDeclaration; taricEvalStatus: string };

export type CustomerLevelValidationData = {
    eoriLevelPaths: Record<string, any>;
    addressLevelPaths: Record<string, any>;
    allCustomerValues: Record<string, any>;
    customerHeader: string;
    customerPath: string;
    disabled: boolean;
};

const EORI_LEVEL_FIELD_PATHS = ['eori', 'identificationNumber', 'id', 'statusCode', 'functionCode'];

export type LMap = Map<string, string | number | boolean | string[] | undefined>;

// TEE | TARIC Evaluation Engine
const InvoiceModal = ({ formikValues, declarationId, saveAsDraft }: Props) => {
    const { isFormType } = useHideEntities();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [activeForm, setActiveForm] = useState<'master' | 'product'>('master');
    const [parsedInvoiceResponse, setParsedInvoiceResponse] = useState<ParsedForm | undefined>(undefined);
    const [selectedSuggestedValuesMap, setSelectedSuggestedValuesMap] = useState<LMap>(new Map());
    const [selectedCustomers, setSelectedCustomers] = useState<Record<string, string[]>>({});
    const [selectedProductsActions, setSelectedProductsActions] = useState<Record<string, string | undefined>>({});
    const [errors, setErrors] = useState<any>(null);

    const [TEE, dispatch] = useInvoiceReducer();

    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { ...products } = useProducts();
    const { parseForm, updateProductFuncName, createProductFuncName, transformData, declarationType, itemPath } =
        useGetDeclarationMapValues();

    const invoiceConfig = useMemo(() => {
        const invoiceConfig = getInvoiceConfig(isFormType);
        return invoiceConfig[declarationType as keyof typeof invoiceConfig];
    }, [declarationType, isFormType]);

    const handleMirroredProductsSubmit = useCallback(
        async (item: any) => {
            let form = removeEmptyObjectsFromDeclarationArrays(
                trimWhiteSpaces({ ...item }, { emptyStringToNull: true })
            );

            if (!(declarationId && updateProductFuncName && createProductFuncName)) return;

            // if (transformData?.product?.forServer) {
            //     form = transformData.product.forServer(form);
            // }

            if (item.id)
                await (products[updateProductFuncName] as UpdateProductFunction)(
                    removeEmptyObjects(form),
                    declarationId,
                    item.id
                );
            else await (products[createProductFuncName] as CreateProductFunction)(form, declarationId);
        },
        [declarationId, createProductFuncName, updateProductFuncName, products, transformData]
    );

    const getInvoiceData = useCallback(() => {
        if (!declarationId) return;

        axiosClient
            .get<SuccessResponse<TaricEvaluationResponse>>(
                `${config.declarationsUrl}/declarations/${declarationId}/teeInvoiceData`
            )
            .then((response) => {
                dispatch({ type: 'UPDATE_STATUS', status: response.data.payload.taricEvalStatus as TaricEvalStatus });

                const items = getDeclarationItems(response.data.payload as any);
                let declarationPayload = getDeclarationPayload(response.data.payload as any);

                if (!declarationPayload) return;

                const newDeclarationPayload = makeTransformation(
                    declarationPayload,
                    curry(IrelandImportDeclarationUtils.box44Transform)(
                        IrelandImportDeclarationUtils.box44ToObject,
                        h1PathBox44,
                        h1Box44KeyNames
                    )
                );

                dispatch({ type: 'SET_DECLARATION', payload: newDeclarationPayload, items });
            })
            .catch(() => {
                message.error(
                    'Error generating the invoice! Please create a new declaration and attach an invoice to it.',
                    5
                );
            });
    }, [declarationId, dispatch]);

    useEffect(() => {
        if (
            TEE.status === TaricEvalStatus.TARIC_SUGGESTIONS_RECEIVED ||
            TEE.status === TaricEvalStatus.TEE_UPDATED_WITH_SUBMITTED_DATA ||
            TEE.status === TaricEvalStatus.FAILED
        )
            return;

        const interval = setInterval(() => {
            getInvoiceData();
        }, REFRESH_DELAY_TIME);

        return () => clearInterval(interval);
    }, [getInvoiceData, TEE.status]);

    useEffect(() => {
        (async () => {
            if (!TEE.payload) return;

            TEE.payload.validationRequired = false;
            // TEE.payload.irelandImportDeclaration.goodsShipment.goodsShipmentItem.countryOrigin = 'Poland';
            let errors: any;
            if (declarationType === 'irelandImportDeclaration') {
                await h1Validation()
                    .validate(TEE.payload, { abortEarly: false })
                    .catch((err) => {
                        errors = err;
                    });
            }

            const defaultParse = parseForm?.(TEE.payload, {
                withoutCustomers: true,
            }) as ParsedForm;

            const invoiceParse = invoiceConfig.parser(defaultParse, TEE.payload);

            setParsedInvoiceResponse(invoiceParse);
            setErrors(errors);
            window.EventBus.publish('handleInvoiceModal', () => setModalOpen(true));
        })();
    }, [TEE.payload, invoiceConfig, parseForm]);

    useEffect(() => {
        if (TEE.status === TaricEvalStatus.FAILED) window.EventBus.publish('invoiceFailedInitially');
    }, [TEE.status]);

    const handleCloseModal = useCallback(() => setModalOpen(false), [setModalOpen]);

    const handleChangeForm = useCallback((form: 'master' | 'product') => setActiveForm(form), []);

    const getTaricEvalLineItemId = useCallback(
        (productPath: `product${number}`) => {
            if (!TEE.payload) return null;
            const indexOfInvoiceProduct = +productPath.slice(7);

            const taricEvalLineItemId = TEE.items?.[indexOfInvoiceProduct]?.taricEvalLineItemId;

            return taricEvalLineItemId;
        },
        [TEE.items, TEE.payload]
    );

    const setInvoiceProductsInFormikStructure = useCallback(
        (suggestedValuesFormikStructure: any) => {
            let indexOfProductForCreation = 0;
            let pathOfProduct: string;
            Object.entries(selectedProductsActions).forEach(([productPath, productAction]) => {
                let bodyOfProduct: Record<string, string> = {};
                const productActionIsNotSelected = !(productPath in selectedProductsActions);
                const productActionIsUndefined = selectedProductsActions[productPath] === undefined;

                if (productActionIsNotSelected || productActionIsUndefined) return;

                if (productAction === '') {
                    const declarationProductsLength = getDeclarationPayloadItems(formikValues)?.length;

                    const indexToCreateProductOn = declarationProductsLength
                        ? declarationProductsLength + indexOfProductForCreation
                        : indexOfProductForCreation;

                    pathOfProduct = `${itemPath}.[${indexToCreateProductOn}]`;

                    indexOfProductForCreation++;
                } else if (productAction !== '' && productAction) {
                    const productActionValues = productAction.split(' ');
                    pathOfProduct = productActionValues[0];
                    const idOfProductForUpdate = productActionValues[1];

                    bodyOfProduct = {
                        id: idOfProductForUpdate,
                    };
                }

                bodyOfProduct = {
                    ...bodyOfProduct,
                    ...suggestedValuesFormikStructure[productPath],
                    taricEvalLineItemId: getTaricEvalLineItemId(productPath as `product${number}`),
                };

                // NCTS specific
                if (declarationType === 'ieNctsDeclaration') {
                    let houseConsignmentId = formikValues?.consignment?.houseConsignment?.[0]?.id;

                    if (bodyOfProduct.id) {
                        houseConsignmentId = formikValues?.consignment?.houseConsignment?.find(
                            (houseConsignment: any) => {
                                return houseConsignment.consignmentItem.find((consignmentItem: any) => {
                                    return consignmentItem.id === bodyOfProduct.id;
                                });
                            }
                        )?.id;
                    }

                    bodyOfProduct = {
                        ...bodyOfProduct,
                        houseConsignmentId,
                    };
                }

                complexSet(suggestedValuesFormikStructure, pathOfProduct, bodyOfProduct);
                delete suggestedValuesFormikStructure[productPath];
            });
        },
        [selectedProductsActions, getTaricEvalLineItemId, declarationType, formikValues, itemPath]
    );

    const setCustomerInFormikStructureAndGetLevelValidation = useCallback(
        (suggestedValuesFormikStructure: any): CustomerLevelValidationData[] => {
            const customersLevelValidationData: CustomerLevelValidationData[] = [];

            Object.entries(selectedCustomers).forEach(([invoiceCustomer, selectedCustomerPaths]) => {
                const selectedCustomerValues = suggestedValuesFormikStructure[invoiceCustomer];

                if (!selectedCustomerValues) return;

                let customerLevelValidationData = {
                    eoriLevelPaths: {},
                    addressLevelPaths: {},
                    allCustomerValues: selectedCustomerValues,
                } as CustomerLevelValidationData;

                Object.keys(selectedCustomerValues).forEach((fieldPath, i) => {
                    if (EORI_LEVEL_FIELD_PATHS.includes(fieldPath))
                        customerLevelValidationData.eoriLevelPaths[i] = fieldPath;
                    else customerLevelValidationData.addressLevelPaths[i] = fieldPath;
                });

                selectedCustomerPaths.forEach((customerPath: string) => {
                    customerLevelValidationData = { ...customerLevelValidationData };
                    customerLevelValidationData.customerPath = customerPath;
                    customerLevelValidationData.customerHeader =
                        invoiceConfig.partiesDataStructure.find((partyData) => partyData.path === customerPath)
                            ?.header ?? 'Customer';
                    customersLevelValidationData.push(customerLevelValidationData);

                    set(suggestedValuesFormikStructure, customerPath, selectedCustomerValues);
                });
            });

            Object.keys(selectedCustomers).forEach((key) => delete suggestedValuesFormikStructure[key]);

            return customersLevelValidationData;
        },
        [invoiceConfig.partiesDataStructure, selectedCustomers]
    );

    const getPath = (suggestedValuePath: string) => {
        const [_, path] = suggestedValuePath.split('-');
        return path;
    };

    const handleSave = useCallback(async () => {
        if (!selectedSuggestedValuesMap || selectedSuggestedValuesMap.size === 0)
            return message.error('Please select suggested values in order to save.');

        let suggestedValuesFormikStructure: any = {};
        for (let [key, value] of selectedSuggestedValuesMap) {
            if (invoiceConfig.taricPaths.some((path) => key.includes(path)))
                key = key.replace(SUGGESTED_TARICS_NUMERATION_REGEX, '');
            else if (key.includes('containerIdentificationNumber'))
                value = (value as string[])?.map((containerIdentificationNumber) =>
                    containerIdentificationNumber.slice(0, 16)
                );

            let path = key;

            if (key?.includes('customer') || key?.includes('product')) {
                path = getPath(key);
            }

            suggestedValuesFormikStructure = set(suggestedValuesFormikStructure, path, value);
        }

        setInvoiceProductsInFormikStructure(suggestedValuesFormikStructure);
        const customerLevelValidation =
            setCustomerInFormikStructureAndGetLevelValidation(suggestedValuesFormikStructure);

        const productPromises: Promise<unknown>[] = [];
        getDeclarationPayloadItems(suggestedValuesFormikStructure)?.forEach((item: any) => {
            productPromises.push(handleMirroredProductsSubmit(item));
        });

        try {
            showGlobalOverlay({
                type: 'LoadingOverlay',
            });

            await Promise.all(productPromises);
            await saveAsDraft?.(true, suggestedValuesFormikStructure);
            window.EventBus.publish('invoiceModalSaved', {
                customerLevelValidation,
            });
        } finally {
            hideGlobalOverlay();
        }

        handleCloseModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedSuggestedValuesMap,
        selectedCustomers,
        handleMirroredProductsSubmit,
        setInvoiceProductsInFormikStructure,
    ]);

    const itemDetailsDisabled = useMemo(() => {
        return TEE.items?.length === 0;
    }, [TEE.items]);

    return (
        <SModal
            visible={modalOpen}
            onCancel={handleCloseModal}
            width={'100rem'}
            footer={false}
            closable={false}
            destroyOnClose
            maskClosable={false}
        >
            <InvoiceHeader
                handleClose={handleCloseModal}
                handleChangeForm={handleChangeForm}
                activeForm={activeForm}
                onSave={handleSave}
                itemDetailsDisabled={itemDetailsDisabled}
            />
            <InvoiceBody
                activeForm={activeForm}
                parsedInvoiceResponse={parsedInvoiceResponse}
                formikValues={formikValues}
                selectedSuggestedValuesMap={selectedSuggestedValuesMap}
                setSelectedSuggestedValues={setSelectedSuggestedValuesMap}
                setSelectedCustomers={setSelectedCustomers}
                selectedCustomers={selectedCustomers}
                setSelectedProductAction={setSelectedProductsActions}
                selectedProductsActions={selectedProductsActions}
                failedToGetSuggestedTarics={TEE.status === TaricEvalStatus.FAILED}
                itemsPath={itemPath}
                partiesDataStructure={invoiceConfig.partiesDataStructure}
                taricPaths={invoiceConfig.taricPaths}
                errors={errors}
            />
        </SModal>
    );
};

export default InvoiceModal;
