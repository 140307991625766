import { cloneDeep, merge } from 'lodash';

import DeepPartial from 'types/DeepPartial';
import { irelandNctsD1ValidationHeader, irelandNctsD1ValidationItem } from './IrelandNctsD1Validation';

const REMOVE = null as any;

// -- Item
const item: DeepPartial<typeof irelandNctsD1ValidationItem> = {
    childValidators: {
        countryOfDispatch: REMOVE,
        commodity: {
            childValidators: {
                goodsMeasure: {
                    childValidators: {
                        netMass: REMOVE,
                    },
                },
            },
        },
        supportingDocument: {
            childValidators: {
                complementOfInformation: REMOVE,
            },
        },
    },
};
export const irelandNctsD2ValidationItem = merge(cloneDeep(irelandNctsD1ValidationItem), item);

// --- Header
const header: DeepPartial<typeof irelandNctsD1ValidationHeader> = {
    childValidators: {
        transitOperation: {
            childValidators: {
                tirCarnetNumber: REMOVE,
            },
        },
        consignment: {
            childValidators: {
                countryOfDispatch: REMOVE,

                inlandModeOfTransport: REMOVE,
                consignor: REMOVE,

                supportingDocument: {
                    childValidators: {
                        complementOfInformation: REMOVE,
                    },
                },

                houseConsignment: {
                    childValidators: {
                        countryOfDispatch: REMOVE,

                        consignor: REMOVE,

                        supportingDocument: {
                            childValidators: {
                                complementOfInformation: REMOVE,
                            },
                        },
                    },
                },
            },
        },
    },
};
export const irelandNctsD2ValidationHeader = merge(cloneDeep(irelandNctsD1ValidationHeader), header);
