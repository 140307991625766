import { ParsedCard, ParsedField, ParsedForm } from 'store/declaration-form-errors/ParsedForm';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';
import { parseDeclarationItems, parseParty } from './utils';
import { get } from 'lodash';

const taricsParser = (parsedItemDetails: ParsedCard[] | undefined, item: any) => {
    const fields: ParsedField[] = [];

    const suggestedTaricsArrayPath = 'suggestedTarics';
    const suggestedTaricArrayValue = get(item, suggestedTaricsArrayPath) as unknown as any[];

    const commodityCodeValues: string[] = [];
    const taricCodeValues: string[] = [];

    suggestedTaricArrayValue?.forEach((taricPair) => {
        const commodityCodeValue = get(taricPair, 'commodityCode');
        commodityCodeValues.push(commodityCodeValue);

        const taricCodeValue = get(taricPair, 'taricCode');
        taricCodeValues.push(taricCodeValue);
    });

    const commodityCodePath = 'goodsInformation.combinedNomenclatureCode';
    const taricCodePath = 'goodsInformation.taricCode';

    const goodsInformationCommodityCode = get(item, commodityCodePath);
    const goodsInformationTaricCode = get(item, taricCodePath);

    if (!(goodsInformationCommodityCode && goodsInformationTaricCode)) {
        fields.push({
            name: `Commodity Code`,
            message: commodityCodeValues,
            path: commodityCodePath,
        });

        fields.push({ name: `Taric Code`, message: taricCodeValues, path: taricCodePath });
    } else {
        const field = parsedItemDetails
            ?.find(({ name }) => name === 'Goods Information')
            ?.fields?.find((field) => field.path === commodityCodePath);
        if (field) {
            field.message = field.message?.toString().substring(0, 8);
        }
    }

    if (fields.length > 0 && parsedItemDetails) {
        parsedItemDetails.unshift({ name: 'Suggested Tarics', fields });
    }
};

export const parseInvoiceResponse = (defaultParse: ParsedForm, declaration: IrelandImportDeclaration) => {
    parseParty(defaultParse.masterDetails, declaration, 'goodsShipment.importer');
    parseParty(defaultParse.masterDetails, declaration, 'goodsShipment.seller');
    parseParty(defaultParse.masterDetails, declaration, 'goodsShipment.buyer');
    parseParty(defaultParse.masterDetails, declaration, 'exporter');
    parseParty(defaultParse.masterDetails, declaration, 'declarant');
    parseParty(defaultParse.masterDetails, declaration, 'representative');

    parseDeclarationItems(defaultParse.items, declaration, 'goodsShipment.goodsShipmentItem', taricsParser);
    const parsedDeclaration = {
        masterDetails: defaultParse.masterDetails,
        items: defaultParse.items,
    };
    return parsedDeclaration;
};
