import { FormikProps, FormikProvider } from 'formik';
import { FC, useEffect } from 'react';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { getFormikProps, getFullCodelistValue } from 'views/declarations/utils/form-utils';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { validators } from 'views/declarations/uk/export/validations/validations';
import useHideEntities from 'hooks/useHideEntities';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import CardSection from 'views/declarations/common/cards/CardSection';
import useCodelists from '../../../../hooks/useCodelists';
import useNumberOfItemsModal from '../../common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from '../../../../hooks/useProducts';
import { useOutletContext } from 'react-router-dom';
import { AESMessageTypes } from '../../../../store/declarations/enums/common/declaration-types';
import useFormUtils from '../../../../hooks/useFormUtils';
import { ieExportFormTypeMap } from './utils/form-utils';
import DeclarationPhoneNumber from 'components/ui/composed/formPhoneNumber/DeclarationPhoneNumber';

interface Props {
    formik: FormikProps<any>;
}

const IrelandExportForm: FC<Props> = ({ formik }) => {
    const { formType } = useFormUtils();
    const { isFormType } = useHideEntities();
    const { aesCodelists } = useCodelists();
    const {
        createIrelandExportProduct,
        createArrivalAtExitProduct,
        listIrelandExportProducts,
        listArrivalAtExitProducts,
    } = useProducts();
    const { saveAsDraft, amendment } =
        useOutletContext<{
            saveAsDraft: (withNotification: boolean, data?: unknown) => Promise<unknown>;
            amendment: boolean;
        }>() ?? {};

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: formType === 'ARRIVAL' ? createArrivalAtExitProduct : createIrelandExportProduct,
        listProducts: formType === 'ARRIVAL' ? listArrivalAtExitProducts : listIrelandExportProducts,
        saveAsDraft,
    });

    useEffect(() => {
        if (!formType) return;

        const { declarationTypeValue } = ieExportFormTypeMap[formType as AESMessageTypes] || {};
        const alreadyPopulated = formik?.values?.exportOperation?.declarationType;

        if (declarationTypeValue && !alreadyPopulated) {
            formik.setFieldValue('exportOperation.declarationType', declarationTypeValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    const getLocationOfGoodsFormCard = (path: string | null) => (
        <FormCardContainer>
            <DeclarationSelect
                required={formType === 'ARRIVAL'}
                label="Type of Location"
                {...getFormikProps(`${path}.typeOfLocation`, formik)}
                condensed
                selectOptions={aesCodelists?.typeOfLocation}
                codeValidation={[validators.exact(1)]}
            />
            <DeclarationSelect
                required={formType === 'ARRIVAL'}
                label="Qualifier of Identification"
                {...getFormikProps(`${path}.qualifierOfIdentification`, formik)}
                condensed
                selectOptions={aesCodelists?.locationOfGoodsQualifierOfIdentification}
                codeValidation={[validators.exact(1)]}
            />
            <DeclarationInput label="UN/LOCODE" {...getFormikProps(`${path}.unLocode`, formik)} condensed />
            <DeclarationInput
                label="Authorisation Number"
                {...getFormikProps(`${path}.authorisationNumber`, formik)}
                condensed
            />
            <DeclarationInput
                label="Additional Identifier"
                {...getFormikProps(`${path}.additionalIdentifier`, formik)}
                condensed
            />
            <DeclarationSelect
                label="Customs Office - Reference Number"
                {...getFormikProps(`${path}.customsOffice.referenceNumber`, formik)}
                codeValidation={[validators.exact(8)]}
                condensed
                selectOptions={aesCodelists?.customsOfficeReferenceNumber}
            />
            <DeclarationInput label="Latitude" {...getFormikProps(`${path}.gnss.latitude`, formik)} condensed />
            <DeclarationInput label="Longitude" {...getFormikProps(`${path}.gnss.longitude`, formik)} condensed />
            <DeclarationInput
                label="Economic Operator - Identification Number"
                {...getFormikProps(`${path}.economicOperator.identificationNumber`, formik)}
                condensed
            />
            <CardSection title="Address">
                <DeclarationInput
                    label="Street and Number"
                    {...getFormikProps(`${path}.address.streetAndNumber`, formik)}
                    condensed
                />
                <DeclarationInput label="Postcode" {...getFormikProps(`${path}.address.postcode`, formik)} condensed />
                <DeclarationInput label="City" {...getFormikProps(`${path}.address.city`, formik)} condensed />
                <CountrySelect
                    label="Country"
                    {...getFormikProps(`${path}.address.country`, formik)}
                    condensed
                    selectOptions={aesCodelists?.countryCodesCommunity}
                />
            </CardSection>
            <CardSection title="Postcode Address">
                <DeclarationInput
                    label="House Number"
                    {...getFormikProps(`${path}.postcodeAddress.houseNumber`, formik)}
                    condensed
                />
                <DeclarationInput
                    label="Postcode"
                    {...getFormikProps(`${path}.postcodeAddress.postcode`, formik)}
                    condensed
                />
                <CountrySelect
                    label="Country"
                    {...getFormikProps(`${path}.postcodeAddress.country`, formik)}
                    condensed
                    selectOptions={aesCodelists?.countryCodesCommunity}
                />
            </CardSection>
            <CardSection title="Contact Person">
                <DeclarationInput label="Name" {...getFormikProps(`${path}.contactPerson.name`, formik)} condensed />
                <DeclarationPhoneNumber
                    label="Phone Number"
                    {...getFormikProps(`${path}.contactPerson.phoneNumber`, formik)}
                    condensed
                />
                <DeclarationInput
                    label="E-mail Address"
                    {...getFormikProps(`${path}.contactPerson.emailAddress`, formik)}
                    condensed
                />
            </CardSection>
        </FormCardContainer>
    );

    return (
        <FormikProvider value={formik}>
            {/* Group 11 - Message Information (including Procedure Codes) */}
            <NewFormCard title="Message Information">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Declaration Type"
                        {...getFormikProps('exportOperation.declarationType', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        codeValidation={[validators.maxLength(5)]}
                        condensed
                        disabled={isFormType(['B1', 'B2', 'B3', 'B4']) || amendment}
                        selectOptions={aesCodelists?.declarationType}
                    />
                    <DeclarationSelect
                        label="Additional Declaration Type"
                        {...getFormikProps('exportOperation.additionalDeclarationType', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        codeValidation={[validators.exact(1)]}
                        condensed
                        disabled={amendment}
                        selectOptions={
                            ieExportFormTypeMap?.[
                                formType as AESMessageTypes
                            ]?.filterAdditionalDeclarationTypeCodelist?.(aesCodelists?.additionalDeclarationType) ||
                            aesCodelists?.additionalDeclarationType
                        }
                    />
                    <DeclarationSelect
                        label="Specific Circumstance Indicator"
                        {...getFormikProps('exportOperation.specificCircumstanceIndicator', formik)}
                        hidden={isFormType(['B3', 'B4', 'C2', 'ARRIVAL'])}
                        codeValidation={[validators.exact(3)]}
                        condensed
                        selectOptions={aesCodelists?.specificCircumstanceIndicator}
                    />
                    <DeclarationSelect
                        label="Security"
                        disabled={amendment}
                        {...getFormikProps('exportOperation.security', formik)}
                        required
                        hidden={isFormType(['B3', 'B4', 'C2', 'ARRIVAL'])}
                        codeValidation={[validators.number(), validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.exportOperationSecurity}
                    />
                    <DeclarationDatepicker
                        required
                        label="Arrival Notification Date and Time"
                        {...getFormikProps('exportOperation.arrivalNotificationDateAndTime', formik)}
                        condensed
                        hidden={isFormType(['B1', 'B2', 'B3', 'B4', 'C1', 'C2'])}
                    />
                    <DeclarationInput
                        label="Arrival Notification Place"
                        {...getFormikProps('exportOperation.arrivalNotificationPlace', formik)}
                        condensed
                        hidden={isFormType(['B1', 'B2', 'B3', 'B4', 'C1', 'C2'])}
                    />
                    <DeclarationSelect
                        label="Storing Flag"
                        {...getFormikProps('exportOperation.storingFlag', formik)}
                        required
                        codeValidation={[validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.flag}
                        hidden={isFormType(['B1', 'B2', 'B3', 'B4', 'C1', 'C2'])}
                    />
                    <DeclarationSelect
                        label="Discrepancies Exist"
                        {...getFormikProps('exportOperation.discrepanciesExist', formik)}
                        required
                        codeValidation={[validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.flag}
                        hidden={isFormType(['B1', 'B2', 'B3', 'B4', 'C1', 'C2'])}
                    />
                    <DeclarationNumberInput
                        label="Number of Items"
                        {...getFormikProps(`numberOfItems`, formik)}
                        fieldEvents={{
                            onBlur(value) {
                                handleNumberOfItems(Number(value));
                            },
                        }}
                        required
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            {/* Group 12 - References of Messages, Documents, Certificates, Authorisations */}
            <MultipleItemsCard
                title="Previous Document"
                path="goodsShipment.previousDocument"
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.previousDocumentType),
                    },
                ]}
                condensed
                hidden={isFormType(['ARRIVAL'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.previousDocumentType}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title="Additional Information"
                path="goodsShipment.additionalInformation"
                hidden={isFormType(['C2', 'ARRIVAL'])}
                initialValues={{ code: '', text: '' }}
                list={(list) => [
                    {
                        field: 'Code',
                        value: getFullCodelistValue(list.code, aesCodelists?.additionalInformationCode),
                    },
                    { field: 'Text', value: list.text },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Code"
                            {...getFormikProps(addPathPrefix(path, 'code'), formik)}
                            required
                            codeValidation={[validators.exact(5)]}
                            condensed
                            selectOptions={aesCodelists?.additionalInformationCode}
                        />
                        <DeclarationInput
                            label="Text"
                            {...getFormikProps(addPathPrefix(path, 'text'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title="Supporting Document"
                path="goodsShipment.supportingDocument"
                hidden={isFormType(['C2', 'ARRIVAL'])}
                initialValues={{
                    referenceNumber: '',
                    type: '',
                    issuingAuthorityName: '',
                    validityDate: '',
                    documentLineItemNumber: '',
                }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.supportingDocumentType),
                    },
                    { field: 'Issuing Authority Name', value: list.issuingAuthorityName },
                    { field: 'Date of Validity', value: list.validityDate },
                    { field: 'Document Line Item Number', value: list.documentLineItemNumber },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.supportingDocumentType}
                        />
                        <DeclarationInput
                            label="Issuing Authority Name"
                            {...getFormikProps(addPathPrefix(path, 'issuingAuthorityName'), formik)}
                            condensed
                        />
                        <DeclarationDatepicker
                            label="Date of Validity"
                            {...getFormikProps(addPathPrefix(path, 'validityDate'), formik)}
                            condensed
                        />
                        <DeclarationNumberInput
                            label="Document Line Item Number"
                            {...getFormikProps(addPathPrefix(path, 'documentLineItemNumber'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title="Additional Reference"
                path="goodsShipment.additionalReference"
                hidden={isFormType(['B3', 'B4', 'C2', 'ARRIVAL'])}
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.additionalReferenceType),
                    },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.additionalReferenceType}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title="Transport Document"
                path="goodsShipment.consignment.transportDocument"
                hidden={isFormType(['B3', 'C2'])}
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.transportDocumentType),
                    },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.transportDocumentType}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <NewFormCard title="References of Messages" hidden={isFormType(['C2'])}>
                <FormCardContainer>
                    <DeclarationInput
                        label="Reference Number/UCR"
                        {...getFormikProps('goodsShipment.consignment.referenceNumberUCR', formik)}
                        hidden={isFormType(['B3', 'C2'])}
                        condensed
                    />
                    <DeclarationSelect
                        label="Warehouse - Type"
                        {...getFormikProps('goodsShipment.warehouse.type', formik)}
                        hidden={isFormType(['C1', 'C2', 'ARRIVAL'])}
                        codeValidation={[validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.warehouseType}
                    />
                    <DeclarationInput
                        label="Warehouse - Identifier"
                        {...getFormikProps('goodsShipment.warehouse.identifier', formik)}
                        hidden={isFormType(['C1', 'C2', 'ARRIVAL'])}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>
            <MultipleItemsCard
                title="Authorisation"
                path="authorisation"
                initialValues={{ type: '', referenceNumber: '', holderOfAuthorisation: '' }}
                list={(list) => [
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, aesCodelists?.authorisationType),
                    },
                    { field: 'Reference Number', value: list.referenceNumber },
                    { field: 'Holder of Authorisation', value: list.holderOfAuthorisation },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.maxLength(4)]}
                            condensed
                            selectOptions={aesCodelists?.authorisationType}
                        />
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationInput
                            label="Holder of Authorisation"
                            {...getFormikProps(addPathPrefix(path, 'holderOfAuthorisation'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            {/* Group 13 - Parties */}
            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: undefined,
                        eoriRequired: true,
                        hidden: isFormType(['C2', 'ARRIVAL']),
                        disabled: amendment,
                    },
                    {
                        path: 'goodsShipment.consignment.consignor',
                        header: 'Consignor',
                        refNumber: undefined,
                        hidden: isFormType(['B2', 'B3', 'B4', 'C2', 'ARRIVAL']),
                    },
                    {
                        path: 'goodsShipment.consignment.consignee',
                        header: 'Consignee',
                        refNumber: undefined,
                        eoriRequired: isFormType(['B2', 'B3', 'B4']),
                        hidden: isFormType(['C2', 'ARRIVAL']),
                    },
                    {
                        path: 'declarant',
                        header: 'Declarant',
                        refNumber: undefined,
                        eoriRequired: true,
                        contactPerson: {
                            present: true,
                            hidden: isFormType(['C2']),
                        },
                        hidden: isFormType(['ARRIVAL']),
                        disabled: amendment,
                    },
                    {
                        path: 'representative',
                        header: 'Representative',
                        refNumber: undefined,
                        hidden: isFormType(['C2', 'ARRIVAL']),
                        contactPerson: {
                            present: true,
                        },
                        hasRepresentativeStatus: true,
                        hasAddress: false,
                        disabled: amendment,
                    },
                    {
                        path: 'goodsShipment.consignment.carrier',
                        header: 'Carrier',
                        refNumber: undefined,
                        hasAddress: false,
                        hidden: isFormType(['B3', 'B4', 'C2', 'ARRIVAL']),
                    },
                    {
                        path: 'goodsShipment.consignment.exitCarrier',
                        header: 'Exit Carrier',
                        refNumber: undefined,
                        contactPerson: {
                            present: true,
                        },
                        hidden: isFormType(['B1', 'B2', 'B3', 'B4', 'C1', 'C2']),
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.city',
                        country: 'address.country',
                        name: 'name',
                        postCode: 'address.postcode',
                        streetAndNumber: 'address.streetAndNumber',
                    },
                    eori: 'identificationNumber',
                    contactPerson: {
                        name: 'contactPerson.name',
                        phoneNumber: 'contactPerson.phoneNumber',
                        email: 'contactPerson.emailAddress',
                    },
                    additional: {
                        representativeStatus: 'status',
                    },
                }}
                condensed
            />
            <MultipleItemsCard
                title="Additional Supply Chain Actor"
                path="goodsShipment.additionalSupplyChainActor"
                hidden={isFormType(['C2', 'ARRIVAL'])}
                initialValues={{ role: '', identificationNumber: '' }}
                list={(list) => [
                    {
                        field: 'Role',
                        value: getFullCodelistValue(list.role, aesCodelists?.additionalSupplyChainActorRoleCode),
                    },
                    { field: 'Identification Number', value: list.identificationNumber },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Role"
                            {...getFormikProps(addPathPrefix(path, 'role'), formik)}
                            required
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aesCodelists?.additionalSupplyChainActorRoleCode}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            required
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            {/* Group 14 - Valuation Information / Taxes */}
            <NewFormCard title="Valuation Information / Taxes">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Transport Charges - Method of Payment"
                        {...getFormikProps('goodsShipment.consignment.transportCharges.methodOfPayment', formik)}
                        hidden={isFormType(['B3', 'B4', 'C2', 'ARRIVAL'])}
                        codeValidation={[validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.transportChargesMethodOfPayment}
                    />
                    <CurrencySelect
                        label="Invoice Currency"
                        {...getFormikProps('exportOperation.invoiceCurrency', formik)}
                        hidden={isFormType(['B3', 'C1', 'C2', 'ARRIVAL'])}
                        condensed
                    />
                    <DeclarationNumberInput
                        label="Total Amount Invoiced"
                        {...getFormikProps('exportOperation.totalAmountInvoiced', formik)}
                        hidden={isFormType(['B3', 'C1', 'C2', 'ARRIVAL'])}
                        condensed
                    />
                    <CurrencySelect
                        selectOptions={aesCodelists?.internalCurrencyUnit}
                        label="Currency Exchange - Internal Currency Unit"
                        {...getFormikProps('currencyExchange.internalCurrencyUnit', formik)}
                        condensed
                        hidden={isFormType(['ARRIVAL'])}
                    />
                    <DeclarationNumberInput
                        label="Currency Exchange - Exchange Rate"
                        {...getFormikProps('currencyExchange.exchangeRate', formik)}
                        condensed
                        hidden={isFormType(['ARRIVAL'])}
                    />
                    <DeclarationInput
                        label="Deferred Payment"
                        {...getFormikProps('deferredPayment.deferredPayment', formik)}
                        condensed
                        hidden={isFormType(['ARRIVAL'])}
                    />
                    <DeclarationSelect
                        label="Nature of Transaction"
                        {...getFormikProps('goodsShipment.natureOfTransaction', formik)}
                        codeValidation={[validators.number(), validators.maxLength(2)]}
                        condensed
                        selectOptions={aesCodelists?.natureOfTransactionCode}
                        hidden={isFormType(['ARRIVAL'])}
                    />
                    <CardSection title="Delivery Terms" hidden={isFormType(['ARRIVAL'])}>
                        <DeclarationSelect
                            label="Incoterm Code"
                            {...getFormikProps('goodsShipment.deliveryTerms.incotermCode', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2', 'ARRIVAL'])}
                            codeValidation={[validators.exact(3)]}
                            condensed
                            selectOptions={aesCodelists?.incotermCode}
                        />
                        <DeclarationSelect
                            label="UN/LOCODE"
                            {...getFormikProps('goodsShipment.deliveryTerms.unLocode', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2', 'ARRIVAL'])}
                            codeValidation={[validators.maxLength(17)]}
                            condensed
                            selectOptions={aesCodelists?.deliveryTermsUnLocode}
                        />
                        <CountrySelect
                            label="Country"
                            {...getFormikProps('goodsShipment.deliveryTerms.country', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2', 'ARRIVAL'])}
                            condensed
                            selectOptions={aesCodelists?.country}
                        />
                        <DeclarationInput
                            label="Location"
                            {...getFormikProps('goodsShipment.deliveryTerms.location', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2', 'ARRIVAL'])}
                            condensed
                        />
                        <DeclarationInput
                            label="Text"
                            {...getFormikProps('goodsShipment.deliveryTerms.text', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2', 'ARRIVAL'])}
                            condensed
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>
            {/* Group 15 - Dates - Times - Periods */}
            <NewFormCard title="Dates - Times - Periods" hidden={isFormType(['C2', 'ARRIVAL'])}>
                <FormCardContainer>
                    <DeclarationDatepicker
                        label="Date and time of presentation of the goods"
                        {...getFormikProps('exportOperation.presentationOfTheGoodsDateAndTime', formik)}
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        showTime
                        condensed
                        getPopupContainer={() => document.body}
                    />
                </FormCardContainer>
            </NewFormCard>
            {/* Group 16 - Places / Countries / Regions */}
            <NewFormCard title="Places / Countries / Regions" hidden={isFormType(['C2', 'ARRIVAL'])}>
                <FormCardContainer>
                    <CountrySelect
                        label="Country of Destination"
                        {...getFormikProps('goodsShipment.countryOfDestination', formik)}
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        condensed
                        selectOptions={aesCodelists?.country}
                    />
                    <CountrySelect
                        label="Country of Export"
                        {...getFormikProps('goodsShipment.countryOfExport', formik)}
                        hidden={isFormType(['B4', 'C2', 'ARRIVAL'])}
                        condensed
                        selectOptions={aesCodelists?.countryCodesCommunity}
                    />
                    <MultipleDeclarationField
                        parent="goodsShipment.consignment.countryOfRoutingOfConsignment"
                        name="country"
                    >
                        {(fieldProps, controls) => (
                            <CountrySelect
                                label="Country of Routing of Consignment"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                hidden={isFormType(['B3', 'B4', 'C2', 'ARRIVAL'])}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>
            <NewFormCard title="Location of Goods" hidden={isFormType(['ARRIVAL'])}>
                {getLocationOfGoodsFormCard('goodsShipment.consignment.locationOfGoods')}
            </NewFormCard>
            <MultipleItemsCard
                title="Location of Goods"
                path="goodsShipment.consignment.locationOfGoods"
                hidden={isFormType(['B1', 'B2', 'B3', 'B4', 'C1', 'C2'])}
                initialValues={{
                    typeOfLocation: '',
                    qualifierOfIdentification: '',
                    unLocode: '',
                    authorisationNumber: '',
                    additionalIdentifier: '',
                    customsOffice: { referenceNumber: '' },
                    gnss: {
                        latitude: '',
                        longitude: '',
                    },
                    economicOperator: {
                        identificationNumber: '',
                    },
                    address: {
                        streetAndNumber: '',
                        postcode: '',
                        city: '',
                        country: '',
                    },
                    postcodeAddress: {
                        houseNumber: '',
                        postcode: '',
                        country: '',
                    },
                    contactPerson: {
                        name: '',
                        phoneNumber: '',
                        emailAddress: '',
                    },
                }}
                list={(list) => [
                    {
                        field: 'Type of Location',
                        value: getFullCodelistValue(list.typeOfLocation, aesCodelists?.typeOfLocation),
                    },
                    {
                        field: 'Qualifier of Identification',
                        value: getFullCodelistValue(
                            list.qualifierOfIdentification,
                            aesCodelists?.locationOfGoodsQualifierOfIdentification
                        ),
                    },
                    { field: 'UN/LOCODE', value: list.unLocode },
                    { field: 'Authorisation Number', value: list.authorisationNumber },
                    { field: 'Additional Identifier', value: list.additionalIdentifier },
                    {
                        field: 'Customs Office - Reference Number',
                        value: getFullCodelistValue(
                            list.customsOffice.referenceNumber,
                            aesCodelists?.customsOfficeReferenceNumber
                        ),
                    },
                    { field: 'Latitude', value: list.gnss.latitude },
                    { field: 'Longitude', value: list.gnss.longitude },
                    {
                        field: 'Economic Operator - Identification Number',
                        value: list.economicOperator.identificationNumber,
                    },
                    { field: 'Address - Street and Number', value: list.address.streetAndNumber },
                    { field: 'Address - Postcode', value: list.address.postcode },
                    { field: 'Address - City', value: list.address.city },
                    { field: 'Address - Country', value: list.address.country },
                    { field: 'Postcode Address - House Number', value: list.postcodeAddress.houseNumber },
                    { field: 'Postcode Address - Postcode', value: list.postcodeAddress.postcode },
                    { field: 'Postcode Address - Country', value: list.postcodeAddress.country },
                    { field: 'Contact Person - Name', value: list.contactPerson.name },
                    { field: 'Contact Person - Phone Number', value: list.contactPerson.phoneNumber },
                    { field: 'Contact Person - E-mail Address', value: list.contactPerson.emailAddress },
                ]}
                condensed
                required
            >
                {(path) => getLocationOfGoodsFormCard(path)}
            </MultipleItemsCard>

            {/* Group 17 - Customs Offices */}
            <NewFormCard title="Customs Offices">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Customs Office of Exit (Declared) - Reference Number"
                        {...getFormikProps('customsOfficeOfExitDeclared.referenceNumber', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        codeValidation={[validators.exact(8)]}
                        condensed
                        disabled={amendment}
                        selectOptions={aesCodelists?.customsOfficeOfExitDeclaredReferenceNumber}
                    />
                    <DeclarationSelect
                        label="Customs Office of Export - Reference Number"
                        {...getFormikProps('customsOfficeOfExport.referenceNumber', formik)}
                        required
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        codeValidation={[validators.exact(8)]}
                        condensed
                        disabled={amendment}
                        selectOptions={aesCodelists?.customsOfficeOfExportReferenceNumber}
                    />
                    <DeclarationSelect
                        label="Customs Office of Presentation - Reference Number"
                        {...getFormikProps('customsOfficeOfPresentation.referenceNumber', formik)}
                        codeValidation={[validators.exact(8)]}
                        condensed
                        disabled={amendment}
                        selectOptions={aesCodelists?.customsOfficeOfPresentation}
                        hidden={isFormType(['ARRIVAL'])}
                    />
                    <DeclarationSelect
                        label="Customs Office of Exit (Actual) - Reference Number"
                        {...getFormikProps('customsOfficeOfExitActual.referenceNumber', formik)}
                        required
                        hidden={isFormType(['B1', 'B2', 'B3', 'B4', 'C1', 'C2'])}
                        codeValidation={[validators.exact(8)]}
                        condensed
                        selectOptions={aesCodelists?.customsOfficeOfExitReferenceNumber}
                    />
                </FormCardContainer>
            </NewFormCard>
            {/* Group 18 - Goods Identification */}
            <NewFormCard title="Goods Identification" hidden={isFormType(['C2', 'ARRIVAL'])}>
                <FormCardContainer>
                    <DeclarationNumberInput
                        label="Gross Mass"
                        {...getFormikProps('goodsShipment.consignment.grossMass', formik)}
                        hidden={isFormType(['C2', 'ARRIVAL'])}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>
            {/* Group 19 - Transport Information */}
            <NewFormCard title="Transport Information" hidden={isFormType(['C1', 'C2'])}>
                <FormCardContainer>
                    <DeclarationSelect
                        label="Container Indicator"
                        {...getFormikProps('goodsShipment.consignment.containerIndicator', formik)}
                        hidden={isFormType(['C1', 'C2', 'ARRIVAL'])}
                        codeValidation={[validators.number(), validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.flag}
                    />
                    <DeclarationSelect
                        label="Mode of Transport at the Border"
                        {...getFormikProps('goodsShipment.consignment.modeOfTransportAtTheBorder', formik)}
                        hidden={isFormType(['C1', 'C2'])}
                        codeValidation={[validators.number(), validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.transportMode}
                    />
                    <DeclarationSelect
                        label="Inland Mode of Transport"
                        {...getFormikProps('goodsShipment.consignment.inlandModeOfTransport', formik)}
                        hidden={isFormType(['B4', 'C1', 'C2', 'ARRIVAL'])}
                        codeValidation={[validators.number(), validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.transportMode}
                    />
                    <CardSection title="Active Border Transport Means">
                        <DeclarationSelect
                            label="Type of Identification"
                            {...getFormikProps(
                                'goodsShipment.consignment.activeBorderTransportMeans.typeOfIdentification',
                                formik
                            )}
                            hidden={isFormType(['B2', 'B4', 'C1', 'C2'])}
                            codeValidation={[validators.number(), validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.typeOfIdentificationOfMeansOfTransportActive}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(
                                'goodsShipment.consignment.activeBorderTransportMeans.identificationNumber',
                                formik
                            )}
                            hidden={isFormType(['B2', 'B4', 'C1', 'C2'])}
                            condensed
                        />
                        <DeclarationSelect
                            label="Nationality"
                            {...getFormikProps(
                                'goodsShipment.consignment.activeBorderTransportMeans.nationality',
                                formik
                            )}
                            hidden={isFormType(['B2', 'B4', 'C1', 'C2'])}
                            codeValidation={[validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.nationality}
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>
            <MultipleItemsCard
                title="Departure Transport Means"
                path="goodsShipment.consignment.departureTransportMeans"
                hidden={isFormType(['B4', 'C1', 'C2', 'ARRIVAL'])}
                initialValues={{ typeOfIdentification: '', identificationNumber: '', nationality: '' }}
                list={(list) => [
                    {
                        field: 'Type of Identification',
                        value: getFullCodelistValue(
                            list.typeOfIdentification,
                            aesCodelists?.typeOfIdentificationOfMeansOfTransportActive
                        ),
                    },
                    { field: 'Identification Number', value: list.identificationNumber },
                    {
                        field: 'Nationality',
                        value: getFullCodelistValue(list.nationality, aesCodelists?.nationality),
                    },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type of Identification"
                            {...getFormikProps(addPathPrefix(path, 'typeOfIdentification'), formik)}
                            hidden={isFormType(['B4', 'C1', 'C2'])}
                            codeValidation={[validators.number(), validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.typeOfIdentificationOfMeansOfTransportActive}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            required={isFormType(['B1', 'B3'])} // TODO check required all?
                            hidden={isFormType(['B4', 'C1', 'C2'])}
                            condensed
                        />
                        <DeclarationSelect
                            label="Nationality"
                            {...getFormikProps(addPathPrefix(path, 'nationality'), formik)}
                            hidden={isFormType(['B4', 'C1', 'C2'])}
                            codeValidation={[validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.nationality}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title="Transport Equipment"
                path="goodsShipment.consignment.transportEquipment"
                hidden={isFormType(['C1', 'C2'])}
                initialValues={{
                    containerIdentificationNumber: '',
                    numberOfSeals: '',
                    seal: [{ identifier: '' }],
                    goodsReference: [{ declarationGoodsItemNumber: '' }],
                }}
                list={(list) => [
                    { field: 'Container Identification Number', value: list.containerIdentificationNumber },
                    { field: 'Number of Seals', value: list.numberOfSeals },
                    {
                        field: 'Seals',
                        value: list.seal?.map((seal) => seal.identifier).join(', '),
                    },
                    {
                        field: 'Goods References',
                        value: list.goodsReference
                            ?.map((goodsReference) => goodsReference.declarationGoodsItemNumber)
                            .join(', '),
                    },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Container Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'containerIdentificationNumber'), formik)}
                            hidden={isFormType(['C1', 'C2'])}
                            condensed
                        />
                        <DeclarationNumberInput
                            required={formType !== 'ARRIVAL'}
                            label="Number of Seals"
                            {...getFormikProps(addPathPrefix(path, 'numberOfSeals'), formik)}
                            hidden={isFormType(['B3', 'B4', 'C2'])}
                            condensed
                        />
                        <MultipleDeclarationField parent={addPathPrefix(path, 'seal')} name="identifier">
                            {(fieldProps, controls) => (
                                <DeclarationInput
                                    required={formType !== 'ARRIVAL'}
                                    label="Seal Identifier"
                                    {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                    hidden={isFormType(['B3', 'B4', 'C2'])}
                                    multipleF={controls}
                                    condensed
                                />
                            )}
                        </MultipleDeclarationField>
                        <MultipleDeclarationField
                            parent={addPathPrefix(path, 'goodsReference')}
                            name="declarationGoodsItemNumber"
                        >
                            {(fieldProps, controls) => (
                                <DeclarationNumberInput
                                    required={formType !== 'ARRIVAL'}
                                    label="Goods Reference - Declaration Goods Item Number"
                                    {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                    hidden={isFormType(['C1', 'C2'])}
                                    multipleF={controls}
                                    condensed
                                />
                            )}
                        </MultipleDeclarationField>
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <NewFormCard title="Test purposes">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Phase Id"
                        {...getFormikProps('phaseID', formik)}
                        codeValidation={[validators.maxLength(12)]}
                        condensed
                        selectOptions={[
                            { id: 'AES_1_0', value: 'Phase AES 1.1' },
                            { id: 'AES_1_1', value: 'Phase AES 1.0' },
                        ]}
                    />
                </FormCardContainer>
            </NewFormCard>
            {modalContextHolder}
        </FormikProvider>
    );
};
export default IrelandExportForm;
