import { useCallback } from 'react';
import useFormUtils from './useFormUtils';

export type AvailableForms = string;

const useHideEntities = () => {
    const { formTypeForCds: formType } = useFormUtils();

    const isFormType = useCallback(
        (formTypes: string[]) => {
            if (!formType) return false;

            for (const formTypeToHide of formTypes) {
                if (formType.includes(formTypeToHide)) return true;
            }

            return false;
        },
        [formType]
    );

    return { isFormType };
};

export default useHideEntities;
