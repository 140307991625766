import Alert from 'components/ui/base/alert/Alert';
import Button from 'components/ui/base/button';
import { useNavigate } from 'react-router-dom';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';

const AlertRefundRemissionRequested = () => {
    const navigate = useNavigate();

    return (
        <Alert
            message={
                <>
                    Remission refund requested by Revenue
                    <Button
                        size="small"
                        type="link"
                        onClick={() => {
                            navigate('refunds/remissions/new', {
                                state: { activeTabKey: DeclarationTabKeys.REFUNDS },
                            });
                        }}
                    >
                        Fill out form
                    </Button>
                </>
            }
            type="info"
            showIcon
        />
    );
};

export default AlertRefundRemissionRequested;
