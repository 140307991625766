import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { TaricsActions } from './actionType';
import { TaricCode, TaricCodeAmount, TaricCodeSection, TaricMeasurement } from './taric-code';
export interface TaricCodeState {
    isLoading: boolean;
    error?: ErrorResponse;
    taricCode?: string[];
    taricCodeDetails?: TaricCode[];
    taricCodeSections?: TaricCodeSection[];
    taricCodeAmount?: TaricCodeAmount;
    importTaricMeasurement?: TaricMeasurement[];
    exportTaricMeasurement?: TaricMeasurement[];
    generalTaricMeasurement?: TaricMeasurement[];
}
export const taricCodeInitialState: Readonly<TaricCodeState> = {
    isLoading: false,
    taricCode: [],
    taricCodeDetails: [],
    taricCodeSections: [],
    importTaricMeasurement: [],
    exportTaricMeasurement: [],
    generalTaricMeasurement: [],
};
const taricCode: Reducer<TaricCodeState, TaricsActions> = (state = taricCodeInitialState, action): TaricCodeState => {
    switch (action.type) {
        case 'GET_TARIC_CODES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_TARIC_CODES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                taricCode: action.payload,
                taricCodeDetails: action.payload as any,
            };
        case 'GET_TARIC_CODES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_TARIC_CODES_SEARCH_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_TARIC_CODES_SEARCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                taricCodeDetails: action.payload,
            };
        case 'GET_TARIC_CODES_SEARCH_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_TARIC_CODES_SECTIONS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_TARIC_CODES_SECTIONS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                taricCodeSections: action.payload,
            };
        case 'GET_TARIC_CODES_SECTIONS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_TARIC_CODES_AMOUNT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_TARIC_CODES_AMOUNT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                taricCodeAmount: action.payload,
            };
        case 'GET_TARIC_CODES_AMOUNT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_EU_IMPORT_TARIC_MEASUREMENT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_EU_IMPORT_TARIC_MEASUREMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                importTaricMeasurement: action.payload,
            };
        case 'GET_EU_IMPORT_TARIC_MEASUREMENT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_EU_EXPORT_TARIC_MEASUREMENT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_EU_EXPORT_TARIC_MEASUREMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                exportTaricMeasurement: action.payload,
            };
        case 'GET_EU_EXPORT_TARIC_MEASUREMENT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_UK_IMPORT_TARIC_MEASUREMENT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_UK_IMPORT_TARIC_MEASUREMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                importTaricMeasurement: action.payload,
            };
        case 'GET_UK_IMPORT_TARIC_MEASUREMENT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_UK_EXPORT_TARIC_MEASUREMENT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_UK_EXPORT_TARIC_MEASUREMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                exportTaricMeasurement: action.payload,
            };
        case 'GET_UK_EXPORT_TARIC_MEASUREMENT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_EU_GENERAL_TARIC_MEASUREMENT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_EU_GENERAL_TARIC_MEASUREMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                generalTaricMeasurement: action.payload,
            };
        case 'GET_EU_GENERAL_TARIC_MEASUREMENT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_UK_GENERAL_TARIC_MEASUREMENT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_UK_GENERAL_TARIC_MEASUREMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                generalTaricMeasurement: action.payload,
            };
        case 'GET_UK_GENERAL_TARIC_MEASUREMENT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
export default taricCode;
