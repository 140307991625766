import { UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Row } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Breadcrumb from 'components/ui/base/breadcrumb/Breadcrum';
import Button from 'components/ui/base/button/Button';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useSession from 'hooks/useSession';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CollapseIcon, InfoSpan, StyledHeader, UncollapseIcon, UserDiv } from './Header.styles';

interface Props {
    collapsed: boolean;
    setCollapsed: Function;
}

const Header: FC<Props> = ({ collapsed, setCollapsed }) => {
    const { breadcrumb } = useBreadcrumb();
    const { logout, userInfo } = useSession();

    const headerMenuItems = useMemo((): ItemType[] => {
        return [
            {
                label: <Link to={{ pathname: '/terms-and-conditions' }}>Terms & Conditions</Link>,
                key: 'terms-and-conditions',
            },
            {
                label: <Link to={{ pathname: '/privacy-policy' }}>Privacy Policy</Link>,
                key: 'privacy-policy',
            },
            {
                label: 'Logout',
                key: 'logout',
                onClick: logout,
            },
        ];
    }, [logout]);

    const menu = useMemo(() => {
        return <Menu items={headerMenuItems} />;
    }, [headerMenuItems]);

    return (
        <StyledHeader className="site-layout-background">
            <Row align="middle">
                {collapsed ? (
                    <UncollapseIcon
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                    />
                ) : (
                    <CollapseIcon
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                    />
                )}
                <Breadcrumb routes={breadcrumb} />
            </Row>

            <UserDiv>
                <Dropdown overlay={menu} placement="bottom">
                    <Button type={'text'}>
                        <UserOutlined /> <InfoSpan>{userInfo?.email}</InfoSpan>
                    </Button>
                </Dropdown>
            </UserDiv>
        </StyledHeader>
    );
};
export default Header;
