export enum DeclarationInternalType {
    IMPORT = 'IMPORT',
    IMPORT_NEW = 'IMPORT_NEW',
    EXPORT = 'EXPORT',
    ENS = 'ENS',
    ETD = 'ETD',
    ARRIVAL = 'ARRIVAL',
    TEMPORARY = 'TEMPORARY',
    GVMS = 'GVMS',
    PBN = 'PBN',
    NCTS = 'NCTS',
}

export const irelandInternalDeclarationTypes = [
    {
        id: DeclarationInternalType.IMPORT,
        value: 'Import (AIS)',
    },
    { id: DeclarationInternalType.ENS, value: 'ENS (ICS)' },
    { id: DeclarationInternalType.TEMPORARY, value: 'TSD' },
    { id: DeclarationInternalType.ETD, value: 'ETD' },
    { id: DeclarationInternalType.EXPORT, value: 'Export (AES)' },
    { id: DeclarationInternalType.ARRIVAL, value: 'Arrival at exit' },
];

export const ukInternalDeclarationTypes = [
    {
        id: DeclarationInternalType.IMPORT,
        value: 'Import',
    },
    { id: DeclarationInternalType.EXPORT, value: 'Export' },
];
