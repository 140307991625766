import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { ParsedCard, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';
import {
    parseFieldErrorsForAesCustomerCard,
    parseFieldErrorsForAisCustomerCard,
    parseFieldErrorsForCard,
    parseFieldErrorsForMultipleCard,
} from 'utils/validationParserUtils';

const irelandI1ValidationErrorsParser = (validation: FormikErrors<IrelandImportDeclaration>) => {
    const masterDetailsErrors: ParsedCard[] = [];
    parseFieldErrorsForCard(
        'Master Details',
        [
            { declarationType: 'Declaration Type' },
            { additionalDeclarationType: 'Additional Declaration Type' },
            { grossMass: 'Gross Mass' },
            { 'goodsShipment.ucr': 'Ucr' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Value Details',
        [
            { invoiceCurrency: 'Invoice Currency' },
            { invoiceAmount: 'Total amount invoiced' },
            { personPayingCustomsDuty: 'Customs Duty Payer' },
            { preferredPaymentMethod: 'Preferred Payment Method' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Transport information',
        [
            { customsOfficeLodgement: 'Customs Office of Lodgement' },
            { presentationCustomsOffice: 'Customs office of presentation' },
            { supervisingCustomsOffice: 'Office Import/Export' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForAisCustomerCard('Exporter', 'exporter', masterDetailsErrors, validation);
    parseFieldErrorsForAisCustomerCard('Importer', 'goodsShipment.importer', masterDetailsErrors, validation);
    parseFieldErrorsForAisCustomerCard('Declarant', 'declarant', masterDetailsErrors, validation);
    parseFieldErrorsForAisCustomerCard('Representative', 'representative', masterDetailsErrors, validation);

    parseFieldErrorsForMultipleCard(
        'Authorisation Holder',
        'authorisationHolder',
        [{ authorisationTypeCode: 'Type code' }, { authorisationHolderIdentification: 'Identification number' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Previous Document',
        'goodsShipment.simplifiedDeclarationDocumentWritingOff',
        [
            { previousDocumentType: 'Previous document type' },
            { previousDocumentIdentifier: 'Previous document identifier' },
            { previousDocumentLineId: 'Goods Item Identifier' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'goodsShipment.additionalInformation',
        [{ additionalInformationCode: 'Code' }, { additionalInformationText: 'Text' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Other details',
        [
            { 'goodsShipment.warehouseType': 'Warehouse type' },
            { 'goodsShipment.warehouseIdentifier': 'Warehouse Identifier' },
            { 'goodsShipment.countryDispatch': 'Country dispatch' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Supply chain actor',
        'supplyChainActor',
        [{ roleCode: 'Role code' }, { traderIdentification: 'Trader identification' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Goods location',
        [
            { 'goodsShipment.locationGoods.locationTypeCode': 'Type of location code' },
            { 'goodsShipment.locationGoods.qualifierIdentification': 'Qualifier of the identification' },
            { 'goodsShipment.locationGoods.identificationOfLocation': 'Identification of location' },
            { 'goodsShipment.locationGoods.additionalIdentifier': 'Additional Identifier' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Container Identification Number',
        'goodsShipment.containerIdentificationNumber',
        [{ containerIdentificationNumber: 'Container Identification Number' }],
        masterDetailsErrors,
        validation
    );

    const itemsErrors: ParsedItem[] = [];
    parseDeclarationItems(itemsErrors, validation);

    return {
        masterDetails: masterDetailsErrors,
        items: itemsErrors,
    };
};

const parseDeclarationItems = (errors: ParsedItem[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const products = get(validation, 'goodsShipment.goodsShipmentItem');

    if (!Array.isArray(products)) return;

    products?.forEach((itemErrors: any, index: number) => {
        const item = { index: index + 1, errors: [] };

        parseDeclarationItem(item.errors, itemErrors);

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseImportI1ProductTemplate = (validation: FormikErrors<IrelandImportDeclaration>) => {
    const productTemplateErrors: ParsedCard[] = [];

    parseDeclarationItem(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseDeclarationItem = (parsed: ParsedCard[], errors: FormikErrors<IrelandImportDeclaration>) => {
    parseFieldErrorsForCard(
        'Goods Information',
        [
            { preference: 'Preference Code' },
            { 'goodsInformation.combinedNomenclatureCode': 'Commodity Code' },
            { 'goodsInformation.taricCode': 'TARIC additional code' },
            { 'goodsInformation.taricAdditionalCode': 'National Additional Code' },
            { 'goodsInformation.goodsDescription': 'Description of Goods' },
            { 'goodsInformation.supplementaryUnits': 'Supplementary Units' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Packages',
        'goodsInformation.packaging',
        [
            { packageNumber: 'Number of Packages' },
            { typePackage: 'Package Type Code' },
            { shippingMarks: 'Shipping Marks' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForCard(
        'Item Details',
        [
            { procedureCode: 'Procedure Code' },
            { previousProcedureCode: 'Previous Procedure Code' },
            { 'goodsInformation.grossMass': 'Gross Mass' },
            { additionalProcedure: 'Additional Procedure Code' },
            { 'goodsInformation.netMass': 'Net Mass' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Simplified Declaration Document Writing Off Header',
        'simplifiedDeclarationDocumentWritingOff',
        [
            { previousDocumentType: 'Previous document type' },
            { previousDocumentIdentifier: 'Previous document identifier' },
            { previousDocumentLineId: 'Goods Item Identifier' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'additionalInformation',
        [{ additionalInformationCode: 'Code' }, { additionalInformationText: 'Text' }],
        parsed,
        errors
    );
    parseFieldErrorsForAesCustomerCard('Exporter', 'exporter', parsed, errors);
    parseFieldErrorsForAesCustomerCard('Buyer', 'buyer', parsed, errors);
    parseFieldErrorsForMultipleCard(
        'Supply chain actor',
        'supplyChainActor',
        [{ roleCode: 'Role code' }, { traderIdentification: 'Trader identification' }],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Additional Taxes',
        'taxBox43Bis',
        [
            { boxTaxType: 'Code' },
            { boxTaxBaseUnit: 'Text' },
            { boxAmount: 'Box Tax Base Unit' },
            { boxTaxRate: 'Tax Rate' },
            { boxTaxPayableAmount: 'Amount of Tax Payable' },
            { boxTaxPaymentMethod: 'Payment Method' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForCard('Value Details', [{ itemAmount: 'Item Price' }], parsed, errors);
    parseFieldErrorsForCard(
        'Extra item details',
        [
            { 'goodsInformation.cusCode': 'CUS code' },
            { taxTotalAmount: 'Calculation of taxes - Total' },
            { countryDispatch: 'Country dispatch' },
            { countryPreferentialOrigin: 'Country of preferential origin code' },
            { quotaOrderNumber: 'Quota Order Number' },
            { transactionNature: 'Nature of transaction' },
            { countryOrigin: 'Country origin' },
            { ucr: 'UCR' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForCard(
        'Container Identification Number',
        [{ containerIdentificationNumber: 'Container Identification Number' }],
        parsed,
        errors
    );
};

export default irelandI1ValidationErrorsParser;
