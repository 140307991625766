import { TextSmall } from 'components/ui/base/typography/Typography.styles';
import useCountries from 'hooks/useCountries';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from 'store/customers/customer';
import { InfoDiv, Label } from './CustomerDetails.styles';

interface Props {
    customer?: Customer | null;
}

const CustomerInfo: FC<Props> = ({ customer }) => {
    const { getNameFromCountryCode } = useCountries();
    const { t } = useTranslation('customers');

    const info = useMemo(() => {
        return (
            <>
                <InfoDiv>
                    <Label>{t('form.customerName')}</Label>
                    <TextSmall>{customer?.name ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.customerType')}</Label>
                    <TextSmall>{customer?.type ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.eori')}</Label>
                    <TextSmall>{customer?.eori ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.referenceCode')}</Label>
                    <TextSmall>{customer?.referenceCode ?? '-'} </TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.traderAccountNumber')}</Label>
                    <TextSmall> {customer?.traderAccountNumber ?? '-'} </TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.deferredAccountNumber')}</Label>
                    <TextSmall> {customer?.deferredAccountNumber ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.vatNumber')}</Label>
                    <TextSmall> {customer?.vat ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.email')}</Label>
                    <TextSmall>{customer?.email ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.phoneNumber')}</Label>
                    <TextSmall>{customer?.phone ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.role')}</Label>
                    <TextSmall>{customer?.type ?? '-'}</TextSmall>
                </InfoDiv>

                <Label>{t('form.address')}</Label>
                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.addressLine1')}</Label>
                    <TextSmall>{customer?.address?.addressLine1 ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.addressLine2')}</Label>
                    <TextSmall>{customer?.address?.addressLine2 ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.postCode')}</Label>
                    <TextSmall>{customer?.address?.postCode ?? '-'} </TextSmall>
                </InfoDiv>

                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.country')}</Label>
                    <TextSmall> {getNameFromCountryCode(customer?.address?.country) ?? '-'} </TextSmall>
                </InfoDiv>

                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.city')}</Label>
                    <TextSmall> {customer?.address?.city ?? '-'} </TextSmall>
                </InfoDiv>
                <Label>{t('form.contact')}</Label>

                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.name')}</Label>
                    <TextSmall> {customer?.contact?.name ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.email')}</Label>
                    <TextSmall> {customer?.contact?.email ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.phoneNumber')}</Label>
                    <TextSmall>{customer?.contact?.phoneNumber ?? '-'} </TextSmall>
                </InfoDiv>

                <InfoDiv paddingLeft={2}>
                    <Label>{t('form.role')}</Label>
                    <TextSmall>{customer?.contact?.role ?? '-'}</TextSmall>
                </InfoDiv>

                <InfoDiv>
                    <Label>{t('form.specialInstructions')}</Label>
                    <TextSmall> {customer?.specialInstructions ?? '-'}</TextSmall>
                </InfoDiv>
            </>
        );
    }, [customer, getNameFromCountryCode, t]);

    return <div>{info}</div>;
};
export default CustomerInfo;
