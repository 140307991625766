import { FormikProps } from 'formik';

export const updateIrelandH1ImportTaricCode = (formik: FormikProps<any>, code: string) => {
    const taric = code.substring(0, 8);
    const addTaricCode = code.substring(8);
    formik.setFieldValue('goodsInformation.combinedNomenclatureCode', taric);
    formik.setFieldValue('goodsInformation.taricCode', addTaricCode);
    formik.setFieldValue('commodityCodeField', code);
};

export const updateIrelandEnsTaricCode = (formik: FormikProps<any>, code: string) => {
    const taric = code.substring(0, 8);
    formik.setFieldValue('commodity.combinedNomenclature', taric);
    formik.setFieldValue('commodityCodeField', code);
};

export const getIrelandEnsTaricCode = (formik: FormikProps<any>) => {
    return formik.getFieldProps('commodityCode').value;
};
