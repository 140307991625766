import { Reducer } from 'redux';
import { TestActions } from './actionTypes';
import { DeclarationFooterState, DeclarationFooterType } from './declaration-footer';

export const declarationFooterInitialState: DeclarationFooterState = {
    type: DeclarationFooterType.MASTER_DETAILS,
};

const declarationFooter: Reducer<DeclarationFooterState> = (
    state = declarationFooterInitialState,
    action: TestActions
) => {
    switch (action.type) {
        case 'SET_DECLARATION_FOOTER':
            return { ...state, type: action.payload };
        case 'CLEAR_DECLARATION_FOOTER':
            return {
                ...state,
                type: undefined,
            };

        default:
            return state;
    }
};
export default declarationFooter;
